import React, {
    useState, useEffect, useCallback,
    useImperativeHandle, forwardRef
} from 'react';
import Modal from 'react-modal';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
function ModalAssinaturaTerapeuta({ id = "modalAssinaturas", terapeutaId, setAssinaturaTerapeuta, onClose, modalData, assinaturaTerapeuta, Arquivos }, ref) {
    const [dados, setDados] = useState();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [assinatura, setAssinatura] = useState(null);
    const snackbar = useSnackbar();
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);
    function afterOpenModal() {
        setAssinatura(null);
    }
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    const editAssinatura = async (assinatura, dados) => {
        let assinaturaLocal = [...assinaturaTerapeuta];
        if (terapeutaId === null || terapeutaId === undefined) {
            const index = assinaturaLocal.indexOf(assinatura);
            assinaturaLocal.splice(index, 1, dados);
            setAssinaturaTerapeuta(assinaturaLocal);
        } else {
            let dadosInclusao = { file: dados.assinatura, terapeuta_id: terapeutaId };
            await Arquivos.store(dadosInclusao, `api/assinatura-terapeuta`, 'file')
                .then(({ data }) => {
                    snackbar.displayMessage('Assinatura adicionada com sucesso!', 'success');
                    assinaturaLocal.push(data);
                    setAssinaturaTerapeuta(assinaturaLocal);
                }).catch(e => {
                    snackbar.displayMessage('Houve um erro ao cadatrar a assinatura, contate o suporte.', 'error');
                    console.error(e);
                })
        }
        onClose();
    }
    const handleSubmit = () => {
        let submitData = {
            assinatura: assinatura
        };
        let assinaturasLocal = [...assinaturaTerapeuta];
        if (terapeutaId === null || terapeutaId === undefined) {
            if (modalData === null || modalData === undefined) {
                if (assinaturaTerapeuta.length > 0) {
                    Swal.fire('Cadastre apenas uma assinatura!', '', 'warning');
                } else {
                    assinaturasLocal.push(submitData);
                    setAssinaturaTerapeuta(assinaturasLocal);
                }
            } else {
                editAssinatura(modalData, submitData);
            }
        } else {
            if (modalData === null || modalData === undefined) {
                if (assinaturaTerapeuta > 0) {
                    Swal.fire('Cadastre apenas uma assinatura!', '', 'warning');
                } else {
                    assinaturasLocal.push(submitData);
                    setAssinaturaTerapeuta(assinaturasLocal);
                }
            } else {
                editAssinatura(modalData, submitData);
            }
        }
        closeModal();
        onClose();
    }
    const handleRemoveImg = () => {
        Swal.fire({
            title: 'Deseja remover a imagem?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let inputImg = document.getElementById("assinatura_terapeuta");
                inputImg.value = "";
                setAssinatura(null);
            }
        });
    };
    const handleChangeAssinatura = (e) => {
        var extPermitidas = ['jpg', 'png', 'jpeg', 'img', 'bmp', 'gif'];
        let inputImg = document.getElementById("assinatura_terapeuta");
        const file = e.target.files[0];
        const size = file.size;
        if (extPermitidas.find((ext) => { return file.name.split('.').pop() === ext }) === undefined) {
            Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + 'Permitidas: ' + extPermitidas, '', 'warning');
            inputImg.value = "";
        } else {
            if (size < 5242880) { //5MB
                if (file) {
                    const reader = new FileReader();
                    reader.addEventListener("load", (e) => {
                        const readerTarget = e.target;
                        inputImg.src = readerTarget.result;
                    });
                    reader.readAsDataURL(file);
                    setAssinatura(file);
                } else {
                    Swal.fire('Houve um problema ao enviar o arquivo', '', 'warning');
                }
            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 5MB)', '', 'warning');
                inputImg.value = "";
            }
            e.preventDefault();
        }
    }
    useEffect(() => {
        if (modalData !== null && modalData.length != 0) {
            let inputImg = document.getElementById("assinatura_terapeuta");
            const reader = new FileReader();
            reader.addEventListener("load", (e) => {
                const readerTarget = e.target;
                inputImg.src = readerTarget.result;
            });
            reader.readAsDataURL(modalData.assinatura)
            setAssinatura(modalData.assinatura)
        }
    }, [modalData]);
    const customStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px',
            width: '600px',
            backgroundColor: "#FFFFFF"
        },
    };
    return (
        <Modal id={id} style={customStyle} onClick={e => handleOutsideClick(e)} isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} contentLabel="Assinatura do Terapeuta" appElement={document.getElementById('root')} transparent>
            <div className='d-flex align-items.center justify-content-between col-12'>
                <p></p>
                <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF', marginBottom: '2rem' }}>
                    Cadastrar Nova Assinatura
                </label>
                <label className='btn-fecha-modal' onClick={e => onClose(e)}> x </label>
            </div>
            <div>
                {assinatura
                    ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img style={{ borderRadius: 10, minHeight: 200, maxWidth: 250 }} id='assinatura_terapeuta' alt=''></img>
                        <div>
                            <span className='btn-delete' onClick={e => handleRemoveImg()}> x </span>
                        </div>
                    </div>
                    : <div>
                        <p className='text-center mt-10px'> Nenhuma imagem encontrada </p>
                    </div>
                }

                <div>
                    <div className='div-center'>
                        <label htmlFor="assinatura" className='btn-upload'>
                            Enviar Arquivo
                            <input type="file" name='assinatura' id='assinatura' style={{ display: "none", cursor: 'none' }} onChange={e => handleChangeAssinatura(e)} />
                        </label>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items.center justify-content-evenly'>
                <button onClick={e => onClose()} className='btn-padrao'> Cancelar </button>
                <button onClick={e => handleSubmit()} className='btn-padrao'> Confirmar </button>
            </div>
        </Modal>
    );
}
export default forwardRef(ModalAssinaturaTerapeuta);