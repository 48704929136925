import { useState } from "react";
import { useAuth } from "../../context/auth";
import HeaderPadrao from "../../layouts/HeaderPadrao";
import RecadosCard from './RecadosCard';

import { Container, ContainerSub, Header, Recados } from './styles';
import api from "../../services/api";


export default function DashboardPadrao() {
    let { user } = useAuth();
    const [recado, setRecado] = useState([]);

    useState(() => {
        api.get('api/recado/get/by/group/'+user.tipo_user)
            .then(({ data }) => {
                setRecado(data);
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);
    return (
        <HeaderPadrao>
            <Container>
                <ContainerSub>
                    <Header>
                        <span>
                            <label style={{ color: "#4906AB", fontSize: "22px", fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>
                                Olá
                            </label>
                            <label style={{ color: "#4906AB", fontSize: "22px", fontFamily: "Nunito, sans-serif" }}>
                                , {user?.name && user.name}
                            </label>
                        </span>
                    </Header>
                    <Recados>
                        <RecadosCard title={"Recados:"} recado={recado} />
                    </Recados>
                </ContainerSub>
            </Container>
        </HeaderPadrao>
    )
}
