import React, { useEffect, useState } from 'react';
import Terapeuta from '../../../layouts/Terapeuta';

import { Container, TituloPagina } from './styles';
import TableStatusDiario from './TableStatusDiario';
import moment from 'moment';
import api from '../../../services/api';
import LegendaStatusDiario from './LegendaStatusDiario';
import AtendimentoAlteracaoService from '../../../services/AtendimentoAlteracaoService';
import AtendimentoService from '../../../services/AtendimentoService';
import { useAuth } from '../../../context/auth';
import { useSnackbar } from '../../../context/snackbar';
import { ClipLoader } from 'react-spinners';
import { ContagemPendentes } from '../TerapeutaPacientes/styles';
import { FiRefreshCcw } from 'react-icons/fi';
import LiveBadge from 'components/LiveBadge';

const StatusDiario = () => {
    const title = 'Status Diário';
    const description = `
        Tela de visualização de todos os atendimentos do terapeuta no dia. Nessa tela o terapeuta pode alterar status,
    solicitar alterações e visualizar as alterações.`;
    const [atendimentos, setAtendimentos] = useState([]);
    const [horariosDia, setHorariosDia] = useState([]);
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [quantidadeDeAtualizacoes, setQuantidadeDeAtualizacoes] = useState(0);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [controleDiario, setControleDiario] = useState(null);
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(null);
    const usuario = useAuth();
    const snackbar = useSnackbar();

    const handleSugestaoChange = (e, atendimentoId) => {
        const { value } = e.target;
        if (value === '1' || value === 1) {
            const atualizacaoObj = { id: atendimentoId, sugestao_terapeuta: value, sugestao_recepcao: value };
            api.patch(`api/atendimento/${atendimentoId}`, atualizacaoObj).then(() => {
                snackbar.displayMessage('Sugestão salva com sucesso!', 'success');
            }).catch(e => {
                snackbar.displayMessage('Erro ao salvar sugestão!', 'error');
                console.error(e);
            });
        } else {
            const atualizacaoObj = { id: atendimentoId, sugestao_terapeuta: value, sugestao_recepcao: '' };
            api.patch(`api/atendimento/${atendimentoId}`, atualizacaoObj).then(() => {
                snackbar.displayMessage('Sugestão salva com sucesso!', 'success');
            }).catch(e => {
                snackbar.displayMessage('Erro ao salvar sugestão!', 'error');
                console.error(e);
            });
        }
    }

    const getHorariosDia = async () => {
        await api.get(`/api/horario-atendimento?filter[dia_semana]=${moment.utc().weekday()}`).then(response => {
            let horarios = response.data;
            setHorariosDia(horarios);
        });
    };

    const getAtendimentosDia = async () => {
        setAlgoCarregando(true);
        try {
            const diaAtual = moment().format('YYYY-MM-DD');
            const controleDiario = await api.get(`/api/controle-diario?filter[dia]=${diaAtual}`).then(response => response.data[0]);
            const controleDiarioId = controleDiario.id;
            setControleDiario(controleDiario);
            const terapeuta_id = usuario.user.terapeuta[0].id;
            const filters = `filter[terapeuta_id]=${usuario.user.terapeuta[0].id}`;
            await AtendimentoService.getByControleDiarioId(controleDiarioId, null, undefined, undefined, filters)
                .then(response => {
                    let atendimentosResponse = response.data.filter((atendimento) => atendimento.terapeuta_id === terapeuta_id);
                    atendimentosResponse = atendimentosResponse.map(atendimento => {
                        atendimento.cor = AtendimentoAlteracaoService.getCorStatusSolicitacao(atendimento.solicitacao_de_alteracaos);
                        return atendimento;
                    });
                    setAtendimentos(atendimentosResponse);
                });
            setAlgoCarregando(false);
        } catch (error) {
            console.error(error);
        }
    };

    const modalLegendas = () => {
        setIsModalLegendaVisible(true);
    }

    const onLoad = async () => {
        await getHorariosDia();
        await getAtendimentosDia();
    }
    useEffect(() => {
        /* Apenas vai iniciar a conexão com o socket, caso o controle diário seja não salvo e não tenha conexão ainda. */
        if (!socket) {
            if (controleDiario && controleDiario.id && controleDiario?.salvo === null) {
                /* Criação da URL do WS */
                const params = `?controle_diario_id=${controleDiario.id}`;
                const isHost = window.location.hostname === 'localhost';
                const urlWS = isHost
                    ? `ws://localhost:6001/ws/controle_diario${params}`
                    : `wss://sistema.neurointensiva.com/ws/controle_diario${params}`;
                /* Cria conexão */
                const socketLocal = new WebSocket(urlWS);
                /* Define a conexão na variável controlada */
                setSocket(socketLocal);

                /* Define o listener open do socket */
                socketLocal.addEventListener('open', function () {
                    /* Define o estado global do socket como verdadeiro(Ou seja, conectado) */
                    setSocketState(true);
                });

                /* Define o listener close do socket */
                socketLocal.addEventListener('close', function () {
                    /* Define o estado global do socket como falso(Ou seja, desconectado) */
                    setSocketState(false);
                });

                /* Define o listener message do socket */
                socketLocal.addEventListener('message', function (event) {
                    try {
                        /* Verifica se a data veio corretamente */
                        if (event.data !== null && event.data !== undefined && event.data !== '') {
                            /* Transforma o texto JSON em um objeto */
                            const data = (JSON.parse(event.data));
                            /* Verifica se tem data */
                            if (data) {
                                /* Verifica se tem id do paciente */
                                if (data.action === 'contador') {
                                    /* Se não tiver id do paciente, significa que não veio do Controle
                                    de Execução, então deve apenas adicionar ao contador de atualizaç~eos. */
                                    const newQuantidade = Number(quantidadeDeAtualizacoes) + 1;
                                    setQuantidadeDeAtualizacoes(newQuantidade);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                });

                /* Define o listener error do socket */
                socketLocal.addEventListener('error', function (event) {
                    console.error('Erro: ', event);
                });
            }
        }
    }, [socket, controleDiario]);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Terapeuta>
            <Container>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <TituloPagina className='mb-0'> Status Diário </TituloPagina>
                    <div className='mb-2'> Dia: {moment().format('DD/MM/YYYY')} </div>
                    <div className='mb-2 d-flex flex-row'>
                        <button className='btn-padrao h-38px p-0 mx-2 w-30px' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => { getAtendimentosDia(controleDiario.id); }}>
                            {quantidadeDeAtualizacoes > 0 &&
                                <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px -30px 0 0' }}>
                                    {quantidadeDeAtualizacoes}
                                </ContagemPendentes>}
                            <FiRefreshCcw size={18} className={`rotacao-padrao${algoCarregando ? ' rodando' : ''}`}></FiRefreshCcw>
                        </button>
                        <button className='btn-padrao h-38px p-0 mx-2 w-30px' onClick={() => modalLegendas()}> ? </button>
                    </div>
                </div>
                <div className='col-sm-12 col-md-8 col-lg-4 mx-auto d-flex justify-content-center'>
                    {atendimentos.length > 0 && horariosDia.length > 0
                        ? <TableStatusDiario atendimentos={atendimentos} horarios={horariosDia} handleChangeSugestao={(e, atendimentoId) => handleSugestaoChange(e, atendimentoId)} />
                        : <ClipLoader color={'#123abc'} loading={true} size={100} />
                    }
                </div>
            </Container>

            {isModalLegendaVisible && <LegendaStatusDiario
                onClose={() => setIsModalLegendaVisible(false)}
            />}
            <LiveBadge sincronizado={socketState} texto={false} tooltip={'Conectado ao servidor de atualização!'} />
        </Terapeuta>
    );
};

export default StatusDiario;
