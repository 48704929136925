import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from './styles';
import api from "../../../services/api";
import { MainDivStyle } from "./styles";
import ClipLoader from "react-spinners/ClipLoader";
import { TableFaltas } from "./TableFaltasPacientes";
import { DetalhesFaltas } from "./DetalhesFaltas";
import moment from 'moment';
import BarChart from '../../../components/Charts/BarChart';
import { FaEraser, FaFilter } from "react-icons/fa";
import Swal from "sweetalert2";
import PacienteService from "../../../services/PacienteService";

export default function FaltasPacientes() {
    /* =============================== */
    /* ===VARIÁVEIS=================== */
    /* =============================== */
        /* ===vrPACIENTE================== */
        const [paciente, setPaciente] = useState([]);
        const [pacienteOriginal, setPacienteOriginal] = useState([]);
        const [pacienteIdentList, setPacienteIdentList] = useState(null);
        const [selecionadoPaciente, setSelecionadoPaciente] = useState([]);
        /* ===vrFILTRO==================== */
        const [filterValue, setFilter] = useState("");
        const [data_i, setDataI] = useState(null);
        const [data_f, setDataF] = useState(null);
        /* ===vrUTILITÁRIOS DA PÁGINA===== */
        const [modalFaltas, setModalFaltas] = useState(false);
        /* ===vrUTILITÁRIOS DA PÁGINA===== */
        const [color, setColor] = useState("#8ed4ae");
        const [loading, setloading] = useState(false);
        const navigate = useNavigate();
        /* ===vrCHART=================== */
        const [chartData, setChartData] = useState();
        const [loadingChart, setLoadingChart] = useState(false);
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
        /**
         * Função que faz um alerta de confirmação para bloquear o acesso de um paciente, e baseado na resposta, bloqueia ou não.
         * @param {Array} paciente Paciente a ser bloqueado
         */
        function bloqueiaAcesso(index){
            let pacienteL = paciente[index];
            let titulo = 'Bloqueio do paciente '+pacienteL.nome+'?';
            let conteudo = 'O paciente '+pacienteL.nome+' esta com '+(pacienteL.faltas+' falta'+(pacienteL.faltas!=1?'s':''))+'. Você deseja bloquear seu acesso a atendimentos e enviar uma notificação de bloqueio?'
            let option = {
                title: /* '(' + pacienteL.id + ')' +  */titulo,
                text: conteudo,
                icon: 'alert',
                confirmButtonText: 'Bloquear acesso',
                denyButtonText: 'Cancelar',
                showConfirmButton: true,
                showDenyButton: true,
            }
            Swal.fire(option).then((result) => {
                if (result.isConfirmed) PacienteService.bloqueiaAcesso(pacienteL.id);
            })
        }
        /**
         * Função que define todas as informações relevantes para o grafico.
         * @return {void}
         */
        const setInformacoesGrafico = async () => {
            setLoadingChart(true);
            let labels = [];
            let dataset = [[], [], [], []];
            pacienteOriginal.forEach(e => {
                labels.push(e.nome);
                dataset[0].push(e.atendimentos.length);
                dataset[1].push(e.faltas);
            });
            let data = {
                labels: labels,
                datasets: [{
                    label: 'Atendimentos',
                    backgroundColor: 'rgba(0,180,0,1)',
                    borderColor: 'rgba(0,80,0,1)',
                    borderWidth: 2,
                    borderRadius: 5,
                    data: dataset[0]
                },
                {
                    label: 'Faltas',
                    backgroundColor: 'rgba(180,0,0,1)',
                    borderColor: 'rgba(80,0,0,1)',
                    borderWidth: 2,
                    borderRadius: 5,
                    data: dataset[1]
                }],
            }
            setChartData(data);
            setLoadingChart(false);
        }
        /**
         * Função que carrega de forma assincrona todas as informações dos pacientes da lista de faltas.
         * @return {void}
         */
        async function loadPacientes() {
            setloading(true);
            let loc_data_i = data_i;
            let loc_data_f = data_f;
            if (data_i === null && data_f === null) {
                var d = new Date();
                loc_data_f = moment(d).format('YYYY-MM-DD');
                setDataF(loc_data_f);
                loc_data_i = moment(menosDiasData(90, d)).format('YYYY-MM-DD');
                setDataI(loc_data_i);
            }
            api.get(`api/paciente/faltas/list/${loc_data_i}/${loc_data_f}`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setPacienteOriginal(data);
                setPaciente(data);
                setloading(false)
            });
        }
        /**
         * Função que subtrai uma quantidade especifica de dias de uma data passada, e retorna a data subtraída.
         * @param {String} days Recebe os dias que vão ser subtraídos da data.
         * @param {Date} date Recebe a data que vai ser subtraída
         * @return {Date}
         */
        const menosDiasData = (days, date) => {
            if (date !== Date && date === String) {
                date = new Date(date);
            }
            date.setDate(date.getDate() - days);
            return date;
        }
    //#endregion
    /* =============================== */
    /* ===HANDLE====================== */
    /* =============================== */
        /**
         * Função que executa à cada alteração do campo de pacienteSearch e filtra o array de informações com base nisso.
         * @param {string} data Variável que recebe o valor do nome a ser filtrado.
         * @return {void}
        */
        const handleChangePacienteSearch = (data) => {
            setFilter(data);
            setPaciente(pacienteOriginal);
            setPaciente((pacientes) => pacientes.filter((item) => {
                return (item.nome.toString().toLowerCase().includes(data.toString().toLowerCase()) ||
                        item.identificador.toString().toLowerCase().includes( data.toString().toLowerCase()
                        ))}
            ));
        }
        /**
         * Função que recebe os dados do paciente e abre/fecha a modal de detalhes de faltas.
         * @param {Number} paciente_index Define o index do vetor dos pacientes.
         * @return {void}
         */
        const handleOpenModalDetalhesFaltas = (paciente_index, debug = false) => {
            setSelecionadoPaciente(paciente[paciente_index]);
            setModalFaltas(true);
        }
        
        const handleCloseModalFaltas = () => {
            setModalFaltas(false);
        }
    /* =============================== */
    /* ===USE EFFECT================== */
    /* =============================== */
    useEffect(() => {
        let idList = [];
        pacienteOriginal.forEach((data) => {
            if (data.status !== 'Inativo') {
                if (data.identificador !== null) {
                    idList.push(data.identificador)
                };
            };
        });
        setPacienteIdentList(idList);
        setInformacoesGrafico();
    }, [pacienteOriginal])
    useEffect(() => {
        loadPacientes();
    }, []);
    /* =============================== */
    /* ===HTML======================== */
    /* =============================== */
    return (
        <Container>
            <div style={MainDivStyle}>
                <div>
                    <div style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", marginRight: 20,
                    }}>
                        <label style={{
                            fontFamily: "Nunito, sans-serif", color: "#7340BD",
                            fontWeight: "bold", cursor: "pointer"
                        }}>
                            Pacientes
                        </label>
                    </div>
                </div>
            </div>
            <div className="mb-2 d-flex">
                <input className="form-control" placeholder={"Pesquisar Paciente por Nome:"}
                    value={filterValue || ''}
                    onChange={e => { handleChangePacienteSearch(e.target.value) }}//set undefinded to filter = empty
                />
            </div>
            <div className="mb-2 d-flex">
                <input className="form-control" value={data_i || ""} id="data_i" name="data_i" type="date" onChange={e => { setDataI(e.target.value) }} />
                <input className="form-control" value={data_f || ""} id="data_f" name="data_f" type="date" onChange={e => { setDataF(e.target.value) }} />
                <button className="btn-padrao font-12px h-38px" onClick={() => {loadPacientes(); setDataF(null); setDataI(null); setFilter(null)}}><FaEraser/></button>
                <button className="btn-padrao font-12px h-38px" onClick={() => {loadPacientes()}}><FaFilter></FaFilter></button>
            </div>
            <div style={{ marginBottom: 20 }}>
                {(paciente.length != 0 && loading === false) ?
                    <TableFaltas source={paciente} openModal={handleOpenModalDetalhesFaltas} bloqueiaAcesso={bloqueiaAcesso}/>
                    : ''}
                {(loading === true) ?
                    <div className="justify-center align-center text-center">
                        <ClipLoader className="m-auto" color={color} loading={loading} size={50} />
                    </div>
                    : ''}
            </div>
            <BarChart className="h-200px w-100per d-none" loading={loadingChart} chartData={chartData} options={{ maintainAspectRatio: false }}></BarChart>
            {modalFaltas ? <DetalhesFaltas onClose={handleCloseModalFaltas} paciente={selecionadoPaciente}/> : ''}
        </Container>
    )
}