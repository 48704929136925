import React, {useState, useEffect} from 'react';
import {ModalContainer} from '../../PacienteAltasModal/styles';
import Input from '../../../Input';
import { Form } from '@unform/web';
import TextArea from '../../../TextArea';
import {BsCloudArrowUp} from 'react-icons/bs';
import moment from 'moment/moment';
import { ButtonStyle2, ButtonStyle3, Delete, Modal, Upload } from '../styles';
import Select from '../../../Select';
import api from '../../../../services/api';
import Swal from 'sweetalert2';
import { RelatorioOrientacaoService } from '../../../../services/RelatoriosService';
import { useSnackbar } from '../../../../context/snackbar';

function OrientacaoRelatorio({ onClose }) {

  const [pacientes, setPacientes] = useState([]);
  const [terapeutas, setTerapeutas] = useState([]);
  const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
  const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
  const [terapias, setTerapias] = useState([]);
  const [especialidades, setEspecialidades] = useState([]);
  const [terapiaSelecionada, setTerapiaSelecionada] = useState(null);
  const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null);
  const [pacienteOptions, setPacienteOptions] = useState([]);
  const [dadosPaciente, setDadosPaciente] = useState([]);
  const [documento, setDocumento] = useState(null);
  const [path, setPath] = useState([]);

  const id = 'modal-paciente-evolucao-diaria';
  let day = moment().format("YYYY-MM-DD");
  const snackbar = useSnackbar();

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  const handleSubmit = (data) => {
    
    if (data.orientacao === "") {
      Swal.fire('Por favor preencha todos os campos!', '', 'warning');
    } else {
      const objSubmit = {
        paciente_id: pacienteSelecionado.value,
        terapeuta_id: terapeutaSelecionado.value,
        responsavel_id: dadosPaciente.responsavel.responsavel_id,
        terapia_id: terapiaSelecionada.value,
        especialidade_id: especialidadeSelecionada.value,
        status: dadosPaciente.status,
        data: day,
        arquivo: documento,
        orientacao: data.orientacao,
        //"user_request":52488
        };
      RelatorioOrientacaoService.store(objSubmit);
      snackbar.displayMessage("Relatório criado com sucesso!", "success");
      onClose();
    };
  }

  const handleUpload = (e) => {
    var arquivo = document.getElementById("arquivo")
    var file = e.target.files[0].name;
    var size = e.target.files[0].size;
    var extPermitidas = ['jpg', 'png', 'jpeg', 'pdf',];
    if (extPermitidas.find((ext) => {return file.split('.').pop() === ext}) === undefined){
        Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg, pgn,)', '', 'warning');
        arquivo.value = "";
    } else {
        if (documento === null) {
            if(size < 40000000) { //40MB         
                setDocumento(e.target.files[0]);
                setPath([...path, e.target.value]);
            } else {           
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                arquivo.value = "";
            }
            e.preventDefault();
        } else {
            Swal.fire('Cadastre apenas um arquivo!', '', 'warning');
        }
        }
  }

  const handleDeleteUpload = () => {
      setDocumento(null);
      path.splice(-1);
  };

  const loadTerapiasEspecialidades = (paciente) => {
    let terapiasList = [];
    let especialidadesList = [];
    if (paciente.terapia_pacientes.length > 0) {
      paciente.terapia_pacientes.forEach((terapia) => {
        terapiasList.push({value: terapia.terapium.id, label: terapia.terapium.terapia});
        especialidadesList.push({value: terapia.especialidade.id, label: terapia.especialidade.especialidade});
      })
    }
    setTerapiaSelecionada(null);
    setEspecialidadeSelecionada(null);
    setTerapias(terapiasList);
    setEspecialidades(especialidadesList);
  }

  const loadTerapeutasPaciente = (paciente) => {
    let terapeutasList = [];
    paciente.forEach((data) => {
      if (terapeutasList.find(terapeuta => terapeuta.value === data.terapeutum.id) === undefined) {
        terapeutasList.push({value: data.terapeutum.id, label: data.terapeutum.nome});
      }
    })
    setTerapeutaSelecionado(null);
    setTerapeutas(terapeutasList);
  }

  const handleSetPaciente = (e) => {
    const pacienteData = pacientes.find(data => data.paciente.id === e.value);
    const terapeutasData = pacientes.filter(data => data.paciente_id === e.value);
    setPacienteSelecionado(e);
    setDadosPaciente(pacienteData);
    loadTerapiasEspecialidades(pacienteData.paciente);
    loadTerapeutasPaciente(terapeutasData);
  }

  const setWarning = (e) => {
    if (dadosPaciente.paciente === undefined) {
      snackbar.displayMessage("Por favor selecione o Paciente!", 'warning');
    } else {
      return
    }
  }

  const getPacientes = () => {
    api.get(`api/atendimento?with=paciente.terapia_pacientes.terapium,paciente.terapia_pacientes.especialidade,terapium`).then(({data}) => {
      let selectPaciente = [];
      data.forEach((paciente) => {
        if (selectPaciente.find(data => data.value === paciente.paciente.id) === undefined) {
          selectPaciente.push({ value: paciente.paciente.id, label: paciente.paciente.nome});
        }
      });
      setPacienteOptions(selectPaciente);
      setPacientes(data);
    })
  }

  useEffect(() => {
    getPacientes();
  }, [])
  
  return (
      <Modal id={id} onClick={handleOutsideClick}>
        <ModalContainer>
          <div style={{position: "relative"}}>
            <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
              right: "25px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Relatório de Orientação</label>
          </div>
          <Form onSubmit={handleSubmit}>
            <div style={{textAlign: "center"}}>
              <label style={{ fontSize: '18px', marginTop: 20,}}>Selecione o Paciente</label>
            </div>
            <div className='d-flex flex-wrap justify-content-center p-2 px-5'>
              <div className='p-1 col-2'><Input readOnly={'readOnly'} onFocus={setWarning} name="id" title="Identificador:" type="text" 
                defaultValue={dadosPaciente.paciente === undefined ? '' : dadosPaciente.paciente.identificador}/></div>
              <div className='p-1 col-7'><Select  options={pacienteOptions} onFocus={setWarning} name="nome" onChange={e => handleSetPaciente(e)} 
              label="Nome do Paciente:" type="text" value={pacienteSelecionado}/></div>
              <div className='p-1 col-3'><Input readOnly={'readOnly'} onFocus={setWarning} name="nascimento" title="Data de Nascimento:" type="text"
                value={dadosPaciente.paciente === undefined ? '00/00/0000' : moment(dadosPaciente.paciente.nascimento).format('DD/MM/YYYY')}/></div>
              <div className='p-1 col-6'><Input readOnly={'readOnly'} onFocus={setWarning}  name="diagnostico" title="Diagnóstico:" type="text"
                defaultValue={dadosPaciente.paciente === undefined ? '' : dadosPaciente.paciente.diagnostico}/></div>
              <div className='p-1 col-6'><Input readOnly={'readOnly'} onFocus={setWarning} name="responsavel" title="Responsável:" type="text" 
                defaultValue={dadosPaciente.paciente === undefined ? '' : dadosPaciente.paciente.responsavel.nome}/></div>
              <div className='p-1 col-4'><Select options={terapeutas} onFocus={setWarning}  onChange={e => setTerapeutaSelecionado(e)} name="terapeuta" label="Terapeuta:" 
                type="text" value={terapeutaSelecionado}/></div>
              <div className='p-1 col-4'><Select options={terapias} onFocus={setWarning}  onChange={e => setTerapiaSelecionada(e)} name="terapia" label="Terapia:" 
                type="text" value={terapiaSelecionada}/></div>
              <div className='p-1 col-4'><Select options={especialidades} onFocus={setWarning} onChange={e => setEspecialidadeSelecionada(e)} name="especialidade" 
                label="Especialidade:" type="text" value={especialidadeSelecionada}/></div>
              <div className='p-1 col-3' style={{width: '26%'}}><Input  name="data" title="Data do Relatório:" type="date" defaultValue={day}/></div>
              <div className='px-1 col-12'><TextArea name="orientacao" title="Orientação:" /></div>
              <div className='px-1 col-12 d-flex align-items-center justify-content-center'>
                <label className='mb-1' htmlFor="anexo-btn" style={{cursor: 'pointer', color: '#6A3BAF', fontWeight: '600'}}> 
                    <input className='d-none' type="file" id='anexo-btn' onChange={(e) => handleUpload(e)} />
                    <BsCloudArrowUp size={30} className='me-2' />Anexar Arquivos
                </label>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                {path.map((item) => {return <span style={ Upload }>{item}
                    <div style={{}}>
                        <span style={Delete} onClick={e=> handleDeleteUpload(e)}>
                            x
                        </span> 
                    </div>
                </span>})}
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
              <button style={ButtonStyle2} onClick={e => onClose()} >
                  Voltar
              </button>
              <button style={ButtonStyle3} type='submit'>
                  Confirmar
              </button>
            </div>
          </Form>
        </ModalContainer>
      </Modal>
  );
}

export default OrientacaoRelatorio;
