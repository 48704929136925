import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import TableStatus from "./TableStatus";
import api from "../../../../services/api";
import StatusAtendimentoModal from "../../../../components/Modais/StatusAtendimentoModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Status() {

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#8ed4ae");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [StatusAtendimento, setStatusAtendimento] = useState([]);
  const [modalData, setModalData] = useState(null);

  const loadStatus = () => {
    api.get('/api/status-atendimento')
      .then(({ data }) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        setStatusAtendimento(data)
      })
      .finally(() => setLoading(false));
  }

  const handleNewStatus = () => {
    setModalData(null);
    setIsOpen(true);
  };

  const editModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  };

  useEffect(() => {
    loadStatus();
  }, []);


  return (
    <Admin>
      <Container>
        <div>
          <button
            onClick={() => handleNewStatus()}
            style={{
              backgroundColor: '#7340BD',
              color: '#FFFFFF',
              fontFamily: "Nunito, sans-serif",
              border: "none",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              marginBottom: 20
            }}>
            <AiFillFileAdd style={{ marginRight: 5 }} />
            Novo Status de atendimento
          </button>
        </div>
        <div>
          {
            (StatusAtendimento.length !== 0 && loading === false) ? <TableStatus callback={loadStatus} source={StatusAtendimento}
              editModal={editModal} /> :
              <ClipLoader color={color} loading={loading} css={override} size={50} />
          }
        </div>
        {modalIsOpen && <StatusAtendimentoModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          callback={loadStatus}
          modalData={modalData}
        />}
      </Container>
    </Admin>
  )
}
