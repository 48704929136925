import React, { useState, useEffect, useRef } from 'react';
import html2PDF from 'jspdf-html2canvas';
import Button from '../../Button';
import { BsArrowDown, BsArrowUp, BsDownload } from 'react-icons/bs';

// Definindo os estilos para cada tipo de folha
const larguraPagina = 210; // 794px
const alturaPagina = 297; // 1122.52px
const styles = {
    container: {
        overflow: 'hidden',
        height: '100vh',
        width: '100vw'
    },
    overScroll: {
        height: 'calc(100vh - 76px)',
        backgroundColor: 'rgba(20, 0, 20, 0.5)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    scroll: {
        height: 'calc(100vh - 76px)',
        zIndex: 2,
        flexDirection: 'column',
        flexWrap: 'wrap',
        scrollbarWidth: 'none', // Add this line to hide the scrollbar in Firefox
        overflowY: 'scroll',
        '&::WebkitScrollbar': {
            display: 'none', // Add this block to hide the scrollbar in Chrome and Safari
        },
    },
    toolbox: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '10px',
        backgroundColor: 'rgba(40, 0, 40, 0.8)',
        boxShadow: '0px 4px 8px rgba(20, 0, 20, 0.4)',
        zIndex: 200,
        height: '76px',
        width: '100%'
    },
    functions: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginLeft: '20px',
        width: '30%',
        margin: '0',
        marginLeft: '20px',
        pdfButton: {
            padding: '10px',
            margin: '0 10px',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            width: 'fit-content',
        },
    },
    informations: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30%',
        margin: '0',
        flexDirection: 'column',
        actualPage: {
            color: 'white',
        },
        documentoAberto: {
            color: 'white',
            fontWeight: 'bold',
        },
        pageInput: {
            width: '50px',
            margin: '0 0px 0 10px',
            backgroundColor: 'rgba(100, 0, 100, 0.8)',
            color: 'white',
            textAlign: 'right',
            paddingRight: '5px',
            width: '40px',
        },
    },
    fechar: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '30%',
        botaoFechar: {
            padding: '10px',
            marginRight: '20px',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            fontSize: '30px',
            width: 'fit-content',
        }
    },
    navegation: {
        paddingRight: '20px',
        width: '200px',
        margin: '0',
        display: 'flex',
        alignItems: 'end',
        marginBottom: '20px',
        justifyContent: 'end',
        div: {
            display: 'flex',
            justifyContent: 'end',
            flexDirection: 'column',
        }
    },
    navegationButton: {
        padding: '10px',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
        width: '55px',
        height: '55px',
        padding: '0',
        fontSize: '30px',
    },
    page: {
        width: larguraPagina + 'mm',
        height: alturaPagina + 'mm',
        padding: '0',
        margin: '0 auto',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 10,
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(20, 0, 20, 0.4)',
        //marginBottom: '15px',
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            width: '100%',
            height: '22mm', // 83.86px
            padding: '0 10px 0px 10px',
            title: {
                fontSize: '24px',
                fontWeight: 'bold',
                marginBottom: '20px',
            },
            logo: {
                marginRight: '10px', // Add this line to create some space between the logo and the content
                width: '150px',
            },
        },
        content: {
            fontSize: '16px',
            textAlign: 'justify',
            display: 'flex',
            alignItems: 'start', // Add this line to center the logo vertically
            padding: '22mm', // 83.86px
            paddingTop: '0',
            paddingBottom: '0',
            height: alturaPagina - 44 + 'mm', // 938.52px
        },
        footer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            marginBottom: '20px',
            width: '100%',
            height: '22mm', // 83.86px
            padding: '0px 10px 10px 10px',
            textAlign: 'center',
        },
    },
    sombra: {
        boxSizing: 'border-box',
        boxShadow: 'rgba(20, 0, 20, 0.4) 0px 30px 90px',
        //border: '1px solid rgba(20, 0, 20, 0.4)',
    },
    hiddenContent: {
        visibility: 'hidden',
        position: 'absolute',
    },
};

const minimapStyles = {
    minimap: {
        width: '200px',
        height: 'calc(100vh - 96px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        position: 'relative',
        transition: 'opacity 0.3s ease',
        opacity: 0.5,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        marginTop: '0.4%',
    },
    minimapHover: {
        opacity: 1,
    },
    thumbnail: {
        cursor: 'pointer',
        border: '1px solid #ccc',
        borderRadius: '4px',
        transformOrigin: 'top left',

        maxWidth: 'calc(' + larguraPagina + 'mm*0.2)',
        maxHeight: 'calc(' + alturaPagina + 'mm*0.2)',
        minWidth: 'calc(' + larguraPagina + 'mm*0.2)',
        minHeight: 'calc(' + alturaPagina + 'mm*0.2)',
        padding: 'calc(22mm*0.2)',
        paddingTop: '0',
        paddingBottom: '0',
        margin: '0 auto',
        overflow: 'hidden',
        zIndex: 10,
        backgroundColor: 'white',

        textAlign: 'justify',

        marginBottom: 'calc(20px*0.2)',
    },
    thumbnailSelected: {
        border: '2px solid blue',
    },
    thumbnailImage: {
        width: 'calc(30mm * 0.2)',
        height: 'auto',
    },
    thumbnailContent: {
        maxWidth: 'calc(793px * 0.2)',
        maxHeight: 'calc((' + alturaPagina + 'mm-44mm) * 0.2)',
        overflow: 'hidden',
        fontSize: 'calc(4.8mm * 0.2)',
        transformOrigin: 'top left',
    },
    thumbnailHeader: {
        fontSize: 'calc(4.8mm * 0.2)',
        height: 'calc(22mm * 0.2)',
        wuidth: '100%',
        justifyContent: 'space-between',
    },
    thumbnailFooter: {
        fontSize: 'calc(4.8mm * 0.2)',
        height: 'calc(22mm * 0.2)',
        wuidth: '100%',
    },
    thumbnailTitle: {
        fontSize: 'calc(7.2mm * 0.2)',
    },
    thumbnailSubTitle: {
        fontSize: 'calc(6mm * 0.2)',
    },
    thumbnailContentText: {
        fontSize: 'calc(4.8mm * 0.2)',
    },
};

const Minimap = ({ pages, onThumbnailClick = () => { }, currentPage, title, minimapRef }) => {
    const [hover, setHover] = useState(false);

    return (
        <div
            ref={minimapRef}
            style={{ ...minimapStyles.minimap, ...(hover ? minimapStyles.minimapHover : {}) }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {pages.map((pageContent, index) => (
                <div
                    key={index}
                    style={{ ...minimapStyles.thumbnail, ...(currentPage === index ? minimapStyles.thumbnailSelected : {}) }}
                    onClick={() => onThumbnailClick(index)}
                >
                    <div style={minimapStyles.thumbnailContent}>
                        <div style={minimapStyles.thumbnailHeader}>
                            <img src="/neuro_10_anos-1.png" alt="Logo" style={minimapStyles.thumbnailImage} />
                            <div style={minimapStyles.thumbnailTitle}>{title}</div>
                        </div>
                        <div style={minimapStyles.thumbnailContentText}>
                            {pageContent}
                        </div>
                        <div style={minimapStyles.thumbnailFooter}>
                            <p>Neuro Sistema © 2024. Todos os direitos reservados.</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

const A4Page = ({ title, onCloseClick = null, modalMode = false, content }) => {
    //#region VARIÁVEIS
    const [pages, setPages] = useState([]);
    const [minimapPages, setMinimapPages] = useState([]);
    const [paginaAtual, setPaginaAtual] = useState(0);
    const [isPrinting, setIsPrinting] = useState(false);
    const [isPdfing, setIsPdfing] = useState(false);
    const [exportDocumentType, setExportDocumentType] = useState(null);
    const [styleSave1, setStyleSave1] = useState();
    const [styleSave2, setStyleSave2] = useState();
    const documentId = 'pdfable';
    const filename = title?.replace(/ /g, '_').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const minimapRef = useRef(null);
    const saveContainerRef = useRef(null);
    const scrollableRef = useRef(null);
    const toolboxRef = useRef(null);
    const containerRef = useRef(null);
    const pageHeightInPixel = 1122.52; // height of the page in mm
    //#endregion

    //#region FUNCOES
    // Função para exportar a página para PDF
    const exportDocument = (type = 'PDF') => {
        //#region execução
        setExportDocumentType(type);
        setIsPdfing(true);
        //#endregion
    };
    // Função para exportar a página para PDF
    function pdf() {
        //#region variaveis
        // Ajustar a altura da div antes de exportar
        const previousHeight = scrollableRef.current.style.height;
        const previousScrollWidth = scrollableRef.current.style.width;
        const previousShadow = scrollableRef.current.style.boxShadow;
        const previousBorder = scrollableRef.current.style.border;
        const previousConHeight = containerRef.current.style.height;
        const previousWidth = containerRef.current.style.width;
        const previousMargin = containerRef.current.style.margin;
        //#endregion
        //#region execução
        scrollableRef.current.style.height = 'auto';
        scrollableRef.current.style.width = larguraPagina + 'mm';
        scrollableRef.current.style.boxShadow = 'none';
        scrollableRef.current.style.border = 'none';
        containerRef.current.style.height = 'auto';
        containerRef.current.style.width = 'auto';
        containerRef.current.style.margin = '0';

        const element = document.getElementById(documentId);
        const newOptions = {
            jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' },
            html2canvas: { scale: 2, useCORS: true, logging: true },
            image: { type: 'jpeg', quality: 0.98 },
            output: `${filename}.pdf`
        };

        html2PDF(element, newOptions);
        scrollableRef.current.style.height = previousHeight;
        scrollableRef.current.style.width = previousScrollWidth;
        scrollableRef.current.style.boxShadow = previousShadow;
        scrollableRef.current.style.border = previousBorder;
        containerRef.current.style.height = previousConHeight;
        containerRef.current.style.width = previousWidth;
        containerRef.current.style.margin = previousMargin;
        setIsPdfing(false);
        //#endregion
    }
    // Função para imprimir a página
    const print = () => {
        // Salvar as configurações originais
        const previousScrollableStyle = { ...scrollableRef.current.style };
        const previousContainerStyle = { ...containerRef.current.style };
        setStyleSave1(previousScrollableStyle);
        setStyleSave2(previousContainerStyle);

        // Remover bordas, margens e sombreamentos
        scrollableRef.current.style.border = 'none';
        scrollableRef.current.style.margin = '0';
        scrollableRef.current.style.boxShadow = 'none';
        containerRef.current.style.margin = '0';
        containerRef.current.style.boxShadow = 'none';
        toolboxRef.current.style.display = 'none';

        // Definir width como auto e overflowY como visible
        scrollableRef.current.style.width = 'auto';
        scrollableRef.current.style.overflowY = 'visible';

        // Restante do código...
        setIsPrinting(true);
    };
    // Função para calcular as páginas
    const calculaPaginas = () => {
        if (saveContainerRef.current) {
            saveContainerRef.current.style.height = 'auto'; // Garantir que a altura é recalculada
            saveContainerRef.current.style.display = 'block'; // Garantir que a altura é recalculada
        }
        const containerHeight = 670;
        let tempPages = [];
        let tempMinimapPages = [];

        if (Array.isArray(content)) {
            // Content is an array
            for (let [index, line] of content.entries()) {
                let tempContent = '';
                let lastTitleIndex;
                let currentHeight = 0;

                const words = line.content.split(' ');
                for (let word of words) {
                    tempContent += `${word} `;
                    if (saveContainerRef.current) {
                        saveContainerRef.current.innerHTML = tempContent;
                        currentHeight = saveContainerRef.current.scrollHeight;
                    }

                    if (currentHeight > containerHeight) {
                        // Remove a última palavra para adicionar na próxima página
                        tempContent = tempContent.trim();
                        const lastSpaceIndex = tempContent.lastIndexOf(' ');
                        const pageContent = tempContent.substring(0, lastSpaceIndex);
                        const brContent = pageContent.trim().split('</br>');
                        const newBrContent = <div>
                            {brContent.map((content) => {
                                return <>
                                    {content}<br></br><br></br>
                                </>
                            })}
                        </div>;
                        tempContent = tempContent.substring(lastSpaceIndex + 1);
                        if (lastTitleIndex !== index) {
                            tempPages.push(
                                <div>
                                    <h4>{line.title}</h4>
                                    {newBrContent}
                                </div>
                            );
                            tempMinimapPages.push(
                                <div>
                                    <h4 style={minimapStyles.thumbnailSubTitle}>{line.title}</h4>
                                    {brContent.map((content) => {
                                        return <>
                                            {content}<br></br>
                                        </>
                                    })}
                                </div>
                            );
                            lastTitleIndex = index;
                        } else {
                            tempPages.push(newBrContent);
                            tempMinimapPages.push(pageContent.trim());
                        }

                        // Redefinir o container com a palavra restante
                        if (saveContainerRef.current) {
                            saveContainerRef.current.innerHTML = tempContent;
                        }
                    }
                }
                if (lastTitleIndex !== index) {
                    const brContent = tempContent.trim().split('</br>');
                    const newBrContent = <div>
                        {brContent.map((content) => {
                            return <>
                                {content}<br></br><br></br>
                            </>
                        })}
                    </div>;
                    tempPages.push(
                        <div>
                            <h4>{line.title}</h4>
                            {newBrContent}
                        </div>
                    );
                    tempMinimapPages.push(
                        <div>
                            <h4 style={minimapStyles.thumbnailSubTitle}>{line.title}</h4>
                            {tempContent.trim()}
                        </div>
                    );
                } else {
                    tempPages.push(tempContent.trim());
                    tempMinimapPages.push(tempContent.trim());
                }
            }
        } else {
            // Content is a string
            let tempContent = '';
            let currentHeight = 0;

            const words = content.split(' ');
            for (let word of words) {
                tempContent += `${word} `;
                if (saveContainerRef.current) {
                    saveContainerRef.current.innerHTML = tempContent;
                    currentHeight = saveContainerRef.current.scrollHeight;
                }

                if (currentHeight > containerHeight) {
                    // Remove a última palavra para adicionar na próxima página
                    tempContent = tempContent.trim();
                    const lastSpaceIndex = tempContent.lastIndexOf(' ');
                    const pageContent = tempContent.substring(0, lastSpaceIndex);
                    const brContent = pageContent.trim().split('</br>');
                    const newBrContent = <div>
                        {brContent.map((content) => {
                            return <>
                                {content}<br></br><br></br>
                            </>
                        })}
                    </div>;
                    tempContent = tempContent.substring(lastSpaceIndex + 1);

                    tempPages.push(newBrContent);
                    tempMinimapPages.push(newBrContent);

                    // Redefinir o container com a palavra restante
                    if (saveContainerRef.current) {
                        saveContainerRef.current.innerHTML = tempContent;
                    }
                }
            }
            tempPages.push(tempContent.trim());
            tempMinimapPages.push(tempContent.trim());
        }
        saveContainerRef.current.style.display = 'none';
        setMinimapPages(tempMinimapPages);
        setPages(tempPages);
    };
    const goToPage = (pageIndex) => {
        const newPage = pageIndex;
        if (newPage !== paginaAtual) {
            let newScroll = (newPage * pageHeightInPixel) + 20;
            scrollableRef.current.scrollTo({ top: newScroll, behavior: 'smooth' });
        }
    };
    const syncScroll = () => {
        const tamanhoGrande = scrollableRef?.current?.scrollHeight;
        const tamanhoPequeno = scrollableRef?.current?.clientHeight;
        const proporcao = tamanhoPequeno / tamanhoGrande;
        const scrollTop = scrollableRef?.current?.scrollTop;
        minimapRef.current.scrollTop = scrollTop * (proporcao * 0.75);
    }

    //#endregion

    //#region HANDLES
    // Função para navegar entre as páginas
    const handleNavigationButton = (event) => {
        let newPage = paginaAtual;
        if (event.key === 'ArrowUp' || event.key === 'PageUp') {
            event.preventDefault();
        }
        if (event.key === 'ArrowUp' || event.key === 'PageUp' || event === 'up') {
            newPage--;
        } else if (event.key === 'ArrowDown' || event.key === 'PageDown' || event === 'down') {
            newPage++;
        }
        goToPage(newPage);
    };
    // Função para atualizar a página atual baseado no scroll
    const handleScroll = () => {
        const { scrollTop } = scrollableRef.current;
        const currentPage = Math.floor(scrollTop / (pageHeightInPixel - 30));
        syncScroll();
        setPaginaAtual(currentPage);
    }
    // Função para mudar a página atual
    const handleChangePage = (event) => {
        const newPage = parseInt(event.target.value) - 1;
        goToPage(newPage);
    };
    const handleThumbnailClick = (pageIndex) => {
        goToPage(pageIndex);
    };
    //#endregion

    //#region USE EFFECTS
    // Calcula as páginas
    useEffect(() => {
        if (content) {
            setPages([]);
            calculaPaginas();
        }
    }, [content]);
    useEffect(() => {
        if (isPdfing) {
            switch (exportDocumentType) {
                case 'pdfable':
                    pdf();
                    break;
                default:
                    break;
            }
        }
    }, [isPdfing]);
    // Imprime a página
    useEffect(() => {
        if (isPrinting) {
            const printOptions = {
                silent: true, // Print without showing the print dialog
                printBackground: true, // Print background colors and images
                headers: false, // Disable headers
                margin: 'none'
            };
            window.print(printOptions);
            setIsPrinting(false);
            scrollableRef.current.style.border = styleSave1.border;
            scrollableRef.current.style.margin = styleSave1.margin;
            scrollableRef.current.style.boxShadow = styleSave1.boxShadow;
            containerRef.current.style.margin = styleSave2.margin;
            containerRef.current.style.boxShadow = styleSave2.boxShadow;
            containerRef.current.style.width = styleSave2.width;
            scrollableRef.current.style.width = styleSave1.width;
            scrollableRef.current.style.overflowY = 'auto';
            toolboxRef.current.style.display = 'flex';
            setStyleSave1();
            setStyleSave2();
        }
    }, [isPrinting]);
    // Adiciona o event listener para as teclas de navegação
    useEffect(() => {
        window.addEventListener('keydown', handleNavigationButton);
        return () => {
            window.removeEventListener('keydown', handleNavigationButton);
        };
    }, []);
    //#endregion
    return (
        <div ref={containerRef} id='gera-visualiza-documento' style={{
            ...styles.container, ...modalMode === true ? {
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1000,
            } : {}
        }}>
            <div ref={toolboxRef} style={styles.toolbox}>
                <div style={styles.functions}>
                    <button type='button' className='btn-icone hl-laranja' style={styles.functions.pdfButton} onClick={() => exportDocument('pdfable')} placeholder="Baixar o documento em PDF.">
                        <BsDownload size={30} />
                    </button>
                    {/* <Button type='button' style={styles.pdfButton} onClick={() => print()}>Imprimir</Button> */}
                </div>
                <div style={styles.informations}>
                    <div style={styles.informations.documentoAberto}>
                        Documento: {filename + '.pdf'}
                    </div>
                    <div style={styles.informations.actualPage}>
                        Página <input type='text' style={styles.informations.pageInput} value={paginaAtual + 1} onChange={(e) => handleChangePage(e)} onFocus={(e) => e.target.select()} /> / {pages?.length}
                    </div>
                </div>
                <p style={styles.fechar}>
                    <button type='button' className='hl-vermelho' style={styles.fechar.botaoFechar} onClick={() => {
                        if (onCloseClick !== null) {
                            onCloseClick();
                        } else {
                            window.close();
                        }
                    }}>
                        X
                    </button>
                </p>
            </div>
            <div style={styles.overScroll}>
                <Minimap minimapRef={minimapRef} pages={minimapPages} onThumbnailClick={handleThumbnailClick} currentPage={paginaAtual} />
                <div ref={scrollableRef} id='pdfable' style={{ ...styles.scroll, ...styles.sombra }} onScroll={handleScroll}>
                    {pages.map((pageContent, index) => {
                        return (
                            <div className={`page page-${index + 1}`} key={index} style={{ ...styles.page, marginBottom: isPdfing ? '0' : '20px' }}>
                                <div style={styles.page.header}>
                                    <img src="/neuro_10_anos-1.png" alt="Logo" style={styles.page.header.logo} />
                                    <div style={styles.page.header.title}>{title}</div>
                                    <p style={styles.page.header.logo}></p>
                                </div>
                                <div style={styles.page.content}>
                                    {pageContent}
                                </div>
                                <div style={styles.page.footer}>
                                    <p>Neuro Sistema © 2024. Todos os direitos reservados.</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div style={styles.navegation}>
                    <div style={styles.navegation.div}>
                        <Button type='button' style={styles.navegationButton} onClick={() => handleNavigationButton('up')}> <BsArrowUp></BsArrowUp> </Button>
                        <Button type='button' style={styles.navegationButton} onClick={() => handleNavigationButton('down')}> <BsArrowDown></BsArrowDown> </Button>
                    </div>
                </div>
            </div>
            <div ref={saveContainerRef} id='savecontainer' style={{ ...styles.page, ...styles.hiddenContent }}></div>
        </div >
    );
};

export default A4Page;