import api from "./api";

class SelectLista {
    /**
     * Construtor para inicializar a classe SelectLista com os parâmetros necessários.
     * @param {String} table Tabela que será usada para criar a lista
     * @param {String} fields Campos que serão usados para mapear. Primeiro é o 'value', depois o 'label' e o resto vem junto.
     * @param {String} select Campos extra adicionados para ajudar na consulta, por exemplo no where. Esses campos não serão retornados. E não pode conflitar com os fields
     * @param {Array} where Array de comparações no MySQL: [ ['campo', 'operador', 'outro_campo'], ...outros ]
     */
    constructor(table, fields, select = null, where = null) {
        if (!table) {
            throw new Error('O parâmetro "table" é obrigatório.');
        }
        if (typeof table !== 'string') {
            throw new Error('O parâmetro "table" deve ser uma string.');
        }
        if (!fields) {
            throw new Error('O parâmetro "fields" é obrigatório.');
        }
        if (typeof fields !== 'string') {
            throw new Error('O parâmetro "fields" deve ser uma string.');
        }
        if (where) {
            if (!Array.isArray(where)) {
                throw new Error('O parâmetro "where" deve ser um array de comparações.');
            }
            where.forEach(condition => {
                if (!Array.isArray(condition) || condition.length !== 3) {
                    throw new Error('Cada condição no parâmetro "where" deve ser um array de três elementos: [\'campo\', \'operador\', \'outro_campo\'].');
                }
            });
        }

        this.table = table;
        this.fields = fields;
        this.select = select;
        this.where = where;
    }

    getTable() {
        return this.table;
    }

    getFields() {
        return this.fields;
    }

    getSelect() {
        return this.select;
    }

    getWhere() {
        return this.where;
    }

    /**
     * Método que retorna uma lista de select. Essa lista terá: value, label e outros campos selecionados.
     * @param {boolean} response Define se será retornada a resposta inteira, ou apenas a data.
     * @returns {Promise} Retorna uma promessa que resolve para a resposta da API ou um erro.
     */
    lista(response = true) {
        if (response) {
            return api.get(`api/select/lista/simples?table=${this.table}&fields=${this.fields}${this.select ? '&select=' + this.select : ''}${this.where ? '&where=' + this.where : ''}`);
        } else {
            return api.get(`api/select/lista/simples?table=${this.table}&fields=${this.fields}${this.select ? '&select=' + this.select : ''}${this.where ? '&where=' + this.where : ''}`).then(res => res.data).catch(err => console.error('Houve um erro ao coletar a listagem.', err));
        }
    }
}

class VariasListas {
    /**
     * Método que retorna uma lista de listas.
     * @param {Array} lista Listagem de listas que recebe os dados para retornar uma listagem de listas: [[]]
     * @param {boolean} response Define se será retornada a resposta inteira, ou apenas a data.
     * @returns {Promise} Retorna uma promessa que resolve para a resposta da API ou um erro.
     */
    static async listaDeListas(lista, response = true) {
        if (response) {
            return api.post('api/lista/lista/simples/', lista);
        } else {
            try {
                const res = await api.post('api/lista/lista/simples/', lista);
                return res.data;
            } catch (err) {
                return console.error('Erro ao coletar alguma das listas.', err);
            }
        }
    }

    /**
     * Método que recebe várias instâncias de Listas e transforma em um array para usar na função listaDeListas.
     * @param {Array} listas Array de instâncias da classe SelectLista.
     * @param {boolean} response Define se será retornada a resposta inteira, ou apenas a data.
     * @returns {Promise} Retorna uma promessa que resolve para a resposta da API ou um erro.
     */
    static getTodasListas(listas, response = true) {
        if (!Array.isArray(listas)) {
            throw new Error('O parâmetro "listas" deve ser um array de instâncias da classe SelectLista.');
        }

        const listaParams = listas.map(listaInstance => {
            if (!(listaInstance instanceof SelectLista)) {
                throw new Error('Todos os elementos do array devem ser instâncias da classe SelectLista.');
            }
            const returnObj = {
                table: listaInstance.getTable(),
                fields: listaInstance.getFields(),
                select: listaInstance.getSelect(),
                where: listaInstance.getWhere()
            };
            return returnObj;
        });

        return this.listaDeListas(listaParams, response);
    }
}

export { SelectLista, VariasListas };