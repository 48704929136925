import { useState } from "react";
import api from "services/api";

const useDiagnosticosData = () => {
    const [diagnosticosOptions, setDiagnosticosOptions] = useState([]);

    const fetchDiagnosticos = async () => {
        try {
            const { data } = await api.get(`api/diagnostico`);
            const options = data.map((diagnostico) => ({
                value: diagnostico.id,
                label: diagnostico.diagnostico
            }));
            setDiagnosticosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar tipo atendimentos:', error);
        }
    };

    return { diagnosticosOptions, fetchDiagnosticos };
};

export default useDiagnosticosData;