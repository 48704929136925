import api from "./api";

class EspecialidadeService {
    static index = (params) => {
        return api.get(`api/especialidade?${params}`);
    }

    static store = (obj) => {
        /* exemplo
        {"especialidade":"delectus"}
        */

        return api.post(`api/especialidade`, obj);
    }

    static show = (id) => {
        return api.get(`api/especialidade/${id}`);
    }

    static update = (obj, id) => {
        /* exemplo
        {"especialidade":"corporis"}
        */

        return api.put(`api/especialidade/${id}`, obj);
    }

    static destroy = (id) => {
        return api.delete(`api/especialidade/${id}`);
    }
}

export default EspecialidadeService;
