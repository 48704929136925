import React, {useMemo} from "react";
import {AiOutlineDelete, AiOutlineEdit} from "react-icons/ai";
import Swal from "sweetalert2";
import ModalidadeService from "../../../../../services/ModalidadeService";
import { TableDefaultPagination } from "../../../../../components/TableDefaultPagination";

export default function TableModalidade({source, callback, editModal}) {

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        ModalidadeService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
      }
    })
  }

  const columns = useMemo(
    () => [
          {
            Header: 'Modalidades:',
            accessor: 'modalidade',
          },
          {
            Header: "Ações",
            accessor: "acoes",
            Cell: ({cell}) => (
              (<div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent"
                }}>
                  <AiOutlineDelete style={{cursor: 'pointer'}} onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22}/>
                  <AiOutlineEdit style={{cursor: "pointer"}} onClick={() => editModal(cell.row.original)} color="#7340BD" size={22}/>
                </div>
              )
            )
          }
        ],
    []
  )

  return (
    <TableDefaultPagination columns={columns} source={source}/>
  )
}
