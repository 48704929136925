import styled from 'styled-components';

export const TableCelulaHeader = styled.th`
  background-color: #7340BD;
  color: #FFFFFF;
  font-family: Nunito, sans-serif;
  border: none;
  padding: 10px 0;
  width: 100px;
  text-align: center;
  border-radius: 10px;
`;

export const Table = styled.table`
  border-spacing: 30px 5px;
  width: 100%;
`;

export const TableCelula = styled.td`
  background-color: #fff;
  padding: 0px 0px 0px 5px;
  box-shadow: 0 0 3px 1px silver;
  width: 100px;
  height: 40px;
  font-size: 14px;
  border-radius: 10px;
  border-color: #F5F7FA;
  border-style: solid;
`;
