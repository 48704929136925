import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AiOutlineForm } from 'react-icons/ai';
import { Form } from '@unform/web';
import { cpf } from 'cpf-cnpj-validator';
import { useAuth } from '../../../../context/auth';
/* SERVICES */
import api from '../../../../services/api';
import Cep from '../../../../services/cep';
import Arquivos from '../../../../services/ArquivosService';
import HorariosAtendimentoService from '../../../../services/HorariosAtendimentoService';
import TerapeutaDiplomaService from '../../../../services/TerapeutaDiplomaService';
import TerapeutaCertificadoService from '../../../../services/TerapeutaCertificadosService';
import TerapeutaEspecialidadeService from '../../../../services/TerapeutaEspecialidadeService';
import TerapeutaTerapiaService from "../../../../services/TerapeutaTerapiaService";
import AssinaturaTerapeutaService from "../../../../services/AssinaturaTerapeutaService";
import Input from '../../../../components/Input';
import InputMask from '../../../../components/InputMask';
import Select from '../../../../components/Select';
import ModalDocumento from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalDocumento';
import { useNavigate, useParams } from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import { TableDocumentosGerais } from '../../../RecepcaoDashboard/Terapeutas/TableDocumentosGerais';
import { TableCursos } from '../../../RecepcaoDashboard/Terapeutas/TableCursos';
import { TableHistoricoDeFerias } from '../../../RecepcaoDashboard/Terapeutas/TableHistoricoDeFerias';
import moment from 'moment';
import { TableDocumentosAssinadosTerapeuta } from '../../../RecepcaoDashboard/Terapeutas/TableDocumentosAssinadosTerapeuta';
import { TableTerapias } from '../../../RecepcaoDashboard/Terapeutas/TableTerapias';
import { TableEspecialidades } from '../../../RecepcaoDashboard/Terapeutas/TableEspecialidades';
import ModalDiplomas from '../../../RecepcaoDashboard/Terapeutas/ModalDiplomas';
import ModalContatos from '../../../RecepcaoDashboard/Terapeutas/ModalContatos';
import { TableContatos } from '../../../RecepcaoDashboard/Terapeutas/TableContatos';
import ModalFerias from '../../../RecepcaoDashboard/Pacientes/Perfil/ModalFerias';
import Swal from 'sweetalert2';
import { TableCertificacoes } from '../../../RecepcaoDashboard/Terapeutas/TableCertificacoes';
import { buttonStyle, Container, subHeader } from '../../../AdminDashboard/Terapeutas/Novo/styles';
import { StatusAfastado, StatusAtivo, StatusFerias, StatusInativo, StatusLiberado, StatusBloqueado } from '../../../AdminDashboard/Pacientes/Perfil/styles';
import { useSnackbar } from '../../../../context/snackbar';
import ModalContratosDeCursos from '../../../RecepcaoDashboard/Terapeutas/ModalContratosDeCursos';
import { TableMedicamentosTerapeuta } from '../../../RecepcaoDashboard/Terapeutas/TableMedicamentosTerapeuta';
import ModalMedicamentosTerapeuta from '../../../RecepcaoDashboard/Terapeutas/ModalMedicamentosTerapeuta';
import ModalAssinaturaTerapeuta from '../../../RecepcaoDashboard/Terapeutas/ModalAssinaturaTerapeuta';
import '../../../../styles/global.css';
import { TableAssinaturasTerapeuta } from '../../../RecepcaoDashboard/Terapeutas/TableAssinaturas';
import Carregamento from '../../../../components/Carregamento';
import { TableCarteiraConselho } from '../../../RecepcaoDashboard/Terapeutas/TableCarteiraConselho';
import ModalCarterinhaConselho from 'pages/RecepcaoDashboard/Terapeutas/ModalCarterinhaConselho';
import useTerapiasData from 'context/terapia';

moment.locale('pt-br');
export default function CadastroPerfilTerapeuta() {
	/*===============================*/
	//#region VARIÁVEIS==============*/
	/*===============================*/
	/*==VARS INFORMAÇÕES da TELA==*/
	const titulo = 'PERFIL TERAPEUTA';
	const rota = '/dashboard/coordenacao/terapeutas/';
	const test_mode = false;
	const usuario = useAuth();
	const { terapiasOptions, fetchTerapias } = useTerapiasData();
	//===VARS MEDICAMENTOS============
	const [medicamentos, setMedicamentos] = useState([]);
	const [medicamentosData, setMedicamentosData] = useState(null);
	//===VARS TERAPIAS================
	const [terapias, setTerapias] = useState([]);
	const [terapiasFiltradas, setTerapiasFiltradas] = useState([]);
	const [minhasTerapias, setMinhasTerapias] = useState([]);
	//===VARS ESPECIALIDADES==========
	const [especialidades, setEspecialidades] = useState(null);
	const [especialidadesFiltradas, setEspecialidadesFiltradas] = useState(null);
	const [minhasEspecialidades, setMinhasEspecialidades] = useState([]);
	//===VARS DIPLOMAS================
	const [diplomas, setDiplomas] = useState([]);
	const [meusDiplomas, setMeusDiplomas] = useState([]);
	const [diplomasFiltrados, setDiplomasFiltrado] = useState(null);
	//===VARS DIPLOMAS================
	const modalAssinaturaRef = useRef(null);
	const [modalAssinaturaData, setModalAssinaturaData] = useState([]);
	const [assinaturaTerapeuta, setAssinaturaTerapeuta] = useState([]);
	const [assinaturaModal, setAssinaturaModal] = useState(false);
	//===VARS CERTIFICAÇÔES===========
	const [certificacoes, setCertificacoes] = useState([]);
	const [minhasCertificacoes, setMinhasCertificacoes] = useState([]);
	const [certificacoesFiltradas, setCertificacoesFiltradas] = useState(null);
	const [loading, setloading] = useState(false);
	//===VARS DISPONIBILIDADES==================
	const diasUteis = [
		{ value: '1', label: 'Segunda-feira' },
		{ value: '2', label: 'Terça-feira' },
		{ value: '3', label: 'Quarta-feira' },
		{ value: '4', label: 'Quinta-feira' },
		{ value: '5', label: 'Sexta-feira' },
		{ value: '6', label: 'Sábado' },
	];
	const [Horarios, setHorarios] = useState([]);
	const [HorariosSelecionados, setHorariosSelecionados] = useState([]);
	const [HorariosSelecionadosOriginais, setHorariosSelecionadosOriginais] = useState([]);
	const valueHoras = [61];
	//===VARS DOCUMENTOS==============
	const [documentosAssinados, setDocumentosAssinados] = useState([]);
	const [documentos, setDocumentos] = useState([]);
	//===VARS CARTERINHAS CONSELHO==============
	const [carterinhasConselho, setCarterinhasConselho] = useState([]);
	//===VARS de FORMULÁRIO===========
	const [dataEntrada, setDataEntrada] = useState(null);
	const [contratosCursos, setContratosCursos] = useState([]);
	const [fotoPerfil, setFotoPerfil] = useState(null);
	//===VARS CONTATOS==================
	const [contatos, setContatos] = useState([]);
	const [modalContatosData, setModalContatosData] = useState(null);
	//===VARS FERIAS==================
	const [feriasList, setFeriasList] = useState([]);
	const [feriasPaciente, setFeriasPaciente] = useState([]);
	//===VARS STATUS==================
	const [statusSelectBox, setStatusSelectBox] = useState([]);
	const [statusSelecionado, setStatusSelecionado] = useState([]);
	const [statusColor, setStatusColor] = useState();
	const [continuar, setContinuar] = useState(false);
	const [terapeutaCarregado, setTerapeutaCarregado] = useState(false);
	//===VARS RESPOSTAS===============
	const snackbar = useSnackbar();
	//===VARS URL INFOS===============
	const navigate = useNavigate();
	const { id } = useParams();
	//===VARS TERAPEUTAS==============
	const [terapeuta, setTerapeuta] = useState(null);
	const [terapeutaId, setTerapeutaId] = useState(id);
	//===VARS LISTAS=================
	const [listaCrachas, setListaCrachas] = useState([]);
	const [listaCPFs, setListaCPFs] = useState([]);
	const [listaEmails, setListaEmails] = useState([]);
	//===VARS DIA ATUAL===============
	let day = new Date(moment().format('MM/DD/YYYY'));
	//===VARS REFS====================
	const formRef = useRef(null);
	const diplomasRef = useRef(null);
	const documentoRef = useRef(null);
	const contatosRef = useRef(null);
	const modalFeriasRef = useRef(null);
	const contratosRef = useRef(null);
	const medicamentosRef = useRef(null);
	const modalCarterinhaConselhoRef = useRef(null);
	//===VARS VALIDADORES====================
	const errorStyle = {
		borderColor: 'rgb(255, 47, 47)',
		color: 'rgb(128, 0, 0)',
	}
	const sucessStyle = {
		borderColor: 'greenyellow',
		color: 'green',
	}
	const [isCPFValid, setIsCPFValid] = useState(false);
	const [isCPFUnico, setIsCPFUnico] = useState(false);
	const [isEmailValid, setIsEmailValid] = useState(false);
	const [isEmailUnico, setIsEmailUnico] = useState(false);
	const [isCrachaValid, setIsCrachaValid] = useState(false);
	const [isSenhasValid, setSenhasValid] = useState(false);
	const [isModified, setIsModified] = useState(true);
	//#endregion
	//===NAVEGADOR de PAGINAS=========
	const NavigatePage = (route) => {
		navigate(route);
	};
	/*===============================*/
	//#region HANDLEs
	//===ASSINATURAS
	const handleOpenModalAssinaturas = (caminho) => {
		setAssinaturaModal(true);
		modalAssinaturaRef.current.openModal(caminho);
	}
	//===CARTEIRINHAS CONSELHO
	const handleOpenModalCarterinhasConselho = (conselho = null) => {
		if (modalCarterinhaConselhoRef.current) {
			modalCarterinhaConselhoRef.current.openModal(conselho, terapiasOptions, carterinhasConselho, terapeutaId, minhasTerapias);
		}
	}
	//===CONTATOS
	const handleEditContatos = useCallback(() => {
		contatosRef.current.openModal();
	}, []);
	const handleOpenContatos = useCallback(() => {
		contatosRef.current.openModal();
		setModalContatosData(null);
	}, []);
	const handleOpenContratos = useCallback(() => {
		contratosRef.current.openModal();
	}, []);
	//===MODAIS UNICAS
	const handleOpenModalDocumento = useCallback(() => {
		documentoRef.current.openModal();
	}, []);
	const handleOpenModalFerias = useCallback(() => {
		modalFeriasRef.current.openModal();
	}, []);
	const handleOpenMedicamentos = useCallback(() => {
		medicamentosRef.current.openModal();
	}, []);
	//===STAUS=====\\
	const handleChangeStatus = (e) => {
		if (statusSelecionado.value === 'Inativo' && e.value === 'Ativo') {
			let cracha = document.getElementById('n_cracha');
			validaCracha(cracha.value, terapeutaId, true);
		}
		setStatusSelecionado(e);
	}
	//===TRATAMENTO IMAGEM PERFIL=====\\
	const handleChangePerfil = (e) => {
		/* CONSTANTES */
		const file = e.target.files[0];
		/* VARIÁVEIS */
		let ext = ['jpg', 'png', 'jpeg', 'img'];
		let inputImg = document.querySelector("#image");
		/* Verifica a exetensão do arquivo */
		if (Arquivos.validaArquivo(ext, 5242880, file)) {
			const reader = new FileReader();
			reader.addEventListener("load", (e) => {
				const readerTarget = e.target;
				inputImg.src = readerTarget.result;
			});
			reader.readAsDataURL(file);
			setFotoPerfil(file);
		} else {
			inputImg.value = '';
		}
		e.preventDefault();
	}
	//#endregion
	//===STATUS=======================\\
	const getDefaultStatus = (statusTerapeuta) => {
		if (!terapeuta) return;
		const status = statusTerapeuta.filter(data => {
			return data.value === terapeuta.status;
		})[0];
		setStatusSelecionado(status);
	};
	//===TERAPIAS====================
	const filterOutMinhasTerapias = (listaTerapias, listaMinhasTerapias) => {
		const idsMinhasTerapias = listaMinhasTerapias.map((t) => t.terapia_id);
		const terapiasFiltradas = listaTerapias.filter((item) => {
			return !idsMinhasTerapias.includes(item.id)
		})
		return terapiasFiltradas;
	}
	const handleRemoveTerapia = async (terapia) => {
		if (terapeuta && terapeuta.id) {
			const data = {
				terapeutaterapium: terapia.id,
			}
			await TerapeutaTerapiaService.destroy(data, terapia.id).then(e => {
				snackbar.displayMessage('Terapia removida com sucesso!', 'success');
				setMinhasTerapias((current) => current.filter((e) => e.terapia_id !== terapia.terapia_id))
			}).catch(e => {
				snackbar.displayMessage('Houve um problema a excluir a terapia!', 'error');
				console.error(e);
			});
		} else {
			setMinhasTerapias((current) => current.filter((e) => e.terapia_id !== terapia.terapia_id))
		}
	}
	const handleAddNewTerapia = async (terapia, label = null) => {
		if (terapia.terapeuta_id) {
			try {
				let terapiaLocal = (terapia.terapia !== undefined) ? { ...terapia.terapia, terapeuta_id: terapia.terapeuta_id } : terapia;
				await Arquivos.store(terapiaLocal, `api/terapeuta-terapia`, 'file')
					.then(({ data }) => {
						setMinhasTerapias([...minhasTerapias, { ...data, terapium: { terapia: label } }]);
						snackbar.displayMessage('Terapia cadastrada com sucesso!', 'success');
					});
			} catch (error) {
				Swal.fire('Houve um problema ao cadastrar!', '', 'error');
				console.error(error);
			}
		} else {
			setMinhasTerapias([...minhasTerapias, { terapia, terapium: { terapia: label } }]);
		}
	}
	//================================
	//===ESPECIALIDADES===============
	//================================
	const filterOutMinhasEspecialidades = (listaEspecialidades, listaMinhasEspecialidades) => {
		const idsMinhasEspecialidades = listaMinhasEspecialidades.map((t) => t.especialidade_id);
		const especialidadesFiltradas = listaEspecialidades.filter((item) => {
			return !idsMinhasEspecialidades.includes(item.id)
		})
		return especialidadesFiltradas;
	}
	const handleAddNewEspecialidade = async (especialidade, label = null) => {
		if (especialidade.terapeuta_id) {
			try {
				await Arquivos.store(especialidade, `api/terapeuta-especialidade`, 'file')
					.then(({ data }) => {
						setMinhasEspecialidades([...minhasEspecialidades, { ...data, especialidade: { especialidade: label } }]);
						snackbar.displayMessage('Especialidade cadastrada com sucesso!', 'success');
					});
			} catch (error) {
				Swal.fire('Houve um problema ao cadastrar a Especialidade!', '', 'error');
				console.error(error);
			};
		} else {
			setMinhasEspecialidades([...minhasEspecialidades, { ...especialidade, especialidade: { especialidade: label } }]);
		}
	}
	const handleRemoveEspecialidade = (especialidade) => {
		Swal.fire({
			title: 'Deseja realmente remover esta especialidade?',
			showDenyButton: true,
			confirmButtonText: 'Remover',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				if (terapeutaId) {
					const data = {
						terapeutaespecialidade: especialidade.id,
					};
					TerapeutaEspecialidadeService.destroy(data, especialidade.id).then(e => {
						snackbar.displayMessage('Especialidade removida com sucesso!', 'success');
						setMinhasEspecialidades((current) => current.filter((e) => e.id !== especialidade.id));
					}).catch(e => {
						snackbar.displayMessage('Houve um problema a excluir a especialidade!', 'error');
						console.error(e);
					});
				} else {
					setMinhasEspecialidades((current) => current.filter((e) => e.especialidade_id !== especialidade.especialidade_id));
				};
			}
		})
	};
	//================================
	//===DIPLOMAS=====================
	//================================
	const handleOpenModalDiplomas = () => {
		diplomasRef.current.openModal();
	};
	const filterOutMeusDiplomas = (listaDiplomas, listaMeusDiplomas) => {
		const idMeusDiplomas = listaMeusDiplomas.map((t) => t.id);
		const diplomasFiltrados = listaDiplomas.filter((item) => {
			return !idMeusDiplomas.includes(item.id)
		});
		return diplomasFiltrados;
	};
	const handleAddNewDiploma = async (diploma, label = null) => {
		if (terapeuta && terapeuta.id) {
			try {
				await TerapeutaDiplomaService.store(diploma).then(({ data }) => {
					setMeusDiplomas([...meusDiplomas, { ...data, nome: label }]);
					snackbar.displayMessage('Diploma cadastrado com sucesso!', 'success');
				})
			} catch (error) {
				Swal.fire('Houve um problema ao cadastrar!', '', 'error');
				console.error(error);
			}
		} else {
			setMeusDiplomas([...meusDiplomas, diploma]);
		};
	};
	const handleRemoveDiploma = async (diploma) => {
		if (terapeuta && terapeuta.id) {
			const data = {
				diplomaterapeuta: diploma.id,
			}
			await TerapeutaDiplomaService.destroy(data, diploma.id).then(() => {
				setMeusDiplomas((current) => current.filter((e) => e.diploma.id !== diploma.id))
			})
		} else {
			setMeusDiplomas((current) => current.filter((e) => e.diploma.id !== diploma.id))
		};
	};
	//================================
	//===CERTIFICAÇÕES================
	//================================
	const filterOutMeusCertificados = (listaCertificados, listaMeusCertificados) => {
		const idMeusCertificados = listaMeusCertificados.map((t) => t.id);
		const certificadosFiltrados = listaCertificados.filter((item) => {
			return !idMeusCertificados.includes(item.id)
		});
		return certificadosFiltrados;
	};
	const handleAddNewCertificacao = async (certificado, label = null) => {
		if (certificado.terapeuta_id) {
			try {
				await Arquivos.store(certificado, `api/terapeuta-certificacao-adicional`, 'file')
					.then(({ data }) => {
						setMinhasCertificacoes([...minhasCertificacoes, { ...data, nome: label }]);
						snackbar.displayMessage('Certificação cadastrada com sucesso!', 'success');
					}).catch(e => {
						snackbar.displayMessage('Houve um problema a incluir a certificação', 'error');
					});
			} catch (error) {
				Swal.fire('Houve um problema ao cadastrar!', '', 'error');
				console.error(error);
			}
		} else {
			setMinhasCertificacoes([...minhasCertificacoes, certificado]);
		};
	};
	const handleRemoveCertificado = (certificado) => {
		Swal.fire({
			title: 'Deseja realmente remover esta especialidade?',
			showDenyButton: true,
			confirmButtonText: 'Remover',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				if (terapeutaId) {
					TerapeutaCertificadoService.destroy(certificado.id).then(e => {
						snackbar.displayMessage('Certificado removido com sucesso!', 'success');
						setMinhasCertificacoes((current) => current.filter((e) => e.id !== certificado.id))
					}).catch(e => {
						snackbar.displayMessage('Houve um problema a excluir o certificado!', 'error');
						console.error(e);
					});
				} else {
					let certificacoesLocal = minhasCertificacoes;
					certificacoesLocal.splice(certificacoesLocal.indexOf(certificado), 1);
					setMinhasCertificacoes(certificacoesLocal);
				};
			}
		});
	};
	//===CARREGA CEP==================
	const getCep = async (cep) => {
		const { data } = await Cep.getAddressByCep(cep);
		document.getElementById('endereco').value = data.logradouro || '';
		document.getElementById('bairro').value = data.bairro || '';
		document.getElementById('estado').value = data.uf || '';
		document.getElementById('cidade').value = data.localidade || '';
	}
	//================================\\
	//#region SUBMIT==================\\
	//================================\\
	async function handleSubmit(data) {
		setloading(true);
		try {
			if (isSenhasValid) {
				if (isCPFValid) {
					if (isCrachaValid) {
						if (isEmailValid) {
							const dataTerapeuta = {
								'n_cracha': data.n_cracha,
								'id': id ? id : null,
								'numero': data.numero,
								'nome': data.nome,
								'nome_curto': data.nome_curto,
								'email': data.email,
								'nascimento': data.nascimento.split('/').reverse().join('-') + ' 00:00:00',
								'cpf': data.cpf.replaceAll('.', '').replace('-', ''),
								'rg': data.rg,
								'telefone1': data.telefone1,
								'telefone2': data.telefone2,
								'telefone3': data.telefone3,
								'endereco': data.endereco,
								'bairro': data.bairro,
								'cidade': data.cidade,
								'estado': data.estado,
								'status': statusSelecionado.label,
								'complemento': data.complemento,
								'cep': data.cep,
								'caminho_curriculo': '',
								'senha': data.senha,
								'data_entrada': dataEntrada,
								'arquivo': fotoPerfil
							};
							if (id) {
								if (!test_mode) {
									await atualizaTerapeuta(dataTerapeuta).then(() => {
										if (continuar) {
											setTerapeutaCarregado(false);
											carregaTerapeuta();
										}
										else {
											navigate('/dashboard/' + usuario.user.tipo_user.toLowerCase() + '/terapeutas');
										}
									});
								}
							} else {
								if (!test_mode) {
									await adicionarNovoTerapeuta(dataTerapeuta).then(() => {
										navigate('/dashboard/' + usuario.user.tipo_user + '/terapeutas');
									});
								}
							}
						} else {
							let text = !isEmailUnico ? 'O email informado já está cadastrado no sistema, por favor, verifique o valor e tente novamente.' : 'O email informado não é conciderado um email valido, por favor, verifique o valor e tente novamente.';
							Swal.fire('Email não é valido!', text, 'error');
						}
					} else {
						Swal.fire('Número de Crachá já cadastrado!', 'O número de crachá informado já está cadastrado no sistema, por favor, verifique o valor e tente novamente.', 'error');
					}
				} else {
					let text = !isCPFUnico ? 'O CPF informado já está cadastrado no sistema, por favor, verifique o valor e tente novamente.' : 'O CPF informado não é conciderado um CPF valido, por favor, verifique o valor e tente novamente.';
					Swal.fire('CPF não é valido!', text, 'error');
				}
			} else {
				Swal.fire('As senha não correspondem', 'A senha do usuário não corresponde à confirmação. Por favor, veririque as senhas digitadas, e tente novamente.', 'error');
			}
		} catch (error) {
			console.error(error);
		} finally {
			setloading(false);
		}
	}
	async function adicionarNovoTerapeuta(data) {
		let chave = data.foto_perfil ? 'arquivo' : null;
		await Arquivos.store(data, 'api/terapeuta', chave)
			.then(async ({ data }) => {
				const horarios = HorariosSelecionados.map((disponibilidade) =>
				({
					dia_semana: disponibilidade.dia_semana,
					horario: disponibilidade.horario.split('T')[1].split(".")[0],
					terapeuta_id: data.id,
				}));
				const novosCertificados = minhasCertificacoes.filter((e) => !e.terapeuta_id);
				const novasTerapias = minhasTerapias.filter((e) => !e.terapeuta_id);
				const novasEspecialidades = minhasEspecialidades.filter((e) => !e.terapeuta_id);
				const terapeutaId = data.id;
				const todos = {
					contatos: contatos,
					ferias: feriasList,
					documentos: documentos,
					horarios: horarios,
					certificacoes: novosCertificados,
					especialidades: novasEspecialidades,
					terapias: novasTerapias.map((e) => (e.terapia)),
				};
				await api.post('api/terapeuta/cadastra-tudo-terapeuta/' + terapeutaId, todos)
					.then(() => {
						snackbar.displayMessage('Especialidades, terapias e certificações cadastradas com sucesso!', 'success');
					}).catch((e) => {
						Swal.fire('Houve um erro ao cadastrar as especialidades, terapias e certificações!', 'Edite o terapeuta, para conferir a persistência dos dados. Reporte ao setor de desenvolvimento.', 'error');
						console.error(e);
					});


				if (assinaturaTerapeuta.length > 0) {
					assinaturaTerapeuta.forEach(async (documento) => {
						let dadosInclusao = { file: documento.assinatura, terapeuta_id: terapeutaId, status: 1 };
						await Arquivos.store(dadosInclusao, `api/assinatura-terapeuta`, 'file')
							.catch(e => {
								snackbar.displayMessage('Houve um erro ao cadatrar a assinatura, contate o suporte.', 'error');
								console.error(e);
							})
					});
				}
			});
	}
	async function atualizaTerapeuta(data) {
		data = await Arquivos.update(data, `terapeuta`, 'arquivo', fotoPerfil, false)
			.then(() => {
				snackbar.displayMessage('Dados do Terapeuta Atualizados!', 'success');
			}).catch((error) => {
				console.error(error);
				snackbar.displayMessage('Um erro ocorreu ao atualizar os dados!', 'error');
			});
		const terapeutaId = id;
		const horariosInseridosNovos = HorariosSelecionados.filter((horario) => {
			return horario.id === '';
		});
		const horariosOriginaisDeletados = HorariosSelecionadosOriginais.filter((horarioOriginal) => {
			return !HorariosSelecionados.find((horarioSelecionado) => {
				return horarioSelecionado.id === horarioOriginal.id;
			});
		});
		const arrayIdsDisponibilidadeParaDeletar = horariosOriginaisDeletados.map((horarioParaDeletar) => {
			return horarioParaDeletar.id;
		});
		if (arrayIdsDisponibilidadeParaDeletar.length > 0) {
			api.delete(`api/terapeuta-disponibilidade/from-ids?ids=${arrayIdsDisponibilidadeParaDeletar.join(',')}`);
		}
		horariosInseridosNovos.forEach((horario) => {
			api.post('api/terapeuta-disponibilidade', {
				terapeuta_id: horario.terapeuta_id,
				horario: horario.horario.split('T')[1].split('.')[0],
				dia_semana: Number(horario.dia_semana),
			}).catch((e) => {
				console.error(e);
			});
		});
		if (contatos.length > 0) {
			contatos.forEach((contato) => {
				if (contato.id === null || contato.id === undefined) {
					contato.terapeuta_id = terapeutaId;
					api.post('api/contato-terapeuta', contato);
				}
			});
		}
		if (feriasList.length > 0) {
			feriasList.forEach((ferias) => {
				if (ferias.id === null) {
					ferias.terapeuta_id = terapeutaId;
					api.post('api/terapeuta-ferias', ferias);
				}
			});
		}
		if (documentos.length > 0) {
			documentos.forEach((documento) => {
				if (documento.id === null) {
					documento.id_terapeuta = terapeutaId;
					api.post('api/terapeuta-documentos', documento);
				}
			});
		}
		if (assinaturaTerapeuta.length > 0) {
			assinaturaTerapeuta.forEach((documento) => {
				if (documento.id !== undefined && documento.id !== null) {
					AssinaturaTerapeutaService.update(documento);
				} else {
					documento.terapeuta_id = terapeutaId;
					documento.status = 1;
					Arquivos.store(documento, `api/assinatura-terapeuta`, 'assinatura');
				}
			});
		}
	}
	//#endregion
	//================================
	//===CARREGADOR DE TODOS OS DADOS=
	//================================
	const carregaListas = async () => {
		try {
			fetchTerapias();
			const terapeutas = await api.get('/api/terapeuta').then(({ data }) => data);
			const users = await api.get('/api/user').then(({ data }) => data);
			let nCrachaList = [];
			let cpfList = [];
			let emailList = [];
			terapeutas.forEach((terapeuta) => {
				if (terapeuta.status !== 'Inativo') {
					nCrachaList.push({
						numero: terapeuta.n_cracha,
						id: terapeuta.id,
					});
				}

				cpfList.push({
					cpf: terapeuta.cpf,
					id: terapeuta.id,
				});

			});

			users.forEach((user) => {
				emailList.push({
					email: user.email,
					id: user.id,
				});
			});
			setListaCrachas(nCrachaList);
			setListaCPFs(cpfList);
			setListaEmails(emailList);
		} catch (error) {
			console.error('Erro ao carregar terapeutas:', error);
		}
	};
	/**
	 * Retorna, definindo, todos os dados do terapeuta.
	 * @returns void
	 */
	const carregaTerapeuta = async () => {
		if (id) {
			setloading(true);
			const withQuery = `terapeuta_terapia.terapium,` +
				`terapeuta_especialidades.especialidade,` +
				`terapeuta_diplomas,terapeuta_disponibilidades,` +
				`terapeuta_assinaturas,` +
				`terapeuta_certificacao_adicionals,` +
				`user.documentos_gerais,` +
				`assinatura_terapeutas,contato_terapeuta,` +
				`terapetua_ferias`;
			let { data } = await api.get(`api/terapeuta/${id}?with=${withQuery}`);
			const carterinhasConselho = await api.get(`api/terapeuta-carteira-conselho?filter[terapeuta_id]=${id}`).then(({ data }) => data);
			setDocumentos(data.user.documentos_gerais);
			setCarterinhasConselho(carterinhasConselho);
			validaCPF(data.cpf, data.id);
			validaCracha(data.n_cracha, data.id);
			validaEmail(data.email, data.user_id);
			data = {
				...data,
				nascimento: moment(data.nascimento).format('YYYY-MM-DD'),
				cpf: data.cpf ? data.cpf.toString() : '',
				cep: data.cep ? data.cep.toString() : '',
				rg: data.rg ? data.rg.toString().replace(/\D/g, '') : '',
				telefone2: data.telefone2 ? data.telefone2.toString() : '',
				telefone3: data.telefone3 ? data.telefone3.toString() : '',
				data_entrada: (data.data_entrada) ? data.data_entrada.split('T')[0] : '',
			};
			setSenhasValid(true);
			setDataEntrada(data.data_entrada);
			formRef.current.setData(data);
			setTerapeuta(data);
			setTerapeutaId(data.id);
			if (data.terapeuta_terapia && data.terapeuta_terapia.length > 0) {
				setMinhasTerapias(data.terapeuta_terapia);
			}
			if (data.terapeuta_diplomas && data.terapeuta_diplomas.length > 0) {
				setMeusDiplomas(data.terapeuta_diplomas);
			}
			if (data.terapeuta_especialidades && data.terapeuta_especialidades.length > 0) {
				setMinhasEspecialidades(data.terapeuta_especialidades);
			}
			if (data.documentos_assinado && data.documentos_assinado.length > 0) {
				setDocumentosAssinados(data.documentos_assinado);
			}
			if (data.terapeuta_certificacao_adicionals && data.terapeuta_certificacao_adicionals.length > 0) {
				setMinhasCertificacoes(data.terapeuta_certificacao_adicionals);
			}
			if (data.assinatura_terapeutas && data.assinatura_terapeutas.length > 0) {
				setAssinaturaTerapeuta(data.assinatura_terapeutas);
			}
			if (data.contato_terapeuta && data.contato_terapeuta.length > 0) {
				setContatos(data.contato_terapeuta);
			}
			if (data.terapeuta_disponibilidades && data.terapeuta_disponibilidades.length > 0) {
				setHorariosSelecionados(data.terapeuta_disponibilidades);
				setHorariosSelecionadosOriginais(data.terapeuta_disponibilidades);
			}
			if (data.terapetua_ferias && data.terapetua_ferias.length > 0) {
				setFeriasList(data.terapetua_ferias);
			}
		}
		setloading(false);
	};
	//===DISPONIBILIDADES=============
	/**
	 * Coleta os horarios padrões do banco de dados. Tabela horarios_atendimento.
	 * Retorna para o setHorarios().
	 */
	const getHorariosPadrao = async () => {
		const { data } = await HorariosAtendimentoService.index();
		setHorarios(data);
	};
	/**
	 * Função que seleciona e deseleciona todas as disponibilidades.
	 * @param {*} e Elemento checkbox selecionado
	 */
	const handleChangeSelecionaTodos = async (e) => {
		const horariosCheckBox = document.getElementsByName('check_input');
		let newHorarios = HorariosSelecionados;
		for (let i = 0; i < horariosCheckBox.length; i++) {
			let el = horariosCheckBox[i];
			el.checked = e.target.checked;
			let terapeutaDisponibilidadeObj = {
				id: '',
				terapeuta_id: id ? Number(id) : null,
				horario: el.dataset.hora,
				dia_semana: Number(el.dataset.dia),
			};
			if (el.checked) {
				newHorarios = [...newHorarios, terapeutaDisponibilidadeObj];
			} else {
				newHorarios =
					newHorarios.filter(
						(horarioSelecionado) => horarioSelecionado.horario !== terapeutaDisponibilidadeObj.horario || Number(horarioSelecionado.dia_semana) !== Number(terapeutaDisponibilidadeObj.dia_semana)
					)
			}
		}
		if (e.target.checked === false) {
			var i = 0;
			while (i < horariosCheckBox.length) {
				horariosCheckBox[i].checked = e.target.checked;
				i++;
			};
			i = 0;
		}
		if (e.target.checked === false) {
			var i = 0;
			while (i < horariosCheckBox.length) {
				horariosCheckBox[i].checked = e.target.checked;
				i++;
			};
			i = 0;
		}
		setHorariosSelecionados(newHorarios);
	}
	/**
	 * Função que cria as linhas da lista de disponibilidades baseado na lista de horarios de atendimento coletada na função getHorarioPadrao.
	 * @returns {*} Retorna elementos HTML
	 */
	const geraLinhasDias = () => {
		return diasUteis.map((dia, index) => {
			return (
				<tr key={index + '-dia-' + dia.value}>
					<td className='flex-nowrap' style={{ minWidth: '150px' }}>{dia.label}:</td>
					<td className='d-flex text-left unselectable'>
						{geraLinhasHorarios(dia)}
					</td>
				</tr>
			);
		});
	};
	/**
	 * Função que retorna um booleano do coeficiente de selecionado do dia e hora passado.
	 * @param {Number} dia Dia da semana em numero.
	 * @param {String} hora Horario em texto padrão do SQL.
	 * @returns {boolean}
	 */
	const isHorarioSelecionado = (dia, hora) => {
		const arrayHorarioSelecionado = HorariosSelecionados.find(horario =>
			Number(horario.dia_semana) === Number(dia) && moment.utc(horario.horario).format('HH:mm') === hora);
		return arrayHorarioSelecionado ? true : false;
	}
	/**
	 * Função que retorna elementos HTML das checkboxs das disponibilidades.
	 * @param {Number} dia Dia da semana em numeral.
	 * @returns 
	 */
	const geraLinhasHorarios = (dia) => {
		return Horarios.filter((x) => parseInt(x.dia_semana) === parseInt(dia.value)).map((horario) => {
			let horaExtenso = moment.utc(horario.horario).format('HH:mm');
			const key = dia + '-' + horario.horario;
			return (
				<label key={key} className='mr-20px'>
					<input id={horario.id}
						type={'checkbox'}
						name={'check_input'}
						value={valueHoras[horario.id]}
						checked={isHorarioSelecionado(dia.value, horaExtenso)}
						onChange={(e) => handleChangeHorariosSelecionados(e)}
						data-dia={dia.value}
						data-hora={horario.horario} />
					{horaExtenso}
				</label>);
		});
	};
	const handleChangeHorariosSelecionados = (e) => {
		let terapeutaDisponibilidadeObj = {
			id: '',
			terapeuta_id: id ? Number(id) : null,
			horario: (e.target !== undefined) ? e.target.dataset.hora : e.dataset.hora,
			dia_semana: Number((e.target !== undefined) ? e.target.dataset.dia : e.dataset.dia),
		};
		let test = (e.target !== undefined) ? e.target.checked : e.checked;
		if (test) {
			setHorariosSelecionados([...HorariosSelecionados, terapeutaDisponibilidadeObj]);
		} else {
			setHorariosSelecionados(
				HorariosSelecionados.filter(
					(horarioSelecionado) => horarioSelecionado.horario !== terapeutaDisponibilidadeObj.horario || Number(horarioSelecionado.dia_semana) !== Number(terapeutaDisponibilidadeObj.dia_semana)
				)
			);
		}
	};
	const calculaTempoCasa = () => {
		let today = moment();
		let test = moment(dataEntrada);
		let diff = Number(today.diff(test, 'd'));
		let anos = 0;
		let meses = 0;
		let dias = 0;
		if (diff >= 365) {
			anos = parseInt(diff / 365);
			diff -= anos * 365;
		}
		if (diff >= 30) {
			meses = parseInt(diff / 30);
			diff -= meses * 30;
		}
		dias = parseInt(diff);
		let text = dataEntrada ?
			anos + ' ano(s) e ' +
			meses + ' meses ' +
			dias + ' dias'
			: '0';

		return text;
	}
	const validaCPF = (valorCPF, id) => {
		let isValid = cpf.isValid(valorCPF);
		let isUnico = validaCPFUnico(valorCPF, id);
		setIsCPFUnico(isUnico);
		setIsCPFValid(isValid && isUnico);
	}
	const validaSenhas = (senha = null, confirma = null) => {
		if (senha === null) {
			senha = document.getElementById('senha').value;
		}
		if (confirma === null) {
			confirma = document.getElementById('confirma').value;
		}
		setSenhasValid(senha === confirma);
	}
	const validaEmail = (email, user_id) => {
		let isValid = email ? email.includes('@') : false;
		let isEmailUnico = validaEmailUnico(email, user_id);
		setIsEmailUnico(isEmailUnico);
		setIsEmailValid(isValid && isEmailUnico);
	}
	const validaEmailUnico = (email, user_id) => {
		let isValid = listaEmails.findIndex((e) => user_id !== e.id && email?.toUpperCase() === e.email?.toUpperCase()) === -1;
		return isValid;
	}
	const validaCracha = (cracha, id, notifica = false) => {
		if (cracha === '') return setIsCrachaValid(false);
		let isValid = listaCrachas.findIndex((e) => id !== e.id && cracha === e.numero) === -1;
		setIsCrachaValid(isValid);
		if (!isValid && notifica) {
			Swal.fire('Cracha em uso!', 'Parece que o cracha desse terapeuta, já esta em uso, altere para pode salvar.', 'warning');
		}
	}
	const validaCPFUnico = (cpf, id) => {
		let isValid = listaCPFs.findIndex((e) => id !== e.id && cpf.replace(/\D/g, '') === e.cpf.replace(/\D/g, '')) === -1;
		return isValid;
	}
	/*===============================*/
	//#region USE EFFECTs============*/
	/*===============================*/
	useEffect(() => {
		setIsModified(true);
	}, [contatos, assinaturaTerapeuta, documentos, feriasList]);
	useEffect(() => {/* ON LOAD */
		let statusList = [
			{ label: 'Ativo', value: 'Ativo' },
			{ label: 'Bloqueado', value: 'Bloqueado' },
			{ label: 'Inativo', value: 'Inativo' },
			{ label: 'Afastado', value: 'Afastado' },
			{ label: 'Liberado', value: 'Liberado' },
			{ label: 'Férias', value: 'Ferias' }
		];
		setStatusSelectBox(statusList);
		carregaListas();
		carregaTerapeuta();
		getHorariosPadrao();
		if (usuario) {
			let permissoes = usuario.user.lista_de_permissoes[0].permissions;
			for (let index = 0; index < permissoes.length; index++) {
				const permissao = permissoes[index];
				const nomePermissao = permissao.name.split('.')[0];
				let local = window.location.pathname.split('/')[3];
				if (local === nomePermissao) {
				}
			}
		} else {
			window.location.href = window.location.hostname + '/';
		}
	}, []);
	useEffect(() => {
		getDefaultStatus(statusSelectBox);
		if (terapeuta !== null) {
			if (terapeuta.status === "Ativo") setStatusColor(StatusAtivo);
			else if (terapeuta.status === "Afastado") setStatusColor(StatusAfastado);
			else if (terapeuta.status === "Inativo") setStatusColor(StatusInativo);
			else if (terapeuta.status === "Férias") setStatusColor(StatusFerias);
			else if (terapeuta.status === "Liberado") setStatusColor(StatusLiberado);
			else if (terapeuta.status === "Bloqueado") setStatusColor(StatusBloqueado);
		}
		if (feriasPaciente.length > 0) {
			let index = feriasPaciente.length - 1;
			let data_ida = new Date(feriasPaciente[index].dt_ida);
			let data_volta = new Date(feriasPaciente[index].dt_volta);
			if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
				setStatusSelecionado(statusSelectBox[3]);
			} else {
				setStatusSelecionado(statusSelectBox[0]);
			}
		}
	}, [terapeuta])
	useEffect(() => {
		setFeriasPaciente(feriasList);
		if (feriasList.length > 0) {
			let index = feriasList.length - 1;
			let data_ida = new Date(feriasList[index].dt_ida);
			let data_volta = new Date(feriasList[index].dt_volta);
			if ((day.getTime() <= data_volta.getTime() && day.getTime() >= data_ida.getTime())) {
				setStatusSelecionado(statusSelectBox[3]);
			} else {
				setStatusSelecionado(statusSelectBox[0]);
			}
		}
	}, [feriasList])
	useEffect(() => {
		geraLinhasDias();
	}, [Horarios]);
	useEffect(() => {
		if (especialidades && minhasEspecialidades) {
			const especialidadesFiltradas = filterOutMinhasEspecialidades(especialidades, minhasEspecialidades);
			setEspecialidadesFiltradas(especialidadesFiltradas)
		}
	}, [especialidades, minhasEspecialidades])
	useEffect(() => {
		if (terapias && minhasTerapias) {
			const terapiasFiltradas = filterOutMinhasTerapias(terapias, minhasTerapias);
			setTerapiasFiltradas(terapiasFiltradas)
		}
	}, [terapias, minhasTerapias])
	useEffect(() => {
		if (certificacoes && minhasCertificacoes) {
			const certificadosFiltrados = filterOutMeusCertificados(certificacoes, minhasCertificacoes);
			setCertificacoesFiltradas(certificadosFiltrados);
		}
	}, [certificacoes, minhasCertificacoes])
	useEffect(() => {
		if (diplomas && meusDiplomas) {
			const diplomasFiltrados = filterOutMeusDiplomas(diplomas, meusDiplomas);
			setDiplomasFiltrado(diplomasFiltrados)
		}
	}, [diplomas, meusDiplomas])
	//#endregion
	return (
		<Container>
			{loading && <Carregamento />}
			<div className='d-flex w-100per flex-col pt-70px pb-70px'>
				<Form onSubmit={handleSubmit} ref={formRef} encType='multipart/form-data'>
					{/* FOTO DE PERFIL E STATUS */}
					<div className='d-flex w-100per flex-row justify-between pb-50px'>
						<div className='d-flex flex-col align-items-center justify-center'>
							<img className='border-radius-50per' id='image' src={(terapeuta === null) ? user : (terapeuta.foto_perfil_full === null) ? user : terapeuta.foto_perfil_full} height={140} width={140} alt='Foto de Perfil'></img>
							<div className='d-flex align-center justify-center'>
								<label htmlFor="foto_perfil" className='pointer'>
									<AiOutlineForm style={{ marginRight: 3, marginBottom: 4 }} />
									Alterar foto de Perfil
									<input type='file' name='foto_perfil' id='foto_perfil' className='some' onChange={(e) => handleChangePerfil(e)} />
								</label>
							</div>
						</div>
						<div className='d-flex flex-col'>
							<div className='d-flex flex-row justify-center align-center bg-branco border-radius-8px shadow-1 pb-2px pt-2px pl-15px pr-15px'>
								{terapeuta === null ? "" : <div style={statusColor} />}
								<label className='m-5px'>
									{terapeuta === null ? "" : terapeuta.status}
								</label>
							</div>
						</div>
					</div>
					<div className='col'>
						<div className='row'>
							<div className='col-2'>
								<Input title="ID do Terapeuta:" name="id-t" disabled="disabled" defaultValue={terapeuta === null ? "" : terapeuta.id} className='w-10per' />
							</div>
							<div className='col-1'>
								<Input title="N° do Crachá:" containerStyle={isCrachaValid ? sucessStyle : errorStyle} id="n_cracha" name="n_cracha" className='w-10per'
									onChange={(e) => {
										const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
										e.target.value = valueWithoutSpaces;
										validaCracha(valueWithoutSpaces, terapeuta?.id);
									}} required />
							</div>
							<div className='col-6'>
								<Input title="Nome:" name="nome" required={'required'} />
							</div>
							<div className='col-3'>
								<Input title="Nome Curto" name="nome_curto" />
							</div>
						</div>
						<div className='row'>
							<div className='col-3'>
								<Select name={'status'} label={'Status:'} options={statusSelectBox} onChange={handleChangeStatus} defaultValue={statusSelecionado} value={statusSelecionado} required={'required'} />
							</div>
							<div className='col-3'>
								<Input title="Data de Nascimento:" name="nascimento" type={'date'} required={'required'} max={moment().format('YYYY-MM-DD')} />
							</div>
							<div className='col-3'>
								<InputMask containerStyle={isCPFValid ? sucessStyle : errorStyle} title="CPF" name="cpf" maskFormat={"999.999.999-99"} onChange={(e) => validaCPF(e.target.value, terapeuta?.id)} onFocus={(e) => { e.target.select() }} required={'required'} />
							</div>
							<div className='col-3'>
								<Input title="RG:" name="rg" required={'required'} type={'number'} />
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<Input containerStyle={isEmailValid ? sucessStyle : errorStyle} title="E-mail:" name="email" type='email' required={'required'} onChange={(e) => validaEmail(e.target.value, terapeuta?.user_id)} />
							</div>
							<div className='col-2'>
								<InputMask title="Telefone 01:" name="telefone1" maskFormat={"99 99999-9999"} required={'required'} />
							</div>
							<div className='col-2'>
								<InputMask title="Telefone 02:" name="telefone2" maskFormat={"99 99999-9999"} />
							</div>
							<div className='col-2'>
								<InputMask title="Telefone 03:" name="telefone3" maskFormat={"99 99999-9999"} />
							</div>
						</div>
						<div className='row'>
							<div className='col-2'>
								<InputMask className='p-0' title="CEP" name="cep" maskFormat={"99999-999"} onBlur={(ev) => getCep(ev.target.value)} required={'required'} />
							</div>
							<div className='col-4'>
								<Input title="Rua:" name="endereco" id='endereco' required={'required'} />
							</div>
							<div className='col-2'>
								<Input title="Bairro:" name="bairro" id='bairro' readOnly={true} />
							</div>
							<div className='col-2'>
								<Input title="Cidade:" name="cidade" id='cidade' readOnly={true} />
							</div>
							<div className='col-1'>
								<Input title="Número:" name="numero" id='numero' required={'required'} />
							</div>
							<div className='col-1'>
								<Input title="Estado:" name="estado" id={'estado'} readOnly={true} />
							</div>
						</div>
						<div className='row'>
							<div className='col-5'>
								<Input title="Complemento:" name="complemento" />
							</div>
							<div className='col-2'>
								<Input title="Data de Entrada na Clínica:" name="data_entrada" type={'date'} id={"data_entrada"} required={'required'}
									onChange={e => setDataEntrada(e.target.value)}
								/>
							</div>
							<div className='col-2'>
								<Input title="Data de Saída da Clínica:" name="data_saida" type={'date'}
									disabled={!((statusSelecionado !== null && statusSelecionado.value !== undefined) ? statusSelecionado.value === 'Inativo' : false)}
								/>
							</div>
							<div className='col-3'>
								<Input className='w-15per' title="Tempo de Casa:" name="tempo_casa" value={calculaTempoCasa()} />
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<Input containerStyle={isSenhasValid ? sucessStyle : errorStyle} id="senha" name="senha" onChange={(e) => validaSenhas(e.target.value)} placeholder={terapeutaId === null ? "Crie uma Senha" : 'Redefinir a senha'} type="password" required={terapeutaId === null ? 'required' : ''} title={'Senha de Usuário:'} autoComplete="" />
							</div>
							<div className='col-6'>
								<Input containerStyle={isSenhasValid ? sucessStyle : errorStyle} id="confirmar" name="confirmar" onChange={(e) => validaSenhas(null, e.target.value)} placeholder={"Confirme a senha"} type="password" required={terapeutaId === null ? 'required' : ''} title={'Confirmar Senha de Usuário:'} autoComplete="" />
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='row'>
							<div className='col-12'>
								<label style={subHeader}>Disponibilidades: </label>
							</div>
							<div className='col-12 d-flex align-center justify-content-center pt-20px'>
								<table className='w-100per table-folha-horarios'>
									<thead>
										<tr>
											<th>Dias</th>
											<th>Horário</th>
										</tr>
									</thead>
									<tbody>
										{geraLinhasDias()}
									</tbody>
									<tfoot>
										<tr>
											<th>
												<label htmlFor="seleciona_todos">Selecionar Todos:</label>
											</th>
											<th>
												<input id='seleciona_todos' type="checkbox" onChange={e => handleChangeSelecionaTodos(e)} />
											</th>
										</tr>
									</tfoot>
								</table>
							</div>
						</div>
						<div className='d-flex flex-col pt-30px col-12'>
							<div className="row">
								<div className='col-lg-4 col-md-12'>
									<div className='text-center'>
										<label style={subHeader}> Terapias: </label>
									</div>
									<TableTerapias source={minhasTerapias} removeTerapia={handleRemoveTerapia} setTerapias={setMinhasTerapias} terapeutaId={terapeutaId} callBack={carregaTerapeuta} />
								</div>
								<div className='col-lg-4 col-md-12'>
									<div className='text-center'>
										<label style={subHeader}> Especialidades: </label>
									</div>
									<TableEspecialidades source={minhasEspecialidades} removeEspecialidade={handleRemoveEspecialidade} />
								</div>
								<div className='col-lg-4 col-md-12'>
									<div className='text-center'>
										<label style={subHeader}> Certificações: </label>
									</div>
									<TableCertificacoes source={minhasCertificacoes} removeCertificacao={handleRemoveCertificado} />
								</div>
							</div>
							<div style={buttonStyle}>
								<button className='btn-padrao' onClick={e => handleOpenModalDiplomas()} type="button">Cadastrar</button>
							</div>
						</div>
						<div className='col-12 d-flex flex-col'>
							<label style={subHeader}>Contatos</label>
							<TableContatos source={contatos} setContatos={setContatos} callBack={carregaTerapeuta} terapeutaId={id} handleOpen={handleEditContatos} setModalContatosData={setModalContatosData} />
							<div className='div-center'>
								<button onClick={e => handleOpenContatos()} type="button" className='btn-padrao'> Adicionar </button>
							</div>
						</div>
						<div className={id ? 'd-flex flex-col mb-20px col-lg-6 col-sm-12' : 'd-flex flex-col mb-20px col-lg-12 col-sm-12'}>
							<div className='pt-20px pb-20px'>
								<label style={subHeader}>Cadastro de Assinatura:</label>
							</div>
							<div>
								<TableAssinaturasTerapeuta terapeutaId={terapeutaId} source={assinaturaTerapeuta} setAssinaturaTerapeuta={setAssinaturaTerapeuta} setModalData={setModalAssinaturaData} handleOpen={handleOpenModalAssinaturas} />
							</div>
							{assinaturaTerapeuta.length === 0 &&
								<div className='div-center'>
									<button onClick={e => handleOpenModalAssinaturas()} type="button" className='btn-padrao'> Adicionar Assinatura</button>
								</div>
							}
						</div>
						{id &&
							<>
								<div className='d-flex flex-col mb-20px col-lg-6 col-sm-12'>
									<div className='pt-20px pb-20px'>
										<label style={subHeader}>Documentos Assinados Terapeuta</label>
									</div>
									<TableDocumentosAssinadosTerapeuta source={documentosAssinados}></TableDocumentosAssinadosTerapeuta>
								</div>
								<div className='d-flex flex-col col-6'>
									<div style={{ paddingTop: 20, paddingBottom: 20 }}>
										<label style={subHeader}>Documentos Gerais</label>
									</div>
									<TableDocumentosGerais source={documentos} deleteCallback={carregaTerapeuta} setDocumentos={setDocumentos} terapeutaId={terapeutaId} />
									<div className='div-center'>
										<button onClick={e => handleOpenModalDocumento()} type="button" className='btn-padrao'> Adicionar </button>
									</div>
								</div>
								<div className='d-flex flex-col col-6'>
									<div style={{ paddingTop: 20, paddingBottom: 20 }}>
										<label style={subHeader}>Carterinhas do Conselho</label>
									</div>
									<TableCarteiraConselho source={carterinhasConselho} deleteCallback={carregaTerapeuta} handleEditConselho={handleOpenModalCarterinhasConselho} setConselhos={setCarterinhasConselho} terapiasCadastradas={minhasTerapias} terapeutaId={terapeutaId} terapias={terapiasOptions} />
									<div className='div-center'>
										<button onClick={e => handleOpenModalCarterinhasConselho()} type="button" className='btn-padrao'> Adicionar </button>
									</div>
								</div>
							</>
						}
						{usuario.user.tipo_user.toLowerCase() === 'desenvolvedor' &&
							<>
								<div className='d-flex flex-col col-12'>
									<label style={subHeader}>Contrato de Realização de Cursos(WIP)</label>
									<TableCursos source={contratosCursos} />
									<div className='div-center'>
										<button onClick={e => handleOpenContratos()} type="button" className='btn-padrao'> Adicionar </button>
									</div>
								</div>
								<div className='d-flex flex-col col-12'>
									<label style={subHeader}>Emergências Terapeuta(WIP)</label>
									<div>
										<TableMedicamentosTerapeuta source={medicamentos} setModalMedicamentosData={setMedicamentosData} terapeutaId={terapeutaId} setMedicamentos={setMedicamentos} callBack={carregaTerapeuta} handleOpen={handleOpenMedicamentos} />
									</div>
									<div className='div-center'>
										<button onClick={e => handleOpenMedicamentos()} type="button" className='btn-padrao'> Adicionar </button>
									</div>
								</div>
							</>
						}
						<div className='d-flex flex-col col-sm-12 col-lg-12'>
							<label style={subHeader}> {feriasList.length === 0 ? "Histórico Férias" : "Novos Históricos a serem adicionados"} </label>
							<TableHistoricoDeFerias source={feriasPaciente} feriasList={feriasList} setFeriasList={setFeriasList} />
							<div className='div-center'>
								<button onClick={e => handleOpenModalFerias()} type="button" className='btn-padrao'> Adicionar </button>
							</div>
						</div>
					</div>
					<div className='btn-space-40px d-flex pb-1 text-center justify-content-between position-fixed bottom-0 center-horizontal pt-10px bg-branco border-radius-top-10px shadow-1'>
						<button className='btn-padrao laranja' type="button" onClick={e => NavigatePage('/dashboard/' + usuario.user.tipo_user.toLowerCase() + '/terapeutas')}> Voltar sem salvar </button>
						{id ? <button className='btn-padrao laranja' type="submit" onClick={e => setContinuar(true)}>Salvar e Continuar Editando</button> : ''}
						<button className='btn-padrao laranja' type="submit" onClick={e => setContinuar(false)}> {id ? "Salvar e Sair" : "Cadastrar"} </button>
					</div>
				</Form>
				{/*============MODAIS===========*/}
				<ModalContratosDeCursos ref={contratosRef} cursos={contratosCursos} setCursos={setContratosCursos} callBack={carregaTerapeuta} />
				<ModalFerias ref={modalFeriasRef} feriasList={feriasList} setFeriasList={setFeriasList} api_link='api/terapeuta-ferias' id={terapeutaId} />
				<ModalDocumento ref={documentoRef} documentos={documentos} user_id={terapeuta?.user_id} type='terapeuta' setDocumentos={setDocumentos} />
				<ModalMedicamentosTerapeuta ref={medicamentosRef} modalData={medicamentosData} medicamentos={medicamentos} setMedicamentos={setMedicamentos} />
				<ModalContatos ref={contatosRef} contatos={contatos} modalData={modalContatosData} terapeutaId={terapeutaId} setContatos={setContatos} />
				<ModalDiplomas ref={diplomasRef} terapeuta={terapeuta} minhasEspecialidades={minhasEspecialidades} addNewEspecialidade={handleAddNewEspecialidade} addNewTerapia={handleAddNewTerapia} addNewDiploma={handleAddNewDiploma} addNewCertificado={handleAddNewCertificacao} onClose={() => diplomasRef.current.closeModal()} />
				<ModalAssinaturaTerapeuta ref={modalAssinaturaRef} onClose={() => { setAssinaturaModal(false); }} setAssinaturaTerapeuta={setAssinaturaTerapeuta} terapeutaId={terapeutaId} modalData={modalAssinaturaData} assinaturaTerapeuta={assinaturaTerapeuta} Arquivos={Arquivos} />
				<ModalCarterinhaConselho ref={modalCarterinhaConselhoRef} setConselhos={setCarterinhasConselho} terapeuta_id={terapeutaId} terapias={terapiasOptions} conselhos={carterinhasConselho} />
			</div>
		</Container>
	)
}