import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../services/api';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
const ContatosModal = forwardRef(({ id = 'agendaModal', terapeuta_id }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Contatos do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [contatos, setContatos] = useState([]);

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: '60vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaContatos(id)
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaContatos = (id) => {
        setContatos([]);
        if (id !== undefined && id !== null) {
            setloading(true);
            api.get(`/api/paciente/${id}/contatos`).then(({ data }) => {
                setContatos(data);
                setloading(false);
            })
        }
    }
    //#endregion
    useEffect(() => {/* ONLOAD */

    }, []);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} transparent>
                <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                    <label></label>
                    <label>{title}</label>
                    <label>
                        <button className='btn-icone' onClick={closeModal}>
                            <AiOutlineClose color='red' size={22} />
                        </button>
                    </label>
                </div>
                <div className="d-flex w-100per flex-col">
                    <table className='tabela-generica'>
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Telefone 1</th>
                                <th>Telefone 2</th>
                                <th>E-mail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(contatos.length > 0)
                                ? contatos.map((contato, index) => {
                                    return (
                                        <tr key={`linha-contato-${index}`}>
                                            <td className={(contato.nome) ? contato.nome : 'text-center'}>
                                                {(contato.nome) ? contato.nome : '-'}
                                            </td>
                                            <td className={(contato.telefone1) ? contato.telefone1 : 'text-center'}>
                                                {(contato.telefone1) ? contato.telefone1 : '-'}
                                            </td>
                                            <td className={(contato.telefone2) ? contato.telefone2 : 'text-center'}>
                                                {(contato.telefone2) ? contato.telefone2 : '-'}
                                            </td>
                                            <td className={(contato.email) ? contato.email : 'text-center'}>
                                                {(contato.email) ? contato.email : '-'}
                                            </td>
                                        </tr>
                                    )
                                })
                                : 'Nenhum contato encontrado.'
                            }
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    )
});
export default ContatosModal;