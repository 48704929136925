import React, { useEffect, useRef, useState} from 'react';
import Responsavel from '../../../../layouts/Responsavel';
import {AiOutlineBell, AiOutlineUser} from 'react-icons/ai';
import {buttonBack,  Container, subHeader, MainDivStyle} from './styles';
import {Form} from '@unform/web';
import Input from '../../../../components/Input';
import {Link, useNavigate, useParams} from 'react-router-dom';
import user from '../../../../assets/img_avatar.png';
import api from '../../../../services/api';
import moment from 'moment';
import UsuarioLogado from '../../../../components/UsuarioLogado';
import TerapiumService from '../../../../services/TerapiumService';
import EspecialidadeService from '../../../../services/EspecialidadeService';
import { TableEspecialidades } from './TableEspecialidades';
import { TableTerapias } from './TableTerapias';
import { TableDiplomas } from './TableDiplomas';
import { TableCursos } from './TableCursos';

moment.locale('pt-br');

export default function NovoTerapeuta() {
  
  const [terapeuta, setTerapeuta] = useState(null);
  const [terapias, setTerapias] = useState([]);
  const [terapiasFiltradas, setTerapiasFiltradas] = useState([]);
  const [minhasTerapias, setMinhasTerapias] = useState([]);
  const [especialidades, setEspecialidades] = useState(null);
  const [especialidadesFiltradas, setEspecialidadesFiltradas] = useState(null);
  const [minhasEspecialidades, setMinhasEspecialidades] = useState([]);
  const [minhasCertificações, setMinhasCertificações] = useState([]);
  const [meusDiplomas, setMeusDiplomas] = useState([]);
  const [meusCursos, setMeusCursos] = useState([]);

  const formRef = useRef(null);
  const navigate = useNavigate();
  const {id} = useParams()

  const loadTerapias = async () => {
    TerapiumService.index().then(({data}) => {
      setTerapias(data);
    });
  }

  const loadEspecialidades = async () => {
    EspecialidadeService.index().then(({data}) => {
      setEspecialidades(data);
    });
  }

  const loadTerapeuta = async () => {
    if (id) {
      const withQuery = `terapeuta_terapia.terapium,terapeuta_especialidades.especialidade`

      let { data } = await api.get(`api/terapeuta/${id}?with=${withQuery}`);

      data = {
        ...data,
        nascimento: moment(data.nascimento).format('YYYY-MM-DD'),
        cpf: data.cpf ? data.cpf.toString() : '',
        cep: data.cep ? data.cep.toString() : ''
      };
      formRef.current.setData(data);

      setTerapeuta(data);

      if (data.terapeuta_terapia) {
        setMinhasTerapias(data.terapeuta_terapia);
      }

      if (data.terapeuta_especialidades) {
        setMinhasEspecialidades(data.terapeuta_especialidades);
      }
    }
  };

  useEffect(() => {
    loadTerapeuta();
    loadTerapias();
    loadEspecialidades();
  }, []);

  useEffect(() => {
    if (especialidades && minhasEspecialidades) {
      const especialidadesFiltradas = filterOutMinhasEspecialidades(especialidades, minhasEspecialidades);
      setEspecialidadesFiltradas(especialidadesFiltradas)
    }
  }, [especialidades, minhasEspecialidades])

  useEffect(() => {
    if (terapias && minhasTerapias) {
      const terapiasFiltradas = filterOutMinhasTerapias(terapias, minhasTerapias);
      setTerapiasFiltradas(terapiasFiltradas)
    }
  }, [terapias, minhasTerapias])

  const filterOutMinhasTerapias = (listaTerapias, listaMinhasTerapias) => {
    const idsMinhasTerapias = listaMinhasTerapias.map((t) => t.terapia_id);

    const terapiasFiltradas = listaTerapias.filter((item) => {
      return !idsMinhasTerapias.includes(item.id)
    })

    return terapiasFiltradas;
  }

  const filterOutMinhasEspecialidades = (listaEspecialidades, listaMinhasEspecialidades) => {
    const idsMinhasEspecialidades = listaMinhasEspecialidades.map((t) => t.especialidade_id);

    const especialidadesFiltradas = listaEspecialidades.filter((item) => {
      return !idsMinhasEspecialidades.includes(item.id)
    })

    return especialidadesFiltradas;
  }


  return (
      <Responsavel>
        <Container>
          <div style={MainDivStyle}>
            <div>
                <Link
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 20,
                  }}
                  to="/dashboard/responsavel/terapeutas"><label style={{
                fontFamily: "Nunito, sans-serif",
                cursor: "pointer",
                color: "#000000"
              }}>Terapeutas</label></Link>

              <label style={{
                fontFamily: "Nunito, sans-serif",
                color: "#7340BD",
                fontWeight: "bold",
                cursor: "pointer"
              }}>Currículo do Terapeuta</label>
            </div>
            <div style={{display: "flex"}}>
              <AiOutlineBell color="silver" size={20}/>
              <AiOutlineUser color="silver" size={20}/>
              <div>
                <UsuarioLogado />
              </div>
            </div>
          </div>
          <div style={{display: "flex", width: "100%", flexDirection: "column", padding: "0 70px"}}>
            <div style={{
              display: "flex",
              width: "100%", 
              justifyContent: "center", 
              alignContent: "center",
              paddingBottom: 50}}>
              <div>
                <img style={{borderRadius: '50%'}} src={user} height={140}></img>
              </div>
            </div>
            <Form ref={formRef}>
              <div style={{width: "100%"}}>
                <div style={{display: "flex"}}>
                  <div style={{width: "10%", paddingRight: 10}}>
                    <Input title="Número:" name="id" disabled="disabled"/>
                  </div>
                  <div style={{width: "50%", paddingRight: 10}}>
                    <Input title="Nome:" name="nome" disabled="disabled"/>
                  </div>
                  <div style={{width: "40%", paddingRight: 10}}>
                    <Input title="Nome Curto" name="nome_curto" disabled="disabled"/>
                  </div>
                </div>
              </div>
              <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{paddingTop: 20, paddingBottom: 20, textAlign: "center"}}>
                  <label style={subHeader}>Diplomas,
                    Certificações e Especialidades</label>
                </div>
                <div style={{paddingTop: 20, paddingBottom: 20, textAlign: "center"}}>
                  <label style={subHeader}>Diplomas</label>
                </div>
                <TableDiplomas source={meusDiplomas}/>
                <div style={{paddingTop: 20, paddingBottom: 20, textAlign: "center"}}>
                  <label style={subHeader}>Especialidades</label>
                </div>
                <TableEspecialidades source={minhasEspecialidades}/>
                <div style={{paddingTop: 20, paddingBottom: 20, textAlign: "center"}}>
                  <label style={subHeader}>Terapias</label>
                </div>
                <TableTerapias source={minhasTerapias}/>
                <div style={{paddingTop: 20, paddingBottom: 20, textAlign: "center"}}>
                  <label style={subHeader}>Certificações Adicionais</label>
                </div>
                <TableTerapias source={minhasCertificações}/>
              </div>
              <div style={{display: "flex", flexDirection: "column"}}>
                <label style={subHeader}>Contrato de Realização
                  de Cursos</label>
                <TableCursos source={meusCursos}/>
              </div>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center",  }}>
                <button style={buttonBack} type="button"
                    onClick={() => {
                      navigate('/dashboard/responsavel/terapeutas');
                    }}>Voltar
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </Responsavel>
  )
}
