export class ChangeCase {
    static toTitleCase(str) {
        return this.toSnakeCase(str).replace('_', ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }
    static toUpperCase(str) {
        return str.toUpperCase();
    }
    static toLowerCase(str) {
        return str.toLowerCase();
    }
    static toCapitalizedCase(str = '') {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }else{
            return 'null'
        }
    }
    static toKebabCase(str) {
        return str.replace(/\s+/g, '-').toLowerCase();
    }
    static toSnakeCase(str) {
        return str.replace(/([A-Z])/g, ' $1').replace(/\s+/g, '_').toLowerCase();
    }
}