import React, {useState, useEffect} from 'react'
import Terapeuta from '../../../layouts/Terapeuta'
import {
    Container, ContainerSub, FormField
  } from './styles'
  import { useNavigate } from 'react-router-dom';
import { AiOutlineBell, AiOutlineUser } from 'react-icons/ai';
import {IoDocumentTextOutline, IoExtensionPuzzleOutline, IoLibraryOutline} from 'react-icons/io5'
import AtividadeExterna from '../Dashboard/Modal/AtividadeExterna';
import Documentacao from '../Dashboard/Modal/Documentacao';
import Materiais from '../Dashboard/Modal/Materiais';
import UsuarioLogado from "../../../components/UsuarioLogado";
import api from '../../../services/api';
import PlanoTerapeuticoService from '../../../services/PlanoTerapeuticoService';
import { useAuth } from '../../../context/auth';

function Solicitacoes() {

    
    const [isModalSolicitacaoDeMateriaisVisible, setIsModalSolicitacaoDeMateriaisVisible] = useState(false);
    const [isModalAtividadeExternaVisible, setIsModalAtividadeExternaVisible] = useState(false);
    const [isModalDocumentacaoVisible, setIsModalDocumentacaoVisible] = useState(false);
    const [especialidades, setEspecialidades] = useState([]);
    const [terapias, setTerapias] = useState();
    const [pacientes, setPacientes] = useState([]);
    const [terapeuta, setTerapeuta] = useState([]);

    const {user} = useAuth();
    let navigate = useNavigate();

    const getAtendimentos = (terapeuta_id) => {
        try {
            api.get(`api/atendimento?group_by=paciente_id&with=paciente,terapium&filter[terapeuta_id]=${terapeuta_id}`).then(({ data }) => {
                let pacientesOptions = [];
                data.forEach((atendimento) => {
                    pacientesOptions.push({ 
                        value: atendimento.paciente_id,
                        label: atendimento.paciente.nome,
                        plano: atendimento.paciente.id_plano_saude,
                        identificador: atendimento.paciente.identificador,
                      });
                });
            PlanoTerapeuticoService.index();
            setPacientes(pacientesOptions);
            });
        } catch (e) {
            console.log("Erro ao obter lista de pacientes - ", e)
        }
    }

    useEffect(() => {
        getAtendimentos(user.terapeuta[0].id);
    },[]);
    
    return (
        <Terapeuta>
            <Container>
                <div style={{
                    display: "flex", justifyContent: "space-between",
                    backgroundColor: "white",
                    borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
                    alignItems: "center", height: 58,
                    padding: "0px 20px", marginBottom: "4rem",
                    boxShadow: "0 0 10px 0px silver",
                }}>
                    <div>
                        <label onClick={() => navigate('/dashboard/terapeuta/solicitacoes')} style={{ fontFamily: "Nunito, sans-serif", marginRight: 20, color: "#7340BD", fontWeight: "bold", cursor: "pointer" }}>Solicitações</label>
                        <label onClick={() => navigate('/dashboard/terapeuta/minhas_solicitacoes')} style={{ fontFamily: "Nunito, sans-serif", marginRight: 20, cursor: "pointer" }}>Minhas solicitações</label>
                    </div>
                    <div style={{ display: "flex" }}>
                        <AiOutlineBell color="silver" size={20} />
                        <AiOutlineUser color="silver" size={20} />
                        <div>
                            <UsuarioLogado />
                        </div>
                    </div>
                </div>
                <ContainerSub>
                    <FormField>
                        <span style={{ 
                            color: "#4906AB",
                            fontSize: "2.0rem", 
                            fontFamily: "Nunito, sans-serif", 
                            fontWeight: "800", 
                            marginBottom: '2rem'}}>
                            Solicitações
                        </span>
                        <div className='d-flex flex-wrap justify-content-center' style={{backgroundColor: "white", width: "40%", height: "100%",
                           boxShadow: "0 0 10px 0px silver", borderRadius: 10 }}>
                            <button className='m-3 d-flex flex-column align-items-center justify-content-evenly' style={{
                                boxShadow: '3px 3px 6px 2px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#7340BD',
                                color: '#FFFFFF',
                                fontFamily: "Nunito, sans-serif",
                                border: "none",
                                // padding: "2%",
                                borderRadius: "10px",
                                width: '120px',
                                height: '90px',
                                fontSize:'15px'
                            }} onClick={() => setIsModalSolicitacaoDeMateriaisVisible(true)}>
                                <div><IoLibraryOutline size={30} /></div>
                                <div>Solicitação de Materiais</div>
                            </button>
                            <button className='m-3 d-flex flex-column align-items-center justify-content-evenly' style={{
                                boxShadow: '3px 3px 6px 2px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#7340BD',
                                color: '#FFFFFF',
                                fontFamily: "Nunito, sans-serif",
                                border: "none",
                                // padding: "2%",
                                borderRadius: "10px",
                                width: '120px',
                                height: '90px',
                                fontSize:'15px'
                            }} onClick={() => setIsModalAtividadeExternaVisible(true)}>
                                <div><IoExtensionPuzzleOutline size={30} /></div>
                                <div>Solicitação de Atividade</div>
                            </button>
                            <button className='m-3 d-flex flex-column align-items-center justify-content-evenly' style={{
                                boxShadow: '3px 3px 6px 2px rgba(0, 0, 0, 0.2)',
                                backgroundColor: '#7340BD',
                                color: '#FFFFFF',
                                fontFamily: "Nunito, sans-serif",
                                border: "none",
                                // padding: "2%",
                                borderRadius: "10px",
                                width: '120px',
                                height: '90px',
                                fontSize:'15px'
                            }} onClick={() => setIsModalDocumentacaoVisible(true)}>
                                <div><IoDocumentTextOutline size={30} /></div>
                                <div>Solicitação de Documentação</div>
                            </button>
                        </div>
                    </FormField>
                </ContainerSub>
            </Container>
            {isModalSolicitacaoDeMateriaisVisible ? <Materiais onClose={() => setIsModalSolicitacaoDeMateriaisVisible(false)} pacientes={pacientes} especialidades={especialidades} terapias={terapias} /> : (<></>)}
            {isModalAtividadeExternaVisible ? <AtividadeExterna onClose={() => setIsModalAtividadeExternaVisible(false)} pacientes={pacientes} especialidades={especialidades} terapias={terapias} /> : (<></>)}
            {isModalDocumentacaoVisible ? <Documentacao onClose={() => setIsModalDocumentacaoVisible(false)} pacientes={pacientes} especialidades={especialidades} terapias={terapias} /> : (<></>)}
        </Terapeuta>
    )
}

export default Solicitacoes