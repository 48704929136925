import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import { AiFillEye } from "react-icons/ai";
import { FaBan } from "react-icons/fa";
import Swal from 'sweetalert2';
import PacienteService from "../../../../services/PacienteService";

export function TableFaltas({ source, openModal, bloqueiaAcesso }) {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            {
                Header: 'Identificador',
                accessor: 'identificador',
            },
            {
                Header: 'Nome',
                accessor: 'nome',
            },

            {
                Header: 'Status',
                accessor: 'status',
            },
            {
                Header: 'Faltas',
                accessor: 'faltas',
            },
            {
                Header: "Ações",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    return ((
                        <div className="d-flex align-center justify-center">
                            <button className="btn-icone font-20px" title={cell.row.index} onClick={(e) => openModal(cell.row.index)} ><AiFillEye/></button>
                            <button className="btn-icone font-20px" title={'Bloquear acesso do paciente.'} onClick={(e) => {bloqueiaAcesso(cell.row.index)}}><FaBan/></button>
                        </div>
                    ))
                }
            }
        ]
    )
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    //#endregion

    return (
        <TableDefaultPagination columns={columns} source={source} />
    )
}