import React from 'react';
import {TituloDiv, TituloLabel} from './styles';

const ModalTitulo = ({children}) => {
  return (
      <TituloDiv>
        <TituloLabel>
          {children}
        </TituloLabel>
      </TituloDiv>
  );
};

export default ModalTitulo;
