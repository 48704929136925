//#region IMPORTAÇÕES
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { Container, override } from './styles';
import api from "../../../services/api";
import ClipLoader from "react-spinners/ClipLoader";
import { TablePaciente } from "./TablePaciente";
import FiltroPaciente from "../../../components/Filter/FiltroPacientes/FiltroPaciente";
import { useAuth } from "../../../context/auth";
//#endregion
export default function ListagemPacientes() {
    //#region VARIAVEIS
    const navigate = useNavigate();
    const color = "#8ed4ae";
    const usuario = useAuth();
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [paciente, setPaciente] = useState([]);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [pacienteIdentList, setPacienteIdentList] = useState(null);
    const [permissoes, setPermissoes] = useState([]);
    //#endregion
    //#region FUNCOES
    const coletaPermissoes = () => {
        let permissoes = usuario.user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        setLoadingDadosPacientes(true);
        async function loadPacientes() {
            api.get(`api/paciente/lista/simples?with=responsavel&select=id,nome,identificador,status,created_at,responsavel.nome`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setPacienteOriginal(data);
                setPaciente(data);
            })
            setLoadingDadosPacientes(false);
        }
        loadPacientes();

        coletaPermissoes();
    }, []);
    useEffect(() => {
        let idList = [];
        pacienteOriginal.forEach((data) => {
            if (data.status !== 'Inativo') {
                if (data.identificador !== null) {
                    idList.push(data.identificador)
                };
            };
        });
        setPacienteIdentList(idList);
    }, [pacienteOriginal, loadingDadosPacientes])
    //#endregion
    //#region HTML
    return (
        <Container>
            <div className="">
                <FiltroPaciente resultRef={setPaciente} list={pacienteOriginal}></FiltroPaciente>
            </div>
            <div style={{ marginBottom: 20 }}>
                {(paciente.length !== 0 && loadingDadosPacientes === false)
                    ? <TablePaciente source={paciente} pacienteIdentList={pacienteIdentList} permissoes={permissoes} usuario={usuario} />
                    : <ClipLoader color={color} loading={loadingDadosPacientes} css={override} size={50} />
                }
            </div>
            {
                permissoes['criação'] &&
                <div className="d-flex align-items-center justify-content-center">
                    <button onClick={() => navigate(`/dashboard/${usuario.user.tipo_user.toLowerCase()}/pacientes/novo`, { state: { pacienteIdentList } })} className="btn-padrao">
                        <AiOutlinePlusCircle style={{ marginRight: 5 }} />
                        Cadastrar Paciente
                    </button>
                </div>
            }
        </Container>
    )
    //#endregion
}