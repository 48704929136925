import React, { useState, useEffect } from 'react';
import { Modal } from '../../../../../components/Modais/styles';
import { ModalContainer } from '../../../../../components/Modais/PacienteAltasModal/styles';
import moment from 'moment';
import DetalhesDoAgendamento from '../../../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import api from '../../../../../services/api';
import * as ReactPDF from 'react-pdf'

const diasDaSemanaArray = [
    { value: 1, label: 'Segunda' },
    { value: 2, label: 'Terça' },
    { value: 3, label: 'Quarta' },
    { value: 4, label: 'Quinta' },
    { value: 5, label: 'Sexta' },
];

function ModalAgendaPaciente({ onClose, paciente }) {
    const [atendimentos, setAtendimentos] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    const [atendimentoId, setAtendimentoId] = useState();
    const diaSemanaIndex = moment().weekday();
    const id = 'modal-terapeuta-agenda-paciente'
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    }
    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento').then(({ data }) => {
            setHorarios(data)
        })
    }
    const getAgendaDia = () => {
        const url = `/api/agendamento?filter[paciente_id]=${paciente.id_paciente}&with=estacao,estacao.sala`
        api.get(url).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                console.log(atendimento.dia_semana_index);
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        })
    }
    useEffect(() => {
        getAgendaDia();
        getAgendaHorarios();
    }, []);

    function takeScreenShot() {
        const screenshotTarget = document.getElementById('tabela-print');

        console.log(ReactPDF)
    }

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <ModalContainer className='overflow-scroll mt-1 mb-1'>
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "25px", fontSize: "25px", top: "-10px"
                    }} onClick={() => onClose()}>
                        x
                    </label>
                </div>
                <div className='d-flex flex-column justify-content-evenly px-5' style={{ borderRadius: '10px', backgroundColor: '#f5f7fa' }}>
                    <div className='text-center'><label style={{ fontWeight: '900', fontSize: '20px', color: '#6A3BAF' }}>Agenda {paciente.nome} 01 a 07/09</label></div>
                    <table className='mt-3' id='tabela-print'>
                        <thead>
                            <tr>
                                {/* <td align={'center'} style={styles.tr1}></td> */}
                                {diasDaSemanaArray.map(dia => (
                                    <td key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? styles.tr2 : styles.tr1}>{dia.label}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {atendimentos.length > 0 ? atendimentos.map(atend => {
                                // const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                return (
                                    <tr>
                                        {diasDaSemanaArray.map(dia => {
                                            let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.dia_semana_index === dia.value);
                                            if (atendimentoEncontrado.length > 0) {
                                                atendimentoEncontrado = atendimentoEncontrado[0];
                                                return (
                                                    <td onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}

                                                        style={{ ...styles.trOcupado, textAlign: 'center' }}>
                                                        <span className='px-1 bg-white' style={{ color: styles.trOcupado.backgroundColor }}>
                                                            {moment(atendimentoEncontrado.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm') + ' - ' +
                                                                moment(atendimentoEncontrado.data_atendimento_final.split('T')[1], 'HH:mm:ss').format('HH:mm')}
                                                        </span>
                                                        <span
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                flexDirection: 'row',
                                                            }}>Estação {atendimentoEncontrado.estacao.estacao}
                                                        </span>
                                                        {atendimentoEncontrado.terapeutum.nome.substr(0, 10)}...
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td style={styles.trVazio}></td>
                                            );
                                        })}
                                    </tr>
                                )
                            })
                                :
                                <tr style={styles.trVazio}><td style={styles.trVazio}></td>
                                    <td style={styles.trVazio}></td>
                                    <td style={styles.trVazio}></td>
                                    <td style={styles.trVazio}></td>
                                    <td style={styles.trVazio}></td></tr>
                            }
                        </tbody>
                    </table>
                    <div className='d-flex align-items-center justify-content-evenly mt-3'>
                        <button className='btn' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', fontFamily: "Nunito, sans-serif", border: "none" }}>Enviar por email</button>
                        <button className='btn' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', fontFamily: "Nunito, sans-serif", border: "none" }}>Imprimir</button>
                        <button onClick={() => takeScreenShot()} className='btn' style={{ backgroundColor: '#7340BD', color: '#FFFFFF', fontFamily: "Nunito, sans-serif", border: "none" }}>Exportar para IMG</button>
                        <button onClick={() => onClose()} className='btn' style={{ backgroundColor: '#27dabe', color: '#FFFFFF', fontFamily: "Nunito, sans-serif", border: "none" }}>Voltar</button>
                    </div>
                </div>
                {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAgendamento
                    agendamentoId={atendimentoId}
                    setHouveExclusaoAgendamento={() => setHouveExclusaoAgendamento(true)}
                    onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
                /> : (<></>)}
            </ModalContainer>
        </Modal>
    )
}
const styles = {
    tr1: {
        backgroundColor: "#F5F7FA",
        color: "#000000",
        padding: 5,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        cursor: "pointer",
        height: 50,
        width: 120
    },
    tr2: {
        backgroundColor: "#7441BF",
        color: "#fff",
        padding: 5,
        width: 120,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        cursor: "pointer",
        height: 50,
    },
    tr3: {
        backgroundColor: "#F5F7FA",
        color: "#000000",
        padding: 5,
        width: 100,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trHora: {
        backgroundColor: "#DFCDF8",
        color: "#9586AC",
        padding: 5,
        width: 100,
        height: 80,
        boxShadow: "0 0 3px 1px silver",
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trVazio: {
        backgroundColor: "#fff",
        padding: 5,
        width: 100,
        height: 80,
        cursor: 'pointer',
        boxShadow: "0 0 3px 1px silver",
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trOcupado: {
        backgroundColor: "#35D058",
        boxShadow: "0 0 3px 1px silver",
        fontSize: 14,
        color: "#fff",
        padding: 5,
        width: 100,
        height: 80,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        cursor: 'pointer',
    },
    linha: {
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        color: '#fff'
    }
};
export default ModalAgendaPaciente