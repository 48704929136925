import api from "./api";

export class RelatorioAcompanhamentoService {
    static index = () => {
        return api.get(`/api/relatorio/acompanhamento`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/acompanhamento`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/acompanhamento/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/acompanhamento/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/acompanhamento/${id}`, {data});
    }
}

export class RelatorioAltaService {
    static index = () => {
        return api.get(`/api/relatorio/alta`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/alta`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/alta/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/alta/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/alta/${id}`, {data});
    }
}

export class RelatorioAlteracaoTerapiaService {
    static index = () => {
        return api.get(`/api/relatorio/alteracao-terapia`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/alteracao-terapia`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/alteracao-terapia/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/alteracao-terapia/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/alteracao-terapia/${id}`, {data});
    }
}

export class RelatorioAtividadeExternaService {
    static index = () => {
        return api.get(`/api/relatorio/atividade-externa`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/atividade-externa`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/atividade-externa/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/atividade-externa/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/atividade-externa/${id}`, {data});
    }
}

export class RelatorioEncerramentoService {
    static index = () => {
        return api.get(`/api/relatorio/encerramento-tratamento`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/encerramento-tratamento`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/encerramento-tratamento/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/encerramento-tratamento/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/encerramento-tratamento/${id}`, {data});
    }
}

export class RelatorioGenericoService {
    static index = () => {
        return api.get(`/api/relatorio/generico`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/generico`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/generico/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/generico/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/generico/${id}`, {data});
    }
}

export class RelatorioOrientacaoService {
    static index = () => {
        return api.get(`/api/relatorio/orientacao`);
    }

    static store = (obj) => {
        return api.post(`/api/relatorio/orientacao`, obj);
    }

    static show = (id) => {
        return api.get(`/api/relatorio/orientacao/${id}`);
    }

    static update = (obj, id) => {
        return api.put(`/api/relatorio/orientacao/${id}`, obj);
    }

    static destroy = (data, id) => {
        return api.delete(`/api/relatorio/orientacao/${id}`, {data});
    }
}
