import styled from 'styled-components';

export const DivPrincipal = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Nunito, sans-serif;
`;

export const ParagrafoSaudacao = styled.p`
  font-size: 2rem;
  color: #7340BD;
`;

export const ParagrafoInformativoNumeroDocumentos = styled.p`
  margin-top: 3vh;
  font-size: 1.5rem;
  color: #7340BD;
`;

export const ItemLista = styled.li`
  list-style: none;
  width: 100%;
  display: flex;
`;

export const DivListaDocumentos = styled.div`
  margin-top: 5vh;
  font-size: 1rem;
  text-align: left;
  width: 50%;
`;

export const NomeDocumento = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 5px;
  box-shadow: 0 0 10px 0px silver;
`;
