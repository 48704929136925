import React, { useEffect, useState } from 'react';
import './styles.css';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import moment from 'moment';
import Swal from 'sweetalert2';

const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const Calendarario = ({ handleClickDay, maxDate, selectedDate, daysMark = {}, dateLimit = false }, rest) => {
    //#region VARIAVEIS
    const [daysMarkObj, setDaysMarkObj] = useState(daysMark);
    const [currentDate, setCurrentDate] = useState(moment.utc().utc());
    const [selectedDateObj, setSelectedDate] = useState(null);
    const [maxDateObj, setMaxDateObj] = useState(null);
    const [daysList, setDaysList] = useState([]);
    const [errorCalendario, setErrorCalendario] = useState({
        message: 'Sem erros',
        status: false
    });
    const startOfMonth = moment.utc(currentDate).startOf('month');
    const endOfMonth = moment.utc(currentDate).endOf('month');
    const startDay = startOfMonth.day();
    const daysInMonth = endOfMonth.date();
    //#endregion

    //#region FUNCOES
    const prevMonth = () => {
        let currentDateLocal = currentDate;
        setCurrentDate(moment.utc(currentDateLocal).subtract(1, 'months'));
    };

    const nextMonth = () => {
        if (maxDateObj) {
            if (currentDate.clone().add(1, 'months').isBefore(maxDateObj, 'month')) {
                setCurrentDate(currentDate.clone().add(1, 'months'));
            }
        } else {
            setCurrentDate(currentDate.clone().add(1, 'months'));
        }
    };

    const checkDate = (date1, date2, testType = 'equal') => {
        if (date1 && date2) {
            switch (testType) {
                case 'equal':
                    return date1.isSame(date2, 'day');
                case 'greater':
                    return date1.isAfter(date2, 'day');
                case 'less':
                    return date1.isBefore(date2, 'day');
                default:
                    return false;
            }
        } else {
            //console.error('The two dates are null');
            return false;
        }
    };

    const renderDays = () => {
        const days = [];
        for (let i = 0; i < startDay; i++) {
            days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
        }
        for (let i = 1; i <= daysInMonth; i++) {
            const dayDate = moment.utc(currentDate).date(i);
            const markedDay = daysMark[dayDate.format('YYYY-MM-DD')];
            days.push(
                <div key={i}
                    className={
                        (maxDate && dayDate.isAfter(maxDate, 'day'))
                            ? "calendar-day disabled"
                            : (checkDate(moment(selectedDate), dayDate))
                                ? (markedDay)
                                    ? "calendar-day active marked"
                                    : "calendar-day active"
                                : (markedDay)
                                    ? "calendar-day marked"
                                    : "calendar-day"
                    }
                    onClick={() => (maxDate && dayDate.isAfter(maxDate, 'day')) ? Swal.fire({ title: 'Dia fora do alcance', timer: 1000 }) : handleClickDay(i, currentDate.month(), currentDate.year())}
                    placeholder={markedDay}>
                    <p>
                        {i}
                    </p>
                </div>
            );
        }
        setDaysList(days);
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        if (selectedDate) {
            setSelectedDate(moment.utc(selectedDate));
        } else {
            setSelectedDate(null);
        }
    }, [selectedDate]);
    useEffect(() => {
        if (currentDate) {
            renderDays();
        }
    }, [currentDate, daysMark]);

    /* useEffect(() => {
        if (maxDate) {
            setMaxDateObj(moment.utc(maxDate));
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'A data máxima não foi informada!',
            });
            let error = {
                message: 'A data máxima não foi informada!',
                status: true
            };
            setErrorCalendario(error);
        }
    }, [dateLimit, maxDate]); */
    //#endregion

    return (
        <>
            {errorCalendario.status
                ? <div className="calendar-error">
                    <p>{errorCalendario}</p>
                </div>
                : <div className="calendar" {...rest}>
                    <div className="calendar-header">
                        <button onClick={prevMonth} type='button'>
                            <AiOutlineLeft />
                        </button>
                        <h2>{months[currentDate.month()]} {currentDate.year()}</h2>
                        <button onClick={nextMonth} type='button'>
                            <AiOutlineRight />
                        </button>
                    </div>
                    <div className="calendar-body">
                        {daysOfWeek.map(day => (
                            <div key={day} className="calendar-weekday">
                                {day}
                            </div>
                        ))}
                        {daysList}
                    </div>
                </div>
            }
        </>
    );
};

export default Calendarario;
