import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;

export const ButtonStyle1 = {
    height: 90, 
    backgroundColor: "#6A3BAF", 
    cursor: 'pointer', 
    display: "flex-grid", 
    alignItems: "center",
    justifyContent: "center", 
    borderRadius: 10, 
    color: 'white', 
    margin: 5, 
    width: 120, 
    padding: 10,
    display: "flex", 
    flexDirection: "column", 
    textAlign: "center", 
}

export const ButtonStyle2 = {
    height:'95px', 
    backgroundColor: "#6A3BAF", 
    cursor: 'pointer', 
    display: "flex-grid", 
    alignItems: "center",
    justifyContent: "center", 
    borderRadius: 10, 
    color: 'white', 
    margin: 5, 
    width: "25%", 
    padding: 20
}