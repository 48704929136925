export const CustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxHeight: "70vh",
        width: "40vw",
        transform: 'translate(-50%, -50%)',
        borderRadius: 10,
        backgroundColor: "#FFFFFF"
    },
};

export const LabelUpload = {
    marginTop: 25, 
    color: '#7340BD', 
    cursor: "pointer"
}

export const ButtonAddStyle = {
    backgroundColor: "#7441BF", 
    color: "#FFFFFF", 
    border: "none", 
    borderRadius: 8, 
    height: 34, 
    marginBottom: 20, 
    marginTop: 20, 
    paddingLeft: 18, 
    paddingRight: 18
}

export const FormStyle = {
    display: "flex", 
    flexDirection: "column", 
    borderWidth: 1, 
    borderTopColor: "red" 
}