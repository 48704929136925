import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const divStyle = {
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center"
}

export const FilterStyle = {
    backgroundColor: "#7441BF", 
    fontFamily: "Nunito, sans-serif", 
    display: "grid",
    color: "#FFFFFF",
    width: "30vw",
    padding: 10,
    borderRadius: 10,  
}