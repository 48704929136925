import styled from "styled-components"

export const Modal = styled.div`
flex-direction: column; 
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 10;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F5F7FA;
  align-items: stretch;
  color: #000;
  width: 40%;
  max-width: 800px;
  height: auto;
  border-radius: 10px;
  padding: 30px 0;
`;

export const ButtonStyle1 = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "8px 22px",
    borderRadius: "10px",
}

export const ButtonStyle2 = {
  backgroundColor: '#7340BD',
  color: '#FFFFFF',
  fontFamily: "Nunito, sans-serif",
  border: "none",
  padding: "8px 22px",
  borderRadius: "10px",
  marginRight: 20 
}

export const ButtonStyle3 = {
  backgroundColor: '#7340BD',
  color: '#FFFFFF',
  fontFamily: "Nunito, sans-serif",
  border: "none",
  padding: "8px 22px",
  borderRadius: "10px",
  marginLeft: 20
}

export const BackButtonStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "6px 22px",
    borderRadius: "10px",
}

export const Upload = {
  marginBottom: 20,
  color: '#7340BD', 
  cursor: "pointer",
  fontFamily: "Nunito, sans-serif",
  display: "flex",
  height: 25,
}

export const Delete = {
  color: '#FFFFFF', 
  cursor: "pointer",
  backgroundColor: '#7340BD',
  fontFamily: "Nunito, sans-serif",
  height: 30,
  marginLeft: 10,
  borderRadius: 20,
  padding: "2px 8px",
  display: "flex", 
  justifyContent: "center",
}