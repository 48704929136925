import React from "react";
import {useTable} from 'react-table';

import {
  AiOutlineDelete
} from "react-icons/ai";
import Swal from "sweetalert2";
import ResponsavelSalaService from "../../../../services/ResponsavelSalaService";

export default function TableResponsavelSala({source, callback}) {

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        ResponsavelSalaService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
      }
    })
  }

  const columns = React.useMemo(() => [{
    Header: " ", columns: [{
      Header: 'Sala:', accessor: 'sala.sala',
    }, {
      Header: 'Responsavel:', accessor: 'terapeutum.nome',
    }, {
      Header: "Ações", accessor: "acoes", Cell: ({cell}) => ((<div style={{
            display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent"
          }}>
            <AiOutlineDelete style={{cursor: 'pointer'}} onClick={() => handleDelete(cell.row.original.id)}
                             color="#7340BD" size={22}/>
          </div>))
    }],
  },], [])

  const data = React.useMemo(() => source, [source]);


  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = useTable({
    columns, data,
  })

  return (<div>
      <table {...getTableProps()} style={{width: '100%'}}>
        <thead>
        {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (<th {...column.getHeaderProps()}
                                                    style={{
                                                      color: 'black',
                                                      fontWeight: 'bold',
                                                      textAlign: 'left'
                                                    }}>{column.render('Header')}</th>))}
          </tr>))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (<tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}
                           style={{
                             padding: '10px', marginRight: '10px', background: 'white', borderRadius: '10px',
                           }}
                >{cell.render('Cell')}</td>
              })}
            </tr>)
        })}
        </tbody>
      </table>
    </div>)
}
