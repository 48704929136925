import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import Input from '../../Login/components/Input';
import { Form } from '@unform/web';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { InputArquivo, RichTextEditor } from '../../../components/Input';
import { PickList } from 'primereact/picklist';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { CgClose } from 'react-icons/cg';
import { BsEye } from 'react-icons/bs';
import Arquivos from '../../../services/ArquivosService';

const ModalUploads = forwardRef(({ modalData }, ref) => {
    //#region VARIAVEIS
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const [arquivo, setArquivo] = useState({
        nome: ''
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "auto",
            maxHeight: "70vh",
            width: '60vw',
            marginRight: '-50%',
            paddingTop: '0px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };

    const formRef = useRef(null);
    const ButtonRef = useRef(null);

    const [picklistSource, setPickListSource] = useState([]);
    const [usersSelectedList, setUserSelectedList] = useState([]);
    const [sourceFilter, setSourceFilter] = useState(null);
    const [tipoUser, setTipoFilter] = useState(null);
    const [tiposList, setTiposList] = useState([]);

    const [imagem, setImagem] = useState(null);
    const [path, setPath] = useState([]);
    //#endregion

    //#region FUNÇÕES
    const previewNotificacao = () => {
        const { conteudo, tipo } = arquivo;
        const titulo = document.getElementById('titulo').value;
        let option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        };
        Swal.fire(option);
    }
    //#endregion

    //#region FUNÇÕES DE MODAL
    const openModal = useCallback(() => {
        setloading(true);
        setTipoFilter(null);
        setSourceFilter(null);
        api.get('api/group')
            .then(({ data }) => {
                let pickList = [], tiposList = [];
                data.forEach((user) => {
                    pickList.push({ id: user.id, label: user.name });
                    if (tiposList.findIndex(ti => ti.label === user.tipo_user) === -1) {
                        tiposList.push({ value: user.tipo_user, label: user.tipo_user });
                    }
                });
                setPickListSource(pickList.sort((a, b) => {
                    if (a.tipo === b.tipo) {
                        return a.label.localeCompare(b.label);
                    } else {
                        return a.tipo.localeCompare(b.tipo);
                    }
                }));
                setTiposList(tiposList);
                setloading(false)
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsOpen(true);
            });
    }, []);

    const closeModal = () => {
        setPath([]);
        setArquivo(null);
        setUserSelectedList([]);
        setArquivo({
            conteudo: '',
            valido_ate: '',
        });
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => {
        return {
            openModal,
            notificacao: arquivo
        };
    }, [openModal, arquivo]);
    //#endregion

    //#region HANDLES
    const handleChange = (e) => {
        setArquivo((prevNotificacao) => ({
            ...prevNotificacao,
            'nome': e.target.value
        }));
    };

    const handleSubmit = () => {
        try {
            const dados = {
                ...arquivo,
                arquivo: imagem
            };
            Arquivos.store(dados, 'api/uploads', 'arquivo')
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('Arquivo enviados com sucesso!', '', 'success', 2000);
                    }
                })
                .catch((error) => {
                    console.error(error.message);
                    Swal.fire('Houve um problema a enviar o arquivo...', '', 'error');
                });
        } catch (error) {
            console.error(error);
        } finally {
            closeModal();
        }
    };
    const handleDeleteUpload = () => {
        setArquivo(null);
        path.splice(-1);
    };
    const handleChangeArquivo = (e) => {
        if (imagem === null) {
            var arquivo = document.getElementById("arquivo")
            const file = e.target.files[0];
            const size = e.target.files[0].size;
            if (size < 40000000) { //40MB         
                console.log(file)
                setImagem(file);
                setPath([...path, e.target.value]);
            } else {
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                arquivo.value = "";
            }
            e.preventDefault();
        } else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'warning');
        }
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (modalData) {
            setArquivo(modalData);
        }
    }, [modalData]);
    //#endregion

    //#region HTML
    return (
        <div>
            <Button className='btn-padrao' ref={ButtonRef} onClick={openModal}>Enviar Arquivo</Button>
            <ReactModal style={customStyles} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnEsc shouldCloseOnOverlayClick appElement={document.getElementById('root')}>
                <div className='div-titulo-fixo d-flex w-100' style={{ justifyContent: 'space-between', position: 'sticky' }}>
                    <label>
                        <BsEye color='purple' className='pointer' size={25} onClick={previewNotificacao}></BsEye>
                    </label>
                    <label>Adicionar/Editar Notificação</label>
                    <label>
                        <CgClose color='red' className='pointer' size={25} onClick={closeModal} />
                    </label>
                </div>
                {loading
                    ? <ClipLoader size={30} className='m-auto'></ClipLoader>
                    : <Form ref={formRef} onSubmit={handleSubmit} className='row'>
                        <label className='col-6 mt-4'>
                            Nome:
                            <Input id="nome" name="nome" title='Nome' value={arquivo.nome} onChange={handleChange} />
                        </label>
                        <label className='col-6 mt-4'>
                            Arquivo:
                            <InputArquivo id="arquivo" name="arquivo" title='Anexar Arquivo' path={path} onChange={handleChangeArquivo} handleDeleteUpload={handleDeleteUpload} />
                        </label>
                        <div className='d-flex justify-between'>
                            <Button className='w-300px' onClick={closeModal}>Cancelar</Button>
                            <Button className='w-300px' type="submit">Salvar</Button>
                        </div>
                    </Form>
                }
            </ReactModal>
        </div>
    );
    //#endregion
});

export default ModalUploads;
