import api from "./api";

class EstacaoService {
  static index = (params = '') => {
    return api.get(`api/estacao?${params}`);
  }
  static store = (obj) => {
    /* exemplo
    {"sala_id":1,"estacao":"ratione","sala":"alias"}
    */

    return api.post(`api/estacao`, obj);
  }
  static show = (id) => {
    return api.get(`api/estacao/${id}`);
  }
  static update = (obj, id) => {
    /* exemplo
    {"sala_id":1,"estacao":"deleniti","sala":"laudantium"}
    */

    return api.put(`api/estacao/${id}`, obj);
  }
  static destroy = (id) => {
    return api.delete(`api/estacao/${id}?id=${id}`);
  }
}

export default EstacaoService;
