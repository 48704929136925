import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import Input, { SimpleInput } from '../../../Input';
import { Form } from '@unform/web';
import { BsCloudArrowUp } from 'react-icons/bs'
import moment from 'moment';
import api from '../../../../services/api';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import 'react-quill/dist/quill.snow.css';
import { CustomStyles } from './styles';
import { CgRemove } from 'react-icons/cg';
import Arquivos from '../../../../services/ArquivosService';
import { TextAreaInput } from '../../../Input';
import { useAuth } from '../../../../context/auth';

const RelatorioEvolucaoPaciente = forwardRef(({ paciente, onClose = () => { }, tipo = 'edição' }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*==vars INFORMACOES==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'MODAL EVOLUCAO DIARIA';
    // eslint-disable-next-line no-unused-vars
    const descricao = 'Tela modal que cadastra evoluções diárias dos atendimentos.';
    /*==vars UTILITARIOS==*/
    const snackbar = useSnackbar();
    const { user } = useAuth();
    let day = moment().format("YYYY-MM-DD");

    const [uploadFiles, setUploadFiles] = useState([]);
    const [relatorioInfo, setRelatorioInfo] = useState([{}]);
    const [data, setData] = useState([]);

    const [glosada, setGlosado] = useState(false);
    const [aceita_glosa, setAceitaGlosa] = useState(false);

    const id = 'modal-paciente-evolucao-diaria';
    const [isOpen, setIsOpen] = useState(false);
    const formRef = React.useRef(null);
    const [idEvolucao, setIdEvolucao] = useState(null);
    const [evolucaoPaciente, setEvolucaoPaciente] = useState(null);
    const [permissoes, setPermissoes] = useState([]);
    const coletaPermissoes = () => {
        let permissoes = user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = 'evolucao_paciente';
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }
    //#endregion

    const openModal = useCallback(async (id) => {
        try {
            if (id) {
                let evolucaoPaciente = await api.get(`api/evolucao-paciente/${id}?id=${id}&with=atendimento,estacao,terapium,especialidade,paciente`);
                let relatorio = JSON.parse(evolucaoPaciente.data.relatorio);
                if (!Array.isArray(relatorio)) {
                    relatorio = [relatorio][0];
                }
                evolucaoPaciente.data.relatorio = relatorio;
                if (evolucaoPaciente.data.status === 'glosado') {
                    setGlosado(true);
                }
                setEvolucaoPaciente(evolucaoPaciente.data);
                delete (relatorio[0]['']);
                setRelatorioInfo(relatorio);
                setIdEvolucao(id);
                let dados = {
                    paciente: evolucaoPaciente.data.paciente.nome,
                    data: moment(evolucaoPaciente.data.atendimento.created_at).format('DD/MM/YYYY'),
                    hora_de: moment(evolucaoPaciente.data.atendimento.data_atendimento).format('HH:mm'),
                    hora_ate: moment(evolucaoPaciente.data.atendimento.date_final).format('HH:mm'),
                    estacao: evolucaoPaciente.data.estacao.estacao,
                    terapia: evolucaoPaciente.data.terapium.terapia,
                    especialidade: evolucaoPaciente.data.especialidade.especialidade
                }
                setData(dados);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsOpen(true);
        }
    }, []);

    useImperativeHandle(ref, () => {
        return { openModal };
    }, []);

    function afterOpenModal() {

    }

    const closeModal = useCallback(() => {
        setData([]);
        setRelatorioInfo([]);
        setIdEvolucao();
        onClose();
        setIsOpen(false);
    }, []);

    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
    async function handleSubmit(data) {
        if (!relatorioInfo) {
            Swal.fire('Preencha todos os campos', 'O relatório não pode ir vasil, por favor adicione as informações necessárias', 'error');
            return;
        }
        Swal.fire({
            title: id ? 'Deseja mesmo editar essa Evolução?' : 'Deseja mesmo salvar essa Evolução?',
            text: 'A evolução não podera sera alterada após o dia de hoje, tenha certeza das informações que estão sendo passadas.',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let json;
                if (Array.isArray(relatorioInfo)) {
                    json = { ...relatorioInfo };
                    json = Object.assign({}, relatorioInfo);
                    json = relatorioInfo.reduce((json, value, key) => { json[key] = value; return json; }, {});
                } else {
                    json = relatorioInfo;
                }

                if (idEvolucao) {
                    let objSubmit = {
                        data_hora: day,
                        mensagem_resposta: data.mensagem_resposta,
                        mensagem_glosa: data.mensagem_glosa,
                        nome_auditor: user.name,
                        tipo: (user.tipo_user.toLowerCase() === 'auditor')
                            ? glosada
                                ? 'mensagem_glosa'
                                : ''
                            : (aceita_glosa && (user.tipo_user.toLowerCase() === 'coordenacao' || user.tipo_user.toLowerCase() === 'administrador'))
                                ? 'mensagem_resposta'
                                : '',
                        status: (user.tipo_user.toLowerCase() === 'auditor')
                            ? glosada
                                ? 'glosado'
                                : 'aceito'
                            : glosada
                                ? aceita_glosa
                                    ? 'glosa_atendimento'
                                    : evolucaoPaciente.status
                                : evolucaoPaciente.status,
                        relatorio: JSON.stringify(json),
                        id: idEvolucao
                    }
                    await api.patch(`api/evolucao-paciente/${idEvolucao}`, objSubmit)
                        .then((response) => {
                            if (response) {
                                snackbar.displayMessage('Evolução do paciente foi atualizada com sucesso!', 'success');
                                const files = uploadFiles;
                                if (files.length > 0) {
                                    files.map(async (file) => {
                                        let formData = {
                                            arquivo: file,
                                            evolucao_paciente_id: response.data.id,
                                            tipo: user.tipo_user.toLowerCase() === 'auditor' ? 'mensagem_glosa' : user.tipo_user.toLowerCase() ? 'mensagem_resposta' : null
                                        }
                                        await Arquivos.store(formData, 'api/arquivo-evolucao-paciente', 'arquivo')
                                            .then((response) => {
                                                if (response) {
                                                    snackbar.displayMessage('Arquivo da Evolução do paciente atualizado com sucesso!', 'success');
                                                } else {
                                                    snackbar.displayMessage('Erro ao atualizar o arquivo.', 'error');
                                                }
                                            }).catch(e => {
                                                console.error(e);
                                                snackbar.displayMessage('Erro ao atualizar o arquivo.', 'error');
                                            });
                                    })
                                }
                            } else {
                                snackbar.displayMessage('Erro ao atualizar evolução.', 'error');
                            }
                        }).catch((e) => {
                            console.error(e);
                            snackbar.displayMessage('Erro ao atualizar evolução.', 'error');
                        }).finally(() => {
                            closeModal();
                            window.location.reload();
                        })
                } else {
                    let objSubmit = {
                        paciente_id: paciente.id_paciente,
                        terapia_id: paciente.terapia_id,
                        especialidade_id: paciente.especialidade_id,
                        estacao_id: paciente.estacao_id,
                        data_hora: day,
                        relatorio: JSON.stringify(json),
                        atendimento_id: paciente.atendimento_id,
                    }
                    await api.post(`api/evolucao-paciente`, objSubmit)
                        .then(async (response) => {
                            if (response) {
                                snackbar.displayMessage('Evolução do paciente foi adicionada com sucesso!', 'success');
                                const files = uploadFiles;
                                if (files.length > 0) {
                                    files.map(async (file) => {
                                        let formData = {
                                            arquivo: file,
                                            evolucao_paciente_id: response.data.id,
                                        }
                                        await Arquivos.store(formData, 'api/arquivo-evolucao-paciente', 'arquivo')
                                            .then((response) => {
                                                if (response) {
                                                    snackbar.displayMessage('Arquivo da Evolução do paciente salva com sucesso!', 'success');
                                                } else {
                                                    snackbar.displayMessage('Erro ao salvar o arquivo.', 'error');
                                                }
                                            }).catch(e => {
                                                console.error(e);
                                                snackbar.displayMessage('Erro ao salvar o arquivo.', 'error');
                                            });
                                    })
                                }
                            } else {
                                snackbar.displayMessage('Erro ao incluir evolução.', 'error');
                            }
                        }).catch((e) => {
                            console.error(e);
                            snackbar.displayMessage('Erro ao incluir evolução.', 'error');
                        }).finally(() => {
                            closeModal();
                        })
                }
            }
        });
    }

    function handleUpload(file_upload) {
        setUploadFiles([...uploadFiles, file_upload.target.files[0]])
    }

    const handleChangeRelatorio = (value) => {
        let dom = value.target;
        let newRelatorio = relatorioInfo;
        if (dom.name == 'intercorrencia' || dom.name == '') {
            newRelatorio[0]['intercorrencia'] = dom.checked;
        } else {
            newRelatorio[0][dom.name] = dom.value;
        }
        setRelatorioInfo(newRelatorio);
    }
    //#endregion
    useEffect(() => {
        if (paciente) {
            let dados = {
                paciente: paciente.nome,
                data: moment(paciente.created_at).format('DD/MM/YYYY'),
                hora_de: moment(paciente.data_atendimento).format('HH:mm'),
                hora_ate: moment(paciente.date_final).format('HH:mm'),
                estacao: paciente.estacao,
                terapia: paciente.terapia,
                especialidade: paciente.especialidade
            }
            setData(dados);
        }
    }, [paciente]);
    useEffect(() => {
        coletaPermissoes();
        Modal.setAppElement(document.body);
    }, []);
    return (
        <Modal id={id}
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={CustomStyles}
            contentLabel="Evolução Diária"
            transparent>
            <div className='position-relative'>
                <label className='btn-x' onClick={() => closeModal()}>x</label>
            </div>
            <div className='div-titulo'>
                <label>Evolução do Paciente</label>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit}>
                <div className='d-flex flex-wrap row'>
                    <div className='p-1 col-sm-12 col-lg-4'>
                        <Input name="paciente" title="Paciente:" type="text" defaultValue={data.paciente} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-3'>
                        <Input name="data" title="Data:" type="text" defaultValue={data.data} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-2'>
                        <Input name="hora_de" title="De:" type="text" defaultValue={data.hora_de} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-2'>
                        <Input name="hora_ate" title="Até:" type="text" defaultValue={data.hora_ate} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-1'>
                        <Input name="estacao" title="Estação:" type="text" defaultValue={data.estacao} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-5'>
                        <Input name='terapia' title='Terapia:' type="text" defaultValue={data.terapia} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-7'>
                        <Input name='especialidade' title='Especialidade:' type="text" defaultValue={data.especialidade} disabled />
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <label style={{ fontWeight: 'bold', marginTop: 10, fontSize: 18, marginLeft: 'auto', marginRight: 'auto' }}>Relatório</label>
                        <TextAreaInput name="estado_geral" defaultValue={relatorioInfo[0]?.estado_geral} title="Estado Geral do Paciente:" onChange={(e) => handleChangeRelatorio(e)} required disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <TextAreaInput name="objetivo" defaultValue={relatorioInfo[0]?.objetivo} title="Objetivo da seção:" onChange={(e) => handleChangeRelatorio(e)} required disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <TextAreaInput name="atividade_realizada" defaultValue={relatorioInfo[0]?.atividade_realizada} title="Qual atividade foi realizada:" onChange={(e) => handleChangeRelatorio(e)} required disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <TextAreaInput name="recursos_tecnicas" defaultValue={relatorioInfo[0]?.recursos_tecnicas} title="Recursos e técnicas utilizadas:" onChange={(e) => handleChangeRelatorio(e)} required disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <TextAreaInput name="desempenho" defaultValue={relatorioInfo[0]?.desempenho} title="Desempenho do paciente durante a seção:" onChange={(e) => handleChangeRelatorio(e)} required disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <TextAreaInput name="comentario_geral" defaultValue={relatorioInfo[0]?.comentario_geral} title="Comentários gerais:" onChange={(e) => handleChangeRelatorio(e)} disabled={tipo !== 'edição'}></TextAreaInput>
                    </div>
                    <div className='p-1 col-sm-12 col-lg-12'>
                        <SimpleInput name="intercorrencia" defaultValue={relatorioInfo[0]?.intercorrencia} defaultChecked={relatorioInfo[0]?.intercorrencia ? true : false} title="Intercorrência?" type="checkbox" onChange={(e) => handleChangeRelatorio(e)} disabled={tipo !== 'edição'}></SimpleInput>
                    </div>
                    {user.tipo_user.toLowerCase() === 'auditor' &&
                        <div className='p-1 col-sm-12 col-lg-12'>
                            <SimpleInput type='checkbox' name={'glosado'} title={'Glosar'} id='glosado' onChange={(e) => setGlosado(e.target.checked)} defaultChecked={glosada} />
                        </div>
                    }
                    {permissoes['mensagem_glosa'] && (user.tipo_user.toLowerCase() !== 'auditor' || glosada === true) &&
                        <div className={permissoes['mensagem_resposta'] ? 'p-1 col-sm-12 col-lg-6' : 'p-1 col-12'}>
                            <TextAreaInput title={'Mensagem de Glosa:'} id={'mensagem_glosa'} name={'mensagem_glosa'} defaultValue={evolucaoPaciente?.mensagem_glosa} disabled={!(user.tipo_user.toLowerCase() === 'auditor')}></TextAreaInput>
                        </div>
                    }
                    {permissoes['mensagem_resposta'] &&
                        <div className={permissoes['mensagem_glosa'] ? 'p-1 col-sm-12 col-lg-6' : 'p-1 col-12'}>
                            <TextAreaInput title={'Mensagem da Coordenação:'} id={'mensagem_resposta'} name={'mensagem_resposta'} defaultValue={evolucaoPaciente?.mensagem_resposta} disabled={!(user.tipo_user.toLowerCase() === 'coordenacao' || user.tipo_user.toLowerCase() === 'administrador')}></TextAreaInput>
                            {(user.tipo_user.toLowerCase() === 'coordenacao' || user.tipo_user.toLowerCase() === 'administrador') &&
                                <div className='col-12'>
                                    <SimpleInput type='checkbox' name={'aceita_glosa'} title={'Aceitar glosa:'} id='aceita_glosa' onChange={(e) => setAceitaGlosa(e.target.checked)} defaultChecked={aceita_glosa} />
                                </div>
                            }
                        </div>
                    }

                    <div className='p-1 col-sm-12 col-lg-12 mt-50pxI d-flex justify-center text-center row'>
                        {uploadFiles.length > 0 ?
                            <div className='col-sm-12 col-lg-12'>
                                <label className='text-left'>Arquivos: {uploadFiles.length}</label>
                                <ul key={'arquivos-ul'} className='col-sm-12 col-lg-12'>
                                    {uploadFiles.map((item, index) => (
                                        <li key={'arquivos-row-' + index} className='d-flex align-center justify-content-between shadow-1 p-1 col-sm-12 col-lg-12'>
                                            <p className='pl-10px m-0'>
                                                {item.name}
                                            </p>
                                            <button type='button' className='btn-icone' onClick={() => setUploadFiles(uploadFiles.filter(i => i != item))} >
                                                <CgRemove size={22} color='#6A3BAF'></CgRemove>
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            : 'Sem arquivos'
                        }
                        <label className='pointer col-sm-12 col-lg-12' htmlFor="anexo-btn" style={{ color: '#6A3BAF', fontWeight: '600' }}>
                            <input className='d-none' type="file" id='anexo-btn' onChange={(e) => handleUpload(e)} />
                            <div className='d-flex flex-column text-muted'>
                            </div>
                            <BsCloudArrowUp size={30} className='me-2' /> Anexar Arquivos
                        </label>
                    </div>
                    <div className='col-sm-12 col-lg-12 d-flex justify-content-between'>
                        <button className='btn-padrao m-1' type='button' onClick={e => closeModal()} > Voltar </button>
                        <button className='btn-padrao m-1' type='submit' > Confirmar </button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
})

export default RelatorioEvolucaoPaciente;
