import styled, {css} from 'styled-components';

import Tooltip from '../Tooltip';


export const TextAreaStyle = styled.textarea`
  background: #FFFFFF;
  border-radius: 10px;
  padding: 14px;
  width: 100%;
  height: 40px;
  flex: 1;
  font: 1.0rem 'Nunito';
  resize: none;
  border: 2px solid #ddd;
  color: #666360;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  ${(props) =>
          props.isErrored &&
          css`
            border-color: #c53030;
          `}

  ${(props) =>
          props.isFocused &&
          css`
            color: #ff9000;
            border-color: #ff9000;
          `}

  ${(props) =>
          props.isFilled &&
          css`
            color: #ff9000;
          `}

  &::placeholder {
    color: #666360;
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
