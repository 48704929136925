import React, { useCallback, useMemo } from 'react'
import Terapeuta from '../../../layouts/Terapeuta'
import { Container, ContainerSub, FormField, LegendaStyle, CenterDivStyle } from './styles'
import moment from 'moment';
import api from '../../../services/api';
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import 'react-day-picker/dist/style.css';
import DetalhesDoAgendamento from '../../RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { getLegendaAgendamento } from '../../../helpers/agenda.helper';
import { useAuth } from '../../../context/auth';
import LegendaTerapeuta from './Modal/ModalLegendas';

const diasDaSemanaArray = [
    {
        value: 1,
        label: 'Segunda'
    },
    {
        value: 2,
        label: 'Terça'
    },
    {
        value: 3,
        label: 'Quarta'
    },
    {
        value: 4,
        label: 'Quinta'
    },
    {
        value: 5,
        label: 'Sexta'
    },
];

function TerapeutaAgenda() {

    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    const [modalLegenda, setModalLegenda] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);

    const [terapeuta, setTerapeuta] = useState({})
    const [atendimentoId, setAtendimentoId] = useState();
    const [horarios, setHorarios] = useState([]);

    const diaSemanaIndex = moment().weekday();
    const { user } = useAuth();

    const getAgendaTerapeuta = (teraData) => {
        api.get(`/api/agendamento?with=estacao,modalidade&filter[terapeuta_id]=${teraData.id}`)
            .then(({ data }) => {
                data = data.map(atendimento => {
                    atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                    let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                    atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                    return atendimento;
                });
                setAtendimentos(data);
            });
    }

    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        })
    }

    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    }
    const carregaTerapeuta = () => {
        api('api/terapeuta/dados/add/' + user.terapeuta[0].id).then(async (response) => {
            let terapias_disponiveis;
            await api.get('api/terapeuta-terapia?with=terapium&filter[terapeuta_id]=' + user.terapeuta[0].id).then(({ data }) => {
                terapias_disponiveis = data;
            })
            if (terapias_disponiveis !== undefined && terapias_disponiveis !== null) {
                terapias_disponiveis = terapias_disponiveis.map((value) => {
                    return value.terapium.terapia;
                })
            }
            let obj = { ...user.terapeuta[0], ...response.data[0], terapias_disponiveis };
            console.log(obj);
            setTerapeuta(obj);
        })
    }

    useEffect(() => {
        if (!houveExclusaoAgendamento) return;
        //  getAgendaDia();
        setHouveExclusaoAgendamento(false);
    }, [houveExclusaoAgendamento]);

    useEffect(() => {
        carregaTerapeuta();
        setTerapeuta(user.terapeuta[0])
        getAgendaTerapeuta(user.terapeuta[0]);
        getAgendaHorarios();
    }, []);

    return (
        <Terapeuta>
            <Container>
                <div className='div-submenu'>
                    <label className='selecionado'> Agenda Semanal </label>
                    <Link to="/dashboard/terapeuta/areacompartilhada">
                        <label style={{ fontFamily: "Nunito, sans-serif", cursor: "pointer", color: "#000000" }}>
                            Áreas Compartilhadas
                        </label>
                    </Link>
                </div>
                <ContainerSub>
                    <FormField>
                        <span style={{
                            color: "#4906AB",
                            fontSize: "2.0rem",
                            fontFamily: "Nunito, sans-serif",
                            fontWeight: "800",
                            marginBottom: '2rem',
                            width: '100%',
                            textAlign: 'center'
                        }}>
                            Agenda semanal
                        </span>
                        <div style={CenterDivStyle}>
                            <button
                                onClick={e => setModalLegenda(true)}
                                style={LegendaStyle}>Legenda
                            </button>
                        </div>
                        <div className='d-flex flex-lg-row flex-sm-col-rev align-items-start justify-content-center gap-5'>
                            <div className='d-flex flex-row max-w-100vw overflow-auto'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td align={'center'} style={styles.tr1}>Horário</td>
                                            {diasDaSemanaArray.map(dia => (
                                                <td key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? styles.tr2 : styles.tr1}>{dia.label}</td>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {horarios.map((horario, horarioIdx) => {
                                            const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                            return (
                                                <tr key={`linha-horario-${horarioIdx}`}>
                                                    <td align={'center'} style={styles.trHora}>{horasExtenso}</td>
                                                    {diasDaSemanaArray.map(dia => {
                                                        let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso);
                                                        if (atendimentoEncontrado.length > 0) {
                                                            atendimentoEncontrado = atendimentoEncontrado[0];
                                                            return (
                                                                <td onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                                    key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                                    style={{ ...styles.trOcupado, ...styles[getLegendaAgendamento(atendimentoEncontrado, atendimentos)] }}>
                                                                    <span
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'row',
                                                                        }}>
                                                                        Sala {atendimentoEncontrado.estacao.sala.sala}<br></br>
                                                                        Estação {atendimentoEncontrado.estacao.estacao}<br></br>
                                                                        {atendimentoEncontrado.paciente.nome_curto.substr(0, 10)}...
                                                                    </span>
                                                                </td>
                                                            )
                                                        }
                                                        return (
                                                            <td style={styles.trVazio}
                                                                key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                                                        );
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-center col-sm-12 col-lg-3'>
                                <div className='d-flex flex-lg-column flex-sm-row p-4 bg-white rounded default-box'>
                                    <div className='col-sm-6 col-lg-12 d-flex justify-content-center pe-3 align-center'>
                                        <img src={user.terapeuta[0].foto_perfil_full === null ? user : user.terapeuta[0].foto_perfil_full} height={150} width={150} className='perfil-image' alt='Foto de Perfil.' />
                                    </div>
                                    <div className='col-sm-12 col-sm-6 d-flex flex-col'>
                                        <span>
                                            <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold" }}>
                                                Nome:
                                            </label> {terapeuta.nome}
                                        </span>
                                        <span>
                                            <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold" }}>
                                                Terapias:
                                            </label>
                                            {(terapeuta.terapias_disponiveis !== null && terapeuta.terapias_disponiveis && terapeuta.terapias_disponiveis.length > 0) &&
                                                <ul>
                                                    {terapeuta.terapias_disponiveis.map((value) => {
                                                        return <li>{value}</li>
                                                    })}
                                                </ul>
                                            }
                                        </span>
                                        <span>
                                            <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold" }}>
                                                Faltas:
                                            </label> {terapeuta.faltas90}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isModalDetalhesDoAtendimentoVisible
                            ? <DetalhesDoAgendamento agendamentoId={atendimentoId} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                            : (<></>)
                        }
                        {modalLegenda && <LegendaTerapeuta onClose={e => setModalLegenda(false)} />}
                    </FormField>
                </ContainerSub>
            </Container>
        </Terapeuta>
    )
}
const styles = {
    tr1: {
        backgroundColor: " #EAEAEA",
        color: "#9586AC",
        boxShadow: "0 0 3px 1px silver",
        padding: 5,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        height: 50,
        minWidth: 100
    },
    tr2: {
        backgroundColor: "#00B19D",
        color: "#fff",
        padding: '5px 22px',
        boxShadow: "0 0 3px 1px silver",
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        marginBottom: 5,
        height: 50,
        minWidth: 100
    },
    tr3: {
        backgroundColor: "#E6C833",
        color: "#fff",
        padding: 5,
        width: 100,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trHora: {
        backgroundColor: "#DFCDF8",
        color: "#9586AC",
        padding: 5,
        boxShadow: "0 0 2px 1px silver",
        width: 100,
        height: 80,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trVazio: {
        backgroundColor: "#fff",
        padding: 5,
        width: 100,
        height: 80,
        boxShadow: "0 0 3px 1px silver",
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
    },
    trOcupado: {
        backgroundColor: "#35D058",
        color: "#fff",
        padding: 5,
        width: 100,
        boxShadow: "0 0 3px 1px silver",
        height: 80,
        borderRadius: 10,
        borderColor: '#F5F7FA',
        borderStyle: 'solid',
        cursor: 'pointer',
    },
    linha: {
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
        color: '#fff'
    },
    presencial: { backgroundColor: "#35D058" },
    online: { backgroundColor: "#3B9E52" },
    domiciliar: { backgroundColor: "#075C1A" },
    conflitoTerapeuta: { backgroundColor: "#E98232" },
    conflitoPaciente: { backgroundColor: "#F94181" },
    avaliacao: { backgroundColor: "#EED10F" },
    reposicao: { backgroundColor: "#C901E8" },
    reserva: { backgroundColor: "#2781DA" },
    substituicao: { backgroundColor: "#e16ff2" },
    reposicao_feita: { backgroundColor: "#afafaf" },
};
export default TerapeutaAgenda