import styled from 'styled-components';
import { shade } from 'polished';

export const ListaSolicitacoes = styled.div`
  background-color: rgb(188, 188, 188);
  position: absolute;
  font-size: 12pt;
  z-index: 40;

  padding: 10px;
  width: 300px !important;
  height: 250px;
  border-radius: 10px;
  text-align: center;
  margin: 20px 0 0 0;
`;

export const Container = styled.div`
  background: #F5F7FA;
  max-width: 1280px;
  margin: 0 auto;
`;

export const Calendar = styled.aside`
  width: 380px;
  .DayPicker {
    background: #28262e;
    border-radius: 10px;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }
  .DayPicker-Day--today {
    font-weight: normal;
  }
  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: #ff9000 !important;
    border-radius: 10px;
    color: #232129 !important;
  }
`;

export const ContainerBotoes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 450px;
`;

export const BotaoAcao = styled.button`
  background-color: #7441BF;
  font-family: Nunito, sans-serif;
  color: #FFFFFF;
  padding: 2px 32px;
  border-radius: 6px;
  margin: 10px 0;
  width: 220px;
  border: 0;
  font-weight: bold;
`;


export const Autorizado = {
  backgroundColor: '#00b19d',
  color: '#fff',
};

export const Pendente = {
  backgroundColor: '#f76397',
  color: '#fff',
};

export const Executado = {
  backgroundColor: '#7441bf',
  color: '#fff',
};

export const LabelFilter = {
  color: "#7441BF",
  fontWeight: "bold",
  fontSize: "18px",
  marginBottom: 30,
  marginTop: -10,
};

export const LabelDefault = {
  marginRight: 5,
  fontWeight: "bold",
};

export const LabelPaciente = {
  marginRight: 5,
  fontWeight: "bold",
  marginTop: 10
};

export const LabelPlanoSaude = {
  marginRight: 70,
  fontWeight: "bold",
  marginTop: 10
};

export const LabelTerapeuta = {
  marginRight: 5,
  fontWeight: "bold",
  marginTop: 20
};

export const DivDisplay = {
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center"
};

export const DivContentDisplay = {
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: 30,
};

export const OptionsStyle = {
  width: "25%",
  borderRadius: 8,
  height: 36,
  boxShadow: "0 0 10px 0px silver",
  border: "none",
  marginRight: 50,
  paddingLeft: 10
};

export const InputStyle = {
  width: "25%",
  borderRadius: 8,
  height: 36,
  boxShadow: "0 0 10px 0px silver",
  border: "none",
  padding: 12,
  marginRight: 50,
};

export const DateStyle = {
  borderRadius: 8,
  height: 40,
  //boxShadow: "0 0 10px 0px silver", 
  border: "none",
  margin: '2px',
  paddingBottom: '2px',
  paddingTop: '2px',
  paddingLeft: '10px',
  paddingRight: '10px',
  visibility: 'visible',
  color: 'hsl(0, 0%, 20%)',
  webkitFlex: '1 1 auto',
  msFlex: '1 1 auto',
  flex: '1 1 auto',
};

export const SelectStyle = {
  height: 10,
  margin: "auto",
  width: "25%",
  paddingRight: 10,
};

export const TerapeutaStyle = {
  display: "flex",
  alignContent: "left",
  justifyContent: "left",
  alignItems: "left",
};

export const PacienteStyle = {
  width: "28%",
  borderRadius: 8,
  height: 36,
  border: "none",
  padding: 12,
  marginRight: 57,
};

export const linhasSolicitacao = {
  aguardando: { backgroundColor: "yelow" },
  aprovado: { backgroundColor: "green" },
  reprovado: { backgroundColor: "#b80000" }
}