import styled from "styled-components"

export const Modal = styled.div`
flex-direction: column; 
width: 100%;
height: 100vh;
position: fixed;
top: 0;
left: 0;
z-index: 10;
background-color: rgba(0, 0, 0, 0.8);
display: flex;
justify-content: center;
align-items: center;
align-content: center;`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  align-items: stretch;
  color: #000;
  width: 35%;
  height: auto;
  border-radius: 10px;
  padding: 30px 0;
  overflow-y: auto;
`;
