import React, {useEffect} from 'react';
import {Modal} from '../../../../../components/Modais/styles';
import {ModalContainer} from '../../../../../components/Modais/PacienteAltasModal/styles';
import { Form } from '@unform/web';
import TextArea from '../../../../../components/TextArea';
import { useRef } from 'react';
import PacienteService from '../../../../../services/PacienteService';
import { useSnackbar } from '../../../../../context/snackbar';
import Swal from 'sweetalert2';

function ModalAnamnese({ onClose, paciente }) {

    const id = 'modal-terapeuta-anamnese';
    const formRef = useRef();
    const snackbar = useSnackbar();

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const loadAnamnesePaciente = () => {
        PacienteService.show(paciente.id_paciente).then(({data}) => {
            formRef.current.setData({
                desenvolvimento_social: data.desenvolvimento_social,
                alimentacao: data.alimentacao,
                fala_linguagem: data.fala_linguagem,
                historico: data.historico,
            });
        })
    }

    const handleSubmit = (data) => {

        if (data.desenvolvimento_social === '' || data.fala_linguagem === '', data.alimentacao === '' ||
            data.historico === '') {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                const submitData = {
                    desenvolvimento_social: data.desenvolvimento_social,
                    alimentacao: data.alimentacao,
                    fala_linguagem: data.fala_linguagem,
                    historico: data.historico,
                };
                PacienteService.update(submitData, paciente.id_paciente).then(() => {
                    snackbar.displayMessage('Cadastro feito com sucesso!', 'success');
                    onClose();
                }).catch(e => {
                    Swal.fire('Houve um problema ao fazer o cadastro!', '', 'error');
                });
            };
    };

    useEffect(() => {
      loadAnamnesePaciente();
    }, []);

  return (
    <Modal id={id} onClick={handleOutsideClick}>
            <ModalContainer style={{overflowY: 'auto'}}>
            <div style={{position: "relative"}}>
                <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
                right: "25px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
                x
                </label>
            </div>
            <div className='d-flex align-items.center justify-content-center'>
                <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Anamnese do paciente</label>
            </div>
            <Form onSubmit={handleSubmit} ref={formRef}>
                <div className='d-flex flex-column px-5' style={{fontSize: '15px', color: '#4906AB', fontWeight: '600'}}>
                    <TextArea title="Histórico:" name="historico" />
                    <TextArea title="Desenvolvimento social:" name="desenvolvimento_social" />
                    <TextArea title="Aspectos de fala e linguagem:" name="fala_linguagem" />
                    <TextArea title="Alimentação:" name="alimentacao" />
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className='m-3 align-self-center' style={{
                        backgroundColor: '#7340BD',
                        color: '#FFFFFF',
                        fontFamily: "Nunito, sans-serif",
                        border: "none",
                        padding: "5px 22px",
                        borderRadius: "10px",
                    }} onClick={e => onClose()}>
                        Voltar
                    </button>
                    <button className='m-3 align-self-center' style={{
                        backgroundColor: '#7340BD',
                        color: '#FFFFFF',
                        fontFamily: "Nunito, sans-serif",
                        border: "none",
                        padding: "5px 22px",
                        borderRadius: "10px",
                    }} type='submit'>
                        Confirmar
                    </button>
                </div>
            </Form>
            </ModalContainer>
        </Modal>
  )
}

export default ModalAnamnese