import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import EstacaoService from "../../../../services/EstacaoService";
import TableEstacao from "./TableEstacao";
import EstacaoModal from "../../../../components/Modais/EstacaoModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Estacao() {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#8ed4ae");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Estacoes, setEstacoes] = useState([]);
    const [modalData, setModalData] = useState(null);

    const loadEstacoes = () => {
        EstacaoService.index('with=sala')
            .then(({ data }) => {
                data.sort((a, b) => {
                    if(a.estacao < b.estacao){
                        return -1;
                    }
                    if(a.estacao > b.estacao){
                        return 1;
                    }
                    return 0;
                });
                setEstacoes(data);
            })
            .finally(() => setLoading(false));
    }

    const handleNewEstacao = () => {
        setModalData(null);
        setIsOpen(true);
    }

    const handleEditModal = (data) => {
        setModalData(data);
        setIsOpen(true);
    }

    useEffect(() => {
        loadEstacoes();
    }, []);


    return (
        <Admin>
            <Container>

                <div>
                    <button onClick={() => handleNewEstacao()} className="btn-padrao">
                        <AiFillFileAdd style={{ marginRight: 5 }} />
                        Nova Estação
                    </button>
                </div>
                <div>
                    {
                        (Estacoes.length !== 0 && loading === false)
                            ? <TableEstacao callback={loadEstacoes} source={Estacoes} editModal={handleEditModal} />
                            : <ClipLoader color={color} loading={loading} css={override} size={50} />
                    }
                </div>

                {modalIsOpen && <EstacaoModal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    callback={loadEstacoes}
                    modalData={modalData}
                    estacoesList={Estacoes}
                />}

            </Container>
        </Admin>
    )
}
