// src/components/PieChart/index.jsx
import React, { useState, useEffect, Children } from "react";
import { Pie } from "react-chartjs-2";

/**
 * Essa função se trata de um elemento react que cria um grafico de torta utilizando o ReactChartJS.
 * @param {[Array, Array, String, any, boolean, boolean, String]} param0 chartData: Recebe a informação pra construção do grafico. options: Recebe as opções customizadas para o grafico, se nulo, colocam as opções padrão. titulo: Recebe o titulo do grafico, se nulo fica sem titulo. children: Recebe as informações a serem colocadas em baixo do grafico.
 * @returns 
 */
export default function PieChart({
    chartData = null,           //Vetor relacionado aos dados do grafico
    options = null,             //Vetor relacionado às opções de customização do grafico
    titulo = 'Grafico Torta',   //Texto de titulo do grafico
    children,                   //Elementos a serem colocados embaixo do grafico
    esperarData,                //Define se sera utilizada a data genérica caso não haja data
    debug = false,              //Define se sera feito o debug dos dados
    maintainAspectRatio = false,//Define o tamanho padrão travado
    className = '',             //Adiciona uma classe css no elemento div que engloba o grafico
    tituloFontSize = '1rem',    //Tamanho da fonte do titulo
}) {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const [debugVar, setDebugVar] = useState(null);
    const dataGenerica = {
        labels: ['Red', 'Orange', 'Blue'],
        datasets: [{
            label: 'Popularity of colours',
            data: [55, 23, 96],
            borderWidth: 1,
        }]
    };
    const mappedChildren = Children.map(children, child =>
        <div className="Row">
            {child}
        </div>
    );
    const [opt, setOpt] = useState({ ...options, maintainAspectRatio: maintainAspectRatio });
    const [tituloLocal, setTituloLocal] = useState(titulo);
    const [telaCarregada, setTelaCarregada] = useState(false);
    const [chartDataLocal, setChartDataLocal] = useState(chartData);
    const [titleFontSizeLocal, setTitleFontSizeLocal] = useState(tituloFontSize);
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const footer = async (tooltipItems) => {
        let sum = 0;
        let dataAtual = 1;
        tooltipItems.forEach(function (tooltipItem, index) {
            dataAtual = tooltipItem.dataset.data[tooltipItem.datasetIndex];
            setDebugVar(tooltipItem.dataset.data);
            sum += tooltipItem.dataset.data.reduce((acumulador, elemento) => {
                return acumulador + elemento;
            }, 0);
        });
        return (100 * dataAtual) / sum + " % Total: " + sum + " Atual: " + dataAtual;
    };
    const criaDadosGrafico = async () => {
        setOpt({
            ...(options != null ? options : []),
            plugins: {
                tooltip: {
                    callbacks: {
                        footer: function (tooltipItems) {
                            let sum = 0;
                            let dataAtual = 1;

                            for (let index = 0; index < tooltipItems.length; index++) {
                                const el = tooltipItems[index];

                                dataAtual = el.dataset.data[el.dataIndex];

                                sum += el.dataset.data.reduce((acumulador, elemento) => {
                                    return acumulador + elemento;
                                }, 0);
                            }
                            return parseFloat((100 * dataAtual) / sum).toFixed(2) + " % - Total: " + sum;
                        },
                    }
                },
                legend: {
                    position: 'right'
                }
            }
        });
        setTituloLocal(titulo);
        setChartDataLocal((chartData == null ? (esperarData == null) ? null : dataGenerica : chartData));
    }
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    useEffect(() => {
    }, [debugVar])
    useEffect(() => {
        criaDadosGrafico();
    }, [chartData, titulo, options]);
    useEffect(() => {
        setTelaCarregada(true);
    }, []);
    //#endregion
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <div className={className}>
            <h2 style={{ textAlign: "center", fontSize: titleFontSizeLocal }}>{tituloLocal}</h2>

            {(chartDataLocal != null && telaCarregada)
                ? <Pie data={chartDataLocal} options={opt} />
                : 'Aguardando informações para o grafico.'
            };
            {(mappedChildren != null) ? mappedChildren : ''};
        </div>
    );
    //#endregion
}