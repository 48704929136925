import api from "./api";

class ResponsavelService {
    static index = (type) => {
        switch (type) {
            case 'responsavel':
                return api.get(`api/responsavel`);
            case 'vinculo':
                return api.get(`api/paciente-responsavel`);
            default:
                return console.error('Tem algo errado com o tipo de busca');
        }

    }
    static store = (obj, type = 'responsavel') => {
        switch (type) {
            case 'responsavel':
                return api.post(`api/responsavel`, obj);
            case 'vinculo':
                return api.post(`api/paciente-responsavel`, obj);
            default:
                return console.error('Tem algo errado com o tipo de cadastro');
        }

    }
    static show = (id, type = 'responsavel') => {
        switch (type) {
            case 'responsavel':
                return api.get(`api/responsavel/${id}`);
            case 'vinculo':
                return api.get(`api/paciente-responsavel/${id}`);
            default:
                return console.error('Tem algo errado com o tipo de busca');
        }
    }
    static update = (obj, id) => {
        return api.put(`api/responsavel/${id}`, obj);
    }
    static destroy = (id, type = 'responsavel') => {
        switch (type) {
            case 'responsavel':
                return api.delete(`api/responsavel/${id}`);
            case 'vinculo':
                return api.delete(`api/paciente-responsavel/${id}`);
            default:
                return console.error('Tem algo errado com o tipo de exclusão');
        }
    }
    static byPaciente = (pacienteId, type = 'responsavel') => {
        switch (type) {
            case 'responsavel':
                return api.get(`api/responsavel?filter[id_paciente]=${pacienteId}`);
            case 'vinculo':
                return api.get(`api/paciente-responsavel?filter[paciente_id]=${pacienteId}`);
            default:
                return console.error('Tem algo errado com o tipo de busca');
        }
    }
}

export default ResponsavelService;
