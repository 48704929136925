import React, { useEffect, useRef, useState } from 'react';
import { Container, Modal } from '../styles';
import { Form } from '@unform/web';
import Select from '../../Select';
import Input from '../../Input';
import TextArea from '../../TextArea';
import { useAuth } from '../../../context/auth';
import PacienteService from '../../../services/PacienteService';
import api from '../../../services/api';
import PlanoTerapeuticoService from '../../../services/PlanoTerapeuticoService';
import { useSnackbar } from '../../../context/snackbar';
import Assinatura from '../../Assinatura';
import moment from 'moment';

export default function PlanoTerapeuticoModal({ onClose, planoTerapeutico, pacienteData }) {
    /*==VARS INFORMAÇÕES da TELA==*/
    const titulo = 'MODAL PLANO TERAPÊUTICO'
    const { user } = useAuth();
    const terapeuta = useState(user.terapeuta !== undefined ? user.terapeuta[0] : null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState();
    const [responsavelSelecionado, setResponsavelSelecionado] = useState();
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState();
    const [terapiaSelecionada, setTerapiaSelecionada] = useState();
    const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState();
    const [pacienteOpcoes, setPacienteOpcoes] = useState();
    const [responsavelOpcoes, setResponsavelOpcoes] = useState();
    const [terapeutaOpcoes, setTerapeutaOpcoes] = useState();
    const [terapiaOpcoes, setTerapiaOpcoes] = useState();
    const [especialidadeOpcoes, setEspecialidadeOpcoes] = useState();
    const [recusarModal, setRecusarModal] = useState(false);
    const [motivoRecusa, setMotivoRecusa] = useState('');
    const snackbar = useSnackbar();
    const formRef = useRef();
    const sigCanvas = useRef({});
    const modalId = 'modal-plano-terapeutico';

    const handleOutsideClick = (e) => {
        if (e.target.id === modalId) onClose();
    };

    const organizeData = () => {
        return {
            ...formRef.current.getData(),
            especialidade_id: especialidadeSelecionada.value,
            paciente_id: pacienteSelecionado.id,
            responsavel_id: responsavelSelecionado.id === undefined ? responsavelSelecionado.value : responsavelSelecionado.id,
            terapeuta_id: terapeutaSelecionado.id,
            terapia_id: terapiaSelecionada.value,
        };
    };

    const handleStatusPlanoTerapeutico = (status) => {
        let data = { status: status };

        if (status === PlanoTerapeuticoService.status().ASSINADO) {
            const assinatura = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
            data = { ...data, assinatura: assinatura, assinado_em: moment().format('YYYY/MM/DD HH:mm:ss') };
            PlanoTerapeuticoService.update(data, planoTerapeutico.id)
                .then(({ data }) => {
                    snackbar.displayMessage('Plano terapêutico atualizado com sucesso', 'success');
                    onClose(data.paciente_id, 'update');
                })
                .catch(response =>
                    snackbar.displayMessage('Houve um erro ao assinar plano terapêutico, atualize a página e tente novamente', 'error'));
        } else {
            PlanoTerapeuticoService.updateStatus(data, planoTerapeutico.id)
                .then(({ data }) => {
                    snackbar.displayMessage('Plano terapêutico atualizado com sucesso', 'success');
                    onClose(data.paciente_id, 'update');
                })
                .catch((error) => {
                    console.error(error);
                    snackbar.displayMessage('Houve um erro ao recusar plano terapêutico, atualize a página e tente novamente', 'error');
                });
        }

    };

    const handleSubmit = () => {
        if (!(especialidadeSelecionada && pacienteSelecionado && responsavelSelecionado && terapeutaSelecionado && terapiaSelecionada)) {
            snackbar.displayMessage('Por favor, preencha todos os campos', 'error');
        }
        const data = organizeData();
        const obj = { ...data, status: 'Pendente' };
        PlanoTerapeuticoService.store(obj)
            .then(() => {
                snackbar.displayMessage('Plano terapêutico salvo com sucesso', 'success');
                onClose(data.paciente_id);
            })
            .catch((response) => {
                console.error(response);
                snackbar.displayMessage('Houve um erro ao salvar plano terapêutico, atualize a página e tente novamente', 'error');
            });
    };

    const handleEdit = () => {
        if (!(especialidadeSelecionada && pacienteSelecionado && responsavelSelecionado && terapeutaSelecionado && terapiaSelecionada)) {
            snackbar.displayMessage('Por favor, preencha todos os campos', 'error');
        }
        const data = organizeData();
        PlanoTerapeuticoService.update(data, planoTerapeutico.id)
            .then(({ data }) => {
                snackbar.displayMessage('Plano terapêutico atualizado com sucesso', 'success');
                onClose(data.paciente_id);
            })
            .catch(response =>
                snackbar.displayMessage('Houve um erro ao atualizar plano terapêutico, atualize a página e tente novamente', 'error'));
    };

    const handleChangePaciente = (paciente) => {
        setPacienteSelecionado(paciente);
        setResponsavelOpcoes([{
            ...paciente.responsavel,
            value: paciente.responsavel.id,
            label: paciente.responsavel.nome,
        }]);
    };

    const confirmarRecusa = () => {
        handleSubmit();
    }

    const handleChangeMotivoRecusa = (value) => {
        setMotivoRecusa(value);
        console.error(value);
    }

    const fetchPacientes = async () => {
        let response;
        let data;
        if (pacienteData !== null) {
            data = { ...pacienteData, value: pacienteData.id, label: pacienteData.nome, responsavel: { id: pacienteData.responsavel_id, nome: pacienteData.responsavel } };
            setPacienteSelecionado(data);
            setPacienteOpcoes([data]);
            getDefaultPaciente([data]);
            let responsavel = data.responsavel;
            setResponsavelSelecionado({ ...responsavel.responsavel, value: responsavel.id, label: responsavel.nome });
        } else {
            response = await PacienteService.getPacienteByTerapeuta(terapeuta[0].id);
            setPacienteOpcoes(data);
            getDefaultPaciente(data);
        }
    };

    const fetchTerapeutas = async () => {
        let lista, listaTerapeuta;
        if (terapeuta !== null) {
            await api.get(`/api/terapeuta/${terapeuta[0].id}?with=terapeuta_terapia.terapium,terapeuta_especialidades.especialidade`)
                .then(({ data }) => {
                    data = [{ ...data, value: data.id, label: data.nome }];
                    lista = data;
                    listaTerapeuta = data;
                    setTerapeutaOpcoes(data);
                    getDefaultTerapeuta(data);
                    if (data.length === 1) {
                        setTerapeutaSelecionado(data[0]);
                    }
                    for (let index = 0; index < listaTerapeuta.length; index++) {
                        const value = listaTerapeuta[index];
                        if (value.id === terapeuta[0].id) {
                            setTerapeutaSelecionado(listaTerapeuta[index]);
                            break;
                        }
                    }
                });
        } else {
            await api.get(`/api/terapeuta?with=terapeuta_terapia.terapium,terapeuta_especialidades.especialidade`)
                .then(({ data }) => {
                    data = data.map(terapeuta => ({ ...terapeuta, value: terapeuta.id, label: terapeuta.nome }));
                    lista = data;
                    setTerapeutaOpcoes(data);
                    getDefaultTerapeuta(data);
                    if (data.length === 1) {
                        setTerapeutaSelecionado(data[0]);
                    }
                });
        }
        return lista;
    };

    const getDefaultPaciente = (pacientes) => {
        if (!planoTerapeutico) return;
        const pacienteTemp = pacientes.filter(pacienteOpcao => {
            return pacienteOpcao.value === planoTerapeutico.paciente_id;
        })[0];
        setPacienteSelecionado(pacienteTemp);
        const responsavel = pacienteTemp.responsavel;
        setResponsavelOpcoes([{
            ...responsavel,
            value: responsavel.id,
            label: responsavel.nome,
        }]);
    };

    const getDefaultTerapeuta = (terapeutas) => {
        if (!planoTerapeutico) return;
        const terapeutaTemp = terapeutas.filter(terapeuta => {
            return terapeuta.value === planoTerapeutico.terapeuta_id;
        })[0];
        setTerapeutaSelecionado(terapeutaTemp);
    };

    const getDefaultTerapiaEspecialidade = (especialidades, terapias) => {
        if (!planoTerapeutico) return;
        const especialidade = especialidades.filter(especialidade => {
            return especialidade.value === planoTerapeutico.especialidade_id;
        })[0];
        setEspecialidadeSelecionada(especialidade);
        const terapia = terapias.filter(terapia => {
            return terapia.value === planoTerapeutico.terapia_id;
        })[0];
        setTerapiaSelecionada(terapia);
    };

    const getDefaultResponsavel = () => {
        if (!planoTerapeutico || !pacienteSelecionado) return;
        const responsavel = pacienteSelecionado.responsavel;
        //? //QUESTION?: hoje vem apenas um responsavel, mas o correto não é vir vários?
        setResponsavelSelecionado({ ...responsavel.responsavel, value: responsavel.id, label: responsavel.nome });
    };

    useEffect(() => {
        getDefaultResponsavel();
    }, [pacienteSelecionado]);


    useEffect(async () => {
        await fetchPacientes();
        await fetchTerapeutas();
    }, []);

    useEffect(() => {
        if (!terapeutaSelecionado) return;
        const terapias = terapeutaSelecionado.terapeuta_terapia.map(terapia => ({
            ...terapia,
            value: terapia.terapium.id,
            label: terapia.terapium.terapia,
        }));
        setTerapiaOpcoes(terapias);
        const especialidades = terapeutaSelecionado.terapeuta_especialidades.map(especialidade => ({
            ...especialidade,
            value: especialidade.especialidade.id,
            label: especialidade.especialidade.especialidade,
        }));
        setEspecialidadeOpcoes(especialidades);
        getDefaultTerapiaEspecialidade(especialidades, terapias);
    }, [terapeutaSelecionado]);


    return (
        <>
            <Modal id={modalId} onClick={handleOutsideClick}>
                <div className='div-modal' style={{ minWidth: '700px', alignItems: 'center', height: '80%', overflowY: 'auto' }}>
                    <div className='w-100per d-flex justify-content-between align-bottom'>
                        <p></p>
                        <label className='titulo-modal-24'>Plano Terapêutico</label>
                        <label className='btn-outline' onClick={() => onClose()}>x</label>
                    </div>
                    <Form ref={formRef} className='w-100per'>
                        <div className='row flex-wrap'>
                            <div className='col-6'>
                                <Select label='Paciente:' name='paciente_id' options={pacienteOpcoes} value={pacienteSelecionado}
                                    onChange={handleChangePaciente} />
                            </div>
                            <div className='col-6'>
                                <Select label='Responsável:' name='responsavel_id' options={responsavelOpcoes}
                                    onChange={setResponsavelSelecionado}
                                    value={responsavelSelecionado} />
                            </div>

                            <div className='col-6'>
                                {
                                    terapeuta === null
                                        ? <Select label='Terapeuta:' name='terapeuta_id' options={terapeutaOpcoes} value={terapeutaSelecionado} defaultValue={terapeutaSelecionado} onChange={setTerapeutaSelecionado} />
                                        : <Select label='Terapeuta:' name='terapeuta_id' options={terapeutaOpcoes} defaultValue={terapeutaSelecionado} value={terapeutaSelecionado} />
                                }
                            </div>
                            <div className='col-6'>
                                <Select label='Terapia:' name='terapia_id' options={terapiaOpcoes} value={terapiaSelecionada}
                                    onChange={setTerapiaSelecionada} />
                            </div>

                            <div className='col-6'>
                                <Select label='Especialidade:' name='especialidade_id' options={especialidadeOpcoes}
                                    value={especialidadeSelecionada} onChange={setEspecialidadeSelecionada} />
                            </div>
                            <div className='col-6'>
                                <Input title='Validade (meses):' type='number' name='validade' readOnly={'readOnly'}
                                    defaultValue={planoTerapeutico ? planoTerapeutico.validade : 6} />
                            </div>

                            <div className='col-12'>
                                <TextArea title='Objetivos Gerais:' name='objetivo_geral'
                                    defaultValue={planoTerapeutico ? planoTerapeutico.objetivo_geral : ''} />
                            </div>

                            <div className='col-12'>
                                <TextArea title='Objetivos Específicos:' name='objetivo_especifico'
                                    defaultValue={planoTerapeutico ? planoTerapeutico.objetivo_especifico : ''} />
                            </div>

                            <div className='col-12'>
                                <TextArea title='Metodologias:' name='metodologias'
                                    defaultValue={planoTerapeutico ? planoTerapeutico.metodologias : ''} />
                            </div>

                            <div className='col-12'>
                                <div style={{ width: '100%' }}>
                                    <Input style={{ padding: 5 }} type={'file'} title='Arquivo:' name='arquivo' id={'arquivo'} />
                                </div>
                            </div>
                        </div>
                        <div className='row p-2'></div>
                        <div className='row flex-wrap'>
                            {user.tipo_user === 'coordenacao' || user.tipo_user === 'terapeuta'
                                ? <div className='col-12 d-flex justify-center align-center'>
                                    {planoTerapeutico ?
                                        <button onClick={handleEdit} className='btn-padrao'>Editar </button>
                                        :
                                        <button onClick={handleSubmit} className='btn-padrao'>Criar </button>}
                                </div>
                                : (user.tipo_user === 'responsavel')
                                    ? <>
                                        {recusarModal
                                            ? <div className=''>
                                                <TextArea title={'Motivo do retorno.'} name={'motivo_recusa'} value={motivoRecusa} defaultValue={motivoRecusa} onChange={(e) => handleChangeMotivoRecusa(e.target.value)}></TextArea>
                                                <button className='btn-padrao' type='button' onClick={() => { setMotivoRecusa(''); setRecusarModal(false) }}>Voltar</button>
                                                <button className='btn-padrao' type='button' onClick={handleStatusPlanoTerapeutico(PlanoTerapeuticoService.status().RECUSADO)}>Confirmar Recusa</button>
                                            </div>
                                            : <>
                                                <div className="col-12 flex-wrap justify-content-center align-content-center text-center p-2">
                                                    <button onClick={() => { setRecusarModal(true); setMotivoRecusa(''); }} className='btn-padrao btn-vermelho'>Recusar </button>
                                                </div>
                                                <div className='col-12 flex-wrap justify-content-center align-content-center text-center p-2'>
                                                    <div className='w-50per m-auto'>
                                                        <Assinatura sigCanvas={sigCanvas} />
                                                    </div>
                                                    <button onClick={() => handleStatusPlanoTerapeutico(PlanoTerapeuticoService.status().ASSINADO)} className='btn-padrao'>Assinar </button>
                                                </div>
                                            </>
                                        }
                                    </>
                                    : <></>
                            }
                        </div>
                    </Form>
                </div>
            </Modal>
        </>
    );
}
