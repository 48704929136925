import React, { useEffect, useState } from "react";
import { Modal, Container } from './styles'
import { Form } from '@unform/web'
import Input from '../../../../../components/Input';
import { GoCalendar } from "react-icons/go";

import moment from "moment";
import {useNavigate} from "react-router-dom";
import api from '../../../../../services/api';
import Select from '../../../../../components/Select';

moment.locale();

export default function DetalhesDoAgendamento({
    agendaButtons,
    isUsuarioEdicaoAgendamento,
    id = 'modalDetalhesDoAtendimento',
    agendamentoId,
    onClose = () => {
    },
    setHouveExclusaoAgendamento = () => {
    },
}) {
    const [agendamento, setAgendamento] = useState();
    const dayName = ["Domingo", "Segunda - Feira", "Terça - Feira", "Quarta - Feira", "Quinta - Feira", "Sexta - Feira", "Sábado"];
    
    const [editaAgendamento, setEditaAgendamento] = useState(false);

    const [terapiaList, setTerapiaList] = useState([]);
    const [especialidadeList, setEspecialidadeList] = useState([]);
    const [modalidadeList, setModalidadeList] = useState([]);
    const [tipoAtendimentoList, setTipoAtendimentoList] = useState([]);
    const reservaList = [{ value: 1 , label: 'Sim'}, { value: 0 , label: 'Não'}];

    const [terapiaSelecionada, setTerapiaSelecionada] = useState(null);
    const [especialidadeSelecionada, setEspecialidadeSelecionada] = useState(null);
    const [modalidadeSelecionada, setModalidadeSelecionada] = useState(null);
    const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);
    const [reservaSelecionada, setReservaSelecionada] = useState(null);
    const [comentarioAlteracao, setComentarioAlteracao] = useState(null);
    const [isDadosCarregadosEdicao, setIsDadosCarregadosEdicao] = useState(false);

    const navigate = useNavigate();

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    }

    const getAgendamento = () => {
        api.get(`api/agendamento/${agendamentoId}?with=terapium,especialidade,modalidade,tipo_atendimento,`+
            `terapeutum.terapeuta_terapia.terapium,terapeutum.terapeuta_especialidades.especialidade`).then(response => {
            setAgendamento(response.data);
        });
    }

    const getCamposSelect = () => {
        const selectTerapia = agendamento.terapeutum.terapeuta_terapia.map(d => ({
            'value': d.terapia_id,
            'label': d.terapium.terapia,
        }));
        setTerapiaList(selectTerapia);

        const selectEspecialidade = agendamento.terapeutum.terapeuta_especialidades.map(d => ({
            'value': d.especialidade_id,
            'label': d.especialidade.especialidade,
        }));
        setEspecialidadeList(selectEspecialidade);

        api.get(`api/modalidade`).then(response => {
            const selectModalidade = response.data.map(d => ({
                'value': d.id,
                'label': d.modalidade,
            }));
            setModalidadeList(selectModalidade);
        });

        api.get(`api/tipo-atendimento`).then(response => {
            const selectTipoAtendimento = response.data.map(d => ({
                'value': d.id,
                'label': d.tipo_atendimento,
            }));
            setTipoAtendimentoList(selectTipoAtendimento);
        });
    }

    const setDefaultValues = () => {
        const terapia = terapiaList.filter(terapia => terapia.value === agendamento.terapia_id)[0];
        setTerapiaSelecionada(terapia);
        const especialidade = especialidadeList.filter(especialidade => especialidade.value === agendamento.especialidade_id)[0];
        setEspecialidadeSelecionada(especialidade);
        const modalidade = modalidadeList.filter(modalidade => modalidade.value === agendamento.modalidade_id)[0];
        setModalidadeSelecionada(modalidade);
        const tipoAtendimento = tipoAtendimentoList.filter(tipoAtendimento => tipoAtendimento.value === agendamento.tipo_atendimento_id)[0];
        setTipoAtendimentoSelecionado(tipoAtendimento);
        const reserva = reservaList.filter(reserva => reserva.value === agendamento.reserva)[0];
        setReservaSelecionada(reserva);
        setIsDadosCarregadosEdicao(true);
    }

    const handleEditAgendamento = () => {
        if (!editaAgendamento) {
            setEditaAgendamento(true);
            return;
        }
        let data = {
            terapia_id: terapiaSelecionada.value,
            especialidade_id: especialidadeSelecionada.value,
            modalidade_id: modalidadeSelecionada.value,
            tipo_atendimento_id: tipoAtendimentoSelecionado.value,
            reserva: reservaSelecionada.value,
            comentario: comentarioAlteracao
        }
        api.patch(`api/agendamento/${agendamentoId}`, data).then(response => {
            getAgendamento();
            setEditaAgendamento(false);
        });
    }

    const handleCancelaEdicaoOuExcluiAgendamento = () => {
        if (editaAgendamento) {
            setEditaAgendamento(false);
            setIsDadosCarregadosEdicao(false);
            return;
        }
        api.delete(`api/agendamento/${agendamentoId}`).then(response => {
            setHouveExclusaoAgendamento();
            onClose();
        });
    }

    const handleChangeTerapia = (e) => {
        setTerapiaSelecionada(e);
    }

    const handleChangeEspecialidade = (e) => {
        setEspecialidadeSelecionada(e);
    }

    const handleChangeModalidade = (e) => {
        setModalidadeSelecionada(e);
    }

    const handleChangeTipoAtendimento = (e) => {
        setTipoAtendimentoSelecionado(e);
    }

    const handleChangeReserva = (e) => {
        setReservaSelecionada(e);
    }

    const showFormAgendamento = () => {
        if (!editaAgendamento) {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "row",width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Nome:" name="paciente" value={agendamento.paciente.nome} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <Input disabled title="Data:" name="data" value={dayName[moment(agendamento.data_atendimento_inicial).weekday()]} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <Input disabled title="Horario:" name="horario" value={moment.utc(agendamento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(agendamento.data_atendimento_final).format('HH:mm')} />
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Plano de saúde:" name="plano_saude" value={agendamento.paciente.plano_saude.plano} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Terapeuta:" name="Terapeuta" value={agendamento.terapeutum.nome} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>

                        <div style={{ width: "50%" }}>
                            <Input disabled title="Terapia:" name="Terapia" value={agendamento.terapium.terapia} />
                        </div>

                        <div style={{ width: "50%" }}>
                            <Input disabled title="Especialidade:" name="Especialidade" value={agendamento.especialidade.especialidade} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Modalidade:" name="Modalidade" value={agendamento.modalidade.modalidade} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Tipo de Atendimento:" name="tipoAtendimento" value={agendamento.tipo_atendimento.tipo_atendimento} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Reserva:" name="reserva" value={agendamento.reserva ? 'Sim' : 'Não'} />
                        </div>
                    </div>
                    {agendaButtons?
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0, justifyContent: "space-between" }}>
                        <button style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", justifyContent: "center",
                            width: "45%",
                            backgroundColor: "#4906AB",
                            color: "#FFFFFF",
                            border: "0",
                            height: 38,
                            borderRadius: 10,
                            marginTop: 15
                        }}
                                onClick={() => {navigate('/dashboard/recepcao/agenda/paciente/' + agendamento.paciente.id)}}>
                            <GoCalendar size={22} color={"white"} style={{ marginRight: 10 }} />
                            <label>Agenda do Paciente</label>
                        </button>

                        <button style={{
                            marginTop: 15,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", justifyContent: "center",
                            width: "45%",
                            backgroundColor: "#F76397",
                            color: "#FFFFFF",
                            border: "0",
                            height: 38,
                            borderRadius: 10
                        }}
                                onClick={() => {navigate('/dashboard/recepcao/agenda/terapeuta/' + agendamento.terapeuta_id)}}>
                            <GoCalendar size={22} color={"white"} style={{ marginRight: 10 }} />
                            <label>Agenda do Terapeuta</label>
                        </button>
                    </div>
                    :''}
                    <Input disabled title="Comentário:" name="comentario" value={agendamento.comentario} />
                </>
            )
        }
        return (
            isDadosCarregadosEdicao &&
            <>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                    <div style={{ width: '50%' }}>
                        <Input disabled title='Nome:' name='paciente' value={agendamento.paciente.nome} />
                    </div>
                    <div style={{ width: '25%' }}>
                        <Input disabled title='Data:' name='data'
                               value={dayName[moment(agendamento.data_atendimento_inicial).weekday()]} />
                    </div>
                    <div style={{ width: '25%' }}>
                        <Input disabled title='Horario:' name='horario'
                               value={moment.utc(agendamento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(agendamento.data_atendimento_final).format('HH:mm')} />
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                    <div style={{ width: '50%' }}>
                        <Input disabled title='Plano de saúde:' name='plano_saude'
                               value={agendamento.paciente.plano_saude.plano} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <Input disabled title='Terapeuta:' name='Terapeuta' value={agendamento.terapeutum.nome} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>

                    <div style={{ width: '50%' }}>
                        <Select label='Terapia:' name='terapia' options={terapiaList}
                                defaultValue={terapiaSelecionada}
                                onChange={(e) => handleChangeTerapia(e)} />
                    </div>

                    <div style={{ width: '50%' }}>
                        <Select label='Especialidade:' name='especialidade' options={especialidadeList}
                                defaultValue={especialidadeSelecionada}
                                onChange={(e) => handleChangeEspecialidade(e)} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                    <div style={{ width: '50%' }}>
                        <Select label='Modalidade:' name='modalidade' options={modalidadeList}
                                defaultValue={modalidadeSelecionada}
                                onChange={(e) => handleChangeModalidade(e)} />
                    </div>
                    <div style={{ width: '50%' }}>
                        <Select label='Tipo de Atendimento:' name='tipo_atendimento' options={tipoAtendimentoList}
                                defaultValue={tipoAtendimentoSelecionado}
                                onChange={(e) => handleChangeTipoAtendimento(e)} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
                    <div style={{ width: '50%' }}>
                        <Select label='Reserva:' name='reserva' options={reservaList}
                                defaultValue={reservaSelecionada}
                                onChange={(e) => handleChangeReserva(e)} />
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    paddingTop: 0,
                    justifyContent: 'space-between',
                }}>
                    <button style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'center',
                        width: '45%',
                        backgroundColor: '#4906AB',
                        color: '#FFFFFF',
                        border: '0',
                        height: 38,
                        borderRadius: 10,
                        marginTop: 15,
                    }}
                            onClick={() => {
                                navigate('/dashboard/recepcao/agenda/paciente/' + agendamento.paciente.id);
                            }}>
                        <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                        <label>Agenda do Paciente</label>
                    </button>

                    <button style={{
                        marginTop: 15,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center', justifyContent: 'center',
                        width: '45%',
                        backgroundColor: '#F76397',
                        color: '#FFFFFF',
                        border: '0',
                        height: 38,
                        borderRadius: 10,
                    }}
                            onClick={() => {
                                navigate('/dashboard/recepcao/agenda/terapeuta/' + agendamento.terapeuta_id);
                            }}>
                        <GoCalendar size={22} color={'white'} style={{ marginRight: 10 }} />
                        <label>Agenda do Terapeuta</label>
                    </button>
                </div>

                <Input disabled={!editaAgendamento} title='Comentário:' name='comentario'
                       onChange={(e) => setComentarioAlteracao(e.target.value)}
                       value={agendamento.comentario} />
            </>
        );
    }

    useEffect(() => {
        if (!editaAgendamento) return;
        getCamposSelect();
        showFormAgendamento();
    }, [editaAgendamento]);

    useEffect(() => {
        if (!editaAgendamento && tipoAtendimentoList.length < 1) return;
        setDefaultValues();
    }, [tipoAtendimentoList]);

    useEffect(() => {
        getAgendamento();
    }, []);

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            {agendamento && <Container>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Detalhes do Atendimento</label>
                </div>
                <Form>
                    {showFormAgendamento()}

                    {isUsuarioEdicaoAgendamento &&
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            marginTop: 30,
                        }}>

                            <button onClick={() => handleCancelaEdicaoOuExcluiAgendamento()} style={{
                                fontWeight: '600',
                                fontSize: '18', backgroundColor: '#d5d5d5', color: '#151515',
                                border: 'none', borderRadius: 8, padding: '4px 40px',
                            }}>
                                {editaAgendamento ? 'Cancelar' : 'Excluir'}
                            </button>
                            <button onClick={() => handleEditAgendamento()} style={{
                                fontWeight: '600',
                                fontSize: '18', backgroundColor: '#3baf90', color: '#FFFFFF',
                                border: 'none', borderRadius: 8, padding: '4px 40px',
                            }}>
                                {editaAgendamento ? 'Salvar' : 'Editar'}
                            </button>
                        </div>
                    }

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                        <button onClick={() => onClose()} style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }}>
                            Voltar
                        </button>
                    </div>
                </Form>
            </Container>}
        </Modal>
    )
}
