import React, {useMemo} from "react";
import {AiOutlineEdit, AiOutlineDelete} from "react-icons/ai";
import Swal from "sweetalert2";
import { TableDefault } from "../../../../components/TableDefault";
import { useSnackbar } from "../../../../context/snackbar";

export function TableMedicamentosTerapeuta({source, setModalMedicamentosData, terapeutaId, setMedicamentos, callBack, handleOpen}) {

    const snackbar = useSnackbar();

    const removeEmergencia = (data) => {
        Swal.fire({
                title: 'Deseja realmente remover este registro ?',
                showDenyButton: true,
                confirmButtonText: 'Remover',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (terapeutaId === null || terapeutaId === undefined) {
                        let medicamentosLocal = [... source];
                        const index = medicamentosLocal.indexOf(data);
                        medicamentosLocal.splice(index, 1);
                        setMedicamentos(medicamentosLocal); 
                        callBack()
                    } else {
                        //ResponsavelService.destroy(data.id);
                        callBack()
                    }
                    snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }});
    }

    const handleEditEmergencia = (data) => {
        setModalMedicamentosData(data);
        handleOpen();
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => [
            {
                Header: 'Medicamentos:',
                accessor: 'medicamentos',
            },
            {
                Header: 'Horário:',
                accessor: 'medicamentos_horarios',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({cell}) => (
                    (   
                        <div style={{ display: "flex", alignItems: "center",
                            justifyContent: "center", backgroundColor: "transparent", margin: 5}}>
                            <AiOutlineEdit style={{cursor: "pointer"}} color="#7340BD"
                                 size={22} onClick={e => handleEditEmergencia(cell.row.original)}/>
                            <AiOutlineDelete
                                style={{cursor: 'pointer'}}
                                color="#7340BD" size={22}
                                onClick={e => removeEmergencia(cell.row.original)}/>      
                        </div>
                    )
                )
            }
        ],
        []
    )

  return (
      <TableDefault columns={columns} source={data} />
  );
}
