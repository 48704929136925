import styled from 'styled-components';

export const Container = styled.div`
    min-width: 20%;
    height: 330px;
`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
`;
export const ContainerName = styled.div`
    background: silver; 
    grid-area: name;
    padding: 40px 0px;
`;
export const ContainerCards = styled.div`
    background: red; 
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
`;
export const ContainerSolicitacoes = styled.div`
    background: black;
    grid-area: footer;
`;
