import styled from 'styled-components';

export const Container = styled.div`
  background: #F5F7FA;
  max-width: 100vw;
  margin: 0 auto;
`;

export const DivContentDisplay = {
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: 30,
  backgroundColor: 'rgb(149, 134, 172) !important',
};
/**
 * Gera um objeto de estilos com base nas configurações fornecidas.
 * 
 * @param {number|Object} [baseWidth=100] - Largura base ou um objeto contendo todas as propriedades.
 * @param {number} [baseHeight=100] - Altura base.
 * @param {number} [baseWidthHora=100] - Largura base para a hora.
 * @param {number} [baseHeightHora=100] - Altura base para a hora.
 * @param {boolean} [isFullScreen=false] - Indica se está em modo tela cheia.
 * @param {boolean} [esperaMode=false] - Indica se está no modo de espera.
 * @param {Array} [differenceBase=['10px', '345px']] - Base de diferença para cálculos.
 * @returns {Object} Objeto contendo todos os estilos gerados.
 */
const listOfStyles = (baseWidth = 100, baseHeight = 100, baseWidthHora = 100, baseHeightHora = 100, isFullScreen = false, esperaMode = false, differenceBase = [19, 345]) => {
  //#region VALIDAÇÃO DE PARÂMETROS
  let baseWidthLocal;
  let baseHeightLocal;
  let baseWidthHoraLocal;
  let baseHeightHoraLocal;
  let isFullScreenLocal;
  let esperaModeLocal;
  let differenceBaseLocal;
  if (typeof baseWidth === 'object') {
    if (baseWidth.baseWidth) {
      baseWidthLocal = baseWidth.baseWidth;
    } else {
      baseWidthLocal = 100;
    }
    if (baseWidth.baseHeight) {
      baseHeightLocal = baseWidth.baseHeight;
    } else {
      baseHeightLocal = 100;
    }
    if (baseWidth.baseWidthHora) {
      baseWidthHoraLocal = baseWidth.baseWidthHora;
    } else {
      baseWidthHoraLocal = 100;
    }
    if (baseWidth.baseHeightHora) {
      baseHeightHoraLocal = baseWidth.baseHeightHora;
    } else {
      baseHeightHoraLocal = 100;
    }
    if (baseWidth.isFullScreen) {
      isFullScreenLocal = baseWidth.isFullScreen;
    } else {
      isFullScreenLocal = false;
    }
    if (baseWidth.esperaMode) {
      esperaModeLocal = baseWidth.esperaMode;
    } else {
      esperaModeLocal = false;
    }
    if (baseWidth.differenceBase) {
      differenceBaseLocal = baseWidth.differenceBase;
    } else {
      differenceBaseLocal = [19, 345];
    }
  } else {
    baseWidthLocal = baseWidth;
    baseHeightLocal = baseHeight;
    baseWidthHoraLocal = baseWidthHora;
    baseHeightHoraLocal = baseHeightHora;
    isFullScreenLocal = isFullScreen;
    esperaModeLocal = esperaMode;
    differenceBaseLocal = differenceBase;
  }
  //#region
  //#region VARIÁVEIS
  let tableMaxWidth = ((window.innerWidth - baseWidthLocal) - differenceBaseLocal[0]);
  const bgThHora = esperaModeLocal ? '#CDD4F7' : '#DFCDF8';
  const bgThSalas = esperaModeLocal ? '#001DAF' : '#00B19D';
  const bgFiltro = esperaModeLocal ? '#8585AA' : 'rgb(149, 134, 172)';
  //#region
  return ({
    width: baseWidthLocal,
    height: baseHeightLocal,
    tamanhoPadrao: {
      width: `${baseWidthLocal}px`,
      height: `${baseHeightLocal}px`,
    },
    dataSpam: {
      fontFamily: "'Nunito', 'sans-serif'",
      color: '#000000',
      fontWeight: 'bold',
    },
    botaoLegenda: {
      backgroundColor: "#7441BF",
      fontFamily: 'Nunito, sans-serif',
      color: "#FFFFFF",
      paddingRight: "32px",
      paddingLeft: "32px",
      paddingTop: "2px",
      paddingBottom: "2px",
      borderRadius: "6px",
      marginTop: "10px",
      marginBottom: "10px",
      border: "0"
    },
    topDiv: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      alignItems: 'center',
      height: '58px',
      padding: '0px 20px',
      marginBottom: '4rem',
      boxShadow: '0 0 10px 0px silver',
    },
    agendamentoTooltip: {
      fontSize: '12px',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      alignItems: 'center',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0 0 10px 0px silver',
      zIndex: 2,
    },
    containerFlexColumn: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
    },
    divFiltroLabel: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    divFiltro: {
      width: '100%',
      paddingBottom: '20px',
      paddingTop: '4px',
    },
    inputFiltroRoxo: {
      borderColor: '#954eff',
      backgroundColor: 'transparent',
      padding: '5px',
      borderRadius: '5px',
      color: '#fff',
    },
    divAgendaDropDown: {
      overflowY: 'scroll',
      maxHeight: `${baseHeightLocal}px`,
      width: '100%',
      display: 'flex',
      div: {
        paddingLeft: '10px',
      }
    },
    table: {
      maxWidth: '100vw',
      scrollBehavior: 'smooth',
      position: 'relative',
      tableLayout: 'auto',
    },
    thead: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: `${tableMaxWidth - 15}px`,
      overflowX: 'hidden',
      tr: {
      },
      trE: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '32px',
      },
      trS: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '32px',
      },
      trR: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '55px',
      },
    },
    tr0: {
      backgroundColor: '#EAEAEA',
      color: '#9586AC',
      boxShadow: '0 0 3px 1px silver',
      padding: '0px',
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      display: 'inline-flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    tr1: {
      backgroundColor: '#EAEAEA',
      color: esperaModeLocal ? bgThSalas : '#9586AC',
      boxShadow: '0 0 3px 1px silver',
      textAlign: 'center',
      maxWidth: `${baseWidthHoraLocal}px`,
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      minWidth: `${baseWidthHoraLocal}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tr2: {
      backgroundColor: bgThSalas,
      color: '#fff',
      textAlign: 'center',
      maxWidth: `${baseWidthLocal}px`,
      minWidth: `${baseWidthLocal}px`,
      height: '32px',
      minHeight: '32px',
      boxShadow: '0 0 3px 1px silver',
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      alignContent: 'center',
      alignItems: 'center',
    },
    tr2T: {
      backgroundColor: bgThSalas,
      color: '#fff',
      textAlign: 'center',
      minWidth: `${baseWidthLocal}px`,
      maxWidth: `${baseWidthLocal}px`,
      height: '32px',
      minHeight: '32px',
      boxShadow: '0 0 3px 1px silver',
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      alignContent: 'center',
      alignItems: 'center',
    },
    tr3: {
      backgroundColor: '#E6C833',
      color: '#fff',
      textAlign: 'center',
      maxWidth: `${baseWidthLocal}px`,
      minWidth: `${baseWidthLocal}px`,
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      alignContent: 'center',
      alignItems: 'center',
    },
    tbody0: {
      display: 'flex',
      flexDirection: 'column',
      height: isFullScreenLocal ? '80vh' : `calc(100vh - ${differenceBaseLocal[1]}px)`,
      maxWidth: `${baseWidthLocal}px`,
      overflow: 'hidden',
      tr: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: `${baseHeightLocal}px`,
        width: '100%',
      }
    },
    tbody: {
      display: 'flex',
      flexDirection: 'column',
      height: isFullScreenLocal ? '80vh' : `calc(100vh - ${differenceBaseLocal[1]}px)`,
      maxWidth: `${tableMaxWidth}px`,
      overflow: 'auto',
      scrollbarGutter: 'stable',
      tr: {
        display: 'flex',
        flexDirection: 'row',
        height: `${baseHeightLocal}px`,
      },
      div: {
        maxWidth: '100vw'
      }
    },
    trHora: {
      backgroundColor: bgThHora,
      color: esperaModeLocal ? bgThSalas : '#9586AC',
      padding: '5px',
      boxShadow: '0 0 2px 1px silver',
      maxWidth: `${baseWidthHoraLocal}px`,
      minWidth: `${baseWidthHoraLocal}px`,
      maxHeight: `${baseHeightHoraLocal}px`,
      minHeight: `${baseHeightHoraLocal}px`,
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tarde: {
      backgroundColor: '#e0d1ad',
    },
    trVazio: {
      backgroundColor: '#fff',
      padding: '0px',
      width: `${baseWidthLocal} !important`,
      height: `${baseHeightLocal} !important`,
      boxShadow: '0 0 3px 1px silver',
      borderRadius: '10px',
      borderColor: '#F5F7FA',
      borderStyle: 'solid',
      verticalAlign: 'top',
    },
    horaDiferente: {
      fontSize: '0pt',
      backgroundColor: 'rgb(255, 102, 102)',
      height: '10px',
      borderRadius: '0 0 10px 10px',
      border: 'none',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '2px',
    },
    linha: {
      borderColor: '#fff',
      borderWidth: '1px',
      borderStyle: 'solid',
      color: '#fff',
    },
    quadrado120: {
      height: '80px',
      width: '120px',
    },
    border1: {
      border: '#000000 solid 1px',
    },
    wh15px: {
      width: '15px',
      height: '15px',
    },
    w250px: {
      width: '250px',
    },
    trOcupado: {
      color: '#fff',
      padding: '0px',
      width: `${baseWidthLocal}px`,
      height: `${baseHeightLocal}px`,
      borderRadius: '10px',
      textAlign: 'center',
      position: 'relative',
    },
    trReserva: {
      borderColor: '#ff0000',
      borderWidth: '1px',
      borderStyle: 'solid',
      fontSize: '12pt',
      padding: '0px',
      color: '#fff',
      width: baseWidthLocal + 'px',
      height: `${baseHeightLocal}px`,
      borderRadius: '10px',
      textAlign: 'center',
      boxShadow: '5px 5px 15px grey',
    },
    btnFechar: {
      backgroundColor: 'transparent',
      color: '#f00',
      border: 'none',
      fontSize: '10pt',
      fontWeight: 'bold',
    },
    divTitulo: {
      width: (baseWidthLocal - 30) + 'px',
      height: '15px',
      padding: '0px',
    },
    extraIcon: {
      padding: '0px',
      margin: '0px',
      borderColor: 'rgb(245, 247, 250)',
      borderStyle: 'solid',
      borderWidth: '0px',
      borderTopLeftRadius: '10px',
      substituicao: {
        color: 'rgb(225, 111, 242)',
        transform: 'rotate(90deg)',
      },
      reserva: {
        color: '#ff0000',
      },
      substituicaoFeito: {
        color: 'gray',
        transform: 'rotate(90deg)',
      },
    },
    reservaDiv: {
      maxHeight: '12px',
      maxWidth: '12px',
      height: '12px',
      width: '12px',
      overflow: 'none',
      padding: '0px',
      borderTopLeftRadius: '10px',
    },
    extraDiv: {
      maxHeight: '12px',
      maxWidth: '12px',
      height: '12px',
      width: '12px',
      top: '-30px',
      left: '-7px',
      overflow: 'none',
      padding: '0px',
      borderTopLeftRadius: '10px',
    },
    agendamentoExtras: {
      width: '15px',
      height: '15px',
    },
    filtro: {
      backgroundColor: bgFiltro,
      width: '400px',
      minHeight: '46px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    },
    padrao: { backgroundColor: "#35D058" },
    presencial: { backgroundColor: "#35D058" },
    online: { backgroundColor: "#3B9E52" },
    domiciliar: { backgroundColor: "#075C1A" },
    conflitoTerapeuta: { backgroundColor: "#E98232" },
    conflitoTerapeutaSala: { backgroundColor: "#f00" },
    conflitoPaciente: { backgroundColor: "#F94181" },
    avaliacao: { backgroundColor: "#EED10F", color: "#f0f0f0" },
    reposicao: { backgroundColor: "#C901E8" },
    reserva: { backgroundColor: "#2781DA" },
    substituicao: { backgroundColor: "#e16ff2" },
    reposicao_feita: { backgroundColor: "#afafaf" },
    avaliacao_feita: { backgroundColor: "#afafaf" },
    aguardandoTerapeuta: { backgroundColor: "#2781DA" },
    duplicado: { backgroundColor: "#00FA9A" },
  })
};
//#region ESTILOS SEPARADOS
const filtered = {
  border: '1px solid #ffa200',
  color: '#fff250'
}
const styles_filtred = {
  padrao: { backgroundColor: "#35D058" },
  presencial: { backgroundColor: "#35D058" },
  online: { backgroundColor: "#3B9E52" },
  domiciliar: { backgroundColor: "#075C1A" },
  conflitoTerapeuta: { backgroundColor: "#E98232" },
  conflitoPaciente: { backgroundColor: "#F94181", },
  conflitoTerapeutaSala: { backgroundColor: "#f00" },
  avaliacao: { backgroundColor: "#EED10F", },
  reposicao: { backgroundColor: "#C901E8", },
  reserva: { backgroundColor: "#2781DA", },
  substituicao: { backgroundColor: "#e16ff2", },
  reposicao_feita: { backgroundColor: "#afafaf", },
  avaliacao_feita: { backgroundColor: "#afafaf", },
  aguardandoTerapeuta: { backgroundColor: "#2781DA" },
  duplicado: { backgroundColor: "#00FA9A" },
};
const styles_escuro = {
  padrao: { backgroundColor: "#bcbcbc" },
  presencial: { backgroundColor: "#bcbcbc" },
  online: { backgroundColor: "#bcbcbc" },
  domiciliar: { backgroundColor: "#bcbcbc" },
  conflitoTerapeuta: { backgroundColor: "#bcbcbc" },
  conflitoPaciente: { backgroundColor: "#bcbcbc" },
  conflitoTerapeutaSala: { backgroundColor: "#bcbcbc" },
  avaliacao: { backgroundColor: "#bcbcbc" },
  reposicao: { backgroundColor: "#bcbcbc" },
  reserva: { backgroundColor: "#bcbcbc" },
  reposicao_feita: { backgroundColor: "#bcbcbc" },
  avaliacao_feita: { backgroundColor: "#bcbcbc" },
  substituicao_feita: { backgroundColor: "#bcbcbc" },
  substituicao: { backgroundColor: "#bcbcbc" },
  aguardandoTerapeuta: { backgroundColor: "#bcbcbc" },
  duplicado: { backgroundColor: "#bcbcbc" },
};
//#region
export { listOfStyles, styles_filtred, styles_escuro, filtered };