import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import Input from '../../../../../components/Input';
import { Form } from '@unform/web';
import moment from "moment/moment";
import Swal from 'sweetalert2';
import DocumentoService from '../../../../../services/DocumentoService';
import { Delete, InputUpload, LabelUpload, Upload, UploadIcon } from '../../../Terapeutas/ModalDiplomas/styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import ModalTitulo from 'components/ModalTitulo';
import { CgClose } from 'react-icons/cg';

const customStyles = {
    overlay: {
        zIndex: 11,
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        maxHeight: "70vh",
        width: "40vw",
        padding: 0,
        zIndex: 12
    },
};

const ModalDocumento = ({ paciente_id, user_id, documentos = [], setDocumentos = () => { }, type = 'paciente' }, ref) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dados, setDados] = React.useState(null);
    const [documento, setDocumento] = useState(null);
    const [path, setPath] = useState([]);

    const documentoService = new DocumentoService(type);

    const formRef = useRef(null);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    const handleChangeDocument = (e) => {
        if (documento === null) {
            const arquivo = document.getElementById("arquivo")
            const file = e.target.files[0].name;
            const size = e.target.files[0].size;
            const extPermitidas = ['jpg', 'png', 'jpeg', 'pdf',];
            if (extPermitidas.find((ext) => { return file.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg ou pgn)', '', 'warning');
                arquivo.value = "";
            } else {
                if (size < 40000000) { //40MB         
                    setDocumento(e.target.files[0]);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = "";
                }
                e.preventDefault();
            }
        } else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'warning');
        }
    }

    function afterOpenModal() {
        setPath([]);
        setDocumento(null);
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(data) {
        try {
            if (data.nome === "" || documento === "") {
                Swal.fire('Por favor prencha todos os campos!', '', 'warning');
            } else {
                let documentosLocal = [...documentos];
                data.created_at = moment().format('YYYY-MM-DD HH:mm:ss');
                switch (type) {
                    case 'paciente':
                        data.id_paciente = paciente_id;
                        break;
                    case 'terapeuta':
                        data.user_id = user_id;
                        data.tipo = 'documento-geral';
                        break;
                    case 'funcionario':
                        data.user_id = user_id;
                        data.tipo = 'documento-geral';
                        break;
                    default:
                        data.id_paciente = paciente_id;
                        break;
                }
                data.arquivo = documento;
                await documentoService.store(data).then((response) => {
                    documentosLocal.push(response.data);
                    setDocumentos(documentosLocal);
                });
                setIsOpen(false);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteUpload = () => {
        setDocumento(null);
        path.splice(-1);
    };

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }

    return (
        <Modal id='modal-documento'
            style={customStyles}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            appElement={document.getElementById('root')}
            transparent>
            <nav className="navbar sticky-top bg-cinza-claro shadow m-0">
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div></div>
                    <ModalTitulo> Adicionar novo Documento Geral </ModalTitulo>
                    <label className="btn-outline text-right pointer" onClick={() => closeModal()}>
                        <CgClose size={22} className="poiner" color="red" />
                    </label>
                </div>
            </nav>
            <div className="p-4">
                <Form ref={formRef} onSubmit={handleSubmit} encType="multipart/form-data" id={'form_docs'} className='row'>
                    <div className='col-12'>
                        <Input title="Nome:" name="nome" required={'required'} />
                    </div>
                    <label htmlFor="arquivo" style={LabelUpload} className='col-12 d-flex flex-row flex-nowrap align-center justify-center hl-laranja font-roxo'>
                        <AiOutlineCloudUpload className='mt-2 ms-2' size={38} style={UploadIcon} />
                        <span className='mt-2 ms-2'>Anexar Documento</span><input type="file" id="arquivo" name="arquivo" style={InputUpload} onChange={e => handleChangeDocument(e)} />
                    </label>
                    <div className='d-flex justify-center align-center flex-col mb-2'>
                        {path.map((item) => {
                            return <span style={Upload}>{item}
                                <div>
                                    <span style={Delete} onClick={handleDeleteUpload}> x </span>
                                </div>
                            </span>
                        })}
                    </div>
                    <div className='col-12'>
                        <button className='btn-padrao w-100' type="button" onClick={functionThatSubmitsForm}> Adicionar </button>
                    </div>
                </Form>
            </div>
        </Modal>
    );
}

export default forwardRef(ModalDocumento);