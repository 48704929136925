import { borderRadius, borderWidth, padding } from 'polished';
import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};

const RecuperarSenhaModal = (props, ref) => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [nome, setNome] = React.useState('false');
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);


    useImperativeHandle(ref, () => {
        return {
            openModal,
            nome
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                transparent
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold" }} ref={(_subtitle) => (subtitle = _subtitle)}>Recuperação de Senha</h2>
                    <form style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                        <a>Insira o número do seu CPF ou email cadastrado e enviaremos um link para a alteração de senha</a>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#442953", fontWeight: "bold" }}>CPF ou email cadastrado</label>
                            <input style={{ borderRadius: 8, textIndent: 8 }} />
                        </div>
                        <button style={{ border: "none", color: "white", borderRadius: 8, backgroundColor: "#CF2251", marginTop: 8, paddingTop: 2, paddingBottom: 2}}>enviar</button>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(RecuperarSenhaModal);
