import styled from 'styled-components';

export const Lista = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 10px 0;
`

export const ItemLista = styled.li`
  background-color: #d5ccff;
  padding: 5px 10px;
  margin: 5px 10px;
  border-radius: 10px;
`
