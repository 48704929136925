import api from './api';
import moment from 'moment/moment';
import AtendimentoAlteracaoService from './AtendimentoAlteracaoService';

class AtendimentoService {
  static index = (params) => {
    return api.get(`api/atendimento?${params}`);
  };
  static store = (obj) => {
    /* exemplo
    {"paciente_id":1,"paciente_id_plano_saude":82,"terapeuta_id":1,"terapia_id":1,"especialidade_id":1,"modalidade_id":1,"tipo_atendimento_id":1,"reserva":false,"data_atendimento_inicial":"1999-04-12","data_atendimento_final":"1983-05-15","comentario":"Similique omnis aut aspernatur eos mollitia nisi dolor. Et rerum eos nemo repudiandae omnis itaque ab. Iure optio est ut.","estacao_id":1}
    */

    return api.post(`api/atendimento`, obj);
  };
  static show = (id) => {
    return api.get(`api/atendimento/${id}`);
  };
  static update = (obj, id) => {
    /* exemplo
    {"paciente_id":1,"paciente_id_plano_saude":35369,"terapeuta_id":1,"terapia_id":1,"especialidade_id":1,"modalidade_id":1,"tipo_atendimento_id":1,"reserva":true,"data_atendimento_inicial":"1995-05-25","data_atendimento_final":"1978-04-30","comentario":"Impedit sed aut facilis. Eum doloribus et cum tempore. Quo odit vel deserunt dolor voluptatem perspiciatis consequatur. Eum maxime fugit molestiae.","estacao_id":1}
    */

    return api.put(`api/atendimento/${id}`, obj);
  };
  static destroy = (id) => {
    return api.delete(`api/atendimento/${id}`);
  };

  static getHorarioAtualizado(atendimento) {
    if (!atendimento.is_ultima_aprovada) {
      return moment(atendimento.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
    }
    const ultimaAlteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
    return moment(ultimaAlteracao.data_atendimento_inicial.split('T')[1], 'HH:mm:ss').format('HH:mm');
  }

  static getStatusSelecionadoByValue(statusList, value) {
    if (!statusList || statusList.length < 1 || statusList === undefined || statusList === null) return;
    return statusList.filter(status => status.value === value)[0] || "";
  }
  /**
   * Retrieves an atendimento by controle diario ID.
   * @param {number} id - The ID of the controle diario.
   * @param {string} withs - Additional relationships to include in the response.
   * @param {string} listas - Comma-separated list of related entities to include in the response.
   * @param {string} extraSelects - Additional fields to select in the response.
   * @param {string} filters - Additional filters to apply in the request.
   * @returns {Promise} A promise that resolves to the response data or null if an error occurs.
   */
  static async getByControleDiarioId(id, withs, listas, extraSelects, filters) {
    const listasParam = listas !== undefined ? listas : 'solicitacao_de_alteracaos,guia,paciente.terapias_paciente';
    const extraSelectsParam = extraSelects !== undefined ? extraSelects : 'terapia_paciente.plano_saude.id';
    return api.get(`api/atendimento/getByControleDiario/${id}?id=${id}${listasParam ? `&listas=${listasParam}` : ''}${listasParam !== undefined ? `&extraSelect=${extraSelectsParam}` : ''}${withs ? `&with=${withs}` : ''}${filters ? '&' + filters : ''}`);
  }

  static getOpcoesAtendimentoLiberado() {
    return [
      {
        value: 1,
        label: 'Sim'
      },
      {
        value: 0,
        label: 'Não'
      },
    ]
  }

  static getOpcaoAtendimentoLiberadoByValue(value) {
    return this.getOpcoesAtendimentoLiberado().filter(status => status.value === value)[0] || "";
  }
}

export default AtendimentoService;
