import React, {useEffect, useState} from "react";
import {AiFillFileAdd} from "react-icons/ai";
import {Container} from './styles';

import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import {TableTiposAtendimento} from "./TableTiposAtendimento";
import TipoAtendimentoService from "../../../../services/TipoAtendimentoService";
import TipoAtendimentoModal from "../../../../components/Modais/TipoAtendimentoModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function TiposAtendimento() {

  const [TipoAtendimentos, setTipoAtendimentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#8ed4ae");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  
  const loadTipoAtendimentos = () => {
    TipoAtendimentoService.index()
      .then(({data}) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        setTipoAtendimentos(data)
      })
      .finally(() => setLoading(false));
  }

  const handleNewTipoAtendimento = () => {
    setModalData(null);
    setIsOpen(true);
  }

  const editModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  }

  useEffect(() => {
    loadTipoAtendimentos();
  }, []);


  return (
    <Admin>
      <Container>
        <div>
          <button
            onClick={() => handleNewTipoAtendimento()}
            style={{
              backgroundColor: '#7340BD',
              color: '#FFFFFF',
              fontFamily: "Nunito, sans-serif",
              border: "none",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              marginBottom: 20
            }}>
            <AiFillFileAdd style={{marginRight: 5}}/>
            Novo tipo de atendimento
          </button>
        </div>
        <div>
          {
            (TipoAtendimentos.length !== 0 && loading === false) ? <TableTiposAtendimento callback={loadTipoAtendimentos} 
              source={TipoAtendimentos} editModal={editModal}/> :
              <ClipLoader color={color} loading={loading} css={override} size={50}/>
          }
        </div>
        {modalIsOpen && <TipoAtendimentoModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          callback={loadTipoAtendimentos}
          modalData={modalData}
        />}
      </Container>
    </Admin>
  )
}
