import React from 'react';
import { useAuth } from '../../context/auth';

const UsuarioLogado = () => {

  const terapeuta = useAuth();

  return (
    <label>Olá, {terapeuta?.user?.tipo_user === 'terapeuta' ? terapeuta?.user?.terapeuta[0]?.nome : terapeuta?.user?.tipo_user === 'responsavel' ?
      terapeuta?.user?.responsavels[0]?.nome : terapeuta?.user?.name}</label>
  );
};

export default UsuarioLogado;
