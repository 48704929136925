import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container } from './styles'
import { TableGuias } from './TableGuias';
import api from "../../../services/api";
import { useAuth } from "../../../context/auth";
import { useLocation } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { FaBroom } from "react-icons/fa";
import { AiFillCaretDown, AiOutlineException } from "react-icons/ai";
import Button from "../../../components/Button";
import ModalGuias from "../Pacientes/Perfil/ModalGuias";
import { Slider } from "primereact/slider";

export default function Guias() {
    /* =============================== */
    /* ===VARIÁVEIS=================== */
    /* =============================== */
    /*==VARS INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'GUIAS A VENCER';
    // eslint-disable-next-line no-unused-vars
    const rota = '/dashboard/recepcao/guiasavencer/';
    /* ===UTILITÁRIOS DA PÁGINA */
    const user = useAuth();
    const [permissoes, setPermissoes] = useState();
    const url_atual = useLocation();
    const [loading, setloading] = useState(false);
    /* ===DADOS DA GUIA */
    const [guias, setGuias] = useState([]);                     /* Listagem de Guias */
    const [guiasOriginal, setGuiasOriginal] = useState([]);     /* Listagem de guias sem filtro */
    const [guiaData, setGuiaData] = useState(null);             /* Data das guias */
    /* ===FILTRO */
    const [mousePause, setMousePause] = useState(false);
    const [filtroTerapia, setFiltroTerapia] = useState('');
    const [filtroNome, setFiltroNome] = useState('');
    const [filtrando, setFiltrando] = useState(false);
    const defaultStatusGuiaFilter = [
        { checked: true, status: 'Ativo' },
        { checked: true, status: 'Espera' },
        { checked: true, status: 'Vencido' },
        { checked: true, status: 'Zerado' },
        { checked: true, status: 'Bloqueado' },
    ];
    const [selecionaTodosStatus, setSelecionaTodosStatus] = useState(true);
    const [porcentagemRange, setPorcentagemRange] = useState([0, 100]);
    const [statusGuia, setStatusGuia] = useState(defaultStatusGuiaFilter);
    const [expand, setExpand] = useState(false);
    const [filtroWidth, setFiltroWidth] = useState();
    const filtroDivRef = useRef();
    /* REFS */
    const modalTerapiaRef = useRef(null);
    const modalGuiaRef = useRef(null);
    /* =============================== */
    /* ===FUNCOES===================== */
    /* =============================== */
    /* ===METODOS */
    const coletaPermissoes = () => {
        let permissoes = user.user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = 'guias';
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }
    //#region FILTRO
    const expandFiltro = (e) => {
        if (expand) {
            fechaFiltro();
        } else {
            abreFiltro();
        }
    }
    const abreFiltro = () => {
        setExpand(true);
    }
    const fechaFiltro = () => {
        setExpand(false);
    }
    /**
     * Função que limpa os filtros
     * @return {void}
     */
    function handleLimpaFiltros() {
        setGuias(guiasOriginal);
        setStatusGuia(defaultStatusGuiaFilter);
        setSelecionaTodosStatus(true);
        setFiltroNome('');
        setFiltroTerapia('');
        setPorcentagemRange([0, 100]);
    }

    const handleChangeStatusGuia = (e) => {
        const { id, checked } = e.target;
        setStatusGuia((prev) => {
            let newPrev = [...prev];
            newPrev.map(find => {
                if (find.status === id) {
                    find.checked = checked
                }
                return find;
            });
            return newPrev;
        });
    }

    const handleChangeSelecionaTodos = (e) => {
        const { checked } = e.target;
        setSelecionaTodosStatus(checked);
        let newStatusGuia = [...statusGuia]
        newStatusGuia = newStatusGuia.map(find => {
            find.checked = checked
            return find;
        });
        setStatusGuia(newStatusGuia);
    }
    /**
     * Função que filtra baseado nos dados definidos pelos inputs, salvos no state.
     * @return {void}
     */
    async function filtrar() {
        setFiltrando(true);
        let lista = guiasOriginal;
        lista = lista.filter((item) => {
            let nameTeste = true;
            let terapiaTeste = true;
            if (filtroNome !== '') {
                nameTeste = String(item.nome).toLowerCase().includes(String(filtroNome).toLowerCase());
            }
            if (filtroTerapia !== '') {
                terapiaTeste = String(item.terapia).toLowerCase().includes(String(filtroTerapia).toLowerCase());
            }
            const passaStatus = statusGuia.filter(filter => filter.status === item.status && filter.checked).length > 0;
            const ql = Number(item.quantidade_liberada);
            const qf = Number(item.quantidade_feita)
            const saldo = ql - qf;
            const porcentagem = 100 - Math.round(saldo * 100 / ql);
            const passaPorcentagem = porcentagem >= porcentagemRange[0] && porcentagem <= porcentagemRange[1];
            const result = (nameTeste && passaStatus && terapiaTeste && passaPorcentagem);
            return result;
        });
        setFiltrando(false);
        setGuias(lista);
    }
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpand(false);
        }
    }
    //#endregion
    //#region HANDLES
    const handleChangeRangePorcentagem = (e) => {
        const { id, value } = e.target;
        setPorcentagemRange(prev => {
            let newRange = [...prev];
            const newId = id.replace(/\D/g, '');
            switch (newId) {
                case '0':
                    if (Number(value) <= Number(newRange[1])) {
                        newRange[newId] = value;
                    }
                    break;
                case '1':
                    if (Number(value) >= Number(newRange[0])) {
                        newRange[newId] = value;
                    }
                    break;
                default:
                    break;
            }
            return newRange
        });
    }
    /* Função que fecha a modal de guia. Chamada de dentro da modal Guias. */
    const handleFechaModalGuia = () => {
        carregaTodasGuias();
        setGuiaData(null);
    }
    /* Função que abre o modal de guia */
    const handleOpenGuia = useCallback((edit, dados) => {
        modalGuiaRef.current.openModal(dados, edit);
    }, [])
    const handleClickAdicionaGuia = () => {
        setGuiaData(null);
        if (modalGuiaRef.current) {
            modalGuiaRef.current.openModal(null);
        }
    }
    //#endregion
    //#region GETTERS
    const carregaTodasGuias = async () => {
        setloading(true);
        await api.get(`api/guia/lista/simples?with=paciente,terapia,especialidade&select=guia.*,paciente.nome,terapia.terapia,especialidade.especialidade,terapia_paciente.plano_saude.nome_reduzido`).then(({ data }) => {
            // Ordena alfabeticamente pelo nome do paciente
            const sortedData = data.sort((a, b) => {
                if (a.nome < b.nome) return -1;
                if (a.nome > b.nome) return 1;
                return 0;
            });

            setGuias(sortedData);
            setGuiasOriginal(sortedData);
        });
        setloading(false);
    };

    const carregaGuiasResponsavel = () => {
        api.get(`api/pacientes?with=guia.terapium,guia.especialidade,guia.plano_saude&filter[responsavels.id]=${user.user.responsavels[0].id}`).then(({ data }) => {
            let guiasList = [];
            data.forEach((paciente) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                if (paciente.status !== 'Inativo') {
                    if (paciente.terapia_pacientes.length > 0) {
                        paciente.terapia_pacientes.forEach((terapia) => guiasList.push({ ...terapia, paciente: paciente.nome, }));
                    };
                }
            })
            setGuias(guiasList);
        })
    }
    //#endregion
    //#region USE EFFECT
    useEffect(() => {/* ON LOAD */
        coletaPermissoes();
        document.addEventListener('mousedown', handleClickOutside);
        { url_atual.pathname.split('/')[2] === "responsavel" ? carregaGuiasResponsavel() : carregaTodasGuias() };
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [])
    useEffect(() => {
        if (guiasOriginal.length > 0) {
            if (!mousePause) {
                filtrar();
            }
        }
    }, [filtroNome, filtroTerapia, statusGuia, porcentagemRange, mousePause]);
    useEffect(() => {
        if (filtroDivRef.current) {
            setFiltroWidth(filtroDivRef.current.clientWidth - 10);
        }
    }, [filtroDivRef]);
    //#endregion
    /* =============================== */
    /* ===HTML======================== */
    /* =============================== */
    return (
        <div>
            <Container>
                <div className="div-titulo w-100per">
                    <label className="unselectable">{url_atual.pathname.split('/')[2] === "responsavel" ? 'Guias dos Pacientes' : 'Guias'}</label>
                </div>
                <br></br>
                <div className="row mb-4">
                    <div className="col-10">
                        <div ref={filtroDivRef} className='div-filter-style justify-content-start align-center w-100'>
                            <div id="div-expand-filter" className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }}
                                onClick={({ target }) => {
                                    if (target === document.getElementById('div-expand-filter')) {
                                        expandFiltro()
                                    }
                                }}>
                                <div id="blank" style={{ height: 'fit-content' }} onClick={(e) => expandFiltro()}>
                                    {!expand ? 'Expandir' : 'Fechar'} Filtro
                                </div>
                                <div className='flex-nowrap'>
                                    {(filtroTerapia || filtroNome || Number(porcentagemRange[0]) !== 0 || Number(porcentagemRange[1]) !== 100 || defaultStatusGuiaFilter.length !== statusGuia.filter(filter => filter.checked === true).length)
                                        ? <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros() }} title='Conteúdo filtrado...' />
                                        : <div width='22' height='22'></div>
                                    }
                                    <AiFillCaretDown onClick={(e) => expandFiltro()} className='pointer' color="white" size={22} style={{ marginLeft: '15px' }} />
                                </div>
                            </div>
                            <div className='div-expand-over row' style={{ marginLeft: '-16px', width: filtroWidth, ...expand ? {} : { display: 'none' } }}>
                                <div className="col-lg-8 row">
                                    <div className="col-lg-6">
                                        <label className="font-16px w-100 unselectable">Nome do Paciente</label>
                                        <input className="form-control m-1 w-100per" placeholder={"Pesquisar Paciente por Nome:"} value={filtroNome || ''} onChange={e => { setFiltroNome(e.target.value) }} />
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="font-16px w-100 unselectable">Terapia</label>
                                        <input className="form-control m-1 w-100per" placeholder={"Pesquisar Paciente por Nome:"} value={filtroTerapia || ''} onChange={e => { setFiltroTerapia(e.target.value) }} />
                                    </div>
                                    <div className="col-lg-12" onMouseEnter={(e) => { setMousePause(true) }} onMouseLeave={(e) => { setMousePause(false) }}>
                                        <label htmlFor="porcentagem-range" className="form-label">Porcentagem de Uso</label>
                                        <div className="d-flex p-0 w-100">
                                            <label htmlFor="porcentagem-range0">Mínimo</label>
                                            <input type="range" className="w-100 ns-range" min={0} max={100} step={1} id="porcentagem-range0" name="porcentagem-range" value={porcentagemRange[0]} onChange={handleChangeRangePorcentagem} />
                                            <input type="number" id="porcentagem0" name="porcentagem0" className="ns-number-range-input" value={porcentagemRange[0]} />
                                        </div>
                                        <div className="d-flex p-0 w-100">
                                            <label htmlFor="porcentagem-range1">Máximo</label>
                                            <input type="range" className="w-100 ns-range" min={0} max={100} step={1} id="porcentagem-range1" name="porcentagem-range" value={porcentagemRange[1]} onChange={handleChangeRangePorcentagem} />
                                            <input type="number" id="porcentagem1" name="porcentagem1" className="ns-number-range-input" value={porcentagemRange[1]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 row">
                                    <div className="col-12">
                                        <label className="font-16px w-100 unselectable">Status da Guia</label>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor={'seleciona_todos'} className="unselectable">
                                            <input type="checkbox" id={'seleciona_todos'} name="seleciona_todos" checked={selecionaTodosStatus} className="m-2" onChange={(e) => handleChangeSelecionaTodos(e)} />
                                            Todos
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        {defaultStatusGuiaFilter.map(({ status }) => <>
                                            <label htmlFor={status} className="unselectable">
                                                <input type="checkbox" id={status} name="status_guia" className="m-2" checked={statusGuia.find(find => find.status === status).checked} onChange={(e) => handleChangeStatusGuia(e)} />
                                                {status}
                                            </label>
                                        </>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 d-flex justify-content-start flex-wrap">
                        {(permissoes && permissoes?.criação) &&
                            <Button className='btn-padrao mt-0' type="button" onClick={handleClickAdicionaGuia}>
                                Adicionar Guia <AiOutlineException className="ml-10px" size={25} />
                            </Button>
                        }
                    </div>
                </div>
                {filtrando ? 'Filtrando' : ''}
                {loading
                    ? <ClipLoader size={50}></ClipLoader>
                    : <TableGuias source={guias} callBack={handleFechaModalGuia} permissoes={permissoes} modalTerapiaRef={modalTerapiaRef} setData={setGuiaData} handleOpen={handleOpenGuia} />
                }
            </Container>
            <ModalGuias ref={modalGuiaRef} listaGuias={guias} callBack={handleFechaModalGuia} />
        </div>
    )
}