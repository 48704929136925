import React, { useEffect, useMemo, useState } from "react";
import "../../../../styles/global.css";
import moment from "moment";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import PieChart from "../../../../components/Charts/PieChart";
import "react-icons";
import { AiOutlineClear } from "react-icons/ai";
import { FaEraser, FaFilter } from "react-icons/fa";

/**
 * Função que mostra a modal de detalhes do pacientes selecionado.
 * @param {[function, Array]} param0 onClose: Função para fechar a modal. paciente: Vetor de informações do paciente a detalhar na modal.
 * @returns 
 */
export function DetalhesFaltas({ onClose, paciente }) {
    /* =============================== */
    //#region VARIAVEIS=============== */
    /* =============================== */
        //#region Colunas da tabela
            const columns = useMemo(() => [
                {
                    Header: 'Atendimento Inicial',
                    accessor: 'data_inicial',
                    Cell: ({ cell }) => {
                        return ((
                            moment(cell.row.original.data_atendimento_inicial).format('DD/MM/YYYY HH:mm:ss')
                        ))
                    }
                },
                {
                    Header: 'Atendimento Final',
                    accessor: 'data_final',
                    Cell: ({ cell }) => {
                        return ((
                            moment(cell.row.original.data_atendimento_final).format('DD/MM/YYYY HH:mm:ss')
                        ))
                    }
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    Cell: ({ cell }) => {
                        return ((
                            cell.row.original.sugestao_recepcao === 3 ? ' Faltou.' : ' Participou.'
                        ))
                    }
                },

            ]);
        //#endregion
        const [source, setSource] = useState(paciente.atendimentos);
        const [dataFiltro, setDataFiltro] = useState(null);
        const [forPDF, setForPDF] = useState(false);
        /* ===CHARTs====================== */
        const [chartData, setChartData] = useState();
        const [loadingChart, setLoadingChart] = useState(false);
        const [options, setOptions] = useState([]);
    //#endregion
    /* =============================== */
    //#region FUNCOEs================= */
    /* =============================== */
        /**
         * Filtra as informações da tabela
         * @param {boolean} reset Parâmetro que define se o filtro vai resetar.
         * @return {void}
         */
        const filtra = (reset = false) =>{
            if(reset){
                setSource(paciente.atendimentos)
                setDataFiltro('');
            }else{
                let filtroData = dataFiltro;
                if(filtroData!=null){
                    let localSource = paciente.atendimentos.filter((value)=>{
                        return (moment(value.data_atendimento_inicial).format('DD/MM/YYYY')===moment(filtroData).format('DD/MM/YYYY'));
                    })
                    setSource(localSource);
                }else{
                    setSource(paciente.atendimentos)
                }
            }
        }
        /**
         * Função que define todas as informações relevantes para o grafico.
         * @return {void}
         */
        const setInformacoesGrafico = async () => {
            setLoadingChart(true);
            //#region ChartData
                let dataset = [[], [], [], []];
                dataset[0].push(Number(paciente.atendimentos.length) - Number(paciente.faltas));
                dataset[0].push(Number(paciente.faltas));
                setChartData({
                    labels: ['Normal', 'Falta'],
                    datasets: [
                        {
                            label: 'Atendimentos',
                            backgroundColor: ['rgba(0,180,0,1)', 'rgba(180,0,0,1)'],
                            borderColor: ['rgba(0,80,0,1)', 'rgba(80,0,0,1)'],
                            borderWidth: 2,
                            borderRadius: 0,
                            data: dataset[0]
                        }
                    ],
                });
            //#endregion
            //#region options
                let createOptions = {
                    maintainAspectRatio: false,

                };
                setOptions(createOptions);
            //#endregion
            setLoadingChart(false);
        }
    //#endregion
    /* =============================== */
    //#region USE EFFECTs============= */
    /* =============================== */
        useEffect(() => {
            setInformacoesGrafico();
        }, [])
    //#endregion
    /* =============================== */
    //#region HTML==================== */
    /* =============================== */
        return (
            <div className="div-modal-outside">
                <div className="div-modal">
                    <div className='w-100per d-flex justify-content-between align-bottom'>
                        <p id="nada"></p>
                        <label className='titulo-modal-24'>Atendimentos do {paciente.nome}</label>
                        <button className='btn-outline' onClick={(e) => onClose()}>x</button>
                    </div>
                    <div className={(forPDF)?"d-none":"w-100per d-flex"}>
                        <div className="input-group mb-3">
                            <input className="form-control" type="date" value={dataFiltro} onChange={(e)=>setDataFiltro(e.target.value)}/>
                            <span className="input-group-text pointer" id="basic-addon1" onClick={(e)=>{filtra(true);}}><FaEraser/></span>
                        </div>
                        <button className="btn-padrao font-12px h-38px" onClick={(e)=>filtra()}><FaFilter/></button>
                    </div>
                    {(paciente.atendimentos.length > 0)
                        ? <TableDefaultPagination
                                columns={columns}
                                source={source}
                                emptyPhrase={'Não há atendimentos disponíveis para mostrar.'}
                                enableFooter={false}/>
                        : ''
                    }
                    <div className="d-flex justify-content-between">
                        <div></div>
                        <PieChart
                            className="h-200px w-50per mt-30px"
                            loading={loadingChart}
                            chartData={chartData}
                            options={options}
                            maintainAspectRatio={true}
                            tituloFontSize='15px'
                            titulo="Proporção de Faltas">
                        </PieChart>
                    </div>
                </div>
            </div>
        );
    //#endregion
};