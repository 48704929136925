import React, { useState, useEffect } from "react";
import { AiOutlineUserAdd, AiOutlineReconciliation, AiOutlineSolution } from 'react-icons/ai';
import moment from "moment/moment";
import { ButtonStyle1 } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { Pacientes } from "../RecepcaoDashboard/Dashboard/styles";
import PacientesClinicaCard from "../AdminDashboard/components/PacientesClinicaCard";
import api from "../../services/api";
import FuncionariosModal from "../../components/Modais/FuncionariosModal";
import FuncionarioService from "../../services/FuncionarioService";
import PlanoSaudeService from "../../services/PlanoSaudeService";
import PlanoSaudeModal from "../../components/Modais/PlanoSaudeModal";
import { FiToggleLeft, FiToggleRight } from "react-icons/fi";
import { FaUsers } from "react-icons/fa";
import HeaderPadrao from "../../layouts/HeaderPadrao";

export default function AgendamentoDashboard() {

    const [modalNewUserIsOpen, setmodalNewUserIsOpen] = useState(false);
    const [modalNewPlanoIsOpen, setModalNewPlanoIsOpen] = useState(false);
    const [pacientes, setPacientes] = useState([]);
    const [estatisticas, setEstatisticas] = useState([]);
    const [lembretes, setLembretes] = useState('...');
    const [editLembrete, setEditLembrete] = useState(false);

    const { user } = useAuth();
    const navigate = useNavigate()

    const loadEstatisticas = async () => {
        let { data } = await api.get('/api/estatisticas/administrador/' + user.id);
        setEstatisticas(data.estatisticas);
        setLembretes(data.lembretes);
        setPacientes(data.pacientes)
    }

    const handleSaveLembretes = () => {
        const data = document.getElementById('lembrete');
        setLembretes(data.value);
        api.patch('/api/user/' + user.id, { lembretes: data.value }).then(response => {
            setEditLembrete(false);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            loadEstatisticas();
        });
    }

    const handleOpenModalNewUser = () => {
        setmodalNewUserIsOpen(true);
    }

    const handleOpenModalNewPlano = () => {
        setModalNewPlanoIsOpen(true);
    }

    const loadFuncionarios = () => {
        FuncionarioService.index()
    }

    const loadPlanos = () => {
        PlanoSaudeService.index()
    }

    useEffect(() => {
        loadEstatisticas();
    }, []);

    return (
        <HeaderPadrao>
            <div style={{ padding: "4rem 19rem" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <label style={{ fontWeight: "900", fontSize: 24, color: "#6A3BAF" }}>Bem vindo, {user.name} !</label>
                        <label style={{ fontWeight: "700", fontSize: 18, color: "#6A3BAF" }}>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <Pacientes>
                        <PacientesClinicaCard pacientes={pacientes} title={"Pacientes da Clínica:"} />
                    </Pacientes>
                </div>
                <div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: 3 }}>
                        <div style={{
                            height: 300, textDecoration: "none", alignItems: "center",
                            justifyContent: "center", width: "50%", boxShadow: "0 0 10px 0px silver",
                            backgroundColor: "white", borderRadius: 20, margin: 10
                        }}>
                            <div style={{ marginBottom: 15, marginTop: 20, textAlign: "center" }}>
                                <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>Acesso Rápido:</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button onClick={() => navigate('/dashboard/administrador/relatorios')}
                                    style={ButtonStyle1}>
                                    <AiOutlineReconciliation size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Relatórios Estratégicos</span>
                                </button>
                                <button onClick={() => handleOpenModalNewPlano()}
                                    style={ButtonStyle1}>
                                    <AiOutlineSolution size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Cadastro de Plano de Saúde</span>
                                </button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button onClick={() => handleOpenModalNewUser()}
                                    style={ButtonStyle1}>
                                    <AiOutlineUserAdd size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Cadastro de Usuário</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/atendimentos')}
                                    style={ButtonStyle1}>
                                    <FaUsers size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Atendimentos</span>
                                </button>
                            </div>
                        </div>
                        <div style={{
                            height: '300px', textDecoration: "none", alignItems: "center",
                            justifyContent: "center", width: "50%", boxShadow: "0 0 10px 0px silver",
                            backgroundColor: "white", borderRadius: 20, textAlign: "center"
                        }}>
                            <div style={{ marginBottom: 10, marginTop: 20, textAlign: "center", justifyContent: 'space-between', display: 'flex', margin: 10 }}>
                                <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF", }}>Lembretes: </span>
                                {editLembrete === false ?
                                    <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF", cursor: 'pointer' }}
                                        onClick={e => setEditLembrete(true)}>
                                        <FiToggleLeft title="Editar" size={23} />
                                    </span> :
                                    <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF", cursor: 'pointer' }}
                                        onClick={e => setEditLembrete(false)}>
                                        <FiToggleRight title="Cancelar Edição" size={23} />
                                    </span>}

                            </div>
                            {editLembrete === false ?
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "left", padding: 15, }}>
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        {lembretes}
                                    </div>
                                </div> :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "left", padding: 15, }}>
                                    <textarea id='lembrete' name='lembretes' style={{ width: '100%', height: '170px', borderRadius: 15, padding: 5, maxHeight: '170px' }} defaultValue={lembretes}>
                                    </textarea>

                                </div>}
                            {editLembrete === false ? '' :
                                <div style={{ display: "flex", justifyContent: 'space-evenly', alignItems: "center", textAlign: "left", padding: 15, }}>
                                    <button style={{
                                        backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", padding: '4px 8px',
                                        borderRadius: 8, width: '20%',
                                    }} onClick={e => setEditLembrete(false)}>Cancelar</button>
                                    <button style={{
                                        backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", padding: '4px 8px',
                                        borderRadius: 8, width: '20%'
                                    }} onClick={e => handleSaveLembretes()}>Salvar</button>
                                </div>}
                        </div>
                    </div>
                    {modalNewUserIsOpen && <FuncionariosModal onClose={e => setmodalNewUserIsOpen(false)} callback={loadFuncionarios} modalData={null} />}
                    {modalNewPlanoIsOpen && <PlanoSaudeModal setIsOpen={setModalNewPlanoIsOpen} callback={loadPlanos} />}
                </div>
            </div>
        </HeaderPadrao>
    )
}