import api from "./api";

class PlanoSaudeService {
    static index = (params) => {
        return api.get(`api/plano-saude?${params}`);
    }
    static store = (obj) => {
        /* exemplo
        {"plano":"est","tipo_plano":"cum"}
        */

        return api.post(`api/plano-saude`, obj);
    }
    static show = (id) => {
        return api.get(`api/plano-saude/${id}`);
    }
    static update = (obj, id) => {
        /* exemplo
        {"plano":"ut","tipo_plano":"in"}
        */

        return api.put(`api/plano-saude/${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/plano-saude/${id}`);
    }
}

export default PlanoSaudeService;
