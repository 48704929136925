import api from "./api";

export class ListaEsperaService {
    static index = () => {
        return api.get(`api/lista-espera?with=lista_espera_demandas.especialidade,lista_espera_demandas.terapium,lista_espera_historicos,lista_espera_responsavels`);
    }
    static store = (obj) => {
        return api.post(`api/lista-espera`, obj);
    }
    static show = (id, options = '') => {
        return api.get(`api/lista-espera/${id}?${options}`);
    }
    static update = (obj, id) => {
        return api.patch(`api/lista-espera/${id}?id=${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/lista-espera/${id}?id=${id}`);
    }
}

export class ListaEsperaDemandaService {
    static index = () => {
        return api.get(`api/lista-espera-demanda`);
    }
    static store = (obj) => {
        return api.post(`api/lista-espera-demanda`, obj);
    }
    static show = (id, options = '') => {
        return api.get(`api/lista-espera-demanda/${id}?${options}`);
    }
    static update = (obj, id) => {
        return api.put(`api/lista-espera-demanda/${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/lista-espera-demanda/${id}`);
    }
}

export class ListaEsperaResponsaveisService {
    static index = () => {
        return api.get(`api/lista-espera-responsavel`);
    }
    static store = (obj) => {
        return api.post(`api/lista-espera-responsavel`, obj);
    }
    static show = (id, options = '') => {
        return api.get(`api/lista-espera-responsavel/${id}?${options}`);
    }
    static update = (obj, id) => {
        return api.put(`api/lista-espera-responsavel/${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/lista-espera-responsavel/${id}`);
    }
}

export class ListaEsperaHistoricoService {
    static index = () => {
        return api.get(`api/lista-espera-historico`);
    }
    static store = (obj) => {
        return api.post(`api/lista-espera-historico`, obj);
    }
    static show = (id, options = '') => {
        return api.get(`api/lista-espera-historico/${id}?${options}`);
    }
    static update = (obj, id) => {
        return api.put(`api/lista-espera-historico/${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/lista-espera-historico/${id}`);
    }
}