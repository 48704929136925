import 'moment/locale/pt-br';

const agendamentoItemLegendaEnum = {
    PRESENCIAL: 'presencial',
    ONLINE: 'online',
    DOMICILIAR: 'domiciliar',
    CONFLITO_TERAPEUTA: 'conflitoTerapeuta',
    CONFLITO_TERAPEUTA_SALA: 'conflitoTerapeutaSala',
    CONFLITO_PACIENTE: 'conflitoPaciente',
    DUPLICADO: 'duplicado',
    AVALIACAO: 'avaliacao',
    REPOSICAO: 'reposicao',
    AGUARDANDO_TERAPEUTA: 'aguardandoTerapeuta',
    REPOSICAO_FEITA: 'reposicao_feita',
    AVALIACAO_FEITA: 'avaliacao_feita',
    SUBSTITUICAO: 'substituicao',
    SUBSTITUICAO_FEITA: 'substituicao_feita',
    PADRAO: 'padrao'
}

const tiposAtendimentoEnum = {
    PADRAO: 1,
    AVALIACAO: 2,
    REPOSICAO: 3,
    REPOSICAO_FEITA: 4,
    SUBSTITUICAO: 5,
    AVALIACAO_FEITA: 6,
    SUBSTITUICAO_FEITA: 7,
    AGUARDANDO_TERAPEUTA: 8,
}

/**
 * Função que coleta a legenda que o agendamento terá, verificando se há ou não conflitos.
 * @param {Object} agendamento O Objeto de agendamento a partir do qual a verificação será feita
 * @param {Object[]} listaAgendamentos Lista de agendamentos do dia que serão verificados
 *
 * @returns {string|null} Enum indicando o tipo de agendamento conforme legenda,
 *                       ou null caso não exista legenda ou atendimento passado seja inválido
 */
const getLegendaAgendamento = (agendamento, listaAgendamentos) => {
    if (!agendamento || !agendamento.id) return null;

    // 2.0 Remover o agendamento sendo verificado da lista de agendamentos
    listaAgendamentos = listaAgendamentos.filter((itemAgendamento) => itemAgendamento.id !== agendamento.id);

    // 2.1. Verificar se na agenda de espera existe um atendimento para outro terapeuta na mesma sala. ✅
    if (agendamento.tipo_atendimento_id === tiposAtendimentoEnum.AGUARDANDO_TERAPEUTA) {
        if (existeConflitoTerapeutaSala(agendamento, listaAgendamentos)) {
            return agendamentoItemLegendaEnum.CONFLITO_TERAPEUTA_SALA;
        }
    }

    // 2.2. Verificar se possui outros atendimentos para mesmo paciente no mesmo horário. ✅
    if (existeConflitoPaciente(agendamento, listaAgendamentos)) {
        return agendamentoItemLegendaEnum.CONFLITO_PACIENTE;
    }

    // 2.3. Verificar se possui outros atendimentos para mesmo terapeuta no mesmo horário.
    if (existeConflitoTerapeuta(agendamento, listaAgendamentos)) {
        return agendamentoItemLegendaEnum.CONFLITO_TERAPEUTA;
    }

    // 2.4. Verificar se o atendimento esta duplicado entre as agendas
    if (atendimentoDuplicado(agendamento, listaAgendamentos)) {
        return agendamentoItemLegendaEnum.DUPLICADO;
    }
    // 1. Avaliação, reposição ou reserva (possuem maior precedência)
    const returns = getLegendaByTipoAtendimento(agendamento);
    return returns;
}

const atendimentoDuplicado = (agendamento, listaAgendamentos) => {
    const conflitosTerapeuta = listaAgendamentos.filter((itemAgendamento) => {
        return (
            /* Testa é a estação é a mesma */
            itemAgendamento.estacao_id === agendamento.estacao_id &&
            /* Testa é o paciente é igual */
            itemAgendamento.paciente_id === agendamento.paciente_id &&
            /* Testa é o terapeuta é igual */
            itemAgendamento.terapeuta_id === agendamento.terapeuta_id &&
            /* Testa se é o horário é igual */
            itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id &&
            /* Verifica se não é reserva */
            itemAgendamento.reserva !== 1
        );
    });

    return conflitosTerapeuta.length > 0;
}

/**
 * Função que verifica e retorna se há ou não conflitos entre pacientes e terapeutas.
 * @param {Object} agendamento              O Objeto de agendamento a partir do qual a verificação será feita
 * @param {Object[]} listaAgendamentos      Lista de agendamentos do dia que serão verificados
 *
 * @returns {boolean}                       True, se há conflito de horário do paciente. False caso contrário
 */
const existeConflitoPaciente = (agendamento, listaAgendamentos) => {
    // Precisa estar no mesmo horário
    // Precisa ser o mesmo paciente
    const conflitosPaciente = listaAgendamentos.filter((itemAgendamento) => {
        if (agendamento.tipo_atendimento_id === 8) {
            return (
                (
                    (
                        /* Testa se a sala é difernte */
                        itemAgendamento.estacao_id !== agendamento.estacao_id &&
                        /* Verifica se é o mesmo paciente */
                        itemAgendamento.paciente_id === agendamento.paciente_id &&
                        /* Verifica se esta no mesmo horário */
                        itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id
                    )
                ) &&
                /* Verifica se não é reserva */
                itemAgendamento.reserva !== 1
            );
        } else {
            return (
                /* Verifica se é o mesmo paciente */
                itemAgendamento.paciente_id === agendamento.paciente_id &&
                /* Verifica se esta no mesmo horário */
                itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id &&
                /* Veririfica se o tipo de atendimento não é Aguardando Terapeuta */
                itemAgendamento.tipo_atendimento_id !== 8 &&
                /* Verifica se não é reserva */
                itemAgendamento.reserva !== 1
            );
        }
    })
    return conflitosPaciente.length > 0;
}

/**
 * @param {Object} agendamento              O Objeto de agendamento a partir do qual a verificação será feita
 * @param {Object[]} listaAgendamentos      Lista de agendamentos que serão verificados
 *
 * @returns {boolean}                       True, se há conflito de horário do terapeuta. False caso contrário
 */
const existeConflitoTerapeuta = (agendamento, listaAgendamentos) => {
    const conflitosTerapeuta = listaAgendamentos.filter((itemAgendamento) => {
        if (agendamento.tipo_atendimento_id === 8) {
            return (
                (
                    (
                        /* Testa se a sala é difernte */
                        itemAgendamento.estacao_id !== agendamento.estacao_id &&
                        /* Testa se o horário é igual */
                        itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id &&
                        /* Testa se o terapeuta é igual */
                        itemAgendamento.terapeuta_id === agendamento.terapeuta_id
                    )
                )
                &&
                /* Verifica se não é reserva */
                itemAgendamento.reserva !== 1
            );
        } else {
            return (
                /* Testa é o terapeuta é igual */
                itemAgendamento.terapeuta_id === agendamento.terapeuta_id &&
                /* Testa é o tipo não é tipo de atendimento */
                itemAgendamento.tipo_atendimento_id !== 8 &&/* Aguardando Terapeuta */
                /* Testa se é o horário é igual */
                itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id &&
                /* Verifica se não é reserva */
                itemAgendamento.reserva !== 1
            );
        }
    });

    return conflitosTerapeuta.length > 0;
}

/**
 * @param {Object} agendamento              O Objeto de agendamento a partir do qual a verificação será feita
 * @param {Object[]} listaAgendamentos      Lista de agendamentos que serão verificados
 *
 * @returns {boolean}                       True, se há conflito de horário do terapeuta. False caso contrário
 */
const existeConflitoTerapeutaSala = (agendamento, listaAgendamentos) => {
    // Precisa estar no mesmo horário
    // Precisa ser o mesmo terapeuta
    const conflitosTerapeutaSala = listaAgendamentos.filter((itemAgendamento) => {
        return (
            /* Testa se é na mesma sala e no mesmo horário */
            (itemAgendamento.estacao_id === agendamento.estacao_id && itemAgendamento.horarios_atendimento_id === agendamento.horarios_atendimento_id)
            /* Testa se tem terapeuta no agendamento ou no item */
            && (itemAgendamento.terapeuta_id && agendamento.terapeuta_id)
            /* Testa se os terapeutas são diferentes */
            && (itemAgendamento.terapeuta_id !== agendamento.terapeuta_id)
            /* Testa se os pacientes são diferentes */
            && (itemAgendamento.paciente_id !== agendamento.paciente_id)
            /* Testa se o atendimento de teste não é reserva */
            && itemAgendamento.reserva !== 1
        );
    });

    return conflitosTerapeutaSala.length > 0;
}

/**
 * @param {Object} agendamento      O Objeto de agendamento a partir do qual a verificação será feita
 *
 * @returns {string}                Enum indicando o tipo de agendamento conforme legenda
 */
const getLegendaByTipoAtendimento = (agendamento) => {
    return {
        [tiposAtendimentoEnum.PADRAO]: agendamentoItemLegendaEnum.PADRAO,
        [tiposAtendimentoEnum.AVALIACAO]: agendamentoItemLegendaEnum.AVALIACAO,
        [tiposAtendimentoEnum.REPOSICAO]: agendamentoItemLegendaEnum.REPOSICAO,
        [tiposAtendimentoEnum.REPOSICAO_FEITA]: agendamentoItemLegendaEnum.REPOSICAO_FEITA,
        [tiposAtendimentoEnum.SUBSTITUICAO]: agendamentoItemLegendaEnum.SUBSTITUICAO,
        [tiposAtendimentoEnum.SUBSTITUICAO_FEITA]: agendamentoItemLegendaEnum.SUBSTITUICAO_FEITA,
        [tiposAtendimentoEnum.AVALIACAO_FEITA]: agendamentoItemLegendaEnum.AVALIACAO_FEITA,
        [tiposAtendimentoEnum.AGUARDANDO_TERAPEUTA]: agendamentoItemLegendaEnum.AGUARDANDO_TERAPEUTA,
    }[agendamento.tipo_atendimento_id];
}

export {
    getLegendaAgendamento,
}