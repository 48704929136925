import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0;

@media only screen and (min-width: 860px) {
    margin: 0 auto;
  }
`;

export const ContainerSub = styled.div`
    @media only screen and (max-width: 859px) {
        // display: grid;
        padding: 0px 2em; 
          grid-template-areas: 
          "header" 
          "estatisticas" 
          "pacientes" 
          "acesso" 
          "recados" 
          "acessoRapido";
      }

    @media only screen and (min-width: 860px) {
        display: grid;
          grid-gap: 20px;
          grid-template-columns: auto auto auto;
          grid-template-areas: 
          "header  header  header" 
          "estatisticas pacientes acesso" 
          "recados  recados  acessoRapido";
      }
`;
export const Header = styled.div` 
    grid-area: header;
    padding: 17px 0px;
`;

export const Estatisticas = styled.div` 
    grid-area: estatisticas; 
`;

export const Pacientes = styled.div` 
    grid-area: pacientes; 
`;

export const Acesso = styled.div` 
    grid-area: acesso; 
`;

export const Recados  =  styled.div` 
    grid-area: recados; 
`;
export const AcessoRapido  =  styled.div` 
    grid-area: acessoRapido; 
`;
