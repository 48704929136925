import React from 'react';
import {ContainerIcone, ContainerNome, ContainerSubCard} from './styles';

function ProntuarioSubCard({ nome, icone, onClick }) {
  return (
      <ContainerSubCard onClick={onClick}>
        <ContainerIcone>
          {icone}
        </ContainerIcone>
        <ContainerNome>
          {nome}
        </ContainerNome>
      </ContainerSubCard>
  );
}

export default ProntuarioSubCard;
