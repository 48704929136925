import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { TableHorariosAtendimento } from "./TableHorariosAtendimento";
import HorariosAtendimentoService from "../../../../services/HorariosAtendimentoService";
import HorarioAtendimentoModal from "../../../../components/Modais/HorarioAtendimentoModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function HorariosAtendimento() {
    const color = "#8ed4ae";
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [horarioAtendimento, setHorarioAtendimento] = useState([]);

    const [selectedHorarioAtendimento, setSelectedHorarioAtendimento] = useState(null);

    const carregaHorariosAtendimento = () => {
        HorariosAtendimentoService.index()
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setHorarioAtendimento(data)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        carregaHorariosAtendimento();
    }, []);

    const showEditModal = (data) => {
        setSelectedHorarioAtendimento(data);
        setIsOpen(true);
    }

    return (
        <Container>
            <div>
                <button onClick={() => { setSelectedHorarioAtendimento(null); setIsOpen(true); }} className="btn-padrao">
                    <AiFillFileAdd style={{ marginRight: 5 }} />
                    Novo Horário de Atendimento
                </button>
            </div>
            <div>
                {(horarioAtendimento.length !== 0 && loading === false)
                    ? <TableHorariosAtendimento callback={carregaHorariosAtendimento} showEditModal={showEditModal} source={horarioAtendimento} />
                    : <ClipLoader color={color} loading={loading} css={override} size={50} />}
            </div>
            <HorarioAtendimentoModal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                callback={carregaHorariosAtendimento}
                horarioAtendimento={selectedHorarioAtendimento}
            />
        </Container>
    )
}
