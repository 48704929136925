import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import { Form } from '@unform/web';
import api from "../../../../../services/api";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { ClipLoader } from 'react-spinners';
import Input from 'components/Input';
import { CgClose } from 'react-icons/cg';
import ModalTitulo from 'components/ModalTitulo';
import Button from 'components/Button';

const ModalCarterinhaPlanoPaciente = ({ modalData, paciente_id, carterinhas, setCarterinhas, callBack }, ref) => {
    //#region VARIAVEIS
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "auto",
            maxHeight: "70vh",
            width: '50vw',
            marginRight: '-50%',
            padding: 0,
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };

    let index = 0;

    const [pacienteId, setPacienteId] = useState();
    const [defaultNumeroCarterinha, setDefaultNumeroCarterinha] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [planoDeSaudeSelectBox, setPlanoDeSaudeSelectBox] = useState([]);

    const [terapiaEscolhida, setTerapiaEscolhida] = useState(null);
    const [planoDeSaudeEscolhido, setPlanoDeSaudeEscolhido] = useState(null);
    const [especialidadeEscolhido, setEspecialidadeEscolhido] = useState(null);
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [salvando, setSalvando] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();
    //#endregion

    //#region FUNCOES MODAL
    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);
    function afterOpenModal() {

    }
    const closeModal = useCallback(() => {
        setDefaultNumeroCarterinha('');
        setPlanoDeSaudeEscolhido([]);
        setIsOpen(false);
    }, []);
    //#endregion
    //#region FUNCOES
    const getDefaultPlano = (planos) => {
        if (!modalData) return;
        console.log(planos, 'planos');
        console.log(modalData, 'modalData');
        const plano = planos.filter(data => {
            return data.value === modalData.plano_saude_id;
        })[0];
        setPlanoDeSaudeEscolhido(plano);
    }
    async function getDadosIniciais() {
        if (pacienteId) {
            const { data } = await api.get(`api/paciente/getCarterinhasFaltantes/${pacienteId}`);
            if (data?.message) {
                if (data?.message === "Não existem planos cadastrados") {
                    if (isOpen) {
                        Swal.fire('Não existem carterinhas', 'Não existem carterinhas a serem cadastradas!', 'warning')
                            .then(() => {
                                closeModal();
                            });
                    }
                }
            }
            const selectPlanoSaude = data.pendentes.map(d => ({
                "value": d.id,
                "label": d.nome_reduzido
            }));
            setPlanoDeSaudeSelectBox(selectPlanoSaude);
            setLoadingDadosIniciais(false);
        }
    };
    const editNewPacienteTerapia = (terapia, dados) => {
        let carterinhasLocal = [...carterinhas];
        const index = carterinhasLocal.indexOf(terapia);
        carterinhasLocal.splice(index, 1, dados);
        setCarterinhas(carterinhasLocal);
    };
    function functionThatSubmitsForm() {
        formRef.current.submitForm()
    };
    //#endregion
    //#region HANDLES
    const handleSubmit = async (data) => {
        setSalvando(true);
        try {
            let carterinhasLocal = [...carterinhas];
            if (planoDeSaudeEscolhido === null || data.numero_carterinha === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning')
            } else {
                const dados = {
                    numero_carterinha: data.numero_carterinha,
                    plano_saude_id: modalData === null ? planoDeSaudeEscolhido.value : modalData.plano_saude_id,
                    paciente_id: paciente_id,
                };
                /* Confere se é ou não Edição */
                if (modalData === null) {/* Caso não seja */
                    /* Confere se é edição de paciente, ou paciente novo */
                    dados.paciente_id = paciente_id;
                    let testIfInsert = await api.get(`api/carterinha-plano-paciente?filter[plano_saude_id]=${dados.plano_saude_id}&filter[paciente_id]=${dados.paciente_id}`);
                    testIfInsert = testIfInsert.data;
                    if (testIfInsert.length > 0) {
                        Swal.fire('Carterinha não pode ser cadastrada!', 'Já existe uma carterinha de mesmo plano cadastrada. Atualize a tela, para conferir se já não foi cadastrada por outro usuário.', 'error');
                    } else {
                        await api.post(`api/carterinha-plano-paciente`, dados).then(async ({ data }) => {
                            carterinhasLocal.push({ ...dados, plano_saude: { plano: planoDeSaudeEscolhido.label }, id: data.id });
                            snackbar.displayMessage('Adicionada nova terapia com sucesso!', 'success');
                        }).catch((e) => {
                            console.error(e.data);
                            console.error(e);
                            snackbar.displayMessage('Houve um problema ao adicionar a terapia! Aperte F12 para ver o erro!', 'error');
                        })
                    }
                    setCarterinhas(carterinhasLocal);
                    closeModal();
                } else {/* Caso seja */
                    dados.index_terapia = carterinhasLocal.length;
                    api.patch(`api/carterinha-plano-paciente/${modalData.id}`, dados).then(({ data }) => {
                        snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                        for (let i = 0; i < carterinhasLocal.length; i++) {
                            if (carterinhasLocal[i].id === modalData.id) {
                                carterinhasLocal[i] = { ...dados, plano_saude: { plano: modalData.plano_saude.nome_reduzido }, id: data.id };
                                break;
                            }
                        }
                        setCarterinhas(carterinhasLocal);
                        closeModal();
                    }).catch((e) => {
                        Swal.fire('Houve um problema ao tentar salvar!', '', 'error');
                    });
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Houve um problema ao tentar cadastrar!", '', 'error');
        } finally {
            setSalvando(false);
        }
    }
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {
        if (modalData === null) {
            setDefaultNumeroCarterinha('');
            setPlanoDeSaudeEscolhido([]);
        } else {
            setDefaultNumeroCarterinha(modalData.numero_carterinha);
            getDefaultPlano(planoDeSaudeSelectBox);
        }
    }, [modalData]);
    useEffect(() => {
        if (paciente_id) {
            setPacienteId(paciente_id);
        }
    }, [paciente_id]);
    useEffect(() => {
        setLoadingDadosIniciais(true);
        getDadosIniciais();
    }, [pacienteId]);
    //#endregion

    return (
        <Modal isOpen={isOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent appElement={document.getElementById('root')}>
            <nav className='navbar sticky-top bg-cinza-claro shadow m-0'>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <div className='w-200px'><ClipLoader size={22} color={'#000'} loading={loadingDadosIniciais} /></div>
                    <ModalTitulo className="text-center">{modalData === null ? "Adicionar Nova" : 'Alterar'} Carterinha do Plano</ModalTitulo>
                    <label className='btn-outline w-200px text-right pointer' onClick={() => closeModal()}>
                        <CgClose size={22} color='red' />
                    </label>
                </div>
            </nav>
            <Form ref={formRef} onSubmit={handleSubmit} className='row p-2 m-0'>
                <div className='col-12'>
                    <Input type={'text'} id={'numero_carterinha'} name={'numero_carterinha'} title={'Numero Carterinha'} defaultValue={defaultNumeroCarterinha} />
                </div>
                <div className='col-12'>
                    {modalData === null
                        ? <Select name="plano_saude_id" label="Plano de Saúde:" options={planoDeSaudeSelectBox} onChange={setPlanoDeSaudeEscolhido} defaultValue={modalData === null ? '' : planoDeSaudeEscolhido} />
                        : <Input type={'text'} id={'plano_saude'} name={'plano_saude'} title="Plano de Saúde:" defaultValue={modalData.plano_saude.nome_reduzido} />
                    }
                </div>
                <div className='col-12'>
                    <Button className='btn-padrao mt-30px' type="button" onClick={functionThatSubmitsForm} disabled={salvando}>
                        {salvando
                            ? <ClipLoader size={30}></ClipLoader>
                            : (modalData === null) ? "Adicionar" : "Alterar"
                        }
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default forwardRef(ModalCarterinhaPlanoPaciente);
