/**
 * Classe para formatação de strings com base em um padrão especificado.
 */
class Formatter {
    /**
     * Cria uma instância da classe Formatter.
     * 
     * @param {string} pattern - O padrão de formatação, utilizando '#' para indicar posições a serem substituídas.
     */
    constructor(pattern) {
        this.pattern = pattern;
    }

    /**
     * Formata um valor de acordo com o padrão fornecido na instância.
     * 
     * @param {string|number} value - O valor a ser formatado.
     * @returns {string} - O valor formatado de acordo com o padrão.
     */
    format(value) {
        // Remove tudo que não é dígito, mas não altera o valor original, pois a formatação ainda pode precisar
        const onlyDigits = value.replace(/\D/g, '');
        let formatted = '';
        let valueIndex = 0;
        
        for (let i = 0; i < this.pattern.length; i++) {
            if (this.pattern[i] === '#') {
                if (valueIndex < onlyDigits.length) {
                    formatted += onlyDigits[valueIndex];
                    valueIndex++;
                } else {
                    break;
                }
            } else {
                formatted += this.pattern[i];
            }
        }

        return formatted;
    }
}
export default Formatter;