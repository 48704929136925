import api from "./api";
import Swal from "sweetalert2";

class Arquivos {
    /**
        * @param string error URL da requisição do arquivo. Necessita de uma barra no final.
        * @return bool
    */
    static returnError(error = '') {
        if (error === 'sem_tabela') {
            console.error('Tabela para api de envio de arquivos não foi definida.');
        } else {
            if (error === 'sem_vetor') {
                console.error('Vetor para api de envio de arquivos não foi definida.');
            } else {
                console.error(error);
            }
        }
        return false;
    }
    /**
         * @param string tabela Tabela que sera usada pra fazer as requisições. Não é necessário barra, nem api no inicio.
         * @return bool
     */
    static index = async (tabela) => {
        if (tabela !== null) {
            try {
                return api.get(tabela);
            } catch (error) {
                return this.returnError(error);
            }
        } else {
            return this.returnError('sem_api');
        }
    }
    /**
        * @param string tabela Tabela que sera usada pra fazer as requisições. Não é necessário barra, nem api no inicio.
        * @param int id Id do registro a ser mostrado
        * @return bool
    */
    static show = async (tabela, id) => {
        if (tabela !== null) {
            try {
                return api.get(`${tabela}${id}`);
            } catch (error) {
                return this.returnError(error);
            }
        } else {
            return this.returnError('sem_api');
        }
    }
    /**
        * @description Função que armazena no banco de dados através da API enviada um conjundo de variáveis de um vetor, contendo um arquivo.
        * @param Array vetor com os dados a serem atualizado. Necessita de conter o id.
        * @param string tabela Tabela que sera usada pra fazer as requisições. Não é necessário barra, nem api no inicio.
        * @param string chave_arquivo A chave, definida no vetor, do arquivo.
        * @param bool debug Define se a função retornará informações de debug ou não.
        * @return bool Retorna se foi feita ou não a percistência dos dados no banco.
    */
    static store = async (vetor, tabela, chave_arquivo = 'arquivo') => {
        if (tabela !== null) {
            try {
                const form = new FormData();
                Object.keys(vetor).forEach((key) => {
                    if (key === chave_arquivo) {
                        if (chave_arquivo !== null) {
                            form.append(key, vetor[key], vetor[key].name);
                        } else {
                            form.append(key, vetor[key]);
                        }
                    } else {
                        form.append(key, vetor[key]);
                    }
                });
                return api.post(tabela, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
            } catch (error) {
                return this.returnError(error);
            }
        } else {
            return this.returnError('sem_tabela');
        }
    }
    /**
        * @description Função que atualiza no banco de dados através da API enviada um conjundo de variáveis de um vetor, contendo um arquivo.
        * @param Array vetor com os dados a serem atualizado. Necessita de conter o id.
        * @param string tabela Tabela que sera usada pra fazer as requisições. Não é necessário barra, nem api no inicio.
        * @param string chave_arquivo A chave, definida no vetor, do arquivo.
        * @param {} arquivo Define se a função retornará informações de debug ou não.
        * @param bool debug Define se a função retornará informações de debug ou não.
        * @return bool Retorna se foi feita ou não a percistência dos dados no banco.
    */
    static update = async (vetor, tabela, chave_arquivo = 'arquivo', arquivo) => {
        /* Debug dos parâmentros enviados na função. */
        if (tabela !== null) {
            if (vetor !== null) {
                try {
                    if (arquivo !== null) {
                        await api.put(`api/${tabela}/${vetor.id}`, vetor);
                        const form = new FormData();
                        form.append('id', vetor.id);
                        form.append(chave_arquivo, arquivo, chave_arquivo);
                        if (form[chave_arquivo] !== null) {
                            return await api.post(`api/${tabela}/arquivo/add/${vetor.id}`, form);
                        }
                    } else {
                        return await api.put(`api/${tabela}/${vetor.id}`, vetor);;
                    }
                } catch (error) {
                    return this.returnError(error);
                }
            } else {
                return this.returnError('sem_vetor');
            }
        } else {
            return this.returnError('sem_tabela');
        }
    }
    /**
     * Função utilizada para validar um arquivo carregado.
     * @param {string} ext_permitidas As extenções permitidas do arquivo.
     * @param {Number} tamanho O tamanho máximo do arquivo.
     * @param {} arquivo Arquivo a ser testado.
     * @returns bool
     */
    static validaArquivo(ext_permitidas, tamanho, arquivo) {
        let tamanho_atual = arquivo.size;
        if (arquivo !== null) {
            if (ext_permitidas.find((ext) => { return arquivo.name.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + 'Permitidas: ' + ext_permitidas, '', 'warning');
                return false;
            } else {
                if (tamanho_atual > tamanho) {
                    {
                        let tamanho_em_mb = tamanho / 1000;
                        Swal.fire(`Limite de tamanho do arquivo excedido! (Max: ${tamanho_em_mb}MB)`, '', 'warning');
                        return false;
                    }
                }
            }
        } else {
            Swal.fire('Houve um problema ao enviar o arquivo, tente novamente.', '', 'warning');
            return false;
        }
        return true;
    }
}

export default Arquivos;
