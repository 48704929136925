import React, { useMemo } from 'react';

import { AiOutlineDelete, AiOutlineDownload } from 'react-icons/ai';
import { TableDefault } from '../../../../../components/TableDefault';
import moment from 'moment/moment';
import { useAuth } from '../../../../../context/auth';
import DocumentoService from "../../../../../services/DocumentoService";
import Swal from 'sweetalert2';
import api from '../../../../../services/api';

export function TableDocumentosGerais({ source, deleteCallback = null, pacienteId, setDocumentos }) {

    const data = React.useMemo(() => source, [source]);
    const { user } = useAuth();
    const documentoService = new DocumentoService('paciente');

    const handleDeleteDocument = async (data) => {
        const id = data.id;
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let documentosLocal = [...source];
                if (pacienteId === null || pacienteId === undefined) {
                    return '';
                } else {
                    documentoService.destroy(id, { id: id, documento_administrativo: id })
                        .then(() => {
                            const index = documentosLocal.indexOf(data);
                            documentosLocal.splice(index, 1);
                            setDocumentos(documentosLocal);
                        })
                }

            }
        });
    }
    const downloadFile = (id, name) => {
        api.get('api/documento/download/' + id + '?id=' + id, { responseType: 'blob' })
            .then((response) => {
                download(response, name);
            });
    }

    const download = (response, name) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Use o nome do arquivo do cabeçalho da resposta
        const contentDisposition = response.headers['content-type'];
        let fileName;
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.split('/');
            fileName = 'DOC - ' + name + '.' + fileNameMatch[1];
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Data',
                accessor: 'created_at', // accessor is the "key" in the data
                Cell: ({ cell }) => (
                    (<>
                        {moment(cell.row.original.created_at).format("DD/MM/YYYY HH:ss")}
                    </>
                    )
                ),
            },
            {
                Header: 'Nome',
                accessor: 'nome',
            },
            {
                Header: 'Opções',
                accessor: 'name',
                Cell: ({ cell }) => (
                    (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <AiOutlineDownload className="icone-padrao" onClick={(e) => downloadFile(cell.row.original.id, cell.row.original.nome)} />
                                <AiOutlineDelete className='icone-padrao' onClick={e => handleDeleteDocument(cell.row.original)} />
                            </div>
                        </>
                    )
                ),
            },
        ],
        [user.id, handleDeleteDocument, deleteCallback],
    );

    return (
        <TableDefault columns={columns} source={data} />
    );
}
