import React, {useEffect, useState} from "react";
import {AiFillFileAdd} from "react-icons/ai";
import {Container} from './styles';

import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import AreaCompartilhadaService from "../../../../services/AreaCompartilhadaService";
import TableAreaCompartilhada from "./TableAreaCompartilhada";
import AreaCompartilhadaModal from "../../../../components/Modais/AreaCompartilhadaModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function AreaCompartilhada() {

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#8ed4ae");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Areas, setAreas] = useState([]);
  const [terapeuta, setTerapeuta] = useState({});
  const [modalData, setModalData] = useState(null);

  const loadAreas = () => {
    AreaCompartilhadaService.index()
      .then(({data}) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        setAreas(data);
      })
      .finally(() => setLoading(false));
  }

  const handleNewArea = () => {
    setIsOpen(true);
    setModalData(null);
  }

  useEffect(() => {
    let t = JSON.parse(sessionStorage.getItem('@Neuro:user'));
    setTerapeuta(t);
    loadAreas();
  }, []);

  return (
    <Admin>
      <Container>
        <div>
          <button
            onClick={() => handleNewArea()}
            style={{
              backgroundColor: '#7340BD',
              color: '#FFFFFF',
              fontFamily: "Nunito, sans-serif",
              border: "none",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              marginBottom: 20
            }}>
            <AiFillFileAdd style={{marginRight: 5}}/>
            Nova Area
          </button>
        </div>
        <div>
          {(Areas.length !== 0 && loading === false) ? <TableAreaCompartilhada callback={loadAreas} source={Areas} 
            setIsOpen={setIsOpen} setModalData={setModalData}/> :
            <ClipLoader color={color} loading={loading} css={override} size={50}/>}
        </div>
        <AreaCompartilhadaModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          callback={loadAreas}
          modalData={modalData}
        />
      </Container>
    </Admin>)
}
