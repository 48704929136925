import React, { useMemo } from 'react';
import { AiOutlineDelete, AiOutlineDownload } from 'react-icons/ai';
import { TableDefault } from '../../../../components/TableDefault';
import moment from 'moment/moment';
import baseURL from '../../../../services/baseUrl';
import { useAuth } from '../../../../context/auth';
import Swal from 'sweetalert2';
import api from '../../../../services/api';
import { useSnackbar } from '../../../../context/snackbar';
import { CgEditFade } from 'react-icons/cg';
import { BsPencil } from 'react-icons/bs';

export function TableCarteiraConselho({ source, deleteCallback = null, handleEditConselho = () => { }, terapeutaId, setConselhos, terapias }) {

    const data = useMemo(() => source, [source]);
    const { user } = useAuth();
    const snackbar = useSnackbar();
    const url = 'api/terapeuta-carteira-conselho/';  // URL da API para carteirinhas de conselho

    const handleDeleteConselho = async (id) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    if (terapeutaId === null || terapeutaId === undefined) {
                        let conselhosLocal = [...source];
                        const index = conselhosLocal.findIndex(conselho => conselho.id === id);
                        conselhosLocal.splice(index, 1);
                        setConselhos(conselhosLocal);
                        deleteCallback && deleteCallback();
                    } else {
                        await api.delete(`${url}${id}?id${id}`).then(() => {
                            let conselhosLocal = [...source];
                            const index = conselhosLocal.findIndex(conselho => conselho.id === id);
                            conselhosLocal.splice(index, 1);
                            setConselhos(conselhosLocal);
                            snackbar.displayMessage('Conselho removido com sucesso!', 'success');
                            deleteCallback && deleteCallback();
                        }).catch((error) => {
                            console.log(error);
                            Swal.fire('Erro ao remover o conselho!', error?.data?.message, 'error');
                        });
                    }
                } catch (error) {
                    console.error(error);
                    Swal.fire('Erro ao remover o conselho!', '', 'error');
                }
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Terapia',
                accessor: 'terapia',
                Cell: ({ cell }) => (
                    <div>
                        {terapias.find(terapia => terapia.value === cell.row.original.terapia_id)?.label}
                    </div>
                ),
            },
            {
                Header: 'Número',
                accessor: 'numero',
            },
            {
                Header: 'Data de Cadastro',
                accessor: 'created_at',
                Cell: ({ cell }) => (
                    <div>
                        {moment(cell.row.original.created_at).format("DD/MM/YYYY HH:mm")}
                    </div>
                ),
            },
            {
                Header: 'Opções',
                Cell: ({ cell }) => (
                    <div className='d-flex align-center justify-center'>
                        <BsPencil className='pointer mx-2'
                            color="#7340BD"
                            size={20}
                            onClick={() => handleEditConselho(cell.row.original)}
                        />
                        <AiOutlineDelete className='pointer mx-2'
                            color="#7340BD"
                            size={22}
                            onClick={() => handleDeleteConselho(cell.row.original.id)}
                        />
                    </div>
                ),
            },
        ],
        [user.id, handleDeleteConselho, deleteCallback]
    );

    return (
        <TableDefault columns={columns} source={data} />
    );
}