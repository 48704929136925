import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    min-width: 20%;
    box-shadow: 0 0 10px 0px silver;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: #D4D6D8 1px solid;
`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div`
    background: silver; 
    grid-area: name;
    padding: 40px 0px;
`;
export const ContainerCards = styled.div`
    background: red; 
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
`;
export const ContainerSolicitacoes = styled.div`
    background: black;
    grid-area: footer;
`;
