import styled from 'styled-components';

let smallScreen = window.innerWidth < 600;

export const Modal = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${smallScreen ? 'block' : 'flex'};
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: none`;


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: ${smallScreen ? '15px' : '30px'};
  color: #000;
  width: ${smallScreen ? '100vw' : '60vw'};
  height: ${smallScreen ? '70vh' : '70vh'};
  border-radius: 10px;
  overflow-x: auto;
  `;


export const Close = styled.div`
  background-color: transparent;
  outline: none;
  width: 32px;
  height: 32px;`;
