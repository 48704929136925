import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../services/api';
import moment from 'moment';
import userGeneric from '../../../../assets/img_avatar.png';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
import DetalhesDoAgendamento from '../../../../pages/TerapeutaDashboard/Agenda/Modal/DetalhesDoAgendamento';
const AgendaPacienteModal = forwardRef(({ id = 'agendaModal', paciente_id, outSideSet = () => { }, temDetalhes = true }, ref) => {
    //#region VARIÁVEIS
    /*===VARS TELA===*/
    const title = 'Agenda do Paciente';
    const [isOpen, setIsOpen] = useState(false);
    const { user } = useAuth();
    /*===VARS VALIDACAO===*/
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [loading, setloading] = useState(false);

    const [paciente, setPaciente] = useState(null);
    const [horarios, setHorarios] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedPacienteId, setSelectedPacienteId] = useState();
    const [viewSize, setViewSize] = useState(window.innerWidth);
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'Segunda'
        },
        {
            value: 2,
            label: 'Terça'
        },
        {
            value: 3,
            label: 'Quarta'
        },
        {
            value: 4,
            label: 'Quinta'
        },
        {
            value: 5,
            label: 'Sexta'
        },
        {
            value: 6,
            label: 'Sábado'
        },
    ];

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 15
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: `${viewSize > 600 ? 80 : 100}vw`,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF",
            zIndex: 100
        },
    };
    //#endregion
    //#region FUNÇÕES DA MODAL
    /**
     * Opens the modal and sets the selected paciente ID.
     * 
     * @param {string} paciente_id - The ID of the paciente.
     */
    const openModal = useCallback((paciente_id) => {
        setSelectedPacienteId(paciente_id);
        setPaciente(null);
        setAtendimentos([])
        setIsOpen(true);
        outSideSet(true);
    }, []);
    /**
     * Closes the modal and resets the selected patient ID.
     */
    const closeModal = useCallback(() => {
        setSelectedPacienteId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    /**
     * Callback function called after the modal is opened.
     */
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    //#region FUNCOEs
    /**
     * Retrieves a patient's information from the API.
     * 
     * @param {number} paciente_id - The ID of the patient.
     */
    const getPaciente = (paciente_id) => {
        if (paciente_id !== undefined && paciente_id !== null) {
            api.get(`api/paciente/${paciente_id}?with=diagnostico1,diagnostico2`)
                .then(({ data }) => {
                    setPaciente(data);
                });
        }
    }
    /**
     * Retrieves the agenda horarios from the API.
     */
    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        })
    }
    /**
     * Retrieves the agenda for a specific day.
     * 
     * @returns {void}
     */
    const getAgendaDia = () => {
        let paciente_id = selectedPacienteId;
        api.get(`/api/agendamento?filter[paciente_id]=${paciente_id}&with=estacao,estacao.sala`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        })
    }
    /**
     * Opens the modal for displaying details of an appointment.
     *
     * @param {Object} detalhes - The details of the appointment.
     */
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    }
    //#endregion
    useEffect(() => {
        getAgendaHorarios();

        window.addEventListener('resize', () => {
            setViewSize(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setViewSize(window.innerWidth);
            });
        }
    }, []);
    useEffect(() => {
        try {
            if (selectedPacienteId !== undefined && selectedPacienteId !== null && horarios) {
                const carregaTudo = () => {
                    getPaciente(selectedPacienteId);
                    getAgendaDia();
                }
                setloading(true);
                setPaciente(null)
                setAtendimentos([]);
                carregaTudo();
                setloading(false);
            }
        } catch (e) {
            console.error("Erro ao carregar dados do paciente - ", e);
        }
    }, [selectedPacienteId, horarios]);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                    <label></label>
                    <label>{title}</label>
                    <label>
                        <button className='btn-icone' onClick={closeModal}>
                            <AiOutlineClose color='red' size={22} />
                        </button>
                    </label>
                </div>
                <div className='d-flex justify-arround'>
                    <table>
                        <thead>
                            <tr>
                                <td key={'dias-da-semana'} align={'center'} className='tr1'>Horário</td>
                                {diasDaSemanaArray.map(dia => (
                                    <td key={'dia-semana' + dia.value} className={`text-center w-150px${diaSemanaIndex === dia.value ? ' tr2' : ' tr1'}`}>{dia.label}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {horarios.map((horario, horarioIdx) => {
                                const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                return (
                                    <tr key={`linha-horario-${horarioIdx}`}>
                                        <td align={'center'} className='trHora'>{horasExtenso}</td>
                                        {diasDaSemanaArray.map(dia => {
                                            let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso);
                                            if (atendimentoEncontrado.length > 0) {
                                                atendimentoEncontrado = atendimentoEncontrado[0];
                                                return (
                                                    <td onClick={() => { if (temDetalhes) modalDetalhesDoAtendimento(atendimentoEncontrado) }}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                        className={temDetalhes ? 'trOcupado w-150px h-90px cursor-zoom' : 'trOcupado w-150px h-90px'}
                                                        style={{ textAlign: 'center' }}>
                                                        <span className='d-flex justify-center flex-row'>
                                                            {atendimentoEncontrado.estacao.sala.sala} - {atendimentoEncontrado.estacao.estacao}
                                                        </span>
                                                        {atendimentoEncontrado.terapeutum.nome.substr(0, 15)} {(atendimentoEncontrado.terapeutum.nome.length > 15) ? '...' : ''}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td className='trVazio w-150px h-90px' key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                                                );
                                            }
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className='d-flex flex-column align-content-start max-w-300px min-w-300px'>
                        <div className='d-flex flex-column align-items-center bg-white w-100' style={{ boxShadow: "0 0 10px 0px silver", borderRadius: 10, height: 'auto' }}>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-center'>
                                    <img src={
                                        (paciente === null)
                                            ? userGeneric
                                            : (paciente.foto_perfil === null || paciente.foto_perfil === '')
                                                ? userGeneric
                                                : paciente.foto_perfil
                                    } height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' alt={''} />
                                </div>
                                <div className='d-flex flex-column m-2'>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Nome:
                                        </label> {paciente?.nome}
                                    </span>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Idade:
                                        </label> {moment(paciente?.nascimento).diff(moment(), 'years') * -1}
                                    </span>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>
                                            Diagnóstico:
                                        </label>
                                        <ul>
                                            <li>{paciente?.diagnostico1?.diagnostico}</li>
                                            <li>{paciente?.diagnostico2?.diagnostico}</li>
                                        </ul>
                                    </span>

                                </div>
                            </div>
                            <div className='d-flex justify-arround col-12'>
                                <a className='btn-padrao mb-2' href={`/dashboard/${user.tipo_user}/pacientes/perfil/${paciente_id}`} target='blank'>Cadastro</a>
                                <a className='btn-padrao mb-2' href={`/dashboard/${user.tipo_user}/pacientes/prontuarios/${paciente_id}`} target='blank'>Prontuário</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {isModalDetalhesDoAtendimentoVisible
                ? <DetalhesDoAgendamento agendamentoId={atendimentoId} onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)} />
                : (<></>)
            }
        </>
    )
});
export default AgendaPacienteModal;