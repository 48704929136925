import React, { useMemo } from "react";
import { useTable } from 'react-table';
import { TableDefault } from "../../../../components/TableDefault";
import moment from "moment";

export function TableHistoricoDeFerias({source}) {

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
    () => [
      {
        Header: 'Data Inicio',
        accessor: 'data_inicio',
        Cell: ({ value }) => {
          return (
            <div >
              {moment.utc(value).format('DD/MM/YYYY')}
            </div>
          )
        }
      },
      {
        Header: 'Data Fim',
        accessor: 'data_fim',
        Cell: ({ value }) => {
          return (
            <div >
              {moment.utc(value).format('DD/MM/YYYY')}
            </div>
          )
        }
      },
      {
        Header: 'Comentário',
        accessor: 'comentario',
      },
    ],
    []
  )

  return (
      <TableDefault columns={columns} source={data} />
  );
}
