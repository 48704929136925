import React, { useEffect, useState } from 'react';
import api from '../../services/api';

const ActiveConnections = () => {
    const [connections, setConnections] = useState([]);

    useEffect(() => {
        const fetchConnections = async () => {
            try {
                const response = await api.get('http://localhost:8080/connections').then(({data})=>data);
                const data = await response.json();
                setConnections(data);
            } catch (error) {
                console.error('Error fetching connections:', error);
            }
        };

        fetchConnections();
        const intervalId = setInterval(fetchConnections, 5000); // Atualiza a cada 5 segundos

        return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
    }, []);

    return (
        <div>
            <h1>Conexões Ativas</h1>
            <ul>
                {connections.map((conn) => (
                    <li key={conn.id}>ID: {conn.id}, Address: {conn.remoteAddress}</li>
                ))}
            </ul>
        </div>
    );
};

export default ActiveConnections;
