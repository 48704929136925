import React, { useEffect, useRef, useState } from 'react';
import api from '../../services/api';
import ModalNotificacao from './ModalNotificacao';
import Input from '../Login/components/Input';
import Select from '../../components/Select';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { BsEye } from 'react-icons/bs';

const Notificacoes = () => {
    //#region VARIAVEIS
    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacoesOriginal, setNotificacoesOriginal] = useState([]);
    const [selectedUsuario, setSelectedUsuario] = useState('');
    const [selectedTitulo, setSelectedTitulo] = useState('');
    const [selectedConteudo, setSelectedConteudo] = useState('');
    const [selectedNivelUrgencia, setSelectedNivelUrgencia] = useState('');
    const [selectedTipo, setSelectedTipo] = useState('');
    const [selectedModoEnvio, setSelectedModoEnvio] = useState('');
    const [selectedVisualizado, setSelectedVisualizado] = useState('');
    const modalNotificacaoRef = useRef();
    //#endregion

    const handleVisualizar = (notificacao) => {
        Swal.fire({
            title: notificacao.titulo + ' - (Preview)',
            html: notificacao.conteudo,
            icon: notificacao.tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        });
    };

    const getNotificacao = async () => {
        try {
            api.get('api/notificacao?with=user')
            .then(({ data }) => {
                setNotificacoes(data);
                setNotificacoesOriginal(data);
            })
            .catch(error => console.error(error));
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        let newNotificacoes = [...notificacoesOriginal];
        newNotificacoes.filter(notificacao => {
            let usuario = selectedUsuario ? notificacao.user.name.toLowerCase().includes(selectedUsuario.toLowerCase()) : true;
            let titulo = notificacao.titulo.toLowerCase().includes(selectedTitulo.toLowerCase());
            let conteudo = notificacao.conteudo.toLowerCase().includes(selectedConteudo.toLowerCase());
            let nivelUrgencia = selectedNivelUrgencia ? notificacao.nivel_urgencia.toLowerCase().includes(selectedNivelUrgencia.value) : true;
            let tipo = selectedTipo ? notificacao.tipo.toLowerCase().includes(selectedTipo.value) : true;
            let modoEnvio = selectedModoEnvio ? notificacao.modo_envio.toLowerCase().includes(selectedModoEnvio.value) : true;
            let visualizado = selectedVisualizado ? (notificacao.visualizado ? selectedVisualizado.value === 'sim' : selectedVisualizado.value === 'não') : true;
            return usuario && titulo && conteudo && nivelUrgencia && tipo && modoEnvio && visualizado;
        });
        setNotificacoes(newNotificacoes);
    }, [selectedUsuario, selectedTitulo, selectedConteudo, selectedNivelUrgencia, selectedTipo, selectedModoEnvio, selectedVisualizado]);
    useEffect(() => {
        getNotificacao()
    }, []);

    return (
        <div className='container'>
            <h1>Notificações</h1>
            <Form>
                <table className='table table-striped'>
                    <thead>
                        <tr>
                            <th>
                                <Input
                                    id='search_usuario'
                                    name='search_usuario'
                                    type='text'
                                    label='Usuário'
                                    placeholder='Nome do usuário'
                                    onChange={(e) => setSelectedUsuario(e.target.value)}
                                />
                            </th>
                            <th>
                                <Input
                                    id='search_titulo'
                                    name='search_titulo'
                                    type='text'
                                    label='Título'
                                    placeholder='Título da notificação'
                                    onChange={(e) => setSelectedTitulo(e.target.value)}
                                />
                            </th>
                            <th>
                                <Input
                                    id='search_conteudo'
                                    name='search_conteudo'
                                    type='text'
                                    label='Conteúdo'
                                    placeholder='Conteúdo da notificação'
                                    onChange={(e) => setSelectedConteudo(e.target.value)}
                                />
                            </th>
                            <th>
                                <Select
                                    id='search_nivel_urgencia'
                                    name='search_nivel_urgencia'
                                    type='text'
                                    options={[
                                        { label: 'Baixo', value: 'baixo' },
                                        { label: 'Médio', value: 'médio' },
                                        { label: 'Alto', value: 'alto' }
                                    ]}
                                    label='Nível de Urgência'
                                    placeholder='Urgência'
                                    onChange={(e) => setSelectedNivelUrgencia(e)}
                                />
                            </th>
                            <th>
                                <Select
                                    id='search_tipo'
                                    name='search_tipo'
                                    type='text'
                                    options={[
                                        { label: 'Aviso', value: 'aviso' },
                                        { label: 'Alerta', value: 'alerta' },
                                        { label: 'Erro', value: 'erro' },
                                        { label: 'Informativo', value: 'informativo' },
                                        { label: 'Sucesso', value: 'sucesso' },
                                        { label: 'Questionamento', value: 'questionamento' }
                                    ]}
                                    label='Tipo'
                                    placeholder='Tipo'
                                    onChange={(e) => setSelectedTipo(e)}
                                />
                            </th>
                            <th>
                                <Select
                                    id='search_modo_envio'
                                    name='search_modo_envio'
                                    type='text'
                                    label='Modo de Envio'
                                    options={[{ label: 'Sistema', value: 'sistema' }]}
                                    placeholder='Envio'
                                    onChange={(e) => setSelectedModoEnvio(e)}
                                />
                            </th>
                            <th>
                                <Select
                                    id='search_visualizado'
                                    name='search_visualizado'
                                    type='text'
                                    label='Visualizado'
                                    options={[
                                        { label: 'Sim', value: 'sim' },
                                        { label: 'Não', value: 'não' }
                                    ]}
                                    placeholder='Visualizado'
                                    onChange={(e) => setSelectedVisualizado(e)}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th>Usuário</th>
                            <th>Título</th>
                            <th>Nível de Urgência</th>
                            <th>Tipo</th>
                            <th>Modo de Envio</th>
                            <th>Visualizado</th>
                            <th>Visualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notificacoes?.map(notificacao => (
                            <tr key={notificacao.id}>
                                <td>{notificacao.user.name}</td>
                                <td>{notificacao.titulo}</td>
                                <td>{notificacao.nivel_urgencia}</td>
                                <td>{notificacao.tipo}</td>
                                <td>{notificacao.modo_envio}</td>
                                <td>{notificacao.visualizado ? `Sim` : `Nao`}</td>
                                <td>
                                    <BsEye className='pointer' size={25} onClick={() => handleVisualizar(notificacao)} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Form>
            <ModalNotificacao ref={modalNotificacaoRef} getNotificacao={getNotificacao} />
        </div>
    );
};

export default Notificacoes;