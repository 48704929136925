import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AiFillProject, AiOutlineClockCircle } from 'react-icons/ai';
import { MdOutlineDriveFolderUpload, MdOutlineFeedback } from 'react-icons/md';
import { useAuth } from '../../context/auth';
import { FaCalendarAlt } from 'react-icons/fa';
import moment from 'moment';
import api from '../../services/api';
import { ContainerButton } from 'components/Header/styles';
import { NotificacaoPendenteP } from './styles';
moment().utc();

const HeaderTerapeuta = () => {

    const { hasDocumentosObrigatoriosPendentes, user } = useAuth();
    const [evolucoesPendentes, setStatusDiarioPendente] = useState(false);
    let url_atual = useLocation();
    const selected = { backgroundColor: '#5c3498' };
    const unselected = {};
    const usuario = useAuth();

    const getPendenciasPacientes = async () => {
        let conta = 0;
        api.get(`api/terapeuta/get/evolucoes/pendentes/` + usuario.user.terapeuta[0].id + '?tipo=dia').then(({ data }) => {
            data.forEach((atendimento) => {
                /* VARIAVEIS */
                const hora = moment(atendimento.data_atendimento).format('HH:mm').split(':');
                const horaAtual = moment().format('HH:mm:ss').split(':');
                let passa = true;
                let minutos = (horaAtual[0] - hora[0]) * 60 + (horaAtual[1] - hora[1]);
                passa = minutos >= 20;
                if (atendimento.sugestao_recepcao !== 1) {
                    passa = false;
                }
                if (passa) {
                    conta++;
                }
            });
        }).finally(() => {
            setStatusDiarioPendente(conta);
        })
    }

    useEffect(() => {
        getPendenciasPacientes();
    }, [])

    return (
        <>
            {!hasDocumentosObrigatoriosPendentes &&
                <>
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center" }}
                        to={"/dashboard/terapeuta/agenda"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "agenda" ? selected : unselected}>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <FaCalendarAlt />
                                <span>Agenda</span>
                            </div>
                        </ContainerButton>
                    </Link>
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center", }}
                        to={"/dashboard/terapeuta/statusdiario"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "statusdiario" ? selected : unselected}>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <AiFillProject />
                                <span>Status Diário</span>
                            </div>
                        </ContainerButton>
                    </Link>
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center" }}
                        to={"/dashboard/terapeuta/pendencias"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "pendencias" ? selected : unselected}>
                            {evolucoesPendentes > 0 &&
                                <NotificacaoPendenteP>{evolucoesPendentes}</NotificacaoPendenteP>
                            }
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <MdOutlineDriveFolderUpload />
                                <span>Pendências</span>
                            </div>
                        </ContainerButton>
                    </Link>{/* 
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center" }}
                        to={"/dashboard/terapeuta/pacientes"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "pacientes" ? selected : unselected}>
                            {contaPendenciasPacientes > 0 &&
                                <NotificacaoPendenteP title='Existem planos terapeuticos pendentes.'> {contaPendenciasPacientes} </NotificacaoPendenteP>
                            }
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <FaUserAlt />
                                <span>Pacientes</span>
                            </div>
                        </ContainerButton>
                    </Link> */}
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center" }}
                        to={"/dashboard/terapeuta/solicitacoes"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "solicitacoes" ? selected : unselected}>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <MdOutlineFeedback />
                                <span>Solicitações</span>
                            </div>
                        </ContainerButton>
                    </Link>
                    <Link
                        style={{ textDecoration: "none", alignItems: "center", justifyContent: "center" }}
                        to={"/dashboard/terapeuta/ultimos-atendimentos"}>
                        <ContainerButton style={url_atual.pathname.split('/')[3] === "ultimos-atendimentos" ? selected : unselected}>
                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", width: '100%' }}>
                                <AiOutlineClockCircle />
                                <span>Atendimentos</span>
                            </div>
                        </ContainerButton>
                    </Link>
                </>
            }
        </>
    );
};

export default HeaderTerapeuta;
