import React, { useEffect, useState } from "react";
import { AiOutlineUser, AiOutlineBell } from "react-icons/ai";
import api from "../../../services/api";
import { Container } from './styles';
import { TableAtendimentos } from "./TableAtendimentos";
import { BotaoAcao } from "../../RecepcaoDashboard/Agenda/ControleDiario/styles";
import Admin from "../../../layouts/Admin";
import FiltroAtendimentos from "../../../components/Filter/FiltroAtendimentos";
import { BotaoAcaoStyle, BotaoInserirAtendimento } from "./styles";
import NovoAtendimentoModal from "../../../components/Modais/NovoAtendimentoModal";
import AtendimentoAlteracaoService from "../../../services/AtendimentoAlteracaoService";
import moment from 'moment';
import EditStatus from "../../../components/Modais/EditStatusModal";

export default function Atendimentos() {

    const [paciente, setPaciente] = useState([]);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [houveInclusaoAtendimento, setHouveInclusaoAtendimento] = useState(false);
    const [houveAlteracaoStatusEdicao, setHouveAlteracaoStatusEdicao] = useState(false);
    const [atendimentos, setAtendimentos] = useState([]);
    const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
    const [isTodasPendenciasResolvidas, setIsTodasPendenciasResolvidas] = useState(true);
    const [terapeuta, setTerapeuta] = useState({});

    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
    const [modalAtendimentoData, setModalAtendimentoData] = useState(null);

    const [modalEditStatus, setModalEditStatus] = useState(false);
    const [modalData, setModalData] = useState([]);

    const handleOpenAtendimento = () => {
        setIsModalAdicionarAtendimentoVisible(true);
        setModalAtendimentoData(null);
    }

    const handleOpenModalEdit = (data) => {
        setModalData(data);
        setModalEditStatus(true);
    }

    useEffect(() => {
        let t = JSON.parse(sessionStorage.getItem('@Neuro:user'));
        setTerapeuta(t);
        if (!houveAlteracaoStatusEdicao && !houveInclusaoAtendimento) return;
        getAgendaDia();
        loadAtendimentos();
        setHouveAlteracaoStatusEdicao(false);
        setHouveInclusaoAtendimento(false);
    }, [houveAlteracaoStatusEdicao, houveInclusaoAtendimento]);

    useEffect(() => {
        loadAtendimentos();
    }, []);

    const getAgendaDia = () => {
        api.get('/api/atendimento?with=terapium,especialidade,estacao,estacao.sala,tipo_atendimento,modalidade,atendimento_alteracaos,' +
            'atendimento_alteracaos.terapium,atendimento_alteracaos.especialidade,atendimento_alteracaos.modalidade,' +
            'atendimento_alteracaos.estacao,atendimento_alteracaos.estacao.sala,atendimento_alteracaos.tipo_atendimento&filter[data_atendimento_inicial]=' + dataSelecionada).then(({ data }) => {
                if (data.length < 1) {
                    setAtendimentos(data);
                }
                data.map(atendimento => {
                    atendimento.cor = AtendimentoAlteracaoService.getCorStatus(atendimento.atendimento_alteracaos);
                    atendimento.alteracao_pendente = AtendimentoAlteracaoService.isAlteracaoPendente(atendimento.atendimento_alteracaos);
                    atendimento.editavel = AtendimentoAlteracaoService.isAlteracaoEditavel(atendimento.atendimento_alteracaos);
                    atendimento.is_ultima_aprovada = AtendimentoAlteracaoService.isUltimaAprovada(atendimento.atendimento_alteracaos);
                    atendimento.ultima_alteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
                    if (atendimento.alteracao_pendente) setIsTodasPendenciasResolvidas(false);
                    return atendimento;
                });
                setAtendimentos(data);
            });
    };

    async function loadAtendimentos() {
        api.get(`api/atendimento?with=terapeutum,paciente,status_atendimento`).then(({ data }) => {
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            setPaciente(data);
            setPacienteOriginal(data);
        })
    }

    return (<Admin>
        <Container>
            <div className="div-submenu">
                <div>
                    <label>Atendimentos</label>
                </div>
            </div>
            <div>
                <FiltroAtendimentos resultRef={setPaciente} list={pacienteOriginal} />
            </div>
            <div>
                <TableAtendimentos source={paciente} openModal={handleOpenModalEdit} callBack={loadAtendimentos}
                    setModalData={setModalAtendimentoData} handleOpenAtendimento={e => setIsModalAdicionarAtendimentoVisible(true)} />
            </div>
            <div style={BotaoInserirAtendimento} onClick={() => handleOpenAtendimento()}>
                <BotaoAcao style={BotaoAcaoStyle}>
                    <div style={{ alignItems: "center", justifyContent: "center", display: "flex", }}>
                        <p style={{ fontSize: 35, marginLeft: -10, }}>+</p>
                        <p style={{ marginLeft: 3, marginTop: 5 }}> Inserir Atendimento</p>
                    </div>
                </BotaoAcao>
            </div>
        </Container>
        {isModalAdicionarAtendimentoVisible
            && <NovoAtendimentoModal
                atendimentos={atendimentos}
                setHouveInclusaoAtendimento={() => setHouveInclusaoAtendimento(true)}
                onClose={() => setIsModalAdicionarAtendimentoVisible(false)}
                modalData={modalAtendimentoData} />
        }

        {modalEditStatus
            && <EditStatus
                onClose={e => setModalEditStatus(false)}
                modalData={modalData}
                setUpdate={setHouveAlteracaoStatusEdicao} />
        }
    </Admin>)
}
