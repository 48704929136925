import { TableDefaultPagination } from "components/TableDefaultPagination";
import React, { useMemo } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import api from "services/api";
import Swal from "sweetalert2";

export default function TableTipoDemandas({ source, callback, showEditModal }) {

	const handleDelete = (id) => {
		Swal.fire({
			title: 'Deseja realmente remover este tipo de demanda?',
			showDenyButton: true,
			confirmButtonText: 'Remover',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				api.delete(`api/tipo-demanda/${id}&id=${id}`)
					.then(() => {
						Swal.fire('Tipo de demanda removido com sucesso!', '', 'success');
						callback();
					})
					.catch(() => Swal.fire('Houve um erro ao tentar remover este tipo de demanda', '', 'error'))
			}
		})
	}
	const columns = useMemo(
		() => [
			{
				Header: 'Tipo de Demanda:',
				accessor: 'tipo_demanda',
				Cell: ({ cell }) => (
					<span>
						{cell.row.original.tipo_demanda}
					</span>
				)
			},
			{
				Header: 'Prioridade:',
				accessor: 'prioridade',
				Cell: ({ cell }) => {
					const priority = cell.row.original.prioridade;

					// Definindo as cores de acordo com a prioridade
					const getPriorityColor = (priority) => {
						switch (priority) {
							case 0: return '#FFFF00'; // Amarelo
							case 1: return '#FFCC00';
							case 2: return '#FF9900';
							case 3: return '#FF6600';
							case 4: return '#FF3300';
							case 5: return '#FF0000'; // Vermelho
							default: return '#FFFF00'; // Amarelo como padrão
						}
					};

					return (
						<span style={{
							display: 'inline-block',
							width: '20px',
							height: '20px',
							backgroundColor: getPriorityColor(priority),
							borderRadius: '4px'
						}}>
						</span>
					)
				}
			},
			{
				Header: "Ações",
				accessor: "acoes",
				Cell: ({ cell }) => (
					<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "transparent"
					}}>
						<AiOutlineDelete
							style={{ cursor: 'pointer' }}
							color="#7340BD" size={22}
							onClick={
								() => handleDelete(cell.row.original.id)
							}
						/>
						<AiOutlineEdit
							color="#7340BD"
							style={{ cursor: 'pointer' }}
							size={22}
							onClick={
								() => showEditModal && showEditModal(cell.row.original)
							}
						/>
					</div>
				)
			}
		],
		[showEditModal]
	);
	return (
		<TableDefaultPagination columns={columns} source={source} />
	)
}