import React from "react";
import Recepcao from "../../../../layouts/Recepcao";
import {
    Container,
} from './styles'

import { Form } from '@unform/web'
import Input from '../../../../components/Input';

export default function NovoPaciente() {

    function handleSubmit(data) {
    }


    return (
        <Recepcao>
            <Container>

                <div style={{ display: "flex", width: "100%", flexDirection: "column", padding: "0 70px" }}>
                    <div style={{ display: "flex", width: "100%", flexDirection: "row", justifyContent: "space-between", paddingBottom: 50 }}>
                        <label>Novo Paciente</label>
                    </div>

                    <Form onSubmit={handleSubmit}>
                        <div style={{ width: "100%" }}>
                            <div style={{ display: "flex" }}>
                                <Input title="Número:" name="Numero" />
                                <Input title="Nome:" name="Nome" />
                                <Input title="Nome Curto" name="NomeCurto" />
                                <Input title="Identificador:" name="Identificador" />
                                <Input title="Data de Entrada:" name="DataDeEntrada" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="Status:" name="Status" />
                                <Input title="Data de Nascimento" name="DataDeNascimento" />
                                <Input title="CPF:" name="CPF" />
                                <Input title="RG:" name="RG" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="Rua:" name="Endereco" />
                                <Input title="Bairro:" name="Bairro" />
                                <Input title="Cidade:" name="Cidade" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="Estado:" name="Estado" />
                                <Input title="Complemento:" name="Complemento" />
                                <Input title="Rua do Resp." name="EnderecoResp" />
                            </div>
                        </div>
                        <div>

                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Terapias</label>
                            </div>
                            {/* <TableTerapias /> */}
                            {/* <button type="submit">Valores</button> */}
                        </div>
                        <div style={{ paddingTop: 30 }}>
                            <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Responsável 01 </label>

                            <div style={{ paddingTop: 20 }}>
                                <input value={false} type="checkbox" />
                                <span>Responsável Principal</span>
                            </div>

                            <div style={{ display: "flex" }}>
                                <Input title="Nome" name="Nome:" />
                                <Input title="Parentesco" name="Parentesco:" />
                                <Input title="CPF" name="CPF:" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="RG" name="RG:" />
                                <Input title="Telefone 01:" name="TelefoneUm" />
                                <Input title="Telefone 02:" name="TelefoneDois" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="Email" name="Email" />
                                <Input title="Rua" name="Endereco" />
                            </div>
                            <div style={{ display: "flex" }}>
                                <Input title="Bairro" name="Bairro:" />
                                <Input title="Cidade" name="Cidade:" />
                                <Input title="Estado" name="Estado:" />
                                <Input title="CEP" name="CEP:" />
                            </div>
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 20, marginTop: 20, paddingLeft: 18, paddingRight: 18 }}
                                type="submit">Adicionar
                            </button>
                        </div>

                        <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Emergências:</label>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Input title="Medicamento(s) em Uso:" name="MedicamentosEmUso" />
                                <Input title="Horario:" name="Horario" />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Input title="Alergia a algum medicamnto?" name="Alergia" />
                                <Input title="Qual(is)?" name="Quais" />
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Documentos Gerais:</label>
                            </div>
                            {/* <TableDocumentosGerais/> */}
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 20, marginTop: 20, paddingLeft: 18, paddingRight: 18 }}
                                type="submit">Adicionar
                            </button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Observações Gerais:</label>
                            <input multiple={true} style={{ height: 120, borderColor: "#F5F6F8", borderRadius: 8, borderWidth: 1 }} />
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 20, marginTop: 20, paddingLeft: 18, paddingRight: 18 }}
                                type="submit">Salvar
                            </button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label style={{ color: "#7441BF", fontWeight: "bold", fontSize: 17 }}>Histórico Férias:</label>
                            {/* <TableHistoricoDeFerias/> */}
                            <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 20, marginTop: 20, paddingLeft: 18, paddingRight: 18 }}
                                type="submit">Adicionar
                            </button>
                        </div>
                        <button style={{ backgroundColor: "#7441BF", color: "#FFFFFF", border: "none", borderRadius: 8, height: 34, marginBottom: 20, marginTop: 20, paddingLeft: 18, paddingRight: 18 }}
                            type="submit">Voltar
                        </button>
                    </Form>
                </div>

            </Container>
        </Recepcao>
    )
}
