import styled from 'styled-components';

export const DocumentoContainer = styled.div`
  height: 100%;
  max-height: 50vh;
  overflow: auto;
  width: 600px;
  border: 1px solid #000;
  overflow-x: hidden;
`
