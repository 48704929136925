import api from "./api";

class ResponsavelSalaService {
    static index = (params) => {
        return api.get(`api/responsavel-sala?${params}`);
    }
    static store = (obj) => {
        /* exemplo
        {"terapeuta_id":1,"sala_id":1,"data_hora":"2012-08-01"}
        */
                
        return api.post(`api/responsavel-sala`, obj);
    }
    static show = (id) => {
        return api.get(`api/responsavel-sala/${id}`);
    }
    static update = (obj, id) => {
        /* exemplo
        {"terapeuta_id":1,"sala_id":1,"data_hora":"2004-10-14"}
        */
                
        return api.put(`api/responsavel-sala/${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/responsavel-sala/${id}`);
    }
}

export default ResponsavelSalaService;