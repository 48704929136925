import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/auth';
import { FiPower } from 'react-icons/fi';
import UsuarioLogado from "../../components/UsuarioLogado";
import NotificacaoService from '../../services/NotificacaoService';
import user_default from 'assets/img_avatar.png';
import { AiOutlineUser, AiOutlineBell, AiOutlineDelete, AiFillBell, AiFillHeart, AiFillFile } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import Swal from 'sweetalert2';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'components/Dropdown';

const FuncoesUsuario = (props) => {
    const { signOut, user } = useAuth();
    const [abreNotificacoes, setAbreNotificacoes] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacaoPendente, setNotificacaoPendente] = useState(0);
    const [loading, setLoading] = useState(false);
    const [mostrandoOpcoesUsuario, setMostrandoOpcoesUsuario] = useState(false);
    const notificacaoDivRef = useRef();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);

    const abrirNotificacao = (id, titulo, conteudo, tipo, visualizado) => {
        const option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            closeButtonText: 'Fechar',
            showConfirmButton: !visualizado,
            showDenyButton: !visualizado,
            showCloseButton: true,
        };

        Swal.fire(option).then((result) => {
            if (result.isConfirmed) visualizarNotificacao(id);
            else if (result.isDenied) deletarNotificacao(id);
        });
    };

    const visualizarNotificacao = async (id) => {
        await NotificacaoService.visualiza(id).finally(() => getNotificacoes());
    };

    const deletarNotificacao = async (id) => {
        await NotificacaoService.delete(id).finally(() => getNotificacoes());
    };

    const atualizarTitulo = (notificacaoPendente_save) => {
        const splitedFilter = document.title.split(')');
        const filtredTitle = splitedFilter[1] ? splitedFilter[1] : splitedFilter;

        if (notificacaoPendente_save > 0) {
            document.title = `(${notificacaoPendente_save}) ${filtredTitle}`;
            enviarNotificacao('Neuro Sistema', {
                body: `Você tem ${notificacaoPendente_save} notificações pendentes. Clique para vê-las 😁`,
                icon: process.env.PUBLIC_URL + '/icone-teste-1.png'
            });
        } else {
            document.title = `${filtredTitle}`;
        }
    };

    const getNotificacoes = async () => {
        setLoading(true);
        await NotificacaoService.userIndex(user.id, 'sistema').then((response) => {
            if (isMountedRef.current) {
                let lista_notificacoes = [];
                let notificacaoPendente_save = 0;

                response.data.forEach((res) => {
                    if (res && res.visualizado == null) {
                        notificacaoPendente_save++;
                    }
                    lista_notificacoes.push(
                        <li key={res.id} className='ns-dropdown-item d-inline-flex justify-content-between mt-2 border-bottom' style={{ backgroundColor: '#f8f8f8' }}>
                            <div className={`col-9 ${res.visualizado == null ? 'font-vermelha' : ''}`} onClick={() => abrirNotificacao(res.id, res.titulo, res.conteudo, res.tipo, res.visualizado)}>
                                <button className='btn-outline'>{res.titulo}</button>
                            </div>
                            <div className='col-3' style={{ borderLeft: '0.5px solid rgb(50,50,50)' }}>
                                <button onClick={() => deletarNotificacao(res.id)} className='btn-outline'>
                                    <AiOutlineDelete />
                                </button>
                            </div>
                        </li>
                    );
                });

                atualizarTitulo(notificacaoPendente_save);
                setNotificacaoPendente(notificacaoPendente_save);
                setNotificacoes(lista_notificacoes);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const enviarNotificacao = (titulo, opcoes) => {
        /* if ("Notification" in window) {
            if (Notification.permission === "granted") {
                const notification = new Notification(titulo, opcoes);
                notification.onclick = (event) => {
                    event.preventDefault();
                    window.focus();
                    setAbreNotificacoes(true);
                };
            }
        } else {
            console.warn("A API de Notificações não está disponível neste dispositivo.");
        } */
    };

    const fechaNotificacoes = (event) => {
        if (notificacaoDivRef.current && !notificacaoDivRef.current.contains(event.target)) {
            setAbreNotificacoes(false);
        }
    };

    useEffect(() => {
        if (abreNotificacoes) {
            getNotificacoes();
        }
    }, [abreNotificacoes]);
    useEffect(() => {
        isMountedRef.current = true;
        if (!loading) getNotificacoes();

        /* if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        document.addEventListener('mousedown', fechaNotificacoes);

        return () => {
            isMountedRef.current = false;
            document.removeEventListener('mousedown', fechaNotificacoes);
        }; */
    }, []);

    return (
        <div className='mr-20px align-center justify-center flex-col'>
            <div className='d-flex align-center justify-center flex-row'>
                <div className=''>
                    <Dropdown
                        clickMode={props.isMobileView}
                        buttonOver={true}
                        button={
                            <div style={{ margin: 0, padding: 0 }}>
                                <button className={`ns-bell-button btn-sair no-box-shadow me-2 pop-light-blue font-branco ns-hover-border-transition-manual${abreNotificacoes ? ' manual-hover manual-border-remove-s-b manual-bg-white' : ''}`}
                                    style={{
                                        padding: '8px 0px 0px 0px',
                                    }}>
                                    {loading
                                        ? <>
                                            <div className='notificacaoPendente'>
                                                <p> <ClipLoader className="back-azul" color={'white'} size={8} /> </p>
                                                <AiOutlineBell size={20} className='font-branco' />
                                            </div>
                                        </>
                                        : notificacaoPendente > 0 ?
                                            <>
                                                <div className='notificacaoPendente'>
                                                    <p>{notificacaoPendente}</p>
                                                    <AiFillBell size={20} className='font-branco' />
                                                </div>
                                            </>
                                            :
                                            <AiOutlineBell size={20} className='font-branco' />
                                    }
                                </button>
                            </div>
                        }
                        content={
                            <div className='ns-dropdown-menu ns-dropdown-menu-right bg-branco' style={{ marginTop: '-17px', marginRight: '-4px', width: '200px', borderRadius: '15px', color: '#000', border: '4px solid #00728C' }}>
                                <p className='ns-dropdown-menu-title' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
                                    Notificações
                                </p>
                                <ul className='ns-dropdown-list min-h-100px'>
                                    {(loading)
                                        ? <ClipLoader className='text-center m-auto' size={20} />
                                        : notificacoes
                                    }
                                </ul>
                            </div>
                        }
                        setMostrando={setAbreNotificacoes}
                    />
                </div>
                <div className=''>
                    <Dropdown
                        clickMode={props.isMobileView}
                        buttonOver={true}
                        button={
                            <div style={{ margin: 0, padding: 0 }}>
                                <img className={`perfil-image btn-sair no-box-shadow pop-light-blue ns-hover-border-transition-manual${mostrandoOpcoesUsuario ? ' manual-hover manual-border-remove-s-b' : ''}`} style={{ maxWidth: '50px', maxHeight: '50px' }} src={user.foto_perfil_full ? user.foto_perfil_full : user_default} alt='' />
                            </div>
                        }
                        content={
                            <div className='ns-dropdown-menu ns-dropdown-menu-right bg-branco' style={{ marginTop: '-23px', marginRight: '-7px', width: '200px', borderRadius: '15px', color: '#000', border: '4px solid #00728C' }}>
                                <p className='ns-dropdown-menu-title ns-abrevia-texto' style={{ borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }}>
                                    {user.name}
                                </p>
                                <ul className='ns-dropdown-list'>
                                    <li><button className="ns-dropdown-item" onClick={(e) => navigate('/dashboard/perfil')}><AiOutlineUser /> Perfil</button></li>
                                    <li><button className="ns-dropdown-item ns-dropdown-item-disabled"><AiFillFile /> Documentos</button></li>
                                    <li><button className="ns-dropdown-item ns-dropdown-item-disabled"><AiFillHeart /> Favoritos</button></li>
                                    <li><hr className="ns-dropdown-item-divider"></hr></li>
                                    <li> <button className='ns-dropdown-item' onClick={signOut}> <FiPower /> Sair </button> </li>
                                </ul>
                            </div>
                        }
                        setMostrando={setMostrandoOpcoesUsuario}
                    />
                </div>
            </div>
            <div className='d-flex align-center justify-center'>
                {props && props.menu}
            </div>
            <div className='p-2 align-center justify-center d-sm-none'>
                <UsuarioLogado />
            </div>
        </div>
    );
};

export default FuncoesUsuario;
