import React from 'react';

import { Container } from './styles';


const Button = ({ children, loading, ...rest }) => (
  <Container type="button" {...rest} className={'btn-padrao font-branca ' + rest?.className}>
    {loading ? 'Carregando...' : children}
  </Container>
);

export default Button;