import React from "react";
import { Container } from './styles'


export default function EstatisticasCard({ title, estatisticas }) {
    return (
        <Container>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <label style={{ color: "#4906AB", fontWeight: "800", fontSize: '18px', fontFamily: "Nunito, sans-serif" }}>{title}</label>
            </div>
            <div style={{ paddingTop: '16px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridColumnGap: "1rem",
                    gridRowGap: "1rem",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#27DABE", height: '110px', width: '110px', borderRadius: '50%',
                        wordWrap: "break-word",
                        textAlign: "center",
                    }}>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{estatisticas.atendidos_hoje}</label>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Pacientes atendidos hoje</label>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#F8A314", height: '110px', width: '110px', borderRadius: '50%',
                        wordWrap: "break-word",
                        textAlign: "center"
                    }}>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{estatisticas.faltas_terapeuta}</label>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Faltas por Terapeutas</label>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#F76397", height: '110px', width: '110px', borderRadius: '50%',
                        wordWrap: "break-word",
                        textAlign: "center"
                    }}>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{estatisticas.faltas_paciente}</label>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Faltas por Paciente</label>
                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#3EBFE5", height: '110px', width: '110px', borderRadius: '50%',
                        wordWrap: "break-word",
                        textAlign: "center"
                    }}>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{estatisticas.reagendamento}</label>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Reagendamento</label>
                    </div>
                </div>
            </div>
        </Container>
    )
}