import moment from 'moment/moment';

class DiaHoraService {
  //FORMATO DB: YYYY-MM-DDTHH:mm:ss.SSSZ
  static getDiaMesAnoFromFormatoDatabase(diaCompleto) {
    return moment(diaCompleto).format('DD/MM/YYYY');
  }

  static getDiaSemanaFromFormatoDatabase(diaCompleto) {
    let dia = moment(diaCompleto).format('dddd');
    dia = dia.charAt(0).toUpperCase() + dia.slice(1);
    return dia;
  }

  static getHoraFromFormatoDatabase(horaCompleta) {
    return moment(horaCompleta.split('T')[1], 'HH:mm').format('HH:mm');
  }

  static getDiasSemana() {
    return [
      {label: 'Segunda-feira', value: 1},
      {label: 'Terça-feira', value: 2},
      {label: 'Quarta-feira', value: 3},
      {label: 'Quinta-feira', value: 4},
      {label: 'Sexta-feira', value: 5}
    ];
  }

  static getWeekDayFromDiaSemana(dataAtendimento) {
    return moment(dataAtendimento, 'DD/MM/YYYY').weekday();
  }
}

export default DiaHoraService;
