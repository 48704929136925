import React, { useEffect, useState } from "react";
import { Container, override } from './styles';
import api from "../../services/api";
import ClipLoader from "react-spinners/ClipLoader";
import Auditor from "../../layouts/Auditor";
import { useAuth } from "../../context/auth";
import { TablePacienteAuditor } from "./TablePaciente";
import moment from "moment/moment";

export default function AuditorDashboard() {
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [paciente, setPaciente] = useState([]);
    const [pacienteOriginal, setPacienteOriginal] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    const [planoSaudeId, setPlanoSaudeId] = useState(null);
    const color = "#8ed4ae";
    const { user } = useAuth();

    useEffect(() => {
        setLoadingDadosPacientes(true);
        let id = user.plano_saude_id;
        setPlanoSaudeId(id);
        async function carregaPacientes() {
            api.get(`api/paciente/by/plano-saude-id/${id}`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                data.filter((e) => {
                    return (e.evolucao_paciente);
                })
                setPacienteOriginal(data);
                setPaciente([]);
            })

            setLoadingDadosPacientes(false);
        }
        carregaPacientes();
    }, []);
    const handleChangePacienteSearch = (data) => {
        setFilterValue(data);
        setPaciente([]);
    }

    const filtrar = () => {
        setPaciente(pacienteOriginal.filter((item) => item.nome.toString().toLowerCase().includes(filterValue.toString().toLowerCase())));
    }

    return (
        <Auditor>
            <Container>
                <div className="d-flex align-items-center justify-content-center pt-4">
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                        <label style={{ fontWeight: "900", fontSize: 24, color: "#6A3BAF" }}>Bem vindo, {user.name} !</label>
                        <label style={{ fontWeight: "700", fontSize: 18, color: "#6A3BAF" }}>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                    </div>
                </div>
                <div className="w-100 p-2">
                    Para iniciar, digite o nome do paciente, e precione "Pesquisar". Aperte no icone de Olho do paciente que deseja acessar.
                </div>
                <div className="w-100 pb-4 pt-4">
                    <div className="input-group">
                        <input className="form-control" placeholder={"Pesquisar Paciente"}
                            id="filter"
                            name="filter"
                            value={filterValue || ''}
                            onChange={e => {
                                handleChangePacienteSearch(e.target.value)
                            }}
                        />
                        <button className="btn-padrao input-group-text bg-roxo font-branca" type="button" onClick={filtrar}> Pesquisar </button>
                    </div>
                </div>
                <div style={{ marginBottom: 20 }}>
                    {
                        (paciente.length > 0 && loadingDadosPacientes === false)
                            ? <TablePacienteAuditor source={paciente} plano_saude_id={planoSaudeId} />
                            : <ClipLoader color={color} loading={loadingDadosPacientes} css={override} size={50} />
                    }
                </div>
            </Container>
        </Auditor>
    )
}