import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import TableTerapias from "./TableTerapias";
import TerapiumService from "../../../../services/TerapiumService";
import TerapiaModal from "../../../../components/Modais/TerapiaModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Terapias() {

    const [loading, setLoading] = useState(true);
    const [color, setColor] = useState("#8ed4ae");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [Terapiums, setTerapiums] = useState([]);

    const [selectedTerapium, setSelectedTerapium] = useState(null);

    const loadTerapias = () => {
        TerapiumService.index()
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setTerapiums(data)
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadTerapias();
    }, []);

    const showEditModal = (terapiumData) => {
        setSelectedTerapium(terapiumData);
        setIsOpen(true);
    }

    return (
        <Admin>
            <Container>

                <div>
                    <button
                        onClick={() => {
                            setSelectedTerapium(null)
                            setIsOpen(true)
                        }} className="btn-padrao">
                        <AiFillFileAdd style={{ marginRight: 5 }} />
                        Nova Terapia
                    </button>
                </div>
                <div>
                    {
                        (Terapiums.length !== 0 && loading === false)
                            ? <TableTerapias callback={loadTerapias} showEditModal={showEditModal} source={Terapiums} />
                            : <ClipLoader color={color} loading={loading} css={override} size={50} />
                    }
                </div>
                <TerapiaModal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    callback={loadTerapias}
                    terapium={selectedTerapium}
                />
            </Container>
        </Admin>
    )
}
