import React, { useEffect, useMemo, useRef, useState } from "react";
import Carregamento from "../../../../components/Carregamento";
import { useAuth } from "../../../../context/auth";
import api from "../../../../services/api";
import { Container } from "../styles";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import { BsEye } from "react-icons/bs";
import Swal from "sweetalert2";
import RelatorioEvolucaoPaciente from "../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente";

export default function ListagemDeGlosas() {
    const { user } = useAuth();
    const [permissoes, setPermissoes] = useState([]);
    const [loading, setloading] = useState(false);
    const [glosasList, setGlosasList] = useState([]);
    const [glosasListOriginal, setGlosasListOriginal] = useState([]);
    const [selectedEvolucao, setSelectedEvolucao] = useState([]);
    const relatorioEdicaoModalRef = useRef();
    const colunas = useMemo(() => [
        {
            Header: 'Terapeuta',
            accessor: 'terapeuta.nome'
        },
        {
            Header: 'Paciente',
            accessor: 'paciente.nome'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: (({ cell }) => {
                const style = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px', margin: 'auto' }
                switch (cell.row.original.status) {
                    case ('aprovado'):
                        return (
                            <b style={{ ...style, backgroundColor: "#00ff00" }}>
                                Aprovado
                            </b>
                        )
                    case ('glosado'):
                        return (
                            <b style={{ ...style, backgroundColor: "#ff0000", color: 'white' }}>
                                Glosado
                            </b>
                        )
                    case (''):
                        return (
                            <b style={{ ...style, backgroundColor: "#ffff00" }}>
                                Pendente
                            </b>
                        )
                    default:
                        return (
                            <b style={{ ...style, backgroundColor: "#ffff00" }}>
                                Pendente
                            </b>
                        )
                }
            })
        },
        {
            Header: 'Ações',
            Cell: (({ cell }) => {
                let id = cell.row.original.id;
                setSelectedEvolucao(cell.row.original);
                return (
                    <div className="w-100per">
                        <button className="btn-icone" type="button" onClick={() => {
                            relatorioEdicaoModalRef?.current.openModal(id);
                        }}>
                            <BsEye size={25}></BsEye>
                        </button>
                    </div>
                )
            })
        }
    ])
    const coletaPermissoes = () => {
        let permissoes = user.lista_de_permissoes[0].permissions;
        let localPermissoes = [];
        for (let index = 0; index < permissoes.length; index++) {
            const permissao = permissoes[index];
            const nomePermissao = permissao.name.split('.');
            let local = window.location.pathname.split('/')[3];
            if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                localPermissoes[nomePermissao[1]] = true;
            }
        }
        setPermissoes(localPermissoes);
    }

    async function carregaTerapeutas() {
        api.get('api/evolucao-paciente?filter[status]=glosado&with=terapium,especialidade,paciente').then(({ data }) => {
            setGlosasList(data);
            setGlosasListOriginal(data);
        }).catch((e) => console.error(e));
    }

    const onLoad = async () => {
        setloading(true);
        await carregaTerapeutas();
        await coletaPermissoes();
        setloading(false);
    }

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <Container>
            {loading ? <Carregamento></Carregamento> : ''}
            <TableDefaultPagination columns={colunas} source={glosasList}></TableDefaultPagination>
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} onClose={() => { }} tipo={'visualizacao'} />
        </Container>
    )
}
