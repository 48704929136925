import api from "./api";

class SolicitacaoAtividadeExternaService {
    path = 'api/';

    index = () => {
        return api.get(`${this.path}solicitacao-atividade-externa`);
    }
    store = (obj) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":219218550,"terapia_id":1,"especialidade_id":1,"status_solicitacao_id":1,"user_id":9968,"user_id_status":401315,"objetivo":"Quia occaecati deleniti aut non facere ipsum fugiat. Dolorum beatae porro pariatur dolore dolore iure in quia.","local":"nihil","horario":"1991-02-27","descricao":"Veniam ut dolorem dolorum accusamus et. Sunt neque eaque non quidem autem voluptas."}
        */

        return api.post(`${this.path}solicitacao-atividade-externa`, obj);
    }
    show = (id) => {
        return api.get(`${this.path}solicitacao-atividade-externa/${id}`);
    }
    update = (obj, id) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":9366,"terapia_id":1,"especialidade_id":1,"status_solicitacao_id":1,"user_id":768,"user_id_status":66,"objetivo":"Sit est voluptate quia. Saepe et quasi et dolor ipsa possimus architecto. Cum distinctio unde eum earum ut tenetur consequatur et. Vel ipsum natus rem qui aut quia.","local":"quia","horario":"1995-06-17","descricao":"Quas dolores est culpa eveniet consequatur quas. Earum dolorum saepe temporibus. Et et vero quas voluptas quis. Illum quo est possimus eligendi soluta voluptas et ea."}
        */

        return api.put(`${this.path}solicitacao-atividade-externa/${id}`, obj);
    }
    destroy = (id) => {
        return api.delete(`${this.path}solicitacao-atividade-externa/${id}`);
    }
}

const SolicitacaoAtvExternaInstance = new SolicitacaoAtividadeExternaService();
export default SolicitacaoAtvExternaInstance;