import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from "../../Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../context/snackbar';
import api from '../../../services/api';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};

const TiposAvaliacaoModal = ({ modalIsOpen, setIsOpen, tipoAvaliacao = null, callback }, ref) => {
    let subtitle;
    // eslint-disable-next-line no-unused-vars
    const description = 'Modal de configuração de tipos de avaliação.';

    const [tipoAvaliacaoName, setTipoAvaliacaoName] = useState(null);
    const [loading, setloading] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (tipoAvaliacao && tipoAvaliacao.id) {
            setTipoAvaliacaoName(tipoAvaliacao.tipo_avaliacao);
        } else {
            setTipoAvaliacaoName(null)
        }
    }, [tipoAvaliacao]);

    async function handleSubmit(formData) {
        const data = {
            tipo_avaliacao: formData.tipo_avaliacao,
        }
        if (tipoAvaliacao && tipoAvaliacao.id)
            return update(data, tipoAvaliacao.id)
        return store(data);
    }

    const store = async (data) => {
        try {
            setloading(true);
            await api.post(`api/tipos-avaliacao`, data);
            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
            setTipoAvaliacaoName(null);
            setIsOpen(false);
            callback();
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao cadastrar!', '', 'warning');
        } finally {
            setloading(false);
        }
    }

    const update = async (data, tipoId) => {
        try {
            setloading(true);
            await api.put(`api/tipos-avaliacao`, data, tipoId);
            setTipoAvaliacaoName(null);
            snackbar.displayMessage("Cadastro alterado com sucesso!", "success");
        } catch (error) {
            Swal.fire('Houve um erro ao cadastrar!', '', 'error');
            console.error(error)
        } finally {
            setloading(false);
            setIsOpen(false);
            callback();
        }
    }


    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                transparent
            >
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold", }} ref={(_subtitle) => (subtitle = _subtitle)}>Tipo de Avaliação</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }} >
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: "flex", flexDirection: "column", width: 450 }}>
                                <Input
                                    name={'tipo_avaliacao'}
                                    title={'Nome:'}
                                    defaultValue={tipoAvaliacaoName}
                                    required={'required'}
                                />
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : tipoAvaliacao === null ? "Adicionar" : "Alterar"
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(TiposAvaliacaoModal);
