import { useState, useEffect } from 'react';
import { useAuth } from './auth';

const usePermissoes = () => {
    const usuario = useAuth();
    const [permissoes, setPermissoes] = useState([]);

    useEffect(() => {
        const coletaPermissoes = () => {
            let permissoes = usuario.user.lista_de_permissoes[0].permissions;
            let localPermissoes = {};
            for (let index = 0; index < permissoes.length; index++) {
                const permissao = permissoes[index];
                const nomePermissao = permissao.name.split('.');
                let local = window.location.pathname.split('/')[3];
                if (local === nomePermissao[0] || local === nomePermissao[0] + 's') {
                    switch (nomePermissao.length) {
                        case 2:
                            if (!localPermissoes[nomePermissao[1]]) {
                                localPermissoes[nomePermissao[1]] = {};
                            }
                            localPermissoes[nomePermissao[1]] = true;
                            break;
                        case 3:
                            if (!localPermissoes[nomePermissao[1]]) {
                                localPermissoes[nomePermissao[1]] = {};
                            }
                            if (!localPermissoes[nomePermissao[1]][nomePermissao[2]]) {
                                localPermissoes[nomePermissao[1]][nomePermissao[2]] = {};
                            }
                            localPermissoes[nomePermissao[1]][nomePermissao[2]] = true;
                            break;
                        case 4:
                            if (!localPermissoes[nomePermissao[1]]) {
                                localPermissoes[nomePermissao[1]] = {};
                            }
                            if (!localPermissoes[nomePermissao[1]][nomePermissao[2]]) {
                                localPermissoes[nomePermissao[1]][nomePermissao[2]] = {};
                            }
                            if (!localPermissoes[nomePermissao[1]][nomePermissao[2]][nomePermissao[3]]) {
                                localPermissoes[nomePermissao[1]][nomePermissao[2]][nomePermissao[3]] = {};
                            }
                            localPermissoes[nomePermissao[1]][nomePermissao[2]][nomePermissao[3]] = true;
                            break;
                        default:
                            console.error('Número de níveis não suportado:', nomePermissao.length);
                    }
                }
            }
            setPermissoes(localPermissoes);
        };


        coletaPermissoes();
    }, [usuario]);

    return permissoes;
};

export default usePermissoes;
