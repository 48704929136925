import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import Input from '../../Login/components/Input';
import { Form } from '@unform/web';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import { InputArquivo, RichTextEditor } from '../../../components/Input';
import { PickList } from 'primereact/picklist';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import { CgClose } from 'react-icons/cg';
import { BsEye } from 'react-icons/bs';

const ModalRecados = forwardRef(({ modalData }, ref) => {
    //#region VARIAVEIS
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setloading] = useState(false);
    const [recado, setRecado] = useState({
        conteudo: '',
        valido_ate: '',
    });

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "auto",
            maxHeight: "70vh",
            width: '60vw',
            marginRight: '-50%',
            paddingTop: '0px',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };

    const formRef = useRef(null);
    const ButtonRef = useRef(null);

    const [picklistSource, setPickListSource] = useState([]);
    const [usersSelectedList, setUserSelectedList] = useState([]);
    const [sourceFilter, setSourceFilter] = useState(null);
    const [tipoUser, setTipoFilter] = useState(null);
    const [tiposList, setTiposList] = useState([]);

    const [imagem, setImagem] = useState(null);
    const [path, setPath] = useState([]);
    //#endregion

    //#region COMPONENTES
    const itemTemplate = (item) => {
        return (
            <tr className="d-flex flex-col">
                <div className='flex-nowrap flex-row d-flex'>{item.label.toUpperCase()}</div>
            </tr>
        );
    };

    const filterByNameAndType = (item) => {
        const nameMatch = sourceFilter ? item.label.toLowerCase().includes(sourceFilter.toLowerCase()) : true;
        const onTheTarget = usersSelectedList.findIndex(user => user.id === item.id) === -1;
        return nameMatch && onTheTarget;
    };

    const TipoFilter = () => {
        return (
            <Select
                type="text"
                id="tipoUser"
                name="tipoUser"
                options={tiposList}
                defaultValue={tipoUser}
                onChange={(e) => setTipoFilter(e)}
                placeholder="Filtrar por Tipo"
                label="Filtrar por Tipo de Usuário"
                isClearable
            />
        );
    };
    //#endregion

    //#region FUNÇÕES
    const previewNotificacao = () => {
        const { conteudo, tipo } = recado;
        const titulo = document.getElementById('titulo').value;
        let option = {
            title: titulo,
            html: conteudo,
            icon: tipo,
            confirmButtonText: 'Visualizar',
            denyButtonText: 'Excluir',
            showConfirmButton: true,
            showDenyButton: true,
        };
        Swal.fire(option);
    }
    //#endregion

    //#region FUNÇÕES DE MODAL
    const openModal = useCallback(() => {
        setloading(true);
        setTipoFilter(null);
        setSourceFilter(null);
        api.get('api/group')
            .then(({ data }) => {
                let pickList = [], tiposList = [];
                data.forEach((user) => {
                    pickList.push({ id: user.id, label: user.name });
                    if (tiposList.findIndex(ti => ti.label === user.tipo_user) === -1) {
                        tiposList.push({ value: user.tipo_user, label: user.tipo_user });
                    }
                });
                setPickListSource(pickList.sort((a, b) => {
                    if (a.tipo === b.tipo) {
                        return a.label.localeCompare(b.label);
                    } else {
                        return a.tipo.localeCompare(b.tipo);
                    }
                }));
                setTiposList(tiposList);
                setloading(false)
            })
            .catch(error => console.error(error))
            .finally(() => {
                setIsOpen(true);
            });
    }, []);

    const closeModal = () => {
        setPath([]);
        setImagem(null);
        setUserSelectedList([]);
        setRecado({
            conteudo: '',
            valido_ate: '',
        });
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => {
        return {
            openModal,
            notificacao: recado
        };
    }, [openModal, recado]);
    //#endregion

    //#region HANDLES
    const handleChange = (e, outsideName) => {
        let name;
        let value;
        if (outsideName) {
            name = outsideName;
            value = e;
        } else {
            if (e.target) {
                name = e.target.name;
                value = e.target.value;
            } else {
                name = e.name;
                value = e.value;
            }
        }
        setRecado((prevNotificacao) => ({
            ...prevNotificacao,
            [name]: value
        }));
    };

    const handleSubmit = (data) => {
        try {
            const dados = {
                ...recado,
                arquivo: imagem,
                valido_ate: data.valido_ate
            };

            const ids = usersSelectedList.map(group => group.id);

            const obj = {
                dados: dados,
                ids: ids
            }
            api.post('api/recado/by/groups', obj)
                .then((response) => {
                    if (response.status === 200) {
                        Swal.fire('Recados enviados com sucesso!', '', 'success', 2000);
                    }
                })
                .catch((error) => {
                    console.error(error.message);
                    Swal.fire('Houve um problema a enviar os recados...', '', 'error');
                });
        } catch (error) {
            console.error(error);
        } finally {
            closeModal();
        }
    };
    const handleDeleteUpload = () => {
        setImagem(null);
        path.splice(-1);
    };
    const handleChangeArquivo = (e) => {
        if (imagem === null) {
            var arquivo = document.getElementById("arquivo")
            var file = e.target.files[0].name;
            var size = e.target.files[0].size;
            var extPermitidas = ['jpg', 'png', 'jpeg', 'gif', 'bmp', 'svg'];
            if (extPermitidas.find((ext) => { return file.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!)', '(Permitidas: jpg, png, jpeg, gif, bmp, svg', 'warning');
                arquivo.value = "";
            } else {
                if (size < 40000000) { //40MB         
                    setImagem(e.target.files[0]);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = "";
                }
                e.preventDefault();
            }
        } else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'warning');
        }
    }
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (modalData) {
            setRecado(modalData);
        }
    }, [modalData]);
    //#endregion

    //#region HTML
    return (
        <div>
            <Button className='btn-padrao' ref={ButtonRef} onClick={openModal}>Enviar Recados</Button>
            <ReactModal style={customStyles} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnEsc shouldCloseOnOverlayClick appElement={document.getElementById('root')}>
                <div className='div-titulo-fixo d-flex w-100' style={{ justifyContent: 'space-between', position: 'sticky' }}>
                    <label>
                        <BsEye color='purple' className='pointer' size={25} onClick={previewNotificacao}></BsEye>
                    </label>
                    <label>{modalData ? 'Editar' : 'Adicionar'} Recados</label>
                    <label>
                        <CgClose color='red' className='pointer' size={25} onClick={closeModal} />
                    </label>
                </div>
                {loading
                    ? <ClipLoader size={30} className='m-auto'></ClipLoader>
                    : <Form ref={formRef} onSubmit={handleSubmit} className='row'>
                        <label className='col-6 mt-4'>
                            Validade:
                            <Input type="datetime-local" id="valido_ate" name="valido_ate" defaultValue={recado.valido_ate} onChange={handleChange} />
                        </label>
                        <label className='col-6 mt-4'>
                            Imagem:
                            <InputArquivo id="arquivo" name="arquivo" title='Anexar Imagem' path={path} onChange={handleChangeArquivo} handleDeleteUpload={handleDeleteUpload} />
                        </label>
                        <label className='col-12 mb-3'>
                            Conteúdo:
                            <RichTextEditor id={'conteudo'} name={'conteudo'} defaultValue={recado.conteudo} onChange={(e) => handleChange(e, 'conteudo')} />
                        </label>
                        <label className='col-12 mt-5'>Usuários a Serem Notificados
                            <PickList
                                dataKey="id" // Chave única usada para identificar os itens na picklist
                                onChange={(e) => { // Função chamada quando os itens selecionados mudam
                                    setUserSelectedList(e.target);
                                }}
                                itemTemplate={itemTemplate} // Template usado para renderizar cada item na picklist
                                filter filterBy='label' // Propriedade de filtro, filtrando pelos rótulos dos itens
                                breakpoint="1280px" // Ponto de quebra para o layout responsivo da picklist

                                /* CONFIGURAÇÔES DA ORIGEM */
                                source={picklistSource.filter(filterByNameAndType)} // Itens disponíveis na picklist, filtrados por nome e tipo
                                sourceHeader="Disponíveis" // Título para a lista de itens disponíveis
                                sourceStyle={{ height: '24rem' }} // Estilo CSS para a lista de itens disponíveis
                                sourceFilterPlaceholder="Pesquisar por Nome" // Placeholder do campo de filtro para a lista de itens disponíveis
                                showSourceControls={false}
                                sourceFilterTemplate={() => ( // Template usado para renderizar o campo de filtro na lista de itens disponíveis
                                    <div className="row">
                                        <label className='label-padrao col-12'>Filtrar por Nome:
                                            <Input
                                                type="text"
                                                value={sourceFilter}
                                                name={'name'}
                                                onChange={(e) => setSourceFilter(e.target.value)}
                                                placeholder="Pesquisar por Nome"
                                            />
                                        </label>
                                    </div>
                                )}

                                /* CONFIGURAÇÔES DO DESTINO */
                                target={usersSelectedList} // Itens selecionados pelo usuário
                                targetHeader="Selecionados" // Título para a lista de itens selecionados
                                targetStyle={{ height: '24rem' }} // Estilo CSS para a lista de itens selecionados
                                targetFilterPlaceholder="Pesquisar por Nome" // Placeholder do campo de filtro para a lista de itens selecionados
                                showTargetControls={false}
                                targetFilterTemplate={() => ( // Template usado para renderizar o campo de filtro na lista de itens selecionados
                                    <div className="row">
                                        <label className='label-padrao col-12'>Filtrar por Nome:
                                            <Input
                                                type="text"
                                                name={'name'}
                                                value={sourceFilter} // Acho que aqui deve ser 'targetFilter' em vez de 'sourceFilter'
                                                onChange={(e) => setSourceFilter(e.target.value)} // Deve ser 'setTargetFilter' em vez de 'setSourceFilter'
                                                placeholder="Pesquisar por Nome"
                                            />
                                        </label>
                                    </div>
                                )}
                            />
                        </label>
                        <div className='d-flex justify-between'>
                            <Button className='w-300px' onClick={closeModal}>Cancelar</Button>
                            <Button className='w-300px' type="submit">Salvar</Button>
                        </div>
                    </Form>
                }
            </ReactModal>
        </div>
    );
    //#endregion
});

export default ModalRecados;
