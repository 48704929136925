//#region IMPORTAÇÕES
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/RecepcaoDashboard/Dashboard';
import PerfilPaciente from './pages/RecepcaoDashboard/Pacientes/Perfil';
import NovoTerapeuta from './pages/RecepcaoDashboard/Terapeutas/Novo';
import GrupoPermissao from './pages/AdminDashboard/Configuracoes/Permissoes';
import PlanoDeSaude from './pages/RecepcaoDashboard/PlanoDeSaude';
import TerapeutaDashboard from './pages/TerapeutaDashboard/Dashboard';
import StatusDiario from './pages/TerapeutaDashboard/StatusDiario';
import Atendimentos from './pages/AdminDashboard/Atendimentos';
import Funcionarios from './pages/AdminDashboard/Funcionarios';
import ListagemPacientes from './pages/AdminDashboard/Pacientes';
import TiposAtendimento from './pages/AdminDashboard/Configuracoes/TiposAtendimento';
import Terapias from './pages/AdminDashboard/Configuracoes/Terapias';
import Especialidade from './pages/AdminDashboard/Configuracoes/Especialidade';
import Modalidade from './pages/AdminDashboard/Configuracoes/Modalidade';
import Sala from './pages/AdminDashboard/Configuracoes/Sala';
import Estacao from './pages/AdminDashboard/Configuracoes/Estacao';
import Documentos from './pages/AdminDashboard/Configuracoes/Documentos';
import AreaCompartilhada from './pages/AdminDashboard/Configuracoes/AreaCompartilhada';
import Status from './pages/AdminDashboard/Configuracoes/Status';
import AgendaSemanal from './pages/RecepcaoDashboard/Agenda/Terapeuta';
import AgendaAreaCompartilhada from './pages/RecepcaoDashboard/Agenda/AreaCompartilhada';
import NovoPaciente from './pages/RecepcaoDashboard/Pacientes/Novo';
import Guias from './pages/RecepcaoDashboard/Guias';
import ListaEspera from './pages/RecepcaoDashboard/Agenda/ListaDeEspera';
import AgendaPacienteId from './pages/RecepcaoDashboard/Agenda/PacienteId';
import AgendaTerapeutaId from './pages/RecepcaoDashboard/Agenda/TerapeutaId';
import AgendaTerapeutas from './pages/RecepcaoDashboard/Agenda/Terapeutas';
import ControleDiario from './pages/RecepcaoDashboard/Agenda/ControleDiario';
import DocumentosPendentes from './pages/DocumentosPendentes';
import React, { useEffect } from 'react';
import DocumentoAssinatura from './pages/DocumentoAssinatura';
import DocumentosAssinados from './pages/RecepcaoDashboard/DocumentosAssinados';
import PlanosTerapeuticosPendentes from './pages/PlanosTerapeuticosPendentes';
import Prontuarios from './pages/RecepcaoDashboard/Pacientes/Prontuarios';
import EvolucaoDiaria from './pages/TerapeutaDashboard/Pendencias';
import PendenciasDiarias from './pages/TerapeutaDashboard/Pendencias/PendenciasDiarias';
import PendenciasAtrasadas from './pages/TerapeutaDashboard/Pendencias/PendenciasAtrasadas';
import TerapeutaPacientes from './pages/TerapeutaDashboard/TerapeutaPacientes';
import TerapeutaProntuarios from './pages/TerapeutaDashboard/TerapeutaPacientes/Prontuarios';
import Solicitacoes from './pages/TerapeutaDashboard/Solicitacoes';
import TerapeutaAgenda from './pages/TerapeutaDashboard/Agenda';
import TerapeutaRelatorios from './pages/TerapeutaDashboard/Relatorios';
import HeaderPadrao from './layouts/HeaderPadrao';
import MinhasSolicitacoes from './pages/TerapeutaDashboard/Solicitacoes/MinhasSolicitacoes';
import ResponsavelDashboard from './pages/ResponsavelDashboard/Dashboard';
import TerapeutasResponsavel from './pages/ResponsavelDashboard/Terapeutas';
import CurriculoTerapeuta from './pages/ResponsavelDashboard/Terapeutas/CurriculoTerapeuta';
import PacientesResponsavel from './pages/ResponsavelDashboard/Pacientes';
import DocumentosPendentesResponsavel from './pages/ResponsavelDashboard/Dashboard/DocumentosPendentes';
import AgendaPacienteResponsavel from './pages/ResponsavelDashboard/AgendaPaciente';
import CoordenacaoDashboard from './pages/CoordenacaoDashboard/Dashboard';
import CadastroPerfilTerapeuta from './pages/CoordenacaoDashboard/Terapeutas/Novo';
import PerfilPacienteResponsavel from './pages/ResponsavelDashboard/Pacientes/Perfil';
import PerfilPacienteAdmin from './pages/AdminDashboard/Pacientes/Perfil';
import ListagemTerapeutas from './pages/AdminDashboard/Terapeutas';
import PlanoDeSaudeAdmin from './pages/AdminDashboard/PlanoDeSaude';
import ConfiguracoesAdmin from './pages/AdminDashboard/Configuracoes';
import RelatóriosEstrategicos from './pages/AdminDashboard/RelatoriosEstrategicos';
import AuditorDashboard from './pages/AuditorDashboard';
import PerfilPacienteAuditor from './pages/AuditorDashboard/Paciente/Perfil';
import AvaliacaoDeMateriais from './pages/CoordenacaoDashboard/SolicitacaoDeMateriais';
import AgendaTerapeutaAdmin from './pages/AdminDashboard/Terapeutas/AgendaTerapeutaAdmin';
import DocumentosTerapeuticos from './pages/ResponsavelDashboard/Dashboard/DocumentosTerapeuticos';
import FaltasPacientes from './pages/CoordenacaoDashboard/FaltasPacientes';
import AreaCompartilhadaTerapeuta from './pages/TerapeutaDashboard/Agenda/AreasCompartilhadas';
import TiposAvaliacao from './pages/AdminDashboard/Configuracoes/TiposAvaliacao';
import RelatóriosCoordenacao from './pages/CoordenacaoDashboard/RelatoriosCoordenacao';
import TerapeutaBloqueado from './pages/TerapeutaDashboard/BloqueioAcesso';
import Pesquisar from './pages/TerapeutaDashboard/TerapeutaPacientes/Pesquisar';
import Diagnosticos from './pages/AdminDashboard/Configuracoes/Diagnosticos';
import ListagemDeGlosas from './pages/AdminDashboard/Terapeutas/Glosas';
import HorariosAtendimento from './pages/AdminDashboard/Configuracoes/HorariosAtendimento';
import Testes from './pages/Testes';
import Testes2 from './pages/Testes2';
import AgendamentoDashboard from './pages/AgendamentoDashboard';
import PerfilUsuario from './pages/Login/Perfil';
import DashboardPadrao from './pages/DashboardPadrao';
import Notificacoes from './pages/Notificacoes';
import Recados from './pages/Recados';
import Uploads from './pages/Uploads';
import ActiveConnections from './pages/WebSocketManager';
import { GraficosPacientes } from './pages/Relatorios/Graficos/Pacientes';
import PadraoDocumento from './pages/Parametros/PadraoDocumento';
import Postman from './pages/Desenvolvedor/Postman';
import DashboardDesenvolvedor from './pages/Desenvolvedor';
import AdministradorRoutes from './module-routes/administrador-route';
import RecepcaoRoutes from './module-routes/recepcao-route';
import DesenvolvedorRoutes from './module-routes/desenvolvedor-route';
import TerapeutaRoutes from './module-routes/terapeuta-route';
import AgendamentoRoutes from './module-routes/agendamento-route';
import CadastrosRoutes from './module-routes/cadastros-route';
import FinanceiroRoutes from './module-routes/financeiro-route';
import ResponsavelRoutes from './module-routes/responsavel-route';
import CoordenacaoRoutes from './module-routes/coordenacao-route';
import AuditorRoutes from './module-routes/auditor-route';
import { useAuth } from 'context/auth';
import RedirecionaParaDashboard from 'pages/RedirecionaParaDashboard';
import GuiasRoutes from './module-routes/guias-route';
import OfflineIndexDev from 'pages/Desenvolvedor/offline-index-dev';
//#endregion
function RoutesApp() {
	//#region VARIAVEIS
	/* TODO: Readicionar verificação de documentos pendentes, quando pronto */
	//const { verificaExisteDocumentoPendente } = useAuth();
	const location = useLocation();
	const { user } = useAuth();
	//#endregion
	//#region FUNCOES
	/**
	 * Creates routes based on the user's type.
	 * 
	 * @returns {Array} The array of routes based on the user's type.
	 */
	const criaRotas = () => {
		try {
			if (user) {
				switch (user?.tipo_user?.toLowerCase()) {
					case 'administrador':
						return AdministradorRoutes.map((route, index) => React.cloneElement(route, { key: 'administracao-route' + index }));
					case 'recepcao':
						return RecepcaoRoutes.map((route, index) => React.cloneElement(route, { key: 'recepcao-route' + index }));
					case 'agendamento':
						return AgendamentoRoutes.map((route, index) => React.cloneElement(route, { key: 'agendamento-route' + index }))
					case 'guias':
						return GuiasRoutes.map((route, index) => React.cloneElement(route, { key: 'guias-route' + index }))
					case 'terapeuta':
						return TerapeutaRoutes.map((route, index) => React.cloneElement(route, { key: 'terapeuta-route' + index }))
					case 'cadastros':
						return CadastrosRoutes.map((route, index) => React.cloneElement(route, { key: 'cadastros-route' + index }))
					case 'financeiro':
						return FinanceiroRoutes.map((route, index) => React.cloneElement(route, { key: 'financeiro-route' + index }))
					case 'responsavel':
						return ResponsavelRoutes.map((route, index) => React.cloneElement(route, { key: 'responsavel-route' + index }))
					case 'coordenacao':
						return CoordenacaoRoutes.map((route, index) => React.cloneElement(route, { key: 'coordenacao-route' + index }))
					case 'auditor':
						return AuditorRoutes.map((route, index) => React.cloneElement(route, { key: 'auditor-route' + index }))
					case 'desenvolvedor':
						return DesenvolvedorRoutes.map((route, index) => React.cloneElement(route, { key: 'desenvolvedor-route' + index }))
					default:
						return ''
				}
			}
		} catch (error) {
			console.error('Erro ao criar rotas: ', error);
		}
	}
	//#endregion
	//#region USE EFFECT
	useEffect(() => {
		//verificaExisteDocumentoPendente();
	}, [location]);
	//#endregion
	//#region HTML
	return (
		<Routes>
			<Route path='/' element={<Login />} />
			<Route path='/offline-testes' element={<OfflineIndexDev />} />
			{user
				? <>
					<Route path='/dashboard/perfil' element={<HeaderPadrao><PerfilUsuario /></HeaderPadrao>} />
					<Route path='/dashboard/' element={<RedirecionaParaDashboard user={user} />} />
					{criaRotas()}
				</>
				: <></>
			}
		</Routes>
	);
	//#endregion
}

export default RoutesApp;