import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';

import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import TableTipoDemandas from "./TableTipoDemandas";
import Admin from "../../../../layouts/Admin";
import api from "services/api";
import TipoDemandaModal from "./TipoDemandaModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function TipoDemandas() {

    const [loading, setLoading] = useState(true);
    const [color] = useState("#8ed4ae");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [tipoDemandas, setTipoDemandas] = useState([]);
    const [selectedTipoDemanda, setSelectedTipoDemanda] = useState(null);

    const loadTipoDemandas = () => {
        api.get('api/tipo-demanda')
            .then(({ data }) => {
                data.sort((a, b) => b.prioridade - a.prioridade); // Ordena por prioridade
                setTipoDemandas(data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        loadTipoDemandas();
    }, []);

    const showEditModal = (tipoDemandaData) => {
        setSelectedTipoDemanda(tipoDemandaData);
        setIsOpen(true);
    };

    return (
        <Admin>
            <Container>
                <div>
                    <button
                        onClick={() => {
                            setSelectedTipoDemanda(null);
                            setIsOpen(true);
                        }} className="btn-padrao">
                        <AiFillFileAdd style={{ marginRight: 5 }} />
                        Novo Tipo de Demanda
                    </button>
                </div>
                <div>
                    {
                        (tipoDemandas.length !== 0 && loading === false)
                            ? <TableTipoDemandas callback={loadTipoDemandas} showEditModal={showEditModal} source={tipoDemandas} />
                            : <ClipLoader color={color} loading={loading} css={override} size={50} />
                    }
                </div>
                <TipoDemandaModal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    callback={loadTipoDemandas}
                    tipoDemanda={selectedTipoDemanda}
                />
            </Container>
        </Admin>
    );
}