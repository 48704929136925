import {createContext, useContext, useState} from 'react';

const AtendimentoReposicaoContext = createContext({});

const AtendimentoReposicaoProvider = ({children}) => {
    const [atendimentoReposicao, setAtendimentoReposicao] = useState(null);

    const setAtendimentoReposicaoState = (atendimentoReposicao) => {
        setAtendimentoReposicao(atendimentoReposicao);
    }

    return (
        <AtendimentoReposicaoContext.Provider value={{atendimentoReposicao, setAtendimentoReposicaoState}}>
            {children}
        </AtendimentoReposicaoContext.Provider>
    );
}

const useAtendimentoReposicao = () => {
    const context = useContext(AtendimentoReposicaoContext);

    if (!context) {
        throw new Error('useAtendimentoReposicao must be used within AtendimentoReposicaoProvider');
    }

    return context;
}

export {useAtendimentoReposicao, AtendimentoReposicaoProvider};
