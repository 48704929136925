import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;

.table{
    table {
        border: 2px solid forestgreen;
        height: 200px;
        width: '100%'
      }
        
      th {
        border-bottom: 1px solid black;
      }
        
      td {
        text-align: center;
      }
}`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;
export const Button = styled.button`
    background-color: '#7340BD',
    color: '#FFFFFF',
    font-family: "Nunito, sans-serif",
    border: "none",
    padding: "2px 22px",
    border-radius: 8px,
    margin-right: 12px,
    height: 34px,
    width: 160px
`;

export const MainDivStyle = {
    display: "flex", 
    justifyContent: "space-between",
    backgroundColor: "white",
    marginBottom: 20,
    borderBottomLeftRadius: 10, 
    borderBottomRightRadius: 10,
    alignItems: "center", 
    height: 58,
    padding: "0px 20px",
    boxShadow: "0 0 10px 0px silver",
}

export const leftContentDivStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: 20,
    paddingBottom: 20,
    width: "100%",
}

export const subHeader = {
    textAlign: "center",
    color: "#7441BF",
    fontWeight: "bold",
    fontSize: 20,
    paddingTop: 20, 
    paddingBottom: 10,
    display: "flex", 
    flexDirection: "column",
}

export const contatoHeader = {
    color: "#7441BF",
    fontWeight: "bold",
    fontSize: 20,
    paddingTop: 20, 
    paddingBottom: 10,
    display: "flex", 
    flexDirection: "column",
}

export const buttonDiplomas = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "2px 22px",
    borderRadius: 8,
    marginRight: 12,
    height: 34,
    width: "40%"
}

export const buttonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 20,
    paddingBottom: 20,
}

export const AddButtonStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "2px 22px",
    borderRadius: 8,
    marginRight: 12,
    height: 34,
}

export const buttonBack = {
    backgroundColor: "#7441BF",
    color: "#FFFFFF",
    border: "none",
    borderRadius: 8,
    height: 34,
    marginLeft: 5,
    marginBottom: 20,
    marginTop: 20,
}

export const buttonDefault = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "2px 22px",
    borderRadius: 8,
    marginRight: 12,
    marginLeft: 5,
    height: 34,
    marginTop: 20
}
