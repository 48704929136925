import React from 'react';

import { Container, HashtagIcon } from './styles';

const Button = ({ title, subtitle, selected }) => {
  return (
    <Container className={selected ? 'active' : ''}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <span>{title}</span>
        <span>{subtitle}</span>
      </div>
    </Container>
  );
};

export default Button;
