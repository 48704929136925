import * as React from 'react';
import {useSortBy, useTable} from 'react-table';
import {CelulaCabecalho, LinhaTabela, LinhaTabelaVazia} from './styles';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';

export function TableDefault({ source, columns, emptyPhrase }) {
  const data = React.useMemo(() => source, [source]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
        columns,
        data,
      },
      useSortBy,
  );

  return (
    <div style={{maxHeight: '450px', height: 'auto', overflowY: 'auto'}}>
        <table {...getTableProps()} style={{ width: '100%' }} id={'table_default'}>
          <thead>
          {headerGroups.map((headerGroup, index) => (
              <tr key={'h'+index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <CelulaCabecalho {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span >
                          {column.isSorted ? (column.isSortedDesc ? <AiOutlineCaretDown style={{marginLeft: '10%'}} color='white'/> : 
                            <AiOutlineCaretUp style={{marginLeft: '10%'}} color='white'/>) : ""}
                      </span>
                    </CelulaCabecalho>
                ))}
              </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
              prepareRow(row);
              return (
                  <tr key={'b'+index} {...row.getRowProps()}>
                    {row.cells.map((cell, index) =>
                      <LinhaTabela key={'cell-index-'+index} style={{ height: '14px', textAlign: 'center' }}>{cell.render('Cell')}</LinhaTabela>
                      )
                    }
                  </tr>
              );
            })}
          </tbody>
        </table>
        {
          rows.length < 1 ?
            <LinhaTabelaVazia>
              {emptyPhrase ?? 'Nada encontrado.'}
            </LinhaTabelaVazia> : ""
        }
    </div>  
  );
};
