import React, { useMemo } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import TerapiumService from "../../../../../services/TerapiumService";
import { TableDefaultPagination } from "../../../../../components/TableDefaultPagination";

export default function TableTerapias({ source, callback, showEditModal }) {

	const handleDelete = (id) => {
		Swal.fire({
			title: 'Deseja realmente remover este registro ?',
			showDenyButton: true,
			confirmButtonText: 'Remover',
			denyButtonText: `Cancelar`,
		}).then((result) => {
			if (result.isConfirmed) {
				TerapiumService.destroy(id)
					.then(() => {
						Swal.fire('Registro removido com sucesso!', '', 'success');
						callback();
					})
					.catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
			}
		})
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Terapia:',
				accessor: 'terapia',
				Cell: ({ cell }) => (
					<span style={{ color: cell.row.original.cor_titulo }}>
						{cell.row.original.terapia}
					</span>
				)
			},
			{
				Header: "Ações",
				accessor: "acoes",
				Cell: ({ cell }) => (
					(<div style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						backgroundColor: "transparent"
					}}>
						<AiOutlineDelete
							style={{ cursor: 'pointer' }}
							color="#7340BD" size={22}
							onClick={
								() => handleDelete(cell.row.original.id)
							}
						/>
						<AiOutlineEdit
							color="#7340BD"
							style={{ cursor: 'pointer' }}
							size={22}
							onClick={
								() => showEditModal && showEditModal(cell.row.original)
							}
						/>
					</div>
					)
				)
			}
		],
		[]
	)

	return (
		<TableDefaultPagination columns={columns} source={source} />
	)
}
