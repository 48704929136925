import React, { useMemo } from "react";
import { Tooltip } from 'react-tooltip';
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import Swal from "sweetalert2";
import { TableDefault } from "../../../../components/TableDefault";
import { useSnackbar } from "../../../../context/snackbar";
import api from "../../../../services/api";
import moment from 'moment';

export function TableAssinaturasTerapeuta({ source, terapeutaId, handleOpen, setAssinaturaTerapeuta }) {

    const data = React.useMemo(() => source, [source]);
    const snackbar = useSnackbar();

    const removeAsinatura = (data) => {
        terapeutaId = data.terapeuta_id;
        Swal.fire({
            title: `Deseja realmente remover este registro<b title='${data.id}'>?</b>`,
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let assId = data.id;
                let assinaturasLocal = [...source];
                if (assId === null || assId === undefined) {
                    const index = assinaturasLocal.indexOf(data);
                    assinaturasLocal.splice(index, 1);
                    setAssinaturaTerapeuta(assinaturasLocal);
                    snackbar.displayMessage("Cadastro removido com sucesso!", "success");
                } else {
                    await api.delete('api/assinatura-terapeuta/' + data.id + '?id=' + data.id).then(() => {
                        snackbar.displayMessage("Cadastro removido com sucesso!", "success");
                        const index = assinaturasLocal.indexOf(data);
                        assinaturasLocal.splice(index, 1);
                        setAssinaturaTerapeuta(assinaturasLocal);
                    }).catch((e) => {
                        console.error(e);
                        snackbar.displayMessage("Houve um problema ao remover o registro.", "error");
                    });
                }
            }
        })
            .catch(() => {
                Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
            });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Data do Cadastro',
                accessor: 'created_at', // accessor is the "key" in the data
                Cell: (cell) => ((moment(cell.row.original).format('DD/MM/YYYY')))
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => (
                    (<div className="d-flex align-items-center justify-center m-1">
                        <AiOutlineEye className="icone-padrao" data-tooltip-id='imagemTooltip' />
                        <Tooltip id='imagemTooltip' type='dark' effect='float' className="max-w-300px d-flex flex-col" style={{ borderRadius: 10 }}>
                            <span>Assinatura</span>
                            <img style={{ borderRadius: 10, maxHeight: 200, maxWidth: 250, backgroundColor: "transparent" }} src={cell.row.original.caminho_assinatura_full} alt=""></img>
                        </Tooltip>
                        <AiOutlineDelete className="icone-padrao" onClick={e => removeAsinatura(cell.row.original)} />
                    </div>)
                )
            }
        ],
        []
    )

    return (
        <TableDefault columns={columns} source={data} />
    );
}
