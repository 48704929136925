import React from 'react';
import { Container, ContainerSub, GridHeader, Header } from "./styles";
import moment from 'moment';

const OfflineIndexDev = () => {
    //#region VARIAVEIS
    const user = {
        name: 'Teste',
        email: 'teste',
        password: 'teste'
    };
    //#endregion
    //#region HTML
    return (
        <Container>
            <ContainerSub id='containerSub'>
                <GridHeader id="header">
                    <Header>
                        <label>Bem Vindo {user?.name && user.name}!</label>
                        <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                    </Header>
                </GridHeader>
            </ContainerSub>
        </Container>
    );
    //#endregion
}
export default OfflineIndexDev;
