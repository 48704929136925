import React, { useCallback, useState, useEffect, useRef, } from "react";
import { Container, PesquisarPacienteStyle } from './styles';
import { TableListaDeEspera } from './TableListaEspera';
import api from "../../../../services/api";
import PacienteListaEsperaModal from "../../../../components/Modais/NovoPacienteListaEspera";
import ClipLoader from "react-spinners/ClipLoader";
import { override } from "./TableListaEspera/styles";
import { AiFillCaretDown } from "react-icons/ai";
import { FaBroom } from "react-icons/fa";
import useTipoDemandaData from "context/tipo-demanda";
import ReactModal from "react-modal";
import ModalTitulo from "components/ModalTitulo";
import { CgClose } from "react-icons/cg";
import { ModalNovoPaciente } from "components/Modais/NovoPacienteListaEspera/styles";

export default function ListaEspera() {
    //#region VARIAVEIS
    const [isModalNovoPacienteVisible, setIsModalNovoPacienteVisible] = useState(false);
    const [loadingDadosPacientes, setLoadingDadosPacientes] = useState(false);
    const [listaEspera, setListaEspera] = useState([]);
    const [listaEsperaOriginal, setListaEsperaOriginal] = useState([]);
    const [color] = useState("#8ed4ae");
    const [modalInfo, setModalInfo] = useState(null);
    const [readOnly, setReadOnly] = useState(false);
    const [comentario, setComentario] = useState('');
    const [modalComentarioOpen, setModalComentarioOpen] = useState(false);
    /* Filtro */
    const [terapias, setTerapias] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const { tipoDemanda, tipoDemandaOptions, fetchTipoDemanda, fetchTipoDemandaOptions } = useTipoDemandaData();
    const [expand, setExpand] = useState(false);
    const [statusSelecionados, setStatusSelecionados] = useState([]);
    const [terapiasSelecionados, setTerapiasSelecionados] = useState([]);
    const [especialidadesSelecionados, setEspecialidadesSelecionados] = useState([]);
    const [tiposDemandaSelecionados, setTiposDemandaSelecionados] = useState([]);
    const [filterNome, setFilterNome] = useState("");
    const [filtroWidth, setFiltroWidth] = useState();
    const filtroDivRef = useRef();
    //#endregion

    //#region FUNCOES
    const getListaEspera = async () => {
        api.get(`api/lista-espera?with=paciente,tipo_demanda,lista_espera_demandas.especialidade,lista_espera_demandas.terapium,lista_espera_demandas.plano_saude,lista_espera_historicos,lista_espera_responsavels`)
            .then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                setListaEsperaOriginal(data);
                setListaEspera(data);
            })
        setLoadingDadosPacientes(false);
    }
    //#region FILTRO
    /**
     * Filtra uma lista de espera baseada em critérios específicos de terapias, especialidades, status e nome.
     *
     * @param {Array} terapias - Lista de IDs de terapias a serem filtradas.
     * @param {Array} especialidades - Lista de IDs de especialidades a serem filtradas.
     * @param {Array} status - Lista de status a serem filtrados.
     * @param {string} nome - Nome ou parte do nome do paciente a ser filtrado.
     * 
     * O algoritmo filtra uma lista de espera original, `listaEsperaOriginal`, e cria uma nova lista contendo apenas os
     * registros que correspondem a todos os critérios fornecidos. A função realiza as seguintes verificações para cada
     * registro:
     * - Se o nome ou parte do nome do paciente (ou nome de um campo alternativo) coincide com o valor fornecido em `nome`.
     * - Se o registro contém pelo menos uma das especialidades fornecidas.
     * - Se o registro contém pelo menos uma das terapias fornecidas.
     * - Se o status do registro coincide com algum dos status fornecidos.
     * 
     * Após as verificações, apenas os registros que atendem a todos os critérios (terapia, especialidade, status e nome)
     * são adicionados à nova lista, `newLista`. Finalmente, a função atualiza a lista de espera com a nova lista filtrada
     * usando a função `setListaEspera`.
     */
    const filtrar = (terapias, especialidades, status, nome, tipo_demanda) => {
        let localListaEspera = listaEsperaOriginal;
        let newLista = [];
        localListaEspera.forEach((row) => {
            let t = false;
            let e = false;
            let s = false;
            let n = false;
            let td = false;
            if (nome) {
                if (row.nome) {
                    if (row.nome.toLowerCase().includes(nome.toLowerCase())) {
                        n = true;
                    }
                } else {
                    if (row.paciente.nome.toLowerCase().includes(nome.toLowerCase())) {
                        n = true;
                    }
                }
            } else {
                n = true;
            }
            if (especialidades.length === 0 && terapias.length === 0) {
                e = true;
                t = true;
            } else {
                row.lista_espera_demandas.forEach((led) => {
                    if (especialidades.length === 0) {
                        e = true;
                    } else {
                        if (especialidades.findIndex((h) => h === led.especialidade_id) >= 0) {
                            e = true;
                        }
                    }
                    if (terapias.length === 0) {
                        t = true;
                    } else {
                        if (terapias.findIndex((h) => h === led.terapia_id) >= 0) {
                            t = true;
                        }
                    }
                });
            }
            if (status.length > 0) {
                if (status.findIndex((h) => h.toLowerCase() === row.status_nome.toLowerCase()) >= 0) {
                    s = true;
                }
            } else {
                s = true;
            }
            if (tipo_demanda.length > 0) {
                if (tipo_demanda.findIndex((h) => h === row.tipo_demanda_id) >= 0) {
                    td = true;
                }
            } else {
                td = true;
            }
            if ((t === true && e === true && s === true && n === true && td)) {
                newLista.push(row);
            }
        });
        setListaEspera(newLista);
    }

    const getTerapias = () => {
        api.get(`api/terapia`).then(({ data }) => {
            setTerapias(data);
        });
    };

    const getEspecialidades = () => {
        api.get(`api/especialidade`).then(({ data }) => {
            setEspecialidades(data);
        });
    };
    /* FILTRO HANDLES */
    const handleSelecionaStatus = async (status, e) => {
        if (e.target.checked) {
            let localStatus = statusSelecionados;
            localStatus.push(status);
            setStatusSelecionados(localStatus);
            filtrar(terapiasSelecionados, especialidadesSelecionados, localStatus, filterNome, tiposDemandaSelecionados);
        } else {
            let localStatus = statusSelecionados;
            let newStatus = [];
            localStatus.filter((e) => e !== status);
            localStatus.forEach((e) => {
                if (e !== status) {
                    newStatus.push(e);
                }
            });
            setStatusSelecionados(newStatus);
            filtrar(terapiasSelecionados, especialidadesSelecionados, newStatus, filterNome, tiposDemandaSelecionados);
        }
    }

    const handleSelecionaTerapia = async (e, id) => {
        if (e.target.checked) {
            let localTerapias = terapiasSelecionados;
            localTerapias.push(id);
            setTerapiasSelecionados(localTerapias);
            filtrar(localTerapias, especialidadesSelecionados, statusSelecionados, filterNome, tiposDemandaSelecionados);
        } else {
            let localTerapias = terapiasSelecionados;
            let newTerapias = [];
            localTerapias.forEach((e) => {
                if (Number(e) !== Number(id)) {
                    newTerapias.push(e);
                }
            });
            setTerapiasSelecionados(newTerapias);
            filtrar(newTerapias, especialidadesSelecionados, statusSelecionados, filterNome, tiposDemandaSelecionados);
        }
    }

    const handleSelecionaEspecialidade = async (e, id) => {
        if (e.target.checked) {
            let localEspecialidade = especialidadesSelecionados;
            localEspecialidade.push(id);
            setEspecialidadesSelecionados(localEspecialidade);
            filtrar(terapiasSelecionados, localEspecialidade, statusSelecionados, filterNome, tiposDemandaSelecionados);
        } else {
            let localEspecialidade = especialidadesSelecionados;
            let newEspecialidades = [];
            localEspecialidade.forEach((e) => {
                if (Number(e) !== Number(id)) {
                    newEspecialidades.push(e);
                }
            });
            setEspecialidadesSelecionados(newEspecialidades);
            filtrar(terapiasSelecionados, newEspecialidades, statusSelecionados, filterNome, tiposDemandaSelecionados);
        }
    }

    const handleSelecionaTipoDemanda = async (e, id) => {
        if (e.target.checked) {
            let localTipoDemanda = tiposDemandaSelecionados;
            localTipoDemanda.push(id);
            setTiposDemandaSelecionados(localTipoDemanda);
            filtrar(terapiasSelecionados, especialidadesSelecionados, statusSelecionados, filterNome, localTipoDemanda);
        } else {
            let localTipoDemanda = especialidadesSelecionados;
            let newTiposDemanda = [];
            localTipoDemanda.forEach((e) => {
                if (Number(e) !== Number(id)) {
                    newTiposDemanda.push(e);
                }
            });
            setTiposDemandaSelecionados(newTiposDemanda);
            filtrar(terapiasSelecionados, especialidadesSelecionados, statusSelecionados, filterNome, newTiposDemanda);
        }
    }

    const handleLimpaFiltros = () => {
        let status = document.getElementsByName('status');
        let terapias = document.getElementsByName('terapias');
        let especialidades = document.getElementsByName('especialidades');
        let tipo_demanda = document.getElementsByName('tipo_demanda');
        status.forEach((e) => {
            e.checked = false;
        });
        terapias.forEach((e) => {
            e.checked = false;
        });
        especialidades.forEach((e) => {
            e.checked = false;
        });
        tipo_demanda.forEach((e) => {
            e.checked = false;
        });
        setTerapiasSelecionados([]);
        setEspecialidadesSelecionados([]);
        setStatusSelecionados([]);
        setTiposDemandaSelecionados([]);
        setFilterNome('');
        filtrar([], [], [], '');
    }

    const handleChangeNome = (data) => {
        setFilterNome(data);
        filtrar(terapiasSelecionados, especialidadesSelecionados, statusSelecionados, data);
    }
    const fechaFiltro = (event) => {
        if (event === null) {
            setExpand(false);
        } else {
            if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                setExpand(false);
            }
        }
    }

    const expandFiltro = () => {
        setExpand(!expand);
    }
    //#endregion

    const handleCloseModal = () => {
        setIsModalNovoPacienteVisible(false);
        getListaEspera();
    }

    const handleOpenModalNovoPaciente = () => {
        setModalInfo("");
        setIsModalNovoPacienteVisible(true);
    }

    const handleEditModal = useCallback(() => {
        setIsModalNovoPacienteVisible(true);
    }, []);
    //#endregion

    useEffect(() => {
        if (filtroDivRef.current) {
            setFiltroWidth(filtroDivRef.current.clientWidth - 10);
        }
    }, [filtroDivRef]);
    useEffect(() => {
        setLoadingDadosPacientes(true);
        getListaEspera();
        fetchTipoDemanda();
        fetchTipoDemandaOptions();
        getEspecialidades();
        getTerapias();
        document.addEventListener('mousedown', fechaFiltro);
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
            }
        });
        return () => {
            document.removeEventListener('mousedown', fechaFiltro);
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                }
            });
        };
    }, []);

    return (

        <Container>
            <div className="row mb-4 mt-4 pe-3 ps-3">
                <div className="div-filter-style col-12" style={{ width: '100%' }} ref={filtroDivRef}>
                    <div className="col-12 row" onClick={() => expandFiltro()}>
                        <div className="col-10 d-flex justify-content-start">
                            <p id="blank" style={{ marginBottom: -30 }}>{expand ? 'Fechar' : 'Expandir'} Filtro</p>
                        </div>
                        <div className="col-2 pointer d-flex align-center justify-content-end">
                            {(statusSelecionados?.length > 0 || filterNome || especialidadesSelecionados?.length > 0 || terapiasSelecionados?.length > 0) &&
                                <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros() }} title='Conteúdo filtrado...' />
                            }
                            <AiFillCaretDown color="white" size={22} />
                        </div>
                    </div>
                    <div className={expand ? "div-expand-over row" : 'some'} style={{ marginTop: '5px', marginLeft: '-12px', borderTopRightRadius: '0px', width: filtroWidth }}>
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <label className="mb-1 w-100 font-bold" style={{ borderBottom: '0.5px solid white' }}>Terapias:</label>
                                <div className="d-flex flex-wrap">
                                    {terapias?.map((terapia, index) => (
                                        <div key={index}>
                                            <div style={{ display: "flex", }}>
                                                <div>
                                                    <input onChange={e => handleSelecionaTerapia(e, terapia.id)} type="checkbox" id={terapia.terapia} name="terapia[]" value={terapia.id} />
                                                </div>
                                                <div>
                                                    <label style={{ flexDirection: "column", margin: 5, }} htmlFor={terapia.terapia}>
                                                        {terapia.terapia}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-12">
                                <label className="mb-1 w-100 font-bold" style={{ borderBottom: '0.5px solid white' }}>Especialidades:</label>
                                <div className="d-flex flex-wrap align-items-start">
                                    {especialidades?.map((especialidade, index) => (
                                        <div key={index} className="d-flex">
                                            <div>
                                                <input onChange={(e) => handleSelecionaEspecialidade(e, especialidade.id)} type="checkbox" id={especialidade.especialidade} name="especialidade[]" value={especialidade.id} />
                                            </div>
                                            <div>
                                                <label htmlFor={especialidade.especialidade} style={{ flexDirection: "column", margin: 5 }}>
                                                    {especialidade.especialidade}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-12">
                                <label className="mb-1 w-100 font-bold" style={{ borderBottom: '0.5px solid white' }}>Tipo de Demanda:</label>
                                <div style={{ display: "flex", flexWrap: "wrap", maxHeight: '40vh', overflow: 'auto' }}>
                                    {tipoDemanda?.map((tipoDemanda, index) => (
                                        <div key={index} style={{ display: "flex" }}>
                                            <div>
                                                <input onChange={(e) => handleSelecionaTipoDemanda(e, tipoDemanda.id)} type="checkbox" id={tipoDemanda.tipo_demanda} name="tipo_demanda[]" value={tipoDemanda.id} />
                                            </div>
                                            <div>
                                                <label htmlFor={tipoDemanda.tipo_demanda} style={{ flexDirection: "column", margin: 5 }}>
                                                    {tipoDemanda.tipo_demanda}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-lg-2 col-sm-12">
                                <label className="mb-1 w-100 font-bold" style={{ borderBottom: '0.5px solid white' }}>Status:</label>
                                <div className="d-flex flex-wrap" style={{ maxHeight: '40vh', overflow: 'auto' }}>
                                    <div className="p-0" style={{ display: "flex" }}>
                                        <div className="p-0">
                                            <input onChange={e => handleSelecionaStatus('Aguardando', e)} type="checkbox" id={'status_ativo'} name="status[]" style={{ flexDirection: "column" }} value={'Ativo'} />
                                        </div>
                                        <div className="p-0">
                                            <span htmlFor={'status_ativo'} className="p-0" style={{ flexDirection: "column", margin: 5 }}>
                                                Aguardando
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <input onClick={e => handleSelecionaStatus('Aprovado', e)} type="checkbox" id={'status_inativo'} name="status[]" style={{ flexDirection: "column" }} value={'Inativo'} />
                                        </div>
                                        <div>
                                            <span htmlFor={'status_inativo'} style={{ flexDirection: "column", margin: 5 }}>
                                                Aprovado
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <input onClick={e => handleSelecionaStatus('Desistênte', e)} type="checkbox" id={'status_inativo'} name="status[]" style={{ flexDirection: "column" }} value={'Inativo'} />
                                        </div>
                                        <div>
                                            <span htmlFor={'status_inativo'} style={{ flexDirection: "column", margin: 5 }}>
                                                Desistênte
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Digitar nome:</label>
                                <input style={PesquisarPacienteStyle} placeholder={"Pesquisar Paciente por Nome"} value={filterNome || ''} onChange={(e) => { handleChangeNome(e.target.value) }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {
                    (listaEspera.length !== 0 && loadingDadosPacientes === false)
                        ? <TableListaDeEspera setComentario={setComentario} setModalComentarioOpen={setModalComentarioOpen} source={listaEspera} callback={() => { getListaEspera(); }} openModal={handleEditModal} setModalInfo={setModalInfo} modalInfo={modalInfo} setReadOnly={setReadOnly} />
                        : <ClipLoader color={color} loading={loadingDadosPacientes} css={override} size={50} />
                }
            </div>
            <div className="d-flex justify-content-center w-100per mt-20px">
                <div className="">
                    <button className="btn-padrao" onClick={handleOpenModalNovoPaciente}>
                        Adicionar nova Espera(Demanda/Paciente)
                    </button>
                </div>
            </div>
            {modalComentarioOpen &&
                <ModalNovoPaciente id="modal-comentario-espera" onClick={(e) => e.target.id === 'modal-comentario-espera' && setModalComentarioOpen(false)}>
                    <div className="div-modal w-30per pt-0">
                        <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                            <div className="container-fluid d-flex align-items-center justify-content-between">
                                <div></div>
                                <ModalTitulo>Comentarios</ModalTitulo>
                                <label className='btn-outline pointer text-right' onClick={() => setModalComentarioOpen(false)}>
                                    <CgClose size={22} color='red' />
                                </label>
                            </div>
                        </nav>
                        <br></br>
                        <p className="text-justify p-2">{comentario}</p>
                    </div>
                </ModalNovoPaciente>}
            {isModalNovoPacienteVisible
                && <PacienteListaEsperaModal modalInfo={modalInfo} tipoDemandaOptions={tipoDemandaOptions} callback={() => { getListaEspera(); setModalInfo(null) }} onClose={() => handleCloseModal()} readOnly={readOnly} />
            }
        </Container>

    )
}