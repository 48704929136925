import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const LabelFilter = {
    color: "#7441BF", 
    fontWeight: "bold", 
    fontSize: "18px", 
    marginBottom: 30,
    marginTop: -10,
}

export const LabelDefault = {
    marginRight: 5, 
    fontWeight: "bold",
}

export const LabelPaciente = {
    marginRight: 5, 
    fontWeight: "bold",
    marginTop: 10
}

export const LabelPlanoSaude = {
    marginRight: 70, 
    fontWeight: "bold", 
    marginTop: 10
}

export const LabelTerapeuta = {
    marginRight: 5, 
    fontWeight: "bold",
    marginTop: 20
}

export const DivDisplay = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
}

export const DivContentDisplay = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    width: "100%", 
    marginTop: 30,
}

export const FilterStyle = {
    backgroundColor: "#7441BF",
    fontFamily: "Nunito, sans-serif",
    color: "#FFFFFF",
    padding: 16,
    marginBottom: 20,
    borderRadius: 10,
    width: "75%"
}

export const OptionsStyle = {
    width: "25%", 
    borderRadius: 8, 
    height: 36, 
    boxShadow: "0 0 10px 0px silver", 
    border: "none",
    marginRight: 50,
    paddingLeft: 10
}

export const InputStyle = {
    width: "25%", 
    borderRadius: 8, 
    height: 36, 
    boxShadow: "0 0 10px 0px silver", 
    border: "none",
    padding: 12, 
    marginRight: 50,
}

export const DateStyle = {
    borderRadius: 8, 
    height: 40, 
    //boxShadow: "0 0 10px 0px silver", 
    border: "none",
    margin: '2px',
    paddingBottom: '2px',
    paddingTop: '2px',
    paddingLeft: '10px',
    paddingRight: '10px',
    visibility: 'visible',
    color: 'hsl(0, 0%, 20%)',
    webkitFlex: '1 1 auto',
    msFlex: '1 1 auto',
    flex: '1 1 auto',
}

export const SelectStyle = {
    height: 10,
    margin: "auto",
    width: "25%", 
    paddingRight: 10,
}

export const TerapeutaStyle = {
    display: "flex",
    alignContent: "left",
    justifyContent: "left",
    alignItems: "left",
}

export const PacienteStyle = {
    width: "28%",
    borderRadius: 8, 
    height: 36, 
    border: "none",
    padding: 12, 
    marginRight: 57,
}