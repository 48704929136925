import api from "./api";
import moment from "moment";

class NotificacaoService {
    static index = (id = null, modo_envio = null) => {
        let query = 'api/notificacao';
        if (id !== null || modo_envio !== null) {
            query += '?';
        }
        if (id !== null) {
            query += `&filter[user_id]=` + id;
        }
        if (modo_envio !== null) {
            query += '&filter[modo_envio]=' + modo_envio;
        }
        return api.get(query);
    }

    static userIndex = (id = null, modo_envio = null) => {
        let query = 'api/notificacao/get/notificacoes';
        if (id !== null || modo_envio !== null) {
            query += '?';
        }
        if (id !== null) {
            query += `&filter[user_id]=` + id;
        }
        if (modo_envio !== null) {
            query += '&filter[modo_envio]=' + modo_envio;
        }
        return api.get(query);
    }

    static store = (obj) => {
        return api.post(`api/notificacao`, obj);
    }

    static show = (id) => {
        return api.get(`api/notificacao/${id}?&id=${id}`);
    }

    static update = (obj, id) => {
        return api.put(`api/notificacao/${id}?&id=${id}`, obj);
    }

    static delete = (id) => {
        return api.delete(`api/notificacao/${id}?&id=${id}`);
    }

    static visualiza = (id) => {
        return api.put(`api/notificacao/${id}?&id=${id}`, { visualizado: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
    }
}

export default NotificacaoService;
