import api from './api';

class AtendimentoAlteracaoService {
	static index = (params) => {
		return api.get(`api/atendimento-alteracao?${params}`);
	}
	static store = (obj) => {

		return api.post(`api/atendimento-alteracao`, obj);
	}
	static show = (id) => {
		return api.get(`api/atendimento-alteracao/${id}`);
	}
	static update = (obj, id) => {

		return api.put(`api/atendimento-alteracao/${id}`, obj);
	}
	static destroy = (id) => {
		return api.delete(`api/atendimento-alteracao/${id}`);
	}

	static getCorStatusSolicitacao = (solicitacoesArray) => {

		if (typeof solicitacoesArray === 'undefined') {
			return;
		}
		//sem alteração
		if (solicitacoesArray.length === 0) {
			return '#BCBCBC';
		}
		for (let index = 0; index < solicitacoesArray.length; index++) {
			const element = solicitacoesArray[index];
			switch (element.status) {
				//PENDENTE
				case "Aguardando":
					return '#F5D70A';/* AMARELO */
				//ALTERADO RECEPÇÃO
				case "Aprovado":
					return '#35D058';/* VERDE */
				//REPROVADA
				case "Reprovado":
					return '#EC4040';/* VERMELHO */
				//SEM ALTERAÇÃO
				default:
					return '#BCBCBC';/* CINZA */
			}
		}
		return '#BCBCBC';/* CINZA */
	}

	static getCorStatus = (alteracoesArray) => {
		if (typeof alteracoesArray === 'undefined') {
			return '#BCBCBC';
		}
		//sem alteração
		if (!alteracoesArray) {
			return '#BCBCBC';
		}
		const ultimaAlteracao = alteracoesArray;
		switch (ultimaAlteracao.status) {
			//PENDENTE
			case "1":
				return '#F5D70A';/* AMARELO */
			//ALTERADO RECEPÇÃO
			case "2":
				return '#35D058';/* VERDE */
			//REPROVADA
			case "3":
				return '#EC4040';/* VERMELHO */
			//APROVADA
			case "4":
				return '#40C5EC';/* AZUL CLARO */
			//SEM ALTERAÇÃO
			default:
				return '#BCBCBC';/* CINZA */
		}
	}

	static isAlteracaoPendente = (alteracoesArray) => {
		if (typeof alteracoesArray === 'undefined' || alteracoesArray.length === 0) {
			return false;
		}
		const alteracao = alteracoesArray[alteracoesArray.length - 1];
		return alteracao.status === '1';
	}

	static isAlteracaoEditavel = (alteracoesArray) => {
		if (typeof alteracoesArray === 'undefined' || alteracoesArray.length === 0) {
			return true;
		}
		const alteracao = alteracoesArray[alteracoesArray.length - 1];
		return alteracao.status === '3';
	}

	static isUltimaAprovada(alteracoesArray) {
		if (alteracoesArray === null || typeof alteracoesArray === 'undefined' || alteracoesArray.length === 0) {
			return false;
		}
		const alteracao = alteracoesArray[alteracoesArray.length - 1];
		return alteracao.status === '4' || alteracao.status === '2';
	}

	static getUltimaAlteracao(alteracoesArray) {
		if (typeof alteracoesArray === 'undefined' || alteracoesArray.length === 0) {
			return;
		}
		return alteracoesArray[alteracoesArray.length - 1];
	}
}

export default AtendimentoAlteracaoService;
