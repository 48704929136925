import React, {useMemo} from 'react';

import {AiOutlineDownload, AiOutlineFileText} from 'react-icons/ai';
import {TableDefault} from '../../../../components/TableDefault';


export function TableCursos({source}) {
 
  const data = React.useMemo(() => source, [source]);

  const columns = useMemo(
      () => [
        {
          Header: 'Data',
          accessor: 'data', // accessor is the "key" in the data
        },
        {
          Header: 'Nome',
          accessor: 'nome',
        },
        {
          Header: 'Aprovado (Por)',
          accessor: 'aprovado_por',
        },
        {
          Header: 'Ações',
          accessor: 'name',
          Cell: ({ cell }) => (
              (<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <AiOutlineDownload color='#7340BD' size={22} />
                  </div>
              )
          ),
        },
      ],
      [],
  );

  return (
      <TableDefault columns={columns} source={data} />
  );
}
