import React, { useEffect, useRef, useState } from "react";
import { CirculoStyle, Detalhamento, QuadradoStyle } from "./styles";
import ContentLoader from 'react-content-loader';
import Dropdown from "components/Dropdown";

/**
 * Class representing a Mostrador.
 * @class
 */
class Mostrador {
    //#region VARIÁVEIS
    listaCores = [
        { nome: 'vermelho', cor: '#FF0000' },
        { nome: 'verde', cor: '#00FF00' },
        { nome: 'azul', cor: '#0000FF' },
        { nome: 'amarelo', cor: '#FFFF00' },
        { nome: 'roxo', cor: '#800080' },
        { nome: 'laranja', cor: '#FFA500' },
        { nome: 'rosa', cor: '#FFC0CB' },
        { nome: 'preto', cor: '#000000' },
        { nome: 'turquesa_medio', cor: '#27DABE' },
        { nome: 'laranja_abobora', cor: '#F8A314' },
        { nome: 'rosa_brilhante', cor: '#F76397' },
        { nome: 'ceu_azul_claro', cor: '#3EBFE5' }
    ];
    fontColor = '#000000';
    //#endregion

    //#region METODOS
    /**
     * Calculates the contrast color based on a given hexadecimal color.
     *
     * @param {string} hexcor - The hexadecimal color code.
     * @returns {string} The contrast color ('black' or 'white').
     */
    getCorDeContraste(hexcor) {
        hexcor = hexcor.replace("#", "");
        var r = parseInt(hexcor.substr(0, 2), 16);
        var g = parseInt(hexcor.substr(2, 2), 16);
        var b = parseInt(hexcor.substr(4, 2), 16);
        var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 180) ? 'black' : 'white';
    }

    /**
     * Creates a title by splitting the given string and rendering each word on a new line.
     * 
     * @param {string} titulo - The title string to be split.
     * @returns {JSX.Element[]} - An array of JSX elements representing the split words with line breaks.
     */
    criaTitulo(titulo) {
        return titulo.split(' ').map((word, i) => (
            <React.Fragment key={i}>
                {word}
                <br />
            </React.Fragment>
        ))
    }
    /**
     * Checks if a given value is a hexadecimal color code.
     *
     * @param {string} valor - The value to be checked.
     * @returns {boolean} - Returns true if the value is a valid hexadecimal color code, otherwise returns false.
     */
    isHexadecimal(valor) {
        const regex = /^#([0-9A-F]{3}){1,2}$/i;
        return regex.test(valor);
    }
    //#endregion

    //#region CONSTRUTOR
    /**
     * Constructor for creating colorful display components.
     *
     * @param {string} titulo - The title of the component.
     * @param {string} cor - The color of the component. Can be a color name or a hexadecimal value.
     * @param {number} valor - The value of the component.
     * @param {string} geometria - The shape of the component. Can be "circulo" or "quadrado".
     * @param {string} tamanho - The size of the component in pixels.
     * @param {Array} detalhamento - Additional details for the component.
     * @throws {Error} If titulo is not a string.
     * @throws {Error} If valor is not an integer.
     * @throws {Error} If geometria is not "circulo" or "quadrado".
     */
    constructor(titulo, cor, valor, geometria, tamanho, detalhamento = []) {
        //#region VALIDAÇÕES
        if (typeof titulo !== 'string') {
            throw new Error('Titulo deve ser uma string');
        }
        if (!Number.isInteger(parseInt(valor))) {
            throw new Error('Valor deve ser um inteiro');
        }
        if (typeof geometria !== 'string' || (geometria !== 'circulo' && geometria !== 'quadrado')) {
            throw new Error('Geometria deve ser "circulo" ou "quadrado"');
        }
        if (typeof tamanho !== 'string' || !tamanho.match(/^\d+px$/)) {
            tamanho = '110px';
        }
        const corEncontrada = this.listaCores.find(item => item.nome === cor);
        if (corEncontrada) {
            this.cor = corEncontrada.cor;
        } else {
            if (this.isHexadecimal(cor)) {
                this.cor = cor;
            } else {
                this.cor = '#FF0000';
            }
        }
        //#endregion
        //#region DEFINE VALORES
        this.titulo = this.criaTitulo(titulo);

        this.valor = valor;

        this.geometria = geometria;

        this.tamanho = tamanho;

        this.fontColor = this.getCorDeContraste(this.cor);

        this.detalhamento = detalhamento;
        //#endregion
    }
    //#endregion
}
/**
 * Componente que renderiza mostradores coloridos com base em suas propriedades.
 * 
 * @param {object} props - As propriedades do componente.
 * @param {string} [props.titulo='Mostradores'] - O título do componente.
 * @param {Array} props.mostradores - Lista de mostradores a serem exibidos.
 * @param {string} [props.className] - Classes CSS adicionais para o componente.
 * @param {string} [props.id='mostradores'] - ID do componente.
 * @param {string} [props.name='mostradores'] - Nome do componente.
 * @param {object} [props.style] - Estilos CSS adicionais para o componente.
 * @param {boolean} props.loading - Indica se o componente está carregando.
 * 
 * @return {JSX.Element} O componente MostradoresColoridos.
 * @param {...object} [props.rest] - Propriedades adicionais passadas ao componente.
 */
function MostradoresColoridos({ titulo = 'Mostradores', mostradores, className, id = 'mostradores', name = 'mostradores', style, loading, ...rest }) {
    //#region FUNCOES
    const [mostradorSelecionado, setMostradorSelecionado] = useState(null);
    const mostradoresRef = useRef();

    /**
     * Handles the click event on a mostrador.
     * 
     * @param {string} mostrador - The mostrador identifier.
     */
    const handleMostradorClick = (mostrador) => {
        if (mostrador) {
            const dom = document.getElementById('mostrador-detalhamento-' + mostrador);

            setMostradorSelecionado(mostrador === mostradorSelecionado ? null : mostrador);
        } else {
            setMostradorSelecionado(null);
        }
    };
    /**
     * Handles the closing of the component if the user clicks outside of it.
     * @param {Event} e - The event object.
     */
    const handleFechaSeNaoClique = (e) => {
        if (mostradoresRef.current && !mostradoresRef.current.contains(e.target)) {
            setMostradorSelecionado(null);
        }
    };
    //#endregion

    //#region USE EFFECT
    useEffect(() => {
        document.addEventListener('mousedown', handleFechaSeNaoClique);

        return () => {
            document.removeEventListener('mousedown', handleFechaSeNaoClique);
        }
    }, []);
    //#endregion

    //#region RETURN
    return loading
        ? <LoadingMostradores titulo={titulo} className={className} id={id} name={name} style={style} {...rest} />
        : (!mostradores || mostradores.length === 0)
            ? 'Sem dados para Mostradores!'
            : <div className={className + " w-100 card"} id={id} name={name} style={style} {...rest}>
                <div className="card-body">
                    <h5 className="card-titulo font-roxo font-bold font-18px text-center">{titulo}</h5>
                    <div className="card-text d-flex justify-content-center align-items-start">
                        <div ref={mostradoresRef} className="d-flex justify-content-between align-items-start w-40vw flex-wrap">
                            {(mostradores && mostradores.length > 0) && mostradores.map((mostrador, index) => {
                                let styles;
                                switch (mostrador.geometria) {
                                    case ('circulo'):
                                        styles = CirculoStyle;
                                        break;
                                    case 'quadrado':
                                        styles = QuadradoStyle;
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <div key={index} className="d-grid position-relative mx-2" style={{ position: 'relative' }}>
                                        <Dropdown
                                            button={
                                                <>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center p-0 unradius-hover-bl"
                                                        style={{
                                                            ...styles,
                                                            margin: '0 auto 0 auto',
                                                            width: mostrador.tamanho,
                                                            height: mostrador.tamanho,
                                                            backgroundColor: mostrador.cor,
                                                            color: mostrador.fontColor,
                                                            cursor: mostrador.detalhamento?.length > 0 ? 'pointer' : 'default'
                                                        }}
                                                    >
                                                        <div className="m-0 p-0 unselect">
                                                            {mostrador.geometria === 'quadrado' &&
                                                                <span className="ml-2 text-center" style={{ wordBreak: 'break-word', fontSize: '13px' }}>
                                                                    {mostrador.titulo}
                                                                </span>
                                                            }
                                                            <span className="font-24px">{mostrador.valor}</span>
                                                        </div>
                                                    </div>
                                                    {mostrador.geometria !== 'quadrado' &&
                                                        <span className="ml-2 text-center unselect" style={{ wordBreak: 'break-word' }}>
                                                            {mostrador.titulo}
                                                        </span>
                                                    }
                                                </>
                                            }
                                            content={
                                                (mostrador.detalhamento?.length > 0) &&
                                                <Detalhamento id={'mostrador-detalhamento-' + index} style={{
                                                    backgroundColor: mostrador.cor,
                                                    color: mostrador.fontColor
                                                }}>
                                                    <ul>
                                                        {mostrador.detalhamento.map((detalhe, i) => {
                                                            return (
                                                                <li key={i + 'detalhe'}>
                                                                    <b>{detalhe.nome}:</b> <span style={{ fontSize: 18 }}>{detalhe.valor}</span>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </Detalhamento>
                                            }
                                            setMostrando={setMostradorSelecionado}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        ;
    //#endregion
}

/**
 * LoadingMostradores component.
 * 
 * @component
 * @param {string} titulo - The title of the component.
 * @param {object} style - The inline style of the component.
 * @param {string} id - The id attribute of the component.
 * @param {string} name - The name attribute of the component.
 * @param {string} className - The class name of the component.
 * @param {...any} rest - Additional props for the component.
 * @returns {JSX.Element} The LoadingMostradores component.
 */
const LoadingMostradores = ({ titulo, style, id, name, className, ...rest }) => (
    <div className={className + " w-100 card"} id={id} name={name} style={style} {...rest}>
        <div className="card-body">
            <h5 className="card-titulo font-roxo font-bold font-18px text-center">{titulo}</h5>
            <div className="card-text d-flex justify-content-center align-items-start">
                <div className="d-flex justify-content-between align-items-start w-40per">
                    <div className="d-grid text-center">
                        <ContentLoader
                            speed={2}
                            width={110}
                            height={110}
                            viewBox="0 0 110 110"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            style={{ margin: '0 auto 0 auto' }}
                        >
                            <circle cx="40" cy="40" r="35" />
                        </ContentLoader>
                    </div>
                    <div className="d-grid text-center">
                        <ContentLoader
                            speed={2}
                            width={110}
                            height={110}
                            viewBox="0 0 110 110"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            style={{ margin: '0 auto 0 auto' }}
                        >
                            <circle cx="40" cy="40" r="35" />
                        </ContentLoader>
                    </div>
                    <div className="d-grid text-center">
                        <ContentLoader
                            speed={2}
                            width={110}
                            height={110}
                            viewBox="0 0 110 110"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            style={{ margin: '0 auto 0 auto' }}
                        >
                            <circle cx="40" cy="40" r="35" />
                        </ContentLoader>
                    </div>
                    <div className="d-grid text-center">
                        <ContentLoader
                            speed={2}
                            width={110}
                            height={110}
                            viewBox="0 0 110 110"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                            style={{ margin: '0 auto 0 auto' }}
                        >
                            <circle cx="40" cy="40" r="35" />
                        </ContentLoader>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export { Mostrador, MostradoresColoridos, LoadingMostradores as LoadingStats };
