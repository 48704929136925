import { useState, useEffect, useRef, forwardRef } from "react";
import Input from "../../../../../components/Input";
import Swal from "sweetalert2";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { ModalDiplomaStyle, Delete, UploadIcon, InputUpload, LabelUpload, Upload, Container, BackButton, } from "../styles";
import Select from "../../../../../components/Select";
import api from "../../../../../services/api";
import { Form } from "@unform/web";

function TerapiasTerapeuta({ callBack, onClose, newTerapia, terapeuta }, ref) {

    const [terapia, setTerapia] = useState([]);
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [path, setPath] = useState([]);
    const [terapiaSelectBox, setTerapiaSelectBox] = useState([]);
    const [docTerapia, setDocTerapia] = useState(null);
    const [terapia_id, setTerapia_id] = useState(null);

    const formRef = useRef(null);
    const id = 'modal-terapias-terapeuta';

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleSubmit = (data) => {
        if (terapia_id === null || data.data === null || data.registro_diploma === null || data.diploma_terapeuta === null || docTerapia === null ){
            Swal.fire('Por favor preencha todos os campos.', '', 'warning');
        } else {
            const dadosTerapia = {
                terapia_id: terapia_id.value,
                file: docTerapia,
                data: data.data,
                registro: data.registro_diploma,
                diploma: data.diploma_terapeuta,
            }
            if (terapeuta && terapeuta.id) {
                dadosTerapia.terapeuta_id = Number(terapeuta.id);
            };
            newTerapia(dadosTerapia, terapia_id.label);
            onClose();
            callBack();
        };

    };

    useEffect(() => {
        if (!terapia) return;
        const selectedTerapia = terapia.map(d => ({
            "value": d.id,
            "label": d.terapia
        }));
        setTerapiaSelectBox(selectedTerapia);
    }, [terapia])

    useEffect(() => {
        async function loadDadosIniciais() {
            const dadosTerapia = await api.get(`api/terapia`);
            setTerapia(dadosTerapia.data);

            const selectTerapia = dadosTerapia.data.map(d => ({
                "value": d.id,
                "label": d.terapia
            }));
            setTerapiaSelectBox(selectTerapia);
            setLoadingDadosIniciais(false);
        }
        loadDadosIniciais();
    }, []);

    const handleUploadDiploma = (e) => {
        if (docTerapia === null) {
            var arquivo = document.getElementById("upload-terapia");
            var name = e.target.files[0].name;
            const file = e.target.files[0];
            var size = e.target.files[0].size;
            var extPermitidas = ['jpg', 'png', 'jpeg', 'JPG', 'PNG', 'JPEG', 'PDF', 'pdf'];
            if (extPermitidas.find((ext) => { return name.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg, png,)', '', 'warning');
                arquivo.value = "";
            } else {
                if (size < 40000000) { //40MB
                    setDocTerapia(file);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = "";
                }
                e.preventDefault();
            }
        }
        else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'error');
        }
    };

    const handleDeleteUpload = () => {
        setDocTerapia(null);
        path.splice(-1);
        setLoadingDadosIniciais(true);
    };

    const functionThatSubmitsForm = () => {
        formRef.current.submitForm();
    }

    return (
        <Container className='w-100per'>
            <div className='d-flex align-items.center justify-content-between'>
                <label></label>
                <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}> Adicionar Nova Terapia </label>
                <label className='btn-fecha-modal' onClick={() => onClose()}> x </label>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit} style={{ height: "100%", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", }}>
                    <div style={{ flexDirection: "column", width: "50%", height: "auto" }}>

                        <Input  title="Diploma:" name="diploma_terapeuta"/>
                    </div>
                    <div style={{ flexDirection: "column", width: "20%", height: "auto", marginLeft: 5 }}>

                        <Input  title="Registro:" name="registro_diploma"/>
                    </div>
                    <div style={{ marginLeft: 5, flexDirection: "column", width: "20%", height: "auto" }}>
                        <Input  containerStyle={ModalDiplomaStyle} title="Data:" name="data" type="date"/>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", }}>
                    <div style={{ flexDirection: "column", width: "70%", height: "auto", }}>
                        <Select options={terapiaSelectBox} label="Terapia:" name="terapia_id" onChange={e => setTerapia_id(e)}/>
                    </div>
                    <div style={{ marginTop: 5, flexDirection: "column", marginLeft: 5, }}>
                        <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
                    </div>
                    <div style={{ flexDirection: "column" }}>
                        <label htmlFor="upload-terapia" style={LabelUpload}>Anexar Certificação
                            <input type="file" id="upload-terapia" style={InputUpload} onChange={handleUploadDiploma} />
                        </label>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {path.map((item) => {
                        return <span style={Upload}>{item}
                            <div style={{}}>
                                <span style={Delete} onClick={handleDeleteUpload}>
                                    x
                                </span>
                            </div>
                        </span>
                    })}
                </div>
                <div className='d-flex align-items.center justify-content-evenly mt-3 mb-3'>
                    <button onClick={onClose} style={BackButton}> Voltar </button>
                    <button onClick={functionThatSubmitsForm} style={BackButton}> Adicionar </button>
                </div>
            </Form>
        </Container>
    );
}

export default TerapiasTerapeuta;