import React, { useEffect, useRef, useState } from 'react';
import SubMenu from '../../../../components/SubMenu';
import api from '../../../../services/api';
import moment from 'moment';
import { VscGraphLine } from 'react-icons/vsc';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { RiFileSearchLine, RiFolderAddLine } from 'react-icons/ri';
import PacienteEvolucaoDiariaModal from '../../../../components/Modais/PacienteEvolucaoDiariaModal';
import PacienteAltasModal from '../../../../components/Modais/PacienteAltasModal';
import PacienteRelatoriosModal from '../../../../components/Modais/PacienteRelatoriosModal';
import PacienteLaudosModal from '../../../../components/Modais/PacienteLaudosModal';
import PacienteInformacoesComplementaresModal from '../../../../components/Modais/PacienteInformacoesComplementaresModal';
import PacientePlanoTerapeuticoModal from '../../../../components/Modais/Pacientes/PlanoTerapeuticoModal';

function Pesquisar() {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const [pesquisado, setPesquisado] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [modalData, setModalData] = useState([]);

    //===MODAL===\\
    const [openModalAltas, setOpenModalAltas] = useState(false);
    const [openModalRelatorios, setOpenModalRelatorios] = useState(false);
    const [openModalLaudos, setOpenModalLaudos] = useState(false);
    const [openModalEvolucao, setOpenModalEvolucao] = useState(false);
    const [openModalOutrosDocs, setOpenModalOutrosDocs] = useState(false);

    //===REFS===\\
    const pacientePlanoTerapeuticoModalRef = useRef(null);
    //#endregion

    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
    const handleOpenModalPlanoTerapeutico = (item) => {
        if (pacientePlanoTerapeuticoModalRef.current) {
            pacientePlanoTerapeuticoModalRef.current.openModal(item);
        }
    };
    //#endregion

    const handleSearch = (searchTerm) => {
        if (searchTerm === '') {
            setPesquisado(false)
            setSearchResults([]);
        } else {
            setPesquisado(true);
            const results = pacientes.filter((paciente) => {
                let nome = paciente.nome.toUpperCase();
                let searchTermUpper = searchTerm.toUpperCase();
                return nome.includes(searchTermUpper);
            });
            setSearchResults(results);
        }
    };

    useEffect(() => {
        api.get('api/paciente/lista/simples?select=id,nome,identificador,status,nascimento').then((response) => {
            setPacientes(response.data);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    return (
        <div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 div-titulo'>
                        <label>Pesquisar Pacientes</label>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-8 col-sm-12 m-auto-i d-inline-flex flex-nowrap'>
                        <input
                            id={'nome'}
                            name={'nome'}
                            className='form-control'
                            placeholder='Digite o nome do paciente...'
                            onChange={(e) => {
                                handleSearch(e.target.value)
                            }}
                        />
                    </div>
                </div>
                {pesquisado &&
                    <div className='row'>
                        <div className='col-lg-8 col-sm-12 m-auto-i'>
                            <label>Resultados da Pesquisa</label>
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className='d-flex flex-wrap justify-arround mt-3'>
                        {searchResults.length > 0
                            ? searchResults.map((item, index) => {
                                return (
                                    <div key={index} className='m-2 mx-5 bg-white' style={{ width: '366px', height: '366px', borderRadius: '10px', boxShadow: "0 0 10px 0px silver" }}>
                                        <div className='d-flex flex-column px-4'>
                                            <span className='align-self-center mt-3' style={{ color: '#4906AB', fontSize: '20px', fontWeight: '900' }}>
                                                {item.nome}
                                            </span>
                                            <span className='text-muted align-self-center mb-3' style={{ fontSize: '20px', fontWeight: '900' }}>
                                                {item.nascimento ? moment().subtract(moment(item.nascimento).format('YY'), 'years').format('YY') : '0'} anos
                                            </span>
                                        </div>
                                        <div className='d-flex flex-wrap justify-content-center'>
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalEvolucao(true))}>
                                                <div><VscGraphLine size={25} /></div>
                                                <div>Evolução</div>
                                            </button>
                                            {/* <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalRelatorios(true))}>
                                                <div><HiOutlineNewspaper size={25} /></div>
                                                <div>Relatórios</div>
                                            </button>
                                            <button className='btn-quadrado m-3' onClick={(e) => (handleOpenModalPlanoTerapeutico(item))}>
                                                <div><RiFolderAddLine size={25} /></div>
                                                <div>Plano terapêutico</div>
                                            </button>
                                            <button className='btn-quadrado m-3' onClick={() => (setModalData(item), setOpenModalLaudos(true))}>
                                                <div><RiFileSearchLine size={25} /></div>
                                                <div>Laudos</div>
                                            </button> */}
                                        </div>
                                    </div>
                                )
                            })
                            : pesquisado
                                ? searchResults.length > 0
                                    ? 'Nenhum paciente encontrado.'
                                    : ''
                                : 'Digite o nome de um paciente para pesquisar.'
                        }
                    </div>
                </div>
            </div>
            {openModalEvolucao && <PacienteEvolucaoDiariaModal onClose={() => { setOpenModalEvolucao(false); setModalData(null) }} paciente={modalData} />}
            {openModalAltas && <PacienteAltasModal onClose={() => { setOpenModalAltas(false); setModalData(null) }} paciente={modalData} />}
            {openModalRelatorios && <PacienteRelatoriosModal onClose={() => { setOpenModalRelatorios(false); setModalData(null) }} paciente={modalData} />}
            {openModalLaudos && <PacienteLaudosModal onClose={() => { setOpenModalLaudos(false); setModalData(null) }} paciente={modalData} />}
            {openModalOutrosDocs && <PacienteInformacoesComplementaresModal onClose={() => { setOpenModalOutrosDocs(false); setModalData(null) }} paciente={modalData} />}
            <PacientePlanoTerapeuticoModal ref={pacientePlanoTerapeuticoModalRef}></PacientePlanoTerapeuticoModal>
        </div >
    );
};

export default Pesquisar;