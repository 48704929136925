import React, {useState, useEffect} from 'react';
import {ModalContainer} from '../../PacienteAltasModal/styles';
import Input from '../../../Input';
import { Form } from '@unform/web';
import TextArea from '../../../TextArea';
import {BsCloudArrowUp} from 'react-icons/bs';
import moment from 'moment/moment';
import { ButtonStyle2, ButtonStyle3, Modal } from '../styles';
import api from '../../../../services/api';
import Swal from 'sweetalert2';
import { RelatorioOrientacaoService } from '../../../../services/RelatoriosService';
import { useSnackbar } from '../../../../context/snackbar';
import { useAuth } from '../../../../context/auth';
import { Delete, Upload } from '../styles';

function RelatorioSupervisao({ onClose }) {

  const [documento, setDocumento] = useState(null);
  const [path, setPath] = useState([]);

  const id = 'modal-paciente-evolucao-diaria';
  const snackbar = useSnackbar();
  const {user} = useAuth();
  let day = moment().format("YYYY-MM-DD");

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  const handleSubmit = (data) => {
    if (data.orientacao === "") {
      Swal.fire('Por favor preencha todos os campos!', '', 'warning');
    } else {
      const objSubmit = {
            data: day,
            arquivo: documento,
            orientacao: data.orientacao,
            user_id: user.id,
        };
      RelatorioOrientacaoService.store(objSubmit);
      snackbar.displayMessage("Relatório criado com sucesso!", "success");
      onClose();
    };
  }

  const handleCloseModal = () => {
    onClose();
  }
  
  const handleUpload = (e) => {
    var arquivo = document.getElementById("arquivo")
    var file = e.target.files[0].name;
    var size = e.target.files[0].size;
    var extPermitidas = ['jpg', 'png', 'jpeg','pdf',];
    if (extPermitidas.find((ext) => {return file.split('.').pop() === ext}) === undefined){
        Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg, pgn,)', '', 'warning');
        arquivo.value = "";
    } else {
        if (documento === null) {
            if(size < 40000000) { //40MB         
                setDocumento(e.target.files[0]);
                setPath([...path, e.target.value]);
            } else {           
                Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                arquivo.value = "";
            }
            e.preventDefault();
        } else {
            Swal.fire('Cadastre apenas um arquivo!', '', 'warning');
        }
        }
    }

    const handleDeleteUpload = () => {
        setDocumento(null);
        path.splice(-1);
    };

  return (
      <Modal id={id} onClick={handleOutsideClick}>
        <ModalContainer>
          <div style={{position: "relative"}}>
            <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
              right: "25px", fontSize: "25px", top: "-10px" }} onClick={() => handleCloseModal()}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Relatório de Supervisão</label>
          </div>
          <Form onSubmit={handleSubmit}>
            <div className='d-flex flex-wrap justify-content-center p-2 px-5'>
              <div className='px-1 col-12'><TextArea name="observacao" title="Observações:" /></div>
              <div className='p-1 col-3' style={{width: '26%'}}><Input  name="data" title="Data do Relatório:" type="date"
                defaultValue={day}/></div>
              <div className='px-1 col-12 d-flex align-items-center justify-content-center'>
                <label  htmlFor="anexo-btn" style={{cursor: 'pointer', color: '#6A3BAF', fontWeight: '600'}}> 
                    <input className='d-none' type="file" id='anexo-btn' onChange={e => handleUpload(e)} />
                    <BsCloudArrowUp size={30} className='me-2' />Anexar Arquivos
                </label>
                </div>
            </div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                {path.map((item) => {return <span style={ Upload }>{item}
                    <div style={{}}>
                        <span style={Delete} onClick={handleDeleteUpload}>
                            x
                        </span> 
                    </div>
                </span>})}
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <button style={ButtonStyle2} onClick={() => handleCloseModal()} >
                    Voltar
                </button>
                <button style={ButtonStyle3} type='submit'>
                    Confirmar
                </button>
              </div>
          </Form>
        </ModalContainer>
      </Modal>
  );
}

export default RelatorioSupervisao;
