import React from 'react';
import { Container, InfoPaciente, LinhaInfoPaciente, NomePaciente, PropriedadePaciente } from './styles';
import moment from 'moment';

function InfoPacienteModal({ paciente }) {

  return (
    <>
      {paciente &&
        <Container style={{ boxShadow: "0 0 10px 0px silver" }}>
          <div>
            <NomePaciente>{paciente.nome}</NomePaciente>
          </div>
          <InfoPaciente>
            <div>
              <LinhaInfoPaciente><PropriedadePaciente>Identificador:</PropriedadePaciente> {paciente.identificador}</LinhaInfoPaciente>
              <LinhaInfoPaciente><PropriedadePaciente>Idade:</PropriedadePaciente>
                {paciente.idade === null
                  ? "Erro: Sem data cadastrada"
                  : paciente.idade + " anos (" + moment(paciente.nascimento).format('DD/MM/YYYY') + ")"}</LinhaInfoPaciente>
            </div>
            <div>
              <LinhaInfoPaciente><PropriedadePaciente>Diagnóstico:</PropriedadePaciente> {paciente.diagnostico1_nome ? paciente.diagnostico1_nome : '-'}</LinhaInfoPaciente>
              <LinhaInfoPaciente><PropriedadePaciente>Plano de Saúde:</PropriedadePaciente> {typeof paciente.plano_saude_nome === 'string' ? paciente.plano_saude_nome : paciente?.plano_saude_nome}</LinhaInfoPaciente>
            </div>
          </InfoPaciente>
        </Container>
      }
    </>
  )
}

export default InfoPacienteModal;
