import api from "./api";

class AssinaturaTerapeuta {
    static index = () => {
        return api.get(`api/assinatura-terapeuta`);
    }

    static store = (obj) => {
        const form = new FormData();
        Object.keys(obj).forEach((key) => {
            if (key === 'assinatura') {
                form.append(key, obj[key], obj[key].name);
            } else {
                form.append(key, obj[key]);
            }
        });
        
        return api.post(`api/assinatura-terapeuta`, form);
    }

    static show = (id) => {
        return api.get(`api/assinatura-terapeuta/${id}`);
    }

    static update = (obj) => {
        return api.put(`api/assinatura-terapeuta/${obj.id}`, obj);
    }

    static destroy = (id, data=null) => {
        return api.delete(`api/assinatura-terapeuta/${id}`, {data});
    }

    static aprovar = (documento) => {
        return api.put(`api/assinatura-terapeuta/${documento}/aprovar`, documento);
    }
}

export default AssinaturaTerapeuta;
