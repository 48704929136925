import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import ReactModal from 'react-modal';
import TextArea from '../../../TextArea';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import api from '../../../../services/api';

const SolicitacaoModal = forwardRef((props, ref) => {
    const title = 'Solicitação de Alteração';
    const [isOpen, setIsOpen] = useState(false);
    const [atendimentoLocal, setAtendimentoLocal] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [motivo, setMotivo] = useState('');
    const [innerWidth, setInnerWidth] = useState();
    const styles = {
        content: {
            position: 'absolute',
            top: '60%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            width: innerWidth < 300 ? '70vw' : '100vw',
            height: '80vh',
        }
    };

    const handleChangeAlteracao = (e) => {
        setDescricao(e.target.value);
    }

    const handleChangeMotivo = (e) => {
        setMotivo(e.target.value);
    }

    const handleSubmit = (data, { reset }) => {
        data.atendimento_id = atendimentoLocal.id;
        data.status = 'Aguardando';
        api.post('/api/solicitacao-alteracao', data).then(() => {
            Swal.fire({
                icon: 'success',
                title: 'Solicitação enviada',
                text: 'Solicitação enviada com sucesso!'
            });
        }).catch((error) => {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Erro ao criar solicitação!',
                text: 'Erro ao criar solicitação, entre em contato com o time técnico.'
            });
        }).finally(() => {
            reset();
            closeModal();
        });
    }

    const openModal = (atendimento) => {
        setAtendimentoLocal(atendimento);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
        open: openModal,
        close: closeModal
    }));

    useEffect(() => {
        setInnerWidth(window.innerWidth);

        window.addEventListener('resize', () => {
            setInnerWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setInnerWidth(window.innerWidth);
            });
        }
    }, []);

    return (
        <ReactModal style={styles} isOpen={isOpen} onRequestClose={closeModal} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
            <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                <label></label>
                <label>{title}</label>
                <label>
                    <button className='btn-icone' onClick={closeModal}>
                        <AiOutlineClose color='red' size={22} />
                    </button>
                </label>
            </div>
            <Form className='div-conteudo' onSubmit={handleSubmit}>
                <TextArea title='Descrição da Alteração:' name='descricao' id='descricao' value={descricao} onChange={(e) => handleChangeAlteracao(e)} />
                <TextArea title='Motivo da Alteração:' name='motivo' id='motivo' value={motivo} onChange={(e) => handleChangeMotivo(e)} />
                <button type='submit' className='btn-padrao mt-20px'>Enviar Solicitação</button>
            </Form>
            {(isOpen && atendimentoLocal.solicitacao_de_alteracaos.length > 0) &&
                <div className='w-100per mt-20px'>
                    <label>Histórico de Alterações</label>
                    <table className='table-round'>
                        <thead>
                            <tr>
                                <th>Alteração</th>
                                <th>Motivo</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isOpen && atendimentoLocal.solicitacao_de_alteracaos.map((alteracao, idx) => {
                                let cor;
                                switch (alteracao.status) {
                                    case 'Aguardando':
                                        cor = '#F5D70A';
                                        break;
                                    case 'Reprovado':
                                        cor = '#EC4040';
                                        break;
                                    case 'Aprovado':
                                        cor = '#35D058';
                                        break;
                                    default:
                                        cor = '#BCBCBC';
                                        break;
                                }
                                return (
                                    <tr key={`tbody-${idx}`}>
                                        <td>{alteracao.descricao}</td>
                                        <td>{alteracao.motivo}</td>
                                        <td style={{ color: cor }}>{alteracao.status}</td>
                                    </tr>
                                );

                            })}
                        </tbody>
                    </table>
                </div>
            }
        </ReactModal>
    );
});

export default SolicitacaoModal;