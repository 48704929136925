import styled from 'styled-components';

export const DivPrincipal = styled.div`
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Nunito, sans-serif;
`;

export const ParagrafoSaudacao = styled.p`
  font-size: 2rem;
  color: #7340BD;
`;

export const ParagrafoInformativoNumeroDocumentos = styled.p`
  font-size: 1.5rem;
  color: #7340BD;
`;

export const ItemLista = styled.li`
  list-style: none;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const DivListaDocumentos = styled.div`
  margin-top: 5vh;
  font-size: 1rem;
  text-align: left;
  width: 70%;
`;

export const NomeDocumento = styled.div`
  width: 70%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 10px;
  margin: 5px;
  box-shadow: 0 0 10px 0px silver
`;

export const ButtonStyle = {
  backgroundColor: '#7340BD',
  color: '#FFFFFF',
  border: 'none',
  padding: '5px 10px',
  borderRadius: '8px',
  width: '25%',
  textAlign: 'center',
  fontWeight: 'bold',
  textDecoration: 'none',
  margin: '5px',
  cursor: 'pointer'
}