import React, {useEffect, useRef, useState} from 'react';
import {Container, Modal} from './styles';
import {Form} from '@unform/web';
import TextArea from '../../TextArea';
import {useAuth} from '../../../context/auth';
import {useSnackbar} from '../../../context/snackbar';
import Assinatura from '../../Assinatura';
import SolicitacaoMaterialService from '../../../services/SolicitacaoMaterialService';
import { BackButtonStyle, ButtonAlignStyle, ButtonStyle, CenterDivStyle, 
        ColumnDivStyle, ContainerStyle, RowDivStyle } from './styles';

export default function AvaliacaoModal({ onClose, modalData, }) {

  const [motivoRejeicao, setMotivoRejeicao] = useState();

  const { user } = useAuth();
  const snackbar = useSnackbar();
  const formRef = useRef();
  const sigCanvas = useRef({});
  const modalId = 'modal-avaliar-solicitacao';

  const handleOutsideClick = (e) => {
    if (e.target.id === modalId) onClose();
  };

  const handleChangeSolicitacao = (status) => {

    let data = { status };

    if (status === SolicitacaoMaterialService.status().APROVADO) {
      data = { ...data, };
    }

    if (status === SolicitacaoMaterialService.status().RECUSADO) {
      const recusado = motivoRejeicao;
      data = { ...data, recusado };
    }

    SolicitacaoMaterialService.update(data, modalData.id)
        .then(response => {
          snackbar.displayMessage('Solicitação de Material atualizada com sucesso', 'success');
          onClose();
        })
        .catch(response =>
            snackbar.displayMessage('Houve um erro ao atualizar a Solicitação, atualize a página e tente novamente', 'error'));
  };

  const handelChangeMotivo = (e) => {
    setMotivoRejeicao(e.target.value);
  };

  return (
      <Modal id={modalId} onClick={(e) => handleOutsideClick(e)}>
          <div style={{position: "relative"}}>
            <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
              right: "-300px", fontSize: "25px", top: "10px" }} onClick={() => onClose()}>
              x
            </label>
          </div>
        <Container style={ContainerStyle}>
          <div style={CenterDivStyle}>
            <label style={{fontWeight: "900", fontSize: 25, color: '#6A3BAF' }}>Análise de Solicitação</label>
          </div>
          <Form ref={formRef} style={{ width: '100%' }}>
            <div style={CenterDivStyle}>
              <label style={{ fontWeight: '900', fontSize: '24', marginTop: 30 }}>Documento da Solicitação</label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 0 }}>
              <div style={RowDivStyle}>
                <TextArea value={modalData.descricao ? modalData.descricao : ''} disabled={"disabled"} name='descricao'></TextArea>
              </div>
            </div>
            <div style={ColumnDivStyle}>
              <button onClick={() => handleChangeSolicitacao(SolicitacaoMaterialService.status().APROVADO)}style={BackButtonStyle}>
                Aprovar
              </button>
            </div>
            <div style={CenterDivStyle}>
              <label style={{fontWeight: "900", fontSize: 25, color: '#6A3BAF', marginTop: 30 }}>Motivo da Rejeição:</label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 0 }}>
              <div style={RowDivStyle}>
                <TextArea onChange={handelChangeMotivo} name='motivo-rejeicao'/>
              </div>
            </div>
                <div style={ButtonAlignStyle}>
                  <button onClick={() => handleChangeSolicitacao(SolicitacaoMaterialService.status().RECUSADO)} style={ButtonStyle}>
                    Recusar
                  </button>
                </div>
                <div  style={ButtonAlignStyle}>
                  <button onClick={() => onClose()} style={BackButtonStyle}>
                    Voltar
                  </button>
                </div>
          </Form>
        </Container>
      </Modal>
  );
}
