import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { AiOutlineUser, AiOutlineBell, AiFillFileAdd } from "react-icons/ai";
import { BotaoInserirRelatorio, BotaoRelatorioStyle, Container, MainDivStyle, override } from './styles';
import api from "../../../services/api";
import { BotaoAcao } from "../../RecepcaoDashboard/Agenda/ControleDiario/styles";
import ModalRelalatoriosEstrategicos from "../../../components/Modais/RelatioriosEstrategicosModal";
import moment from "moment";
import Coordenacao from "../../../layouts/Coordenacao";

export default function RelatóriosCoordenacao() {

    const navigate = useNavigate();
    const [modalRelatorios, setModalRelatorios] = useState(false);
    const [relatorios, setRelatorios] = useState([]);
    const [color, setColor] = useState("#8ed4ae");

    const [relatorioAlta, setRelatorioAlta] = useState([]);
    const [relatorioAcompanhamento, setRelatorioAcompanhamento] = useState([]);
    const [relatorioOrientacao, setRelatorioOrientacao] = useState([]);
    const [relatorioGeral, setRelatorioGeral] = useState([]);
    const [relatorioAdequacao, setRelatorioAdequacao] = useState([]);
    const [relatorioEncerramento, setRelatorioEncerramento] = useState([]);
    const [relatorioAtividade, setRelatorioAtividade] = useState([]);

    const handleOpenModalRelatorios = () => {
        setModalRelatorios(true);
    }

    const handleCloseModalRelatorios = () => {
        setModalRelatorios(false);
    }

    const getRelatórios = () => {
        let dadosRelatorios = []
        api.get(`api/relatorio/alta`).then(({ data }) => {
            let dadosAlta = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosAlta.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Alta",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioAlta(dadosAlta);
        });
        api.get(`api/relatorio/acompanhamento`).then(({ data }) => {
            let dadosAcompanhamento = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosAcompanhamento.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Acompanhamento",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioAcompanhamento(dadosAcompanhamento);
        });
        api.get(`api/relatorio/alteracao-terapia`).then(({ data }) => {
            let dadosAlteracao = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosAlteracao.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Alteração",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioAdequacao(dadosAlteracao);
        });
        api.get(`api/relatorio/atividade-externa`).then(({ data }) => {
            let dadosAtividade = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosAtividade.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Atividade",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioAtividade(dadosAtividade);
        });
        api.get(`api/relatorio/encerramento-tratamento`).then(({ data }) => {
            let dadosEncerramento = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosEncerramento.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Encerramento",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioEncerramento(dadosEncerramento);
        });
        api.get(`api/relatorio/generico`).then(({ data }) => {
            let dadosGeral = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosGeral.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Genérico",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioGeral(dadosGeral);
        });
        api.get(`api/relatorio/orientacao`).then(({ data }) => {
            let dadosOrientacao = [];
            data.sort((a, b) => {
                return new Date(b.created_at) - new Date(a.created_at);
            })
            data.forEach((item) => {
                dadosOrientacao.push({
                    id: item.id,
                    status: item.status,
                    tipo: "Relatório Orientação",
                    data: moment(item.data).format('DD/MM/YYYY')
                })
            });
            setRelatorioOrientacao(dadosOrientacao);
        });
        dadosRelatorios.push(relatorioAcompanhamento, relatorioAdequacao, relatorioAlta, relatorioAtividade, relatorioEncerramento,
            relatorioGeral, relatorioOrientacao);
        setRelatorios(dadosRelatorios);
    }

    useEffect(() => {
        getRelatórios();
    }, [])

    return (
        <Container>
            <div style={MainDivStyle}>
                <div>
                    <div style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", marginRight: 20,
                    }}>
                        <label style={{
                            fontFamily: "Nunito, sans-serif", color: "#7340BD",
                            fontWeight: "bold", cursor: "pointer"
                        }}>
                            Relatórios
                        </label>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span style={{
                    color: "#4906AB",
                    fontSize: "2.0rem",
                    fontFamily: "Nunito, sans-serif",
                    fontWeight: "800",
                    textAlign: "center",
                    marginBottom: 50,
                    marginTop: 20
                }}>
                    Relatórios
                </span>
            </div>
            <div className='d-flex flex-column align-items-center justify-content-center'>
                <div className='d-flex align-items-center justify-content-center' style={{ width: '93%' }}>
                    <div className='col-2'><span style={{ width: '200px', color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Id Relatório:</span></div>
                    <div className='col-2'><span style={{ width: '200px', color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Tipo de relatório:</span></div>
                    <div className='col-2'><span style={{ width: '200px', color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Data do relatório:</span></div>
                    <div className='col-2'><span style={{ width: '200px', color: "#4906AB", fontWeight: '600', margin: '0 10px' }}></span></div>
                </div>
                <div style={{ width: "93%", maxHeight: '400px', overflowY: 'auto' }}>
                    {relatorioAcompanhamento === undefined ? "Não foi possível carregar os dados, " : relatorioAcompanhamento.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioAdequacao === undefined ? "Não foi possível carregar os dados, " : relatorioAdequacao.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioAlta === undefined ? "Não foi possível carregar os dados, " : relatorioAlta.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioAtividade === undefined ? "Não foi possível carregar os dados, " : relatorioAtividade.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioEncerramento === undefined ? "Não foi possível carregar os dados, " : relatorioEncerramento.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioGeral === undefined ? "Não foi possível carregar os dados, " : relatorioGeral.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                    {relatorioOrientacao === undefined ? "Não foi possível carregar os dados, " : relatorioOrientacao.map((item) => {
                        return (
                            <div className='d-flex align-items-center justify-content-center mb-1'>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.id} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.tipo} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'><input readOnly={'readOnly'} defaultValue={item.data} style={{ width: '97%', boxShadow: "0 0 10px 0px silver", borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} /></div>
                                <div className='col-2'>
                                    <button style={{
                                        backgroundColor: '#7340BD',
                                        color: '#FFFFFF',
                                        fontFamily: "Nunito, sans-serif",
                                        border: "none",
                                        padding: "7px 30px",
                                        borderRadius: "10px",
                                        marginLeft: 10,
                                    }}>
                                        Abrir
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {/* <div style={{marginBottom: 20, marginTop: 50}}>
                   <TableRelatoriosEstrategicos source={[]} />
                </div> */}
            <div style={BotaoInserirRelatorio}>
                <BotaoAcao onClick={handleOpenModalRelatorios} style={BotaoRelatorioStyle}>
                    <div style={{ alignItems: "center", justifyContent: "center", display: "flex", }}>
                        <p style={{ fontSize: 35, marginLeft: -10, }}>+</p>
                        <p style={{ marginLeft: 3, marginTop: 5 }}> Criar Relatório</p>
                    </div>
                </BotaoAcao>
            </div>
            {modalRelatorios && <ModalRelalatoriosEstrategicos onClose={handleCloseModalRelatorios} />}
        </Container>
    )
}