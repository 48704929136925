import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Recepcao from '../../../../layouts/Recepcao';
import {AiOutlineBell, AiOutlineUser} from 'react-icons/ai';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css'; 
import {AgendaSubmenu, Calendar, Container} from './styles';
import user from '../../../../assets/img_avatar.png';
import api from '../../../../services/api';
import DetalhesDoAgendamento from '../Terapeuta/Modal/DetalhesDoAgendamento';
import moment from 'moment';
import UsuarioLogado from "../../../../components/UsuarioLogado";

export default function AgendaPacienteId(props) {

  const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [monthAvailability, setMonthAvailability] = useState([]);
  const [atendimentos, setAtendimentos] = useState([]);
  const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
  const [horarios, setHorarios] = useState([]);
  const [paciente, setPaciente] = useState(null);
  const [atendimentoId, setAtendimentoId] = useState(null);

  const diaSemanaIndex = moment().weekday();
  const diasDaSemanaArray = [
    {
      value: 1,
      label: 'Segunda'
    },
    {
      value: 2,
      label: 'Terça'
    },
    {
      value: 3,
      label: 'Quarta'
    },
    {
      value: 4,
      label: 'Quinta'
    },
    {
      value: 5,
      label: 'Sexta'
    },
  ];
  const {id} = useParams()
  const navigate = useNavigate();

  const getPaciente = () => {
    api.get('/api/paciente/' + id)
      .then(({data}) => {
        setPaciente(data)
      });
  }

  const getAgendaHorarios = () => {
    api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({data}) => {
      setHorarios(data);
    })
  }

  useEffect(() => {
    if (!houveExclusaoAgendamento) return;
    getAgendaDia();
    setHouveExclusaoAgendamento(false);
  }, [houveExclusaoAgendamento]);

  const getAgendaDia = () => {
    const dataSegundaFeira = moment().isoWeekday(1).format('YYYY-MM-DD');
    const dataSextaFeira = moment().isoWeekday(5).format('YYYY-MM-DD');
    api.get(`/api/agendamento?with=terapeutum,paciente,modalidade,estacao&filter[paciente_id]=${id}&filter[between]=${dataSegundaFeira},${dataSextaFeira}`).then(({data}) => {
    
      data = data.map(atendimento => {
        atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
        let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
        atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
        return atendimento;
      });
      setAtendimentos(data);
    })
  }


  const handleDateChange = useCallback((day, modifiers) => {
    if (modifiers.available && !modifiers.disabled) setSelectedDate(day);
  }, []);

  const disabledDays = useMemo(() => {
    const dates = monthAvailability
      .filter(monthDay => monthDay.available === false)
      .map(monthDay => {
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();

        return new Date(year, month, monthDay.day);
      });

    return dates;
  }, [currentMonth, monthAvailability]);


  const handleMonthChange = useCallback((month) => {
    setCurrentMonth(month);
  }, []);

  const modalDetalhesDoAtendimento = (detalhes) => {
    setAtendimentoId(detalhes.id);
    setIsModalDetalhesDoAtendimentoVisible(true);
  }

  useEffect(() => {
    getAgendaDia();
    getAgendaHorarios();
    getPaciente();
  }, []);

  return (
    <Recepcao>
      <Container>
        <div style={AgendaSubmenu}>
        <div>
          <label style={{fontFamily: "Nunito, sans-serif" , marginRight: 20, color: "#7340BD",
              cursor: "pointer"}} onClick={() => navigate('/dashboard/recepcao/pacientes')}>
                Pacientes
            </label>
            <label style={{fontFamily: "Nunito, sans-serif", fontWeight: "bold" ,marginRight: 20, 
              color: "#7340BD", cursor: "pointer"}}>
                Agenda do Paciente: {paciente === null ? "" : paciente.id}
            </label>
          </div>
          <div style={{display: "flex"}}>
            <AiOutlineBell color="silver" size={20}/>
            <AiOutlineUser color="silver" size={20}/>
            <div>
              <UsuarioLogado />
            </div>
          </div>
        </div>
        <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
          <span style={{ 
                    color: "#4906AB",
                    fontSize: "2.0rem", 
                    fontFamily: "Nunito, sans-serif", 
                    fontWeight: "800", 
                    marginBottom: '2rem'}}>
                    Agenda semanal
                </span>
          </div>
          <div className='d-flex w-100 flex-column'>
            <div className='d-flex align-items-start justify-content-center flex-row w-100'>
              <div className='d-flex flex-row w-100 '>
                <table>
                  <thead>
                    <tr>
                      <td align={'center'} style={styles.tr1}>Horário:</td>
                      {diasDaSemanaArray.map(dia => (
                          <td key={dia.value} align={'center'} style={diaSemanaIndex === dia.value ? styles.tr2 : styles.tr1}>{dia.label}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {horarios.map((horario, horarioIdx) => {
                    const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                    return (
                        <tr key={`linha-horario-${horarioIdx}`}>
                          <td align={'center'} style={styles.trHora}>{horasExtenso}</td>
                          {diasDaSemanaArray.map(dia => {
                            let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso);
                            if (atendimentoEncontrado.length > 0) {
                              atendimentoEncontrado = atendimentoEncontrado[0];
                              return (
                                  <td onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                      key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                      style={{...styles.trOcupado, textAlign: 'center'}}>
                                  <span
                                                      style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        borderBottom: 'solid',
                                                        borderWidth: 0.5,
                                                        borderColor: '#fff',
                                                      }}>Estação - {atendimentoEncontrado.estacao.estacao}</span>
                                    Terapeuta - {atendimentoEncontrado.terapeutum.nome.substr(0, 10)}...
                                  </td>
                              )
                            }
                            return (
                                <td style={styles.trVazio}
                                    key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                            );
                          })}
                        </tr>
                    )})}
                  </tbody>
                </table>
              </div>
            <div className='d-flex flex-column align-items-center justify-content-center'>
              <div style={{boxShadow: "0 0 10px 0px silver", backgroundColor: "white", borderRadius: 12}}>
                <Calendar>
                  <DayPicker
                    weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S']}
                    fromMonth={new Date()}
                    disabledDays={[{daysOfWeek: [0, 6]}, ...disabledDays]}
                    modifiers={{
                      available: {daysOfWeek: [1, 2, 3, 4, 5]},
                    }}
                    onMonthChange={handleMonthChange}
                    selectedDays={selectedDate}
                    onDayClick={handleDateChange}
                    months={[
                      'Janeiro',
                      'Fevereiro',
                      'Março',
                      'Abril',
                      'Maio',
                      'Junho',
                      'Julho',
                      'Agosto',
                      'Setembro',
                      'Outubro',
                      'Novembro',
                      'Dezembro',
                    ]}
                  />
                </Calendar>
              </div>
              
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#FFFFFF",
                padding: 20,
                borderRadius: 12, 
                boxShadow: "0 0 10px 0px silver",
                marginTop: 5
              }}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <img src={user} height={80} width={80} style={{borderRadius: 50, marginBottom: 10}}/>
                  </div>
                  <span style={{}}>
                      <label style={{
                        fontFamily: "Nunito, sans-serif",
                        color: "#707070",
                        fontWeight: "bold"
                      }}>Nome:</label> {paciente?.nome}
                  </span>
                  <span>
                      <label style={{
                        fontFamily: "Nunito, sans-serif",
                        color: "#707070",
                        fontWeight: "bold"
                      }}>Terapia</label> Terapia Ocupacional
                  </span>
                  <span>
                      <label style={{
                        fontFamily: "Nunito, sans-serif",
                        color: "#707070",
                        fontWeight: "bold"
                      }}>Faltas:</label> 000
                  </span>
                </div>
                <button style={styles.tr2}
                onClick={
                  () => {
                    navigate(`/dashboard/recepcao/pacientes/perfil/${id}`);
                }}
                >Abrir</button>
              </div>
            </div>
            </div>
          </div>
        </div>
        {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAgendamento
            agendamentoId={atendimentoId}
            setHouveExclusaoAgendamento={() => setHouveExclusaoAgendamento(true)}
          onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
        /> : (<></>)}
      </Container>
    </Recepcao>
  )
}


const styles = {
  tr1: {
    backgroundColor: " #EAEAEA",
    color: "#000000",
    boxShadow: "0 0 3px 1px silver",
    padding: 5,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: "pointer",
    height: 50,
    width: 120
  },
  tr2: {
    backgroundColor: "#7441BF",
    color: "#fff",
    padding: 5,
    width: 120,
    borderRadius: 10,
    boxShadow: "0 0 3px 1px silver",
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: "pointer",
    height: 50,
  },
  tr3: {
    backgroundColor: "#F5F7FA",
    color: "#000000",
    padding: 5,
    width: 100,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderWidth: 2,
    borderStyle: 'solid',
  },
  trHora: {
    backgroundColor: "#DFCDF8",
    color: "#9586AC",
    padding: 5,
    boxShadow: "0 0 2px 1px silver",
    width: 100,
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
  },
  trVazio: {
    backgroundColor: "#fff",
    padding: 5,
    width: 100,
    height: 80,
    boxShadow: "0 0 3px 1px silver",
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
  },
  trOcupado: {
    backgroundColor: "#35D058",
    fontSize: 14,
    color: "#fff",
    padding: 5,
    width: 100,
    height: 80,
    borderRadius: 10,
    borderColor: '#F5F7FA',
    borderStyle: 'solid',
    cursor: 'pointer',
  },
  linha: {
    borderColor: '#fff',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff'
  }
};
