import React, {useEffect, useState} from "react";
import {AiFillFileAdd} from "react-icons/ai";
import {Container} from './styles';

import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import ModalidadeService from "../../../../services/ModalidadeService";
import TableModalidade from "./TableModalidade";
import ModalidadeModal from "../../../../components/Modais/ModalidadeModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Modalidade() {

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#8ed4ae");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Modalidades, setModalidades] = useState([]);
  const [modalData, setModalData] = useState(null);

  const loadModalidades = () => {
    ModalidadeService.index()
      .then(({data}) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        setModalidades(data)
      })
      .finally(() => setLoading(false));
  }

  const editModal = (data) => {
    setModalData(data);
    setIsOpen(true);
  }

  const handleNewModalidade = () => {
    setIsOpen(true);
    setModalData(null);
  }

  useEffect(() => {
    loadModalidades();
  }, []);


  return (
    <Admin>
      <Container>
        <div>
          <button
            onClick={() => handleNewModalidade()}
            style={{
              backgroundColor: '#7340BD',
              color: '#FFFFFF',
              fontFamily: "Nunito, sans-serif",
              border: "none",
              padding: "10px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              marginBottom: 20
            }}>
            <AiFillFileAdd style={{marginRight: 5}}/>
            Nova Modalidade
          </button>
        </div>
        <div>
          {
            (Modalidades.length !== 0 && loading === false) ? <TableModalidade callback={loadModalidades} source={Modalidades}
              editModal={editModal}/> :
              <ClipLoader color={color} loading={loading} css={override} size={50}/>
          }
        </div>
        <ModalidadeModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          callback={loadModalidades}
          modalData={modalData}
        />
      </Container>
    </Admin>
  )
}
