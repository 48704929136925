import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import api from '../../../../services/api';
import moment from 'moment';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

function InclusoesMensais() {
    const labelsFake = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const dataFake = {
        labels: labelsFake,
        datasets: [
            {
                label: 'Dataset 2',
                data: labelsFake.map((a) => a.length * 100),
                backgroundColor: 'rgb(75, 192, 192)',
                stack: 'Stack 0',
            },
            {
                label: 'Dataset 3',
                data: labelsFake.map((a) => a.length * 100),
                backgroundColor: 'rgb(53, 162, 235)',
                stack: 'Stack 1',
            },
        ],
    };

    const [options, setOptions] = useState({
        plugins: {
            title: {
                display: true,
                text: 'Registros por Dia',
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    });
    const [data, setData] = useState();
    const [dataGrafico, setDataGrafico] = useState(null);

    useEffect(() => {
        setDataGrafico(dataFake);
        api.get('api/relatorio-paciente').then((response) => {
            setData(response.data);
        });
    }, []);
    useEffect(() => {
        if (data) {
            let newData = {};
            let labels = [];
            let datasets = [];
            let monthTotals = {}; // Store the sum of values for each month
            let monthCounts = {}; // Store the count of values for each month
            let totalSum = 0; // Para calcular a média geral
            let totalCount = 0; // Para calcular a média geral

            data.forEach((item) => {
                const month = moment.utc(item.created_at).format('MMMM');
                const data = moment.utc(item.created_at).format('DD/MM/YYYY');
                if (!labels.includes(data)) {
                    labels.push(data);
                }
                if (newData[month] === undefined) {
                    newData[month] = {};
                }
                if (newData[month][data] === undefined) {
                    newData[month][data] = 0;
                    totalCount += 1;
                } else {
                    newData[month][data] += 1;
                }
                // Calculate the sum and count of values for each month
                if (monthTotals[month] === undefined) {
                    monthTotals[month] = 0;
                    monthCounts[month] = 0;
                }
                monthTotals[month] += 1; // Assuming the value to be averaged is 1, change this if needed
                monthCounts[month] += 1;

                // Adicionando ao total geral para a média
                totalSum += 1; // Assumindo que cada item contribui com 1 para a média
            });

            // Calculando a média geral
            const overallAverage = totalSum / totalCount;

            // Adicionando datasets para o gráfico
            Object.keys(newData).forEach((month, index) => {
                datasets.push({
                    label: month,
                    data: Object.values(newData[month]),
                    backgroundColor: `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`,
                    stack: `Stack ${index}`,
                    type: 'bar',
                });
            });

            // Adicionando o dataset da linha da média
            datasets.push({
                label: 'Média Geral',
                data: Array(labels.length).fill(overallAverage), // Isso garante que a linha da média se estenda por todo o gráfico
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                type: 'line',
            });

            let newDataGrafico = {
                labels: labels,
                datasets: datasets,
            };
            setDataGrafico(newDataGrafico);
        }
    }, [data]);

    return (
        <div className="container">
            {dataGrafico && options ? (
                <div className="">
                    <Bar options={options} data={dataGrafico} />
                </div>
            ) : (
                'Aguardando informações para o gráfico.'
            )}
        </div>
    );
}

function TerapiasPorPaciente() {
    const [data, setData] = useState([]);
    const [dataGrafico, setDataGrafico] = useState(null);
    const options = {
        plugins: {
            title: {
                display: true,
                text: 'Registros por Terapia',
            },
        },
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                stacked: false,  // Alterar para falso para barras individuais
            },
            y: {
                stacked: false,  // Alterar para falso para barras individuais
            },
        },
    };

    useEffect(() => {
        api.get('api/relatorio-paciente-terapia').then((response) => {
            setData(response.data);
        });
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            let labels = [];
            let datasets = [];
            let terapiaCount = {};
            let monthData = {}; // Store the terapia count for each month

            data.forEach((item) => {
                const terapia = item.terapia;
                const month = moment.utc(item.created_at).format('MMMM');
                if (!terapiaCount[terapia]) {
                    labels.push(terapia);
                    terapiaCount[terapia] = {
                        count: 0,
                        cor: item.cor_titulo,
                    };
                }
                terapiaCount[terapia].count += 1;

                // Store the terapia count for each month
                if (!monthData[month]) {
                    monthData[month] = {};
                }
                if (!monthData[month][terapia]) {
                    monthData[month][terapia] = 0;
                }
                monthData[month][terapia] += 1;
            });

            // Create datasets for each month
            Object.keys(monthData).forEach((month, index) => {
                datasets.push({
                    label: month,
                    data: labels.map((terapia) => monthData[month][terapia] || 0),
                    backgroundColor: labels.map((terapia) => terapiaCount[terapia].cor),
                    stack: `Stack ${index}`,
                });
            });

            let newDataGrafico = {
                labels: labels,  // Use terapias as labels for the X axis
                datasets: datasets,
            };
            setDataGrafico(newDataGrafico);
        }
    }, [data]);

    return (
        <div className="container">
            {(dataGrafico && dataGrafico.length > 0) && options
                ? <div className="">
                    <Bar options={options} data={dataGrafico} />
                </div>
                : ('Aguardando informações para o gráfico.')
            }
        </div>
    );
}

export function GraficosPacientes() {
    return (
        <div>
            <div className="div-titulo m-2">
                <label>Relatórios</label>
            </div>
            <div className='d-flex'>
                <div className='col-sm-12 col-lg-6 p-0 m-0'>
                    <h4 className='text-center'>Inclusões Mensais</h4>
                    <InclusoesMensais />
                </div>
                <div className='col-sm-12 col-lg-6 p-0 m-0'>
                    <h4 className='text-center'>Terapias por Paciente</h4>
                    <TerapiasPorPaciente />
                </div>
            </div>
        </div>
    );
}