import React, { useState } from 'react';
import DraggableItem from './DraggableItem';

const DragAndDrop = () => {
    const colunas = ['horarios/salas', '1', '2', '3', '4', '5'];
    const [grade, setGrade] = useState([
        ['08:00', null, 'Agenda', 'Agenda', 'Agenda', 'Agenda'],
        ['09:00', 'Agenda', null, 'Agenda', null, 'Agenda'],
        ['10:00', 'Agenda', 'Agenda', null, 'Agenda', 'Agenda'],
        ['11:00', 'Agenda', null, 'Agenda', 'Agenda', 'Agenda'],
        ['12:00', 'Agenda', null, 'Agenda', 'Agenda', 'Agenda'],
        ['13:00', null, null, 'Agenda', null, null],
    ]);

    const handleDrop = (antigaGridPos, novaGridPos, content) => {
        const newGrade = [...grade];
        newGrade[antigaGridPos.row][antigaGridPos.col] = null;
        newGrade[novaGridPos.row][novaGridPos.col] = content;
        setGrade(newGrade);
        console.log('Item movido para uma nova célula!');
    };

    return (
        <div className='container'>
            <table>
                <thead>
                    <tr>
                        {colunas.map((col, idx) => {
                            return <th key={idx}>{col}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {grade.map((linha, index) => {
                        let hora;
                        return (
                            <tr key={index}>
                                {linha.map((celula, index2) => {
                                    if (index2 === 0) {
                                        hora = celula;
                                        return <th key={index2}>{celula}</th>;
                                    } else {
                                        return (
                                            <td key={index2} className='p-0'>
                                                <DraggableItem
                                                    onDrop={handleDrop}
                                                    gridPos={{ col: index2, row: index }}>
                                                    {celula ? `${celula}: ${colunas[index2]} / ${hora}` : null}
                                                </DraggableItem>
                                            </td>
                                        );
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default DragAndDrop;
