import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Error, TextAreaStyle } from './styles';


const TextArea = ({ name, title, containerStyle = {}, icon: Icon, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  containerStyle = {
    ...containerStyle,
    marginTop: 0,
  };

  const styleInput = {
    ...rest.style,
    backgroundColor: rest.disabled ? '#ececec' : '#FFF',
    color: rest.disabled ? '#545454' : '#000',
    borderRadius: '8px',
  };

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {!rest.noLabel && <label style={{ fontWeight: 'bold', marginTop: 10, fontSize: 13 }}>{title}</label>}
      {Icon && <Icon size={20} />}
      <TextAreaStyle
        name={name}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        style={styleInput}
        autoComplete='off'
        {...rest}
        rows={6}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color='#c53030' size={20} />
        </Error>
      )}
    </div>
  );
};

export default TextArea;
