import { useState } from "react";
import api from "services/api";

const useTipoAtendimentosData = () => {
    const [tipoAtendimentosOptions, setTipoAtendimentosOptions] = useState([]);

    const fetchTipoAtendimentosData = async () => {
        try {
            const { data } = await api.get(`api/tipo-atendimento`);
            const options = data.map((tipoAtendimento) => ({
                value: tipoAtendimento.id,
                label: tipoAtendimento.tipo_atendimento
            }));
            setTipoAtendimentosOptions(options);
        } catch (error) {
            console.error('Erro ao carregar tipo atendimentos:', error);
        }
    };

    return { tipoAtendimentosOptions, fetchTipoAtendimentosData };
};

export default useTipoAtendimentosData;