import styled from 'styled-components';

export const ContainerCard = styled.div`
  border-radius: 15px;
  padding: 10px;
  background: #FFFFFF;
  width: 400px;
  margin: 0 0 50px 0;
`;

export const HeaderCard = styled.div`
  text-align: center;
`;

export const PacienteNome = styled.div`
  color: #7441BF;
  font-weight: bold;
  font-size: 18px;
`;

export const PacienteDescricao = styled.div`
  color: #BEBEBE;
`;

export const ContainerSubcard = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
