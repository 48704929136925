import React, { useState, useEffect, useRef } from 'react';
import { Form } from '@unform/web';
import { BackButton, Container, DefaultModal, Delete, InputUpload, LabelUpload, ModalDiplomaStyle, Upload, UploadIcon } from '../styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import api from '../../../../../services/api';
import Swal from 'sweetalert2';
import Select from '../../../../../components/Select';
import Input from '../../../../../components/Input';

const EspecialidadeTerapeuta = ({ terapeuta, callBack, newEspecialidade, onClose }, ref) => {
    let subtitle;

    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [especialidade, setEspecialidade] = useState([]);
    const [especialidadeSelectBox, setEspecialidadeSelectBox] = useState([]);
    const [especialidadeEscolhido, setEspecialidadeEscolhido] = useState(null);
    const [docEspecialidade, setDocEspecialidade] = useState(null);
    const [path, setPath] = useState([]);

    const formRef = useRef(null);
    const id = 'modal-especialdades-terapeuta';

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const handleUploadDiploma = (e) => {
        if (docEspecialidade === null) {
            var arquivo = document.getElementById("upload-especialidade");
            var file = e.target.files[0].name;
            var size = e.target.files[0].size;
            var extPermitidas = ['jpg', 'png', 'jpeg', 'pdf',];
            if (extPermitidas.find((ext) => { return file.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg e pgn)', '', 'warning');
                arquivo.value = "";
            } else {
                if (size < 40000000) { //40MB         
                    setDocEspecialidade(e.target.files[0]);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = "";
                }
                e.preventDefault();
            }
        }
        else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'error');
        }
    };

    const handleDeleteUpload = () => {
        setDocEspecialidade(null);
        path.splice(-1);
        setLoadingDadosIniciais(true);
    };

    async function handleSubmit(data) {
        if (especialidadeEscolhido === null || data.date === null || docEspecialidade === 0) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            let formData = new FormData();
            formData.append("arquivo", docEspecialidade);
            let label = especialidadeEscolhido.label;
            const dados = {
                especialidade_id: Number(especialidadeEscolhido.value),
                data: data.date,
                file: docEspecialidade
            };
            if (terapeuta && terapeuta.id) {
                dados.terapeuta_id = Number(terapeuta.id);
            };
            newEspecialidade(dados, label);
            onClose();
            callBack();
        };
    };

    useEffect(() => {
        async function loadDadosIniciais() {
            const dadosEspecialidade = await api.get(`api/especialidade`);
            setEspecialidade(dadosEspecialidade.data);
            const selectEspecialidade = dadosEspecialidade.data.map(d => ({
                "value": d.id,
                "label": d.especialidade
            }));
            setEspecialidadeSelectBox(selectEspecialidade);
            setLoadingDadosIniciais(false);
        };
        loadDadosIniciais();
    }, [])

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    };

    return (
        <Container className='w-100per'>
            <div className='position-relative'>
            </div>
            <div className='d-flex align-items.center justify-content-between'>
                <label></label>
                <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}> Adicionar Nova Especialidade </label>
                <label className='btn-fecha-modal' onClick={e => onClose()}> x </label>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit} style={{ height: "100%", width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "center", alignContent: "center", }}>
                    <div style={{ flexDirection: "column", width: "40%", height: "auto" }}>
                        <Select options={especialidadeSelectBox} label="Especialidade:" name="especialidade_id" onChange={e => setEspecialidadeEscolhido(e)} />
                    </div>
                    <div style={{ marginLeft: 5, flexDirection: "column", width: "28%", height: "auto" }}>
                        <Input containerStyle={ModalDiplomaStyle} title="Data:" name="date" type="date" />
                    </div>
                    <div style={{ marginTop: 5, flexDirection: "column", marginLeft: 5, }}>
                        <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
                    </div>
                    <div style={{ flexDirection: "column" }}>
                        <label htmlFor="upload-especialidade" style={LabelUpload}>Anexar Certificação
                            <input type="file" id="upload-especialidade" style={InputUpload} onChange={e => handleUploadDiploma(e)} />
                        </label>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {path.map((item) => {
                        return <span style={Upload}>{item}
                            <div>
                                <span style={Delete} onClick={e => handleDeleteUpload()}> x </span>
                            </div>
                        </span>
                    })}
                </div>
                <div className='d-flex align-items.center justify-content-evenly mt-3 mb-3'>
                    <button onClick={e => onClose()} className="btn-padrao"> Voltar </button>
                    <button onClick={e => functionThatSubmitsForm()} className="btn-padrao"> Adicionar </button>
                </div>
            </Form>
        </Container>
    );
}

export default EspecialidadeTerapeuta;