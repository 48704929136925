import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;`;

export const ContagemPendentes = styled.a`
    border-radius: 100px;
    background-color: red;
    font-size: 12px;
    width: 18px !important;
    height: 18px !important;

    padding: 0px;
    margin: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    font-weight: bold;

    color: white;
    text-decoration: none;
    :hover {
        font-size: 16px;
    }
`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;

export const FormField = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    flex-direction: column;
`;