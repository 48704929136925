import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 25px;
  background-color: #ffffff;
  padding: 15px 20% 10px;
`;

export const NomePaciente = styled.span`
  color: #4906AB;
  font-weight: bold;
  font-size: 24px;
  text-transform: capitalize;
`;

export const InfoPaciente = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const PropriedadePaciente = styled.span`
  color: #4906AB;
  font-weight: bold;
`;

export const LinhaInfoPaciente = styled.div`
  margin: 10px 0;
`;
