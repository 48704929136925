import React, {useMemo} from "react";
import { useNavigate } from "react-router-dom";
import {AiOutlineCalendar } from "react-icons/ai";
import TablePagination from "../../../../components/TablePagination";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";

export function TableResponsavelPaciente({ source }) {

  const navigate = useNavigate();
  const columns = useMemo(
    () => [
          {
            Header: 'Nome:',
            accessor: 'nome',
          },
          {
            Header: 'Identificador:',
            accessor: 'identificador',
          },
          {
            Header: "Ações",
            accessor: "acoes",
            Cell: ({ cell }) => (
              (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                <button
                  onClick={() => navigate('/dashboard/responsavel/pacientes/perfil/' + cell.row.original.id)}
                  style={{
                    backgroundColor: '#7340BD',
                    color: '#FFFFFF',
                    fontFamily: "Nunito, sans-serif",
                    border: "none",
                    padding: "2px 22px",
                    borderRadius: 8,
                    marginRight: 12
                  }}>
                  Abrir
                </button>
                <AiOutlineCalendar
                  style={{cursor: 'pointer'}}
                  onClick={() => navigate('/dashboard/responsavel/agenda/paciente/' + cell.row.original.id)}
                  color="#7340BD"
                  size={22}
                />
              </div>
              )
            )
          }
        ],
    []
  )

  return (
      <TableDefaultPagination columns={columns} source={source}/>
    )
}