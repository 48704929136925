import React from 'react';
import moment from "moment";
import { ButtonEditStatus } from '../styles';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import AtendimentoAlteracaoService from '../../../../services/AtendimentoAlteracaoService';

export function TableAtendimentos({ source, openModal, callBack, setModalData, handleOpenAtendimento }) {
    const snackbar = useSnackbar();

    const removeAtendimento = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                AtendimentoAlteracaoService.destroy(data.id);
                callBack()
                snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    }

    const handleEditAtendimento = (data) => {
        setModalData(data);
        handleOpenAtendimento();
    };

    const columns = React.useMemo(() => [
        {
            Header: 'Paciente:',
            accessor: 'paciente.nome',
        },
        {
            Header: 'Terapeuta:',
            accessor: 'terapeutum.nome',
        },
        {
            Header: 'Data:',
            accessor: 'data_atendimento_inicial',
            desc: true,
            Cell: ({ cell }) => {
                /*
                  A data é criada dessa maneira, porque a coluna data_atendimento_inicial tem apenas 
                 o horário correto, a data é armazenada na coluna created_at.
                  Isso acontece porque a tabela de agendamento, que se converte em atendimento através
                 de um processo chamado Carregar Controle Diário, não se vincula à data específica, e sim
                 ao dia da semana. O que faz com que a data, fique desatualizada. Então em todas as situalções
                 em que for ser necessário mostrar a data do atendimento, sempre se trata da fusão do created_at(D/M/Y)
                 e do data_atendimento_inicial(H:m). Ou a utilização do atributo adicional criado no Laravel, chamado data_atendimento_real
                */

                let data = moment.utc(cell.row.original.data_atendimento_real).format('DD/MM/YYYY HH:mm');
                return data;
            }
        },
        {
            Header: 'Plano de saúde:',
            accessor: 'paciente.plano_saude.plano',
        },
        {
            Header: 'Status:',
            accessor: 'status_atendimento.status',
        },
        {
            Header: "Ações",
            accessor: "acoes",
            Cell: ({ cell }) => ((
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                    <button onClick={(e, row) => openModal(cell.row.original)} style={ButtonEditStatus}>
                        Editar Status
                    </button>
                    <AiOutlineEdit style={{ cursor: "pointer", marginRight: 5 }} color="#7340BD"
                        onClick={e => handleEditAtendimento(cell.row.original)} size={22} />
                    <AiOutlineDelete
                        style={{ cursor: 'pointer', marginRight: 5 }}
                        color="#7340BD" size={22}
                        onClick={e => removeAtendimento(cell.row.original)} />
                </div>))
        }])

    return (
        <TableDefaultPagination columns={columns} source={source} />
    )
}