import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F5F7FA;
  align-items: stretch;
  color: #000;
  width: ${window.innerWidth<600 ? 100 : 60}vw;
  height: 70vh;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 30px 0;
  margin: 0px;
`;
