import api from "./api";

class DocumentosGeraisFuncionarioService {
    static index = (params) => {
        return api.get(`api/documentos-gerais?${params}`);
    }

    static store = (obj) => {
        /* exemplo
        {"user":"delectus"}
        */

        return api.post(`api/documentos-gerais`, obj);
    }

    static show = (id) => {
        return api.get(`api/documentos-gerais/${id}`);
    }

    static update = (obj, id) => {
        /* exemplo
        {"user":"corporis"}
        */

        return api.put(`api/documentos-gerais/${id}`, obj);
    }

    static destroy = (id) => {
        return api.delete(`api/documentos-gerais/${id}`);
    }
}

export default DocumentosGeraisFuncionarioService;
