import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import { PickList } from 'primereact/picklist';
import api from '../../../services/api';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import Button from '../../Button';
import { useSnackbar } from '../../../context/snackbar';
import Input from '../../Input';

const customStyles = {
    content: {
        top: '55%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '80vh',
        minHeight: '80vh',
        width: '70vw',
        display: 'grid',
        backgroundColor: '#FFFFFF',
    },
};

const AssocPermissaoModal = ({ modalIsOpen, setIsOpen, groupId }, ref) => {
    let subtitle;

    const formRef = useRef(null);
    const [Permissoes, setPermissoes] = useState([]);
    const [PermissaoSelecionada, setPermissaoSelecionada] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sourceFilter, setSourceFilter] = useState('');
    const [targetFilter, setTargetFilter] = useState('');
    const color = useState('#8ed4ae');
    const snackbar = useSnackbar();

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    const loadPermissoes = () => {
        let permissoes = [];
        api
            .get('/api/permission?order_by=name:asc')
            .then(({ data }) => {
                data.forEach((x) => {
                    permissoes.push({ value: x.id, label: x.name });
                });
                setPermissoes(permissoes);
                loadGroupPermissoes();
            })
            .catch((e) => alert(e));
    };

    const loadGroupPermissoes = () => {
        let defaults = [];
        api
            .get(`/api/group/${groupId}?with=permissions`)
            .then(({ data }) => {
                data.permissions.forEach((x) => {
                    defaults.push({ value: x.id, label: x.name });
                });
                setPermissaoSelecionada(defaults.sort((a, b) => a.label.localeCompare(b.label)));
                setLoading(false);
            })
            .catch((e) => alert(e));
    };

    useEffect(() => {
        loadPermissoes();
    }, []);

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit() {
        await api.post(`api/permission/${groupId}/assoc`, { permissions: PermissaoSelecionada })
            .then(() => snackbar.success('Permissões associadas com sucesso!', 'success'))
            .catch((e) => {
                snackbar.error('Erro ao associar permissões!', 'error');
                console.error(e);
            }).finally(() => {
                setLoading(true);
                closeModal();
            });
    }

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Modal
            ariaHideApp={false}
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            transparent
        >
            <div style={{ position: 'relative' }}>
                <label
                    style={{
                        opacity: 0.5,
                        cursor: 'pointer',
                        position: 'absolute',
                        right: '10px',
                        fontSize: '25px',
                        top: '-10px',
                    }}
                    onClick={() => setIsOpen(false)}
                >
                    x
                </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h2 style={{ color: '#442953', fontWeight: 'bold' }} ref={(_subtitle) => (subtitle = _subtitle)}>
                    Permissões
                </h2>
                <Form
                    ref={formRef}
                    onSubmit={handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', borderWidth: 1, borderTopColor: 'red' }}
                >
                    <div style={{ paddingTop: 30 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {!loading ? (
                                <PickList
                                    source={Permissoes.filter(
                                        (item) => !PermissaoSelecionada.some((sel) => sel.value === item.value) && (!sourceFilter || item.label.toLowerCase().includes(sourceFilter.toLowerCase()))
                                    )}
                                    target={PermissaoSelecionada.filter((item => (!targetFilter || item.label.toLowerCase().includes(targetFilter.toLowerCase()))))}
                                    sourceHeader="Disponíveis"
                                    targetHeader="Selecionadas"
                                    itemTemplate={(item) => <span>{item.label}</span>}
                                    onChange={(e) => setPermissaoSelecionada(e.target)}

                                    filter filterBy='label' // Propriedade de filtro, filtrando pelos rótulos dos itens

                                    sourceStyle={{ height: '24rem' }} // Estilo CSS para a lista de itens disponíveis
                                    sourceFilterTemplate={() => ( // Template usado para renderizar o campo de filtro na lista de itens selecionados
                                        <div className="row">
                                            <label className='label-padrao col-12'>Filtrar por Nome:
                                                <Input
                                                    type="text"
                                                    id={'name'}
                                                    name={'name'}
                                                    value={sourceFilter} // Acho que aqui deve ser 'targetFilter' em vez de 'sourceFilter'
                                                    onChange={(e) => setSourceFilter(e.target.value)} // Deve ser 'setTargetFilter' em vez de 'setSourceFilter'
                                                    placeholder="Pesquisar por Permissão"
                                                />
                                            </label>
                                        </div>
                                    )}
                                    targetStyle={{ height: '24rem' }} // Estilo CSS para a lista de itens disponíveis
                                    targetFilterTemplate={() => ( // Template usado para renderizar o campo de filtro na lista de itens selecionados
                                        <div className="row">
                                            <label className='label-padrao col-12'>Filtrar por Nome:
                                                <Input
                                                    type="text"
                                                    id={'tar_name'}
                                                    name={'tar_name'}
                                                    value={targetFilter} // Acho que aqui deve ser 'targetFilter' em vez de 'sourceFilter'
                                                    onChange={(e) => setTargetFilter(e.target.value)} // Deve ser 'setTargetFilter' em vez de 'setSourceFilter'
                                                    placeholder="Pesquisar por Permissão"
                                                />
                                            </label>
                                        </div>
                                    )}
                                />
                            ) : (
                                <ClipLoader color={color} css={override} loading={loading} size={50} />
                            )}
                        </div>
                    </div>
                    <Button type="submit">Atualizar Lista de Permissões</Button>
                </Form>
            </div>
        </Modal>
    );
};

export default forwardRef(AssocPermissaoModal);