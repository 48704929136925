import api from "./api";

class AgendaAreaCompartilhadaService {
    static index = (params) => {
        return api.get(`api/agenda-compartilhada?${params}`);
    }
    static store = (obj) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":82,"terapeuta_id":1,"terapia_id":1,"especialidade_id":1,"modalidade_id":1,"tipo_atendimento_id":1,"reserva":false,"data_atendimento_inicial":"1999-04-12","data_atendimento_final":"1983-05-15","comentario":"Similique omnis aut aspernatur eos mollitia nisi dolor. Et rerum eos nemo repudiandae omnis itaque ab. Iure optio est ut.","estacao_id":1}
        */
                
        return api.post(`api/agenda-compartilhada`, obj);
    }
    static show = (id, params) => {
        return api.get(`api/agenda-compartilhada/${id}?id=${id}&${params}`);
    }
    static update = (obj, id) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":35369,"terapeuta_id":1,"terapia_id":1,"especialidade_id":1,"modalidade_id":1,"tipo_atendimento_id":1,"reserva":true,"data_atendimento_inicial":"1995-05-25","data_atendimento_final":"1978-04-30","comentario":"Impedit sed aut facilis. Eum doloribus et cum tempore. Quo odit vel deserunt dolor voluptatem perspiciatis consequatur. Eum maxime fugit molestiae.","estacao_id":1}
        */
                
        return api.put(`api/agenda-compartilhada/${id}?id=${id}`, obj);
    }
    static destroy = (id) => {
        return api.delete(`api/agenda-compartilhada/${id}?id=${id}`);
    }
}

export default AgendaAreaCompartilhadaService;