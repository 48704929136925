import React from "react";
import {BoxStyle1, BoxStyle2, BoxStyle3, BoxStyle4, ContainerDiv } from './styles'


export default function PacientesClinicaCard({ title, pacientes }) {
    return (
        <div style={ContainerDiv}>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"}}>
                <label style={{ color: "#4906AB", fontWeight: "800", fontSize: '18px', fontFamily: "Nunito, sans-serif" }}>{title}</label>
            </div>
            <div style={{ paddingTop: '16px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{
                    display: "flex", alignItems: "center",
                }}>
                    <div style={BoxStyle1}>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Total de Pacientes</label>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{pacientes.total_pacientes && pacientes.total_pacientes}</label>
                    </div>
                    <div style={BoxStyle2}>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Afastados</label>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{pacientes.afastados && pacientes.afastados}</label>
                    </div>
                    <div style={BoxStyle3}>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Em férias</label>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{pacientes.ferias && pacientes.ferias}</label>
                    </div>
                    <div style={BoxStyle4}>
                        <label style={{ color: "#FFFFFF", fontSize: '0.8rem', fontFamily: "Nunito, sans-serif", fontWeight: "700" }}>Inativos</label>
                        <label style={{ color: "#FFFFFF", fontSize: '1.4rem', fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>{pacientes.inativos && pacientes.inativos}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}