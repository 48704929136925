import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../../../services/api';
const MiniCurriculoModal = forwardRef(({ id = 'agendaModal', modo = 'modal', terapeuta_id = null }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = 'Mini Curriculo do Terapeuta';
    const [isOpen, setIsOpen] = useState(false);
    /*===VARS VALIDACAO===*/
    const [loading, setloading] = useState(false);
    const [terapeuta, setTerapeuta] = useState([]);

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: 'fit-content',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((id) => {
        setIsOpen(true);
        carregaTerapeuta(id)
    }, []);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const carregaTerapeuta = (id) => {
        setloading(true);
        api.get(`/api/terapeuta/${id}/mini-curriculo`).then(({ data }) => {
            setTerapeuta(data);
        }).catch((e) => {
            console.error(e);
        }).finally(() => {
            setloading(false);
        });
    }
    //#endregion
    useEffect(() => {/* ON LOAD */
        if (modo === 'incorporado') {
            carregaTerapeuta(terapeuta_id);
        }
    }, []);
    return (
        <>
            {modo === 'modal' ?
                <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} appElement={document.getElementById('root')} transparent>
                    {loading && <Carregamento></Carregamento>}
                    <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                        <label></label>
                        <label>{title}</label>
                        <label>
                            <button className='btn-icone' onClick={closeModal}>
                                <AiOutlineClose color='red' size={22} />
                            </button>
                        </label>
                    </div>
                    <div className='div-curriculo'>
                        <div className='coluna-esquerda'>
                            <img src={terapeuta.foto_perfil_full} alt=''></img>
                            <label>{terapeuta.nome}</label>
                        </div>
                        <div className='coluna-direita'>
                            <fieldset >
                                <legend>Terapias:</legend>
                                <ul id='terapias' key='terapias'>
                                    {terapeuta.terapias && terapeuta.terapias.map((es, index) => {
                                        if (es.diploma && es.registro) {
                                            return (<li key={index}>{es.terapia} - {es.diploma} - {es.registro}</li>)
                                        } else {
                                            if (es.diploma) {
                                                return (<li key={index}>{es.terapia} - {es.diploma}</li>)
                                            } else {
                                                if (es.registro) {
                                                    return (<li key={index}>{es.terapia} - {es.registro}</li>)
                                                } else {
                                                    return (<li key={index}>{es.terapia}</li>)
                                                }
                                            }
                                        }
                                    })}
                                </ul>
                            </fieldset>
                            <fieldset>
                                <legend>Especialidades:</legend>
                                <ul id="especialidades" key="especialidades">
                                    {terapeuta.especialidades && terapeuta.especialidades.map((es, index) => {
                                        return (<li key={index}>{es.especialidade}</li>)
                                    })}
                                </ul>
                            </fieldset>
                            <fieldset>
                                <legend>Certificacoes:</legend>
                                <ul id="certificacoes" key="certificacoes">
                                    {terapeuta.certificacao && terapeuta.certificacao.map((es, index) => {
                                        return (<li key={index}>{es.nome}</li>)
                                    })}
                                </ul>
                            </fieldset>
                        </div>
                    </div>
                </Modal>
                : modo === 'incorporado' ?
                    <div className='div-curriculo-in'>
                        <div className='coluna-horizontal'>
                            <label>
                                Curriculo do Terapeuta
                            </label>
                        </div>
                        <div className="linha">
                            <div className='coluna-esquerda'>
                                <img src={terapeuta.foto_perfil_full} alt=''></img>
                                <label className='p-1'>{terapeuta.nome}</label>
                            </div>
                            <div className='coluna-direita'>
                                <fieldset>
                                    <legend>Terapias</legend>
                                    <ul id='terapias' key='terapias'>
                                        {terapeuta.terapias && terapeuta.terapias.map((es, index) => {
                                            if (es.diploma && es.registro) {
                                                return (<li key={index}>{es.terapia} - {es.diploma} - {es.registro}</li>)
                                            } else {
                                                if (es.diploma) {
                                                    return (<li key={index}>{es.terapia} - {es.diploma}</li>)
                                                } else {
                                                    if (es.registro) {
                                                        return (<li key={index}>{es.terapia} - {es.registro}</li>)
                                                    } else {
                                                        return (<li key={index}>{es.terapia}</li>)
                                                    }
                                                }
                                            }
                                        })}
                                    </ul>
                                </fieldset>
                                <fieldset>
                                    <legend>Especialidades</legend>
                                    <ul id="especialidades" key="especialidades">
                                        {terapeuta.especialidades && terapeuta.especialidades.map((es, index) => {
                                            return (<li key={index}>{es.especialidade}</li>)
                                        })}
                                    </ul>
                                </fieldset>
                                <fieldset>
                                    <legend>Certificacoes</legend>
                                    <ul id="certificacoes" key="certificacoes">
                                        {terapeuta.certificacao && terapeuta.certificacao.map((es, index) => {
                                            return (<li key={index}>{es.nome}</li>)
                                        })}
                                    </ul>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                    : ''
            }
        </>
    )
});
export default MiniCurriculoModal;