import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import Input from '../../../../../components/Input';
import { Form } from '@unform/web';
import moment from "moment/moment";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { useAuth } from '../../../../../context/auth';
import api from '../../../../../services/api';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        maxHeight: "70vh",
        width: "40vw",
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};

const ModalFerias = ({ feriasList, paciente_id = null, id = paciente_id, setFeriasList, api_link = 'api/paciente-ferias' }, ref) => {
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [data_inicio, setData_inicio] = useState(null);

    const formRef = useRef(null);
    const snackbar = useSnackbar();
    const user = useAuth();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(formData) {
        try {
            if (formData.dt_ida === "" || formData.dt_volta === "" || formData.comentario === "") {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
            } else {
                let feriasLocal = feriasList;
                formData.aprovado_por = user.user.id;
                if (id !== null) {
                    let obj = (api_link === 'api/paciente-ferias')
                        ? { ...formData, paciente_id: id }
                        : {
                            aprovado_por: formData.aprovado_por,
                            comentario: formData.comentario,
                            data_inicio: formData.dt_ida,
                            data_fim: formData.dt_volta,
                            terapeuta_id: id
                        };
                    await api.post(api_link, obj).then(({ data }) => {
                        feriasLocal.push(data);
                        setFeriasList(feriasLocal);
                        snackbar.displayMessage('Ferias cadastradas com sucesso!', 'success');
                    }).catch((e) => {
                        snackbar.displayMessage('Não foi possível cadastrar as férias!', 'error');
                    })
                } else {
                    feriasLocal.push(formData);
                    setFeriasList(feriasLocal);
                    snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                }
                closeModal();
            };
        } catch (error) {
            Swal.fire('Houve um erro ao criar o cadastro!', '', 'error');
            console.error(error)
        }
    }

    function functionThatSubmitsForm() {
        formRef.current.submitForm();
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
                appElement={document.getElementById('root')}
                transparent
            >
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => closeModal()}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold", textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>Histórico de Férias</h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                        <div style={{ paddingTop: 30 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "50%", paddingRight: 10 }}>
                                    <Input type={'date'} title="Data Inicio:" name="dt_ida" onChange={(e) => { setData_inicio(e.target.value); document.getElementById('dt_volta').value = null }} />
                                </div>
                                <div style={{ width: "50%", paddingRight: 10 }}>
                                    <Input type={'date'} title="Data Fim:" name="dt_volta" id="dt_volta" min={data_inicio} />
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 10 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%", paddingRight: 10 }}>
                                    <Input title="Aprovado por:" name="aprovado_por" readOnly={'readOnly'}
                                        defaultValue={user.user.name} />
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 10 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "100%", paddingRight: 10 }}>
                                    <Input title="Comentário:" name="comentario" />
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 10 }}>
                            <button className='btn-padrao' type="button" onClick={functionThatSubmitsForm}> Adicionar </button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(ModalFerias);
