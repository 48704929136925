import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {DocumentoContainer} from './styles';
import {useAuth} from '../../context/auth';

export function Documento({ documentoId }) {

  const { user } = useAuth();
  const [numPages, setNumPages] = useState(1);
  const [pages, setPages] = useState([]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (<>
    <DocumentoContainer>
      <Document
          file={{ url: `http://ec2-54-88-196-144.compute-1.amazonaws.com/api/documento-administrativo/${documentoId}/${user.id}/pdf` }}
          onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </DocumentoContainer>
  </>);
}
