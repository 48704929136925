import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown, AiFillEdit, AiFillEye, AiOutlineClear, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import 'react-day-picker/dist/style.css';
import AdicionarAgendamento from '../../../TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import moment from 'moment';
import 'moment/locale/pt-br';
import api from '../../../../services/api';
import Responsavel from './Modal/Responsavel';
import DetalhesDoAgendamento from './Modal/DetalhesDoAgendamento';
import EstacaoService from '../../../../services/EstacaoService';
import { getLegendaAgendamento } from "../../../../helpers/agenda.helper";
import LegendaDefault from '../ControleDiario/Modal/Legenda';
import { ClipLoader } from "react-spinners";
import { FiRefreshCcw } from 'react-icons/fi';
import Swal from 'sweetalert2';
import AgendamentoService from '../../../../services/AgendamentoService';
import { BsCheck, BsDownload, BsFullscreen, BsTrashFill } from 'react-icons/bs';
import "./styles.css";
import { Container, DivContentDisplay, listOfStyles, styles_escuro, styles_filtred, filtered } from './styles';
import "../../../../styles/global.css"
import { useSnackbar } from '../../../../context/snackbar';
import { Form } from '@unform/web';
import Select from '../../../../components/Select';
import Input from '../../../Login/components/Input';
import { FaBroom } from 'react-icons/fa';
import { useAuth } from '../../../../context/auth';
import { Tooltip } from 'react-tooltip';
import { ChangeCase } from '../../../../services/ChangeCase';
import usePermissoes from '../../../../context/permission';
import { toPng } from 'html-to-image';
import Dropdown from 'components/Dropdown';
import useTerapiasData from 'context/terapia';
import useEspecialidadesData from 'context/especialidade';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import useModalidadeData from 'context/modalidade';
import DraggableItem from 'components/DragAndDrop/DraggableItem';
import LiveBadge from 'components/LiveBadge';

/**
 * Component for displaying and managing a weekly agenda.
 *
 * @component
 * @example
 * // Usage
 * <AgendaSemanal />
 */
export default function AgendaSemanal() {
	//#region VARIÁVEIS
	/*==VARS INFORMAÇÕES da TELA==*/
	// eslint-disable-next-line no-unused-vars
	const titulo = 'AGENDA SEMANAL';
	// eslint-disable-next-line no-unused-vars
	const rota = 'dashboard/{modulo}/agenda';
	// eslint-disable-next-line no-unused-vars
	const descricao = 'Tela que cadastra e visualiza agendamentos para o dia/semana.';
	const stylesSize = [90, 70, 90, 70];
	/*==VARS ESTILOS==*/
	const stylesNormal = listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3]
	});
	const stylesNormalFullscreen = listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		isFullScreen: true
	});
	const stylesEspera = listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		esperaMode: true
	});
	const stylesEsperaFullscreen = listOfStyles({
		baseWidth: stylesSize[0],
		baseHeight: stylesSize[1],
		baseWidthHora: stylesSize[2],
		baseHeightHora: stylesSize[3],
		isFullScreen: true,
		esperaMode: true
	});
	const [styles, setStyles] = useState(stylesNormal);
	/*==VARS SOCKET==*/
	const [socket, setSocket] = useState(null);
	const [socketState, setSocketState] = useState(null);
	/*==VARS UTILITÁRIOS==*/
	const snackbar = useSnackbar();
	/*==VARS LOADING==*/
	const [loading, setloading] = useState(true);
	const [algoCarregando, setAlgoCarregando] = useState(false);
	const [fullScreenState, setfullScreenState] = useState(false);
	const [mostrandoReserva, setMostrandoReserva] = useState(false);
	const [esperaMode, setEsperaMode] = useState(false);
	/*==VARS PERMISSÕES==*/
	const usuario = useAuth();
	const permissoes = usePermissoes();
	/*==VARS MODAIS==*/
	const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
	const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
	const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
	const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);

	const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
	/*==VARS DIAS==*/
	const dayName = ["Domingo", "Segunda - Feira", "Terça - Feira", "Quarta - Feira", "Quinta - Feira", "Sexta - Feira", "Sábado"]
	/*==VARS Listas de Dados==*/
	const [estacaos, setEstacaos] = useState([]);
	const [estacaosOriginal, setEstacaosOriginal] = useState([]);
	const [horarios, setHorarios] = useState([]);
	const [agendamentos, setAgendamentos] = useState([]);
	const [socketAgendamento, setSocketAgendamento] = useState(null);
	const [newAgendamentos, setNewAgendamentos] = useState([]);

	const [hour, setHour] = useState(null);
	const [estacaoObj, setEstacaoObj] = useState(null);
	const [tipoAtendimentoSelecionado, setTipoAtendimentoSelecionado] = useState(null);
	const [reservaSelecionada, setReservaSelecionada] = useState(null);
	const [expand, setExpand] = useState(false);
	const [dataSelecionada, setDataSelecionada] = useState(moment().format('YYYY-MM-DD'));
	const [agendamentoId, setAgendamentoId] = useState();
	const [idHorario, setIdHorario] = useState();
	/*==VARS DATAS==*/
	const dateNow = new Date();
	const [dataAtual, setDataAtual] = useState(moment(dateNow));
	const [diaAtual, setDiaAtual] = useState(dateNow.getDay());
	const [dataSave, setDataSave] = useState(moment());
	/*==VARS EXPORT TO IMAGE==*/
	const elementRef = useRef(null);
	const estacaoTableRef = useRef(null);
	/*==VARS FILTRO==*/
	const filtroDivRef = useRef(null);
	const [filtroIncidencia, setFiltroIncidencia] = useState('');

	const { terapiasOptions, fetchTerapias } = useTerapiasData();
	const { especialidadesOptions, fetchEspecialidades } = useEspecialidadesData();
	const { tiposAtendimentoOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
	const { modalidadesOptions, fetchModalidadeData } = useModalidadeData();

	const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
	const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState([]);
	const [salaSelecionadaOpt, setSalaSelecionadaOpt] = useState([]);
	const [terapiasSelecionadaOpt, setTerapiasSelecionadaOpt] = useState([]);
	const [especialidadesSelecionadaOpt, setEspecialidadesSelecionadaOpt] = useState([]);

	const [pacientesList, setPacientesList] = useState([]);
	const [salasList, setSalasList] = useState([]);
	//===VARS REFS===*/
	const tableRef = useRef(null);
	const horaTableBodyRef = useRef(null);
	const agendamentoTableBodyRef = useRef(null);
	const estacaoTableHeadRef = useRef(null);
	//#endregion
	//#region FUNCOES
	/**
	 * Enters fullscreen mode for the specified element.
	 */
	const enterFullscreen = () => {
		const elem = tableRef.current;
		if (elem.requestFullscreen) {
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { // Firefox
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
			elem.webkitRequestFullscreen();
		}
	};
	/**
	 * Exits full screen mode.
	 */
	const exitFullScreen = () => {
		const isDocumentActive = () => {
			return !document.hidden;
		};
		if (isDocumentActive) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	};
	/**
	 * Toggles the fullscreen mode.
	 */
	const fullScreen = () => {
		if (!document.fullscreenElement) {
			enterFullscreen();
			setfullScreenState(true);
		} else {
			exitFullScreen();
			setfullScreenState(false);
		}
	}
	/**
	 * Approves a reservation as an appointment.
	 * 
	 * @param {Object} agendamento - The appointment object.
	 * @param {number} agendamento.id - The ID of the appointment.
	 * @param {string} agendamento.data_atendimento_inicial - The initial date and time of the appointment.
	 * @param {string} agendamento.data_atendimento_final - The final date and time of the appointment.
	 * @param {string} agendamento.paciente_nome - The name of the patient.
	 * @param {string} agendamento.terapeuta_nome_curto - The short name of the therapist.
	 */
	const aprovaReserva = (agendamento) => {
		let id = agendamento.id;
		let data1 = agendamento.data_atendimento_inicial.split(' ')[1].split('.')[0];
		let data2 = agendamento.data_atendimento_final.split(' ')[1].split('.')[0];
		let conteudo = `Paciente: ${agendamento.paciente_nome} \n`
			+ `Terapeuta: ${agendamento.terapeuta_nome_curto} \n`
			+ `${data1} até ${data2}`;
		let tipo = 'question';
		if (id !== null) {
			let option = {
				title: 'Você deseja confirmar essa reserva, como agendamento?',
				text: conteudo,
				icon: tipo,
				confirmButtonText: 'Confirmar',
				denyButtonText: 'Cancelar',
				showConfirmButton: true,
				showDenyButton: true
			}
			Swal.fire(option).then((result) => {
				if (result.isConfirmed) {
					AgendamentoService.update({ id: id, reserva: 0 })
						.then(() => {
							snackbar.displayMessage('Reserva aprovada como agendamento.', 'success');
							atualizaComponenteData();
						})
						.catch((e) => {
							snackbar.displayMessage('Houve um erro ao aprovar a reserva.', 'error');
							console.error(e);
						})
				}
			})

		}
	}
	/*==FILTRO==*/
	/**
	 * Expands or collapses the filter.
	 * 
	 * @param {Event} e - The event object.
	 * @returns {void}
	 */
	const expandFiltro = (e) => {
		if (expand) {
			fechaFiltro();
		} else {
			abreFiltro();
		}
	}
	/**
	 * Opens the filter.
	 */
	const abreFiltro = () => {
		setExpand(true);
	}
	/**
	 * Closes the filter dropdown if the event target is outside the filter container.
	 * @param {Event} event - The event object.
	 */
	const fechaFiltro = (event) => {
		try {
			if (!event) {
				setExpand(false);
			} else {
				if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
					setExpand(false);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}
	/**
	 * Clears the filters for the therapist's agenda.
	 */
	const handleLimpaFiltros = () => {
		setPacienteSelecionadoOpt([]);
		setSalaSelecionadaOpt([]);
		setTerapiasSelecionadaOpt([]);
		setEspecialidadesSelecionadaOpt([]);
		setFiltroIncidencia('');
		setTerapeutaSelecionado(null);
	}
	/**
	 * Filters the agendamentos based on the selected filters and updates the agendamentos list.
	 * 
	 * @returns {void}
	 */
	const filtrar = () => {
		const filtro = filtroIncidencia === '' ? null : filtroIncidencia;
		const idPaciente = pacienteSelecionadoOpt.value ? pacienteSelecionadoOpt.value : null;
		const agendamentosNovos = agendamentos.map((a) => {
			/* filtra terapia? */
			const filterTerapia = terapiasSelecionadaOpt?.length > 0;
			/* filtra especialidade? */
			const filterEspecialidade = especialidadesSelecionadaOpt?.length > 0;
			/* filtra terapeuta? */
			const filterTerapeuta = terapeutaSelecionado === null ? false : true;
			/* filtra paciente? */
			const filterPaciente = idPaciente === null ? false : true;

			/* Testa incidência de terapeuta */
			const terapeutaFiltro = filterTerapeuta ? a.terapeuta_id === terapeutaSelecionado : true;
			/* Testa incidência de paciente */
			const pacienteFiltro = filterPaciente ? a.paciente_id === idPaciente : true;
			/* Testa incidência de terapia */
			const inTerapias = filterTerapia ? terapiasSelecionadaOpt.some(terapia => terapia.value === a.terapia_id) : true;
			/* Testa incidência de especialidade */
			const inEspecialidade = filterEspecialidade ? especialidadesSelecionadaOpt.some(especialidade => especialidade.value === a.especialidade_id) : true;

			/* Variável que define no final se será filtrao, não filtrado ou sem filtros */
			let filtrado = (filterTerapia || filterEspecialidade || filterTerapeuta || filterPaciente)
				? (terapeutaFiltro && pacienteFiltro && inTerapias && inEspecialidade)
					? true : false
				: undefined;
			if (filtrado === undefined) {
				if (filtro !== '') {
					if (String(a.paciente_nome).toLowerCase().includes(String(filtro).toLowerCase()) ||
						String(a.paciente_identificador).toLowerCase().includes(String(filtro).toLowerCase())) {
						filtrado = true;
					}
				}
			}
			return ({ ...a, filtrado: filtrado });
		});
		createNewAgendamentos(agendamentosNovos);
		updateFilter();
	}
	/**
	 * Updates the filter based on the selected checkboxes.
	 * 
	 * @returns {Array<string>} An array of selected checkbox values.
	 */
	const updateFilter = () => {
		let fields = document.getElementsByName('salas[]');
		let checks = [];
		fields.forEach((x) => {
			if (x.checked) {
				checks.push(x.value);
			}
		});

		return checks;
	}
	/*==AGENDAMENTO==*/
	/**
	 * Function to delete an appointment.
	 * 
	 * @param {number} id - The ID of the appointment to be deleted.
	 * @returns {void}
	 */
	const excluirAgendamento = (id) => {
		Swal.fire({
			title: 'Tem certeza? (' + id + ')',
			text: "Você não poderá reverter isso!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Sim, excluir!'
		}).then((result) => {
			if (result.isConfirmed) {
				AgendamentoService.destroy(id).then(({ data }) => {
					atualizaSocket({ id: id, deleted_at: moment.utc().format('YYYY-MM-DD HH:mm:ss') });
					carregaAgendamentos(dataSave.format('YYYY-MM-DD'));
					getAgendaHorarios(diaAtual);
					Swal.fire(
						'Deletado!',
						'O agendamento foi deletado.',
						'success'
					);
				});
			}
		});
	}
	/*==MODAIS==*/
	/**
	 * Handles the opening of an appointment.
	 * 
	 * @param {string} hour - The hour of the appointment.
	 * @param {object} estacao - The station object.
	 * @param {string} idHorario - The ID of the schedule.
	 * @param {Event} e - The event object.
	 * @returns {void}
	 */
	const handleOpenAgendamento = (hour, estacao, idHorario, e) => {
		e.stopPropagation();
		if (permissoes['criacao_edicao']) {
			if (!mostrandoReserva) {
				setHour(hour);
				setEstacaoObj(estacao);
				setIsModalAdicionarAtendimentoVisible(true);
				setIdHorario(idHorario);
			}
		}
	}
	/**
	 * Opens the modal with the details of an appointment.
	 * 
	 * @param {Object} detalhes - The details of the appointment.
	 */
	const modalDetalhesAgendamento = (detalhes) => {
		setAgendamentoId(detalhes.id);
		setIsModalDetalhesDoAtendimentoVisible(true);
	}
	/**
	 * Opens a modal for editing an appointment.
	 *
	 * @param {Object} detalhes - The details of the appointment.
	 * @param {string} hour - The hour of the appointment.
	 * @param {Object} estacao - The station of the appointment.
	 * @param {string} idHorario - The ID of the appointment time.
	 * @param {string} reserva - The reservation of the appointment.
	 * @param {string} tipoAtendimento - The type of appointment.
	 */
	const modalEditar = (detalhes, hour, estacao, idHorario, reserva, tipoAtendimento) => {
		setAgendamentoId(detalhes.id);
		setHour(hour);
		setEstacaoObj(estacao);
		setIsModalAdicionarAtendimentoVisible(true);
		setIdHorario(idHorario);
		setTipoAtendimentoSelecionado(tipoAtendimento);
		setReservaSelecionada(reserva);
	}
	/*==GETTERS==*/
	/**
	 * Retrieves the agenda for the current day.
	 * 
	 * @returns {void}
	 */
	const getAgendaDia = () => {
		let params = 'with=sala.responsavel_salas.terapeutum,sala&';
		let filters = updateFilter();
		if (filters.length > 0) {
			params += 'filter[sala_id]=' + filters.join(',');
		}
		EstacaoService.index(params).then(({ data }) => {
			data.sort((a, b) => Number(a.sala.sala) < Number(b.sala.sala) ? -1 : Number(a.sala.sala) > Number(b.sala.sala) ? 1 : 0);
			setEstacaos(data);
			setSalasList(data.map((opt) => ({
				estacao_id: opt.id,
				sala_id: opt.sala_id,
				value: opt.id,
				label: `${opt.sala.sala} / ${opt.estacao}`,
			})))
			setEstacaosOriginal(data);
		})
	}
	/**
	 * Retrieves the agenda horarios for a given diaAtualLocal.
	 * @param {string} diaAtualLocal - The current local day.
	 */
	const getAgendaHorarios = (diaAtualLocal) => {
		api.get('/api/horario-atendimento?filter[dia_semana]=' + diaAtualLocal).then(({ data }) => {
			setHorarios(data);
		})
	}
	/**
	 * Creates new agendamentos based on the given data.
	 * 
	 * @param {Array} data - The data to create agendamentos from.
	 * @returns {void}
	 */
	const createNewAgendamentos = (data) => {
		let pacientes = [];
		let newAgendamentos = [];
		for (let index = 0; index < data.length; index++) {
			const atendimento = data[index];
			if (pacientes.filter(filter => filter.value === atendimento?.paciente_id).length <= 0) {
				pacientes.push({
					value: atendimento?.paciente_id,
					nome: atendimento?.paciente_nome,
					identificador: atendimento?.paciente_identificador,
					label: `(${atendimento?.paciente_identificador}) ${atendimento?.paciente_nome}`
				});
			}
			if (!newAgendamentos[atendimento.estacao_id]) {
				newAgendamentos[atendimento.estacao_id] = {};
			}
			if (!newAgendamentos[atendimento.estacao_id][atendimento.horarios_atendimento_id]) {
				newAgendamentos[atendimento.estacao_id][atendimento.horarios_atendimento_id] = [];
			}
			newAgendamentos[atendimento.estacao_id][atendimento.horarios_atendimento_id].push(
				(atendimento.prop && atendimento.prop.constructor === Array)
					? atendimento.reverse()
					: atendimento
			);
		}
		setPacientesList(pacientes);
		setAgendamentos(data);
		setNewAgendamentos(newAgendamentos);
	}

	/**
	 * Loads appointments based on a given date.
	 * 
	 * @param {Date} dataSaveLocal - The date to load appointments for.
	 * @returns {Promise<void>} - A promise that resolves when the appointments are loaded.
	 */
	const carregaAgendamentos = async (dataSaveLocal) => {
		setloading(true);
		let day = moment(dataSaveLocal).day();
		AgendamentoService.getByWeekDay(day, 'terapia_paciente,terapia_paciente.plano_saude,estacao', 'terapia_paciente.plano_saude_id,plano_saude.nome_reduzido,plano_saude.limite_diario_terapias')
			.then(({ data }) => {
				createNewAgendamentos(data);
				setloading(false);
			});
	}
	/*==FUNÇÕES DE ATUALIZAÇÃO==*/
	/**
	 * Updates the socket with the provided data.
	 * @param {any} data - The data to be sent through the socket.
	 */
	const atualizaSocket = (data) => {
		if (data) {
			socket.send(JSON.stringify(data));
		}
	}
	/**
	 * Updates the appointment.
	 *
	 * @param {Object} agendamento - The appointment object to be updated.
	 * @returns {void}
	 */
	const atualizaAgendamento = (agendamento) => {
		setAlgoCarregando(true);
		try {
			// Crie uma cópia do estado atual
			let newAgendamentosLocal = [...agendamentos];
			if (agendamento.droped === true) {
				let index = newAgendamentosLocal.findIndex((a) => a.id === agendamento.id);
				if (index !== -1) {
					newAgendamentosLocal.splice(index, 1);
				}
			}
			let index = newAgendamentosLocal.findIndex((a) => a.id === agendamento.id);
			if (agendamento.deleted_at) {
				if (index !== -1) {
					newAgendamentosLocal.splice(index, 1);
				}
			} else {
				if (index !== -1) {
					newAgendamentosLocal[index] = agendamento;
				} else {
					newAgendamentosLocal.push(agendamento);
				}
			}
			// Atualize o estado com o novo array de agendamentos
			setSocketAgendamento(null);
			setAgendamentos(newAgendamentosLocal);
			createNewAgendamentos(newAgendamentosLocal);
		} catch (error) {
			console.error(error);
		}
		setAlgoCarregando(false);
	};
	/**
	 * Updates the component data.
	 * 
	 * @param {string|null} diaAtualLocal - The current day in local time. If null, it will be set to the value of `dataSaveLocal`.
	 * @param {string|null} dataSaveLocal - The saved data in local time. If null, it will be set to the value of `dataSave`.
	 * @returns {Promise<void>} - A promise that resolves when the component data is updated.
	 */
	const atualizaComponenteData = async (diaAtualLocal = null, dataSaveLocal = null) => {
		setAlgoCarregando(true);
		try {
			if (diaAtualLocal === null && dataSaveLocal === null) {
				dataSaveLocal = moment(dataSave);
				diaAtualLocal = dataSaveLocal.format('e');
			}
			setDiaAtual(diaAtualLocal);
			setDataAtual(dataSaveLocal);
			setDataSave(dataSaveLocal);
			setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
			carregaAgendamentos(dataSaveLocal.format('YYYY-MM-DD'));
			getAgendaHorarios(diaAtualLocal);
		} catch (error) {
			snackbar.displayMessage('Erro ao atualizar componente.', 'error');
			console.error(error);
		}
		setAlgoCarregando(false);
	};
	//#endregion
	//#region HANDLES
	/**
	 * Handles the drop event for a therapist's schedule grid.
	 * 
	 * @param {Object} antigaGridPos - The previous grid position.
	 * @param {Object} novaGridPos - The new grid position.
	 * @param {Object} content - The content being dropped.
	 * @returns {Promise<boolean>} - A promise that resolves to true if the drop event was successful, or false otherwise.
	 */
	const handleDrop = async (antigaGridPos, novaGridPos, content) => {
		setAlgoCarregando(true);
		try {
			const hora = horarios.filter((filter) => {
				const newHour = moment.utc(filter.horario);
				return newHour.format('HH:mm').includes(novaGridPos.row);
			})[0];
			const hora_id = hora.id
			const data_inicio = moment.utc(hora.horario).format('YYYY-MM-DD HH:mm:ss') + '.00';
			const data_fim = moment.utc(hora.horario).add(40, 'minutes').format('YYYY-MM-DD HH:mm:ss') + '.00';
			const newObj = {
				...content,
				data_atendimento_inicial: data_inicio,
				data_atendimento_final: data_fim,
				estacao_id: novaGridPos.col,
				horarios_atendimento_id: hora_id
			}
			const result = api.patch('api/agendamento/' + content.id, newObj).then((response) => {
				if (response.status === 200) {
					snackbar.displayMessage('Alterado local e hora do agendamento com sucesso!', 'success');
					atualizaAgendamento({ ...newObj, droped: true }, true);
					atualizaSocket({ ...newObj, droped: true });
					return true;
				}
			}).catch((error) => {
				snackbar.displayMessage('Erro ao alterar o horario ou local do agendamento', 'error');
				console.error(error);
			}).finally(() => {
				setAlgoCarregando(false);
			});
			return result;
		} catch (error) {
			console.error(error);
			snackbar.displayMessage('Erro ao alterar o horario ou local do agendamento', 'error');
			setAlgoCarregando(false);
			return false;
		}
	}
	/**
	 * Handles the click event for the next day button.
	 * 
	 * @param {Date} oldDataSave - The old data save value.
	 */
	const handleClickNextDay = (oldDataSave) => {
		const localDataSave = oldDataSave ? oldDataSave : dataSave;
		let dataSaveLocal = moment(localDataSave).add(1, 'days');
		if (dataSaveLocal.day() === 0) {
			dataSaveLocal = moment(dataSave).add(2, 'days');
		}
		let diaAtualLocal = dataSaveLocal.format('e');
		atualizaComponenteData(diaAtualLocal, dataSaveLocal);
	};
	/**
	 * Handles the click event for the previous day button.
	 * 
	 * @param {Date} oldDataSave - The old data save value.
	 */
	const handleClickPrevDay = (oldDataSave) => {
		const localDataSave = oldDataSave ? oldDataSave : dataSave;
		let dataSaveLocal = moment(localDataSave).subtract(1, 'days');
		if (dataSaveLocal.day() === 0) {
			dataSaveLocal = moment(dataSave).subtract(2, 'days');
		}
		let diaAtualLocal = dataSaveLocal.format('e');
		atualizaComponenteData(diaAtualLocal, dataSaveLocal);
	};

	/**
	 * Synchronizes the scroll position of a source element with one or two target elements.
	 * 
	 * @param {RefObject} source - The source element to synchronize scroll position from.
	 * @param {RefObject} targetUp - The target element to synchronize vertical scroll position to. Can be null.
	 * @param {RefObject} targetLeft - The target element to synchronize horizontal scroll position to. Can be null.
	 */
	const syncScroll = (source, targetUp, targetLeft) => {
		source = source.current;
		targetUp = targetUp != null ? targetUp.current : null;
		targetLeft = targetLeft != null ? targetLeft.current : null;
		if (targetUp != null)
			targetUp.scrollTop = source.scrollTop;
		if (targetLeft != null)
			targetLeft.scrollLeft = source.scrollLeft;
	};
	/**
	 * Handles the export of the component to an image.
	 */
	const handleExportToImage = () => {
		if (elementRef.current === null) {
			return;
		}
		elementRef.current.classList.add('overflow-y-no-scroll');
		elementRef.current.classList.add('bg-branco');
		elementRef.current.classList.add('w-content-i');
		elementRef.current.classList.add('h-content-i');
		agendamentoTableBodyRef.current.classList.add('w-content-i');
		agendamentoTableBodyRef.current.classList.add('h-content-i');
		agendamentoTableBodyRef.current.classList.add('max-w-content-i');
		estacaoTableHeadRef.current.classList.add('max-w-content-i');
		estacaoTableHeadRef.current.classList.add('w-content-i');
		estacaoTableHeadRef.current.classList.add('h-content-i');
		horaTableBodyRef.current.classList.add('h-content-i');
		estacaoTableRef.current.classList.add('w-content-i');
		estacaoTableRef.current.classList.add('h-content-i');
		toPng(elementRef.current)
			.then((dataUrl) => {
				const link = document.createElement('a');
				link.download = `agenda-${esperaMode ? 'espera-' : ''}${dataSave.format('DD_MM_YYYY-HH_MM')}.jpeg`;
				link.href = dataUrl;
				link.click();
			})
			.catch((err) => {
				console.error('Error generating image:', err);
			})
			.finally(() => {
				elementRef.current.classList.remove('overflow-y-no-scroll');
				elementRef.current.classList.remove('bg-branco');
				elementRef.current.classList.remove('w-content-i');
				elementRef.current.classList.remove('h-content-i');
				agendamentoTableBodyRef.current.classList.remove('w-content-i');
				agendamentoTableBodyRef.current.classList.remove('h-content-i');
				agendamentoTableBodyRef.current.classList.remove('max-w-content-i');
				estacaoTableHeadRef.current.classList.remove('max-w-content-i');
				estacaoTableHeadRef.current.classList.remove('w-content-i');
				estacaoTableHeadRef.current.classList.remove('h-content-i');
				horaTableBodyRef.current.classList.remove('h-content-i');
				estacaoTableRef.current.classList.remove('w-content-i');
				estacaoTableRef.current.classList.remove('h-content-i');
			});
	};
	/**
	 * Handles the change of the modal visibility for the legend.
	 */
	const handleChangeModalLegenda = () => {
		setIsModalLegendaVisible((isModalLegendaVisible) => !isModalLegendaVisible);
	}
	/**
	 * Handles the change of the esperaMode state.
	 */
	const handleChangeEsperaMode = () => {
		setEsperaMode((esperaMode) => !esperaMode);
	}
	//#region USE EFFECT
	useEffect(() => {
		if (isModalAdicionarAtendimentoVisible) {
			if (fullScreenState) {
				exitFullScreen();
			}
		} else {
			if (fullScreenState) {
				enterFullscreen();
			}
		}
	}, [isModalAdicionarAtendimentoVisible]);
	useEffect(() => {
		setStyles(
			esperaMode
				? fullScreenState
					? stylesEsperaFullscreen : stylesEspera
				: fullScreenState
					? stylesNormalFullscreen
					: stylesNormal
		);
	}, [esperaMode, fullScreenState]);
	useEffect(() => {
		if (socketAgendamento) {
			atualizaAgendamento(socketAgendamento);
		}
	}, [socketAgendamento]);
	useEffect(() => {
		if (!houveExclusaoAgendamento) return;
		getAgendaDia();
		setHouveExclusaoAgendamento(false);
	}, [houveExclusaoAgendamento]);
	useEffect(() => {
		filtrar();
		if (salaSelecionadaOpt?.length > 0) {
			let estacoesFiltradas = estacaosOriginal.filter((e) => {
				let sala = e.id;
				let result = salaSelecionadaOpt.filter((filtro) => filtro.estacao_id === sala)?.length > 0;
				return result;
			});
			setEstacaos(estacoesFiltradas);
		} else {
			setEstacaos(estacaosOriginal);
		}
	}, [pacienteSelecionadoOpt, salaSelecionadaOpt, terapiasSelecionadaOpt, especialidadesSelecionadaOpt, filtroIncidencia, terapeutaSelecionado]);
	/**
	 * Function called when the component is loaded.
	 * It collects the lists, gets the agenda for the current day,
	 * loads the appointments for the selected date.
	 */
	function onload() {
		/* COLETA DAS LISTAS */
		getAgendaDia();
		getAgendaHorarios(diaAtual);
		carregaAgendamentos(dataSelecionada);
	}
	/* WEBSOCKET */
	useEffect(() => {
		if (!socket) {
			let urlWS;
			if (window.location.hostname === 'localhost') {
				urlWS = 'ws://localhost:6001/ws/agenda';
			} else {
				urlWS = 'wss://sistema.neurointensiva.com/ws/agenda';
			}
			const socketLocal = new WebSocket(urlWS);
			setSocket(socketLocal);

			socketLocal.addEventListener('open', function () {
				setSocketState(true);
				//console.info('Conectado ao servidor');
			});

			socketLocal.addEventListener('close', function () {
				setSocketState(false);
				//console.info('Desconectado do servidor');
			});

			socketLocal.addEventListener('message', function (event) {
				try {
					if (event.data !== null && event.data !== undefined && event.data !== '') {
						const data = (JSON.parse(event.data));
						setSocketAgendamento(data);
					}
				} catch (error) {
					console.error('Erro ao parsear a mensagem: ', error);
				}
			});

			socketLocal.addEventListener('error', function (event) {
				console.error('Erro: ', event);
			});
		}
	}, [socket]);
	useEffect(() => {
		const handleAtalhos = (e) => {
			if (e.key === 'Escape') {
				setfullScreenState(false);
				setExpand(false);
			}
		};
		fetchTerapias();
		fetchEspecialidades();
		fetchTipoAtendimentosData();
		fetchModalidadeData();
		document.addEventListener('keydown', handleAtalhos);
		document.addEventListener('mousedown', (e) => {
			fechaFiltro(e);
		});
		onload();
		return () => {
			document.removeEventListener('mousedown', (e) => {
				fechaFiltro(e);
			});
			document.removeEventListener('keydown', handleAtalhos);
		};
	}, []);
	/**
	 * Filters the option for the paciente based on the inputValue.
	 * 
	 * @param {object} option - The option to be filtered.
	 * @param {string} inputValue - The input value to filter the option.
	 * @returns {boolean} - Returns true if the option should be included in the filtered list, otherwise false.
	 */
	const filterOptionPaciente = (option, inputValue) => {
		if (!inputValue) return true;

		const lowerInputValue = inputValue.toLowerCase();
		const identificador = option.data.identificador.toLowerCase();
		const nome = option.data.nome.toLowerCase();
		const identificadorResult = identificador.includes(lowerInputValue);

		if (pacientesList.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
			return identificadorResult;
		}
		return nome.includes(lowerInputValue);
	};
	//#endregion
	//#region HTML
	return (
		<Container>
			<div style={styles.containerFlexColumn}>
				<div ref={tableRef} style={{ backgroundColor: '#f6f6f9 !important', maxWidth: '100vw' }}>
					<div style={styles.tr0}>
						<div ref={filtroDivRef} className='div-filter-style justify-content-start align-center' style={styles.filtro}>
							<div id="div-expand-filter" className="w-100per pointer d-flex justify-between align-center" style={{ height: '40px' }}
								onClick={({ target }) => {
									if (target === document.getElementById('div-expand-filter')) {
										expandFiltro()
									}
								}}>
								<div id="blank" style={{ height: 'fit-content' }} onClick={(e) => expandFiltro()}>
									{!expand ? 'Expandir' : 'Fechar'} Filtro
								</div>
								<div className='flex-nowrap'>
									{pacienteSelecionadoOpt?.value !== undefined || terapiasSelecionadaOpt?.length > 0 || especialidadesSelecionadaOpt?.length > 0 || salaSelecionadaOpt?.length > 0 || filtroIncidencia
										? <FaBroom color="yellow" id="botao-limpa-filtro" size={22} onClick={() => { handleLimpaFiltros() }} title='Conteúdo filtrado...' />
										: <div width='22' height='22'></div>
									}
									<AiFillCaretDown onClick={(e) => expandFiltro()} className='pointer' color="white" size={22} style={{ marginLeft: '15px' }} />
								</div>
							</div>

							<Form className='div-expand-over' style={{ backgroundColor: 'rgb(149, 134, 172)', width: '50vw', marginLeft: '-16px', ...expand ? {} : { display: 'none' } }}>
								<div style={DivContentDisplay} className='d-flex flex-col'>
									<div className='row w-100per'>
										<div className="col-12">
											<label className="label-filtro">Paciente:</label>
											<div className="d-flex justify-around align-center">
												<Select
													name={'pacientes'}
													options={pacientesList}
													value={pacienteSelecionadoOpt}
													filterOption={filterOptionPaciente}
													onChange={(e) => {
														setPacienteSelecionadoOpt({ value: e.value, label: e.label })
													}} />
												<AiOutlineClear className="pointer m-2" onClick={(e) => {
													setPacienteSelecionadoOpt([]);

												}} size={25} />
											</div>
										</div>
										<div className="col-12">
											<label className="label-filtro">Salas:</label>
											<div className="d-flex justify-around align-center">
												<Select
													name={'salas'}
													options={salasList}
													value={salaSelecionadaOpt}
													onChange={(e) => {
														setSalaSelecionadaOpt(e)
													}}
													isMulti={true} />
												<AiOutlineClear className="pointer m-2" onClick={(e) => {
													setSalaSelecionadaOpt([]);
												}} size={25} />
											</div>
										</div>
										<div className="col-12">
											<label className="label-filtro">Terapias:</label>
											<div className="d-flex justify-around align-center">
												<Select
													name={'terapias'}
													options={terapiasOptions}
													value={terapiasSelecionadaOpt}
													onChange={(e) => {
														setTerapiasSelecionadaOpt(e)
													}}
													isMulti={true} />
												<AiOutlineClear className="pointer m-2" onClick={(e) => {
													setTerapiasSelecionadaOpt([]);
												}} size={25} />
											</div>
										</div>
										<div className="col-12">
											<label className="label-filtro">Especialidades:</label>
											<div className="d-flex justify-around align-center">
												<Select
													name={'especialidades'}
													options={especialidadesOptions}
													value={especialidadesSelecionadaOpt}
													onChange={(e) => {
														setEspecialidadesSelecionadaOpt(e)
													}}
													isMulti={true} />
												<AiOutlineClear className="pointer m-2" onClick={(e) => {
													setEspecialidadesSelecionadaOpt([]);
												}} size={25} />
											</div>
										</div>
										<div className="col-12">
											<label className="label-filtro">Pesquisar:</label>
											<div className="d-flex justify-around align-center">
												<Input
													name={'nome'}
													value={filtroIncidencia}
													onChange={(e) => {
														setFiltroIncidencia(e.target.value);
														setPacienteSelecionadoOpt([]);
														setSalaSelecionadaOpt([]);
													}}
													placeholder={'Digite os termos da pesquisa(Identificador, nome)...'} />
												<AiOutlineClear className="pointer m-2" onClick={(e) => setFiltroIncidencia('')} size={25} />
											</div>
										</div>
									</div>
								</div>
							</Form>
						</div>
						<div className='p-1 justify-content-around d-flex align-center' style={{ width: '300px' }}>
							<AiOutlineLeft className='pointer' color="black" size={30} onClick={() => handleClickPrevDay()} />
							<span className='texto-navegacao' title={`${dataAtual.format('DD/MM/YYYY')}`}>{`${dayName[dataAtual.day()]}`}</span>
							<AiOutlineRight className='pointer' color="black" size={30} onClick={() => handleClickNextDay()} />
						</div>
						<div className='d-inline-flex justify-content-end align-center' style={{ width: '400px' }}>
							{/* SWITCH AGENDA ESPERA */}
							<div className="form-check form-switch me-2">
								<input className="form-check-input" type="checkbox" role="switch" id="esperaCheck" checked={esperaMode} onChange={() => handleChangeEsperaMode()} />
								<label className="form-check-label" htmlFor="esperaCheck">Agenda Espera</label>
							</div>
							{/* BOTÃO EXPORT IMAGEM */}
							<BsDownload className='pointer' size={30} onClick={() => handleExportToImage()} style={{ marginRight: '10px' }} />
							{/* BOTÃO ATUALIZA AGENDA */}
							<button type='button' className={`btn-outline roda-hover pointer mr-10px`} onClick={() => atualizaComponenteData()} >
								<FiRefreshCcw className={`font-roxo-claro3${algoCarregando ? ' rodando' : ''}`} size={30} />
							</button>
							{/* BOTÃO LEGENDA */}
							<button onClick={() => handleChangeModalLegenda()} className='btn-outline' title='Legenda dos tipos de atendimento por cor.' style={{ marginRight: '10px' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" fill="none" stroke="rgb(149, 134, 172)" strokeWidth="2" />
									<text x="12" y="17" textAnchor="middle" fontSize="14" fill="rgb(149, 134, 172)" fontWeight='bold'>?</text>
								</svg>
							</button>
							{/* BOTÃO FULLSCREEN */}
							<button onClick={(e) => fullScreen()}>
								<BsFullscreen size={25} color='rgb(149, 134, 172)' stroke="rgb(149, 134, 172)" strokeWidth="2" fontWeight='bold' fontSize='14' />
							</button>
						</div>
					</div>
					<div ref={elementRef} id='div-agenda' className='d-inline-flex'>
						<table className='table-agenda-terapeuta' id='label-e-horas' style={styles.table}>
							<thead style={styles.thead}>
								{/* ETIQUETAS */}
								<tr key={'linha-salas'} style={styles.thead.trS}>
									<th key={'coluna-salas'} style={styles.tr1}>Salas</th>
								</tr>
								<tr key={'linha-estacoes'} style={styles.thead.trE}>
									<th key={'coluna-estacoes'} style={styles.tr1}>Estações</th>
								</tr>
								<tr key={'linha-responsaveis'} style={styles.thead.trR}>
									<th key={'coluna-responsaveis'} style={styles.tr1}>Resp.</th>
								</tr>
							</thead>
							{/* HORAS */}
							<tbody ref={horaTableBodyRef} style={styles.tbody0} onScrollCapture={(e) => { syncScroll(horaTableBodyRef, agendamentoTableBodyRef, null) }}>
								{horarios.map((horario, index) => {
									let tarde = (
										Number(horario.horario.split('T')[1].split(':')[0]) >= 17
										&&
										(Number(horario.horario.split('T')[1].split(':')[0]) !== 17 || (Number(horario.horario.split('T')[1].split(':')[0]) === 17 && Number(horario.horario.split('T')[1].split(':')[1]) >= 40))
									);
									return (
										<tr key={'linha-horario-' + index} style={styles.tbody.tr}>
											<td key={'coluna-horario-' + index} style={tarde ? { ...styles.trHora, ...styles.tarde } : { ...styles.trHora }} align={'center'}>{moment.utc(horario.horario).format('HH:mm')}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<table ref={estacaoTableRef} className='table-agenda-terapeuta' id='salas-e-agendamentos' style={styles.table}>
							<thead ref={estacaoTableHeadRef} style={styles.thead} onScrollCapture={(e) => syncScroll(estacaoTableHeadRef, null, agendamentoTableBodyRef)}>
								{/* SALAS */}
								<tr key={'linha-sala-cont'} style={styles.thead.tr}>
									{estacaos.map((estacao, index) => {
										return <td key={'coluna-sala-cont-' + index} align={'center'} style={styles.tr2} className='font-14px' >{estacao.sala.sala}</td>;
									})}
								</tr>
								{/* ESTAÇÕES */}
								<tr key={'linha-estacao-cont'} style={styles.thead.tr}>
									{estacaos.map((estacao, index) => {
										return <td key={'coluna-estacao-cont-' + index} align={'center'} style={styles.tr2} className='font-14px' >{estacao.estacao}</td>;
									})}
								</tr>
								{/* RESPONSÁVEIS */}
								<tr key={'linha-responsavel-cont'} style={styles.thead.tr}>
									{estacaos.map((estacao, index) => {
										return (estacao.sala.responsavel_salas.length > 0
											? <td key={'coluna-responsavel-cont-' + index} align={'center'} style={{ ...styles.tr2, height: '55px' }} className='font-14px'>{estacao.sala.responsavel_salas.map(responsaveis => responsaveis.terapeutum.nome.substring(0, 15) + (responsaveis.terapeutum.nome.length > 15 ? '...' : '')).join(', ')}</td>
											: <td key={'coluna-responsavel-cont-' + index} align={'center'} style={{ ...styles.tr2, height: '55px' }} className='font-14px'>Sem responsável</td>
										)
									})}
								</tr>
							</thead>
							{/* AGENDAMENTOS */}
							<tbody ref={agendamentoTableBodyRef} style={styles.tbody} onScrollCapture={(e) => { syncScroll(agendamentoTableBodyRef, horaTableBodyRef, estacaoTableHeadRef) }}>
								{loading === false
									? horarios.map((horario, index) => {
										let horaInteira = moment.utc(horario.horario);
										let hora = moment.utc(horario.horario).format('HH:mm');
										let returns;
										const idHorario = horario.id;
										returns = (
											<tr key={'linha-agendamento-' + index} style={styles.tbody.tr}>
												{estacaos.map((estacao, index2) => {
													//#region VARIAVEIS
													const localEstacao = estacao;
													let agendamento =
														(newAgendamentos[localEstacao.id] !== undefined)
															? (newAgendamentos[localEstacao.id][horario.id] !== undefined)
																? newAgendamentos[localEstacao.id][horario.id]
																: null
															: null;
													let reservaNoTitulo = [];
													let titulo;
													let substituicaoNoTitulo = [];
													let tooltipInfo = <>
														Sala/Estação: {localEstacao.sala.sala} {localEstacao.estacao} ({localEstacao.id})<br></br>
														Hora: {hora} ({horario.id})
													</>;
													let div2styleAgenda;
													let agendaContent = null;
													let updateContent;
													//#endregion
													//#region FUNCOES
													const geraDropDownAgenda = (dropdownAgenda, tipo) => {
														return dropdownAgenda.map((a) => {
															//VARS\\
															const legenda = getLegendaAgendamento(a, agendamentos);
															let horaFinalDiferente;
															let horaFinal;
															if (a.data_atendimento_final) {
																if (a.data_atendimento_final.includes('T')) {
																	horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
																	horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
																} else {
																	horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
																	horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
																}
															}
															horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
															let div2style = {
																cursor: 'default',
																marginTop: '0px',
																...styles.trReserva
															};

															if (a.filtrado === undefined) {
																div2style = {
																	...div2style,
																	backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
																	...styles[legenda],
																};
															} else if (a.filtrado === true) {
																div2style = {
																	...div2style,
																	backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
																	...styles_filtred[legenda],
																	...filtered,
																};
															} else if (a.filtrado === false) {
																div2style = {
																	...div2style,
																	backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
																	...styles_escuro[legenda],
																};
															}

															// Ajustes específicos para reserva e substituição
															if (tipo === 'substituicao') {
																div2style = {
																	...div2style,
																	backgroundColor: a.tipo_atendimento_id === 7 ? 'grey' : 'rgb(225, 111, 242)',
																};
															}

															return (
																<div style={styles.agendamentoExtras} key={a.id}>
																	<Dropdown
																		substituicao={tipo !== 'reserva'}
																		reserva={tipo === 'reserva'}
																		setMostrando={setMostrandoReserva}
																		button={
																			<svg
																				className='p-0 m-0'
																				style={{
																					...styles.extraIcon,
																					...(tipo === 'reserva'
																						? styles.extraIcon.reserva
																						: (a.tipo_atendimento_id === 7)
																							? styles.extraIcon.substituicaoFeito
																							: styles.extraIcon.substituicao
																					)
																				}}
																				id={`${tipo === 'reserva' ? 'agendamento-reserva-icone' : 'agendamento-substituicao-icone'}${a.id}`}
																				width="15"
																				height="15"
																				fill="currentColor"
																			>
																				<polygon points="0,0 15,0 0,15" />
																			</svg>
																		}
																		content={
																			<div id={`${tipo === 'reserva' ? 'agendamento-reserva' : 'agendamento-substituicao'}${a.id}`} style={div2style}>
																				<div className={horaFinalDiferente ? 'd-flex h-100per flex-wrap p-1' : 'd-flex h-100per align-content-between flex-wrap'}>
																					<div className='col-12' title={a.paciente_nome} style={a.paciente_status === 'Ferias' ? { color: '#FF0000', fontWeight: '700' } : {}}>
																						{a.paciente_identificador}
																					</div>
																					<div className='col-12 d-flex justify-content-between align-items-center'>
																						<AiFillEye className='pointer h-azul' title='Detalhes do agendamento.' onClick={() => modalDetalhesAgendamento(a)} />
																						{permissoes['criacao_edicao'] && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
																							<AiFillEdit className='pointer h-azul' title='Editar agendamento.' onClick={() => modalEditar(a, horaInteira, localEstacao, idHorario, a.reserva, a.tipo_atendimento_id)} />
																						}
																						{permissoes['exclusão'] &&
																							<BsTrashFill className='pointer h-azul' title={'Excluir agendamento.' + a.id} onClick={() => excluirAgendamento(a.id)} />
																						}
																						{permissoes['criacao_edicao'] && legenda === 'reserva' &&
																							<BsCheck size={22} className='pointer h-azul' onClick={() => aprovaReserva(a)} title='Aprova reserva como atendimento.' />
																						}
																					</div>
																					<div className='col-12' onClick={() => { console.log(a.terapeuta_id); setTerapeutaSelecionado(a.terapeuta_id) }} style={a.terapeuta_status === 'Ferias' ? { color: '#FF0000', fontWeight: '700' } : {}}>
																						{a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 10) : 'Sem Terapeuta'}.
																					</div>
																					{!horaFinalDiferente &&
																						<div className='hora-diferente d-inline-flex' title={horaFinal}>
																							Hora Final: {horaFinal}
																						</div>
																					}
																				</div>
																			</div>
																		}
																	/>
																</div>
															);
														});
													};
													//#endregion
													if (agendamento !== null && agendamento.length > 0) {
														// eslint-disable-next-line eqeqeq
														const agenda = agendamento.filter((a) => {
															const reserva = a.reserva === 0
															const tipo = a.tipo_atendimento_id !== 5 && a.tipo_atendimento_id !== 7
															const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
															return reserva && tipo && espera;
														});
														if (agenda.length > 0) {
															agendaContent = agenda.map((a) => {
																updateContent = a;
																//VARS\\
																const legenda = getLegendaAgendamento(a, agendamentos);
																let horaFinalDiferente;
																let horaFinal;
																if (a.data_atendimento_final) {
																	if (a.data_atendimento_final.includes('T')) {
																		horaFinalDiferente = String(a.data_atendimento_final).split('T')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
																		horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split('T')[1].split('.')[0].split(':') : null;
																	} else {
																		horaFinalDiferente = String(a.data_atendimento_final).split(' ')[1].split('.')[0] === moment.utc(a.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm:ss');
																		horaFinal = a.data_atendimento_final ? String(a.data_atendimento_final).split(' ')[1].split('.')[0].split(':') : null;
																	}
																}
																horaFinal = horaFinal ? horaFinal[0] + ':' + horaFinal[1] : '00:00';
																div2styleAgenda = {
																	cursor: 'default',
																	...styles.trOcupado,
																};
																if (a.filtrado === undefined) {
																	div2styleAgenda = {
																		...div2styleAgenda,
																		...styles[legenda],
																	};
																} else if (a.filtrado === true) {
																	div2styleAgenda = {
																		...div2styleAgenda,
																		...styles_filtred[legenda],
																		...filtered,
																	};
																} else if (a.filtrado === false) {
																	div2styleAgenda = {
																		...div2styleAgenda,
																		...styles_escuro[legenda],
																	};
																}
																tooltipInfo = <>
																	{(legenda === 'conflitoTerapeuta' || legenda === 'conflitoPaciente' || legenda === 'conflitoTerapeutaSala' || legenda === 'duplicado') &&
																		<h5>{ChangeCase.toTitleCase(legenda)}</h5>
																	}
																	Sala/Estação: {localEstacao.sala.sala} / {localEstacao.estacao} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${localEstacao.id})`}<br></br>
																	Paciente: {a.paciente_nome} {a.paciente_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.paciente_id})`}<br></br>
																	Terapeuta: {a.terapeuta_nome} {a.terapeuta_status === 'Ferias' ? ' - Em férias' : ''} {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${a.terapeuta_id})`}<br></br>
																	Horário: {hora}  {usuario?.user?.tipo_user?.toUpperCase() === 'DESENVOLVEDOR' && `(${horario.id})`}
																</>
																titulo = <p className='pointer' onClick={() => setPacienteSelecionadoOpt({ value: a.paciente_id, label: a.paciente_nome })} style={a.paciente_status === 'Ferias' ? { color: '#FF0000', fontWeight: '700' } : {}}>
																	{a.paciente_identificador}
																</p>;

																return (
																	<>
																		<div className='col-12 d-flex justify-content-between align-items-center h-auto-i' id={'agendamento' + a.id}>
																			<AiFillEye className='pointer h-azul' title='Detalhes do agendamento.' onClick={() => modalDetalhesAgendamento(a)}></AiFillEye>
																			{permissoes['criacao_edicao'] && legenda !== 'reposicao_feita' && legenda !== 'substituicao_feita' &&
																				<AiFillEdit className='pointer h-azul' title='Editar agendamento.' onClick={() => modalEditar(a, horaInteira, localEstacao, idHorario, a.reserva, a.tipo_atendimento_id)}></AiFillEdit>
																			}
																			{permissoes['exclusão'] &&
																				<BsTrashFill className='pointer h-azul' title={'Excluir agendamento.' + a.id} onClick={() => excluirAgendamento(a.id)} />
																			}
																		</div>
																		<div className='col-12 h-auto-i pointer' onClick={(e) => { console.log(a.terapeuta_id); setTerapeutaSelecionado(a.terapeuta_id) }} style={a.terapeuta_status === 'Ferias' ? { color: '#FF0000', fontWeight: '700' } : {}}>
																			{a.terapeuta_nome_curto ? a.terapeuta_nome_curto.substr(0, 7) + '.' : '---'}
																		</div>
																		{!horaFinalDiferente &&
																			<div className='hora-diferente d-inline-flex' width={styles.width}>
																				Hora Final: {horaFinal}
																			</div>
																		}
																	</>
																)
															})
														}
														const agendaReserva = agendamento.filter((a) => {
															const reserva = a.reserva === 1
															const espera = esperaMode ? a.tipo_atendimento_id === 8 : a.tipo_atendimento_id !== 8;
															return reserva && espera;
														});
														if (agendaReserva.length > 0) {
															reservaNoTitulo = geraDropDownAgenda(agendaReserva, 'reserva');
														}
														const agendaSubstituicao = !esperaMode ? agendamento.filter((a) => {
															const substituicao = a.tipo_atendimento_id === 5 || a.tipo_atendimento_id === 7
															return substituicao;
														}) : [];
														if (agendaSubstituicao.length > 0) {
															substituicaoNoTitulo = geraDropDownAgenda(agendaSubstituicao, 'substituicao');
														}
													}
													/* FINAL RETURN */
													return (
														<td style={{ ...styles.trVazio, ...styles.tamanhoPadrao, cursor: permissoes['criacao_edicao'] ? 'pointer' : 'default' }}
															key={`linha-agendamento-${localEstacao.id}-${horario.id}`}
															id={index2 + '-last-' + index}
															data-tooltip-id={"tooltip-" + hora + "-" + localEstacao.id}
															onClick={(e) => { if (agendaContent === null) { !mostrandoReserva && handleOpenAgendamento(horaInteira, localEstacao, idHorario, e) } }}
														>
															<Tooltip
																id={"tooltip-" + hora + "-" + localEstacao.id}
																style={styles.agendamentoTooltip}
																place="top"
																effect="solid"
															>
																{tooltipInfo}
															</Tooltip>
															<DraggableItem
																onDrop={handleDrop}
																gridPos={{ col: localEstacao.id, row: hora }}
																scrollPosRef={agendamentoTableBodyRef}
																dragZoneStyle={{ borderRadius: '10px' }}
																draggableStyle={{ backgroundColor: 'white', borderRadius: '10px' }}
																hoveredStyle={{ backgroundColor: '#c7ecfc' }}
																updateContent={updateContent}
																draggableDragginStyle={{}}
																gridSize={{ width: stylesSize[0], height: stylesSize[1], }}
																controlledState={agendaContent ? true : false}
																blankContent={
																	<div className={'d-grid fade-in'} style={{ ...div2styleAgenda, ...styles.tamanhoPadrao }} id={index2 + '-last-' + index + '-div'}>
																		<div className='w-100per d-flex h-15px m-0 p-0'>
																			{reservaNoTitulo.length > 0 ? reservaNoTitulo : <div style={styles.agendamentoExtras}></div>}
																			<div style={styles.divTitulo}>{titulo}</div>
																			{substituicaoNoTitulo.length > 0 ? substituicaoNoTitulo : <div style={styles.agendamentoExtras}></div>}
																		</div>
																	</div>
																}
															>
																<div className={'d-grid fade-in'} style={{ ...div2styleAgenda, ...styles.tamanhoPadrao }} id={index2 + '-last-' + index + '-div'}>
																	<div className='w-100per d-flex h-15px m-0 p-0'>
																		{reservaNoTitulo.length > 0 ? reservaNoTitulo : <div style={styles.agendamentoExtras}></div>}
																		<div style={styles.divTitulo}>{titulo}</div>
																		{substituicaoNoTitulo.length > 0 ? substituicaoNoTitulo : <div style={styles.agendamentoExtras}></div>}
																	</div>
																	{agendaContent}
																</div>
															</DraggableItem>
														</td>
													);
												})}
											</tr>
										);
										return returns;
									})
									: <ClipLoader size={30} className='m-auto' />
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			{/*#region MODAIS */}
			{
				isModalAdicionarAtendimentoVisible
					? <AdicionarAgendamento
						onClose={(data) => {
							setIsModalAdicionarAtendimentoVisible(false);
							setAgendamentoId(null);
							setIdHorario(null);
							atualizaSocket(data);
						}}
						tiposAtendimentoOptions={tiposAtendimentoOptions}
						modalidadesOptions={modalidadesOptions}
						hour={hour}
						agendamentos={agendamentos}
						estacao={estacaoObj}
						reservaSelecionada={reservaSelecionada}
						tipoAtendimentoSelecionado={tipoAtendimentoSelecionado}
						agendamentoId={agendamentoId}
						idHorario={idHorario}
						date={dataSave.format('YYYY-MM-DD')}
						updateAgenda={() => { carregaAgendamentos(dataSave.format('YYYY-MM-DD')); getAgendaHorarios(diaAtual); }}
						esperaMode={esperaMode} />
					: (<></>)
			}

			{
				isModalLegendaVisible
					? <LegendaDefault onClose={() => handleChangeModalLegenda()} />
					: (<></>)
			}
			{
				isModalResponsavelVisible
					? <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />
					: (<></>)
			}
			{
				isModalDetalhesDoAtendimentoVisible
					? <DetalhesDoAgendamento agendamentoId={agendamentoId} agendaButtons={true} temDetalhes={false} onClose={() => { setIsModalDetalhesDoAtendimentoVisible(false); setAgendamentoId(null); }} />
					: ''
			}
			{/*#endregion */}
			<LiveBadge sincronizado={socketState} texto={false} />
		</Container >
	)
	//#endregion
}