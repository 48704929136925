import React, { useState, useEffect } from "react";
import { Modal, Container } from './styles'
import { FormField } from '../../styles'
import Swal from "sweetalert2";
import { Form } from '@unform/web'
import Select from "../../../../../components/Select";
import TextArea from "../../../../../components/TextArea";
import SolicitacaoMaterialService from "../../../../../services/SolicitacaoMaterialService";
import { useAuth } from "../../../../../context/auth";
import Input from "../../../../../components/Input";
import moment from "moment";
import { useRef } from "react";
import api from "../../../../../services/api";
import { useSnackbar } from "../../../../../context/snackbar";

export default function Materiais({ id2 = 'modal', onClose = () => { }, pacientes, terapias, especialidades, modalData }) {
    
    const [pacienteID, setPacienteID] = useState(null);
    const [terapiaID, setTerapiaId] = useState(null);
    const [userIdStatus, setUserIdStatus] = useState(1);
    const [especialidadeId, setEspecialidadeId] = useState(null);
    const [statusSolicitacaoId, setStatusSolicitacaoId] = useState(2);
    const [descricao, setDescricao] = useState();
    const [planoSaudeId, setPlanoSaudeId] = useState(1);
    const [tempoDeUso, setTempoDeUso] = useState(null);
    const [dataUtilizacao, setDataUtilizacao] = useState(null);

    const [terapiasBox, setTerapiasBox] = useState(null);
    const [especialidadesBox, setEspecialidadesBox] = useState(null);
    
    const userId = useAuth();
    const snackbar = useSnackbar();
    const formRef = useRef(null);

    const handleOutsideClick = (e) => {
        if (e.target.id === id2) onClose();
    }

    const handleChangePaciente = (e) => {
        getPacienteData(e.identificador);
        setPacienteID(e.value);
        setTerapiaId(null);
        setEspecialidadeId(null);
    };

    const handleTerapia = (e) => {
        setTerapiaId(e)
    };

    const handleEspecialidade = (e) => {
        setEspecialidadeId(e)
    };
    
    const handleDescricao = (e) => {
        setDescricao(e.value)
    };

    const getPacienteData = (id) => {
        api.get(`api/paciente?with=terapia_pacientes.terapium,terapia_pacientes.especialidade&filter[identificador]=${id}`).then(({data}) => {
            let terapiasList = [];
            let especialidadesList = [];
            if (data[0].terapia_pacientes.length > 0) {
                data[0].terapia_pacientes.forEach((terapia) => {
                    terapiasList.push({ value: terapia.terapium.id, label: terapia.terapium.terapia });
                    especialidadesList.push({ value: terapia.especialidade.id, label: terapia.especialidade.especialidade });
                });
                setTerapiasBox(terapiasList);
                setEspecialidadesBox(especialidadesList);  
            }
        });
    };

    const handleSubmit = async (form) => {
        if ( form.Descricao === "" || form.quantidade === "" || form.referencia === "" || form.nome_material === "") {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            if (userId !== undefined) {
                storeSolicitacaoMaterial(form);
                onClose();
                snackbar.displayMessage('Solicitação cadastrada com sucesso!', 'success');
            } else {
                Swal.fire('Ocorreu um erro ao cadastrar a solicitação!', '', 'error');
            };
        };
    }
    const storeSolicitacaoMaterial = async (form) => {
        let postData = {
            nome_material: form.nome_material,
            quantidade: form.quantidade,
            referencia: form.referencia,
            paciente_id: pacienteID,
            user_id: userId.user.id,
            descricao: form.Descricao,
            paciente_id_plano_saude: planoSaudeId,
            user_id_status: userIdStatus,
            status_solicitacao_id: statusSolicitacaoId,
        }
        api.post(`api/solicitacao-material`, postData).then(({ data }) => {
            
        }).catch(() => Swal.fire('Houve um problema ao fazer o cadastro!', '', 'error'));
    }

    const functionThatSubmitsForm = () => {
        formRef.current.submitForm();
    }

    useEffect(() => {
        if (terapias !== undefined && especialidades !== undefined) {
            setTerapiasBox(terapias);
            setEspecialidadesBox(especialidades);
        }
    }, [])

    return (
        <Modal id={id2} onClick={handleOutsideClick}>
            <Container style={{overflowY: "auto"}}>
                <div style={{position: "relative"}}>
                    <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
                    right: "5px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
                    x
                    </label>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Solicitação de Materiais</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <FormField>
                        {modalData === undefined ? <Select label="Paciente:" name="Paciente" options={pacientes} onChange={handleChangePaciente}/>
                        : <Input title={"Paciente:"} name="Paciente" defaultValue={modalData.paciente} readOnly={'readOnly'}/> }
                    </FormField>
                    <FormField>
                        <FormField>
                            {modalData === undefined ? 
                                <Input title={"Nome do Material:"} name="nome_material"/>
                                : <Input title={"Nome do Material:"} name="nome_material" defaultValue={modalData.nome_material} readOnly={'readOnly'}/>}
                            
                        </FormField>
                        <FormField>
                            {modalData === undefined ? 
                              <Input title={"Quantidade:"} name="quantidade"/> 
                            : <Input title={"Quantidade:"} name="quantidade" defaultValue={modalData.quantidade} readOnly={'readOnly'}/> }
                            
                        </FormField>
                    </FormField>
                    <FormField>
                        <FormField>
                            {modalData === undefined ? <Input title="Link/Loja de Referência:"  name="referencia"/>
                             : <Input title={"Link/Loja de Referência:"} name="referencia" defaultValue={modalData.data_da_utilizacao}readOnly={'readOnly'}/>}
                            
                        </FormField>
                    </FormField>
                    <FormField>
                        {modalData === undefined ? <TextArea title="Comentários Gerais:" name="Descricao" onChange={handleDescricao} /> : 
                        <TextArea title="Descrição:" name="Descricao" onChange={handleDescricao} 
                        defaultValue={modalData === undefined ? "" : modalData.descricao} readOnly={'readOnly'}/>}
                    </FormField>

                    {/* <FormField>
                        <AiOutlineCloudUpload color="#6A3BAF" size={36} />
                        <label>Anexar Arquivos</label>
                    </FormField> */}

                    <FormField style={{justifyContent: 'space-evenly'}}>
                    <button style={{ fontWeight: "600", fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                                    border: "none", borderRadius: 8, padding: "4px 40px"}} 
                            onClick={e => onClose()}>
                            Voltar
                    </button>
                    {modalData === undefined ? <button style={{
                        fontWeight: "600",
                        fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                        border: "none", borderRadius: 8, padding: "4px 40px"
                    }}onClick={e => functionThatSubmitsForm()}>Confirmar</button> : ""}
                    </FormField>
                </Form>
            </Container>
        </Modal>
    )
}