import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Input from "../../Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../context/snackbar';
import HorariosAtendimentoService from '../../../services/HorariosAtendimentoService';
import Select from '../../Select';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import { Button } from 'react-day-picker';
moment().utc();

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '40vw',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF"
    },
};

const HorarioAtendimentoModal = ({ modalIsOpen, setIsOpen, horarioAtendimento = null, callback }, ref) => {
    let subtitle;
    // eslint-disable-next-line no-unused-vars
    const description = 'Modal de configuração de horários de atendimento.';

    const dayOptions = [
        { value: 0, label: "Domingo" },
        { value: 1, label: "Segunda - Feira" },
        { value: 2, label: "Terça - Feira" },
        { value: 3, label: "Quarta - Feira" },
        { value: 4, label: "Quinta - Feira" },
        { value: 5, label: "Sexta - Feira" },
        { value: 6, label: "Sábado" }
    ];
    const [horario, setHorario] = useState("");
    const [dia_semana, setDiaSemana] = useState("");
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    function afterOpenModal() {
        subtitle.style.color = '#000';
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useEffect(() => {
        if (horarioAtendimento && horarioAtendimento.id) {
            setHorario(moment.utc(horarioAtendimento.horario).format('HH:mm'));
            let localListaSemana = dayOptions;
            setDiaSemana(localListaSemana.filter((e) => Number(e.value) === Number(horarioAtendimento.dia_semana)));
            setId(horarioAtendimento.id);
        } else {
            setHorario("");
            setId();
        }
    }, [horarioAtendimento]);

    async function handleSubmit() {
        Swal.fire({
            title: 'Realmente deseja alterar o registro selecionado?',
            html: 'Lembre-se de que esse processo não facilmente reversível, e pode impactar diretamente no agendamento. Tenha cuidado.',
            showConfirmButton: true,
            showDenyButton: true
        }).then((response) => {
            if (response.isConfirmed) {
                const data = {
                    dia_semana: dia_semana?.value,
                    horario: horario,
                    id: id
                }

                if (horarioAtendimento && horarioAtendimento.id)
                    return update(data, id)

                return store(data);
            }
        })
    }

    const store = async (data) => {
        try {
            setLoading(true);
            await HorariosAtendimentoService.store(data)
                .then(() => {
                    snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                })
            setHorario("");
            setIsOpen(false);
            callback();
        } catch (error) {
            console.error(error);
            Swal.fire('Houve um problema ao cadastrar!', '', 'warning');
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    }

    const update = async (data, id) => {
        try {
            setLoading(true);
            await HorariosAtendimentoService.update(data, id)
                .then(() => {
                    snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                })
            setHorario("");
            snackbar.displayMessage("Cadastro alterado com sucesso!", "success");
        } catch (error) {
            Swal.fire('Houve um erro ao cadastrar!', '', 'error');
            console.error(error)
        } finally {
            setLoading(false);
            setIsOpen(false);
            callback();
        }
    }


    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                appElement={document.getElementById('root')}
                transparent
            >
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold" }} ref={(_subtitle) => (subtitle = _subtitle)}>Especialidade</h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }} >
                        <div className='row'>
                            <div className='col-12 mb-2'>
                                <Input
                                    name={'horario'}
                                    title={'Horario:'}
                                    type={'time'}
                                    value={horario}
                                    required={'required'}
                                    onChange={(e) => setHorario(e.target.value)}
                                />
                            </div>
                            <div className="col-12 mt-2">
                                <Select
                                    id={'dia_semana'}
                                    name={'dia_semana'}
                                    value={dia_semana}
                                    onChange={setDiaSemana}
                                    options={dayOptions}>
                                </Select>
                            </div>
                        </div>
                        <Button className='btn-padrao' type="submit" disabled={loading}>
                            {loading
                                ? <ClipLoader size={22} />
                                : horarioAtendimento === null ? "Adicionar" : "Alterar"
                            }
                        </Button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(HorarioAtendimentoModal);