import React, { useMemo } from 'react';
import { AiOutlineDelete, AiOutlineDownload } from 'react-icons/ai';
import { TableDefault } from '../../../../components/TableDefault';
import moment from 'moment/moment';
import { useAuth } from '../../../../context/auth';
import DocumentoService from '../../../../services/DocumentoService';
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../context/snackbar';

export function TableDocumentosGerais({ source, deleteCallback = null, terapeutaId, setDocumentos }) {

    const data = React.useMemo(() => source, [source]);
    const documentoService = new DocumentoService('funcionario');

    const snackbar = useSnackbar();
    const { user } = useAuth();

    const handleDeleteDocumento = async (id) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (terapeutaId === null || terapeutaId === undefined) {
                    return '';
                } else {
                    documentoService.destroy(id, { id: id, documento_administrativo: id })
                        .then(() => deleteCallback && deleteCallback());
                }

            }
        });
    }

    /**
     * Downloads a document with the given ID.
     * 
     * @param {number} id - The ID of the document to download.
     * @returns {Promise<void>} - A promise that resolves when the document is downloaded.
     */
    const downloadDocumento = async (id) => {
        try {
            const download = (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;

                // Use o nome do arquivo do cabeçalho da resposta
                const contentType = response.headers['content-type'];
                const contentDisposition = response.headers['content-disposition'];
                let fileName;
                if (contentType && contentDisposition) {
                    const fileNameMatch = contentType.split('/');
                    const name = contentDisposition.replace('attachment; filename="', '').split('.')[0];
                    fileName = name + '.' + fileNameMatch[1];
                }

                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            }
            const response = await documentoService.download(id).then(response => response);
            download(response);
        } catch (error) {
            console.error(error);
            snackbar.displayMessage("Erro ao baixar documento", "error");
        }
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'nome',
            },
            {
                Header: 'Data',
                accessor: 'created_at', // accessor is the "key" in the data
                Cell: ({ cell }) => (
                    (<div>
                        {moment(cell.row.original.created_at).format("DD/MM/YYYY HH:ss")}
                    </div>
                    )
                ),
            },
            {
                Header: 'Opções',
                accessor: 'name',
                Cell: ({ cell }) => (
                    (
                        <>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <AiOutlineDownload
                                    onClick={e => downloadDocumento(cell.row.original.id)}
                                    color='#7340BD' size={22}
                                    className="pointer"
                                />

                                <AiOutlineDelete
                                    onClick={e => handleDeleteDocumento(cell.row.original.id)}
                                    color="#7340BD" size={22}
                                    className="pointer"
                                />
                            </div>
                        </>
                    )
                ),
            },
        ],
        [user.id, handleDeleteDocumento, deleteCallback],
    );

    return (
        <TableDefault columns={columns} source={data} />
    );
}
