import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import Select from "../../Select";
import ResponsavelSalaService from "../../../services/ResponsavelSalaService";
import TableResponsavelSala from "./TableResponsavelSala";
import Swal from 'sweetalert2';
import api from '../../../services/api';
import Button from '../../Button';
import { ClipLoader } from 'react-spinners';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "#FFFFFF",
    maxHeight: "70vh",
    width: "40vw",
  },
};

const ResponsavelSalaModal = ({ modalIsOpen, setIsOpen, salaId }, ref) => {
  let subtitle;

  const formRef = useRef(null);
  const [ResponsavelSalas, setResponsavelSalas] = useState([]);
  const [Terapeutas, setTerapeitas] = useState([]);
  const [TerapeutaId, setTerapeutaId] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadResponsavelSalas = () => {
    ResponsavelSalaService.index('with=sala,terapeutum&filter[sala_id]=' + salaId).then(({ data }) => {
      setResponsavelSalas(data);
    }).catch((e) => alert(e))
  }

  const loadTerapeutas = () => {
    let terapeutas = []
    api.get(`api/terapeuta`).then(({ data }) => {
      data.forEach((terapeuta) => {
        terapeutas.push({ value: terapeuta.id, label: terapeuta.nome });
      });
      setTerapeitas(terapeutas);
    }).catch((e) => Swal.fire('Houve um erro ao carregar os terapeutas!', '', 'warning'));
  }

  useEffect(() => {
    loadTerapeutas();
    loadResponsavelSalas();
  }, []);

  function afterOpenModal() {
    subtitle.style.color = '#000';
  }

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const storeResponsavelSala = () => {
    setLoading(true);
    if (TerapeutaId === null) {
      Swal.fire('Por favor preencha todos os campos!', '', 'warning');
    } else {
      if (ResponsavelSalas !== undefined && ResponsavelSalas.filter((e) => TerapeutaId === e.terapeuta_id).length > 0) {
        Swal.fire('Terapeuta já incluído!', '', 'error');
      } else {
        let postData = {
          terapeuta_id: TerapeutaId,
          sala_id: salaId,
          data_hora: new Date(),
        }
        ResponsavelSalaService.store(postData).then(({ data }) => {
          loadResponsavelSalas();
        }).catch((e) => alert(e))
          .finally(() => setLoading(false));
      }
    }
  }

  async function handleSubmit() {
    storeResponsavelSala();
  }

  return (
    <div>
      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} transparent shouldCloseOnOverlayClick={false} shouldCloseOnEsc={true}>
        <div style={{ position: "relative" }}>
          <label style={{
            opacity: 0.5, cursor: "pointer", position: "absolute",
            right: "10px", fontSize: "25px", top: "-10px"
          }} onClick={() => setIsOpen(false)}>
            x
          </label>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 style={{ color: "#442953", fontWeight: "bold" }} ref={(_subtitle) => (subtitle = _subtitle)}>Responsável</h2>
          <Form ref={formRef} onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
            <div style={{ paddingTop: 30 }}>
              <div style={{ display: "flex", flexDirection: "column", width: '100%' }}>
                <Select name={'responsavel'} label={'Responsavel'} onChange={(e) => setTerapeutaId(e.value)} options={Terapeutas}
                  style={{ position: 'static' }} />
              </div>
            </div>
            <div>
              <TableResponsavelSala
                source={ResponsavelSalas}
                callback={loadResponsavelSalas}
              />
            </div>
            <Button className='btn-padrao' type="submit" disabled={loading}>
              {loading
                ? <ClipLoader size={22} />
                : "Adicionar"
              }
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default forwardRef(ResponsavelSalaModal);
