import React, { useState, useEffect } from "react";
import { AiFillCaretDown, AiOutlineClear } from "react-icons/ai";
import { Container, DivDisplay, FilterStyle, LabelFilter, InputStyle, DivContentDisplay, PacienteStyle, TerapeutaStyle, OptionsStyle, DateStyle } from "./styles";
import api from "../../../services/api";
import SelectFilter from "../../Select";
import { Form } from "@unform/web";
import moment from "moment";
export default function FiltroAtendimentos({ resultRef, list }) {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const [originalList, setOriginalList] = useState();
    const [expand, setExpand] = useState(false);
    //===VARS SELECTE LISTS===\\
    const [paciente, setPaciente] = useState([]);
    const [terapeuta, setTerapeuta] = useState([]);
    const [planosSaude, setPlanosSaude] = useState([]);
    const [statusAtendimento, setStatusAtendimento] = useState([]);
    //===VARS SELECTED===\\
    const [statusSelecionado, setStatusSelecionado] = useState(null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [dataSelecionado, setDataSelecionado] = useState(null);

    const [statusSelecionadoOpt, setStatusSelecionadoOpt] = useState(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState(null);
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    //===FUN GETTERS===\
    const getPlanos = () => {
        api.get('api/plano-saude').then(({ data }) => {
            let planoSaudeOptions = [];
            data.forEach((planoSaude) => {
                planoSaudeOptions.push({ value: planoSaude.id, label: planoSaude.plano });
            });
            setPlanosSaude(planoSaudeOptions);
        });
    };
    const getPacientes = () => {
        api.get('api/paciente').then(({ data }) => {
            let pacientes = [];
            data.forEach((p) => {
                pacientes.push({ value: p.id, label: p.nome });
            });
            setPaciente(pacientes);
        });
    };
    const getTerapeutas = () => {
        api.get('api/terapeuta').then(({ data }) => {
            let terapeutas = [];
            data.forEach((p) => {
                terapeutas.push({ value: p.id, label: p.nome });
            });
            setTerapeuta(terapeutas);
        });
    };
    const loadStatus = async () => {
        const response = await api.get(`api/status-atendimento`);
        const selectStatus = response.data.map(d => ({
            "value": d.id,
            "label": d.status
        }));
        setStatusAtendimento(selectStatus);
    }
    //===FUN HANDLES===\
    const expandFiltro = () => {
        let text = document.getElementById('blank');
        setExpand(!expand);
        if (expand === false) {
            text.innerHTML = 'Fechar Filtro';
        } else {
            text.innerHTML = "Expandir Filtro"
        }
    }
    //===FUN FILTERS===\
    const filter = () => {
        if (originalList !== null && originalList !== undefined) {
            let result = originalList.filter((value, index) => {
                let status, paciente, terapeuta, data, planoSaude = false;
                if (statusSelecionado === null) {
                    status = true;
                } else {
                    if (statusSelecionado == value.sugestao_recepcao) {
                        status = true
                    } else {
                        status = false;
                    }
                }
                if (pacienteSelecionado === null) {
                    paciente = true;
                } else {
                    if (pacienteSelecionado == value.paciente_id) {
                        paciente = true
                    } else {
                        paciente = false;
                    }
                }
                if (terapeutaSelecionado === null) {
                    terapeuta = true;
                } else {
                    if (terapeutaSelecionado == value.terapeuta_id) {
                        terapeuta = true
                    } else {
                        terapeuta = false;
                    }
                }
                if (dataSelecionado === null) {
                    data = true;
                } else {
                    if (moment.utc(dataSelecionado).format('DD/MM/YYYY') == moment.utc(value.created_at).format('DD/MM/YYYY')) {
                        data = true
                    } else {
                        data = false;
                    }
                }
                if (planoSaudeSelecionado === null) {
                    planoSaude = true;
                } else {
                    if (planoSaudeSelecionado == value.plano_saude_id) {
                        planoSaude = true
                    } else {
                        planoSaude = false;
                    }
                }
                return status && paciente && terapeuta && data && planoSaude;
            });
            resultRef(result);
        }
    }
    function filterByStatus(value) {
        api.get('api/atendimento?filter[atendimento.status]=' + value)
            .then(({ data }) => {
                resultRef(data);
            })
            .catch((e) => console.error(e));
    }
    const filterDate = async (date) => {
        if (date.length === 10) {
            api.get('api/atendimento?filter[horario]=' + date)
                .then(({ data }) => {
                    resultRef(data);
                })
                .catch((e) => console.error(e));
        }
    }
    function filterByPaciente(value) {
        api.get('api/atendimento?filter[paciente_id]=' + value)
            .then(({ data }) => {
                resultRef(data);
            })
            .catch((e) => console.error(e));
    }
    function filterByTerapeuta(value) {
        api.get('api/atendimento?filter[terapeuta_id]=' + value)
            .then(({ data }) => {
                resultRef(data);
            })
            .catch((e) => console.error(e));
    }
    function filterByPlano(value) {
        api.get('api/atendimento?filter[paciente_id_plano_saude]=' + value)
            .then(({ data }) => {
                resultRef(data);
            })
            .catch((e) => console.error(e));
    }
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    useEffect(() => {
        getPlanos();
        getPacientes();
        getTerapeutas();
        loadStatus();
        setOriginalList(list);
    }, []);
    useEffect(() => {
        filter();
    }, [statusSelecionado, pacienteSelecionado, dataSelecionado, planoSaudeSelecionado, terapeutaSelecionado]);
    useEffect(() => {
        setOriginalList(list);
    }, [list]);
    //#endregion
    return (<Container>
        <Form>
            <div style={DivDisplay}>
                <div style={FilterStyle}>
                    <div style={{ width: "100%", marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="w-100per pointer" onClick={() => expandFiltro()}>
                            <div style={{ display: "flex" }}>
                                <p id="blank" style={{ marginBottom: -30 }}>Expandir Filtro</p>
                            </div>
                            <div style={{ cursor: "pointer", marginLeft: "96%" }}>
                                <AiFillCaretDown color="white" size={22} />
                            </div>
                        </div>
                        {expand &&
                            <div style={{ marginTop: -20, marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={DivContentDisplay} className="row">
                                    <div className="col-6">
                                        <label className="label-filtro">Por Data:</label>
                                        <div className="d-flex justify-around align-center">
                                            <input style={DateStyle} type="date" value={dataSelecionado} onChange={(e) => { setDataSelecionado(e.target.value); }} maxLength={10} />
                                            <AiOutlineClear className="pointer m-2" onClick={(e) => { setDataSelecionado(null); }} size={25} />
                                        </div>
                                    </div>

                                    <div className="col-6">
                                        <label className="label-filtro">Status de Atendimento:</label>
                                        <div className="d-flex justify-around align-center">
                                            <SelectFilter name="status" options={statusAtendimento} value={statusSelecionadoOpt} onChange={(e) => { setStatusSelecionado(e.value); setStatusSelecionadoOpt({ value: e.value, label: e.label }) }} />
                                            <AiOutlineClear className="pointer m-2" onClick={(e) => { setStatusSelecionado(null); setStatusSelecionadoOpt(null); }} size={25} />
                                        </div>
                                    </div>
                                </div>
                                <div style={DivContentDisplay} className="row">
                                    <div className="col-6">
                                        <label className="label-filtro">Paciente:</label>
                                        <div className="d-flex justify-around align-center">
                                            <SelectFilter style={PacienteStyle} label={''} name={'paciente'} value={pacienteSelecionadoOpt} options={paciente} onChange={(e) => { setPacienteSelecionado(e.value); setPacienteSelecionadoOpt({ value: e.value, label: e.label }) }} />
                                            <AiOutlineClear className="pointer m-2" onClick={(e) => { setPacienteSelecionado(null); setPacienteSelecionadoOpt(null); }} size={25} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <label className="label-filtro">Plano de Saúde:</label>
                                        <div className="d-flex justify-around align-center">
                                            <SelectFilter containerStyle={OptionsStyle} label={''} name={'planosSaude'} value={planoSaudeSelecionadoOpt} options={planosSaude} onChange={(e) => { setPlanoSaudeSelecionado(e.value); setPlanoSaudeSelecionadoOpt({ value: e.value, label: e.label }) }} />
                                            <AiOutlineClear className="pointer m-2" onClick={(e) => { setPlanoSaudeSelecionado(null); setPlanoSaudeSelecionadoOpt(null); }} size={25} />
                                        </div>
                                    </div>
                                </div>
                                <div style={TerapeutaStyle} className="row">
                                    <div className="col-12 ">
                                        <label className="label-filtro">Terapeuta:</label>
                                        <div className="d-flex justify-around align-center w-100per pr-20px">
                                            <SelectFilter style={InputStyle} label={''} name={'terapeuta'} options={terapeuta} value={terapeutaSelecionadoOpt} onChange={(e) => { setTerapeutaSelecionado(e.value); setTerapeutaSelecionadoOpt({ value: e.value, label: e.label }) }} />
                                            <AiOutlineClear className="pointer m-2" onClick={(e) => { setTerapeutaSelecionado(null); setTerapeutaSelecionadoOpt(null); }} size={25} />
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </Form>
    </Container>);
}