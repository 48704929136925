import axios from 'axios';
import baseURL from './baseUrl';

const token = sessionStorage.getItem('@Neuro:access_token');

const api = axios.create({
    baseURL: baseURL,
    headers: {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
});

export default api;