import api from "./api";

class SolicitacaoMaterialService {
    path = 'api/';

    status = () => {
        return {
            PENDENTE: 1,
            SOLICITADO: 2,
            RECEBIDO: 3,
            EM_ANALISE: 4,
            APROVADO: 5,
            NAO_APROVADO: 6,
            MATERIAL_DISPONIVEL: 7,
            CANCELADO: 8,
        }
    }

    index = () => {
        return api.get(`${this.path}solicitacao-material`);
    }
    store = (obj) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":3,"terapia_id":1,"especialidade_id":1,"descricao":"Quo enim dolores aperiam sit eum qui quis. Neque impedit rem itaque saepe error.","user_id":578605326,"user_id_status":4675410,"status_solicitacao_id":1}
        */

        return api.post(`${this.path}solicitacao-material`, obj);
    }
    show = (id) => {
        return api.get(`${this.path}solicitacao-material/${id}?id=${id}`);
    }
    update = (obj, id) => {
        /* exemplo
        {"paciente_id":1,"paciente_id_plano_saude":318,"terapia_id":1,"especialidade_id":1,"descricao":"Doloribus non adipisci sit. Et maiores iusto omnis doloribus. Delectus autem repellendus ut nam minus doloribus sit ipsam.","user_id":1797,"user_id_status":93760966,"status_solicitacao_id":1}
        */

        return api.put(`${this.path}solicitacao-material/${id}?id=${id}`, obj);
    }
    destroy = (id) => {
        return api.delete(`${this.path}solicitacao-material/${id}?id=${id}`);
    }
}


const SolicitacaoMaterialInstance = new SolicitacaoMaterialService();
export default SolicitacaoMaterialInstance;