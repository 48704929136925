import * as React from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { CelulaCabecalho, LinhaTabela, LinhaTabelaVazia } from './styles';
import TablePagination from '../TablePagination';
import { AiOutlineCaretDown, AiOutlineCaretUp } from 'react-icons/ai';
/**
 * Tabela padrão do sistema.
 * @param {[Array, Array, String, boolean]} param0 Lista de parâmetros da página. source: dados para a tabela, columns: detalhamento das colunas, emptyPhrase: frase de tabela vasia, enableFooter: define se havera um footer ou não.
 * @returns 
 */
export function TableDefaultPagination({
    source,
    columns,
    emptyPhrase,
    initialState = { pageIndex: 0, pageSize: 8 },
    enableFooter = true,
    selectedRowId = null,
    className
}) {
    const data = React.useMemo(() => source, [source]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: initialState
    },
        useSortBy,
        usePagination,
    )

    return (
        <div className={className}>
            <table {...getTableProps()} className="TableListaEspera" style={{ width: '100%' }} id={'table_default'}>
                <thead style={{ cursor: 'pointer' }}>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <CelulaCabecalho key={index}
                                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span >
                                        {column.isSorted ? (column.isSortedDesc ? <AiOutlineCaretDown style={{ marginLeft: '10%' }} color='white' /> :
                                            <AiOutlineCaretUp style={{ marginLeft: '10%' }} color='white' />) : ""}
                                    </span>
                                </CelulaCabecalho>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="TableListaEsperaBody">
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) =>
                                    <LinhaTabela key={index} style={{
                                        height: '14px',
                                        textAlign: 'center',
                                        backgroundColor: row.original.id === selectedRowId ? '#e2e2e2' : '',
                                    }}>
                                        {cell.render('Cell')}
                                    </LinhaTabela>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {page.length < 1 ? <LinhaTabelaVazia> {emptyPhrase ?? 'Nada encontrado.'} </LinhaTabelaVazia> : ""}
            <div style={{ marginTop: 10 }}>
                {(enableFooter === true) ?
                    <TablePagination
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={gotoPage}
                        nextPage={nextPage}
                        previousPage={previousPage}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        linhas={source?.length} />
                    : ''
                }
            </div>
        </div>
    );
}