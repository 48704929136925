import React, { useState } from 'react';
import Terapeuta from '../../../layouts/Terapeuta';
import user from '../../../assets/img_avatar.png';
import natal from '../../../assets/natal.png';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useEffect } from "react";
import api from "../../../services/api";
import moment from "moment";
import PlanoTerapeuticoModal from '../../../components/Modais/PlanoTerapeuticoModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/auth';
import { FaCalendarAlt, FaFileMedicalAlt } from 'react-icons/fa';

export default function TerapeutaDashboard() {

    const [isModalSolicitacaoDeMateriaisVisible, setIsModalSolicitacaoDeMateriaisVisible] = useState(false);
    const [isModalAtividadeExternaVisible, setIsModalAtividadeExternaVisible] = useState(false);
    const [isModalDocumentacaoVisible, setIsModalDocumentacaoVisible] = useState(false);
    const [isSolicitarReuniaoVisible, setIsSolicitarReuniaoVisible] = useState(false);
    const [openModalPlanoTerapeutico, setOpenModalPlanoTerapeutico] = useState(false);
    const [especialidades, setEspecialidades] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [estatisticas, setEstatisticas] = useState({
        atendimentos_hoje: '00',
        evolucoes_pendentes: '00',
        relatorios_pendentes: '00',
        planos_terapeuticos_vencendo_30: '00',
    });

    const navigate = useNavigate();
    const terapeutaUser = useAuth();

    const getEspecialidades = () => {
        try {
            api.get('api/especialidade').then(({ data }) => {
                let especialidadeOptions = [];

                data.forEach((especialidade) => {
                    especialidadeOptions.push({ value: especialidade.id, label: especialidade.especialidade });
                });

                setEspecialidades(especialidadeOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de especialides - ", e)
        }
    }

    const getTerapias = () => {
        try {
            api.get('api/terapia').then(({ data }) => {
                let terapiasOptions = [];

                data.forEach((terapia) => {
                    terapiasOptions.push({ value: terapia.id, label: terapia.terapia });
                });

                setTerapias(terapiasOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de terapias - ", e)
        }
    }

    const getAgendaTerapeuta = () => {
        api.get('/api/estatisticas/terapeuta/' + terapeutaUser.user.terapeuta[0].id)
            .then(({ data }) => {
                setEstatisticas(data);
            })
            .catch(e => {
                console.error(e);
            })
    }

    const getPacientes = () => {
        try {
            api.get(`api/pacientes`).then(({ data }) => {
                let pacienteOptions = [];

                data.forEach((paciente) => {
                    pacienteOptions.push({ value: paciente.id, label: paciente.nome });
                });

                setPacientes(pacienteOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de pacientes - ", e)
        }
    }

    useEffect(() => {
        if (isModalSolicitacaoDeMateriaisVisible || isModalAtividadeExternaVisible || isSolicitarReuniaoVisible || isModalDocumentacaoVisible) {
            if (especialidades.length < 1) getEspecialidades()
            if (terapias.length < 1) getTerapias()
            if (pacientes.length < 1) getPacientes()
        }
    }, [isModalSolicitacaoDeMateriaisVisible, isModalAtividadeExternaVisible, isSolicitarReuniaoVisible, isModalDocumentacaoVisible])
    useEffect(() => {/* ONLOAD */
        getAgendaTerapeuta();
    }, []);
    return (
        <Terapeuta>
            <div className='container'>
                <div className='d-flex row'>
                    <img className='perfil-image w-150px h-150px p-0' src={terapeutaUser.user.terapeuta[0].foto_perfil_full ? terapeutaUser.user.terapeuta[0].foto_perfil_full : user} alt='Foto de perifl'></img>

                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <label className='font-24px font-bold font-roxo'>Bem vindo, {terapeutaUser?.user.terapeuta[0].nome}!</label>
                        <label className='font-18px font-roxo'>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                    </div>
                </div>
                <div className='d-flex row'>
                    <div className='d-flex flex-column bg-white align-items-stretch rounded mt-3' style={{ padding: "20px 40px", boxShadow: "0 0 10px 0px silver" }}>
                        <div className='d-flex align-items-center justify-content-center col-12'>
                            <label className='font-18px font-bold font-roxo'>Você tem:</label>
                        </div>
                        <div className='d-flex bg-white flex-row justify-content-lg-between justify-content-center flex-wrap flex-lg-nowrap '>
                            <div className='d-flex flex-column justify-content-center align-items-start text-center p-2' style={{ width: 150, wordWrap: "break-word" }}>
                                <div className='d-flex align-items-center justify-content-center rounded m-auto' style={{ backgroundColor: "#6A3BAF", height: 80, width: 80 }}>
                                    <label style={{ color: "#FFFFFF" }}>
                                        {estatisticas.atendimentos_hoje}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center justify-content-center'>
                                    <label className='font-15px font-bold font-roxo'>Atendimentos Hoje</label>
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-start text-center p-2' style={{ width: 150, wordWrap: "break-word" }}>
                                <div className='d-flex align-items-center justify-content-center rounded m-auto' style={{ backgroundColor: "#27DABE", height: 80, width: 80 }}>
                                    <label style={{ color: "#FFFFFF" }}>
                                        {estatisticas.evolucoes_pendentes}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center justify-content-center' >
                                    <label className='font-15px font-bold font-roxo'>Evolução Diária a Preencher</label>
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-start text-center p-2' style={{ width: 150, wordWrap: "break-word" }}>
                                <div className='d-flex align-items-center justify-content-center rounded m-auto' style={{ backgroundColor: "#3EBFE5", height: 80, width: 80 }}>
                                    <label style={{ color: "#FFFFFF" }}>
                                        {estatisticas.planos_terapeuticos_vencendo_30}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center justify-content-center text-center' >
                                    <label className='font-15px font-bold font-roxo'>
                                        Planos Terapêuticos a Vencer em 30 dias
                                    </label>
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-start text-center p-2' style={{ width: 150 }}>
                                <div className='d-flex align-items-center justify-content-center rounded m-auto' style={{ backgroundColor: "#F76397", height: 80, width: 80 }}>
                                    <label style={{ color: "#FFFFFF" }}>
                                        {estatisticas.relatorios_pendentes}
                                    </label>
                                </div>

                                <div className='d-flex align-items-center justify-content-center text-center' >
                                    <label className='font-15px font-bold font-roxo'>Relatórios Pendentes</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex w-100 align-items-center justify-content-around mt-3' >
                        <Link className='text-decoration-none align-items-center justify-content-center w-50 p-2' style={{ height: 90 }}
                            to={'/dashboard/terapeuta/statusdiario'}>
                            <button className='d-flex flex-column align-items-center justify-content-center border-none rounded text-white w-100' style={{
                                height: 90, backgroundColor: '#6A3BAF', fontWeight: 'bold'
                            }}>
                                Status Diário
                            </button>
                        </Link>
                        <Link className='text-decoration-none align-items-center justify-content-center w-50 p-2' style={{ height: 90 }}
                            to={'/dashboard/terapeuta/pacientes/prontuarios'}>
                            <button className='d-flex flex-column align-items-center justify-content-center border-none rounded text-white w-100' style={{
                                height: 90, backgroundColor: '#6A3BAF', fontWeight: 'bold'
                            }}>
                                Evolução Diária do Paciente
                            </button>
                        </Link>
                    </div>
                    {window.location.hostname === 'localhost' &&
                        <div className='d-flex w-100 align-items-center justify-content-around mt-4' >
                            <Link className='text-decoration-none align-items-center justify-content-center w-50 p-2' style={{ height: 90 }}
                                to={'/dashboard/terapeuta/minhas_solicitacoes'}>
                                <button className='d-flex flex-column align-items-center justify-content-center border-none rounded text-white w-100' style={{
                                    height: 90, backgroundColor: '#6A3BAF', fontWeight: 'bold', marginTop: -10
                                }}>
                                    Solicitações
                                </button>
                            </Link>
                        </div>
                    }
                    {window.location.hostname === 'localhost' &&
                        <div className='align-items-center justify-content-center col-12 px-2 rounded bg-white h-content mt-3 pb-3 default-box'>
                            <div className='text-center'><span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF", }}>Consultas</span></div>
                            <div className='d-flex align-items-center justify-content-around'>
                                <button onClick={() => setOpenModalPlanoTerapeutico(true)} className='btn-padrao w-25 flex-col' style={{ height: '75px' }} type='button'>
                                    <FaFileMedicalAlt size={20} />
                                    <span className='font-14px'>Planos</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/terapeuta/agenda')} className='btn-padrao w-25 flex-col' style={{ height: '75px' }} type='button'>
                                    <FaCalendarAlt size={20} />
                                    <span className='font-14px'>Agenda</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/terapeuta/relatorios')} className='btn-padrao w-25 flex-col' style={{ height: '75px' }} type='button'>
                                    <AiOutlineClockCircle size={20} />
                                    <span className='font-14px'>Últimos Atendimentos</span>
                                </button>
                            </div>
                        </div>}
                    <div className='d-flex flex-column rounded bg-white w-100 align-items-center justify-content-around mt-4'
                        style={{ boxShadow: "0 0 10px 0px silver" }} >
                        <div className='w-100 text-center'><span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>Recados</span></div>
                        <div className='col-12 flex-row flex-wrap'>
                            <div className='col-sm-12 col-lg-6 p-2 d-flex justify-content-center'><img src={natal} alt='Imagem de natal' /></div>
                            <div className='col-sm-12 col-lg-6 p-2 d-flex justify-content-center text-justify'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium excepturi saepe, commodi totam labore ex deserunt ut nobis molestias necessitatibus, iusto consectetur optio repellendus temporibus dicta expedita facere iure rerum.</div>
                        </div>
                    </div>
                </div>
            </div>
            {openModalPlanoTerapeutico && <PlanoTerapeuticoModal onClose={setOpenModalPlanoTerapeutico} />}
        </Terapeuta>
    )
}
