import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;

export const FormField = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: 10px;
    flex-direction: column;
`;

export const Calendar = styled.aside`
  width: 380px;
  .DayPicker {
    background: #28262e;
    border-radius: 10px;
  }
  .DayPicker-wrapper {
    padding-bottom: 0;
  }
  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }
  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px;
  }
  .DayPicker-Day {
    width: 40px;
    height: 40px;
  }
  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: #3e3b47;
    border-radius: 10px;
    color: #fff;
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }
  .DayPicker-Day--today {
    font-weight: normal;
  }
  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }
  .DayPicker-Day--selected {
    background: #ff9000 !important;
    border-radius: 10px;
    color: #232129 !important;
  }
`;

export const LegendaStyle = {
  backgroundColor: "#7441BF",
  fontFamily: "Nunito, sans-serif",
  color: "#FFFFFF",
  paddingRight: "32px",
  paddingLeft: "32px",
  paddingTop: "2px",
  paddingBottom: "2px",
  borderRadius: "6px",
  marginTop: "10px",
  marginBottom: "10px",
  border: 0
}

export const CenterDivStyle = {
  width: '100%',
  display: "flex",
  justifyContent: "center",
  justifyItems: "center",
  alignContent: "center",
  alignItems: "center",
  paddingBottom: "30px"
}