import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import Modal from 'react-modal';
import { AiOutlineClose } from "react-icons/ai";
import moment from "moment";
import { TableDefaultPagination } from "../../../TableDefaultPagination";
import { CgEyeAlt } from "react-icons/cg";
import './styles.css';
import PlanoTerapeuticoModal from "../../PlanoTerapeuticoModal";
import api from "../../../../services/api";


const PacientePlanoTerapeuticoModal = forwardRef((props, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS INFORMACOES DA TELA*/
    const titulo = 'MODAL DE PLANOS TERAPEUTICOS';
    const rota = 'dashboard/{modulo}/pacientes';
    const descricao = 'Modal que lista todos os planos terapeuticos do paciente.';
    const title = 'Planos Terapeuticos';

    const [infoPlano, setInfoPlano] = useState('');
    const [modalInfo, setModalInfo] = useState(false);
    const modalId = 'modal-plano-terapeutico';

    const [isOpen, setIsOpen] = useState(false);
    const [planos, setPlanos] = useState([]);
    const [pacienteData, setPacienteData] = useState([]);
    const infoPlanoModalRef = useRef(null);

    /* ===VARS MODAIS=== */
    const [openModalPlanoTerapeutico, setOpenModalPlanoTerapeutico] = useState(false);
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: '80vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };
    const columns = useMemo(() =>
        [
            {
                Header: 'Terapeuta',
                acessor: 'nome_terapeuta',
                Cell: ({ cell }) => cell.row.original.nome_terapeuta
            },
            {
                Header: 'Terapia',
                acessor: 'terapia',
                Cell: ({ cell }) => cell.row.original.terapia
            },
            {
                Header: 'Responsavel',
                acessor: 'nome_responsavel',
                Cell: ({ cell }) => cell.row.original.nome_responsavel
            },
            {
                Header: 'Metodologias',
                Cell: ({ cell }) => {
                    return (
                        <>
                            <button className="btn-icone">
                                <CgEyeAlt size={22} onClick={() => handleClickOpenModalInfo(cell.row.original)}></CgEyeAlt>
                            </button>
                        </>
                    )
                }
            }
        ]);
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const openModal = useCallback((paciente) => {
        setPacienteData(paciente);
        setPlanos(paciente.plano_terapeuticos);
        if (paciente !== undefined && paciente !== null) {
            let nascimento = moment.utc(paciente.nascimento);
            let idade = (Number(nascimento.diff(moment(), 'years')) * -1) + ` Anos (${nascimento.format('DD/MM/YYYY')})`;
            setPacienteData({ ...paciente, idade: idade });
        }
        setIsOpen(true);
    }, []);
    const closeModal = useCallback(() => {
        handleCloseModalInfo();
        setIsOpen(false);
    }, []);
    useImperativeHandle(ref, () => ({ openModal, closeModal }));

    const getPlanos = (id) => {
        api.get('api/plano-terapeutico?filter[paciente_id]=' + id + '&with=paciente,terapeutum,responsavel,terapium').then(({ data }) => {
            setPlanos(data);
        });
    }

    const handleClickOpenModalInfo = (info) => {
        setInfoPlano(info);
        setModalInfo(true);
    }
    const handleCloseModalInfo = () => {
        setInfoPlano(null);
        setModalInfo(false);
    }
    const handleClosePlanoTerapeuticoModal = (id) => {
        setOpenModalPlanoTerapeutico(false);
        if (id)
            getPlanos(id);
    }
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    //#endregion
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <>
            <Modal id={modalId} style={customStyles} isOpen={isOpen} onRequestClose={closeModal} appElement={document.getElementById('root')}
                shouldCloseOnOverlayClick={true} transparent>
                <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                    <label></label>
                    <label>{title}</label>
                    <label>
                        <button className='btn-icone' onClick={closeModal}>
                            <AiOutlineClose color='red' size={22} />
                        </button>
                    </label>
                </div>
                <div className="flex-row">

                    <div className="dados-paciente col-12">
                        {pacienteData != null ?
                            <>
                                <h1> {pacienteData.nome} </h1>
                                <div className="dados-paciente-col">
                                    <label><b> Identificador: </b> {pacienteData.identificador}</label>
                                    <label><b> Idade: </b> {pacienteData.idade}</label>
                                </div>
                                <div className="dados-paciente-col">
                                    <label><b> Diagnóstico: </b> {pacienteData.diagnostico}</label>
                                    <label><b> Infomação: </b> {pacienteData.identificador}</label>
                                </div>
                            </>
                            : 'Sem paciente.'
                        }
                    </div>
                    <div className="col-12">
                        <TableDefaultPagination source={planos} columns={columns}></TableDefaultPagination>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn-padrao" type="button" onClick={() => setOpenModalPlanoTerapeutico(true)}>Adicionar Plano Terapêutico</button>
                    </div>
                </div>
            </Modal>
            {openModalPlanoTerapeutico && <PlanoTerapeuticoModal pacienteData={pacienteData} onClose={handleClosePlanoTerapeuticoModal} />}
            {modalInfo &&
                <div id="modal-outside" className="div-modal-outside no-top" onClick={(e) => {
                    if (e.target === document.getElementById('modal-outside')) {
                        handleCloseModalInfo()
                    }
                }}>
                    <div className="div-modal">
                        <div className='div-titulo d-flex justify-content-between'>
                            <label></label>
                            <label>Plano Terapeutico Selecionado</label>
                            <label>
                                <button className='btn-icone' type="button" onClick={handleCloseModalInfo}>
                                    <AiOutlineClose color='red' size={22} />
                                </button>
                            </label>
                        </div>
                        <div className="folha-leitura">
                            <div className="cabecalho">
                                <p><b>Paciente:</b> {infoPlano.nome_paciente}</p>
                                <p><b>Terapeuta:</b> {infoPlano.nome_terapeuta}</p>
                                <p><b>Terapia:</b> {infoPlano.terapia}</p>
                                <p><b>Status:</b> {infoPlano.status}</p>
                            </div>
                            <div className="content">
                                <b>Objetivo Geral</b>
                                <br />
                                {infoPlano.objetivo_geral}
                                <br />
                                <br />
                                <b>Objetivo Especifico</b>
                                <br />
                                {infoPlano.objetivo_especifico}
                                <br />
                                <br />
                                <b>Metodologias</b>
                                <br />
                                {infoPlano.metodologias}
                                <br />
                            </div>
                            {infoPlano.status === 'Assinado'
                                &&
                                <div className="footer">
                                    <p><b>Assinado por:</b> {infoPlano.responsavel.nome}</p>
                                    <img src={infoPlano.assinatura}></img>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
    //#endregion
});
export default PacientePlanoTerapeuticoModal;