import React, { useEffect } from 'react';
import { SnackbarContainer, SnackbarDismiss, SnackbarLabel } from './styles';
import { useSnackbar } from '../../context/snackbar';

const Snackbar = () => {
  const snackbar = useSnackbar();
  let type = snackbar.type;

  const successStyle = {
    backgroundColor: '#36863a',
  };

  const errorStyle = {
    backgroundColor: '#c7312d',
  };

  const warningStyle = {
    backgroundColor: '#f6aa00',
    color: '#1e1e1e',
  };

  const infoStyle = {
    backgroundColor: '#1b9dab',
  };

  const style = type === 'success' ? successStyle : type === 'error' ? errorStyle : type === 'warning' ? warningStyle : infoStyle;

  const handleDismiss = () => {
    snackbar.onClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleDismiss();
    }, 5000);

    return () => clearTimeout(timer);
  }, [snackbar]);

  return (
    <SnackbarContainer style={{ ...style }}>
      <SnackbarLabel>{snackbar.message}</SnackbarLabel>
      <SnackbarDismiss onClick={handleDismiss}>×</SnackbarDismiss>
    </SnackbarContainer>
  );
};

export default Snackbar;