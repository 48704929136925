import api from "./api";

class FuncionarioService {
    static index = (params) => {
        return api.get(`api/user?${params}`);
    }
    static getByTipoUser = (params) => {
        return api.get(`api/user/by/tipo/user?${params}`);
    }

    static store = (obj) => {
        return api.post(`api/register`, obj);
    }

    static show = (id) => {
        return api.get(`api/user/${id}?with=contato_users,documentos_gerais`);
    }

    static update = (obj, id) => {
        return api.patch(`api/user/${id}?id=` + id, obj);
    }

    static destroy = (id) => {
        return api.delete(`api/user/${id}`);
    }
}

export default FuncionarioService;
