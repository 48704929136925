import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import Admin from "../../../../layouts/Admin";
import api from "../../../../services/api";
import { TableTiposAvaliacao } from "./TableTiposAtendimento";
import TiposAvaliacaoModal from "../../../../components/Modais/TiposAvaliacaoModal";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function TiposAvaliacao() {

  const [loading, setLoading] = useState(true);
  const [color, setColor] = useState("#8ed4ae");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [tiposAvaliacao, setTiposAvaliacao] = useState([]);

  const [selectedTipoAvaliacao, setSelectedTiposAvaliacao] = useState(null);

  const showEditModal = (data) => {
    setSelectedTiposAvaliacao(data);
    setIsOpen(true);
  }

  const loadTiposAvaliacao = () => {
    api.get(`api/tipos-avaliacao`)
      .then(({ data }) => {
        data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })
        setTiposAvaliacao(data)
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    loadTiposAvaliacao();
  }, []);

  return (
    <Admin>
      <Container>

        <div>
          <button onClick={() => { setSelectedTiposAvaliacao(null); setIsOpen(true) }} className="btn-padrao">
            <AiFillFileAdd style={{ marginRight: 5 }} />
            Novo Tipo de Avaliacao
          </button>
        </div>
        <div>
          {
            (tiposAvaliacao.length !== 0 && loading === false)
              ? <TableTiposAvaliacao callback={loadTiposAvaliacao} showEditModal={showEditModal} source={tiposAvaliacao} />
              : loading === false ? <p style={{ fontWeight: 'bold', textAlign: 'center' }}>Nenhum dado foi encontrado</p>
                : <ClipLoader color={color} loading={loading} css={override} size={50} />
          }
        </div>
        <TiposAvaliacaoModal
          modalIsOpen={modalIsOpen}
          setIsOpen={setIsOpen}
          callback={loadTiposAvaliacao}
          tipoAvaliacao={selectedTipoAvaliacao}
        />
      </Container>
    </Admin>
  )
}
