import styled from 'styled-components';

export const ButtonDcoumentStyle = {
    height: 90, 
    backgroundColor: "#6A3BAF", 
    cursor: 'pointer', 
    display: "flex-grid", 
    alignItems: "center",
    justifyContent: "center", 
    borderRadius: 10, 
    color: 'white', 
    margin: 5, 
    width: 120, 
    padding: 10,
    display: "flex", 
    flexDirection: "column", 
    textAlign: "center", 
}

export const ButtonStyle = {
    height:'95px', 
    backgroundColor: "#6A3BAF", 
    cursor: 'pointer', 
    display: "flex-grid", 
    alignItems: "center",
    justifyContent: "center", 
    borderRadius: 10, 
    color: 'white', 
    margin: 5, 
    width: "25%", 
    padding: 20
}