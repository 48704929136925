import React, {useMemo} from "react";
import {AiOutlineDelete,AiOutlineEdit} from "react-icons/ai";
import Swal from "sweetalert2";
import TipoAtendimentoService from "../../../../../services/TipoAtendimentoService";
import { TableDefaultPagination } from "../../../../../components/TableDefaultPagination";

export function TableTiposAtendimento({source, callback, editModal}) {

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        TipoAtendimentoService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
      }
    })
  }

  const columns = useMemo(
    () => [
          {
            Header: 'Tipo de atendimento:',
            accessor: 'tipo_atendimento',
          },
          {
            Header: "Ações",
            accessor: "acoes",
            Cell: ({cell}) => (
              (<div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "transparent"
                }}>
                  <AiOutlineDelete style={{cursor: 'pointer'}} onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22}/>
                  <AiOutlineEdit style={{cursor: 'pointer'}} onClick={() => editModal(cell.row.original)} color="#7340BD" size={22}/>
                </div>
              )
            )
          }
        ],
    []
  )

  return (
    <TableDefaultPagination columns={columns} source={source}/>
  )
}