import styled from 'styled-components';

export const ContainerSubCard = styled.div`
  padding: 5px;
  border-radius: 7px;
  box-shadow: 6px 4px 11px -3px rgba(0, 0, 0, 0.4);
  background: #7441BF;
  color: #FFFFFF;
  font-weight: bold;
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin: 15px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    box-shadow: 6px 4px 11px -3px rgba(0, 0, 0, 0.7);
    background: #6c3ab2;
    transition: all .3s;
  }
`;

export const ContainerIcone = styled.div`
  text-align: center;
  font-size: 24px;
`;

export const ContainerNome = styled.div`
  font-size: 12px;
`;
