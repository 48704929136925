import React, { useEffect, useState } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import { Container } from './styles';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import EspecialidadeService from "../../../../services/EspecialidadeService";
import { TableEspecialidade } from "./TableEspecialidade";
import EspecialidadeModal from "../../../../components/Modais/EspecialidadeModal";
import Admin from "../../../../layouts/Admin";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Especialidade() {

	const [loading, setLoading] = useState(true);
	const [color, setColor] = useState("#8ed4ae");
	const [modalIsOpen, setIsOpen] = useState(false);
	const [Especialidades, setEspecialidades] = useState([]);

	const [selectedEspecialidade, setSelectedEspecialidade] = useState(null);

	const loadEspecialidades = () => {
		EspecialidadeService.index()
			.then(({ data }) => {
				data.sort((a, b) => {
					return new Date(b.created_at) - new Date(a.created_at);
				})
				setEspecialidades(data)
			})
			.finally(() => setLoading(false));
	}

	useEffect(() => {
		loadEspecialidades();
	}, []);

	const showEditModal = (data) => {
		setSelectedEspecialidade(data);
		setIsOpen(true);
	}

	return (
		<Admin>
			<Container>
				<div>
					<button onClick={() => { setSelectedEspecialidade(null); setIsOpen(true); }} className="btn-padrao">
						<AiFillFileAdd style={{ marginRight: 5 }} />
						Nova Especialidade
					</button>
				</div>
				<div>
					{(Especialidades.length !== 0 && loading === false)
						? <TableEspecialidade callback={loadEspecialidades} showEditModal={showEditModal} source={Especialidades} />
						: <ClipLoader color={color} loading={loading} css={override} size={50} />}
				</div>
				<EspecialidadeModal
					modalIsOpen={modalIsOpen}
					setIsOpen={setIsOpen}
					callback={loadEspecialidades}
					especialidade={selectedEspecialidade}
				/>
			</Container>
		</Admin>
	)
}
