import { useSnackbar } from "context/snackbar";
import api from "./api";
/**
 * Classe responsável por lidar com serviços relacionados a documentos.
 * @class
 */
class DocumentoService {
    url = 'api/documento';
    snackbar = useSnackbar();
    /**
     * Constructs a new DocumentoService object.
     * 
     * @param {string} [type='paciente'] - The type of the document service. Possible values are 'paciente', 'terapeuta', and 'funcionario'.
     */
    constructor(type = 'paciente') {
        switch (type.toLocaleLowerCase()) {
            case 'paciente':
                this.url = `api/documento`;
                break;
            case 'terapeuta':
                this.url = `api/documentos-gerais`;
                break;
            case 'funcionario':
                this.url = `api/documentos-gerais`;
                break;
            default:
                this.url = `api/documento`;
                break;
        }
    }
    /**
     * Retrieves the list of documents.
     *
     * @returns {Promise} A promise that resolves with the list of documents.
     */
    index = () => {
        try {
            return api.get(this.url);
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static index = () => {
        return this.index();
    };
    /**
     * Stores the given object in the server.
     * 
     * @param {Object} obj - The object to be stored.
     * @returns {Promise} A promise that resolves to the server response.
     */
    store = (obj) => {
        try {
            const form = new FormData();
            Object.keys(obj).forEach((key) => {
                if (key === 'arquivo') {
                    form.append(key, obj[key], obj[key].name);
                } else {
                    form.append(key, obj[key]);
                }
            });

            return api.post(this.url, form);
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static store = (obj) => {
        return this.store(obj);
    }

    /**
     * Retrieves a document by its ID.
     *
     * @param {number} id - The ID of the document to retrieve.
     * @returns {Promise} A promise that resolves with the retrieved document.
     */
    show = (id) => {
        try {
            return api.get(`${this.url}/${id}`);
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static show = (id) => {
        return this.show(id);
    };

    /**
     * Updates a document with the given object and ID.
     *
     * @param {Object} obj - The object containing the updated document data.
     * @param {string} id - The ID of the document to be updated.
     * @returns {Promise} A promise that resolves to the updated document.
     */
    update = (obj, id) => {
        try {
            return api.put(`${this.url}/${id}`, obj);
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static update = (obj, id) => {
        return this.update(obj, id);
    };

    /**
     * Deletes a document by its ID.
     *
     * @param {number} id - The ID of the document to be deleted.
     * @param {object} [data=null] - Additional data to be sent with the request.
     * @returns {Promise} A promise that resolves when the document is successfully deleted.
     */
    destroy = (id, data = null) => {
        try {
            return api.delete(`${this.url}/${id}`, { data });
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static destroy = (id, data = null) => {
        return this.destroy(id, data);
    };

    /**
     * Approves a document.
     *
     * @param {string} documento - The document to be approved.
     * @returns {Promise} A promise that resolves when the document is approved.
     */
    aprovar = (documento) => {
        try {
            return api.put(`${this.url}/${documento}/aprovar`, documento);
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static aprovar = (documento) => {
        return this.aprovar(documento);
    };

    /**
     * Downloads a document with the specified ID.
     *
     * @param {number} id - The ID of the document to download.
     * @returns {Promise<Blob>} A promise that resolves to a Blob object representing the downloaded document.
     */
    download = (id) => {
        try {
            return api.get(`${this.url}/download/${id}`, { responseType: 'blob' });
        } catch (error) {
            this.snackbar.displayMessage("Houve um erro ao resgatar os documentos!", "error");
            console.error(error);
        } finally {
            this.snackbar.displayMessage("Documentos consultados com sucesso!", "success");
        }
    };
    static download = (id) => {
        return this.download(id);
    };
}

export default DocumentoService;
