import React, {useEffect, useState} from 'react';
import Container from '../../../../components/Container';
import { useAuth } from '../../../../context/auth';
import {
  DivListaDocumentos,
  DivPrincipal,
  ItemLista,
  NomeDocumento,
  ParagrafoInformativoNumeroDocumentos,
  ParagrafoSaudacao,
} from '../DocumentosTerapeuticos/styles';
import {Link} from 'react-router-dom';
import Responsavel from '../../../../layouts/Responsavel';

const DocumentosPendentesResponsavel = () => {
  const { user } = useAuth();
  const [documentos, setDocumentos] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [numeroDocumentosPendentes, setNumeroDocumentosPendentes] = useState('00');

  useEffect(() => {
    let documentosPendentes = sessionStorage.getItem('@Neuro:docs_pending');
    if (documentosPendentes === null || documentosPendentes.length < 1) return;
    documentosPendentes = JSON.parse(documentosPendentes);
    setDocumentos(documentosPendentes);
    if (documentosPendentes.length > 0 && documentosPendentes.length < 10) {
      setNumeroDocumentosPendentes('0' + documentosPendentes.length);
      return;
    }
    setNumeroDocumentosPendentes(documentosPendentes.length);
  }, []);

  useEffect(() => {
    if (documentos === null) return;
    setIsLoaded(true);
  }, [documentos]);


  return (
      <Responsavel>
        <Container>
          <DivPrincipal>
            <ParagrafoSaudacao>
              <span style={{ fontWeight: 'bold' }}>Olá, Responsável</span> 
            </ParagrafoSaudacao>
            <ParagrafoInformativoNumeroDocumentos>
              Você tem <span style={{ fontWeight: 'bold' }}>{numeroDocumentosPendentes}</span> documentos para revisão     
            </ParagrafoInformativoNumeroDocumentos>
            {isLoaded &&
                <DivListaDocumentos>
                  <span style={{ fontWeight: 'bold', marginLeft: 15 }}>Documentos:</span>
                  <ul style={{ width: '100%' }}>
                    {documentos.map((documento) => {
                      return (
                          <ItemLista>
                            <NomeDocumento>{documento.nome_documento}</NomeDocumento>
                            <Link to={`/documentos-pendentes/responsavel/${documento.id}`} style={{
                              backgroundColor: '#7340BD',
                              color: '#FFFFFF',
                              border: 'none',
                              padding: '5px 10px',
                              borderRadius: '8px',
                              width: '25%',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              textDecoration: 'none',
                              margin: '5px',
                            }}>Abrir</Link>
                          </ItemLista>
                      );
                    })}
                  </ul>
                </DivListaDocumentos>
            }
          </DivPrincipal>
        </Container>
      </Responsavel>
  );
};

export default DocumentosPendentesResponsavel;
