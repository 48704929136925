import React, { useMemo } from "react";
import { TableDefault } from '../../../../../components/TableDefault';
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import { AiOutlineDelete } from "react-icons/ai";
import PacienteFeriasService from "../../../../../services/PacienteFeriasService";
import moment from "moment/moment";

export function TableHistoricoDeFerias({ source, feriasList, setFeriasList, readOnly = false }) {

    const data = React.useMemo(() => source, [source]);
    const snackbar = useSnackbar();

    const removeFerias = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (data.paciente_id === null || data.paciente_id === undefined) {
                    let newFerias = [];
                    feriasList.forEach((e) => {
                        if (Number(e.id) !== Number(data.id)) {
                            newFerias.push(e);
                        }
                    });
                    setFeriasList(newFerias);
                    snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                } else {
                    PacienteFeriasService.destroy(data.id).then(() => {
                        let newFerias = [];
                        feriasList.forEach((e) => {
                            if (Number(e.id) !== Number(data.id)) {
                                newFerias.push(e);
                            }
                        });
                        setFeriasList(newFerias);
                        snackbar.displayMessage('Cadastro removido com sucesso!', 'success');
                    }).catch((error) => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
                }
            }
        });
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Data Inicio',
                accessor: 'dt_ida', Cell: ({ cell }) => {
                    return moment.utc(cell.row.original.dt_ida).format('DD/MM/YYYY')
                }
            },
            {
                Header: 'Data Fim',
                accessor: 'dt_volta', Cell: ({ cell }) => {
                    return moment.utc(cell.row.original.dt_volta).format('DD/MM/YYYY')
                }
            },
            {
                Header: 'Dias de Férais',
                accessor: 'dias_ferias', Cell: ({ cell }) => {
                    let diff = moment.utc(cell.row.original.dt_volta).diff(moment.utc(cell.row.original.dt_ida), 'days');
                    return (diff > 0) ? diff + 1 : 0;
                }
            },
            {
                Header: 'Dias Restantes',
                Cell: ({ cell }) => {
                    let diff = moment.utc(cell.row.original.dt_volta).diff(moment.utc(), 'days');
                    return (diff > 0) ? diff + 1 : 0;
                }
            },
            {
                Header: 'Comentário',
                accessor: 'comentario',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    if (readOnly === false) {
                        return (
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent", margin: 5 }}>
                                <AiOutlineDelete className="icone-padrao" onClick={e => removeFerias(cell.row.original)} />
                            </div>
                        )
                    }else{
                        return ''
                    }
                }
            }
        ], [source]
    )

    return (
        <TableDefault columns={columns} source={data} />
    );
}
