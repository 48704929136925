import React, { useRef, useEffect, useState } from "react";
import { Modal, Container } from './styles'
import { FormField } from '../../styles'
import { Form } from '@unform/web'
import Input from '../../../../../components/Input';
import Select from "../../../../../components/Select";
import TextArea from "../../../../../components/TextArea";
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import api from "../../../../../services/api";

const tiposDocs = [
    {value: 'laudo', label: 'Laudo', selected: 'true'},
    {value: 'outros', label: 'Outros documentos'},
  ]

export default function Documentacao({ id = 'modal', onClose = () => { }, pacientes, terapias, especialidades, modalData, paciente }) {

    const [PacienteId, setPacienteId] = useState(null);
    const [TerapiaId, setTerapiaId] = useState(null);
    const [EspecialidadeId, setEspecialidadeId] = useState(null);
    const [Documento, setDocumento] = useState(null);
    const [Descricao, setDescricao] = useState(null);
    const [StatusSolicitacaoId, setStatusSolicitacaoId] = useState(1);
    const [SolicitacaoDocumentacaos, setSolicitacaoDocumentacaos] = useState(null);
    const [tipoDocumento, setTipoDocumento] = useState(null);

    const snackbar = useSnackbar();
    const formRef = useRef(null);

    const handleSubmit = async (form) => {

        if (PacienteId === null || TerapiaId === null || EspecialidadeId === null || form.Documento === "" ||
            tipoDocumento === null || form.Observação === "") {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            if (await storeSolicitacaoDocumentacao(form)) {
                onClose();
                snackbar.displayMessage("Solicitação criada com sucesso!", "success");
            } else {
                Swal.fire('Ocorreu um erro ao cadastrar a solicitação!', '', 'error');
            }
        };
    };

    const getDefaultTerapia = (terapia) => {
        if (!paciente) return;
        const terapiaPaciente = terapia.filter(data => {
            return data.value === paciente.terapia_id;
        })[0];
        setTerapiaId(terapiaPaciente);
    };

    const getDefaultEspeciadade = (especialidade) => {
        if (!paciente) return;
        const especialidadePaciente = especialidade.filter(data => {
            return data.value === paciente.especialidade_id;
        })[0];
        setEspecialidadeId(especialidadePaciente);
    };

    const getDefaultPaciente = (pacientes) => {
        if (!paciente) return;
        const pacienteSolicitacao = pacientes.filter(data => {
            return data.value === paciente.id_paciente;
        })[0];
        setPacienteId(pacienteSolicitacao);
    };

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    const storeSolicitacaoDocumentacao = (form) => {
        let postData = {
            paciente_id: PacienteId.value,
            terapia_id: TerapiaId.value,
            especialidade_id: EspecialidadeId.value,
            documento: form.Documento,
            descricao: form.Descricao,
            status_solicitacao_id: StatusSolicitacaoId,
            tipo: tipoDocumento.value,
        }
        return api.post(`api/solicitacao-documentacao` , postData).then(({ data }) => {
            return true
        }).catch(() => false);
    }

    useEffect(() => {
      getDefaultEspeciadade(especialidades);
      getDefaultTerapia(terapias);
      getDefaultPaciente(pacientes);
    }, [paciente]);


    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <Container>
                <div style={{position: "relative"}}>
                    <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
                     right: "5px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
                    x
                    </label>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Solicitar Documentação</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <FormField>
                        <FormField>
                            {modalData === undefined ? <Select label="Paciente" name="Paciente" options={pacientes} onChange={setPacienteId} 
                               value={PacienteId} />
                                : <Input title={"Paciente"} name="Paciente" defaultValue={modalData.nome} readOnly={'readOnly'}/>}
                        </FormField>
                        <FormField>
                            {modalData === undefined ? <Select label="Terapia" name="Terapia" options={terapias} onChange={setTerapiaId}
                                value={TerapiaId} />
                                : <Input title={"Terapia"} name="Terapia" defaultValue={modalData.terapia} readOnly={'readOnly'}/>}
                        </FormField>
                    </FormField>
                    <FormField>
                        <div style={{ width: "50%" }}>
                            {modalData === undefined ? <Select label="Especialidade" name="Especialidade" options={especialidades} 
                                onChange={setEspecialidadeId} value={EspecialidadeId}/>
                                : <Input title={"Especialidade"} name="Especialidade" defaultValue={modalData.especialidade} readOnly={'readOnly'}/>}
                        </div>
                        <div style={{ width: "50%" }}>
                        {modalData === undefined ? <Input title="Documento:" name="Documento"/> : 
                            <Input title={"Documento"} name="Documento" defaultValue={modalData.documento}/>}
                        </div>
                    </FormField>
                    <FormField>
                        {modalData === undefined ? <Select label="Tipo de Documento:" name="Tipo" options={tiposDocs} onChange={setTipoDocumento}
                           value={tipoDocumento} />
                        : <Input title={"Tipo de Documento"} name="Tipo" value={modalData.tipo}/>}
                        
                    </FormField>
                    <FormField>
                    {modalData === undefined ? <TextArea title="Observação:" name="Descricao" /> : 
                            <TextArea title={"Observação"} name="Observação" defaultValue={modalData.descricao}/>}
                    </FormField>
                    {/* <FormField>
                        <AiOutlineCloudUpload color="#6A3BAF" size={36} />
                        <label>Anexar Arquivos</label>
                    </FormField> */}
                    <FormField>
                        <button style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }} onClick={e => onClose(e)}>
                            Voltar
                        </button>
                        {modalData === undefined ? <button style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }} type='submit'>
                            Confirmar
                        </button> : ''}
                    </FormField>
                </Form>
            </Container>
        </Modal>
    )
}