import { useState } from "react";
import api from "services/api";

const useTerapiasData = () => {
    const [terapiasOptions, setTerapiasOptions] = useState([]);

    const fetchTerapias = async () => {
        try {
            const { data } = await api.get(`api/terapia/`);
            const options = data.map((terapia) => ({
                value: terapia.id,
                label: terapia.terapia
            }));
            setTerapiasOptions(options);
        } catch (error) {
            console.error('Erro ao carregar terapias:', error);
        }
    };

    return { terapiasOptions, fetchTerapias };
};

export default useTerapiasData;