import React, {useMemo} from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import { TableDefault } from "../../../../components/TableDefault";
import { useSnackbar } from "../../../../context/snackbar";
import api from "../../../../services/api";

export function TableContatos({source, terapeutaId, setContatos, callBack, handleOpen, setModalContatosData}) {

    const data = React.useMemo(() => source, [source]);
    const snackbar = useSnackbar();

    const removeContato = (data) => {
        Swal.fire({
                title: 'Deseja realmente remover este registro ?',
                showDenyButton: true,
                confirmButtonText: 'Remover',
                denyButtonText: `Cancelar`,
            }).then((result) => {
                if (result.isConfirmed) {
                    if (terapeutaId === null || terapeutaId === undefined) {
                        let contatosLocal = [... source];
                        const index = contatosLocal.indexOf(data);
                        contatosLocal.splice(index, 1);
                        setContatos(contatosLocal); 
                        callBack()
                    } else {
                        api.delete('api/contato-terapeuta/'+ data.id + '?id=' + data.id)
                            .then(e=>{
                                Swal.fire('Cadastro de contato removido com sucesso!', '', 'success');
                            })
                            .catch(e=>{
                                Swal.fire('Erro ao excluir cadastro de contato', '', 'error');
                                console.error(e);
                            });
                        callBack()
                    }
            }});
    }

    const handleEditContato = (data) => {
        setModalContatosData(data);
        handleOpen();
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'nome', // accessor is the "key" in the data
            },
            {
                Header: 'Parentesco',
                accessor: 'parentesco',
            },
            {
                Header: 'Contato',
                accessor: 'contato',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({cell}) => (
                    (   
                        <div style={{ display: "flex", alignItems: "center",
                            justifyContent: "center", backgroundColor: "transparent", margin: 5}}>
                            <AiOutlineEdit className="icone-padrao" onClick={e => handleEditContato(cell.row.original)}/>
                            <AiOutlineDelete className="icone-padrao" onClick={e => removeContato(cell.row.original)}/>
                        </div>
                    )
                )
            }
        ],
        []
    )

  return (
      <TableDefault columns={columns} source={data} />
  );
}
