import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import Modal from 'react-modal';
import Input from '../../../../components/Input';
import { Form } from '@unform/web';
import { CustomStyles, FormStyle, LabelUpload } from './styles';
import Swal from 'sweetalert2';
import { BackButton, Delete, InputUpload, ModalDiplomaStyle, Upload, UploadIcon } from '../ModalDiplomas/styles';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { useAuth } from '../../../../context/auth';
import { useSnackbar } from '../../../../context/snackbar';

const ModalContratoCursos = (props, ref) => {

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [dados, setDados] = React.useState(null);
    const [path, setPath] = useState([]);
    const [docCurso, setDocCurso] = useState(null);

    const formRef = useRef(null);
    const user = useAuth();
    const snackbar = useSnackbar();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const editCursos = (contrato, dados) => {
        let contratosLocal = [...props.cursos];
        const index = contratosLocal.indexOf(contrato);
        contratosLocal.splice(index, 1, dados);
        props.setCursos(contratosLocal);
    }

    const handleSubmit = async (data) => {
        if (data.nome === '' || data.data === '' || docCurso === 0) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            let formData = new FormData();
            formData.append("arquivo", docCurso);
            const dadosCurso = {
                nome: data.nome,
                data: data.data,
                aprovado_por: data.aprovado_por,
                arquivo: formData,
            }
            if (props.modalData === null) {
                let contratosLocal = [...props.cursos];
                if (props.paciente_id !== undefined && props.paciente_id !== null) {
                    dadosCurso.paciente_id = props.paciente_id;
                    //const response = await EmergenciasPacienteService.store(dadosCurso);
                    //contratosLocal.push(response.data);
                } else {
                    contratosLocal.push(dadosCurso);
                }
                setIsOpen(false);
                snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                props.setCursos(contratosLocal);
            } else {
                let contratosLocal = [...props.cursos];
                if (props.paciente_id === undefined || props.paciente_id === null) {
                    editCursos(props.modalData, dadosCurso)
                    setIsOpen(false);
                    snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                } else {
                    /* EmergenciasPacienteService.update(dadosCurso, props.modalData.id).then(() => {
                        snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                        setIsOpen(false);
                        props.callBack();
                    }).catch((e) => {
                        Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
                    }) */
                }
            }

        };

    };

    const handleUploadDiploma = (e) => {
        if (docCurso === null) {
            var arquivo = document.getElementById("upload-contrato-curso");
            var file = e.target.files[0].name;
            var size = e.target.files[0].size;
            var extPermitidas = ['jpg', 'png', 'jpeg', 'pdf',];
            if (extPermitidas.find((ext) => { return file.split('.').pop() === ext }) === undefined) {
                Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + '(Permitidas: pdf, jpg, jpeg, pgn,)', '', 'warning');
                arquivo.value = "";
            } else {
                if (size < 40000000) { //40MB         
                    setDocCurso(e.target.files[0]);
                    setPath([...path, e.target.value]);
                } else {
                    Swal.fire('Limite de tamanho do arquivo excedido! (Max: 40MB)', '', 'warning');
                    arquivo.value = "";
                }
                e.preventDefault();
            }
        }
        else {
            Swal.fire('Erro: Cadastre apenas um arquivo!', '', 'warning');
        }
    };

    const handleDeleteUpload = () => {
        setDocCurso(null);
        path.splice(-1);
    };

    const functionThatSubmitsForm = () => {
        formRef.current.submitForm();
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={CustomStyles}
                contentLabel="Example Modal"
                transparent>
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={e => closeModal()}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h2 style={{ color: "#6A3BAF", fontWeight: "bold", textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>
                        Novo Contrato de Realização de Curso
                    </h2>
                    <Form ref={formRef} onSubmit={handleSubmit} style={FormStyle} >
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center", }}>
                            <div style={{ flexDirection: "column", width: "40%", height: "auto", }}>
                                <Input title="Nome:" name="nome"
                                />
                            </div>
                            <div style={{ marginLeft: 5, flexDirection: "column", width: "28%", height: "auto" }}>
                                <Input containerStyle={ModalDiplomaStyle} title="Data:" name="data" type="date"
                                />
                            </div>
                            <div style={{ marginLeft: 5, flexDirection: "column", width: "28%", height: "auto" }}>
                                <Input containerStyle={ModalDiplomaStyle} title="Aprovado por:" name="aprovado_por"
                                    value={user.user.name} readOnly={'readOnly'} />
                            </div>
                            <div style={{ marginTop: 5, flexDirection: "column", marginLeft: 5, }}>
                                <AiOutlineCloudUpload color='#7340BD' size={38} style={UploadIcon} />
                            </div>
                            <div style={{ flexDirection: "column", }}>
                                <label htmlFor="upload-contrato-curso" style={LabelUpload}>Anexar Contrato
                                    <input type="file" id="upload-contrato-curso" style={InputUpload}
                                        onChange={e => handleUploadDiploma(e)} defaultValue={props.modalData === null ? '' : ''} />
                                </label>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {path.map((item) => {
                                return <span style={Upload}>{item}
                                    <div>
                                        <span style={Delete} onClick={handleDeleteUpload}>
                                            x
                                        </span>
                                    </div>
                                </span>
                            })}
                        </div>
                        <div className='d-flex align-items.center justify-content-evenly mt-3 mb-3'>
                            <button onClick={e => closeModal()} style={BackButton}>
                                Voltar
                            </button>
                            <button onClick={e => functionThatSubmitsForm()} style={BackButton}>
                                Adicionar
                            </button>
                        </div>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(ModalContratoCursos);
