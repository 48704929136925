import React from 'react'

import Container from '../components/Container'
import Header from '../components/Header'

const Responsavel = ({ children }) => (
    <>
        <Header />
        <Container>
            {children}
        </Container>
    </>
)

export default Responsavel;
