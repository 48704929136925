import React, { useState } from 'react';
import { useEffect } from "react";
import api from "../../../../services/api";
import moment from "moment";
import { Container, ContainerSub, FormField } from './styles'
import { useNavigate } from 'react-router-dom';
import RelatorioEvolucaoPaciente from '../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente';

export default function PendenciasDiarias() {
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState()
    const [pacientes, setPacientes] = useState([]);

    function handleAbrir(itemPaciente) {
        setShowModal(true)
        setModalData(itemPaciente)
    }

    useEffect(() => {
        getPacientes()
    }, [])

    const getPacientes = () => {
        try {
            api.get(`api/atendimento?with=paciente&filter[between]=${moment().subtract(1, 'days').format('YYYY-MM-DD')},${moment().format('YYYY/MM/DD')}`).then(({ data }) => {
                let pacienteOptions = [];
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
                console.log('pendencias diarias: ', data)
                data.forEach((atendimento) => {
                    pacienteOptions.push({
                        id_paciente: atendimento.paciente.id,
                        nome: atendimento.paciente.nome,
                        data_atendimento: atendimento.data_atendimento_real,
                        status: atendimento.paciente.status,
                        estacao: atendimento.estacao_id,
                        //PENDENCIA DIÁRIA DO PACIENTE
                    });
                });

                setPacientes(pacienteOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de pacientes - ", e)
        }
    }

    return (
        <Container>
            <div className='div-submenu'>
                <div>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias')} style={{ fontFamily: "Nunito, sans-serif", marginRight: 20, cursor: "pointer" }}>Evolução Diária</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-gerais')} style={{ fontFamily: "Nunito, sans-serif", color: "#7340BD", fontWeight: "bold", marginRight: 20, cursor: "pointer" }}>Pendências Gerais</label>
                    <label onClick={() => navigate('/dashboard/terapeuta/pendencias/pendencias-atrasadas')} style={{ fontFamily: "Nunito, sans-serif", cursor: "pointer" }}>Pendências Atrasadas</label>
                </div>
            </div>
            <ContainerSub>
                <FormField>
                    <span style={{
                        color: "#4906AB",
                        fontSize: "2.0rem",
                        fontFamily: "Nunito, sans-serif",
                        fontWeight: "800",
                        marginBottom: '2rem'
                    }}>
                        Pendências Gerais
                    </span>
                    <table>
                        <thead>
                            <tr>
                                <td style={{ color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Paciente</td>
                                <td style={{ color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Pendência</td>
                                <td style={{ color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Data e hora</td>
                                <td style={{ color: "#4906AB", fontWeight: '600', margin: '0 10px' }}>Limite</td>
                            </tr>
                        </thead>
                        <tbody>
                            {pacientes.map((item) => {
                                console.log(item)
                                return (
                                    <tr>
                                        <td>
                                            <input value={item.nome} style={{ width: '200px', borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} />
                                        </td>
                                        <td>
                                            <input value={item.nome} style={{ width: '200px', borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} />
                                        </td>
                                        <td>
                                            <input value={moment(item.data_atendimento).format('DD/MM/YYYY')} style={{ width: '200px', borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} />
                                        </td>
                                        <td>
                                            <input value={moment().format('DD/MM/YYYY') + ' às 23:59'} style={{ width: '200px', borderRadius: 8, height: 36, border: "none", padding: 12, marginLeft: 10, marginRight: 10 }} />
                                        </td>
                                        <td>
                                            <button className="btn-padrao" onClick={() => handleAbrir(item)}>
                                                Abrir
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </FormField>
            </ContainerSub>
            {showModal && <RelatorioEvolucaoPaciente onClose={() => setShowModal(false)} paciente={modalData} />}
        </Container>
    )
}
