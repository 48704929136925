import React, { useState } from 'react'
import Container from '../components/Container'
import Header from '../components/Header'

const HeaderPadrao = ({ children }) => {
    const [isSubMenuMinimized, setIsSubMenuMinimized] = useState(false);

    const handleSubMenuToggle = (isMinimized) => {
        setIsSubMenuMinimized(isMinimized);
    };
    return (
        <>
            <Header onSubMenuToggle={handleSubMenuToggle} />
            <Container>
                {React.cloneElement(children, { minimizedSubNavbar: isSubMenuMinimized })}
            </Container>
        </>
    );
};

export default HeaderPadrao