import React, { useEffect, useState } from "react";
import { Modal, Container } from './styles'
import { Form } from '@unform/web'
import Input from '../../../../../../components/Input';
import { GoCalendar } from "react-icons/go";

import moment from "moment";
import {useNavigate} from "react-router-dom";
import api from '../../../../../../services/api';
import AgendaAreaCompartilhadaService from "../../../../../../services/AgendaAreaCompartilhadaService";
moment.locale();
export default function DetalhesDoAtendimento({
    id = 'modalDetalhesDoAtendimento',
    atendimentoId,
    onClose = () => {
    }
}) {
    const [atendimento, setAtendimento] = useState();
    const dayName = ["Domingo", "Segunda - Feira", "Terça - Feira", "Quarta - Feira", "Quinta - Feira", "Sexta - Feira", "Sábado"];

    const navigate = useNavigate();

    const handleSubmit = (data) => {
        onClose();
    }

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    }

    useEffect(() => {
        api.get(`api/agenda-compartilhada/${atendimentoId}/?id=${atendimentoId}&?with=terapium,modalidade,tipo_atendimento`).then(response => {
            setAtendimento(response.data);
        });
    }, []);

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            {atendimento && <Container>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Detalhes do Atendimento</label>
                </div>
                <Form onSubmit={handleSubmit}>
                    <div style={{ display: "flex", flexDirection: "row",width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Nome:" name="paciente" value={atendimento.paciente.nome} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <Input disabled title="Data:" name="data" value={dayName[new Date().getDay(moment(atendimento.data_atendimento_inicial).day())]} />
                        </div>
                        <div style={{ width: "25%" }}>
                            <Input disabled title="Horario:" name="horario" value={moment.utc(atendimento.data_atendimento_inicial).format('HH:mm') + ' - ' + moment.utc(atendimento.data_atendimento_final).format('HH:mm')} />
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Plano de saúde:" name="plano_saude" value={atendimento.paciente.plano_saude.plano} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Terapeuta:" name="Terapeuta" value={atendimento.terapeutum.nome} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>

                        <div style={{ width: "50%" }}>
                            <Input disabled title="Terapia:" name="Terapia" value={atendimento.terapium.terapia} />
                        </div>

                        <div style={{ width: "50%" }}>
                            <Input disabled title="Especialidade:" name="Especialidade" value={atendimento.terapium.especialidade} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Modalidade:" name="Modalidade" value={atendimento.modalidade.modalidade} />
                        </div>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Tipo de Atendimento:" name="tipoAtendimento" value={atendimento.tipo_atendimento.tipo_atendimento} />
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0 }}>
                        <div style={{ width: "50%" }}>
                            <Input disabled title="Reserva:" name="reserva" value={atendimento.reserva ? 'Sim' : 'Não'} />
                        </div>
                    </div>


                    <div style={{ display: "flex", flexDirection: "row", width: "100%", paddingTop: 0, justifyContent: "space-between" }}>
                        <button style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", justifyContent: "center",
                            width: "45%",
                            backgroundColor: "#4906AB",
                            color: "#FFFFFF",
                            border: "0",
                            height: 38,
                            borderRadius: 10,
                            marginTop: 15
                        }}
                        onClick={() => {navigate('/dashboard/recepcao/agenda/paciente/' + atendimento.paciente.id)}}>
                            <GoCalendar size={22} color={"white"} style={{ marginRight: 10 }} />
                            <label>Agenda do Paciente</label>
                        </button>

                        <button style={{
                            marginTop: 15,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center", justifyContent: "center",
                            width: "45%",
                            backgroundColor: "#F76397",
                            color: "#FFFFFF",
                            border: "0",
                            height: 38,
                            borderRadius: 10
                        }}>
                            <GoCalendar size={22} color={"white"} style={{ marginRight: 10 }} />
                            <label>Agenda do Terapeuta</label>
                        </button>
                    </div>


                    <Input disabled title="Comentário:" name="comentario" value={atendimento.comentario} />

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 10 }}>
                        <button type="submit" style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }}>
                            Voltar
                        </button>
                    </div>
                </Form>
            </Container>}
        </Modal>
    )
}
