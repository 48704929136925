import { useState, useEffect } from "react";

import { Container, ContainerSub, Header } from './styles';
import api from "../../services/api";
import { useAuth } from "../../context/auth";
import HeaderPadrao from "../../layouts/HeaderPadrao";
import RecadosCard from "../DashboardPadrao/RecadosCard";
import { GridHeader } from "./styles";
import { GridRecados } from "./styles";
import { BsBell } from "react-icons/bs";
import { MdEmail, MdQueryStats } from "react-icons/md";
import { CgChart } from "react-icons/cg";
import { Mostrador, MostradoresColoridos } from "../../components/MostradoresColoridos";
import moment from "moment";
import { AiOutlineReconciliation, AiOutlineSolution, AiOutlineUserAdd } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import AcessoRapido from "../../components/AcessoRapido";
import { SelectLista, VariasListas } from "../../services/ListasServices";


export default function DashboardDesenvolvedor() {
    //#region VARIAVEIS
    const { user } = useAuth();
    const estacaoList = new SelectLista('estacao', 'id,estacao');
    const salaList = new SelectLista('sala', 'id,sala');
    const terapiaList = new SelectLista('terapia', 'id,terapia');
    const tipo_user = user.tipo_user;
    const [recado, setRecado] = useState([]);
    const [mostradoresColoridos, setMostradoresColoridos] = useState({
        titulo: 'Estatísticas',
        mostradores: [],
    });
    const [acessoRapido, setAcessoRapido] = useState([]);
    const [loadingMostradores, setLoadingMostradores] = useState(false);
    const [loadingAcessos, setLoadingAcessos] = useState(false);
    const mostradorStyle = {
        borderTopLeftRadius: '5%',
        borderTopRightRadius: '5%',
        gridArea: 'mostradores',
        margin: '0',
        padding: '0'
    };
    //#endregion

    //#region FUNCOES
    /**
     * Gera componentes de acesso rápido baseado no tipo de usuário.
     * Esta função retorna um conjunto de botões de acesso rápido.
     * @function
     * @returns {Array|null} Um elemento JSX contendo os botões de acesso rápido 
     *                             para o tipo de usuário especificado, ou null se 
     *                             o tipo de usuário não for reconhecido.
    */
    const geraAcessoRapido = async () => {
        switch (tipo_user.toUpperCase()) {
            case 'DESENVOLVEDOR':
                return [
                    [
                        {
                            titulo: 'Notificações',
                            icone: <BsBell size={25} />,
                            link: '/dashboard/desenvolvedor/notificacoes'
                        },
                        {
                            titulo: 'Recados',
                            icone: <MdEmail size={25} />,
                            link: '/dashboard/desenvolvedor/notificacoes/recados'
                        },
                    ],
                    [
                        {
                            titulo: 'Postman',
                            icone: <MdQueryStats size={25} />,
                            link: '/dashboard/desenvolvedor/homem-postagem'
                        },
                        {
                            titulo: 'Graficos',
                            icone: <CgChart size={25} />,
                            link: '/dashboard/desenvolvedor/relatorios/graficos'
                        }
                    ]
                ];
            case 'ADMINISTRADOR':
                return [
                    [
                        {
                            titulo: 'Relatórios Estratégicos',
                            icone: <AiOutlineReconciliation size={25} />,
                            link: '/dashboard/administrador/relatorios'
                        },
                        {
                            titulo: 'Cadastro de Plano de Saúde',
                            icone: <AiOutlineSolution size={25} />,
                            link: '/dashboard/administrador/planodesaude'
                        },
                    ],
                    [
                        {
                            titulo: 'Cadastro de Usuário',
                            icone: <AiOutlineUserAdd size={25} />,
                            link: '/dashboard/administrador/funcionarios'
                        },
                        {
                            titulo: 'Atendimentos',
                            icone: <FaUsers size={25} />,
                            link: '/dashboard/administrador/atendimentos'
                        }
                    ]
                ];
            default:
                return [];
        }
    };

    const geraMostradores = async (informacoes) => {
        let returns = [];
        switch (tipo_user.toUpperCase()) {
            case 'DESENVOLVEDOR':
                returns = [
                    {
                        titulo: 'Pacientes',
                        cor: 'turquesa_medio',
                        valor: informacoes[0]
                            ? typeof informacoes[0] === 'number'
                                ? informacoes[0]
                                : informacoes[0]?.valor
                            : 0,
                        geometria: 'quadrado',
                        detalhamento: informacoes[0]?.detalhamento
                    },
                    {
                        titulo: 'Terapeutas',
                        cor: 'laranja_abobora',
                        valor: informacoes[1]
                            ? typeof informacoes[1] === 'number'
                                ? informacoes[1]
                                : informacoes[1]?.valor
                            : 0,
                        geometria: 'quadrado',
                        detalhamento: informacoes[1]?.detalhamento
                    },
                    {
                        titulo: 'Atendimentos',
                        cor: 'rosa_brilhante',
                        valor: informacoes[2]
                            ? typeof informacoes[2] === 'number'
                                ? informacoes[2]
                                : informacoes[2]?.valor
                            : 0,
                        geometria: 'quadrado',
                        detalhamento: informacoes[2]?.detalhamento
                    },
                    {
                        titulo: 'Agendamentos',
                        cor: 'ceu_azul_claro',
                        valor: informacoes[3]
                            ? typeof informacoes[3] === 'number'
                                ? informacoes[3]
                                : informacoes[3]?.valor
                            : 0,
                        geometria: 'quadrado',
                        detalhamento: informacoes[3]?.detalhamento
                    }
                ];
                break;
            case 'ADMINISTRADOR':
                returns = [
                    {
                        titulo: 'Total Pacientes',
                        cor: 'turquesa_medio',
                        valor: informacoes[0] ? informacoes[0] : 0,
                        geometria: 'quadrado'
                    },
                    {
                        titulo: 'Afastados',
                        cor: 'laranja_abobora',
                        valor: informacoes[1] ? informacoes[1] : 0,
                        geometria: 'quadrado',
                    },
                    {
                        titulo: 'Em Férias',
                        cor: 'rosa_brilhante',
                        valor: informacoes[2] ? informacoes[2] : 0,
                        geometria: 'quadrado',
                    },
                    {
                        titulo: 'Inativos',
                        cor: 'ceu_azul_claro',
                        valor: informacoes[3] ? informacoes[3] : 0,
                        geometria: 'quadrado',
                    }
                ];
                break;
            default:
                return [];
        }
        return returns.map((e) => new Mostrador(e.titulo, e.cor, e.valor, e.geometria, null, e?.detalhamento));
    };

    const onLoad = async () => {
        let informacoes = null;
        const tipoUserUpper = tipo_user.toUpperCase();

        try {
            const recadoResponse = await api.get('api/recado/get/by/group/' + user.tipo_user);
            setRecado(recadoResponse.data);

            if (tipoUserUpper === 'ADMINISTRADOR') {
                const statsResponse = await api.get('api/estatisticas/administrador/' + user.id);
                informacoes = [statsResponse.data?.total_pacientes, statsResponse.data?.afastados, statsResponse.data?.ferias, statsResponse.data?.inativos];
            } else if (tipoUserUpper === 'DESENVOLVEDOR') {
                const statsResponse = await api.get('api/estatisticas/desenvolvedor/' + user.id);
                informacoes = [
                    {
                        valor: statsResponse.data?.pacientes.total,
                        detalhamento: [
                            {
                                nome: 'Ativos',
                                valor: statsResponse.data?.pacientes.ativos
                            },
                            {
                                nome: 'Inativos',
                                valor: statsResponse.data?.pacientes.inativos
                            },
                            {
                                nome: 'Ferias',
                                valor: statsResponse.data?.pacientes.ferias
                            },
                            {
                                nome: 'Afastados',
                                valor: statsResponse.data?.pacientes.afastados
                            },
                            {
                                nome: 'Total',
                                valor: statsResponse.data?.pacientes.total
                            },
                        ]
                    },
                    {
                        valor: statsResponse.data?.terapeutas.total,
                        detalhamento: [
                            {
                                nome: 'Ativos',
                                valor: statsResponse.data?.terapeutas.ativos
                            },
                            {
                                nome: 'Inativos',
                                valor: statsResponse.data?.terapeutas.inativos
                            },
                            {
                                nome: 'Bloqueados',
                                valor: statsResponse.data?.terapeutas.bloqueados
                            },
                            {
                                nome: 'Liberados',
                                valor: statsResponse.data?.terapeutas.liberados
                            },
                            {
                                nome: 'Total',
                                valor: statsResponse.data?.terapeutas.total
                            },
                        ]
                    },
                    {
                        valor: statsResponse.data?.atendimentos.total,
                        detalhamento: [
                            {
                                nome: 'Atendidos Hoje',
                                valor: statsResponse.data?.atendimentos.atendidos_hoje
                            },
                            {
                                nome: 'Não Atendidos',
                                valor: statsResponse.data?.atendimentos.nao_atendidos
                            },
                            {
                                nome: 'Faltas Terapeuta',
                                valor: statsResponse.data?.atendimentos.faltas_terapeutas
                            },
                            {
                                nome: 'Faltas Paciente',
                                valor: statsResponse.data?.atendimentos.faltas_pacientes
                            },
                            {
                                nome: 'Reagendados',
                                valor: statsResponse.data?.atendimentos.reagendados
                            },
                            {
                                nome: 'Total',
                                valor: statsResponse.data?.atendimentos.total
                            },
                        ]
                    },
                    {
                        valor: statsResponse.data?.agendamentos.total,
                        detalhamento: [
                            {
                                nome: 'Padrão',
                                valor: statsResponse.data?.agendamentos.padrao
                            },
                            {
                                nome: 'Avaliação',
                                valor: statsResponse.data?.agendamentos.avaliacao
                            },
                            {
                                nome: 'Avaliação Feita',
                                valor: statsResponse.data?.agendamentos.avaliacao_feita
                            },
                            {
                                nome: 'Reposição',
                                valor: statsResponse.data?.agendamentos.reposicao
                            },
                            {
                                nome: 'Reposição Feita',
                                valor: statsResponse.data?.agendamentos.reposicao_feita
                            },
                            {
                                nome: 'Subistituíção',
                                valor: statsResponse.data?.agendamentos.substituicao
                            },
                            {
                                nome: 'Subistituição Feita',
                                valor: statsResponse.data?.agendamentos.substituicao_feita
                            },
                            {
                                nome: 'Aguardando Terapeuta',
                                valor: statsResponse.data?.agendamentos.aguardando_terapeuta
                            },
                            {
                                nome: 'Total',
                                valor: statsResponse.data?.agendamentos.total
                            },
                        ]
                    }
                ];
            }

            if (informacoes) {
                const mostradoresLocal = await geraMostradores(informacoes);
                setMostradoresColoridos({
                    titulo: 'Estatísticas',
                    mostradores: mostradoresLocal
                });
            }

            const acessoRapido = await geraAcessoRapido();
            setAcessoRapido(acessoRapido);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingMostradores(false);
            setLoadingAcessos(false);
        }
    };
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        if (tipo_user) {
            setLoadingMostradores(true);
            setLoadingAcessos(true);
            onLoad();
        }
    }, [tipo_user]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const todasListas = await VariasListas.getTodasListas([
                    estacaoList,
                    salaList,
                    terapiaList
                ]);
            } catch (error) {
                console.error('Erro ao buscar listas:', error);
            }
        };

        fetchData();
    }, []);
    //#endregion

    //#region HTML
    return (
        <HeaderPadrao>
            <Container className='row'>
                <ContainerSub id='containerSub'>
                    <GridHeader id="header">
                        <Header>
                            <label>Bem Vindo {user?.name && user?.name}!</label>
                            <label>Hoje é dia {moment.utc().format('DD/MM/YYYY')}</label>
                        </Header>
                    </GridHeader>
                    {(tipo_user.toUpperCase() === 'DESENVOLVEDOR' || tipo_user.toUpperCase() === 'ADMINISTRADOR') &&
                        <MostradoresColoridos mostradores={mostradoresColoridos.mostradores} style={mostradorStyle} titulo={mostradoresColoridos.titulo} id="mostradores" className='mostradores' name="mostradores" loading={loadingMostradores} />
                    }
                    <GridRecados id="recados">
                        <RecadosCard title={"Recado"} recado={recado} />
                    </GridRecados>
                    {(tipo_user.toUpperCase() === 'DESENVOLVEDOR' || tipo_user.toUpperCase() === 'ADMINISTRADOR') &&
                        <AcessoRapido id="acesso" name="acesso" acessos={acessoRapido} loading={loadingAcessos} />
                    }
                </ContainerSub>
            </Container>
        </HeaderPadrao>
    );
    //#endregion
}