import React, {useMemo} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import { TableDefaultPagination } from '../../../../components/TableDefaultPagination';

export function TableTerapeutas({source}) {

    const navigate = useNavigate();
    const columns = useMemo(
      () => [
            {
              Header: 'Nome:',
              accessor: 'nome',
            },
            {
              Header: 'Paciente:',
              accessor: 'terapeuta_paciente[0].paciente.nome',
            },
            {
              Header: 'Terapias:',
              accessor: (row, index) => {
                return row.terapeuta_terapia.map(terapia => terapia.terapium.terapia).join(', ')
              }
            },
            {
              Header: "Currículo:",
              accessor: "curriculo",
              Cell: ({ cell }) => (
                (<div style={{ display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "transparent" }}>
                  <button
                    onClick={() => navigate('/dashboard/responsavel/terapeuta/curriculo/' + cell.row.original.id)}
                                        style={{
                      backgroundColor: '#7340BD',
                      color: '#FFFFFF',
                      fontFamily: "Nunito, sans-serif",
                      border: "none",
                      padding: "2px 22px",
                      borderRadius: 8,
                      marginRight: 12
                    }}>
                    Abrir
                  </button>
                </div>
                )
              )
            }
          ],
      []
    )
    const TextField = styled.input`
        height: 32px;
        width: 200px;
        border-radius: 3px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #e5e5e5;
        padding: 0 32px 0 16px;

        &:hover {
            cursor: pointer;
        }`;

    const ClearButton = styled.button`
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 34px;
        width: 32px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: '#7441BF';
        border-width: 0
    `;

    return (
      <TableDefaultPagination columns={columns} source={source}/>
    )
}
