import React, { useRef, useState, useEffect, forwardRef } from 'react';
import { Form } from '@unform/web';
import Input from "../../Input";
import Select from "../../Select";
import Button from "../../Button"
import { Container, ModalNovoPaciente } from './styles';
import { useNavigate } from "react-router-dom";
import { ListaEsperaDemandaService, ListaEsperaResponsaveisService, ListaEsperaHistoricoService, ListaEsperaService } from '../../../services/ListaEsperaService';
import moment from 'moment/moment';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import InputMask from '../../InputMask';
import { AiOutlineDelete } from 'react-icons/ai';
import { useAuth } from '../../../context/auth';
import { ClipLoader } from 'react-spinners';
import ModalTitulo from 'components/ModalTitulo';
import { CgAdd, CgClose } from 'react-icons/cg';
import SelectPaciente from 'components/Select/SelectPaciente';
import useDiagnosticosData from 'context/diagnostico';
import usePlanoSaudeData from 'context/plano-saude';
import { FaBroom } from 'react-icons/fa';

function PacienteListaEsperaModal({ id2 = 'lista-espera', onClose, tipoDemandaOptions, modalInfo, callback, readOnly = false }, ref) {
    //#region VARIAVEIS====\\
    /* INFORMACOES DA PÁGINA */
    // eslint-disable-next-line no-unused-vars
    const titulo = 'Modal Lista de Espera';
    // eslint-disable-next-line no-unused-vars
    const descricao = 'Modal que permite editar e cadastrar itens da lista de espera.';
    /* UTILIZATARIOS */
    const Usuario = useAuth();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const formRef = useRef(null);
    /* LISTAS de SELEÇÃO */
    const { diagnosticosOptions, fetchDiagnosticos } = useDiagnosticosData();
    const { planoSaudeOptions, fetchPlanoSaudeTerapiaData } = usePlanoSaudeData();
    const [especialidadeSelectBox, setEspecialidadeSelectBox] = useState([]);
    const [terapiaSelectBox, setTerapiaSelectBox] = useState([]);
    /* DADOS DE ENVIO */
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [diagnosticoSelecionadoOpt, setDiagnosticoSelecionadoOpt] = useState([]);
    const [tipoDemandaSelecionadoOpt, setTipoDemandaSelecionadoOpt] = useState(null);
    const [listaEsperaId, setListaEsperaId] = useState(null);
    const [pacienteId, setPacienteId] = useState(null);
    const [periodo, setPeriodo] = useState();
    const options = [
        { value: 'manha', label: 'Manhã', selected: 'true' },
        { value: 'tarde', label: 'Tarde' },
        { value: 'noite', label: 'Noite' },
    ]
    const [comentarios, setComentarios] = useState('');
    const [demandas, setDemandas] = useState([]);
    const [responsaveis, setResponsaveis] = useState([]);
    const [historicos, setHistoricos] = useState([]);
    /* listas de renderização */
    const [demandasList, setDemandasList] = useState([]);
    const [responsaveisList, setResponsaveisList] = useState([]);
    const [historicosList, setHistoricosList] = useState([]);
    /* CARERGADORES */
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    /* CAMPOS DAS LISTAS */
    /* demanda */
    const [terapiaSelecionado, setTerapiaSelecionado] = useState(null);
    const [especialidadeSelecionado, setEspecialidadeSelecionado] = useState(null);
    const [planoDeSaudeSelecionado, setPlanoDeSaudeSelecionado] = useState([]);
    const [quantidade, setQuantidade] = useState("");
    /* responsavel */
    const [responsavel, setResponsavel] = useState("");
    const [parentesco, setParentesco] = useState("");
    const [contato, setContato] = useState("");
    /* historico */
    const [dataHistorico, setDataHistorico] = useState("");
    const [comentarioHistorico, setComentarioHistorico] = useState("");

    const [diasList, setDiasList] = useState([]);/* Dias da Semana */
    const [submitionOn, setSubmitionOn] = useState(false);
    //#endregion
    //#region FUNCOES
    const getDefaultPeriodo = (periodo) => {
        if (!modalInfo) return;
        const periodoPaciente = periodo.filter(data => {
            return data.value === modalInfo.periodo;
        })[0];
        setPeriodo(periodoPaciente);
    }
    const adicionarNovaEspera = async (data) => {
        if (!data && (!data.nome || !data.nascimento || !periodo)) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            let dataPaciente;
            if (pacienteSelecionadoOpt) {
                dataPaciente = {
                    'paciente_id': pacienteSelecionadoOpt.value,
                    'tipo_demanda_id': tipoDemandaSelecionadoOpt?.value
                };
            } else {
                dataPaciente = {
                    'nome': data.nome,
                    'identificador': data.identificador,
                    'nascimento': data.nascimento.split('/').reverse().join('-') + ' 00:00:00',
                    'diagnostico': diagnosticoSelecionadoOpt?.value,
                    'tipo_demanda_id': tipoDemandaSelecionadoOpt?.value
                };
            }
            dataPaciente = {
                ...dataPaciente,
                'periodo': periodo.label,
                'comentarios': comentarios,
                'segunda': diasList.segunda ? true : false,
                'terca': diasList.terca ? true : false,
                'quarta': diasList.quarta ? true : false,
                'quinta': diasList.quinta ? true : false,
                'sexta': diasList.sexta ? true : false,
                'sabado': diasList.sabado ? true : false,
                'domingo': false,
                'status': '0'
            }
            await ListaEsperaService.store(dataPaciente)
                .then((response) => {
                    Swal.fire('Dados da espera criados com sucesso.', '', 'success');
                    let id = response.data.id;/* ID da lista de espera recém registrada */
                    if (demandas.length >= 0) {
                        demandas.forEach((data) => {
                            let obj = {
                                'lista_espera_id': id,
                                'terapia_id': data.terapia_id,
                                'especialidade_id': data.especialidade_id,
                                'plano_saude_id': data.plano_saude_id,
                                'quantidade': parseInt(data.quantidade)
                            };
                            ListaEsperaDemandaService.store(obj);
                        });
                    }

                    if (responsaveis.length >= 0) {
                        responsaveis.forEach((data) => {
                            let obj = {
                                'lista_espera_id': id,
                                'responsavel': data.responsavel,
                                'contato': data.contato,
                                'grau_parentesco': data.grau_parentesco
                            };
                            ListaEsperaResponsaveisService.store(obj);
                        });
                    }

                    if (historicos.length >= 0) {
                        historicos.forEach((data) => {
                            let obj = {
                                'lista_espera_id': id,
                                'data': data.data,
                                'comentario': data.comentario
                            };
                            ListaEsperaHistoricoService.store(obj);
                        });
                    }

                    callback();
                })
                .catch(() => Swal.fire('Houve um erro ao adicionar a espera. Tente novamente.', '', 'error'));
            onClose();
            callback();
        };
    }
    async function atualizaPaciente(data, id) {
        if (!data && (!data.nome || !data.nascimento || !periodo)) {
            Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            let dataPaciente;
            if (pacienteSelecionadoOpt) {
                dataPaciente = {
                    'paciente_id': pacienteSelecionadoOpt.value,
                    'tipo_demanda_id': tipoDemandaSelecionadoOpt?.value
                };
            } else {
                dataPaciente = {
                    'nome': data.nome,
                    'identificador': data.identificador,
                    'nascimento': data.nascimento.split('/').reverse().join('-') + ' 00:00:00',
                    'diagnostico': diagnosticoSelecionadoOpt?.value,
                    'tipo_demanda_id': tipoDemandaSelecionadoOpt?.value
                };
            }
            dataPaciente = {
                ...dataPaciente,
                'periodo': periodo.label,
                'comentarios': comentarios,
                'segunda': diasList.segunda ? true : false,
                'terca': diasList.terca ? true : false,
                'quarta': diasList.quarta ? true : false,
                'quinta': diasList.quinta ? true : false,
                'sexta': diasList.sexta ? true : false,
                'sabado': diasList.sabado ? true : false
            }
            await ListaEsperaService.update(dataPaciente, id)
                .then((response) => {
                    Swal.fire('Dados da espera atualizados com sucesso.', '', 'success');
                    let id = response.data.id;
                    if (demandas.length > 0) {
                        demandas.forEach((demanda) => {
                            demanda.lista_espera_id = id;
                            ListaEsperaDemandaService.store(demanda);
                        });
                    }

                    if (responsaveis.length > 0) {
                        responsaveis.forEach((responsavel) => {
                            responsavel.lista_espera_id = id;
                            ListaEsperaResponsaveisService.store(responsavel);
                        });
                    }

                    if (historicos.length > 0) {
                        historicos.forEach((historico) => {
                            historico.lista_espera_id = id;
                            ListaEsperaHistoricoService.store(historico);
                        });
                    }

                    callback();
                }).catch(() => {
                    Swal.fire('Houve um erro ao atualizar os dados dessa espera. Tente novamente.', '', 'error')
                }).finally(() => {
                    onClose();
                    callback();
                })
        }

    }
    const getEspera = (data) => {
        if (data?.lista_espera_demandas) {
            setDemandasList(data.lista_espera_demandas);
        };
        if (data?.lista_espera_responsavels) {
            setResponsaveisList(data.lista_espera_responsavels);
        };
        if (data?.lista_espera_historicos) {
            setHistoricosList(data.lista_espera_historicos);
        };
    };
    function functionThatSubmitsForm() {
        setSubmitionOn(true);
        formRef.current.submitForm();
    };
    //#endregion
    //#region HANDLES
    const handleOutsideClick = (e) => {
        if (e.target.id === id2) onClose();
    };
    const handleChangeDiasList = (e) => {
        let diasListLocal = diasList;
        if (e.target) {
            diasListLocal[e.target.name] = e.target.checked;
        } else {
            diasListLocal[e.name] = e.checked;
        }
        setDiasList(diasList);
    }
    const handleSetComentarios = (e) => {
        setComentarios(e.target.value);
    };
    const handleSubmit = (data) => {
        try {
            if (listaEsperaId) {
                atualizaPaciente(data, listaEsperaId);
            } else {
                adicionarNovaEspera(data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setSubmitionOn(false);
        };
    }
    const handleSetDemandas = () => {
        if (terapiaSelecionado === null || especialidadeSelecionado === null || planoDeSaudeSelecionado === null || quantidade === "") {
            Swal.fire('Por favor preencha todos os campos da demanda!', '', 'warning');
        } else {
            let newDemandasList = [
                ...demandasList,
                {
                    terapia_id: terapiaSelecionado.value,
                    especialidade_id: especialidadeSelecionado.value,
                    plano_saude_id: planoDeSaudeSelecionado.value,
                    quantidade: quantidade,
                    plano_saude: {
                        plano_saude_id: planoDeSaudeSelecionado.value,
                        plano: planoDeSaudeSelecionado.label,
                    },
                    especialidade: {
                        especialidade_id: especialidadeSelecionado.value,
                        especialidade: especialidadeSelecionado.label,
                    },
                    terapium: {
                        terapia_id: terapiaSelecionado.value,
                        terapia: terapiaSelecionado.label,
                    }
                }];
            setDemandasList(newDemandasList);
            setDemandas([...demandas,
            {
                terapia_id: terapiaSelecionado.value,
                especialidade_id: especialidadeSelecionado.value,
                plano_saude_id: planoDeSaudeSelecionado.value,
                quantidade: quantidade,
                plano_saude: {
                    plano_saude_id: planoDeSaudeSelecionado.value,
                    plano: planoDeSaudeSelecionado.label,
                },
                especialidade: {
                    especialidade_id: especialidadeSelecionado.value,
                    especialidade: especialidadeSelecionado.label,
                },
                terapium: {
                    terapia_id: terapiaSelecionado.value,
                    terapia: terapiaSelecionado.label,
                }
            }]);
            setTerapiaSelecionado(null);
            setEspecialidadeSelecionado(null);
            setPlanoDeSaudeSelecionado(null);
            setQuantidade("");

            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
        };
    }

    const handleDeleteDemanda = (demanda) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (demanda.lista_espera_id === null || demanda.lista_espera_id === undefined) {
                    let demandasLocal = [...demandasList];
                    const index = demandasLocal.indexOf(demanda);
                    demandasLocal.splice(index, 1);

                    setDemandasList(demandasLocal);
                } else {
                    let demandasLocal = [...demandasList];
                    const index = demandasLocal.indexOf(demanda);
                    demandasLocal.splice(index, 1);

                    setDemandasList(demandasLocal);
                    ListaEsperaDemandaService.destroy(demanda.id);
                    getEspera();
                    callback();
                }
                snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    }
    const handleChangePlanoSaude = (event) => {
        setPlanoDeSaudeSelecionado(event);
        let selectTerapia = [];
        event.plano_saude_terapia.forEach(terapiaItem => {
            const { terapia_id, especialidade_id, especialidade, terapium } = terapiaItem;
            // Procura pela terapia atual na lista de selectTerapia
            let terapiaExistente = selectTerapia.find(t => t.value === terapia_id);
            if (!terapiaExistente) {
                // Se a terapia ainda não existir na lista, cria um novo objeto terapia com a especialidade
                terapiaExistente = {
                    value: terapia_id,
                    label: terapium.terapia,
                    especialidades: []
                };
                selectTerapia.push(terapiaExistente);
            }
            // Verifica se a especialidade já foi adicionada a esta terapia
            if (!terapiaExistente.especialidades.some(e => e.value === especialidade_id)) {
                terapiaExistente.especialidades.push({
                    value: especialidade_id,
                    label: especialidade.especialidade
                });
            }
        });
        setTerapiaSelectBox(selectTerapia);
    };
    const handleChangeTerapia = (e) => {
        setTerapiaSelecionado(e);
        const selectEspecialidade = e.especialidades;
        setEspecialidadeSelectBox(selectEspecialidade);
    };
    const handleChangeEspecialidade = (e) => {
        setEspecialidadeSelecionado(e);
    };
    const handleSetQuantidade = (e) => {
        setQuantidade(e.target.value);
    };
    const handleSetResponsaveis = () => {
        if (responsavel === "" || parentesco === "" || contato === "") {
            Swal.fire('Por favor preencha todos os campos do responsável!', '', 'warning');
        } else {
            setResponsaveisList([...responsaveisList,
            {
                responsavel: responsavel,
                grau_parentesco: parentesco,
                contato: contato,
            }
            ]);
            setResponsaveis([...responsaveis,
            {
                responsavel: responsavel,
                grau_parentesco: parentesco,
                contato: contato,
            }
            ]);
            setResponsavel("");
            setParentesco("");
            setContato("");

            snackbar.displayMessage("Cadastro criado com sucesso!", "success")
                .catch(() => { Swal.fire('Houve um problema ao cadastrar!', '', 'warning') });
        };
    }
    const handleDeleteResponsavel = (responsavel) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (responsavel.lista_espera_id === null || responsavel.lista_espera_id === undefined) {
                    let responsaveisLocal = [...responsaveisList];
                    const index = responsaveisLocal.indexOf(responsavel);
                    responsaveisLocal.splice(index, 1);

                    setResponsaveisList(responsaveisLocal);
                } else {
                    let responsaveisLocal = [...responsaveisList];
                    const index = responsaveisLocal.indexOf(responsavel);
                    responsaveisLocal.splice(index, 1);

                    setResponsaveisList(responsaveisLocal);
                    ListaEsperaResponsaveisService.destroy(responsavel.id);
                    getEspera();
                    callback();
                }
                snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    }
    const handleChangeResponsavel = (e) => {
        setResponsavel(e.target.value);
    }
    const handleChangeParentesco = (e) => {
        setParentesco(e.target.value);
    }
    const handleChangeContato = (e) => {
        setContato(e.target.value);
    }
    const handleSetHistoricos = () => {
        if (dataHistorico === "" || comentarioHistorico === "") {
            Swal.fire('Por favor preencha todos os campos do histórico!', '', 'warning');
        } else {
            setHistoricosList([...historicosList,
            {
                data: dataHistorico,
                comentario: comentarioHistorico,
            }
            ]);
            setHistoricos([...historicos,
            {
                data: dataHistorico,
                comentario: comentarioHistorico,
            }
            ]);
            setDataHistorico("");
            setComentarioHistorico("");

            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
        };
    }
    const handleDeleteHistorico = (historico) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (historico.lista_espera_id === null || historico.lista_espera_id === undefined) {
                    let historicosLocal = [...historicosList];
                    const index = historicosLocal.indexOf(historico);
                    historicosLocal.splice(index, 1);

                    setHistoricosList(historicosLocal);
                } else {
                    let historicosLocal = [...historicosList];
                    const index = historicosLocal.indexOf(historico);
                    historicosLocal.splice(index, 1);

                    setHistoricosList(historicosLocal);
                    ListaEsperaHistoricoService.destroy(historico.id);
                    getEspera();
                    callback();
                }
                snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    .catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
            }
        });
    }
    const handleChangeData = (e) => {
        setDataHistorico(e.target.value);
    }
    const handleChangeComentario = (e) => {
        setComentarioHistorico(e.target.value);
    }
    //#endregion
    //#region USE EFFECT
    const onLoad = async () => {
        setLoadingDadosIniciais(true);
        if (modalInfo) {
            setDemandas(modalInfo.lista_espera_demandas);
            setResponsaveis(modalInfo.lista_espera_responsavels);
            setHistoricos(modalInfo.lista_espera_historicos);
        } else {
            setDemandas([]);
            setResponsaveis([]);
            setHistoricos([]);
        }
        setLoadingDadosIniciais(false);
    }
    useEffect(() => {/* ON LOAD */
        fetchPlanoSaudeTerapiaData();
        fetchDiagnosticos();
        onLoad();
    }, []);
    useEffect(() => {
        if (modalInfo) {
            getEspera(modalInfo);
            setListaEsperaId(modalInfo?.id);
            setPacienteId(modalInfo?.paciente_id);
            setComentarios(modalInfo?.comentario);
        }
        getDefaultPeriodo(options);
    }, [modalInfo]);
    useEffect(() => {
        if (modalInfo?.tipo_demanda_id) {
            const tipo = tipoDemandaOptions.filter((filter) => modalInfo.tipo_demanda_id === filter.value);
            if (tipo) {
                setTipoDemandaSelecionadoOpt(tipo[0])
            }
        }
    }, [modalInfo, tipoDemandaOptions]);
    useEffect(() => {
        if (modalInfo?.diagnostico_id) {
            const diagnostico = diagnosticosOptions.filter((filter) => modalInfo.diagnostico_id === filter.value);
            if (diagnostico) {
                setDiagnosticoSelecionadoOpt(diagnostico[0])
            }
        }
    }, [modalInfo, diagnosticosOptions]);
    //#endregion
    //#region HTML
    return (
        <ModalNovoPaciente id={id2} onMouseDown={handleOutsideClick}>
            <Container className='pt-0'>
                <nav className='navbar sticky-top bg-cinza-claro shadow' style={{ margin: '0px -30px 0px -30px' }}>
                    <div className="container-fluid d-flex align-items-center justify-content-between">
                        <div className='w-200px'>
                            <ClipLoader size={22} color={'#000'} loading={loadingDadosIniciais || algoCarregando} />
                            {(!loadingDadosIniciais && modalInfo && !pacienteId && !readOnly)
                                && <div className='col-12 d-flex'>
                                    <div className=''>
                                        <Button className="btn-padrao font-branca" onClick={() => navigate('/dashboard/' + Usuario.user.tipo_user + '/pacientes/novo/'+listaEsperaId, {
                                            state: { modalInfo, list: true }
                                        })}>
                                            Efetivar Paciente
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                        <ModalTitulo>{modalInfo === "" ? "Adicionar Nova Espera" : "Detalhes da Espera"}</ModalTitulo>
                        <label className='btn-outline w-200px text-right' onClick={() => onClose()}>
                            <CgClose size={22} color='red' />
                        </label>
                    </div>
                </nav>
                <br></br>
                <div className='row' style={{ overflowX: 'hidden', overflowY: 'scroll' }}>
                    <div className='align-items-center col-12'>
                        {loadingDadosIniciais
                            ? <></>
                            : <Form onSubmit={handleSubmit} className='pt-2' ref={formRef}>
                                <fieldset className="ns-fieldset">
                                    <legend>Dados do Paciente</legend>
                                    <div className='col'>
                                        <div className='row'>
                                            {(listaEsperaId && pacienteId) || !listaEsperaId
                                                ? <div className='col-12 d-flex align-items-end'>
                                                    <SelectPaciente
                                                        label='Paciente base:'
                                                        id='paciente_select'
                                                        name='paciente'
                                                        style={{ width: readOnly ? '100%' : '92%' }}
                                                        plusWith='diagnostico1,diagnostico2'
                                                        plusSelect='nascimento'
                                                        value={pacienteSelecionadoOpt}
                                                        defaultValue={pacienteId}
                                                        setCarregando={setAlgoCarregando}
                                                        setPaciente={(paciente) => {
                                                            setPacienteSelecionadoOpt(paciente);
                                                            setAlgoCarregando(false);
                                                        }}
                                                        controlStyle={readOnly ? {} : { borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                                        setTerapias={() => { }}
                                                        readOnly={readOnly}
                                                        required={true}
                                                    />
                                                    <Button className={`p-0 h-40px pb-3 pt-3${readOnly ? ' some' : ''}`} onClick={() => setPacienteSelecionadoOpt(null)} style={{ width: '8%', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button'>
                                                        <FaBroom size={20}></FaBroom>
                                                    </Button>
                                                </div>
                                                : <></>
                                            }
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 col-lg-6'>
                                                {pacienteSelecionadoOpt
                                                    ? <Input title="Nome do Paciente:" name="nome" value={pacienteSelecionadoOpt?.nome} disabled={'disabled'} />
                                                    : <Input title="Nome do Paciente:" name="nome" defaultValue={modalInfo?.nome} required={!readOnly ? 'required' : null} disabled={readOnly} />
                                                }
                                            </div>
                                            <div className='col-sm-12 col-lg-4'>
                                                {pacienteSelecionadoOpt
                                                    ? <Input title="Data de Nascimento:" name="nascimento" type="date" icon={null} disabled={true} value={pacienteSelecionadoOpt?.nascimento?.split('T')[0]} readOnly={readOnly} />
                                                    : <Input title="Data de Nascimento:" type="date" icon={null} name="nascimento" required={!readOnly ? 'required' : null} defaultValue={modalInfo?.nascimento === undefined ? "" : modalInfo.nascimento.split('T')[0]} disabled={readOnly} />
                                                }
                                            </div>
                                            <div className='col-sm-12 col-lg-2'>
                                                {pacienteSelecionadoOpt
                                                    ? <Input title="Identificador:" name="identificador" value={pacienteSelecionadoOpt?.identificador} disabled={'disabled'} readOnly={readOnly} />
                                                    : <Input maxLength={3} title="Identificador:" name="identificador" defaultValue={modalInfo?.identificador} disabled={readOnly} />
                                                }
                                            </div>
                                        </div>
                                        <div className='row'>
                                            {pacienteSelecionadoOpt
                                                ? <>
                                                    <div className='col-lg-5 col-sm-12'>
                                                        <Input title="Diagnóstico 1:" name={'_diagnostico1'} value={pacienteSelecionadoOpt.diagnostico1} disabled={'disabled'} readOnly={'readOnly'}></Input>
                                                    </div>
                                                    <div className='col-lg-4 col-sm-12'>
                                                        <Input title="Diagnóstico 2:" name={'_diagnostico2'} value={pacienteSelecionadoOpt.diagnostico2} disabled={'disabled'} readOnly={'readOnly'}></Input>
                                                    </div>
                                                </>
                                                :
                                                <div className='col-lg-9 col-sm-12'>
                                                    <Select label="Diagnóstico:" name="diagnostico" options={diagnosticosOptions} value={diagnosticoSelecionadoOpt} onChange={setDiagnosticoSelecionadoOpt} required={!readOnly ? 'required' : null} readOnly={readOnly} />
                                                </div>
                                            }
                                            <div className='col-lg-3 col-sm-12'>
                                                <Select name="periodo" label={'Período:'} value={periodo} options={options} onChange={setPeriodo} required={!readOnly ? 'required' : null} readOnly={readOnly} />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="ns-fieldset">
                                    <legend>Informações da Espera</legend>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <Select name="tipo_demanda" label="Tipo da Demanda:" options={tipoDemandaOptions} value={tipoDemandaSelecionadoOpt} onChange={setTipoDemandaSelecionadoOpt} required={!readOnly ? 'required' : null} readOnly={readOnly}></Select>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="ns-fieldset">
                                    <legend>Demandas</legend>
                                    {!readOnly &&
                                        <div className='row'>
                                            <div className='col-lg-3 col-sm-12 pe-0'>
                                                <Select label="Plano de Saúde:" name="plano_saude_id" options={planoSaudeOptions} onChange={e => handleChangePlanoSaude(e)} value={planoDeSaudeSelecionado} />
                                            </div>
                                            <div className='col-lg-3 col-sm-12 p-0'>
                                                <Select label="Terapia:" name="terapia_id" options={terapiaSelectBox} onChange={e => handleChangeTerapia(e)} value={terapiaSelecionado} />
                                            </div>
                                            <div className='col-lg-3 col-sm-12 p-0'>
                                                <Select label="Especialidade:" name="especialidade_id" options={especialidadeSelectBox} onChange={e => handleChangeEspecialidade(e)} value={especialidadeSelecionado} />
                                            </div>
                                            <div className='col-lg-2 col-sm-12 p-0'>
                                                <Input title="Quantidade:" name="quantidade" type="number" onChange={e => handleSetQuantidade(e)} value={quantidade} />
                                            </div>
                                            <div className='col-1 ps-0 d-flex justify-content-center align-items-end'>
                                                <Button className='p-0 h-40px pb-3 pt-3' onClick={e => handleSetDemandas()}>
                                                    <CgAdd size={20}></CgAdd>
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {demandasList === undefined ? "" : demandasList.map((item, index) => {
                                        return (
                                            <div key={index} className={`row pt-${index === 0 ? '3' : '0'}`}>
                                                <div className='col-lg-3 col-sm-12 pe-0'>
                                                    <Input name="plano_saude" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.plano_saude.plano} readOnly={readOnly} />
                                                </div>
                                                <div className='col-lg-3 col-sm-12 p-0'>
                                                    <Input name="terapia" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.terapium.terapia} readOnly={readOnly} />
                                                </div>
                                                <div className='col-lg-3 col-sm-12 p-0'>
                                                    <Input name="especialidade" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.especialidade.especialidade} readOnly={readOnly} />
                                                </div>
                                                <div className='col-lg-2 col-sm-12 p-0'>
                                                    <Input name="quantidade" className='p-0' type="number" value={item === undefined ? "" : item.quantidade} readOnly={readOnly} disabled={'disabled'} />
                                                </div>
                                                <div className='col-lg-1 col-sm-12 ps-0 d-flex align-items-end justify-content-center'>
                                                    <div className='input-disabled pointer d-flex align-items-center justify-content-center w-100'>
                                                        <AiOutlineDelete size={20} onClick={e => handleDeleteDemanda(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </fieldset>
                                {!pacienteSelecionadoOpt &&
                                    <fieldset className="ns-fieldset">
                                        <legend>Responsáveis</legend>
                                        {!readOnly &&
                                            <div className='row'>
                                                <div className='col-lg-4 col-sm-12 pe-0'>
                                                    <Input title="Responsável:" name="responsavel" value={responsavel} onChange={handleChangeResponsavel} />
                                                </div>
                                                <div className='col-lg-3 col-sm-12 p-0'>
                                                    <Input title="Grau de Parentesco: " name="grau_parentesco" value={parentesco} onChange={handleChangeParentesco} />
                                                </div>
                                                <div className='col-lg-4 col-sm-12 p-0'>
                                                    <InputMask title="Contato:" name="contato" icon={null} maskFormat={"(99)99999-9999"} value={contato} onChange={handleChangeContato} />
                                                </div>
                                                <div className='col-1 ps-0 d-flex justify-content-center align-items-end'>
                                                    <Button className='p-0 h-40px pb-3 pt-3' onClick={e => handleSetResponsaveis()}>
                                                        <CgAdd size={20}></CgAdd>
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                        {responsaveisList === undefined ? "" : responsaveisList.map((item, index) => {
                                            return (
                                                <div key={index} className={`row pt-${index === 0 ? '3' : '0'}`}>
                                                    <div className='col-lg-4 col-sm-12 pe-0'>
                                                        <Input name="resposavel" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.responsavel} />
                                                    </div>
                                                    <div className='col-lg-3 col-sm-12 p-0'>
                                                        <Input name="grau_parentesco" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.grau_parentesco} />
                                                    </div>
                                                    <div className='col-lg-4 col-sm-12 p-0'>
                                                        <Input name="contato" className='p-0' disabled={'disabled'} type="text" value={item === undefined ? "" : item.contato} />
                                                    </div>
                                                    <div className='col-lg-1 col-sm-12 d-flex align-items-end justify-content-center ps-0'>
                                                        <div className='input-disabled pointer d-flex align-items-center justify-content-center w-100'>
                                                            <AiOutlineDelete size={20} onClick={e => handleDeleteResponsavel(item)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </fieldset>
                                }
                                <fieldset className="ns-fieldset">
                                    <legend>Histórico</legend>
                                    {!readOnly &&
                                        <div className='row'>
                                            <div className='col-lg-4 col-sm-12 pe-0'>
                                                <Input title="Data:" type="date" name="data" value={dataHistorico} onChange={handleChangeData} />
                                            </div>
                                            <div className='col-lg-7 col-sm-12 p-0'>
                                                <Input title="Comentário: " name="comentario" value={comentarioHistorico} onChange={handleChangeComentario} />
                                            </div>
                                            <div className='col-1 ps-0 d-flex justify-content-center align-items-end' style={{ paddingTop: '30px' }}>
                                                <Button className='p-0 h-40px ps-2 pe-2' onClick={e => handleSetHistoricos()}>
                                                    <CgAdd size={20}></CgAdd>
                                                </Button>
                                            </div>
                                        </div>
                                    }
                                    {historicosList === undefined ? "" : historicosList.map((item, index) => {
                                        return (
                                            <div key={index} className={`row pt-${index === 0 ? '3' : '0'}`}>
                                                <div className='col-lg-4 col-sm-12 pe-0'>
                                                    <Input name="data" className='p-0' disabled={'disabled'} value={item === undefined ? "" : moment.utc(item.data).format('DD/MM/YYYY')} />
                                                </div>
                                                <div className='col-lg-7 col-sm-12 p-0'>
                                                    <Input name="comentario" className='p-0' disabled={'disabled'} value={item === undefined ? "" : item.comentario} />
                                                </div>
                                                <div className='col-lg-1 col-sm-12 ps-0 d-flex align-items-end justify-content-center'>
                                                    <div className='input-disabled pointer d-flex align-items-center justify-content-center w-100'>
                                                        <AiOutlineDelete size={20} onClick={e => handleDeleteHistorico(item)} />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </fieldset>
                                <fieldset className="ns-fieldset">
                                    <legend>Dias Disponíveis</legend>
                                    <div className='d-flex flex-wrap'>
                                        <div className='col-12 d-flex flex-nowrap justify-content-around'>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='segunda' name='segunda' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.segunda : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='segunda'>Segunda-feira</label>
                                            </div>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='terca' name='terca' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.terca : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='terca'>Terça-feira</label>
                                            </div>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='quarta' name='quarta' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.quarta : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='quarta'>Quarta-feira</label>
                                            </div>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='quinta' name='quinta' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.quinta : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='quinta'>Quinta-feira</label>
                                            </div>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='sexta' name='sexta' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.sexta : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='sexta'>Sexta-feira</label>
                                            </div>
                                            <div className='form-check align-items-center d-flex'>
                                                <input type='checkbox' className='form-check-input' id='sabado' name='sabado' onChange={handleChangeDiasList} defaultChecked={modalInfo ? modalInfo?.sabado : false} disabled={readOnly}></input>
                                                <label className="ml-10px form-check-label" htmlFor='sabado'>Sábado</label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset className="ns-fieldset pb-0">
                                    <legend>Comentários Gerais</legend>
                                    <textarea onChange={handleSetComentarios} defaultValue={modalInfo === null ? "" : modalInfo.comentarios} className='w-100per h-200px text-area-padrao border-none' name={'comentarios'} required readOnly={readOnly} />
                                </fieldset>
                                <div className='col-12 d-flex justify-content-end'>
                                    {!readOnly &&
                                        <button type='button' onClick={functionThatSubmitsForm} disabled={submitionOn} className='btn-padrao font-branca'>
                                            {submitionOn
                                                ? <ClipLoader />
                                                : listaEsperaId === undefined ? "Adicionar Espera" : "Atualizar Espera"
                                            }
                                        </button>
                                    }
                                </div>
                            </Form>
                        }
                    </div>
                </div>
            </Container>
        </ModalNovoPaciente>
    );
    //#endregion
}

export default forwardRef(PacienteListaEsperaModal);