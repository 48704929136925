import React, { useEffect, useState } from "react";
import { AiOutlineUser, AiOutlineBell, AiOutlinePlusCircle, } from "react-icons/ai";
import { Container, DivHeaderStyle } from './styles';
import api from "../../../services/api";
import UsuarioLogado from "../../../components/UsuarioLogado";
import { MainDivStyle, DivLabelStyle, TextFormat } from "./styles";
import Coordenacao from "../../../layouts/Coordenacao";
import AvaliacaoModal from "../../../components/Modais/AvaliarSolicitacaoMaterial";
import Swal from "sweetalert2";
import moment from "moment/moment";
import { ButtonRegister } from "../../AdminDashboard/Terapeutas/styles";
import Materiais from "../../TerapeutaDashboard/Dashboard/Modal/Materiais";
import "./styles.css";
import "../../../styles/global.css"

export default function AvaliacaoDeMateriais() {

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [modalSolicitacoes, setModalSolicitacoes] = useState(false);
    const [avaliacaoModal, setAvaliacaoModal] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [dadosPacientes, setDadosPacientes] = useState([]);
    const [pacientes, setPacientes] = useState(null);

    const handleOpenModalAvaliacao = (item) => {
        setModalData(item);
        setAvaliacaoModal(true);
    }

    const getSolicitacoes = () => {
        try {
            api.get(`api/solicitacao-material?with=terapium,paciente,user.terapeuta,status_solicitacao&filter=[status_solicitacao]=${1}`).then(({ data }) => {
                data.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                })
                setSolicitacoes(data);
            })
        }
        catch (e) {
            Swal.fire("Erro ao carregar as solicitações");
        }
    }

    const getPacientes = () => {
        api.get(`api/paciente?with=terapia_pacientes.terapium,terapia_pacientes.especialidade,plano_saude`).then(({ data }) => {
            let pacienteOptions = [];
            data.forEach((paciente) => {
                pacienteOptions.push({
                    value: paciente.id,
                    label: paciente.nome,
                    plano: paciente.plano_saude.plano,
                    identificador: paciente.identificador
                });
            });
            setPacientes(pacienteOptions);
        });
    };

    const dadosSolicitacao = () => {
        let dadosSolicitacao = [];
        solicitacoes.forEach((data) => {
            dadosSolicitacao.push(
                {
                    id: data.id,
                    nome: data.paciente.nome,
                    status: data.status_solicitacao.status,
                    data_atendimento: (moment(data.created_at).format('DD/MM/YYYY')),
                    terapia: data.terapium.terapia,
                    data_util: (moment(data.data_da_utilizacao).format('DD/MM/YYYY')),
                    descricao: data.descricao,
                    terapeuta: data.user === null ? 'Erro: houve um problema ao carregar' : data.user.name,
                    tempo_uso: data.tempo_de_uso,
                    responsavel: data.paciente.responsavel.nome,
                });
            setDadosPacientes(dadosSolicitacao);
        });
    }

    useEffect(() => {
        getSolicitacoes();
        getPacientes();
    }, []);

    useEffect(() => {
        dadosSolicitacao();
    }, [solicitacoes])

    return (
        <Container>
            <div style={MainDivStyle}>
                <div>
                    <div style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", marginRight: 20,
                    }}>
                        <label style={{
                            fontFamily: "Nunito, sans-serif", color: "#7340BD",
                            fontWeight: "bold", cursor: "pointer"
                        }}>
                            Solicitação de Materiais
                        </label>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <AiOutlineBell color="silver" size={20} />
                    <AiOutlineUser color="silver" size={20} />
                    <div>
                        <UsuarioLogado />
                    </div>
                </div>
            </div>
            <div style={DivHeaderStyle}>
                <div style={DivLabelStyle}>
                    <label style={TextFormat}>Solicitação de Materiais</label>
                </div>
                <div style={{ alignItems: "center", justifyContent: "center", display: "flex", }}>
                    <button
                        onClick={e => setModalSolicitacoes(true)}
                        style={ButtonRegister}>
                        <AiOutlinePlusCircle style={{ marginRight: 5 }} />
                        Nova Solicitação
                    </button>
                </div>
            </div>
            {dadosPacientes === undefined ? 'Não foi possível carregar os dados' :
                <table className="table-default">
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Data da Solicitação</th>
                            <th>Responsável</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody className="scrolly">
                        {dadosPacientes === undefined ? "Não foi possível carregar os dados " : dadosPacientes.map((item, index) => {
                            return (
                                <tr>
                                    <td>{item.status}</td>
                                    <td>{item.data_atendimento}</td>
                                    <td>{item.responsavel}</td>
                                    <td>
                                        <button onClick={e => handleOpenModalAvaliacao(item)}>
                                            Avaliar
                                        </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            {modalSolicitacoes ? <Materiais onClose={() => setModalSolicitacoes(false)} pacientes={pacientes} /> : <></>}
            {avaliacaoModal && <AvaliacaoModal onClose={setAvaliacaoModal} modalData={modalData} />}
        </Container>
    )
}
