import React, { useEffect, useRef, useState } from 'react';
import { BsEye } from 'react-icons/bs';
import { CgBlock } from 'react-icons/cg';
import api from '../../services/api';
import ModalUploads from './ModalUploads';
import moment from 'moment';
import { FiDelete } from 'react-icons/fi';

const Uploads = () => {

    //#region VARIAVEIS
    //LISTAS
    const [arquivos, setArquivos] = useState([]);
    //REFS
    const modalRecadoRef = useRef();
    //#endregion
    //#region USE EFFECTS
    useEffect(() => {/* ONLOAD */
        api.get('api/uploads')
            .then(({ data }) => {
                setArquivos(data);
            })
            .catch((error) => {
                console.log(error)
            })
    }, []);
    //#endregion
    //#region HTML
    return (
        <div className='container'>
            <div className='div-titulo'>
                <label>Uploads</label>
            </div>
            <ModalUploads ref={modalRecadoRef}></ModalUploads>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Caminho</th>
                        <th>Data Inclusão</th>
                        <th>Baixar</th>
                    </tr>
                </thead>
                <tbody>
                    {arquivos.map((arquivo) => (
                        <tr key={arquivo.id}>
                            <td>{arquivo.id}</td>
                            <td>{arquivo.nome}</td>
                            <td>{arquivo.caminho}</td>
                            <td>{moment.utc(arquivo.created_at).format('DD/MM/YYYY HH:mm')}</td>
                            <td>
                                <BsEye size={22} />
                                <CgBlock size={22} />
                                <FiDelete size={22} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    //#endregion
};

export default Uploads;