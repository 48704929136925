import api from "./api";

class TerapiumService {
    static index = (params='') => {
        return api.get(`api/terapia?${params}`);
    }

    static store = (obj) => {
        /* exemplo
        {"terapia":"dolor","especialidade":"ut","valor":0.36741717}
        */

        return api.post(`api/terapia`, obj);
    }

    static show = (id) => {
        return api.get(`api/terapia/${id}`);
    }

    static update = (obj, id) => {
        /* exemplo
        {"terapia":"dolorum","especialidade":"nihil","valor":0.4198}
        */

        return api.put(`api/terapia/${id}`, obj);
    }

    static destroy = (id) => {
        return api.delete(`api/terapia/${id}`);
    }
}

export default TerapiumService;
