import React, { useMemo } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { TableDefault } from '../../../../../components/TableDefault';
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import { MedicosAssistentesService } from "../../../../../services/EmergenciasPacienteService";
import api from "../../../../../services/api";

export function TableMedicosAssistentes({ source, setModalMedicosData, pacienteId, setMedicos, callBack, handleOpen, readOnly = false }) {

    const snackbar = useSnackbar();

    const removeMedico = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                let medicosLocal = [...source];
                let pacienteId = data.paciente_id;
                if (pacienteId === null || pacienteId === undefined) {
                    const index = medicosLocal.indexOf(data);
                    medicosLocal.splice(index, 1);
                    setMedicos(medicosLocal);
                } else {
                    await api.delete('api/paciente-medico-assistente/' + data.id).then(() => {
                        snackbar.displayMessage("Cadastro removido com sucesso!", "success");
                        const index = medicosLocal.indexOf(data);
                        medicosLocal.splice(index, 1);
                        setMedicos(medicosLocal);
                    })
                        .catch(() => {
                            Swal.fire('Houve um problema ao remover o médico assistente!', '', 'error');
                        });
                }
            }
        });
    }

    const handleEditMedico = (data) => {
        setModalMedicosData(data);
        handleOpen();
    };

    const data = React.useMemo(() => source, [source]);

    const columns = useMemo(
        () => [
            {
                Header: 'Nome:',
                accessor: 'nome', // accessor is the "key" in the data
            },
            {
                Header: 'Telefone:',
                accessor: 'telefone',
            },
            {
                Header: 'Especialidade',
                accessor: 'especialidade',
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        return (
                            <div style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "center", backgroundColor: "transparent", margin: 5
                            }}>
                                <AiOutlineEdit className="icone-padrao" onClick={e => handleEditMedico(cell.row.original)} />
                                <AiOutlineDelete className="icone-padrao" onClick={e => removeMedico(cell.row.original)} />
                            </div>
                        )
                    } else {
                        return <></>;
                    }
                }
            }
        ],
        [source]
    )

    return (
        <TableDefault columns={columns} source={data} />
    );
}
