import Recepcao from "../../../layouts/Recepcao";
import { useAuth } from "../../../context/auth";

import EstatisticasCard from './components/EstatisticasCard';
import PacientesClinicaCard from './components/PacientesClinicaCard';
import AcessoCards from './components/AcessoCards';
import RecadosCard from './components/RecadosCard';

import { Container, ContainerSub, Header, Estatisticas, Pacientes, Acesso, Recados, AcessoRapido, } from './styles'
import { useEffect, useState } from "react";
import api from "../../../services/api";
import AcessoRapidoCards from "./components/AcessoRapidoCard";


export default function Dashboard() {
    const { user } = useAuth();
    const [estatisticas, setEstatisticas] = useState([]);
    const [pacientes, setPacientes] = useState([]);

    const loadEstatisticas = async () => {
        let { data } = await api.get('/api/estatisticas');
        setEstatisticas(data.estatisticas);
        setPacientes(data.pacientes)
    }

    useEffect(() => {
        loadEstatisticas()
    }, []);

    return (
        <Recepcao>
            <Container>
                <ContainerSub>
                    <Header>
                        <span>
                            <label style={{ color: "#4906AB", fontSize: "22px", fontFamily: "Nunito, sans-serif", fontWeight: "800" }}>
                                Olá
                            </label>
                            <label style={{ color: "#4906AB", fontSize: "22px", fontFamily: "Nunito, sans-serif" }}>
                                , {user?.name && user.name}
                            </label>
                        </span>
                    </Header>
                    <Estatisticas style={{
                        height: 300, textDecoration: "none", alignItems: "center",
                        justifyContent: "center", boxShadow: "0 0 10px 0px silver",
                        backgroundColor: "white", borderRadius: 20,
                    }}>
                        <EstatisticasCard estatisticas={estatisticas} title={"Estatísticas:"} />
                    </Estatisticas>
                    <Pacientes style={{
                        height: 300, textDecoration: "none", alignItems: "center",
                        justifyContent: "center", boxShadow: "0 0 10px 0px silver",
                        backgroundColor: "white", borderRadius: 20,
                    }}>
                        <PacientesClinicaCard pacientes={pacientes} title={"Pacientes da Clínica:"} />
                    </Pacientes>
                    <Acesso style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", boxShadow: "0 0 10px 0px silver",
                        backgroundColor: "white", borderRadius: 20,
                    }}>
                        <AcessoCards title={"Acesso:"} />
                    </Acesso>
                    <Recados style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", boxShadow: "0 0 10px 0px silver",
                        backgroundColor: "white", borderRadius: 20, height: 150, marginTop: 100
                    }}>
                        <RecadosCard title={"Recados:"} />
                    </Recados>
                    <AcessoRapido style={{
                        textDecoration: "none", alignItems: "center",
                        justifyContent: "center", boxShadow: "0 0 10px 0px silver",
                        backgroundColor: "white", borderRadius: 20, marginBottom: 50
                    }}>
                        <AcessoRapidoCards title={"Acesso Rápido:"} />
                    </AcessoRapido>
                </ContainerSub>
            </Container>
        </Recepcao>
    )
}
