import React, { useState, useEffect } from 'react';
import { Modal } from '../styles';
import { ModalContainer } from '../PacienteAltasModal/styles';
import InfoPacienteModal from '../InfoPacienteModal';
import moment from 'moment';
import RelatorioOrientacao from '../TerapeutaRelatorios/RelatorioOrientacao';
import RelatorioAcompanhamento from '../TerapeutaRelatorios/RelatorioDeAcompanhamento';
import RelatorioGeral from '../TerapeutaRelatorios/RelatorioGeral';
import RelatorioDeAdequacao from '../TerapeutaRelatorios/RelatorioAdequacaoTerapia';
import RelatorioEncerramento from '../TerapeutaRelatorios/RelatorioEncerramento';
import api from '../../../services/api';

function PacienteRelatoriosModal({ onClose, paciente }) {

  const id = 'modal-paciente-relatorios';

  const [orientacaoModal, setOrientacaoModal] = useState(false);
  const [acompanhamentoModal, setAcompanhamentoModal] = useState(false);
  const [relatorioGeralModal, setRelatorioGeralModal] = useState(false);
  const [adequacaoModal, setAdequacaoModal] = useState(false);
  const [encerramentoModal, setEncerramentoModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [dropDown, setDropDown] = useState(false);
  const [docsFilter, setDocsFilter] = useState(paciente.documentos);

  const [relatorioAlta, setRelatorioAlta] = useState([]);
  const [relatorioAcompanhamento, setRelatorioAcompanhamento] = useState([]);
  const [relatorioOrientacao, setRelatorioOrientacao] = useState([]);
  const [relatorioGeral, setRelatorioGeral] = useState([]);
  const [relatorioAdequacao, setRelatorioAdequacao] = useState([]);
  const [relatorioEncerramento, setRelatorioEncerramento] = useState([]);
  const [relatorioAtividade, setRelatorioAtividade] = useState([]);

  const handleOpenAcompanhamento = (data) => {
    setAcompanhamentoModal(true);
    setModalData(data);
  }

  const handleNewAcompanhamento = () => {
    setAcompanhamentoModal(true);
    setModalData(null);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  function handleCheckboxFilter(e) {
    !e.target.checked ? setDocsFilter(paciente.documentos) : setDocsFilter((docsFiltrados) =>
      paciente.documentos.filter((item) => item.nome.toString().toLowerCase().includes(e.target.value.toString().toLowerCase()))
    )
  }

  const getRelatoriosPaciente = () => {
    api.get(`api/relatorio/alta?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosAlta = [];
      data.forEach((item) => {
        dadosAlta.push({
          id: item.id,
          tipo: "Relatório Alta",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioAlta(dadosAlta);
    });
    api.get(`api/relatorio/acompanhamento?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosAcompanhamento = [];
      data.forEach((item) => {
        dadosAcompanhamento.push({
          id: item.id,
          tipo: "Relatório Acompanhamento",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioAcompanhamento(dadosAcompanhamento);
    });
    api.get(`api/relatorio/alteracao-terapia?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosAlteracao = [];
      data.forEach((item) => {
        dadosAlteracao.push({
          id: item.id,
          tipo: "Relatório Alteração",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioAdequacao(dadosAlteracao);
    });
    api.get(`api/relatorio/atividade-externa?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosAtividade = [];
      data.forEach((item) => {
        dadosAtividade.push({
          id: item.id,
          tipo: "Relatório Atividade",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioAtividade(dadosAtividade);
    });
    api.get(`api/relatorio/encerramento-tratamento?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosEncerramento = [];
      data.forEach((item) => {
        dadosEncerramento.push({
          id: item.id,
          tipo: "Relatório Encerramento",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          justificativa: item.justificativa,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioEncerramento(dadosEncerramento);
    });
    api.get(`api/relatorio/generico?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosGeral = [];
      data.forEach((item) => {
        dadosGeral.push({
          id: item.id,
          tipo: "Relatório Genérico",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioGeral(dadosGeral);
    });
    api.get(`api/relatorio/orientacao?with=terapium,especialidade&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
      let dadosOrientacao = [];
      data.forEach((item) => {
        dadosOrientacao.push({
          id: item.id,
          tipo: "Relatório Orientação",
          terapia: item.terapium.terapia,
          especialidade: item.especialidade.especialidade,
          descritivo: item.descritivo,
          data: moment(item.data).format('DD/MM/YYYY')
        })
      });
      setRelatorioOrientacao(dadosOrientacao);
    });
  }

  useEffect(() => {
    getRelatoriosPaciente()
  }, [])

  return (
    <Modal id={id} onClick={handleOutsideClick}>
      <ModalContainer>
        {/* <div className='position-relative'>
            <button onClick={handleDropDown} className='btn text-white d-flex position-absolute end-0 me-3' style={{backgroundColor: '#6A3BAF'}}>Novo
            {dropDown?
              <div style={{backgroundColor: '#6A3BAF', width: '190px'}} className='d-flex flex-column align-items-start justify-content-center position-absolute end-0 top-100 p-2 rounded text-white'>
                <div onClick={() => handleNewAcompanhamento()} style={{cursor: 'pointer'}} className='text-decoration-underline mb-1'><span>Acompanhamento</span></div>
                <div onClick={() => setAdequacaoModal(true)} style={{cursor: 'pointer'}} className='text-decoration-underline mb-1'><span>Adequação da terapia</span></div>
                <div onClick={() => setRelatorioGeralModal(true)} style={{cursor: 'pointer'}} className='text-decoration-underline mb-1'><span>Relatório geral</span></div>
                <div onClick={() => setOrientacaoModal(true)} style={{cursor: 'pointer'}} className='text-decoration-underline mb-1'><span>Orientação</span></div>
                <div onClick={() => setEncerramentoModal(true)} style={{cursor: 'pointer'}} className='text-decoration-underline mb-1'><span>Encerramento Terapia</span></div>
              </div>
              :''
            }
            </button>
          </div> */}
        <div className='d-flex aling-center justify-center'>
          <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Relatórios</label>
        </div>
        <InfoPacienteModal paciente={paciente} />
        <div className='d-flex  flex-column justify-content-center align-items-center'>
          <label style={{ fontWeight: '600', fontSize: '16px', color: '#6A3BAF' }}>Filtro</label>
          <div className='d-flex mb-2  rounded w-75 flex-wrap' style={{ backgroundColor: '#6A3BAF' }}>
            <div className='p-1 col-4'><input onClick={(e) => handleCheckboxFilter(e)} value='acompanhamento' type="checkbox" /><label className='ms-1 text-white'>Acompanhamento</label></div>
            <div className='p-1 col-4'><input onClick={(e) => handleCheckboxFilter(e)} value='aumento' type="checkbox" /><label className='ms-1 text-white'>Adequação da terapia</label></div>
            <div className='p-1 col-4'><input onClick={(e) => handleCheckboxFilter(e)} value='geral' type="checkbox" /><label className='ms-1 text-white'>Relatório geral</label></div>
            <div className='p-1 col-4'><input onClick={(e) => handleCheckboxFilter(e)} value='orientacao' type="checkbox" /><label className='ms-1 text-white'>Orientação</label></div>
            <div className='p-1 col-4'><input onClick={(e) => handleCheckboxFilter(e)} value='reducao' type="checkbox" /><label className='ms-1 text-white'>Redução de terapia</label></div>
          </div>
        </div>
        <div className='d-flex flex-wrap overflow-auto px-4'>
          <div className='col-3 px-1'><span>Data:</span></div>
          <div className='col-4 px-1'><span>Nome:</span></div>
          <div className='col-4 px-1'><span>Terapia:</span></div>
          <div className='col-1'></div>
          {relatorioAcompanhamento.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.data).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button onClick={e => handleOpenAcompanhamento(item)} style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioAdequacao.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioAlta.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioAtividade.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioEncerramento.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioGeral.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
          {relatorioOrientacao.map((item) => {
            return (
              <>
                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.updated_at).format('YYYY-MM-DD')} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={item.tipo} className='form-control' /></div>
                <div className='col-4 p-1'><input type="text" readOnly={'readOnly'} value={paciente.terapia} className='form-control' /></div>
                <div className='col-1 p-1'>
                  <button style={{
                    fontWeight: "600",
                    fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                    border: "none", borderRadius: 8, padding: "4px 10px"
                  }}>
                    Abrir
                  </button>
                </div>
              </>
            )
          })}
        </div>
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <button onClick={() => onClose()} style={{
            fontWeight: "600",
            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
            border: "none", borderRadius: 8, padding: "4px 40px"
          }}>
            Voltar
          </button>
        </div>
      </ModalContainer>
      {orientacaoModal && <RelatorioOrientacao onClose={() => setOrientacaoModal(false)} paciente={paciente} />}
      {acompanhamentoModal && <RelatorioAcompanhamento onClose={() => setAcompanhamentoModal(false)} paciente={paciente} modalData={modalData} />}
      {relatorioGeralModal && <RelatorioGeral onClose={() => setRelatorioGeralModal(false)} paciente={paciente} />}
      {adequacaoModal && <RelatorioDeAdequacao onClose={() => setAdequacaoModal(false)} paciente={paciente} />}
      {encerramentoModal && <RelatorioEncerramento onClose={() => setEncerramentoModal(false)} paciente={paciente} />}
    </Modal>
  );
}

export default PacienteRelatoriosModal;
