// src/components/BarChart/index.jsx
import React, { useState, useEffect, Children } from "react";
import { Bar } from "react-chartjs-2";

/**
 * Essa função se trata de um elemento react que cria um grafico de torta utilizando o ReactChartJS.
 * @param {[Array, Array, String, any, boolean, boolean, String]} param0 chartData: Recebe a informação pra construção do grafico. options: Recebe as opções customizadas para o grafico, se nulo, colocam as opções padrão. titulo: Recebe o titulo do grafico, se nulo fica sem titulo. children: Recebe as informações a serem colocadas em baixo do grafico.
 * @returns 
 */
export default function BarChart({ chartData, options, titulo, children, esperarData, debug = false, className }) {
    /* =============================== */
    /* ===VARIÁVEIS=================== */
    /* =============================== */
    const dataGenerica = {
        labels: ['Red', 'Orange', 'Blue'],
        datasets: [
            {
                label: 'Popularity of colours',
                data: [55, 23, 96],
                borderWidth: 1,
            }
        ]
    };
    const mappedChildren = Children.map(children, child =>
        <div className="Row">
            {child}
        </div>
    );
    const [opt, setOpt] = useState(options);
    const [tituloLocal, setTituloLocal] = useState(titulo);
    const [chartDataLocal, setChartDataLocal] = useState(chartData);
    /* =============================== */
    /* ===USE EFFECTs================= */
    /* =============================== */
    useEffect(() => {
        setOpt((options != null ? options : ''));
        setTituloLocal(titulo);
        setChartDataLocal((chartData == null ? (esperarData == null) ? null : dataGenerica : chartData));
    }, [chartData, titulo, options]);
    useEffect(() => {
        setOpt(options);
    }, [])
    /* =============================== */
    /* ===HTML======================== */
    /* =============================== */
    return (
        <div className={className}>
            <h2 style={{ textAlign: "center" }}>{tituloLocal}</h2>

            {(chartDataLocal != null) ? <Bar data={chartDataLocal} options={opt} /> : 'Aguardando informações para o grafico.'};
            {(mappedChildren != null) ? mappedChildren : ''};
        </div>
    );
}