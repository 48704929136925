import React from 'react';
import SignaturePad from "react-signature-canvas";

export default function Assinatura({sigCanvas}) {

  const clear = () => {
    sigCanvas.current.clear();
  }

  return (<>
    <div style={{
      border: '1px solid black', width: '100%', minHeight: 150
    }}>
      <SignaturePad
        ref={sigCanvas}
        canvasProps={{
          className: "signatureCanvas"
        }}
      />
    </div>
    <div style={{
      display: "flex",
      justifyContent: "end",
      margin: "10px 0px 20px"
    }}>
      <button onClick={clear}>Limpar</button>
    </div>
  </>)
};
