import React, { useMemo } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import { TableDefaultPagination } from "../../../../../components/TableDefaultPagination";
import HorariosAtendimentoService from "../../../../../services/HorariosAtendimentoService";
import moment from "moment";

export function TableHorariosAtendimento({ source, callback, showEditModal }) {
  const dayName = ["Domingo", "Segunda - Feira", "Terça - Feira", "Quarta - Feira", "Quinta - Feira", "Sexta - Feira", "Sábado"]
  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este registro ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        HorariosAtendimentoService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Horario:',
        accessor: 'horario',
        Cell: ({ cell }) => {
          return moment.utc(cell.row.original.horario).format('HH:mm')
        }
      },
      {
        Header: 'Dia da Semana:',
        accessor: 'dia_semana',
        Cell: ({ cell }) => {
          return dayName[cell.row.original.dia_semana];
        }
      },
      {
        Header: "Ações",
        accessor: "acoes",
        Cell: ({ cell }) => (
          (<div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "transparent"
          }}>{/* 
                  <AiOutlineDelete
                      style={{cursor: 'pointer'}}
                      onClick={
                        () => handleDelete(cell.row.original.id)
                      }
                      color="#7340BD"
                      size={22}/> */}
            <AiOutlineEdit
              style={{ cursor: 'pointer' }}
              color="#7340BD"
              size={22}
              onClick={() => showEditModal && showEditModal(cell.row.original)}
            />
          </div>
          )
        )
      }
    ]
  )

  return (
    <TableDefaultPagination columns={columns} source={source} setPageSize={20} />
  )
}
