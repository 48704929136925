import React, { useState, useEffect, useRef } from 'react';
import { Table, TableCelula, TableCelulaHeader } from './styles';
import AtendimentoModal from '../AtendimentoModal';
import { Form } from '@unform/web';
import { AtendimentoReposicaoProvider } from '../../../../context/atendimentoReposicao';
import Select from '../../../../components/Select';
import AtendimentoService from '../../../../services/AtendimentoService';
import api from '../../../../services/api';
import moment from 'moment';
import { BsCheck, BsCheckLg, BsClockFill } from 'react-icons/bs';
import SolicitacaoModal from '../../../../components/Modais/Terapeuta/SolicitacaoModal';
import { CgClose } from 'react-icons/cg';
import { HiClock } from 'react-icons/hi';
import { GoClock } from 'react-icons/go';
import { FaCheck } from 'react-icons/fa';

const TableStatusDiario = ({ atendimentos, horarios, handleChangeSugestao = (e, atendimentoId, status) => { } }) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);
    const [atendimentoModalSelecionado, setAtendimentoModalSelecionado] = useState(null);
    const [statusAtendimento, setStatusAtendimento] = useState(null);
    const solicitacaoRef = useRef();
    //#endregion

    /*===============================*/
    //#region HANDLEs================*/
    /*===============================*/
    function handleOpenModalAtendimento(atendimento) {
        if (solicitacaoRef.current) {
            solicitacaoRef.current.open(atendimento);
        }
    }
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const getStatusAtendimento = () => {
        api.get(`/api/status-atendimento`)
            .then(response => {
                let statusList = response.data;
                statusList = statusList.map(status => {
                    return { value: status.id, label: status.status }
                })
                setStatusAtendimento(statusList);
            })
    }
    const confereHorario = (hora) => {
        let time1 = moment().format('HH:mm').split(':');
        let time2 = hora.split(':');

        let decimalTime1 = Number(time1[0]) * 60 + time1[1];
        let decimalTime2 = Number(time2[0]) * 60 + time2[1];

        const differenceInMinutes = decimalTime1 - decimalTime2;
        return (differenceInMinutes < 20);
    }
    //#endregion
    /*===============================*/
    //#region USE EFFECTs============*/
    /*===============================*/
    useEffect(() => {
        getStatusAtendimento();
    }, [])
    //#endregion
    /*===============================*/
    //#region HTML===================*/
    /*===============================*/
    return (
        <AtendimentoReposicaoProvider>
            <div>
                <div className='' id='container-accordion'>
                    {horarios?.map((horario_atendimento) => {
                        const atendimentosHora = atendimentos.filter(atendimento => {
                            return atendimento.horarios_atendimento_id === horario_atendimento.id;
                        });
                        const horario = moment.utc(horario_atendimento.horario).format('HH:mm');
                        const testeConfereHorario = confereHorario(horario);
                        const passou = testeConfereHorario;
                        if (atendimentosHora.length > 0) {
                            return (
                                <div className="card w-100 m-0">
                                    {atendimentosHora.map((atendimento, index) => {
                                        const atendimentoStyle = atendimento
                                            ? {
                                                backgroundColor: atendimento.cor,
                                                cursor: 'pointer',
                                                color: 'black'
                                            }
                                            : {};
                                        return (
                                            <div className="card-body w-100 p-0">
                                                <button className={`card-title w-100 border-bottom ms-0 me-0 p-1 font-18px d-flex w-100 justify-content-between align-items-center bg-roxo font-branca`}
                                                    type="button" onClick={() => document.getElementById(`hora-${horario}id-${atendimento.id}-index`).classList.toggle('some')}>
                                                    <p className='d-flex align-items-center flex-wrap justify-content-start p-0 m-0'>
                                                        {index === 0 && <> <GoClock style={{ marginBottom: '2px', color: passou ? 'red' : 'lightgreen' }} /> {horario} - </>} {atendimento.paciente_identificador}
                                                    </p>
                                                    <p className='p-0 m-0 d-flex flex-row align-items-center'>
                                                        <p className='p-0 m-0' style={{ width: '15px', height: '15px', borderRadius: '10px', backgroundColor: atendimento.cor, }}></p>
                                                        {(atendimento.liberado)
                                                            ? <FaCheck className='success p-0 m-0 ms-1' />
                                                            : <CgClose className='font-vermelho p-0 m-0 ms-1' />
                                                        }
                                                    </p>
                                                </button>
                                                <div id={`hora-${horario}id-${atendimento.id}-index`} className='some w-100'>
                                                    <div className='w-100 d-flex flex-row p-0 m-0'>
                                                        <div className='font-12px p-2 pt-0' style={{ width: '70%' }}>
                                                            Horario: {horario} <br></br>
                                                            Paciente: {atendimento.paciente_nome}<br></br>
                                                            Status do Atendimento: {atendimento.sugestao_recepcao_name ? atendimento.sugestao_recepcao_name : '-'}<br></br>
                                                            Tipo do Atendimento: {atendimento.tipo_atendimento ? atendimento.tipo_atendimento : '-'}<br></br>
                                                        </div>
                                                        <div className='w-30 d-flex justify-content-end p-0 m-0 pe-2' style={{ width: '30%' }}>
                                                            <button className='btn-padrao font-16px' style={atendimentoStyle} onClick={() => handleOpenModalAtendimento(atendimento)}>Alteração</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="card-text p-2 pt-0 m-0 font-12px d-flex justify-content-between align-items-center">
                                                    <p className='p-0 m-0 col-4'>Sala/Estação: {atendimento.sala}/{atendimento.estacao}</p>
                                                    <p className='p-0 m-0 col-4'>{(atendimento && !passou) &&
                                                        <select className='p-1'
                                                            id='status_terapeuta'
                                                            name='status_terapeuta'
                                                            style={{ borderRadius: '5px', width: '100%' }}
                                                            defaultValue={atendimento?.sugestao_terapeuta}
                                                            onChange={(e) => handleChangeSugestao(e, atendimento.id, atendimento.sugestao_recepcao)}
                                                        >
                                                            <option value=''>Selecione</option>
                                                            {statusAtendimento?.map((e) =>
                                                                <option value={e.value} selected={atendimento?.sugestao_terapeuta === e.value}>{e.label}</option>
                                                            )}
                                                        </select>
                                                    }</p>
                                                    <p className='p-0 m-0 col-4 text-center'>Status: {atendimento.sugestao_recepcao_name ? atendimento.sugestao_recepcao_name : '-'}</p>
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        } else {
                            return (
                                <div className="card w-100">
                                    <div className="card-body p-0">
                                        <h5 className="card-title border-bottom ms-0 me-0 p-1 font-18px bg-roxo font-branca">
                                            <GoClock style={{ marginBottom: '2px', color: passou ? 'red' : 'lightgreen' }} />{horario}
                                        </h5>
                                        <p className="card-text p-2">
                                            <p className='p-0 m-0'></p>
                                        </p>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                {isModalAtendimentoOpen &&
                    <AtendimentoModal
                        atendimentoId={atendimentoModalSelecionado.id}
                        onClose={() => setIsModalAtendimentoOpen(false)}
                        isAtendimentoEditavel={atendimentoModalSelecionado.editavel}
                    />
                }
                <SolicitacaoModal ref={solicitacaoRef} />
            </div>
        </AtendimentoReposicaoProvider>
    )
    //#endregion
};

export default TableStatusDiario;