import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import InputMask from '../../../../../components/InputMask';
import { Form } from '@unform/web';
import api from "../../../../../services/api";
import Input from "../../../../../components/Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: "auto",
        width: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "#FFFFFF",
    },
};

const ModalMedicos = (props, ref) => {

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dados, setDados] = useState(null);
    const [idGuia, setIdGuia] = useState(null);
    const [especialidade, setEspecialidade] = useState(null);
    const [especialidadeEscolhido, setEspecialidadeEscolhido] = useState(null);
    const [especialidadeSelectBox, setEspecialidadeSelectBox] = useState([]);
    const [loadingDadosIniciais, setLoadingDadosIniciais] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const getDefaultEspecialidade = (especialidades) => {
        if (!props.modalData) return;
        const especialidade = especialidades.filter(data => {
            return data.value === props.modalData.especialidade_id;
        })[0];
        setEspecialidadeEscolhido(especialidade);
    }

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const editNewPacienteTerapia = (medico, dados) => {
        let medicosLocal = [...props.medicos];
        const index = medicosLocal.indexOf(medico);
        medicosLocal.splice(index, 1, dados);
        props.setMedicos(medicosLocal);
    }

    async function handleSubmit(data) {
        try {
            if (data.nome === '' || data.telefone === '' || data.especialidade === '') {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning')
            } else {
                const dados = {
                    nome: data.nome,
                    telefone: data.telefone,
                    especialidade: data.especialidade,
                };
                if (props.modalData === null) {
                    let medicosLocal = [...props.medicos];
                    if (props.paciente_id !== undefined && props.paciente_id !== null) {
                        dados.paciente_id = props.paciente_id
                        await api.post('api/paciente-medico-assistente', dados).then(()=>{
                            medicosLocal.push(dados);
                            snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                        }).catch((e)=>{
                            Swal.fire("Erro ao Cadastrar", "Não foi possível cadastrar o médico assistente. Aperte F12 para verificar o erro.", "error");
                            console.error(e);
                        })
                    } else {
                        medicosLocal.push(dados);
                    }
                    props.setMedicos(medicosLocal);
                    setIsOpen(false);
                } else {
                    let medicosLocal = [...props.medicos];
                    if (props.paciente_id === undefined || props.paciente_id === null) {
                        medicosLocal.push(dados);
                        editNewPacienteTerapia(props.modalData, dados);
                        snackbar.displayMessage("Cadastro criado com sucesso!", "success");
                        setIsOpen(false);
                    } else {
                        api.put('api/paciente-medico-assistente/'+props.modalData.id, dados).then(() => {
                            snackbar.displayMessage('Cadastro alterado com sucesso!', 'success');
                            for (let index = 0; index < medicosLocal.length; index++) {
                                const e = medicosLocal[index];
                                if(e.id == props.modalData.id){
                                    medicosLocal[index] = {...dados, id: props.modalData.id};
                                    break;
                                }
                            }
                            props.setMedicos(medicosLocal);
                            setIsOpen(false);
                        }).catch((e) => {
                            Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
                        })
                    }
                }
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Houve um problema ao tentar cadastrar!", '', 'error');
        }
    }

    useEffect(() => {
        setLoadingDadosIniciais(true);

        async function loadDadosIniciais() {
            const dadosEspecialidade = await api.get(`api/especialidade`);
            setEspecialidade(dadosEspecialidade.data);

            const selectEspecialidade = dadosEspecialidade.data.map(d => ({
                "value": d.id,
                "label": d.especialidade
            }));
            setEspecialidadeSelectBox(selectEspecialidade);
            setLoadingDadosIniciais(false);
        };
        loadDadosIniciais();
    }, []);

    useEffect(() => {
        getDefaultEspecialidade(especialidadeSelectBox)
    }, [props.modalData])


    function functionThatSubmitsForm() {
        formRef.current.submitForm()
    };

    return (
        <div>
            <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent appElement={document.getElementById('root')}>
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "10px", fontSize: "25px", top: "-10px"
                    }} onClick={() => setIsOpen(false)}>
                        x
                    </label>
                </div>
                <div style={{ display: "flex", flexDirection: "column", }}>
                    <h2 style={{ color: "#442953", fontWeight: "bold", textAlign: 'center' }}
                        ref={(_subtitle) => (subtitle = _subtitle)}>{props.modalData === null ? "Adicionar Médico Assistente" : "Alterar Médico Assistente"}</h2>
                    <Form ref={formRef} onSubmit={handleSubmit}
                        style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                        <div>
                            <div style={{ display: "flex", flexDirection: "column", width: 'auto' }}>
                                <div style={{ width: "100%", paddingRight: 10, paddingTop: 10, display: 'flex', }}>
                                    <div style={{ width: '50%', margin: 2 }}>
                                        <Input style={{ width: '50%' }} name="nome" title="Nome:"
                                            defaultValue={props.modalData === null ? '' : props.modalData.nome} />
                                    </div>
                                    <div style={{ width: '22%', margin: 2, }}>
                                        <InputMask style={{ width: "22%", }} title="Telefone:" name="telefone" icon={null}
                                            maskFormat={"(99)99999-9999"} disabled={false}
                                            defaultValue={props.modalData === null ? '' : props.modalData.telefone} />
                                    </div>
                                    <div style={{ width: '33%', margin: 2 }}>
                                        <Input style={{ width: '30%' }} options={especialidadeSelectBox} name="especialidade" title="Especialidade:"
                                            defaultValue={props.modalData === null ? '' : props.modalData.especialidade}
                                            onChange={setEspecialidadeEscolhido} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='btn-padrao' type="button" onClick={functionThatSubmitsForm}> {props.modalData === null ? "Adicionar" : "Alterar"} </button>
                    </Form>
                </div>
            </Modal>
        </div>
    );
}

export default forwardRef(ModalMedicos);