//#region IMPORTAÇÕES
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCaretDown, AiOutlineClear, AiOutlineClose, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import moment from 'moment';
import api from '../../../../services/api';
import Responsavel from './Modal/Responsavel';
import AtendimentoAlteracaoService from '../../../../services/AtendimentoAlteracaoService';
import AtendimentoModal from '../../../TerapeutaDashboard/StatusDiario/AtendimentoModal';
import { AtendimentoReposicaoProvider } from '../../../../context/atendimentoReposicao';
import AtendimentoService from '../../../../services/AtendimentoService';
import Select from '../../../../components/Select';
import { Form } from '@unform/web';
import { useSnackbar } from '../../../../context/snackbar';
import Swal from 'sweetalert2';
import { ClipLoader } from "react-spinners";
import { useAuth } from '../../../../context/auth';
import './styles.css';
import 'react-day-picker/dist/style.css';
import ComentariosModal from '../../../../components/Modais/Comentarios';
import { BsCheck, BsEye } from 'react-icons/bs';
import { DivContentDisplay, linhasSolicitacao } from './styles';
import ControleDeExecucaoModal from '../../../../components/Modais/Checkin';
import { ContagemPendentes } from '../../../TerapeutaDashboard/TerapeutaPacientes/styles';
import { CgClose } from 'react-icons/cg';
import { FaBroom } from 'react-icons/fa';
import { FiDownload, FiFilter, FiRefreshCcw } from 'react-icons/fi';
import Calendario from '../../../../components/Calendario';
import { IoAddCircleOutline } from 'react-icons/io5';
import { IoMdOpen } from 'react-icons/io';
import usePermissoes from '../../../../context/permission';
import AdicionarAgendamento from '../../../TerapeutaDashboard/Dashboard/Modal/AdicionarAgendamento';
import Conflitos from './Modal/Conflitos';
import ConferirConflitos from './Modal/ConferirConflitos';
import LegendaDefault from './Modal/Legenda';
import usePacienteData from 'context/paciente';
import useSalaData from 'context/sala';
import useHorarioAtendimentoData from 'context/horario-atendimento';
import useTipoAtendimentosData from 'context/tipo-atendimento';
import useModalidadeData from 'context/modalidade';
import { Tooltip } from 'react-tooltip';
import LiveBadge from 'components/LiveBadge';
//#endregion

export default function ControleDiario({ minimizedSubNavbar }) {
    //#region VARIÁVEIS
    /*==INFORMAÇÕES da TELA==*/
    // eslint-disable-next-line no-unused-vars
    const titulo = 'CONTROLE DIÁRIO';
    // eslint-disable-next-line no-unused-vars
    const rota = '/dashboard/recepcao/controlediario/';
    /*==VARS USUARIO==*/
    const usuario = useAuth();
    /*==VARS MENSAGEM==*/
    const snackbar = useSnackbar();
    const permissoes = usePermissoes();
    const { pacientes, pacienteOptions, fetchPacienteData } = usePacienteData(['nome', 'identificador', 'aviso', 'terapia_paciente']);
    const { tipoAtendimentosOptions, fetchTipoAtendimentosData } = useTipoAtendimentosData();
    const { salaEstacaoOptions, fetchSalaEstacaoData } = useSalaData();
    const { horarioAtendimentoComFimOptions, fetchHorarioAtendimentoComFimData } = useHorarioAtendimentoData();
    const { modalidadesOptions, fetchModalidadeData } = useModalidadeData();
    const [daysMark, setDaysMark] = useState({});
    const [quantidadeAtendimentos, setQuantidadeAtendimentos] = useState(0);
    const [quantidadeConflitos, setQuantidadeConflitos] = useState(0);
    const [quantidadeErros, setQuantidadeErros] = useState(0);
    const [quantidadeSucessos, setQuantidadeSucessos] = useState(0);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    /*==PENDÊNCIAS DE ATUALIZAÇÃO==*/
    const [quantidadeDeAtualizacoes, setQuantidadeDeAtualizacoes] = useState(0);
    /*==VARS MODAL==*/
    const [isModalAdicionarAtendimentoVisible, setIsModalAdicionarAtendimentoVisible] = useState(false);
    const [isModalLegendaVisible, setIsModalLegendaVisible] = useState(false);
    const [isModalResponsavelVisible, setIsModalResponsavelVisible] = useState(false);
    const [isModalAtendimentoOpen, setIsModalAtendimentoOpen] = useState(false);

    const [isAtendimentoEditavel, setIsAtendimentoEditavel] = useState(false);
    //CALENDARIO
    const [isCalendarioVisible, setIsCalendarioVisible] = useState(false);
    //LISTA DE PRESENÇA EXECUÇÃO
    const [pacienteEmExecucaoList, setPacienteEmExecucaoList] = useState([]);
    //REFS
    const modalComentariosRef = useRef();
    const filtroDivRef = useRef();
    const tableRef = useRef();
    const pendenciasRef = useRef();
    const calendarioRef = useRef();
    const conferirConflitosModalRef = useRef();
    /*==VARS SOCKET==*/
    const [socket, setSocket] = useState(null);
    const [socketState, setSocketState] = useState(null);
    //CHECKIN
    const checkInModalRef = useRef(null);
    //SELECT LISTS
    const [expand, setExpand] = useState(false);
    const [terapeutasList, setTerapeutasList] = useState([]);
    const [planosSaudeList, setPlanosSaude] = useState([]);
    const [statusAtendimento, setStatusAtendimento] = useState(null);
    const [tipoAtendimentos, setTipoAtendimentos] = useState(null);
    const customPacienteFilter = (option, inputValue) => {
        if (!inputValue) return true;

        const lowerInputValue = inputValue.toLowerCase();
        const identificador = option.data.identificador.toLowerCase();
        const nome = option.data.nome.toLowerCase();
        const identificadorResult = identificador.includes(lowerInputValue);

        if (pacienteOptions.some(opt => opt.identificador.toLowerCase().includes(lowerInputValue))) {
            return identificadorResult;
        }
        return nome.includes(lowerInputValue);
    };
    //SELECTED
    const [statusSelecionado, setStatusSelecionado] = useState(null);
    const [pacienteSelecionado, setPacienteSelecionado] = useState(null);
    const [terapeutaSelecionado, setTerapeutaSelecionado] = useState(null);
    const [planoSaudeSelecionado, setPlanoSaudeSelecionado] = useState(null);
    const [habilitaSucesso, setHabilitaSucesso] = useState(true);
    const [habilitaErros, setHabilitaErros] = useState(true);
    const [habilitaConflitos, setHabilitaConflitos] = useState(true);

    const [statusSelecionadoOpt, setStatusSelecionadoOpt] = useState(null);
    const [pacienteSelecionadoOpt, setPacienteSelecionadoOpt] = useState(null);
    const [terapeutaSelecionadoOpt, setTerapeutaSelecionadoOpt] = useState(null);
    const [planoSaudeSelecionadoOpt, setPlanoSaudeSelecionadoOpt] = useState(null);
    //OUTRAS
    const [atendimentoModalSelecionado, setAtendimentoModalSelecionado] = useState(null);
    const [houveAlteracaoStatusEdicao, setHouveAlteracaoStatusEdicao] = useState(false);
    const [houveInclusaoAtendimento, setHouveInclusaoAtendimento] = useState(false);
    const [controleDiarioId, setControleDiarioId] = useState(null);
    const [controleDiario, setControleDiario] = useState(null);

    const [scrollTopInfo, setScrollTopInfo] = useState({ scrollTop: 0, scrollLeft: 0 });

    const [temDiaCarregado, setTemDiaCarregado] = useState(false);
    /*==VARS Opções do select==*/
    const opcoesLiberado = [
        { value: null, label: '' },
        { value: 0, label: <CgClose className='font-vermelho' size={22} /> },
        { value: 1, label: <BsCheck className='success' size={30} /> }
    ];
    const dayName = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentosOriginal, setAtendimentosOriginal] = useState([]);
    const [atendimentosNormal, setAtendimentosNormal] = useState([]);
    const dateNow = new Date();
    const [dataAtual, setDataAtual] = useState(`${dayName[dateNow.getDay()]} - ${moment.utc().format('DD/MM/YYYY')}`);
    const [dataOriginal, setDataOriginal] = useState(moment.utc());
    const [dataSelecionada, setDataSelecionada] = useState(moment.utc().format('YYYY-MM-DD'));
    const color = "#8ed4ae";
    /* ==VARS LOADING== */
    const [loading, setloading] = useState(true);
    const [algoCarregando, setAlgoCarregando] = useState(false);
    const [gettingAgenda, setGettingAgenda] = useState(false);
    //#endregion
    //#region FUNCOES
    async function onLoad(data) {
        setloading(true);
        try {
            let novaData = data ? data : dataOriginal;
            setDataSelecionada(novaData.format('YYYY-MM-DD'));
            await getControleDiarioId().then((response) => {
                if (response) {
                    setTemDiaCarregado(true);
                    getAgendamentosControleDiario(response);
                } else {
                    setTemDiaCarregado(false);
                }
            });
            setloading(false);
        } catch (error) {
            console.error(error)
        }
    }
    const filtrar = async () => {
        setloading(true);
        let newAtendimentosList = [];
        let qtdeAtendimento = 0;
        let qtdeSucessos = 0;
        let qtdeConflitos = 0;
        let qtdeErros = 0;
        await atendimentosOriginal.forEach((el) => {
            let newAtendimentos = el.atendimentos.filter((atendimento) => {
                let status, paciente, terapeuta, planoSaude = false;
                /* TESTA ATENDIMENTO ALTERAÇÃO */
                let paciente_id = atendimento.paciente_id;
                let terapeuta_id = atendimento.terapeuta_id;
                let plano_saude_id = atendimento.plano_saude_id;
                //#region FILTRO
                if (statusSelecionado === null) {
                    status = true;
                } else {
                    if (Number(statusSelecionado) === Number(atendimento.sugestao_recepcao)) {
                        status = true
                    } else {
                        status = false;
                    }
                }
                if (pacienteSelecionado === null) {
                    paciente = true;
                } else {
                    if (Number(pacienteSelecionado) === Number(paciente_id)) {
                        paciente = true
                    } else {
                        paciente = false;
                    }
                }
                if (terapeutaSelecionado === null) {
                    terapeuta = true;
                } else {
                    if (Number(terapeutaSelecionado) === Number(terapeuta_id)) {
                        terapeuta = true
                    } else {
                        terapeuta = false;
                    }
                }
                if (planoSaudeSelecionado === null) {
                    planoSaude = true;
                } else {
                    if (Number(planoSaudeSelecionado) === Number(plano_saude_id)) {
                        planoSaude = true
                    } else {
                        planoSaude = false;
                    }
                }
                //#endregion
                /*---POSSIVEIS ERROS/CONFLITOS---
                 - horario_paciente
                 - sala_terapeuta
                 - liberado_realizado_sem_codigo
                 - liberado_realizado_sem_codigo2
                 - realizado_nao_liberado
                 - sem_execucao
                 - sem_guia
                 - sem_liberacao
                 - sem_status
                 - sem_terapeuta
                */
                const erro = atendimento?.conflitos.liberado_realizado_sem_codigo || atendimento?.conflitos.liberado_realizado_sem_codigo2 || atendimento?.conflitos.realizado_nao_liberado || atendimento?.conflitos.sem_execucao || atendimento?.conflitos.sem_guia || atendimento?.conflitos.sem_liberacao || atendimento?.conflitos.sem_status || atendimento?.conflitos.sem_terapeuta;
                const conflito = atendimento?.conflitos.horario_paciente || atendimento?.conflitos.sala_terapeuta;
                let erroConflitoTest = false;
                if (habilitaSucesso) {
                    if (!erro && !conflito) {
                        erroConflitoTest = true;
                    }
                }
                if (habilitaErros) {
                    if (erro) {
                        erroConflitoTest = true;
                    }
                }
                if (habilitaConflitos) {
                    if (conflito) {
                        erroConflitoTest = true;
                    }
                }

                let result = status && paciente && terapeuta && planoSaude && erroConflitoTest;
                if (result) {
                    if (erro) {
                        qtdeErros++;
                    }
                    if (conflito) {
                        qtdeConflitos++;
                    }
                    if (!erro && !conflito) {
                        qtdeSucessos++;
                    }
                }
                return result;
            });
            if (newAtendimentos.length > 0) {
                qtdeAtendimento += newAtendimentos.length;
                newAtendimentosList.push({
                    hora: el.hora,
                    atendimentos: newAtendimentos
                });
            }
        });
        setQuantidadeAtendimentos(qtdeAtendimento);
        setQuantidadeSucessos(qtdeSucessos);
        setQuantidadeErros(qtdeErros);
        setQuantidadeConflitos(qtdeConflitos);
        setAtendimentos(newAtendimentosList);
        setloading(false);
    }
    /* SOCKET */
    /**
     * Função que envia um array de informações para atualizar todos os conectados no Controle Diário.
     * @param {Array} data Data para ser enviada ao socket, e atualizar todas as telas.
     */
    const atualizaSocket = (socketLocal = null, data) => {
        if (socketLocal && socketLocal.readyState === WebSocket.OPEN) {
            if (data) {
                socketLocal.send(JSON.stringify(data));
            }
        } else {
            console.error('Socket is not open or is null');
        }
    }
    const atualizaExecutandoList = (pessoa, setPacienteEmExecucaoList) => {
        setPacienteEmExecucaoList((oldList) => {
            let newList = [...oldList];
            if (pessoa.action === 'new_connection') {
                const index = newList.findIndex(p => p.id === pessoa.id);
                if (index !== -1) {
                    newList[index] = pessoa;
                } else {
                    newList.push(pessoa);
                }
            } else if (pessoa.action === 'update_connections_list') {
                newList = pessoa.connections ? pessoa.connections : [];
            } else if (pessoa.action === 'disconnect') {
                newList = newList.filter(filter => filter.id !== pessoa.id);
            }
            return newList; // Retorna a nova lista para atualizar o estado
        });
    };
    const selecionaData = async (dataSaveLocal) => {
        setDataAtual(dataSaveLocal.format('dddd - DD/MM/YYYY'));
        setDataOriginal(dataSaveLocal);
        setDataSelecionada(dataSaveLocal.format('YYYY-MM-DD'));
        adicionarParametroNaUrl('data', dataSaveLocal.format('YYYY-MM-DD'));
    }
    /* ==AGENDA== */
    const getAgendamentosControleDiario = (controleid = null, socketSend = false) => {
        setGettingAgenda(true);
        setAlgoCarregando(true);
        try {
            const { scrollTop, scrollLeft } = document.getElementById('tabela-controle');
            setScrollTopInfo({ scrollTop: scrollTop, scrollLeft: scrollLeft });
            let id = (controleid === null) ? controleDiarioId : controleid;
            AtendimentoService.getByControleDiarioId(id)
                .then(({ data }) => {
                    data.map(atendimento => {
                        atendimento.cor = AtendimentoAlteracaoService.getCorStatus(atendimento.atendimento_alteracaos);
                        atendimento.ultima_alteracao = AtendimentoAlteracaoService.getUltimaAlteracao(atendimento.atendimento_alteracaos);
                        return atendimento;
                    });
                    setAtendimentosNormal(data);
                    function compareHorario(a, b) {
                        // Convertendo os horários para objetos Date para comparar corretamente
                        const horarioA = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + a.hora_atendimento);
                        const horarioB = new Date(moment.utc().format('YYYY-MM-DD') + ' ' + b.hora_atendimento);

                        // Comparando os horários e retornando o resultado da comparação
                        if (horarioA < horarioB) {
                            return -1;
                        }
                        if (horarioA > horarioB) {
                            return 1;
                        }
                        return 0;
                    }
                    // Ordenando a variável 'data' usando a função de comparação
                    data.sort(compareHorario);
                    let terapeutasListLocal = [];
                    let atendimentoPorHorario = [];
                    let data2 = [];
                    for (let index = 0; index < data.length; index++) {
                        const el = data[index];
                        const nextEl = data[index + 1];
                        /* CONFLITOS */
                        const realizadoOuNulo = el.sugestao_recepcao === null || el.sugestao_recepcao === 1;
                        const horario_sala = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.estacao_id === el.estacao_id && filter.horarios_atendimento_id === el.horarios_atendimento_id
                        }).length > 0;
                        const horario_terapeuta = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && (filter.terapeuta_id !== null && filter.terapeuta_id === el.terapeuta_id) && filter.horarios_atendimento_id === el.horarios_atendimento_id
                        }).length > 0;
                        const horario_paciente = data.filter((filter) => {
                            const filtroRealizadoOuNulo = filter.sugestao_recepcao === null || filter.sugestao_recepcao === 1;
                            return (filter.id !== el.id) && filtroRealizadoOuNulo && realizadoOuNulo && filter.paciente_id === el.paciente_id && filter.horarios_atendimento_id === el.horarios_atendimento_id && filter.estacao_id === el.estacao_id
                        }).length > 0;
                        const sem_terapeuta = realizadoOuNulo && !el.terapeuta_id;
                        const sem_status = realizadoOuNulo && !el.sugestao_recepcao;
                        const sem_liberacao = realizadoOuNulo && !el.liberado;
                        const realizado_nao_liberado = realizadoOuNulo && el.sugestao_recepcao === 1 && !el.liberado;
                        const liberado_realizado_sem_codigo = realizadoOuNulo && !realizado_nao_liberado && !el?.codigo_1;
                        const liberado_realizado_sem_codigo2 = false;
                        const sem_execucao = realizadoOuNulo && !realizado_nao_liberado && !el.executado;
                        const sem_guia = realizadoOuNulo && !el.guia;
                        const substituido = data.filter((filter) =>
                            filter.id !== el.id &&
                            el.tipo_atendimento_id !== 5 &&
                            filter.horarios_atendimento_id === el.horarios_atendimento_id &&
                            filter.estacao_id === el.estacao_id &&
                            filter.tipo_atendimento_id === 5).length > 0;
                        const conflitos = {
                            'id': el.id,
                            'horario_sala': horario_sala,
                            'horario_terapeuta': horario_terapeuta,
                            'horario_paciente': horario_paciente,
                            'sem_terapeuta': sem_terapeuta,
                            'sem_status': sem_status,
                            'sem_liberacao': sem_liberacao,
                            'realizado_nao_liberado': realizado_nao_liberado,
                            'liberado_realizado_sem_codigo': liberado_realizado_sem_codigo,
                            'liberado_realizado_sem_codigo2': liberado_realizado_sem_codigo2,
                            'sem_execucao': sem_execucao,
                            'sem_guia': sem_guia,
                        }
                        el.substituido = substituido;
                        el.conflitos = conflitos;
                        const horaAgora = el.hora_atendimento;
                        const horaDepois = (nextEl !== undefined) ? nextEl.hora_atendimento : false;
                        if (terapeutasListLocal.filter((filter) => filter.value === el.terapeuta_id).length === 0) {
                            terapeutasListLocal.push({ value: el.terapeuta_id, label: `(${el.id}) ${el.terapeuta_nome}` });
                        }
                        if (horaDepois) {
                            if (horaAgora === horaDepois) {
                                atendimentoPorHorario.push(el);
                            } else {
                                atendimentoPorHorario.push(el);
                                data2.push({
                                    hora: horaAgora,
                                    atendimentos: atendimentoPorHorario
                                });
                                atendimentoPorHorario = [];
                            }
                        } else {
                            atendimentoPorHorario.push(el);
                            data2.push({
                                hora: horaAgora,
                                atendimentos: atendimentoPorHorario
                            });
                        }
                    }
                    setTerapeutasList(terapeutasListLocal.sort((a, b) => {
                        if (a.label < b.label) {
                            return -1;
                        }
                        if (a.label > b.label) {
                            return 1;
                        }
                        return 0;
                    }))
                    setAtendimentosOriginal(data2);
                    setAlgoCarregando(false);
                    setQuantidadeDeAtualizacoes(0);
                    setGettingAgenda(false);
                });
        } catch (error) {
            console.error(error);
        }
    };
    const salvarControleDiario = async (semTeste = false) => {
        let semBloqueio = true;
        let semAviso = true;
        for (let index = 0; index < atendimentos.length; index++) {
            const atendimentosHora = atendimentos[index].atendimentos;
            for (let index2 = 0; index2 < atendimentosHora.length; index2++) {
                const e = atendimentosHora[index2];
                if (!semTeste) {
                    if (semAviso) {
                        semAviso = !e.conflitos.sala_terapeuta &&
                            !e.conflitos.horario_paciente;
                    }
                }
                semBloqueio = !e.conflitos.sem_terapeuta &&
                    !e.conflitos.sem_status &&
                    !e.conflitos.sem_liberacao &&
                    !e.conflitos.realizado_nao_liberado &&
                    !e.conflitos.liberado_realizado_sem_codigo2 &&
                    !e.conflitos.sem_guia &&
                    !e.conflitos.sem_execucao;
                if (!semBloqueio) {
                    break;
                }
            }
        }

        if (!semAviso) {
            if (conferirConflitosModalRef.current) {
                conferirConflitosModalRef.current.openModal(atendimentosOriginal);
                return false;
            }
        }
        if (semBloqueio) {
            Swal.fire({
                title: 'Deseja mesmo salvar esse Controle Diário?',
                text: 'O Controle Diário não podera ser alterado após essa ação.',
                html: `<div style='width: 100%; display: grid'>
                    <label for='obs'>Observação (Opcional): </label>
                    <textarea style='width: 100%; border-radius: 10px; border: .2 solid grey' id='obs' name='obs' rows='5' placeholder='Digite alguma observação para o controle diário...'></textarea>
                </div>`,
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Salvar',
                cancelButtonText: 'Cancelar ação',
                icon: 'question'
            }).then((e) => {
                if (e.isConfirmed) {
                    let obs = e?.value?.obs;
                    api.put('api/controle-diario/' + controleDiarioId, { id: controleDiarioId, salvo: moment.utc(), salvo_por: usuario.user.id, obs: obs }).then((response) => {
                        if (response.status === 400 && response.message === 'Não foi possível salvar o controle diário porque um atendimento não tinha guia disponível.') {
                            Swal.fire('Não foi possível salvar Controle Diário!', 'Houve um problema ao salvar o Controle Diário, porque algum atendimento não possue Guia válida, atualize a tela (F5) e confira novamente os Erros.', 'error');
                        }
                        if (response.status === 200) {
                            window.location.reload();
                        }
                    }).catch((e) => {
                        Swal.fire(e.message, '', 'error');
                    })
                }
            })
        } else {
            Swal.fire('Pendencias não Resolvidas!', 'Existem erros que impédem o Controle Diário de ser Salvo.', 'error');
        }
    };
    const expandFiltro = (e) => {
        if (e.target === document.getElementById('div-expande-filtro')) {
            if (expand) {
                fechaFiltro(null);
            } else {
                abreFiltro();
            }
        }
    }
    const abreFiltro = () => {
        setExpand(true);
    }
    const fechaFiltro = (event) => {
        try {
            if (!event) {
                setExpand(false);
            } else {
                if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
                    setExpand(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    function adicionarParametroNaUrl(chave, valor) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Adiciona ou atualiza o parâmetro
        parametros.set(chave, valor);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function removerParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Remove o parâmetro
        parametros.delete(chave);

        // Modifica a URL sem recarregar a página
        window.history.pushState({}, '', `${window.location.pathname}?${parametros}`);
    }
    function recuperarParametroDaUrl(chave) {
        // Cria um objeto URLSearchParams a partir da string de consulta atual
        const parametros = new URLSearchParams(window.location.search);

        // Retorna o valor do parâmetro
        return parametros.get(chave);
    }
    //#endregion
    //#region HANDLES
    const handleCloseModalControleDeExecucao = (insideSocket = null) => {
        if (insideSocket && insideSocket.readyState === WebSocket.OPEN) {
            atualizaSocket(insideSocket, { action: 'contador' });
        } else {
            if (socket && socket.readyState === WebSocket.OPEN) {
                atualizaSocket(socket, { action: 'contador' });
            }
        }
    }
    function handleOpenModalAtendimento(atendimento, editavel = true) {
        setIsAtendimentoEditavel(editavel);
        setIsModalAtendimentoOpen(true);
        setAtendimentoModalSelecionado(atendimento);
    }
    function handleOpenModalPendencias(conflitos) {
        pendenciasRef.current.openModal(conflitos);
    }
    const handleLimpaFiltros = () => {
        setTerapeutaSelecionado(null);
        setTerapeutaSelecionadoOpt(null);
        setStatusSelecionado(null);
        setStatusSelecionadoOpt(null);
        setPacienteSelecionado(null);
        setPacienteSelecionadoOpt(null);
        setPlanoSaudeSelecionado(null);
        setPlanoSaudeSelecionadoOpt(null);
    }
    const handleFiltraPaciente = (paciente_id) => {
        setPacienteSelecionado(paciente_id);
        setPacienteSelecionadoOpt(pacienteOptions.filter((el) => Number(el.value) === Number(paciente_id)));
    }
    /* ==MODAL CONTROLE DE EXECUÇÃO== */
    const handleOpenModalCheckin = (paciente_id) => {
        handleLimpaFiltros();
        setPacienteSelecionado(paciente_id);
        setPacienteSelecionadoOpt(pacienteOptions.filter((el) => Number(el.value) === Number(paciente_id)));
        if (checkInModalRef.current) {
            checkInModalRef.current.openModal(controleDiarioId, paciente_id);
        }
    }
    /* ==MODAL COMENTARIOS== */
    const handleModalComentarios = (dados) => {
        if (modalComentariosRef.current) {
            modalComentariosRef.current.openModal(dados);
        }
    }
    const handleChangeHabilitaSucesso = (event) => {
        setHabilitaSucesso(event.target.checked);
    }
    const handleChangeHabilitaErros = (event) => {
        setHabilitaErros(event.target.checked);
    }
    const handleChangeHabilitaConflitos = (event) => {
        setHabilitaConflitos(event.target.checked);
    }
    const handleClickDay = (day, month, year) => {
        let dia = moment.utc(`${year}-${month + 1}-${day}`, 'YYYY-MM-DD');
        let dataSaveLocal = moment.utc(dia);
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    }
    const handleToggleCalendario = () => {
        setIsCalendarioVisible(!isCalendarioVisible);
    };
    const handleFechaCalendario = (event) => {
        try {
            if (event === null) {
                setIsCalendarioVisible(false);
            } else {
                if (calendarioRef.current && !calendarioRef.current.contains(event.target)) {
                    setIsCalendarioVisible(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
    const handleExportToExcelControle = () => {
        api.get('api/controle-diario/exportar-para-excel/' + controleDiarioId).then(({ data }) => {
            // Crie um link para baixar o arquivo
            const link = document.createElement('a');
            link.href = data.file;
            link.download = `atendimentos_${controleDiarioId}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((error) => {
            console.error(error);
            snackbar.displayMessage("Erro ao exportar controle diário para excel!", "error");
        });
    }
    const handleVerSolicitacao = (solicitacao) => {
        Swal.fire({
            title: 'Solicitação das ' + moment.utc(solicitacao.created_at).format('HH:mm'),
            icon: 'info',
            html:
                solicitacao.status === 'Reprovado'
                    ? `<p className='text-left'>
                        Descrição: ${solicitacao.descricao}
                    </p>
                    <p className='text-left'>
                        Motivo: ${solicitacao.motivo}
                    </p>
                    <p className='text-left'>
                        Reprova: ${solicitacao.motivo_reprovacao}
                    </p>`
                    : `<p className='text-left'>
                        Descrição: ${solicitacao.descricao}
                    </p>
                    <p className='text-left'>
                        Motivo: ${solicitacao.motivo}
                    </p>`
        });
    }
    const handleAbreSolicitacaoDeAlteracao = (solicitacao) => {
        Swal.fire({
            title: 'Solicitação de Alteração do Atendimento',
            icon: 'info',
            html: `
            <div className="row text-justify">
                <div className="col-12 text-left">
                    Descrição: ${solicitacao.descricao}
                </div>
                <div className="col-12 text-left">
                    Motivo: ${solicitacao.motivo}
                </div>
            </div>`,
            showDenyButton: true,
            confirmButtonText: 'Aprovar',
            allowOutsideClick: true,
            denyButtonText: `Reprovar`
        }).then((result) => {
            if (result.isConfirmed) {
                api.patch('api/solicitacao-alteracao/' + solicitacao.id + '?id=' + solicitacao.id, { status: 'Aprovado' }).then(() => {
                    Swal.fire('Solicitação de alteração aprovada com sucesso!', '', 'success');
                    getAgendamentosControleDiario();
                }).catch((error) => {
                    console.error(error);
                    snackbar.displayMessage("Erro ao aprovar solicitação de alteração!", "error");
                });
            } else if (result.isDenied) {
                Swal.fire({
                    title: 'Qual o motivo da reprovação?',
                    input: 'text',
                    inputLabel: 'Motivo',
                    inputPlaceholder: 'Digite o motivo da reprovação...',
                    showCancelButton: true,
                    confirmButtonText: 'Reprovar',
                    showLoaderOnConfirm: true,
                    preConfirm: (motivo) => {
                        if (motivo === '') {
                            Swal.showValidationMessage('Motivo não pode ser vazio.');
                        }
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        api.patch('api/solicitacao-alteracao/' + solicitacao.id + '?id=' + solicitacao.id, { status: 'Reprovado', motivo_reprovacao: result.value }).then(() => {
                            Swal.fire('Solicitação de alteração reprovada com sucesso!', '', 'success');
                            getAgendamentosControleDiario();
                        }).catch((error) => {
                            console.error(error);
                            snackbar.displayMessage("Erro ao reprovar solicitação de alteração!", "error");
                        });
                    }
                });
            }
            let dataSaveLocal = dataOriginal;
            selecionaData(dataSaveLocal);
        });

    }
    const handleChangeAtendimentoLiberado = async (e, atendimentoId, isUltimaAlteracao) => {
        let option = {
            title: 'Deseja confirma a liberação?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: `Cancelar`,
        };
        let atualiza = true;
        if (e.value === 1) {
            await Swal.fire(option).then((result) => {
                if (!result.isConfirmed) {
                    let dataSaveLocal = dataOriginal;
                    //let diaAtualLocal = dataSaveLocal.format('e');
                    selecionaData(dataSaveLocal);
                    atualiza = false;
                }
            });
        }
        if (atualiza) {
            let url = `/api/atendimento/${atendimentoId}`;
            if (isUltimaAlteracao) {
                url = `/api/atendimento-alteracao/${atendimentoId}`
            }
            api.patch(url, { liberado: e.value }).then(({ data }) => {
                snackbar.displayMessage("Status de liberação atualizado com sucesso!", "success");
                getAgendamentosControleDiario();
                atualizaSocket(socket, { action: 'contador' });
            }).catch((e) => {
                snackbar.displayMessage("Erro ao atualizar status de liberação!", "error");
                console.error(e);
            });
        }
    };
    const handleChangeStatusRecepcao = async (e, atendimento, isUltimaAlteracao) => {
        /* variaveis */
        let atualiza = true;
        let url = '';
        /* execuções */
        if (e.slug === 'falta-paciente' || e.slug === 'falta-terapeuta') {
            let option = {
                title: 'Deseja confirma a falta?',
                showDenyButton: true,
                confirmButtonText: 'Confirmar',
                denyButtonText: `Cancelar`,
            };
            await Swal.fire(option).then((result) => {
                if (result.isConfirmed) {
                    url = `/api/atendimento/${atendimento.id}`;
                } else {
                    atualiza = false;
                    let dataSaveLocal = dataOriginal;
                    selecionaData(dataSaveLocal);
                }
            });
        } else {
            url = `/api/atendimento/${atendimento.id}`;
        }
        if (atualiza) {
            api.patch(url, { sugestao_recepcao: e.value }).then(({ data }) => {
                snackbar.displayMessage("Status atualizado com sucesso!", "success");
                getAgendamentosControleDiario(controleDiarioId);
                atualizaSocket(socket, { action: 'contador' });
            }).catch((e) => {
                console.error(e);
            });
        }
    };
    /*===ALTERAÇÃO===*/
    const handleClickExcluiAlteracao = (antigo_horario = null, novo_horario = null, user_id = null, alteracao_id = null) => {
        let options = {
            title: 'Deseja realmente reprovar essa alteração?',
            text: `Alteração de horário de: ${antigo_horario} para ${novo_horario}`,
            icon: 'question',
            showDenyButton: true,
            denyButtonText: `Voltar`,
            confirmButtonText: 'Reprovar',
        };
        Swal.fire(options).then((result) => {
            if (result.isConfirmed) {
                try {
                    AtendimentoAlteracaoService.update({ status: '3' }, alteracao_id).then(() => {
                        Swal.fire('Alteração do atendimento reprovada com sucesso!', '', 'success');
                    })
                } catch (error) {
                    console.error(error);
                    Swal.fire('Alteração do atendimento não pode ser reprovada, tente novamente mais tarde!', 'Código do erro:' + error, 'error');
                }
            }
            let dataSaveLocal = dataOriginal;
            selecionaData(dataSaveLocal);
        });
    }
    const handleClickAprovaAlteracao = (antigo_horario = null, novo_horario = null, user_id = null, alteracao_id = null) => {
        let options = {
            title: 'Deseja realmente aprovar essa alteração?',
            text: `Alteração de horário de: ${antigo_horario} para ${novo_horario}`,
            icon: 'question',
            showDenyButton: true,
            denyButtonText: `Voltar`,
            confirmButtonText: 'Aprovar',
        };
        Swal.fire(options).then((result) => {
            if (result.isConfirmed) {
                let status = '';
                api('api/user/tipo_user/' + user_id).then(async (response) => {
                    switch (response.data) {
                        case ('recepcao'):
                            status = '4';
                            break;
                        default:
                            status = '2';
                            break;
                    }
                    if (status !== '') {
                        try {
                            await AtendimentoAlteracaoService.update({ status: status }, alteracao_id).then(() => {
                                Swal.fire('Alteração do atendimento aprovada com sucesso!', '', 'success')
                            }).finally(() => {
                                let dataSaveLocal = dataOriginal;
                                selecionaData(dataSaveLocal);
                            })
                        } catch (error) {
                            console.error(error);
                            Swal.fire('Alteração do atendimento não pode ser aprovada, tente novamente mais tarde!', 'Código do erro:' + error, 'error');
                        }
                    }
                })
            }
        });
    }
    /*NAVEGADORES de DATA*/
    const handleClickNextDay = () => {
        let dataSaveLocal = moment.utc(dataOriginal).add(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    const handleClickPrevDay = () => {
        let dataSaveLocal = moment.utc(dataOriginal).subtract(1, 'days');
        setControleDiario([]);
        setControleDiarioId();
        setAtendimentos([]);
        setAtendimentosOriginal([]);
        selecionaData(dataSaveLocal);
    };
    //#endregion

    //GETTERS
    //#region GETTERS
    const getControleDiario = () => {
        Swal.fire({
            title: 'Deseja carregar os atendimentos?',
            showDenyButton: true,
            confirmButtonText: 'Confirmar',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            setloading(true);
            if (result.isConfirmed) {
                api.get(`/api/controle-diario/carregar/${dataSelecionada}/${usuario.user.id}`)
                    .then((response) => {
                        switch (response.status) {
                            case 200:
                                snackbar.displayMessage("Dia carregado com sucesso!", "success");
                                window.location.reload();
                                break;
                            case 400:
                                snackbar.displayMessage("Dia já foi carregado!", "error");
                                break;
                            case 401:
                                Swal.fire("Existe um dia que não foi salvo", "Por favor, confira os dias anteriores para salva-lo, e tente novamente!", 'error');
                                break;
                            default:
                                snackbar.displayMessage("Erro ao carregar dia!", "error");
                                break;
                        }
                    }).catch((error) => {
                        switch (error.response.status) {
                            case 200:
                                snackbar.displayMessage("Dia carregado com sucesso!", "success");
                                break;
                            case 400:
                                snackbar.displayMessage("Dia já foi carregado!", "error");
                                break;
                            case 401:
                                Swal.fire("Existe um dia que não foi salvo", "Por favor, confira os dias anteriores para salva-lo, e tente novamente!", 'error');
                                break;
                            default:
                                snackbar.displayMessage("Erro ao carregar dia!", "error");
                                break;
                        }
                        console.error(error);
                    }).finally(() => {
                        setloading(false)
                    });
            }
        });
    };
    /**
     * Função que coleta o controle diário do dia da semana atual, e salva o id.
     * @returns {boolean}
     */
    const getControleDiarioId = async (data = null) => {
        let dataTeste = data === null ? dataSelecionada : data;
        const id = await api.get(`/api/controle-diario?filter[dia]=${dataTeste}`)
            .then(response => {
                let controleDiario = response.data;
                if (controleDiario?.length < 1) {
                    return false;
                }
                controleDiario = controleDiario[controleDiario?.length - 1];
                setControleDiarioId(controleDiario?.id);
                setControleDiario(controleDiario);
                return controleDiario?.id;
            })
            .catch(e => {
                console.error(e);
                return false;
            });
        return id;
    }
    /**
     * Função que define o status do atendimento via useState.
     * @returns {void}
     */
    const getStatusAtendimento = async () => {
        await api.get(`/api/status-atendimento`)
            .then(response => {
                let statusList = response.data;
                statusList = statusList.map(status => {
                    return {
                        value: status.id,
                        label: status.status,
                        slug: status.slug,
                    }
                })
                setStatusAtendimento(statusList);
            })
    }
    /**
     * Função que define o tipo do atendimento via useState.
     * @returns {void}
     */
    const getTipoAtendimento = async () => {
        await api.get(`/api/tipo-atendimento`)
            .then(response => {
                let tiposList = response.data;
                tiposList = tiposList.map(status => {
                    return {
                        value: status.id,
                        label: status.tipo_atendimento
                    }
                })
                setTipoAtendimentos(tiposList);
            })
    }
    /**
     * Função que define o status selecionado pelo valor.
     * @param {Array} statusList Array com a lista de status
     * @param {Number} value 
     * @returns {*}
     */
    const getStatusSelecionadoByValue = (statusList, value) => {
        if (!statusList || statusList.length < 1 || statusList === undefined || statusList === null) return;
        return statusList.filter(status => status.value === value)[0] || "";
    }
    /**
     * Função que coleta todos os planos cadastrados em uma lista de seleção
     */
    const getPlanosSaude = () => {
        api.get('api/plano-saude').then(({ data }) => {
            let planoSaudeOptions = [];
            data.forEach((planoSaude) => {
                planoSaudeOptions.push({ value: planoSaude.id, label: planoSaude.plano });
            });
            setPlanosSaude(planoSaudeOptions)
        });
    };
    //#endregion
    //#region USE EFFECT
    /* WEBSOCKET */
    useEffect(() => {
        /* Apenas vai iniciar a conexão com o socket, caso o controle diário seja não salvo e não tenha conexão ainda. */
        if (!socket) {
            if (controleDiario && controleDiario.id && controleDiario?.salvo === null) {
                /* Criação da URL do WS */
                const params = `?controle_diario_id=${controleDiario.id}`;
                const isHost = window.location.hostname === 'localhost';
                const urlWS = isHost
                    ? `ws://localhost:6001/ws/controle_diario${params}`
                    : `wss://sistema.neurointensiva.com/ws/controle_diario${params}`;

                /* Cria conexão */
                const socketLocal = new WebSocket(urlWS);

                /* Define o listener open do socket */
                socketLocal.addEventListener('open', function () {
                    /* Define o estado global do socket como verdadeiro(Ou seja, conectado) */
                    setSocketState(true);
                });

                /* Define o listener close do socket */
                socketLocal.addEventListener('close', function () {
                    /* Define o estado global do socket como falso(Ou seja, desconectado) */
                    setSocketState(false);
                });

                /* Define o listener message do socket */
                socketLocal.addEventListener('message', function (event) {
                    try {
                        /* Verifica se a data veio corretamente */
                        if (event.data !== null && event.data !== undefined && event.data !== '') {
                            /* Transforma o texto JSON em um objeto */
                            const data = (JSON.parse(event.data));
                            /* Verifica se tem data */
                            if (data) {
                                /* Verifica se tem id do paciente */
                                if (data.action === 'contador') {
                                    /* Se não tiver id do paciente, significa que não veio do Controle
                                    de Execução, então deve apenas adicionar ao contador de atualizaç~eos. */
                                    const newQuantidade = Number(quantidadeDeAtualizacoes) + 1;
                                    setQuantidadeDeAtualizacoes(newQuantidade);
                                } else {
                                    /* Chama a função que atualiza a lista */
                                    atualizaExecutandoList(data, setPacienteEmExecucaoList);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('Erro ao parsear a mensagem: ', error);
                    }
                });

                /* Define o listener error do socket */
                socketLocal.addEventListener('error', function (event) {
                    console.error('Erro: ', event);
                });

                /* Salva a variável para ser utilizada em outras partes do código */
                setSocket(socketLocal);
            }
        }
    }, [socket, controleDiario]);
    useEffect(() => {
        if (!houveAlteracaoStatusEdicao || !houveInclusaoAtendimento) return;
        getAgendamentosControleDiario(controleDiarioId);
        setHouveAlteracaoStatusEdicao(false);
        setHouveInclusaoAtendimento(false);
    }, [houveAlteracaoStatusEdicao, houveInclusaoAtendimento]);
    useEffect(() => {
        if (dataOriginal) {
            onLoad();
        }
    }, [dataOriginal]);
    useEffect(() => {
        setAlgoCarregando(true);
        filtrar().then(() => {
            let ele = document.getElementById('tabela-controle');
            if (ele) {
                ele.scrollTop = scrollTopInfo?.scrollTop;
                ele.scrollLeft = scrollTopInfo?.scrollLeft;
            }
            setAlgoCarregando(false);
        });
    }, [terapeutaSelecionado, pacienteSelecionado, planoSaudeSelecionado, statusSelecionado, atendimentosOriginal, habilitaErros, habilitaConflitos, habilitaSucesso, pacienteEmExecucaoList]);
    useEffect(() => {/* ONLOAD */
        const dataFromUrl = recuperarParametroDaUrl('data');
        if (dataFromUrl) {
            selecionaData(moment.utc(dataFromUrl));
        } else {
            selecionaData(moment.utc());
        }
        api.get('api/controle-diario/is/nao/salvo').then(({ data }) => {
            if (data.length > 0) {
                const naoSalvo = data[0];
                if (moment.utc(naoSalvo.carregado).format('DD/MM/YYYY') !== moment.utc(dataFromUrl).format('DD/MM/YYYY')) {
                    Swal.fire({
                        title: 'Existe um dia que não foi salvo',
                        text: 'Por favor, confira os dias anteriores para salva-lo, e tente novamente! \n \n Dia: ' + moment.utc(naoSalvo.dia).format('DD/MM/YYYY'),
                        icon: 'error',
                        timer: 10000
                    });
                    const day = moment.utc(naoSalvo.dia).format('YYYY-MM-DD');
                    const newDayMark = [];
                    newDayMark[day] = 'Dia não salvo, clique aqui e salve-o.';
                    setDaysMark(newDayMark);
                }
            }
        }).catch((error) => {
            console.error(error);
        });
        getStatusAtendimento();
        getTipoAtendimento();
        fetchPacienteData();
        fetchSalaEstacaoData();
        fetchHorarioAtendimentoComFimData(moment.utc(dataFromUrl).weekday());
        fetchModalidadeData();
        fetchTipoAtendimentosData();
        getPlanosSaude();
        /* VERIFICADORES DE CLICK */
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        // Adiciona o listener de redimensionamento da janela
        window.addEventListener("resize", handleResize);
        document.addEventListener('mousedown', (e) => {
            fechaFiltro(e);
            handleFechaCalendario(e);
        });
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
                handleFechaCalendario();
            }
        });
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener('mousedown', (e) => {
                fechaFiltro(e);
                handleFechaCalendario(e);
            });
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                    handleFechaCalendario();
                }
            });
        };
    }, []);
    //#endregion
    //#region HTML
    return (
        <AtendimentoReposicaoProvider>
            <div className='container'>
                <div className='w-100per d-flex flex-col'>
                    <div className='row'>
                        <div className='col-12 d-flex flex-wrap justify-content-around'>
                            <div className='d-flex justify-content-around flex-row'>
                                {(permissoes['criacao_edicao'] && temDiaCarregado)
                                    ? <button className='btn-padrao w-200px h-38px m-2'
                                        style={{
                                            backgroundColor: controleDiario?.salvo === null ? '#35CF58' : 'lightgrey',
                                            cursor: controleDiario?.salvo === null ? 'pointer' : 'not-allowed',
                                            color: controleDiario?.salvo === null ? 'white' : 'black'
                                        }}
                                        onClick={() => { controleDiario?.salvo === null && salvarControleDiario() }}>
                                        {controleDiario?.salvo === null ? 'Salvar' : 'Salvo'}
                                    </button>
                                    : <button className='btn-padrao w-200px h-38px m-2'
                                        style={moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada
                                            ? { backgroundColor: '#40C5ED' }
                                            : {
                                                backgroundColor: controleDiario?.salvo === null ? '#35CF58' : 'lightgrey',
                                                cursor: controleDiario?.salvo === null ? 'pointer' : 'not-allowed',
                                                color: controleDiario?.salvo === null ? 'white' : 'black'
                                            }} onClick={() => moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada && getControleDiario()}>
                                        {moment.utc(dateNow).format('YYYY-MM-DD') === dataSelecionada ? 'Carregar' : 'Não carregado'}
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(loading === false)
                ? <div className='d-flex align-center justify-center w-100per mt-1 flex-col'>
                    <div className='div-controlediario-cabecalho'>
                        {(temDiaCarregado) &&
                            <div className='div-filter-style'>
                                <div ref={filtroDivRef} style={{ width: "100%" }}>
                                    <div id='div-expande-filtro' className="w-100per pointer" onClick={(e) => expandFiltro(e)}>
                                        <div className='d-flex align-items-center' style={{ display: "flex" }} onClick={(e) => abreFiltro()}>
                                            <p id="blank" style={{ marginBottom: -30 }}>
                                                {expand ? 'Fechar ' : 'Expandir '}Filtro
                                            </p>
                                        </div>
                                        <div className='pointer flex-nowrap d-flex flex-row align-items-center' style={{ marginLeft: "86%" }}>
                                            {terapeutaSelecionado || pacienteSelecionado || planoSaudeSelecionado || statusSelecionado
                                                ? <FaBroom color="yellow" id="botao-limpa-filtro" size={32} onClick={(e) => { handleLimpaFiltros() }} title='Conteúdo filtrado...' />
                                                : <div style={{ width: '25px', height: '25px' }}></div>
                                            }
                                            <AiFillCaretDown onClick={(e) => expand ? fechaFiltro() : abreFiltro()} id='botao-expande-filtro' color="white" size={26} style={{ marginLeft: '10px' }} />
                                        </div>
                                    </div>
                                    <Form className='div-expand-over' style={expand ? { width: '45%' } : { display: 'none' }}>
                                        <div style={DivContentDisplay} className="row">
                                            <div className="col-6">
                                                <label className="label-filtro">Terapeuta:</label>
                                                <div className="d-flex justify-around align-center w-100per">
                                                    <Select id={'terapeutas'} name={'terapeutas'} options={terapeutasList} value={terapeutaSelecionadoOpt}
                                                        onChange={(e) => { setTerapeutaSelecionado(e.value); setTerapeutaSelecionadoOpt({ value: e.value, label: e.label }); }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setTerapeutaSelecionado(null);
                                                        setTerapeutaSelecionadoOpt(null);
                                                    }} size={25} />
                                                </div>
                                            </div>

                                            <div className="col-6">
                                                <label className="label-filtro">Status de Atendimento:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select
                                                        name="status"
                                                        options={statusAtendimento}
                                                        value={statusSelecionadoOpt}
                                                        onChange={(e) => {
                                                            setStatusSelecionado(e.value);
                                                            setStatusSelecionadoOpt({ value: e.value, label: e.label });
                                                        }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setStatusSelecionado(null);
                                                        setStatusSelecionadoOpt(null);
                                                    }} size={25} />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={DivContentDisplay} className="row">
                                            <div className="col-6">
                                                <label className="label-filtro">Paciente:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select
                                                        name={'pacientes'}
                                                        options={pacienteOptions}
                                                        value={pacienteSelecionadoOpt}
                                                        filterOption={customPacienteFilter()}
                                                        onChange={(e) => {
                                                            setPacienteSelecionado(e.value);
                                                            setPacienteSelecionadoOpt({ value: e.value, label: e.label })
                                                        }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setPacienteSelecionado(null);
                                                        setPacienteSelecionadoOpt(null);
                                                    }} size={25} />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <label className="label-filtro">Plano de Saúde:</label>
                                                <div className="d-flex justify-around align-center">
                                                    <Select
                                                        name={'planosSaude'}
                                                        options={planosSaudeList}
                                                        value={planoSaudeSelecionadoOpt}
                                                        onChange={(e) => {
                                                            setPlanoSaudeSelecionado(e.value);
                                                            setPlanoSaudeSelecionadoOpt({ value: e.value, label: e.label });
                                                        }} />
                                                    <AiOutlineClear className="pointer m-2" onClick={(e) => {
                                                        setPlanoSaudeSelecionado(null);
                                                        setPlanoSaudeSelecionadoOpt(null);
                                                    }} size={25} />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        }
                        <div className={temDiaCarregado ? 'div-seletor-style' : 'div-seletor-style-solo'}>
                            <div className='w-100per d-flex flex-col'>
                                <div className='col-12 d-flex text-right justify-content-center align-center mt-2'>
                                    <button className='btn-icone'>
                                        <AiOutlineLeft className='pointer' color='black' size={22} onClick={() => handleClickPrevDay()} />
                                    </button>
                                    <span className='texto-navegacao me-2 ml-2' onClick={(e) => handleToggleCalendario()}>{`${dataAtual.charAt(0).toUpperCase() + dataAtual.slice(1)}`}</span>
                                    <button className='btn-icone'>
                                        {moment.utc(dateNow).format('YYYY-MM-DD') !== dataSelecionada ?
                                            <AiOutlineRight className='pointer' color='black' size={22} onClick={() => handleClickNextDay()} />
                                            : <AiOutlineRight color='white' size={22} />
                                        }
                                    </button>
                                </div>
                            </div>
                            <div ref={calendarioRef} className={isCalendarioVisible ? 'div-expand-over calendar-div p-0 w-fit-content-i bg-white' : 'some'}>
                                <div className='w-100 d-flex justify-content-end'>
                                    <button className='btn-icone' onClick={(e) => handleToggleCalendario()}>
                                        <AiOutlineClose color='red' size={22} />
                                    </button>
                                </div>

                                <Calendario dateLimit={true} style={{ borderTop: 'none' }} daysMark={daysMark} handleClickDay={handleClickDay} maxDate={moment.utc(dateNow).format('YYYY-MM-DD')} selectedDate={dataSelecionada}></Calendario>
                            </div>
                        </div>
                        {(temDiaCarregado) &&
                            <div className='div-buttons-style'>
                                {gettingAgenda && <ClipLoader color={color} size={40} />}
                                {permissoes['controle_diario']['novo_atendimento'] &&
                                    <button className='btn-padrao w-200px h-38px m-2' type='button' style={{ backgroundColor: '#00B19C' }} onClick={() => setIsModalAdicionarAtendimentoVisible(true)}>
                                        Novo Atendimento
                                    </button>
                                }
                                <button className='btn-padrao h-38px' title='Atualiza Controle Diário.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => { getAgendamentosControleDiario(controleDiarioId); }}>
                                    {quantidadeDeAtualizacoes > 0 &&
                                        <ContagemPendentes type="button" style={{ position: 'absolute', margin: '-30px -30px 0 0' }}>
                                            {quantidadeDeAtualizacoes}
                                        </ContagemPendentes>}
                                    <FiRefreshCcw size={18} className={`rotacao-padrao${algoCarregando ? ' rodando' : ''}`}></FiRefreshCcw>
                                </button>
                                <button className='btn-padrao h-38px' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={(e) => handleExportToExcelControle()}>
                                    <FiDownload size={18} />
                                </button>
                                <button className='btn-padrao h-38px help' title='Legenda das cores.' type='button' style={{ backgroundColor: '#7441BF' }} onClick={e => setIsModalLegendaVisible(true)}>
                                    ?
                                </button>
                            </div>
                        }
                    </div>
                    {(!temDiaCarregado)
                        ? <div className="justify-center w-100per align-center text-center p-4 font-vermelho">
                            Não existe controle diário carregado.
                        </div>
                        : loading
                            ? <ClipLoader></ClipLoader>
                            : <table ref={tableRef} className='table-controle-diario'>
                                <thead key={'head'}>
                                    {/* COLUNAS DA TABELA */}
                                    <tr key={'head-line'}>
                                        <th key={'l1'} className='cd-col-0'>Hora</th>
                                        <th key={'l2'} className='cd-col-1' title='Nome curto do terapeuta.'>Terapeuta</th>
                                        <th key={'l3'} className='cd-col-2' title='Terapia e Especialidade do atendimento.'>Terapia <br /> Especialidade</th>
                                        <th key={'l4'} className='cd-col-3' title='Local para criar, aprovar ou reprovar atendimento.'>Alteração</th>
                                        <th key={'l5'} className='cd-col-4' title='Identificador do paciente.'>Paciente</th>
                                        <th key={'l6'} className='cd-col-5' title='Sala e a estação do atendimento.'>Sala <br /> Estação</th>
                                        <th key={'l7'} className='cd-col-6' title='Define qual a modalidade e o tipo do atendimento.'>Tipo e Modalidade</th>
                                        <th key={'l8'} className='cd-col-7' title='Status que o terapeuta sugeriu para o atendimento.'>Status <br /> Terapeuta</th>
                                        <th key={'l9'} className='cd-col-8' title='Define o status que o atendimento vai receber.'>Status</th>
                                        <th key={'l10'} className='cd-col-9' title='Define se a guia foi liberada pelo plano.'>Liberado</th>
                                        <th key={'l11'} className='cd-col-10' title='Define se o atendimento foi executado no plano.'>Execução</th>
                                        <th key={'l12'} className='cd-col-11' title='Comentário registrado no atendimento.'></th>
                                        <th key={'l13'} className='cd-col-12' title='Cor que define alguma incompatibilidade no no atendimento.'> P </th>
                                    </tr>
                                </thead>
                                <tbody id='tabela-controle' className='controle-diario-tbody' key={'body'} style={{ transition: minimizedSubNavbar ? 'height 1s' : 'height 300ms', height: minimizedSubNavbar ? `${windowHeight - 306}px` : `${windowHeight - 356}px` }}>
                                    {(atendimentos !== null && atendimentos.length !== 0)
                                        ? atendimentos.map((el, index1) => {
                                            let hora = el.hora;
                                            let atendimentosL = el.atendimentos;
                                            return (
                                                <>
                                                    <tr key={`linha-horario-${index1}`}>
                                                        <td className='td-hora' rowSpan={Number(atendimentosL.length) + 1}>
                                                            {hora.split(':')[0] + ':' + hora.split(':')[1]}
                                                        </td>
                                                    </tr>
                                                    {
                                                        atendimentosL.map((atendimento, index2) => {
                                                            const atendimento_alteracaos = atendimento.atendimento_alteracaos;
                                                            let dados = [];
                                                            dados['terapeuta'] = `${atendimento.terapeuta_nome ? `${atendimento.terapeuta_nome.substring(0, 15)}` : '---'}`;
                                                            dados['paciente'] = `(${atendimento.paciente_id}) ${atendimento.paciente_identificador}`;
                                                            dados['paciente_nome'] = `${atendimento.paciente_nome}`;
                                                            dados['paciente_id'] = atendimento.paciente_id;
                                                            dados['terapia'] = atendimento.terapia;
                                                            dados['especialidade'] = atendimento.especialidade;
                                                            dados['plano_saude'] = atendimento.nome_reduzido;
                                                            dados['sala'] = atendimento.sala;
                                                            dados['estacao'] = atendimento.estacao;
                                                            dados['terapia'] = atendimento.terapia;
                                                            dados['especialidade'] = atendimento.especialidade;
                                                            dados['sala'] = atendimento.sala;
                                                            dados['estacao'] = atendimento.estacao;
                                                            dados['reposicao'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento.tipo_atendimento;
                                                            dados['modalidade'] = atendimento.modalidade;
                                                            dados['tipo_atendimento'] = atendimento.reposicao ? 'Reposição' : atendimento.tipo_atendimento;
                                                            dados['usuario_acesso_execucao_id'] = atendimento.usuario_acesso_execucao_id;
                                                            dados['ultimo_acesso_execucao'] = atendimento.ultimo_acesso_execucao;
                                                            dados['id'] = atendimento.id;
                                                            dados['terapeuta_status'] = atendimento.sugestao_terapeuta;
                                                            dados['recepcao_status'] = atendimento.sugestao_recepcao;
                                                            dados['terapeuta_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_terapeuta) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_terapeuta).label;
                                                            dados['recepcao_status_nome'] = AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao) === undefined ? '-' : AtendimentoService.getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao).label;
                                                            dados['liberado'] = atendimento.liberado;
                                                            dados['checkin'] = atendimento.checkin;
                                                            dados['data_semana'] = moment.utc(atendimento.data_atendimento_inicial).format('dddd');
                                                            dados['data_inicio'] = moment.utc(atendimento.data_atendimento_inicial).format('HH:mm');
                                                            dados['data_final'] = moment.utc(atendimento.data_atendimento_final).format('HH:mm');
                                                            dados['data_inicio_final'] = moment.utc(atendimento.data_atendimento_inicial).add(40, 'Minutes').format('HH:mm');
                                                            dados['comentario'] = atendimento.comentario;
                                                            dados['guia'] = atendimento.guia;
                                                            dados['executado'] = atendimento.executado;
                                                            dados['codigo_1'] = atendimento.codigo_1;
                                                            dados['codigo_2'] = atendimento.codigo_2;
                                                            let horaDiferente = dados['data_inicio_final'] !== dados['data_final'];
                                                            let statusValue = getStatusSelecionadoByValue(statusAtendimento, atendimento.sugestao_recepcao);
                                                            let contaSolicitacaoPendente = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Aguardando').length;
                                                            let contaSolicitacaoReprovada = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Reprovado').length;
                                                            let contaSolicitacaoAprovada = atendimento.solicitacao_de_alteracaos.filter(solicitacao => solicitacao.status === 'Aprovado').length;
                                                            const guiaStyle = !dados['guia'] ? { /* borderBottom: '1px #f00 solid', */ borderLeft: '1px #f00 solid' } : {};
                                                            const subStyle = atendimento.substituido ? { /* borderTop: '1px #e16ff2 solid', */ borderRight: '1px #e16ff2 solid' } : {};
                                                            return (
                                                                <tr key={`linha-atendimento-${dados['id']}`}>
                                                                    {/*TERAPEUTA*/}
                                                                    <td style={{ ...guiaStyle, ...subStyle }}
                                                                        className={!horaDiferente ? 'td-content cd-col-1' : 'td-content cd-col-1 p-0 btlr-0 bblr-0'}>
                                                                        {horaDiferente
                                                                            ? <div className='div-nome-terapeuta'>
                                                                                <div className="hora-diferente">
                                                                                    {dados['data_final']}
                                                                                </div>
                                                                                <div className='nome-terapeuta'>
                                                                                    {dados['terapeuta']}
                                                                                </div>
                                                                            </div>
                                                                            : <div className='nome-terapeuta'>
                                                                                {dados['terapeuta']}
                                                                            </div>
                                                                        }
                                                                    </td>

                                                                    {/*TERAPIA/ESPECIA*/}
                                                                    <td className='td-content cd-col-2'>
                                                                        {dados['terapia']}
                                                                        <br /> {dados['especialidade']}
                                                                    </td>

                                                                    {/*ALTERAÇÃO*/}
                                                                    <td className='td-content cd-col-3 p-0' style={{ backgroundColor: atendimento.cor }}>
                                                                        <div className='w-100 d-flex justify-content-end'>
                                                                            {(atendimento.solicitacao_de_alteracaos && atendimento.solicitacao_de_alteracaos.length > 0) ?
                                                                                <>
                                                                                    <ContagemPendentes type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                                                                        style={{
                                                                                            backgroundColor:
                                                                                                (contaSolicitacaoAprovada !== 0)
                                                                                                    ? 'green'
                                                                                                    : (contaSolicitacaoPendente === 0 && contaSolicitacaoReprovada === 0)
                                                                                                        ? 'gray'
                                                                                                        : contaSolicitacaoPendente > contaSolicitacaoReprovada
                                                                                                            ? 'orange'
                                                                                                            : 'red'
                                                                                        }}>
                                                                                        {atendimento.solicitacao_de_alteracaos.length}
                                                                                    </ContagemPendentes>
                                                                                    <ul className="dropdown-menu dropdown-menu-end w-300px h-auto-i p-4">
                                                                                        <li key={'solicitacao:title-atendimento:' + atendimento.id}><h6 className='font-preta'>Solicitações de Alteração</h6></li>
                                                                                        {atendimento.solicitacao_de_alteracaos.map((solicitacao, index) => {
                                                                                            let corSolicitacao = solicitacao.status.toLowerCase();
                                                                                            return (
                                                                                                <li key={'solicitacao:' + index + '-atendimento:' + atendimento.id}>
                                                                                                    <button type="button" className={`w-100 btn btn-${corSolicitacao === 'aguardando' ? 'warning' : corSolicitacao === 'aprovado' ? 'success' : 'danger'}`}
                                                                                                        onClick={() => corSolicitacao === 'aguardando' ? handleAbreSolicitacaoDeAlteracao(solicitacao) : handleVerSolicitacao(solicitacao)}
                                                                                                        style={linhasSolicitacao[corSolicitacao]}>
                                                                                                        Hora: {moment.utc(solicitacao.created_at).format('HH:mm')}
                                                                                                        <IoMdOpen size={20} className='ml-20px' />
                                                                                                    </button>
                                                                                                </li>
                                                                                            );
                                                                                        })}
                                                                                    </ul>
                                                                                </>
                                                                                : <div style={{ width: '18px', height: '18px', margin: '0', padding: '0' }}></div>
                                                                            }
                                                                        </div>
                                                                        <div className='w-100 d-flex justify-content-center m-0 p-0'>
                                                                            {permissoes['criacao_edicao']
                                                                                ? (atendimento_alteracaos)
                                                                                    ? <button className="btn-outline font-12px m-0" title='Visualizar alteração.' onClick={() => handleOpenModalAtendimento(atendimento, false)}>
                                                                                        <BsEye size={20}></BsEye>
                                                                                    </button>
                                                                                    : <button className="btn-outline font-12px m-0" title='Adicionar nova alteração.' onClick={() => handleOpenModalAtendimento(atendimento, controleDiario?.salvo === null)}>
                                                                                        {
                                                                                            controleDiario?.salvo
                                                                                                ? <BsEye size={20}></BsEye>
                                                                                                : <IoAddCircleOutline size={20}></IoAddCircleOutline>
                                                                                        }
                                                                                    </button>
                                                                                : 'X'
                                                                            }
                                                                        </div>
                                                                        <div className='w-100'>
                                                                            <div style={{ width: '18px', height: '18px', margin: '0', padding: '0' }}></div>
                                                                        </div>
                                                                    </td>

                                                                    {/*PACIENTE*/}
                                                                    <td className='td-content cd-col-4' data-tooltip-id={"atendimento" + dados['id']}>
                                                                        <div className='d-flex flex-nowrap w-100 justify-content-between align-items-center'>
                                                                            <div style={{ width: '24px' }}>
                                                                                <FiFilter className={`btn-icone pointer${dados['paciente_id'] === pacienteSelecionado ? ' some' : ''}`} size={24} onClick={() => handleFiltraPaciente(dados['paciente_id'])}></FiFilter>
                                                                            </div>
                                                                            {permissoes['criacao_edicao']
                                                                                ? <p className='p-2 pointer font-azul text-sublinhado' onClick={() => controleDiario?.salvo === null && handleOpenModalCheckin(dados['paciente_id'])}>
                                                                                    {dados['paciente']}
                                                                                </p>
                                                                                : <p className='p-2'>
                                                                                    {dados['paciente']}
                                                                                </p>
                                                                            }
                                                                            <div style={{ width: '24px' }}>
                                                                            </div>
                                                                        </div>
                                                                        <Tooltip id={"atendimento" + dados['id']} type='dark' effect='float' className="d-flex border-radius-10px justify-content-between flex-wrap">
                                                                            {dados['paciente_nome']}
                                                                        </Tooltip>
                                                                    </td>
                                                                    {/*SALA/ESTAÇÃO*/}
                                                                    <td className='td-content cd-col-5'>
                                                                        {dados['sala']} - {dados['estacao']}
                                                                    </td>

                                                                    {/*TIPO/MODALIDADE*/}
                                                                    <td className='td-content cd-col-6'>
                                                                        {dados['tipo_atendimento']} <br /> {dados['modalidade']}
                                                                    </td>
                                                                    {/*STATUS TERAPEUTA*/}
                                                                    <td className='td-content cd-col-7'>
                                                                        {dados['terapeuta_status_nome']}
                                                                    </td>

                                                                    {/*STATUS*/}
                                                                    <td className='td-content cd-col-8'>
                                                                        {permissoes['criacao_edicao']
                                                                            ? <Form>
                                                                                <Select
                                                                                    id={`status-recepcao${dados['id']}`}
                                                                                    name={`status-recepcao${dados['id']}`}
                                                                                    options={statusAtendimento}
                                                                                    readOnly={controleDiario?.salvo !== null}
                                                                                    defaultValue={statusValue === undefined ? '' : statusValue}
                                                                                    onChange={(e) => { handleChangeStatusRecepcao(e, atendimento, false); }}
                                                                                />
                                                                            </Form>
                                                                            : statusValue.label
                                                                        }
                                                                    </td>

                                                                    {/*LIBERADO*/}
                                                                    <td className='td-content cd-col-9'>
                                                                        {permissoes['criacao_edicao']
                                                                            ? <Form>
                                                                                <Select
                                                                                    id={`status-liberado${dados['id']}`}
                                                                                    name={`status-liberado${dados['id']}`}
                                                                                    options={opcoesLiberado}
                                                                                    readOnly={controleDiario?.salvo !== null}
                                                                                    onChange={(e) => handleChangeAtendimentoLiberado(e, atendimento.id, false)}
                                                                                    defaultValue={
                                                                                        (atendimentosL[index2].liberado)
                                                                                            ? opcoesLiberado.filter((e) => (e.value === Number(atendimentosL[index2].liberado)))[0]
                                                                                            : { value: null, label: '' }
                                                                                    }
                                                                                />
                                                                            </Form>
                                                                            : opcoesLiberado.filter((e) => (e.value === Number(atendimentosL[index2].liberado)))[0].label
                                                                        }
                                                                    </td>

                                                                    {/*STATUS EXECUCAO*/}
                                                                    <td style={
                                                                            (pacienteEmExecucaoList.filter((filter) => {
                                                                                return filter.paciente_id == dados['paciente_id']
                                                                            }).length > 0)
                                                                                ? { border: '6px #fff000 solid' }
                                                                                : {}
                                                                        }
                                                                        className={
                                                                            (dados['executado'] === 'pendente')
                                                                                ? 'p-pendende td-content cd-col-10'
                                                                                : (dados['executado'] === 'executado')
                                                                                    ? 'p-executado td-content cd-col-10'
                                                                                    : (dados['executado'] === 'pago')
                                                                                        ? 'p-executado td-content cd-col-10'
                                                                                        : 'td-content cd-col-10'
                                                                        }>
                                                                        {dados['executado']}
                                                                    </td>

                                                                    {/*COMENTARIO*/}
                                                                    <td className='td-content cd-col-11'>
                                                                        {permissoes['criacao_edicao']
                                                                            ? <BsEye className='icone-padrao' size={22} onClick={() => handleModalComentarios(dados)} />
                                                                            : dados['comentario']
                                                                        }
                                                                    </td>

                                                                    {/*P*/}
                                                                    <th onClick={() => (atendimento?.conflitos.liberado_realizado_sem_codigo ||
                                                                            atendimento?.conflitos.liberado_realizado_sem_codigo2 ||
                                                                            atendimento?.conflitos.realizado_nao_liberado ||
                                                                            atendimento?.conflitos.sem_execucao ||
                                                                            atendimento?.conflitos.sem_guia ||
                                                                            atendimento?.conflitos.sem_liberacao ||
                                                                            atendimento?.conflitos.sem_status ||
                                                                            atendimento?.conflitos.sem_terapeuta ||
                                                                            atendimento?.conflitos.horario_paciente ||
                                                                            atendimento?.conflitos.sala_terapeuta) &&
                                                                            handleOpenModalPendencias(atendimento.conflitos)
                                                                        }
                                                                        className={
                                                                            (atendimento?.conflitos.liberado_realizado_sem_codigo ||
                                                                                atendimento?.conflitos.liberado_realizado_sem_codigo2 ||
                                                                                atendimento?.conflitos.realizado_nao_liberado ||
                                                                                atendimento?.conflitos.sem_execucao ||
                                                                                atendimento?.conflitos.sem_guia ||
                                                                                atendimento?.conflitos.sem_liberacao ||
                                                                                atendimento?.conflitos.sem_status ||
                                                                                atendimento?.conflitos.sem_terapeuta
                                                                            ) ? 'td-p-pendente cd-col-12 pointer'
                                                                                : atendimento?.conflitos.horario_paciente || atendimento?.conflitos.sala_terapeuta
                                                                                    ? 'cd-col-12 bg-laranja-i'
                                                                                    : 'td-p-ok cd-col-12'
                                                                        }>
                                                                    </th>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                            )
                                        })
                                        : <></>
                                    };
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colSpan={12} className='td-footer d-flex justify-content-center align-items-center flex-wrap p-0 m-0 ps-4 pe-4'>
                                            <div className='d-flex'>
                                                <span className='p-0 m-0 me-2 d-flex align-items-center'>Total:
                                                    <b className='ms-1' style={{ color: terapeutaSelecionado || pacienteSelecionado || planoSaudeSelecionado || statusSelecionado ? '#ccc000' : '#000' }}>
                                                        {quantidadeAtendimentos}
                                                    </b>
                                                </span>
                                                <div className="form-check-reverse form-switch me-2">
                                                    <label className="form-check-label" htmlFor="apenas-sucesso-input" style={{ textDecoration: habilitaSucesso ? 'none' : 'line-through' }}>
                                                        <span className='font-sucesso p-0 m-0'>Sucessos</span>:
                                                        <b className='ms-1' style={{ color: terapeutaSelecionado || pacienteSelecionado || planoSaudeSelecionado || statusSelecionado ? '#ccc000' : '#000' }}>
                                                            {quantidadeSucessos}
                                                        </b>
                                                    </label>
                                                    <input className="form-check-input switch-sucesso" type="checkbox" role="switch" id="apenas-sucesso-input" checked={habilitaSucesso} onChange={(e) => handleChangeHabilitaSucesso(e)} />
                                                </div>
                                                <div className="form-check-reverse form-switch me-2 ms-2" style={{ color: '' }}>
                                                    <label className="form-check-label" htmlFor="apenas-erros-input" style={{ textDecoration: habilitaErros ? 'none' : 'line-through' }}>
                                                        <span className='font-pendente p-0 m-0'>Erros</span>:
                                                        <b className='ms-1' style={{ color: terapeutaSelecionado || pacienteSelecionado || planoSaudeSelecionado || statusSelecionado ? '#ccc000' : '#000' }}>
                                                            {quantidadeErros}
                                                        </b>
                                                    </label>
                                                    <input className="form-check-input switch-erro" type="checkbox" role="switch" id="apenas-erros-input" checked={habilitaErros} onChange={(e) => handleChangeHabilitaErros(e)} />
                                                </div>
                                                <div className="form-check-reverse form-switch me-2 ms-2">
                                                    <label className="form-check-label" htmlFor="apenas-conflitos-input" style={{ textDecoration: habilitaConflitos ? 'none' : 'line-through' }}>
                                                        <span className='font-laranja p-0 m-0'>Conflitos</span>:
                                                        <b className='ms-1' style={{ color: terapeutaSelecionado || pacienteSelecionado || planoSaudeSelecionado || statusSelecionado ? '#ccc000' : '#000' }}>
                                                            {quantidadeConflitos}
                                                        </b>
                                                    </label>
                                                    <input className="form-check-input switch-conflito" type="checkbox" role="switch" id="apenas-conflitos-input" checked={habilitaConflitos} onChange={(e) => handleChangeHabilitaConflitos(e)} />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </tfoot>
                            </table>
                    }
                </div>
                : <div className="justify-center w-100per align-center text-center">
                    <ClipLoader color={color} loading={loading} size={50} />
                </div>
            }
            {/* Modal de Novo Atendimento */}
            {isModalAdicionarAtendimentoVisible && <AdicionarAgendamento onClose={() => { setIsModalAdicionarAtendimentoVisible(false); }} date={dataOriginal.format('YYYY-MM-DD')} agendamentos={atendimentosNormal} updateAgenda={() => getAgendamentosControleDiario(controleDiarioId)} incluirAtendimento={true} />}
            {/* Modal de Legenda */}
            {isModalLegendaVisible && <LegendaDefault onClose={() => setIsModalLegendaVisible(false)} legendaMode={'controle-diario'} />}
            {/* Modal do Responsavel da Sala */}
            {isModalResponsavelVisible && <Responsavel onClose={() => setIsModalResponsavelVisible(false)} />}
            {/* Modal de Alteração do Atendimento */}
            {isModalAtendimentoOpen && <AtendimentoModal atendimentoId={atendimentoModalSelecionado.id} statusAtendimento={statusAtendimento} controleDiarioId={controleDiarioId} tipoAtendimentoOptions={tipoAtendimentosOptions} horarioAtendimentoOptions={horarioAtendimentoComFimOptions} pacienteOptionsOriginal={pacienteOptions} modalidadesOptions={modalidadesOptions} pacientes={pacientes} estacaoOptions={salaEstacaoOptions} atendimentos={atendimentosOriginal} atendimento={atendimentoModalSelecionado} setHouveAlteracaoStatusEdicao={() => setHouveAlteracaoStatusEdicao(true)} onClose={() => [setIsModalAtendimentoOpen(false), getAgendamentosControleDiario()]} isAtendimentoEditavel={isAtendimentoEditavel} snackbar={snackbar} />}
            {/* Modal de Visualização e Edição do Comentário */}
            <ComentariosModal ref={modalComentariosRef} getAgendaDia={getAgendamentosControleDiario}></ComentariosModal>
            {/* Modal de Controle de Execução */}
            <ControleDeExecucaoModal ref={checkInModalRef} user={usuario.user} controleDiarioSocket={socket} onClose={handleCloseModalControleDeExecucao}></ControleDeExecucaoModal>
            {/* Modal de Visualização de Conflitos e Erros do Atendimento */}
            <Conflitos ref={pendenciasRef} />
            {/* Modal de Confirmação de Conflitos do Atendimento */}
            <ConferirConflitos ref={conferirConflitosModalRef} salvarControleDiario={() => { salvarControleDiario(true) }} />
            <LiveBadge sincronizado={socketState} texto={false} />
        </AtendimentoReposicaoProvider >
    );
    //#endregion
}