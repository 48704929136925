import React, { useEffect, useState } from 'react';

const ScheduleUpdates = () => {
    const [messages, setMessages] = useState([]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const nome = document.getElementById('nome').value;
        const mensagem = document.getElementById('mensagem').value;
        const data = {
            nome,
            mensagem,
        };
        const ws = new WebSocket('wss://localhost:6001/sync');
        ws.send(JSON.stringify(data));
        document.getElementById('mensagem').value = '';
    };

    useEffect(() => {
        const ws = new WebSocket('wss://localhost:6001/sync');

        ws.onopen = () => {
            console.log('Connected to WebSocket server');
        };

        ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages((prevMessages) => [...prevMessages, data]);
        };

        ws.onclose = () => {
            console.log('Disconnected from WebSocket server');
        };

        return () => {
            ws.close();
        };
    }, []);

    return (
        <div>
            <form onSubmit={handleSubmit} className=''>
                <input type='text' id='nome' nome='nome' placeholder='Seu nome' />
                <textarea id='mensagem' nome='mensagem'></textarea>
                <button type='submit'>Enviar</button>
            </form>
            {messages.map((message, index) => (
                <div key={index}>
                    <strong>{message.nome}:</strong> {message.mensagem}
                </div>
            ))}
        </div>
    );
};

export default ScheduleUpdates;