import React, { useRef, useState, useEffect } from "react";
import { Container } from './styles'
import { Form } from '@unform/web'
import Input from '../../../../components/Input';
import InputMask from "../../../../components/InputMask";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from "../../../../components/Select";
import PacienteService from "../../../../services/PacienteService";
import Cep from "../../../../services/cep";
import moment from "moment/moment";
import ModalInformacoesComplementares from "../../../../components/Modais/InformacoesComplementares";
import Auditor from "../../../../layouts/Auditor";
import { StatusAfastado, StatusAtivo, StatusFerias, StatusInativo } from "../../../AdminDashboard/Pacientes/Perfil/styles";
import Carregamento from "../../../../components/Carregamento";
import { ClipLoader } from "react-spinners";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import { FiEye } from "react-icons/fi";
import { BsArrowLeft, BsPaperclip } from "react-icons/bs";
import RelatorioEvolucaoPaciente from "../../../../components/Modais/TerapeutaRelatorios/RelatorioEvolucaoPaciente";
import Button from "../../../../components/Button";

export default function PerfilPacienteAuditor() {
    /* ===VARIAVEIS=== */
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);
    const [pacienteStatus, setPacienteStatus] = useState([]);
    const [paciente, setPaciente] = useState(null);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [statusColor, setStatusColor] = useState();
    const [evolucoes, setEvolucoes] = useState([]);
    const [evolucaoSelecionada, setEvolucaoSelecionada] = useState(null);
    const [planoSaudeId, setPlanoSaudeId] = useState(null);

    const relatorioEdicaoModalRef = useRef();
    const [tipoModal, setTipoModal] = useState('visualizacao')
    const columns = React.useMemo(
        () => [
            {
                Header: 'Data de Criação',
                accessor: 'data_criacao',
                Cell: ({ cell }) => {
                    return (moment(cell.row.original.created_at).format('DD/MM/YYYY'))
                }
            },
            {
                Header: 'Terapeuta',
                accessor: 'terapeuta.nome'
            },
            {
                Header: 'Terapia',
                accessor: 'terapium.terapia'
            },
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ cell }) => {
                    const style = { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '15px', margin: 'auto' }
                    switch (cell.row.original.status) {
                        case ('aprovado'):
                            return (
                                <p style={{ ...style, backgroundColor: "#00ff00" }}>
                                    Aprovado
                                </p>
                            )
                        case ('glosado'):
                            return (
                                <p style={{ ...style, backgroundColor: "#ff0000" }}>
                                    Glosado
                                </p>
                            )
                        case ('aceito'):
                            return (
                                <p style={{ ...style, backgroundColor: "#00ff00" }}>
                                    Aceito
                                </p>
                            )
                        default:
                            return (
                                <p style={{ ...style, backgroundColor: "#ffff00" }}>
                                    Pendente
                                </p>
                            )
                    }
                }
            },
            {
                Header: 'Acoes',
                accessor: 'acoes',
                Cell: ({ cell }) => {
                    return (
                        <div className='col-12' title={"Plano: " + cell.row.original.plano_saude_id}>
                            <button className='btn-outline' onClick={(e) => handleOpenModalEvolucao(cell.row.original.id)}>
                                <FiEye size={20} />
                            </button>
                        </div>
                    )
                }
            },
        ]
    )
    const [observacoes, setObservacoes] = useState("");
    const [infoComplModal, setInfoComplModal] = useState(false);
    const formRef = useRef(null);

    function handleOpenModalEvolucao(id) {
        setTipoModal(`visualizacao`);
        relatorioEdicaoModalRef.current.openModal(id);
    }

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        ).replace(/_/g, ' ');
    }

    function getTitle(str) {
        switch (str) {
            case 'estado_geral':
                return 'Estado Geral';
            case 'atividade_realizada':
                return 'Atividade Realizada';
            case 'intercorrencia':
                return 'Intercorrência';
            case 'comentario_geral':
                return 'Comentário Geral';
            case 'desempenho':
                return 'Desempenho';
            case 'recursos_tecnicas':
                return 'Recursos e Técnicas';
            case 'objetivo':
                return 'Objetivo';
            default:
                return toTitleCase(str);
        }
    }

    function listaArquivos() {
        let lista = [];
        for (let index = 0; index < evolucaoSelecionada.arquivo_evolucao_pacientes.length; index++) {
            const item = evolucaoSelecionada.arquivo_evolucao_pacientes[index];
            lista.push(
                <a href={item.caminho_full} target='blank' download>
                    <div>
                        <BsPaperclip size={25} />
                    </div>
                    <div>
                        Arquivo {index + 1}
                    </div>
                </a>
            );
        }
        return lista;
    }

    const getDefaultStatus = (statusPaciente) => {
        if (!paciente) return;
        const status = statusPaciente.filter(data => {
            return data.label === paciente.status;
        })[0];
        setStatusSelecionado(status);
    };

    const carregaPaciente = (plano_saude_id) => {
        plano_saude_id = planoSaudeId ? planoSaudeId : plano_saude_id;
        setloading(true);
        PacienteService.getByIdFilterPlano(id, plano_saude_id, 'with=evolucao_pacientes,evolucao_pacientes.especialidade,evolucao_pacientes.terapium,evolucao_pacientes.arquivo_evolucao_pacientes')
            .then(({ data }) => {
                data = data[0];
                setEvolucoes(data.evolucao_pacientes.filter((e) => Number(e.plano_saude_id) === Number(plano_saude_id)));
                setPaciente(data);

                delete data.responsavels;
                delete data.documentos;
                delete data.paciente_ferias;
                delete data.terapia_pacientes;

                data = {
                    ...data,
                    cpf: data.cpf ? data.cpf.toString() : '',
                    cep: data.cep ? data.cep.toString() : '',
                    nascimento: moment.utc(data.nascimento).format('YYYY-MM-DD'),
                };

                setObservacoes(data.obs);
                formRef.current.setData(data);
            })
            .catch((e) => console.error('e', e))
            .finally(() => {
                setloading(false);
            });
    }

    const getCep = async (cep) => {
        const { data } = await Cep.getAddressByCep(cep);
        document.getElementById('endereco').value = data.logradouro
        document.getElementById('bairro').value = data.bairro
        document.getElementById('estado').value = data.uf
        document.getElementById('cidade').value = data.localidade
    }

    useEffect(() => {
        getDefaultStatus(pacienteStatus);
        if (paciente !== null) {
            if (paciente.status === "Ativo") setStatusColor(StatusAtivo);
            else if (paciente.status === "Afastado") setStatusColor(StatusAfastado);
            else if (paciente.status === "Inativo") setStatusColor(StatusInativo);
            else {
                setStatusColor(StatusFerias);
            }
        }
        getDefaultStatus(pacienteStatus);
    }, [paciente])

    useEffect(() => {
        let statusList = [];
        statusList.push({
            label: 'Ativo',
            value: 'ativo'
        });
        statusList.push({
            label: 'Inativo',
            value: 'inativo'
        });
        statusList.push({
            label: 'Afastado',
            value: 'afastado'
        });
        statusList.push({
            label: 'Férias',
            value: 'ferias'
        });
        setPacienteStatus(statusList);
        let plano_saude_id = location?.state?.plano_saude_id;
        setPlanoSaudeId(plano_saude_id);
        Promise.all([carregaPaciente(plano_saude_id)]);
    }, [])

    return (
        <Auditor>
            <Container>
                <div className="d-flex w-100per flex-col">
                    <div className="row justify-between pt-10px align-content-start">
                        <div className='d-flex justify-content-between text-right m-0'>
                            <div className="d-flex">
                                <Button className="btn-padrao h-content-i" type="button" onClick={() => navigate('/dashboard/auditor/')}>
                                    <BsArrowLeft size={28} /> Voltar
                                </Button>
                            </div>
                            <div className="d-flex flex-col">
                                <img className="perfil-image border" src={
                                    paciente && paciente.foto_perfil
                                } height={140} alt=''></img>
                                <p className="p-0 m-0">{paciente?.nome}</p>
                            </div>
                            <div className='d-flex align-center w-content h-content text-right' style={{ backgroundColor: '#fff', borderRadius: 8, boxShadow: "0 0 3px 0px silver", padding: '2px 15px' }}>
                                {paciente === null ? "" : <div style={statusColor} />}
                                {paciente === null ? "" : paciente.status}
                            </div>
                        </div>
                    </div>
                    <Form ref={formRef}>
                        <div className="container">
                            <ul className="nav nav-tabs z-3" id="tab-list" role="tablist">
                                <li className="nav-item z-3">
                                    <a className="nav-link active z-3" id="list-dados-list" data-bs-toggle="list" href="#list-dados" role="tab" aria-controls="list-dados">Dados do Paciente</a>
                                </li>
                                <li className="nav-item z-3">
                                    <a className="nav-link z-3" id="list-evolucoes-list" data-bs-toggle="list" href="#list-evolucoes" role="tab" aria-controls="list-evolucoes">Evoluções Diárias</a>
                                </li>
                            </ul>
                            <div className="tab-content z-0" id="tab-content">
                                <div id="list-dados" data-bs-toggle="list" className="tab-pane fade show active bg-branco border" role="tabpanel">
                                    <div className="p-4 pt-2">
                                        <div className='row'>
                                            <div style={{ width: "40%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Nome:" name="nome" />
                                            </div>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Nome Curto" name="nome_curto" />
                                            </div>
                                            <div style={{ width: "10%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} maxLength={3} title="Identificador:" name="identificador" />
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <Input title="Data de Entrada:" name="data_de_entrada" icon={null} type={'date'} maskformat={"99/99/9999"} disabled={'disabled'} />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <Select label="Status:" name="status" options={pacienteStatus} value={statusSelecionado} onChange={setStatusSelecionado} disabled={'disabled'} readOnly={'readOnly'} />
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <Input title="Data de Nascimento:" name="nascimento" icon={null} type={'date'} disabled={'disabled'} />
                                            </div>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <InputMask disabled={'disabled'} title="CPF" name="cpf" icon={null} maskformat={"999.999.999-99"} containerStyle={{ color: 'rgb(84, 84, 84)', borderRadius: '8px', backgroundColor: 'rgb(236, 236, 236)' }} />
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="RG:" name="rg" />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <InputMask onBlur={(ev) => getCep(ev.target.value)} title="CEP" name="cep" id="cep" icon={null} maskformat={"99999-999"} disabled={'disabled'} containerStyle={{ color: 'rgb(84, 84, 84)', borderRadius: '8px', backgroundColor: 'rgb(236, 236, 236)' }} />
                                            </div>
                                            <div style={{ width: "60%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Rua:" name="endereco" id="endereco" />
                                            </div>
                                            <div style={{ width: "20%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Cidade:" name="cidade" id="cidade" />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Estado:" name="estado" id="estado" />
                                            </div>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Complemento:" name="complemento" id="complemento" />
                                            </div>
                                            <div style={{ width: "30%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Bairro:" name="bairro" id="bairro" />
                                            </div>
                                            <div style={{ width: "10%", paddingRight: 10 }}>
                                                <Input disabled={'disabled'} title="Número:" name="numero" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="list-evolucoes" data-bs-toggle="list" className="tab-pane fade bg-branco border" role="tabpanel">
                                    <div className="p-4 pb-2">
                                        {loading === true
                                            ? <div className='d-flex w-100per justify-center'>
                                                <ClipLoader size={30} color='purple'></ClipLoader>
                                                {/* TABELA */}
                                            </div>
                                            : <TableDefaultPagination columns={columns} source={evolucoes} enableFooter={false} initialState={{ pageSize: 4 }} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                    {/* CARREGAMENTO */}
                    {loading && <Carregamento></Carregamento>}
                </div>
                {infoComplModal && (
                    <ModalInformacoesComplementares onClose={() => setInfoComplModal(false)} paciente={paciente} />
                )}
            </Container>
            <RelatorioEvolucaoPaciente ref={relatorioEdicaoModalRef} tipo={tipoModal} />
        </Auditor>
    )
}
