import {ItemLista, Lista} from './styles';
import { useSnackbar } from '../../../../context/snackbar';

export function ListaVariaveisDocumentoPersonalizado({ variaveis }) {

  const snackbar = useSnackbar();

  function copyToClipboard(data) {
    //copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(data);
    snackbar.displayMessage('Texto copiado!', 'success');
  }

  return (
      <>
        <p style={{marginTop: 10}}>Utilize as variáveis para redigir o documento, delimitando com chaves no início e final, exemplo: <b>{variaveis[0]}</b></p>
        <p style={{marginTop: 10}}>Clique para copiar:</p>
        <Lista>
          {variaveis.map((variavel) => {
            return <ItemLista><span id="text-variable" style={{cursor: 'pointer'}} onClick={e => copyToClipboard(variavel)}>
              {variavel}
              </span>
            </ItemLista>
          })}
        </Lista>
      </>
  );
};
