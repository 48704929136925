import React from 'react'

import Container from '../components/Container'
import Header from '../components/Header'

const Terapeuta = ({ children, pendente }) => (
    <>
        {pendente?'':<Header />}
        <Container>
            {children}
        </Container>
    </>
)

export default Terapeuta