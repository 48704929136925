import React from "react";
import { Modal, Container } from './styles';
import { FormField } from '../../styles';
import { Form } from '@unform/web'
import Input from '../../../../../components/Input';
import Select from "../../../../../components/Select";
import SolicitacaoAtividadeExterna from '../../../../../generated/SolicitacaoAtividadeExterna';
import TextArea from "../../../../../components/TextArea";
import { useSnackbar } from "../../../../../context/snackbar";
import Swal from "sweetalert2";
import { useAuth } from "../../../../../context/auth";
import { useEffect } from "react";
import moment from "moment";
import { useRef } from "react";

export default function AtividadeExterna({ id = 'modal', onClose = () => { }, pacientes, terapias, especialidades, modalData }) {

    const snackbar = useSnackbar();
    const user = useAuth();
    const formRef = useRef(null);

    const {
        loadSolicitacaoAtividadeExternas,
        storeSolicitacaoAtividadeExterna,
        PacienteId,
        setPacienteId,
        TerapiaId,
        setTerapiaId,
        EspecialidadeId,
        setEspecialidadeId,
        Descricao,
        setDescricao,
        PacienteIdPlanoSaude,
        setPacienteIdPlanoSaude,
        UserId,
        setUserId,
    } = SolicitacaoAtividadeExterna()

    const handleSubmit = async (form) => {

        if (PacienteId === null || TerapiaId === null || EspecialidadeId === null || form.Descricao === "" ||
            form.Horario === "" || form.Local === "") {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning');
        } else {
            if (await storeSolicitacaoAtividadeExterna(form)) {
                onClose();
                snackbar.displayMessage("Solicitação criada com sucesso!", "success");
            } else {
                Swal.fire('Ocorreu um erro ao cadastrar a solicitação!', '', 'error');
            };
        };
    }
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    }

    const handlePaciente = (e) => {
        setPacienteId(e.value)
        setPacienteIdPlanoSaude(e.plano);
    }

    const handleTerapia = (e) => {
        setTerapiaId(e.value)
    }

    const handleEspecialidade = (e) => {
        setEspecialidadeId(e.value)
    }

    const functionThatSubmitsForm = () => {
        formRef.current.submitForm();
    }

    useEffect(() => {
      setUserId(user.user.id)
    }, [])
    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <Container style={{overflowY: "auto"}}>
                <div style={{position: "relative"}}>
                    <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
                    right: "5px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
                    x
                    </label>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Solicitação Atividade Externa</label>
                </div>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <FormField>
                        {modalData === undefined ? <Select label="Paciente" name="Paciente" options={pacientes} onChange={handlePaciente} />
                            : <Input title={"Paciente:"} name="Paciente" defaultValue={modalData.paciente} readOnly={'readOnly'}/>}
                    </FormField>
                    <FormField>
                        <FormField>
                            {modalData === undefined ? <Select label="Terapia" name="Terapia" options={terapias} onChange={handleTerapia} />
                                : <Input title={"Terapia:"} name="Terapia" defaultValue={modalData.terapia} readOnly={'readOnly'}/>}
                            
                        </FormField>
                        <FormField>
                            {modalData === undefined ? <Select label="Especialidade" name="Especialidade" options={especialidades} onChange={handleEspecialidade} />
                                : <Input title={"Especialidade:"} name="Especialidade" defaultValue={modalData.especialidade} readOnly={'readOnly'}/>}
                            
                        </FormField>
                    </FormField>
                    <FormField>
                        <Input title="Objetivo:" name="Objetivo" defaultValue={modalData === undefined ? '' : modalData.objetivo} />
                    </FormField>
                    <FormField>
                        <div style={{ width: "50%" }}>
                            <Input title="Local:" name="Local" defaultValue={modalData === undefined ? '' : modalData.local}/>
                        </div>
                        <div style={{ width: "50%" }}>
                            <Input type="time" title="Horário:" name="Horario" defaultValue={modalData === undefined ? '' : 
                            moment(modalData.horario).format('hh:mm')} />
                        </div>
                    </FormField>
                    <FormField>
                        <TextArea title="Descrição:" name="Descricao" defaultValue={modalData === undefined ? '' : modalData.descricao} />
                    </FormField>
                    {/* <FormField>
                        <AiOutlineCloudUpload color="#6A3BAF" size={36} />
                        <label>Anexar Arquivos</label>
                    </FormField> */}
                    <FormField>
                        <button style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }} onClick={e => onClose(e)}>
                            Voltar
                        </button>
                        {modalData === undefined ? <button style={{
                            fontWeight: "600",
                            fontSize: "18", backgroundColor: "#6A3BAF", color: "#FFFFFF",
                            border: "none", borderRadius: 8, padding: "4px 40px"
                        }} onClick={functionThatSubmitsForm}>
                            Confirmar
                        </button> : ''}
                    </FormField>
                </Form>
            </Container>
        </Modal>
    )
}