import React from 'react';
import {Container, Modal} from '../styles';
import {Documento} from '../../Documento';

export default function DocumentoVisualizacaoAssinaturaModal({ documento, onClose }) {
  const modalId = 'modal-documento-visualizacao-assinatura';

  const handleOutsideClick = (e) => {
    if (e.target.id === modalId) onClose();
  };

  return (
      <Modal id={modalId} onClick={handleOutsideClick}>
        <Container style={{width: '50%', minWidth: '700px', alignItems: 'center'}}>
          <div style={{position: "relative"}}>
            <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
              right: "-350px", fontSize: "25px", top: "-20px" }} onClick={() => onClose()}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '24', color: '#6A3BAF' }}>Documento {documento.documentos_administrativo.nome_documento}</label>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2vh' }}>
            <Documento documentoId={documento.documentos_administrativo.id} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '5vh', width: '30%', alignItems: 'center' }}>
            <p>Assinatura:</p>
            <img src={documento.assinatura} alt='Assinatura documento' />
          </div>
        </Container>
      </Modal>
  );
}
