import React from "react";
import {
    AiOutlineCloseCircle, AiOutlineProject,
    AiOutlineSolution, AiOutlineApartment, AiOutlineSnippets, AiOutlineShareAlt,
    AiOutlineExclamationCircle,
    AiOutlineForm
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CardButtonStyle1, Container, ContainerAtalhos } from './styles'
import Admin from "../../../layouts/Admin";

export default function ConfiguracoesAdmin() {

    const navigate = useNavigate();

    return (
        <Admin>
            <Container>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: 3 }}>
                    <div style={ContainerAtalhos}>
                        <div style={{ marginBottom: 15, marginTop: 20, textAlign: "center" }}>
                            <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>Configurações:</span>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: 10 }}>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/grupopermissoes')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineCloseCircle size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Permissões</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/tipoatendimento')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineProject size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Tipos de atendimento</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/terapias')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineSolution size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Terapias</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/especialidades')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineSolution size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Especialidades</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/modalidades')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineSolution size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Modalidades</span>
                                </button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: 10 }}>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/salas')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineApartment size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Salas</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/estacoes')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineApartment size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Estações</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/documentos')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineSnippets size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Documentos</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/areacompartilhada')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineShareAlt size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Área Compartilhada</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/status')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineExclamationCircle size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Status</span>
                                </button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", padding: 10 }}>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/tiposavaliacao')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineForm size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Tipos de Avaliação</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/horarios-atendimento')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineForm size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Horários Atendimento</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/administrador/configuracoes/diagnosticos')}
                                    style={CardButtonStyle1}>
                                    <AiOutlineForm size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Cadastro Diagnósitco</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </Admin>
    )
}
