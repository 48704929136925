import React, { useMemo, forwardRef } from "react";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import ResponsavelService from "../../../../../services/ResponsavelService";
import Swal from "sweetalert2";
import { useSnackbar } from "../../../../../context/snackbar";
import api from "../../../../../services/api";
import { TableDefaultPagination } from "../../../../../components/TableDefaultPagination";

const TableResponsavel = forwardRef(({ source, setModalData, setResponsaveis, handleOpen, readOnly = false }, ref) => {
    //#region VARIAVEIS
    const snackbar = useSnackbar();
    //#endregion
    //#region FUNCOES
    const removeResponsavel = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let responsaveisLocal = [...source];
                if (data.id_paciente === null || data.id_paciente === undefined) {
                    let newList = responsaveisLocal.filter((item) => item.id !== data.id);
                    setResponsaveis(newList);
                    snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                } else {
                    ResponsavelService.destroy(data.id).then(() => {
                        const index = responsaveisLocal.indexOf(data);
                        responsaveisLocal.splice(index, 1);
                        setResponsaveis(responsaveisLocal);
                        snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    }).catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
                }
            }
        });
    }
    const removeResponsavelVinculo = (data) => {
        Swal.fire({
            title: 'Deseja realmente remover este registro ?',
            showDenyButton: true,
            confirmButtonText: 'Remover',
            denyButtonText: `Cancelar`,
        }).then((result) => {
            if (result.isConfirmed) {
                let responsaveisLocal = [...source];
                if (data.id_paciente === null || data.id_paciente === undefined) {
                    const index = responsaveisLocal.indexOf(data);
                    responsaveisLocal.splice(index, 1);
                    setResponsaveis(responsaveisLocal);
                    snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                } else {
                    ResponsavelService.destroy(data.pr_id, 'vinculo').then(() => {
                        const index = responsaveisLocal.indexOf(data);
                        responsaveisLocal.splice(index, 1);
                        setResponsaveis(responsaveisLocal);
                        snackbar.displayMessage("Cadastro removido com sucesso!", "success")
                    }).catch(() => {
                        Swal.fire('Houve um problema ao remover o cadastro!', '', 'error');
                    });
                }
            }
        });
    }
    //#endregion
    //#region HANDLES
    const handleEditResponsavel = (data) => {
        setModalData(data);
        handleOpen();
    };
    const handleChangeResponsavelLegal = (e) => {
        let responsaveisLocal = [...source];
        const index = responsaveisLocal.indexOf(e);
        let valor = e.responsavel_fiscal == 0 ? 1 : 0;
        if (e.ja_registrado === null || e.ja_registrado === undefined || e.ja_registrado === false) {
            api.put(`api/responsavel/${e.id}`, { responsavel_fiscal: valor }).then(() => {
                responsaveisLocal[index].responsavel_fiscal = valor;
                snackbar.displayMessage("Responsável Legal alterado com sucesso!", "success")
            }).catch((e) => {
                console.error(e);
                Swal.fire('Houve um problema ao alterar o responsável legal!', '', 'error');
            });
        } else {
            api.put(`api/paciente-responsavel/${e.pr_id}`, { responsavel_fiscal: valor, responsavel_legal: valor }).then(() => {
                responsaveisLocal[index].responsavel_fiscal = valor;
                responsaveisLocal[index].responsavel_legal = valor;
                snackbar.displayMessage("Responsável Legal alterado com sucesso!", "success")
            }).catch((e) => {
                console.error(e);
                Swal.fire('Houve um problema ao alterar o responsável legal!', '', 'error');
            });
        }
        setResponsaveis(responsaveisLocal);
    }
    //#endregion
    const columns = useMemo(
        () => [
            {
                Header: 'Nome',
                accessor: 'nome', // accessor is the "key" in the data
            },
            {
                Header: 'Parentesco',
                accessor: 'parentesco',
            },
            {
                Header: 'CPF',
                accessor: 'cpf',
            },
            {
                Header: 'Responsável Legal',
                accessor: 'responsavel_fiscal',
                Cell: ({ cell }) => {
                    if ((cell.row.original.pr_id !== null && cell.row.original.pr_id !== undefined) || (cell.row.original.id_paciente !== null && cell.row.original.id_paciente !== undefined)) {
                        return (
                            <div className="d-flex justify-content-center">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={(e) => !readOnly && handleChangeResponsavelLegal(cell.row.original)} checked={cell.row.original.responsavel_fiscal == 1} readOnly={readOnly ? 'readOnly' : ''} />
                                    <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Responsável Legal</label>
                                </div>
                            </div>
                        );
                    } else {
                        return <p>...</p>;
                    }
                }
            },
            {
                Header: "Ações:",
                accessor: "acoes",
                Cell: ({ cell }) => {
                    if (!readOnly) {
                        if (cell.row.original.ja_registrado === null || cell.row.original.ja_registrado === undefined || cell.row.original.ja_registrado === false) {
                            return (
                                <div className="d-flex justify-content-center align-items-center bg-transparent m-2">
                                    <AiOutlineEdit style={{ cursor: "pointer" }} color="#7340BD" size={22} onClick={e => handleEditResponsavel(cell.row.original)} />
                                    <AiOutlineDelete className="pointer font-roxo" size={22} onClick={e => removeResponsavel(cell.row.original)} />
                                </div>
                            )
                        } else {
                            return (
                                <div className="d-flex justify-content-center align-items-center bg-transparent m-2">
                                    {(cell.row.original.id_paciente !== null || cell.row.original.paciente_id !== null) &&
                                        <AiOutlineEdit style={{ cursor: "pointer" }} color="#7340BD" size={22} onClick={e => {
                                            if (cell.row.original.type === undefined || cell.row.original.type === 'responsavel') {
                                                handleEditResponsavel(cell.row.original)
                                            } else {
                                                Swal.fire({
                                                    title: 'Impossibilitado de editar este registro!',
                                                    text: `Este responsável é um ${cell.row.original?.tipo_user === 'terapeuta' ? 'terapeuta' : 'funcionário'}, para edita-lo acesse o seu cadastro na aba ${cell.row.original?.tipo_user === 'terapeuta' ? 'Terapeutas' : 'Funcionários'}.`
                                                });
                                            }
                                        }} />
                                    }
                                    <AiOutlineDelete className="pointer font-roxo" size={22} onClick={e => removeResponsavelVinculo(cell.row.original)} />
                                </div>
                            )
                        }
                    } else {
                        return <></>
                    }
                }
            }
        ],
        [source]
    );
    return (
        <TableDefaultPagination columns={columns} source={source} enableFooter={false} />
    );
});

export default TableResponsavel;
