import React, { forwardRef, useCallback, useState } from 'react';
import Modal from 'react-modal';
import { Form } from '@unform/web';
import { useSnackbar } from '../../../context/snackbar';
import Swal from 'sweetalert2';
import api from '../../../services/api';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		maxHeight: '70vh',
		width: '35vw',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#FFFFFF"
	},
};

const PermissaoModal = ({ modalIsOpen, setIsOpen, callback, modalData }, ref) => {
	let subtitle;

	const [formData, setFormData] = useState({
		id: null,
		name: '',
		guard_name: '',
		description: '',
		deleted_at: '',
		created_at: '',
		updated_at: '',
		link: '',
		title: '',
		icon: '',
		placeholder: '',
		linkable: '',
		sublinkable: '',
		has_submenu: '',
		submenu_position: '',
	});
	const snackbar = useSnackbar();
	const [has_submenu, setHasSubmenu] = useState();
	const [sublinkable, setSublinkable] = useState();
	const [linkable, setLinkable] = useState();

	function afterOpenModal() {
		subtitle.style.color = '#000';
		if (modalData !== null) {
			setHasSubmenu(modalData.has_submenu);
			setLinkable(modalData.linkable);
			setSublinkable(modalData.sublinkable);
			setFormData(modalData);
		}
	}

	const closeModal = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleChange = (e) => {
		let localData = formData;
		e = e.target;
		if (e.type === 'checkbox') {
			localData[e.name] = e.checked;
		} else {
			localData[e.name] = e.value;
		}
		setFormData(localData);
	}

	async function handleSubmit(e) {
		let data = formData;
		try {
			data.guard_name = 'api';
			if (data.linkable === null || data.linkable === '') {
				data.linkable = false;
			}
			if (data.sublinkable === null || data.sublinkable === '') {
				data.sublinkable = false;
			}
			if (data.has_submenu === null || data.has_submenu === '') {
				data.has_submenu = false;
			}
			if (data.id === null) {
				await api.post('api/permission', data).then(() => {
					snackbar.displayMessage("Cadastro criado com sucesso!", "success");
				}).catch((e) => {
					snackbar.displayMessage("Houve um erro ao cadastrar essa permissão!", "error");
					console.error(e);
				}).finally(() => {
					setIsOpen(false);
					callback();
				});
			} else {
				let url = 'api/permission/' + data.id + '?id=' + data.id;
				await api.put(url, data).then(() => {
					snackbar.displayMessage("Cadastro atualizado com sucesso!", "success");
				}).catch((e) => {
					snackbar.displayMessage("Houve um erro ao atualizar essa permissão!", "error");
					console.error(e);
				}).finally(() => {
					setIsOpen(false);
					callback();
				});
			}
		} catch (error) {
			Swal.fire('Houve um problema ao cadastrar!', '', 'error');
			console.error(error)
		}
	}

	return (
		<div>

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={closeModal}
				style={customStyles}
				transparent>
				<div style={{ position: "relative" }}>
					<label style={{
						opacity: 0.5, cursor: "pointer", position: "absolute",
						right: "10px", fontSize: "25px", top: "-10px"
					}} onClick={() => setIsOpen(false)}>
						x
					</label>
				</div>
				<div style={{ display: "flex", flexDirection: "column" }}>
					<h2 style={{ color: "#442953", fontWeight: "bold", marginTop: 20 }} ref={(_subtitle) => (subtitle = _subtitle)}>
						{formData === null ? "Nova Permissão" : "Alterar Permissão"}
					</h2>
					<Form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
						<label htmlFor="name">Name:</label>
						<input
							type="text"
							id="name"
							name="name"
							defaultValue={formData['name']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>

						<label htmlFor="description">Description:</label>
						<input
							type="text"
							id="description"
							name="description"
							defaultValue={formData['description']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>

						<label htmlFor="link">Link:</label>
						<input
							type="text"
							id="link"
							name="link"
							defaultValue={formData['link']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>

						<label htmlFor="title">Title:</label>
						<input
							type="text"
							id="title"
							name="title"
							defaultValue={formData['title']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>

						<label htmlFor="icon">Icon:</label>
						<input
							type="text"
							id="icon"
							name="icon"
							defaultValue={formData['icon']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>

						<label htmlFor="placeholder">Placeholder:</label>
						<input
							type="text"
							id="placeholder"
							name="placeholder"
							defaultValue={formData['placeholder']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>
						<label htmlFor="placeholder">Posição do Submenu:</label>
						<input
							type="number"
							id="submenu_position"
							name="submenu_position"
							defaultValue={formData['submenu_position']}
							onChange={(e) => handleChange(e)}
							className="form-control"
						/>
						<div className='row'>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="linkable" className='m-0 p-0'>É um link:</label>
								<input
									type="checkbox"
									id="linkable"
									name="linkable"
									className='ml-10px'
									checked={linkable}
									value={linkable}
									defaultValue={formData['linkable']}
									onChange={(e) => { handleChange(e); setLinkable(e.target.checked) }}
								/>
							</div>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="sublinkable" className='m-0 p-0'>É um Submenu:</label>
								<input
									type="checkbox"
									id="sublinkable"
									name="sublinkable"
									className='ml-10px'
									checked={sublinkable}
									value={sublinkable}
									defaultValue={formData['sublinkable']}
									onChange={(e) => { handleChange(e); setSublinkable(e.target.checked) }}
								/>
							</div>
							<div className='col-4 h-100px d-flex align-items-center'>
								<label htmlFor="has_submenu" className='m-0 p-0'>Página tem submenu:</label>
								<input
									type="checkbox"
									id="has_submenu"
									name="has_submenu"
									className='ml-10px'
									checked={has_submenu}
									value={has_submenu}
									defaultValue={formData['has_submenu']}
									onChange={(e) => { handleChange(e); setHasSubmenu(e.target.checked) }}
								/>
							</div>
						</div>
						<button type="submit" className='btn-padrao'>Adicionar Permissão</button>
					</Form>
				</div>
			</Modal>
		</div>
	);
}

export default forwardRef(PermissaoModal);