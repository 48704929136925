import React, { useState } from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { useEffect } from "react";
import api from "../../../services/api";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Responsavel from '../../../layouts/Responsavel';
import { useAuth } from '../../../context/auth';
import { FaCalendarAlt, FaFileMedicalAlt } from 'react-icons/fa';
import { } from "react-icons/io";
import { MdOutlinePendingActions } from 'react-icons/md';
import { IoOpenOutline } from 'react-icons/io5';

export default function ResponsavelDashboard() {

    const { user } = useAuth();

    const [pacientes, setPacientes] = useState([]);
    const [terapeutas, setTerapeutas] = useState([]);
    const [lembretes, setLembretes] = useState('Texto de lembretes.');
    const [editLembrete, setEditLembrete] = useState(false);

    const navigate = useNavigate();

    const getPacientes = (responsavel_id) => {
        try {
            api.get(`api/paciente/list/by/responsavel?id=` + responsavel_id).then(({ data }) => {
                console.log(data);
                setPacientes(data);
            });
        } catch (e) {
            console.error("Erro ao obter lista de pacientes - ", e)
        }
    }

    const getTerapeutas = () => {
        let terapeutasList = [];
        pacientes.forEach((paciente) => {
            api.get(`/api/atendimento?with=terapeutum&filter[paciente_id]=${paciente.id}`).then(({ data }) => {
                data.forEach((atendimento) => {
                    const checkDouble = terapeutasList.find(terapeutum => atendimento.terapeutum.nome === terapeutum)
                    if (atendimento.paciente_id === paciente.id && checkDouble === undefined) {
                        terapeutasList.push(atendimento.terapeutum.nome);
                    };
                })
                setTerapeutas(terapeutasList);
            })
        })

    }

    useEffect(() => {
        getPacientes(user.responsavels[0].id);
        api.get('api/user/' + user.id).then(({ data }) => {
            setLembretes(data.lembretes);
        })
    }, [])

    useEffect(() => {
        getTerapeutas()
    }, [pacientes])

    const labelStyle = {
        padding: '10px 10px 10px 0px'
    }

    const contentStyle = {
        fontFamily: "Nunito, sans-serif",
        fontWeight: "bolder"
    }

    return (
        <Responsavel>
            <div className='container'>
                <div className='col-12 d-grid text-center'>
                    <label style={{ fontWeight: "900", fontSize: 22, color: "#6A3BAF" }}>Bem vindo, {user.responsavels[0].nome}!</label>
                    <label style={{ fontWeight: "700", fontSize: 18, color: "#6A3BAF" }}>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                </div>
                <div className='row flex-sm-col-rev justify-content-center'>
                    <div className="col-sm-12 col-lg-4">
                        <h5 style={{ fontWeight: "700", fontSize: 20, color: "#6A3BAF" }}>
                            Pacientes
                        </h5>
                        <hr className='w-100'></hr>
                        {pacientes.map((item, index) => {
                            return (
                                <div className='m-0 p-0'>
                                    <div className='w-100 d-flex justify-content-between'>
                                        <button className='btn-icone' style={{ marginBottom: '-50px', position: 'relative', zIndex: 10 }} onClick={() => navigate('/dashboard/responsavel/pacientes/perfil/' + item.id)}>
                                            <IoOpenOutline size={26} />
                                        </button>
                                        <button className='btn-icone' style={{ marginBottom: '-50px', position: 'relative', zIndex: 10 }} onClick={() => navigate('/dashboard/responsavel/agenda/paciente/' + item.id)}>
                                            <FaCalendarAlt size={26} />
                                        </button>
                                    </div>
                                    <div className='card col-12 d-flex flex-column p-4 mt-2'>
                                        <div className='aligin-items-center justify-content-center text-left w-100per' style={{ display: "flex-grid" }}>
                                            <div className='d-flex flex-row flex-wrap'>
                                                <div className='w-40per pe-2 pb-2'>
                                                    <img className='perfil-image border' style={{ width: '100%' }} src={item.foto_perfil} alt=''></img>
                                                </div>
                                                <div className='w-60per display-sm-grid display-lg-flex align-items-center'>
                                                    <div className='d-flex align-content-center justify-content-between' style={labelStyle}>
                                                        <span style={contentStyle}>
                                                            Identificador:
                                                        </span>
                                                        <span style={contentStyle} className='text-center'>
                                                            {item.identificador}
                                                        </span>
                                                    </div>
                                                    <div className='d-flex align-content-center justify-content-between' style={labelStyle}>
                                                        <span style={contentStyle}>
                                                            Paciente:
                                                        </span>
                                                        <span style={contentStyle} className='text-center'>
                                                            {item.nome}
                                                        </span>
                                                    </div>
                                                    <div className='d-flex align-content-center justify-content-between' style={labelStyle}>
                                                        <span style={contentStyle}>
                                                            Data de Nasc:
                                                        </span>
                                                        <span style={contentStyle} className='text-center'>
                                                            {(moment(item.nascimento).format('DD/MM/YYYY'))}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={'#collapseThree' + index} aria-expanded="false" aria-controls={"collapseThree" + index}>
                                                            Terapias:
                                                        </button>
                                                    </h2>
                                                    <div id={"collapseThree" + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <ul key={'lista-de-terapias'}>
                                                                {item.terapia_paciente.map((terapia, index) => {
                                                                    return <li key={index}>{terapia.terapia} - {terapia.especialidade} - {terapia.plano}</li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwo" + index} aria-expanded="false" aria-controls={"collapseTwo" + index}>
                                                            Terapeuta(s)
                                                        </button>
                                                    </h2>
                                                    <div id={"collapseTwo" + index} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <ul key={'lista-de-terapeutas'}>
                                                                {terapeutas.map((e, index) => {
                                                                    return <li key={index}>{e}</li>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {false === true &&
                        <>
                            <div className='col-sm-12 col-lg-2 mt-4'></div>
                            <div className='col-sm-12 col-lg-6 d-flex flex-wrap justify-content-lg-end justify-content-sm-center'>
                                <div className='card p-3' style={{ height: 'fit-content' }}>
                                    <div style={{ marginBottom: 15, marginTop: 20, textAlign: "center" }}>
                                        <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>Acesso Rápido:</span>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <button onClick={() => navigate('/dashboard/responsavel/documentos-pendentes/' + user.responsavels[0].id)} className={'btn-acesso-rapido'}>
                                            <MdOutlinePendingActions size={30} />
                                            <span>Documentos Pendentes</span>
                                        </button>
                                        <button onClick={() => navigate('/dashboard/responsavel/documentos-terapeuticos')} className={'btn-acesso-rapido'}>
                                            <FaFileMedicalAlt size={30} />
                                            <span>Documentos Terapêuticos</span>
                                        </button>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <button onClick={() => navigate('/dashboard/responsavel/agenda/paciente/')} className={'btn-acesso-rapido'}>
                                            <FaCalendarAlt size={30} />
                                            <span> Agenda </span>
                                        </button>
                                        <button onClick={() => navigate('/dashboard/responsavel/documentos-assinados')} className={'btn-acesso-rapido'}>
                                            <AiOutlineClockCircle size={30} />
                                            <span>Histórico de Documentos</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </Responsavel>
    )
}
