import React, { useState, useEffect, forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import Modal from 'react-modal';
import Select from '../../../../../components/Select';
import { Form } from '@unform/web';
import Input from "../../../../../components/Input";
import Swal from 'sweetalert2';
import { useSnackbar } from '../../../../../context/snackbar';
import { ClipLoader } from 'react-spinners';
import { AiOutlineDownload } from 'react-icons/ai';
import api from '../../../../../services/api';



const ModalTerapia = ({ modalData, source, callBack }, ref) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "auto",
            maxHeight: "70vh",
            width: '50vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF"
        },
    };

    let subtitle;

    const [modalIsOpen, setIsOpen] = useState(false);
    const [dadosTerapia, setDadosTerapia] = useState(null);

    const [terapiaEscolhida, setTerapiaEscolhida] = useState(null);
    const [salvando, setSalvando] = useState(false);

    const formRef = useRef(null);
    const snackbar = useSnackbar();

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    useImperativeHandle(ref, () => {
        return {
            openModal,
            dados: dadosTerapia
        };
    }, []);

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#6A3BAF';
    }

    const closeModal = useCallback((response) => {
        if (response !== undefined && response !== null) {
            callBack(response, source);
        }
        setIsOpen(false);
    }, []);

    const downloadFile = (id, name) => {
        api.get('api/terapeuta-terapia/download/' + id + '?id=' + id, { responseType: 'blob' })
            .then((response) => {
                download(response, name);
            });
    }

    const download = (response, name) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Use o nome do arquivo do cabeçalho da resposta
        const contentDisposition = response.headers['content-type'];
        let fileName;
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.split('/');
            fileName = 'DIPLOMA - ' + name + '.' + fileNameMatch[1];
        }

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    }

    const handleSubmit = async (data) => {
        setSalvando(true);
        try {
            if (data.registro === null || data.diploma === null) {
                Swal.fire('Por favor preencha todos os campos!', '', 'warning')
            } else {
                const dados = {
                    id: dadosTerapia.id,
                    registro: data.registro,
                    diploma: data.diploma,
                }
                api.patch('api/terapeuta-terapia/' + dados.id + '?&id=' + dados.id, dados)
                    .then((response) => {
                        if (response.status === 200) {
                            Swal.fire('Cadastro alterado com sucesso!', '', 'success');
                            closeModal(response.data);
                        }
                    }).catch((error) => {
                        Swal.fire('Houve um problema ao tentar cadastrar!', '', 'error');
                        console.error(error);
                    });
            }
        } catch (error) {
            console.error(error);
            Swal.fire("Houve um problema ao tentar cadastrar!", '', 'error');
        } finally {
            setSalvando(false);
        }
    }

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (modalData !== null) {
            setDadosTerapia(modalData);
            setTerapiaEscolhida({
                label: modalData.terapium.nome,
                value: modalData.terapium.id
            });
        }
    }, [modalData]);

    function functionThatSubmitsForm() {
        formRef.current.submitForm()
    };

    return (
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal" transparent appElement={document.getElementById('root')}>
            <div style={{ position: "relative" }}>
                <label style={{
                    opacity: 0.5, cursor: "pointer", position: "absolute",
                    right: "10px", fontSize: "25px", top: "-10px"
                }} onClick={() => setIsOpen(false)}>
                    x
                </label>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 className='d-flex justify-center text-center' style={{ color: "#442953", fontWeight: "bold" }} ref={(_subtitle) => (subtitle = _subtitle)}>
                    <p title={dadosTerapia?.id}>Alterar</p>&nbsp;Terapia&nbsp;do&nbsp;Terapeuta
                </h2>
                <Form ref={formRef} onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column", borderWidth: 1, borderTopColor: "red" }}>
                    <div className='row'>
                        <div className='col-md-6'>
                            <label htmlFor='terapia'>Terapia</label>
                            <Input
                                name='terapia_id'
                                value={dadosTerapia?.terapium.terapia}
                                readOnly={'readOnly'}
                                disabled={true}
                            />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='diploma'>Diploma</label>
                            <Input
                                type='text'
                                name='diploma'
                                defaultValue={dadosTerapia?.diploma}
                                placeholder='Digite o diploma'
                            />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor='registro'>Registro</label>
                            <Input
                                type='text'
                                name='registro'
                                defaultValue={dadosTerapia?.registro}
                                placeholder='Digite o registro'
                            />
                        </div>
                        <div className='col-md-6 d-flex justify-center align-center'>
                            <div className='text-center' style={{ lineHeight: '1px' }}>
                                <AiOutlineDownload className="btn-icone" size={50} onClick={(e) => downloadFile(dadosTerapia?.id, dadosTerapia?.terapium.terapia)} />
                                <br></br>
                                <label htmlFor='diploma'>ARQUIVO</label>
                            </div>
                        </div>
                    </div>
                    <button className='btn-padrao mt-30px' type="button" onClick={functionThatSubmitsForm} disabled={salvando}>
                        {salvando
                            ? <ClipLoader color='#ffffff' size={30}></ClipLoader>
                            : "Alterar"
                        }
                    </button>
                </Form>
            </div>
        </Modal>
    );
}

export default forwardRef(ModalTerapia);
