import React, { useEffect, useState } from 'react';
import { Modal } from '../styles';
import { ModalContainer } from '../PacienteAltasModal/styles';
import InfoPacienteModal from '../InfoPacienteModal';
import moment from 'moment'
import api from '../../../services/api';
import { AiFillEye } from 'react-icons/ai';

function PacientePlanoTerapeuticoModal({ onClose, paciente }) {

    const [planosTerapeuticos, setPlanosTerapeuticos] = useState([]);

    const id = 'modal-paciente-plano-terapeutico';

    const getPlanosTerapeuticos = () => {
        if (paciente) {
            api.get(`/api/plano-terapeutico?with=terapium&filter[paciente_id]=${paciente.id_paciente}`).then(({ data }) => {
                setPlanosTerapeuticos(data);
            })
        }
    }

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    };

    useEffect(() => {
        getPlanosTerapeuticos();
    }, [])

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <ModalContainer>
                <div style={{ position: "relative" }}>
                    <label style={{
                        opacity: 0.5, cursor: "pointer", position: "absolute",
                        right: "25px", fontSize: "25px", top: "-10px"
                    }} onClick={() => onClose()}>
                        x
                    </label>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <label style={{ fontWeight: '900', fontSize: '24px', color: '#6A3BAF' }}>Plano Terapeutico</label>
                </div>

                <InfoPacienteModal paciente={paciente} />
                <div className='d-flex flex-wrap overflow-auto px-2'>
                    <div className='col-3 px-1'><span>Terapia:</span></div>
                    <div className='col-3 px-1'><span>Objetivo geral:</span></div>
                    <div className='col-3 px-1'><span>Data hora:</span></div>
                    <div className='col-2 px-1'><span>Status:</span></div>
                    <div className='col-1'></div>
                    {planosTerapeuticos.map((item) => {
                        return (
                            <>
                                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={item.terapium.terapia} className='form-control' /></div>
                                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={item.objetivo_geral} className='form-control' /></div>
                                <div className='col-3 p-1'><input type="text" readOnly={'readOnly'} value={moment(item.created_at).format('YYYY-MM-DD' + ' ' + 'HH:MM')} className='form-control' /></div>
                                <div className='col-2 p-1'><input type="text" readOnly={'readOnly'} value={item.nome} className='form-control' /></div>
                                <div className='col-1 p-1'>
                                    <AiFillEye className='icone-padrao' />
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className='d-flex align-items-center justify-content-center mt-2'>
                    <button onClick={() => onClose()} className='btn-padrao'> Voltar </button>
                </div>
            </ModalContainer>
        </Modal>
    );
}

export default PacientePlanoTerapeuticoModal;
