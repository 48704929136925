import React, { useState, useEffect, useRef } from "react";
import { FilterStyle } from "./styles";
import api from "../../services/api";
import { AiFillCaretDown } from 'react-icons/ai';

export default function FiltroTerapias({ resultRef, callBack, sourceStatus, source }) {

    const [terapiaId, setTerapiaId] = useState([]);
    const [especialidades, setEspecialidades] = useState([]);
    const [especialidadeSelecionado, setEspecialidadeSelecionado] = useState([]);
    const [terapiaSelecionado, setTerapiaSelecionado] = useState([]);
    const [statusSelecionado, setStatusSelecionado] = useState([]);
    const [expand, setExpand] = useState(false);
    const filtroDivRef = useRef();

    const handleFilterAtivo = (ativo, e) => {
        if (e.target.checked) {
            let input = document.getElementById('status_inativo');
            input.checked = false
            resultRef(sourceStatus);
            resultRef((status) =>
                status.filter((item) => item.status.toString().toLowerCase() === (ativo.toString().toLowerCase()))
            );
        } else callBack();
    }

    const handleFilterInativo = (inativo, e) => {
        if (e.target.checked) {
            let input = document.getElementById('status_ativo');
            input.checked = false
            resultRef(sourceStatus);
            resultRef((status) =>
                status.filter((item) => item.status.toString().toLowerCase().includes(inativo.toString().toLowerCase()))
            );
        } else callBack();
    }

    const getTerapias = () => {
        api.get(`api/terapia`).then(({ data }) => {
            setTerapiaId(data);
        });
    };

    const getEspecialidades = () => {
        api.get(`api/especialidade`).then(({ data }) => {
            setEspecialidades(data);
        });
    };

    const updateFilterTerapia = () => {
        let fields = document.getElementsByName('terapia[]');
        let checks = [];
        fields.forEach((x) => {
            if (x.checked) {
                checks.push(x.value);
            }
        });
        return checks;
    }

    const updateFilterEspecialidade = () => {
        let fields = document.getElementsByName('especialidade[]');
        let checks = [];
        fields.forEach((x) => {
            if (x.checked) {
                checks.push(x.value);
            }
        });
        return checks;
    }

    const filterByTerapia = () => {
        let filters = updateFilterTerapia();
        setTerapiaSelecionado(filters);
        if (filters.length > 0) {
        } else {
            callBack();
        }
    }

    const filterByEspecialidade = () => {
        let filters = updateFilterEspecialidade();
        setEspecialidadeSelecionado(filters);
        if (filters.length > 0) {
        } else {
            callBack();
        }
    }

    const filtrar = () => {
        source.filter((e) => {
            let terapia = false;
            let especialidade = false;
            let status = false;

            if (terapiaSelecionado.length > 0) {
                terapia = e?.terapeuta_terapia?.some((e) => {
                    return terapiaSelecionado.some((ter) => {
                        return Number(ter) === Number(e.terapia_id)
                    });
                })
            }
            if (especialidadeSelecionado.length > 0) {
                especialidade = e?.terapeuta_especialidade?.some((e) => {
                    return especialidadeSelecionado.some((esp) => {
                        return Number(esp) === Number(e.especialidade_id);
                    });
                });
            }
            if (statusSelecionado.length > 0) {
                if (statusSelecionado?.findIndex((status) => e.status === status) !== -1) {
                    status = true;
                }
            } else {
                status = true;
            }

            return terapia && especialidade && status;
        })
    }

    /*==FILTRO==*/
    const handleClickOutside = (event) => {
        if (filtroDivRef.current && !filtroDivRef.current.contains(event.target)) {
            setExpand(false);
        }
    }

    const expandFiltro = (e) => {
        if (expand) {
            fechaFiltro();
        } else {
            abreFiltro();
        }
    }
    const abreFiltro = () => {
        setExpand(true);
    }
    const fechaFiltro = () => {
        setExpand(false);
    }

    useEffect(() => {
        if (terapiaSelecionado.length > 0 || especialidadeSelecionado.length > 0 || statusSelecionado.length > 0)
            filtrar()
    }, [terapiaSelecionado, especialidadeSelecionado, statusSelecionado]);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setExpand(false);
            }
        });
        getTerapias();
        getEspecialidades();
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    setExpand(false);
                }
            });
        };
    }, []);

    return (
        <div style={{ width: "100%", }}>
            <div style={FilterStyle} className="w-100" ref={filtroDivRef}>
                <div className="d-flex row" onClick={() => expandFiltro()}>
                    <div className="d-flex col-11">
                        <p id="blank" style={{ marginBottom: -30 }}>
                            {expand
                                ? 'Fechar '
                                : 'Expandir '
                            }
                            Filtro
                        </p>
                    </div>
                    <div className="col-1">
                        <AiFillCaretDown className="btn-icone" color="white" size={25} />
                    </div>
                </div>
                <div className={expand ? "div-expand-over" : 'some'} style={{ marginTop: 30, marginLeft: -10 }}>
                    <div>
                        <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Selecionar Terapias:</label>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {terapiaId.map((terapia, index) => (
                                <div key={index} className="d-inline-flex flex-nowrap">
                                    <input
                                        id={'ter' + terapia.terapia}
                                        type="checkbox"
                                        name="terapia[]"
                                        value={terapia.id}
                                        style={{ flexDirection: "column" }}
                                        onClick={e => filterByTerapia(e.target.value)}
                                    />
                                    <label htmlFor={'ter' + terapia.terapia} style={{ flexDirection: "column", margin: 5, }}>
                                        {terapia.terapia}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Selecionar Especialidades:</label>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {especialidades.map((especialidade, index) => (
                                <div key={index} className="d-inline-flex flex-nowrap">
                                    <input
                                        id={'esp' + especialidade.especialidade}
                                        type="checkbox"
                                        name="especialidade[]"
                                        value={especialidade.id}
                                        style={{ flexDirection: "column" }}
                                        onClick={(e) => filterByEspecialidade(e.target.value)}
                                    />
                                    <label htmlFor={'esp' + especialidade.especialidade} style={{ flexDirection: "column", margin: 5 }}>
                                        {especialidade.especialidade}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <label style={{ marginBottom: 5, fontWeight: "bolder", borderBottom: '0.5px solid white' }}>Filtrar por Status:</label>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            <div className="d-flex flex-nowrap">
                                <input
                                    id={'status_ativo'}
                                    name="ativo"
                                    type="checkbox"
                                    style={{ flexDirection: "column" }}
                                    value={'Ativo'}
                                    onClick={e => handleFilterAtivo('Ativo', e)}
                                />
                                <label htmlFor={'status_ativo'} style={{ flexDirection: "column", margin: 5 }}>
                                    Ativo
                                </label>
                            </div>
                            <div className="d-flex flex-nowrap">
                                <input
                                    id={'status_inativo'}
                                    name="inativo"
                                    type="checkbox"
                                    value={'Inativo'}
                                    style={{ flexDirection: "column" }}
                                    onClick={e => handleFilterInativo('Inativo', e)}
                                />
                                <label htmlFor={'status_inativo'} style={{ flexDirection: "column", margin: 5 }}>
                                    Inativo
                                </label>
                            </div>
                            <div className="d-flex flex-nowrap">
                                <input
                                    id={'status_bloqueado'}
                                    name="inativo"
                                    type="checkbox"
                                    value={'Inativo'}
                                    style={{ flexDirection: "column" }}
                                    onClick={e => handleFilterInativo('Bloqueado', e)}
                                />
                                <label htmlFor={'status_bloqueado'} style={{ flexDirection: "column", margin: 5 }}>
                                    Bloqueado
                                </label>
                            </div>
                            <div className="d-flex flex-nowrap">
                                <input
                                    id={'status_pendente'}
                                    name="inativo"
                                    type="checkbox"
                                    value={'Pendente'}
                                    style={{ flexDirection: "column" }}
                                    onClick={e => handleFilterInativo('Pendente', e)}
                                />
                                <label htmlFor={'status_pendente'} style={{ flexDirection: "column", margin: 5 }}>
                                    Pendente
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
