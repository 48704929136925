import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import Modal from 'react-modal';
import api from '../../../../services/api';
import moment from 'moment';
import userGeneric from '../../../../assets/img_avatar.png';
import DetalhesDoAgendamento from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/Modal/DetalhesDoAgendamento';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../../context/auth';
import Carregamento from '../../../Carregamento';
import { AiOutlineClose } from 'react-icons/ai';
import { listOfStyles } from '../../../../pages/RecepcaoDashboard/Agenda/Terapeuta/styles';
import { getLegendaAgendamento } from '../../../../helpers/agenda.helper';
const AgendaModal = forwardRef(({ id = 'agendaModal', outSideSet, mode = 0 }, ref) => {
    /*===============================*/
    //#region VARIÁVEIS==============*/
    /*===============================*/
    /*===VARS TELA===*/
    const title = `Agenda do Terapeuta`;
    const [isOpen, setIsOpen] = useState(false);
    const user = useAuth();
    /*===VARS VALIDACAO===*/
    const [isModalDetalhesDoAtendimentoVisible, setIsModalDetalhesDoAtendimentoVisible] = useState(false);
    const [houveExclusaoAgendamento, setHouveExclusaoAgendamento] = useState(false);
    const [loading, setloading] = useState(false);

    /*===VARS AGENDA===*/
    const [baseWidth, baseHeight] = [150, 90];
    const styles = listOfStyles(baseWidth, baseHeight);

    const [terapeuta, setTerapeuta] = useState(null);
    const [terapiasTerapeuta, setTerapiasTerapeuta] = useState([]);
    const [horarios, setHorarios] = useState([]);
    const [atendimentos, setAtendimentos] = useState([]);
    const [atendimentoId, setAtendimentoId] = useState();
    const [selectedTerapeutaId, setSelectedTerapeutaId] = useState();
    const diaSemanaIndex = moment().weekday();
    const diasDaSemanaArray = [
        {
            value: 1,
            label: 'Segunda'
        },
        {
            value: 2,
            label: 'Terça'
        },
        {
            value: 3,
            label: 'Quarta'
        },
        {
            value: 4,
            label: 'Quinta'
        },
        {
            value: 5,
            label: 'Sexta'
        },
    ];
    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 15
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: "70vh",
            width: '80vw',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#FFFFFF",
            zIndex: 15
        },
    };
    //#endregion
    /*===============================*/
    //#region FUNÇÕES DA MODAL==============*/
    /*===============================*/
    const openModal = useCallback((terapeuta_id) => {
        setSelectedTerapeutaId(terapeuta_id);
        setIsOpen(true);
        outSideSet(true);
    }, []);
    const closeModal = useCallback(() => {
        setSelectedTerapeutaId(null);
        setIsOpen(false);
        outSideSet(false);
    }, []);
    function afterOpenModal() {

    }
    useImperativeHandle(ref, () => ({ openModal, closeModal }));
    //#endregion
    /*===============================*/
    //#region FUNCOEs================*/
    /*===============================*/
    const getTerapeuta = () => {
        let terapeuta_id = selectedTerapeutaId;
        if (terapeuta_id !== undefined && terapeuta_id !== null) {
            api.get(`api/terapeuta/${terapeuta_id}?with=terapeuta_especialidades.especialidade,terapeuta_terapia.terapium`)
                .then(async ({ data }) => {
                    setTerapeuta(data);
                    setTerapiasTerapeuta(data.terapeuta_terapia);
                });
        }
    }
    const getAgendaHorarios = () => {
        api.get('/api/horario-atendimento?filter[dia_semana]=1').then(({ data }) => {
            setHorarios(data);
        })
    }
    const getAgendaDia = () => {
        let terapeuta_id = selectedTerapeutaId;
        const dataSegundaFeira = moment().isoWeekday(1).format('YYYY-MM-DD');
        const dataSextaFeira = moment().isoWeekday(5).format('YYYY-MM-DD');
        api.get(`/api/agendamento?filter[terapeuta_id]=${terapeuta_id}&with=estacao,estacao.sala`).then(({ data }) => {
            data = data.map(atendimento => {
                atendimento.dia_semana_index = moment(atendimento.data_atendimento_inicial).weekday();
                let horaInicial = atendimento.data_atendimento_inicial.split('T')[1];
                atendimento.hora_inicial = moment(horaInicial, 'HH:mm:ss').format('HH:mm');
                return atendimento;
            });
            setAtendimentos(data);
        })
    }
    const modalDetalhesDoAtendimento = (detalhes) => {
        setAtendimentoId(detalhes.id);
        setIsModalDetalhesDoAtendimentoVisible(true);
    }
    //#endregion
    useEffect(async () => {
        await getAgendaHorarios();
    }, []);
    useEffect(async () => {
        if (selectedTerapeutaId !== undefined && selectedTerapeutaId !== null) {
            const carregaTudo = () => {
                getTerapeuta();
                getAgendaDia();
            }
            setloading(true);
            setTerapeuta(null)
            setTerapiasTerapeuta([])
            setAtendimentos([]);
            await carregaTudo();
            setloading(false);
        }
    }, [selectedTerapeutaId]);
    return (
        <>
            {loading && <Carregamento></Carregamento>}
            <Modal style={customStyles} id={id} isOpen={isOpen} onRequestClose={closeModal} afterOpenModal={afterOpenModal} shouldCloseOnOverlayClick={true} appElement={document.getElementById('root')} transparent>
                <div className='div-titulo d-flex mb-20px pb-15px justify-content-between'>
                    <label></label>
                    <label title={selectedTerapeutaId}>{title}</label>
                    <label>
                        <button className='btn-icone' onClick={closeModal}>
                            <AiOutlineClose color='red' size={22} />
                        </button>
                    </label>
                </div>
                <div className='d-flex justify-arround'>
                    <table>
                        <thead>
                            <tr>
                                <td align={'center'} className='tr1'>Horário:</td>
                                {diasDaSemanaArray.map(dia => (
                                    <td key={dia.value} align={'center'} className={diaSemanaIndex === dia.value ? 'tr2' : 'tr1'}>{dia.label}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {horarios.map((horario, horarioIdx) => {
                                const horasExtenso = moment(horario.horario.split('T')[1], 'HH:mm:ss').format('HH:mm');
                                return (
                                    <tr key={`linha-horario-${horarioIdx}`}>
                                        <td align={'center'} style={styles.trHora}>{horasExtenso}</td>
                                        {diasDaSemanaArray.map(dia => {
                                            let atendimentoEncontrado = atendimentos.filter(atendimento => atendimento.reserva == 0 && (atendimento.dia_semana_index === dia.value && atendimento.hora_inicial === horasExtenso));
                                            if (atendimentoEncontrado.length > 0) {
                                                atendimentoEncontrado = atendimentoEncontrado[0];
                                                let legenda = getLegendaAgendamento(atendimentoEncontrado, atendimentos);
                                                let div2style = {
                                                    ...styles[legenda],
                                                    ...styles.trOcupado
                                                };
                                                return (
                                                    <td onClick={() => modalDetalhesDoAtendimento(atendimentoEncontrado)}
                                                        key={`linha-dia-${dia.value}-horario-${horarioIdx}`}
                                                        className='trOcupado w-150px h-90px cursor-zoom'
                                                        style={styles.trVazio}>
                                                        <div style={div2style}>
                                                            <div className='row d-flex h-100per'>
                                                                <span className='d-flex justify-center flex-row'>
                                                                    Sala: {atendimentoEncontrado.estacao.sala.sala}
                                                                    <br></br>
                                                                    Estação: {atendimentoEncontrado.estacao.estacao}
                                                                    <br></br>
                                                                    {atendimentoEncontrado.paciente.identificador}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td className='trVazio w-150px h-90px' key={`linha-dia-${dia.value}-horario-${horarioIdx}`}></td>
                                            );
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className='d-flex flex-column align-content-start max-w-300px min-w-300px'>
                        <div className='d-flex flex-column align-items-center bg-white w-100' style={{ boxShadow: "0 0 10px 0px silver", borderRadius: 10, height: 'auto' }}>
                            <div className='d-flex flex-column'>
                                <div className='d-flex justify-content-center'>
                                    <img src={
                                        (terapeuta === null)
                                            ? userGeneric
                                            : (terapeuta.foto_perfil_full === null || terapeuta.foto_perfil_full === '')
                                                ? userGeneric
                                                : terapeuta.foto_perfil_full
                                    } height={75} width={75} className='perfil-image rounded-circle mb-2 mt-2' />
                                </div>
                                <div className='d-flex flex-column m-2'>
                                    <span style={{}}>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", textAlign: 'center' }}>Nome:</label> {terapeuta?.nome}
                                    </span>
                                    <span>
                                        <label style={{ fontFamily: "Nunito, sans-serif", color: "#707070", fontWeight: "bold", }}>
                                            Terapias:
                                        </label>
                                        {terapiasTerapeuta &&
                                            <ul>
                                                {terapiasTerapeuta.map((value, index) => {
                                                    return <li key={index}>{value.terapium.terapia}</li>
                                                })}
                                            </ul>
                                        }
                                    </span>
                                </div>
                            </div>
                            <button className='btn-padrao mb-2' onClick={() => { Navigate(`/dashboard/${user.tipo_user}/terapeutas/perfil/${id}`); }} >Cadastro</button>
                        </div>
                    </div>
                </div>
            </Modal>
            {isModalDetalhesDoAtendimentoVisible ? <DetalhesDoAgendamento
                agendamentoId={atendimentoId}
                setHouveExclusaoAgendamento={() => setHouveExclusaoAgendamento(true)}
                onClose={() => setIsModalDetalhesDoAtendimentoVisible(false)}
            /> : (<></>)}
        </>
    )
});
export default AgendaModal;