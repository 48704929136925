import React from "react";
import Button from "../Button";
import { Container } from './styles';
import { Link } from 'react-router-dom';

export default function AcessoCards({ title }) {
    return (
        <Container>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <label style={{ color: "#4906AB", fontWeight: "bold", fontSize: '18px', fontFamily: "Nunito, sans-serif" }}>{title}</label>
            </div>
            <div style={{ paddingTop: '16px', display: "flex", flexDirection: "column" }}>
                <Link to="/dashboard/recepcao/guiasavencer" style={{textDecoration: "none"}}>
                    <Button title={"Guias a Verificar"} />
                </Link>
            </div>
        </Container>
    )
}
