
import styled, { css } from 'styled-components';


import {
  AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin
} from "react-icons/ai";


const iconCSS3 = css`
  width: 32px;
  height: 32px;
  margin-left: 28px;
  margin-top: 16px;
  cursor: pointer; 
`;

export const YoutubeIcon = styled(AiOutlineYoutube)
  `
  ${iconCSS3}
`;

export const FacebookIcon = styled(AiOutlineFacebook)
  `
  ${iconCSS3}
`;

export const InstagramIcon = styled(AiOutlineInstagram)
  `
  ${iconCSS3}
`;

export const LinkedinIcon = styled(AiOutlineLinkedin)
  `
  ${iconCSS3}
`;

export const Container = styled.div`
  display: flex; 
  justify-content: center;  
  height: 100vh;
  width: 100vw;
  background-color: #412651;
  align-items: center;

  // box-sizing:border-box;
  // padding-top: 150px;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // display: flex;
  // 
   
  form{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 590px;
    width: 590px;
    @media (max-width: 1024px) {   
      height: 350px;
      width: 350px;
    }
  }

`;

export const ContainerTop = styled.section`
  display: block;
  background: #FFFFFF;
  height:100vh;
  width:100%; 
  box-sizing:border-box;
  img{
    max-height: 290px;
    max-width: 290px;
  }
`;

export const ContainerTopWrap = styled.div`
  display: grid;
  height: 100%;
	grid-template-columns: 2.5fr 6fr; 
  grid-template-areas:
		"left right"
	;
  
  @media (max-width: 1024px) {   
    grid-template-columns: 8fr; 
    grid-template-areas:
      "left"
    ;
  }
`;

export const ContainerTopLeft = styled.div`
  background-color: #572565;
  grid-area: 'left';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  height: 99%; // foi ncessario ;/

  > img {
    padding-bottom: 70px;
    align-self: center;
  }
  
  @media (max-width: 1024px) {   
    padding: 30px;
    > img {
      padding-bottom: 30px;
      align-self: center;
    }
  }
  
  .create-account{
    width: 100%;
    margin-top: 14px;
    height: 56px;
    border-radius: 8px;
    font-weight: 500;
    background: #4081b1;
    color: #FFFFFF;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: 0;

    transition: filter 0.2s;

    img {
      margin-right: 8px;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
  .create-google {
    width: 100%;
    margin-top: 34px;
    height: 56px;
    border-radius: 8px;
    font-weight: 500;
    background: #FFF;
    color: #4081b1;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: 0;

    transition: filter 0.2s;

    img {
      margin-right: 8px;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
  .create-facebook {
    width: 100%;
    margin-top: 15px;
    height: 56px;
    border-radius: 8px;
    font-weight: 500;
    background: #4285f4;
    color: #FFF;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    border: 0;

    transition: filter 0.2s;

    img {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
  .separator {
    font-size: 16px;
    color: #FFFFFF;

    margin: 28px 0;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      flex: 1;
      height: 1px;
      background: #FFFFFF;
      margin-right: 16px;
    }

    &::after {
      content: '';
      flex: 1;
      height: 1px;
      background: #FFFFFF;
      margin-left: 16px;
    }
  }
  p {
    align-self: center;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-top: 16px;
    a {
      color: #4081b1;
    }
  }
`;
export const ContainerTopRigth = styled.div`
  grid-area: 'right';
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1180px) {   
    display: none;
  }
  
  label{
    font-size: 34px;
    font-family: 'Montserrat';
    font-weight: 600;
    width: 700px; 
    padding-bottom: 10px; 
    color: #572565;
  }
   
  .two-colors{
    font-size: 34px;
    font-family: 'Montserrat';
    font-weight: bold;
    line-height: 40px; 
    width: 700px;
    color: #4081b1;
  }

  .lottie{
    height: 800px;
    width: 800px;
    svg{
      height: 100%;
      width: 100%;
      max-height: 800px;
      max-width: 800px;
    }
  }
`;

export const ContainerBottom = styled.section`
  display: block;
  background: #f6f6f6;
  height:100vh;
  width:100%; 
  box-sizing:border-box;
`;

export const ContainerBottomWrap = styled.div`
  display: grid;
  /* height: 100%; */
	grid-template-columns: 2.5fr 6fr; 
	grid-template-rows: auto 150px;
  grid-template-areas:
		"left2 right2"
    "footer2 footer2"
	;
  
  @media (max-width: 1024px) {   
    grid-template-columns: 8fr; 
    grid-template-areas:
      "left2"
    ;
  }
`;

export const ContainerBottomLeft = styled.div`
  /* background-color: #572565; */
  grid-area: 'left';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px;
  /* height: 99%; // foi ncessario ;/ */
  
  @media (max-width: 1024px) {   
    padding: 30px;
    height: 100%; // foi ncessario ;/
  } 
  .container{
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .title{ 
    font-size: 22px;
    font-weight: bold;
    font-family: 'Montserrat'; 
    padding-bottom: 10px;
  }
  .subTitle{
    font-size: 18px;
    font-weight: bold;
    font-family: 'Montserrat'; 
  }
`;

export const ContainerBottomRigth = styled.div`
grid-area: 'right2';
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1180px) {   
    display: none;
  }
`;

export const ContainerFooterRigth = styled.div`
  grid-area: 'footer2';
  display: flex;
  flex-direction: row;
  align-items: center;  

  img{
    height: 50px;
    width: 190px;
  }
  
  label{
    padding-right: 24px;
    font-size: 15px;
  }
`;




export const SectionBottomFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  /* justify-content: space-between;*/
  
  label{
    padding-right: 24px;
    font-size: 15px;
  }
  div{

  }
`;


export const SectionBottomBody = styled.div`
  display: flex;
  justify-content: row; 
  padding: 10px;
  align-items: center;
  .container{
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .title{ 
    font-size: 22px;
    font-weight: bold;
    font-family: 'Montserrat'; 
    padding-bottom: 10px;
  }
  .subTitle{
    /* color: red; */
    font-size: 18px;
    font-weight: bold;
    font-family: 'Montserrat'; 
  }
`;

export const SectionBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;


export const SectionBottom = styled.section` 
  display: flex;
  background: #F6F6F6;
  /* background-image: url('https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'); */
  
  /* background-position: center; Center the image */
  /* background-repeat: no-repeat; Do not repeat the image */
  /* background-size: cover; Resize the background image to cover the entire container */

  height:100vh;
  width:100%;
  padding: 10px;
  box-sizing:border-box;
  img{
    max-height: 29px;
    max-width: 290px;
  }
  label{
    font-size: 1.2rem;
    color: black;
    font-weight: 600
  }
  @media (max-width: 1180px) {   
    video{
      display: none;
    }
  }
`;