import React, { useState } from 'react';
import user from '../../../assets/img_avatar.png';
import { AiFillFileExcel, AiOutlineUnorderedList } from 'react-icons/ai';
import { useEffect } from "react";
import api from "../../../services/api";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import Coordenacao from '../../../layouts/Coordenacao'
import { ButtonDcoumentStyle } from './styles';
import { IoDocumentsSharp} from 'react-icons/io5';
import RelatorioDeFaltas from '../../../components/Modais/RelatioriosEstrategicosModal/RelatorioDeFaltas';
import { FaBox, FaUserAlt, FaUserMd } from 'react-icons/fa';

export default function CoordenacaoDashboard() {
    const [especialidades, setEspecialidades] = useState([]);
    const [terapias, setTerapias] = useState([]);
    const [pacientes, setPacientes] = useState([]);
    const [terapeuta, setTerapeuta] = useState({});
    const [modalFaltas, setModalFaltas] = useState(false);

    const navigate = useNavigate();

    const handleOpenModalFaltas = () => {
        setModalFaltas(true);
    }
    useEffect(() => {
        let t = JSON.parse(sessionStorage.getItem('@Neuro:user'));
        setTerapeuta(t);
        {
            if (especialidades.length < 1) getEspecialidades()
            if (terapias.length < 1) getTerapias()
            if (pacientes.length < 1) getPacientes()
        }

    }, [])

    const getEspecialidades = () => {
        try {
            api.get('api/especialidade').then(({ data }) => {
                let especialidadeOptions = [];

                data.forEach((especialidade) => {
                    especialidadeOptions.push({ value: especialidade.id, label: especialidade.especialidade });
                });

                setEspecialidades(especialidadeOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de especialides - ", e)
        }
    }

    const getTerapias = () => {
        try {
            api.get('api/terapia').then(({ data }) => {
                let terapiasOptions = [];

                data.forEach((terapia) => {
                    terapiasOptions.push({ value: terapia.id, label: terapia.terapia });
                });

                setTerapias(terapiasOptions);
            });
        } catch (e) {
            console.error("Erro ao obter lista de terapias - ", e)
        }
    }

    const getPacientes = () => {
        try {
            api.get(`api/pacientes`).then(({ data }) => {
                setPacientes(data);
            });
        } catch (e) {
            console.error("Erro ao obter lista de pacientes - ", e)
        }
    }

    return (
        <Coordenacao>
            <div className='col' style={{ padding: "4rem 19rem" }}>
                <div className='row'>
                    <div style={{ display: "grid", alignItems: "center", justifyContent: "center", textAlign: "center", margin: "auto" }}>
                        <label style={{ fontWeight: "900", fontSize: 24, color: "#6A3BAF" }}>Bem vindo, {terapeuta.name} !</label>
                        <label style={{ fontWeight: "700", fontSize: 18, color: "#6A3BAF" }}>Hoje é dia {moment.utc().format('DD, MMMM YYYY')}</label>
                    </div>
                </div>
                <div className='row pt-20px gap-4'>
                    <div className='col-8 row d-flex bg-branco flex-wrap'
                         style={{ padding: "20px 40px", borderRadius: 20, boxShadow: "0 0 10px 0px silver" }}>
                        <div className='col-12 m-auto text-center' style={{ marginBottom: 15, marginTop: 20, textAlign: "center" }}>
                            <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>
                                Conteúdo a definir
                            </span>
                        </div>
                        <div className='col-2 justify-center align-center d-grid' style={{ wordWrap: "break-word" }}>
                            <img alt={''} style={{ borderRadius: '50%' }} src={user} height={140}></img>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button className='btn-padrao'> Abrir Perfil </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-4' style={{ padding: "20px 40px", backgroundColor: "white", borderRadius: 20, boxShadow: "0 0 10px 0px silver" }}>
                        <div>
                            <div style={{ marginBottom: 15, marginTop: 20, textAlign: "center" }}>
                                <span style={{ fontWeight: "900", fontSize: "14", color: "#6A3BAF" }}>Acesso Rápido:</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button onClick={() => navigate('/dashboard/coordenacao/terapeutas')}
                                    style={ButtonDcoumentStyle}>
                                    <FaUserMd size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Terapeutas</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/coordenacao/pacientes')}
                                    style={ButtonDcoumentStyle}>
                                    <FaUserAlt size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Pacientes</span>
                                </button>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <button onClick={() => navigate('/dashboard/coordenacao/solicitacao-materiais')}
                                    style={ButtonDcoumentStyle}>
                                    <FaBox size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer' }}>Solicitação de Materiais</span>
                                </button>
                                <button onClick={() => navigate('/dashboard/coordenacao/documentos-pendentes')}
                                    style={ButtonDcoumentStyle}>
                                    <IoDocumentsSharp size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Documentos</span>
                                </button>
                                <button onClick={() => handleOpenModalFaltas()}
                                    style={ButtonDcoumentStyle}>
                                    <AiFillFileExcel size={25} />
                                    <span style={{ fontSize: 14, cursor: 'pointer', }}>Relatório de Faltas</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalFaltas && <RelatorioDeFaltas onClose={() => setModalFaltas(false)} />}

        </Coordenacao>
    )
}
