import { useState } from "react";
import api from "services/api";
const useSalaData = () => {
    const [salaOptions, setSalaOptions] = useState([]);
    const [salaEstacaoOptions, setSalaEstacaoOptions] = useState([]);

    const fetchSalaData = async () => {
        try {
            const { data } = await api.get('api/sala');
            const options = data.map((sala, index) => ({
                label: sala.sala,
                value: sala.id,
                key: index
            }))
            setSalaOptions(options);
        } catch (error) {
            console.error(error);
        }
    }

    const fetchSalaEstacaoData = async () => {
        const response = await api.get(`api/sala?with=estacaos`);
        let selectEstacao = [];
        response.data.forEach(s => {
            return s.estacaos.forEach(e => (
                selectEstacao.push({
                    'value': e.id,
                    'label': s.sala + ' - ' + e.estacao,
                })));
        });
        setSalaEstacaoOptions(selectEstacao);
    }

    return { salaOptions, salaEstacaoOptions, fetchSalaData, fetchSalaEstacaoData };
}

export default useSalaData;