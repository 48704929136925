import React, { useEffect, useState } from "react";
import { Modal, Container } from './styles'
import { Form } from '@unform/web'

export default function LegendaTerapeuta({
    id = 'modalLegenda',
    onClose = () => { }
}) {

    const data = [
        {
            color: "#35D058",
            texto: "Presencial",
            icon: null
        },
        {
            color: "#3B9E52",
            texto: "Teleatendimento",
            icon: null
        },
        {
            color: "#075C1A",
            texto: "Domiciliar",
            icon: null
        },
        {
            color: "#E98232",
            texto: "Conflito de Horários Terapeuta",
            icon: null
        },
        {
            color: "#F94181",
            texto: "Conflito de Horários Paciente",
            icon: null
        },
        {
            color: "#EED10F",
            texto: "Avaliação",
            icon: null
        },
        {
            color: "#C901E8",
            texto: "Reposição",
            icon: null
        },
        {
            color: "#2781DA",
            texto: "Reserva",
            icon: null
        },
    ]
    useEffect(() => {

    }, []);

    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
    }

    return (
        <Modal id={id} onClick={handleOutsideClick}>
            <Container>
                <div style={{position: "relative"}}>
                    <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
                      right: "10px", fontSize: "25px", top: "-10px" }} onClick={() => onClose()}>
                      x
                    </label>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "left" }}>
                    <label style={{ fontWeight: "900", fontSize: "18", color: "#6A3BAF" }}>Legenda:</label>
                </div>
                {
                    data.map((legendas) =>
                        <div style={{
                            display: "flex", alignItems: "center", justifyContent: "left",
                            padding: "10px"
                        }}>
                            <div style={{
                                backgroundColor: legendas.color,
                                height: "24px", width: "36px", borderRadius: "6px", 
                                marginRight: "10px"
                            }} />
                            <label>{legendas.texto}</label>
                        </div>
                    )
                }

            </Container>
        </Modal>
    )
}
