import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;`;

export const ContainerSub = styled.div`
    display: grid;
    grid-template-areas:
        'name'
        'menu'
        'footer';
    // grid-gap: 10px;
    // background-color: #2196F3;
`;
export const ContainerName = styled.div` 
    grid-area: name;
    padding: 24px 0px;
`;
export const ContainerCards = styled.div`
    grid-area: menu;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 80px;
`;
export const ContainerSolicitacoes = styled.div`
    // background: black;
    grid-area: footer;
    padding: 24px 0px;
`;

export const FormField = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    gap: 10px;
    flex-direction: column;
`;

export const table = {
    width: '100%'
}

export const title = {
    color: "#4906AB",
    fontSize: "2.0rem",
    fontFamily: "Nunito, sans-serif",
    fontWeight: "800",
    marginBottom: '2rem'
}

export const tr = {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: '0.2rem'
}
export const col = [
    { width: '25% !important', display: 'inline-flex' },
    { width: '25% !important', display: 'inline-flex' },
    { width: '25% !important', display: 'inline-flex' },
    { width: '25% !important', display: 'inline-flex' }
]

export const tbody = {
    height: '100%',
}

export const thead = {
    width: '100%',
}