/**
 * Calcula a diferença em dias entre duas datas
 * 
 * @param {string} date1 - A primeira data no formato 'YYYY-MM-DD'
 * @param {string} date2 - A segunda data no formato 'YYYY-MM-DD'
 * @param {string} tipo - Em que formato o resultado será retornado (por exemplo, 'dias')
 * @returns {number} - A diferença em dias entre as duas datas
 */
const diferencaEntreDatas = (date1, date2, tipo = 'dias') => {
    // Converter as strings para objetos Date ajustando para meia-noite no fuso horário local
    const d1 = new Date(`${date1}T00:00:00`);
    const d2 = new Date(`${date2}T00:00:00`);

    // Calcular a diferença em milissegundos
    const diffTime = Math.abs(d2 - d1);

    let diff;
    switch (tipo.toLowerCase()) {
        case 'dias':
        case 'days':
        case 'dia':
        case 'day':
            // Converter a diferença para dias
            diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            break;
        default:
            diff = diffTime;
            break;
    }

    return Number(diff);
}
/**
 * Função que ira comprar a primeira com a segunda data, para definir, se a primeira vem depois da segunda.
 * @param {Date} data1 Data um a ser comparada.
 * @param {Date} data2 Data dois a ser comparada.
 * @returns {bool} Retorna se a primeira data é depois da segunda.
 */
const depois = (data1, data2) => {
    return data1 > data2;
}
/**
 * Função que ira comprar a primeira com a segunda data, para definir, se a primeira vem antes que a segunda.
 * @param {Date} data1 Data um a ser comparada.
 * @param {Date} data2 Data dois a ser comparada.
 * @returns {bool} Retorna se a primeira data é antes que a segunda.
 */
const antes = (data1, data2) => {
    return data1 < data2;
}
/**
 * Retorna a data atual no formato especificado.
 * 
 * @param {string} [formato='YYYY-MM-DD'] - O formato desejado para a data. 
 *                                          Opções válidas: 'YYYY-MM-DD', 'DD-MM-YYYY', 'MM-DD-YYYY', 'DD/MM/YYYY'.
 *                                          Se um formato inválido for passado, a função retorna o objeto Date completo.
 * @returns {string|Date} - A data atual formatada de acordo com o formato especificado, ou o objeto Date se o formato for inválido.
 */
const hoje = (formato = 'YYYY-MM-DD') => {
    const dataAtual = new Date();

    const ano = dataAtual.getFullYear();
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Meses são indexados a partir de 0
    const dia = String(dataAtual.getDate()).padStart(2, '0');

    let dataFormatada;

    switch (formato) {
        case 'YYYY-MM-DD':
            dataFormatada = `${ano}-${mes}-${dia}`;
            break;
        case 'DD-MM-YYYY':
            dataFormatada = `${dia}-${mes}-${ano}`;
            break;
        case 'DD-MM-YYYY':
            dataFormatada = `${dia}-${mes}-${ano}`;
            break;
        case 'DD/MM/YYYY':
            dataFormatada = `${dia}/${mes}/${ano}`;
            break;
        default:
            return dataAtual;
    }

    return dataFormatada;
};

export { diferencaEntreDatas, antes, depois, hoje };