import React, { useMemo, useRef } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Swal from "sweetalert2";
import FuncionarioService from "../../../../services/FuncionarioService";
import { TableDefaultPagination } from "../../../../components/TableDefaultPagination";
import { BsEye } from "react-icons/bs";
import DadosBasicosModal from "../../../../components/Modais/FuncionariosModal/DadosBasicosModal";

export function TableFuncionarios({ source, callback, openModal, setFuncionarioId, permissoes }) {
  const dadosBasicosRef = useRef();

  const handleEditModal = (data) => {
    setFuncionarioId(data);
    openModal(true);
  }
  const handleDadosBasicosModal = (id) => {
    if(dadosBasicosRef.current){
      dadosBasicosRef.current.openModal(id);
    }
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Deseja realmente remover este funcionário ?',
      showDenyButton: true,
      confirmButtonText: 'Remover',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        FuncionarioService.destroy(id)
          .then(() => {
            Swal.fire('Registro removido com sucesso!', '', 'success');
            callback();
          })
          .catch(() => Swal.fire('Houve um erro ao tentar remover este registro', '', 'error'))
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Nome:',
        accessor: 'name',
      },
      {
        Header: 'E-mail:',
        accessor: 'email',
      },
      {
        Header: 'Tipo:',
        accessor: 'tipo_user',
      },
      {
        Header: 'Telefone:',
        accessor: 'telefone',
      },
      {
        Header: 'Status:',
        accessor: 'status',
      },
      {
        Header: "Ações",
        accessor: "acoes",
        Cell: ({ cell }) => (
          (<div className="d-flex align-center justify-arround" style={{ backgroundColor: "transparent" }}>
            {permissoes['edição'] &&
              <AiOutlineEdit title={cell.row.original.id} className="pointer" onClick={() => handleEditModal(cell.row.original.id)} color="#7340BD" size={22} />}
            {permissoes['dados_básicos'] &&
              <BsEye title={cell.row.original.id} className="pointer" onClick={() => handleDadosBasicosModal(cell.row.original.id)} color="#7340BD" size={22} />}
            {permissoes['exclusão'] &&
              <AiOutlineDelete title={cell.row.original.id} className="pointer" onClick={() => handleDelete(cell.row.original.id)} color="#7340BD" size={22} />}
          </div>
          )
        )
      }
    ],
    []
  );

  return (
    <>
      <TableDefaultPagination columns={columns} source={source} />
      <DadosBasicosModal ref={dadosBasicosRef}></DadosBasicosModal>
    </>
  )
}