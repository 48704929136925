import React, { useEffect, useState } from 'react';
import Container from '../../../../components/Container';
import {
    DivListaDocumentos,
    DivPrincipal,
    ItemLista,
    NomeDocumento,
    ParagrafoInformativoNumeroDocumentos,
} from './styles';
import { useAuth } from '../../../../context/auth';
import api from '../../../../services/api';
import Responsavel from '../../../../layouts/Responsavel';
import PlanoTerapeuticoModal from '../../../../components/Modais/PlanoTerapeuticoModal';
import { ParagrafoSaudacao } from '../../../DocumentoAssinatura/styles';

const DocumentosTerapeuticos = () => {

    const { user } = useAuth();
    const [openModalPlanoTerapeutico, setOpenModalPlanoTerapeutico] = useState(false);
    const [planoTerapeuticoModal, setPlanoTerapeuticoModal] = useState();
    const [planosTerapeuticos, setPlanosTerapeuticos] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [numeroDocumentosPendentes, setNumeroDocumentosPendentes] = useState('00');

    const fetchData = () => {
        api.get(`/api/plano-terapeutico?with=paciente,terapeutum,terapium,responsavel&filter[status]=Pendente&filter[responsavel_id]=${user.responsavels[0].id}`)
            .then(({ data }) => {
                setNumeroDocumentosPendentes(data.length);
                if (data.length > 0 && data.length < 10) {
                    setNumeroDocumentosPendentes('0' + data.length);
                }
                setPlanosTerapeuticos(data);
            });
    }

    useEffect(() => {
        if (openModalPlanoTerapeutico) return;
        fetchData();
    }, [openModalPlanoTerapeutico]);

    useEffect(() => {
        if (!planosTerapeuticos) return;
        setIsLoaded(true);
    }, [planosTerapeuticos]);

    return (
        <Responsavel>
            <Container>
                <DivPrincipal>
                    <ParagrafoSaudacao>
                        <b>Olá, Responsável</b>
                    </ParagrafoSaudacao>
                    <ParagrafoInformativoNumeroDocumentos>
                        Você tem <strong><b>{numeroDocumentosPendentes}</b></strong> documentos pendentes de <b>plano terapêutico</b> para revisão
                    </ParagrafoInformativoNumeroDocumentos>
                    {isLoaded &&
                        <DivListaDocumentos>
                            <table className='w-70 m-auto'>
                                <thead>
                                    <tr>
                                        <th>Documento</th>
                                        <th>Ação</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {planosTerapeuticos.map((plano) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <NomeDocumento>Terapia: {plano.terapium.terapia} - Terapeuta: {plano.terapeutum.nome} - Paciente: {plano.paciente.nome}</NomeDocumento>
                                                </td>
                                                <td>
                                                    <button onClick={() => { setPlanoTerapeuticoModal(plano); setOpenModalPlanoTerapeutico(true) }} className='btn-padrao'>
                                                        Abrir
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </DivListaDocumentos>
                    }
                </DivPrincipal>
            </Container>
            {openModalPlanoTerapeutico && <PlanoTerapeuticoModal planoTerapeutico={planoTerapeuticoModal} onClose={setOpenModalPlanoTerapeutico} />}
        </Responsavel>
    );
};

export default DocumentosTerapeuticos;