import React, {forwardRef, useRef, useState} from 'react';
import {Form} from '@unform/web';
import Input from '../../Input';
import Select from '../../Select';
import {Container, Modal} from '../styles';
import DocumentoAdministrativoService from '../../../services/DocumentoAdministrativoService';
import {useSnackbar} from '../../../context/snackbar';
import {Editor} from '@tinymce/tinymce-react';
import {ListaVariaveisDocumentoPersonalizado} from './ListaVariaveisDocumentoPersonalizado';
import api from '../../../services/api';
import Swal from 'sweetalert2';

const DocumentoAdministrativoModal = ({ onClose, setDocumentoAdministrativoModalOpen, loadDocumentos, }, ref) => {
  const formRef = useRef(null);
  const editorRef = useRef(null);
  const id = 'modal';
  const snackbar = useSnackbar();
  const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState(null);
  const [tipoAtribuicaoSelecionada, setTipoAtribuicaoSelecionada] = useState(null);
  const [criarDocumentoPersonalizado, setCriarDocumentoPersonalizado] = useState(false);
  const [documento, setDocumento] = useState(null);

  const variaveisTerapeuta = [
    '{{terapeuta.nome}}',
    '{{terapeuta.cpf}}',
    '{{terapeuta.rg}}',
    '{{terapeuta.nascimento}}',
    '{{terapeuta.email}}',
    '{{terapeuta.telefone1}}',
    '{{terapeuta.endereco}}',
    '{{terapeuta.estado}}',
    '{{terapeuta.bairro}}',
    '{{terapeuta.cep}}'
  ];

  const variaveisResponsavel = [
    '{{responsavel.nome}}',
    '{{responsavel.cpf}}',
    '{{responsavel.rg}}',
    '{{responsavel.email}}',
    '{{responsavel.telefone1}}',
    '{{responsavel.endereco}}',
    '{{responsavel.estado}}',
    '{{responsavel.bairro}}',
    '{{responsavel.cep}}'
  ];

  const tiposDocumentos = [
    {
      value: 'obrigatorio',
      label: 'Obrigatório',
    },
    {
      value: 'optativo',
      label: 'Optativo',
    },
    {
      value: 'informativo',
      label: 'Informativo',
    },
  ];
  const atribuicao = [
    {
      value: 'terapeuta',
      label: 'Terapeutas',
    },
    {
      value: 'responsavel',
      label: 'Responsáveis',
    },
  ];

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  const handleChangeTipoDocumento = (e) => {
    setTipoDocumentoSelecionado(e.value);
  };

  const handleChangeDocumento = (e) => {
    var extPermitidas = ['pdf', 'PDF'];
    let inputDoc = document.querySelector("#upload_doc");
    const file = e.target.files[0];
    var size = e.target.files[0].size;
    if (extPermitidas.find((ext) => {return file.name.split('.').pop() === ext}) === undefined){
        Swal.fire('Extensão do arquivo inválida!' + '\n' + '\n' + 'Permitidas: ' + extPermitidas, '', 'warning');
        inputDoc.value = "";
    } else {
        if(size < 20971520) { //20MB
            if(file) {
              setDocumento(file);
            } else {
                Swal.fire('Houve um problema ao enviar o arquivo', '', 'warning');
            }
            
        } else {           
            Swal.fire('Limite de tamanho do arquivo excedido! (Max: 5MB)', '', 'warning');
            inputDoc.value = "";
        }
        e.preventDefault();
    }       
    
  };

  const handleChangeTipoAtribuicao = (e) => {
    setTipoAtribuicaoSelecionada(e.value);
  };

  const handleSubmit = (data) => {
    data.tipo_documento = tipoDocumentoSelecionado;
    data.atribuido_para = tipoAtribuicaoSelecionada;
    data.documento_personalizado = editorRef.current.getContent();
    let response;
    if (documento != null) {
      data.arquivo = documento;
      response = DocumentoAdministrativoService.store(data);
    } else {
      response = api.post(`api/documento-administrativo`, data);
    }
    response.then(data => {
      snackbar.displayMessage('Documento inserido com sucesso.', 'success');
      loadDocumentos();
      setDocumentoAdministrativoModalOpen(false);
    }).catch(error => {
      snackbar.displayMessage('Houve um erro ao inserir o documento, tente novamente.', 'error');
    });
  };

  const modalStyleOnClick = {width: '80%', height: 'auto', transition: 'all 0.5s'};

  return (
      <Modal id={id} onClick={handleOutsideClick}>
        <Container style={criarDocumentoPersonalizado ? modalStyleOnClick : {transition: 'all 0.5s'}}>
          <div style={{position: "relative"}}>
            <label style={{ opacity: 0.5, cursor: "pointer", position: "absolute", 
              right: "10px", fontSize: "25px", top: "-10px" }} onClick={() => setDocumentoAdministrativoModalOpen(false)}>
              x
            </label>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <label style={{ fontWeight: '900', fontSize: '18', color: '#6A3BAF' }}>Documento Administrativo</label>
          </div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
              <div style={{ width: '66%' }}>
                <Input name={'nome_documento'} required title={'Nome do documento:'} />
              </div>
              <div style={{ width: '34%' }}>
                <Select required name={'tipo_documento'} label={'Tipo:'} options={tiposDocumentos}
                        onChange={handleChangeTipoDocumento} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
              <div style={{ width: '34%' }}>
                <Input type={'number'} required name={'prazo'} title={'Prazo (em dias)'} />
              </div>
              <div style={{ width: '34%' }}>
                <Input type={'number'} required name={'validade'} title={'Validade (em dias)'} />
              </div>
              <div style={{ width: '34%' }}>
                <Select required name={'atribuido_para'} label={'Atribuir para:'} options={atribuicao}
                        onChange={handleChangeTipoAtribuicao} />
              </div>
            </div>
            <div style={{marginTop: 10}}>
              <input value={criarDocumentoPersonalizado}
                     onChange={(e) => {
                       setCriarDocumentoPersonalizado(!criarDocumentoPersonalizado)
                     }}
                     type="checkbox" name="criar_documento_personalizado"
                     id={'criar_documento_personalizado'}
                     title="Criar Documento Personalizado" />
              <label htmlFor="criar_documento_personalizado" style={{ paddingLeft: 6 }}>Criar Documento Personalizado</label>
            </div>
            <div style={criarDocumentoPersonalizado ? {display: 'none', transition: 'all 3'} : {display: 'block', transition: 'all 3s'}}>
              <div>
                <Input type={'file'} name={'arquivo'} id='upload_doc' title={'Documento:'} style={{ padding: '0' }}
                       onChange={handleChangeDocumento} />
              </div>
            </div>
            <div style={criarDocumentoPersonalizado ? {display: 'flex', flexDirection: 'column', transition: 'all 3s'} : {display: 'none', transition: 'all 3'}}>
              <div hidden={tipoAtribuicaoSelecionada === null}>
                <div id={'dados-responsavel'} hidden={tipoAtribuicaoSelecionada !== 'responsavel'}>
                  <ListaVariaveisDocumentoPersonalizado variaveis={variaveisResponsavel} />
                </div>
                <div id={'dados-terapeuta'} hidden={tipoAtribuicaoSelecionada !== 'terapeuta'}>
                  <ListaVariaveisDocumentoPersonalizado variaveis={variaveisTerapeuta} />
                </div>
              </div>
              <div style={{zIndex: '0'}}>
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    name={'documento_personalizado'}
                    ref={editorRef}
                    initialValue=""
                    init={{
                      height: 450,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                          'bold italic backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                />
              </div>
            </div>
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              width: '100%',
            }}>
              <button
                  style={{
                    backgroundColor: '#7441BF',
                    fontFamily: 'Nunito, sans-serif',
                    color: '#FFFFFF',
                    padding: '2px 32px',
                    borderRadius: '6px',
                    margin: '10px 0',
                    width: '220px',
                    border: '0',
                    fontWeight: 'bold',
                  }}
                  type='submit'
              >
                Adicionar
              </button>
            </div>
          </Form>
        </Container>
      </Modal>
  );
};

export default forwardRef(DocumentoAdministrativoModal);
