import styled from 'styled-components';

export const Container = styled.div`
background: #F5F7FA;
max-width: 1280px;
margin: 0 auto;
`;

export const DivStyle = {
    display: "flex", 
    justifyContent: "space-between",
    backgroundColor: "white",
    borderBottomLeftRadius: 10, borderBottomRightRadius: 10,
    alignItems: "center", height: 58,
    padding: "0px 20px", marginBottom: "4rem",
    boxShadow: "0 0 10px 0px silver",
    borderLeft: 10,
    borderRight: 10,
}

export const DivHeaderStyle = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center", 
}

export const TextFormat = {
    fontSize: 23, 
    fontWeight: "bold",
}

export const DivLabelStyle = {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center", 
    backgroundColor: "#F76397", 
    borderRadius: 8, 
    fontFamily: "Nunito, sans-serif", 
    color: "#FFFFFF", 
    width: "90%",
    height: 30,

}

export const FilterStyle = {
    backgroundColor: "#7441BF", 
    fontFamily: "Nunito, sans-serif", 
    color: "#FFFFFF", 
    padding: 16, 
    borderRadius: 10, 
    margin: 20,
}

export const BotaoAcaoStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    lineHeight: 1,
    border: "none",
    padding: "2px 22px",
    borderRadius: 8,
    marginRight: 12,
    height: 40,
    width: "18%"
}

export const PesquisarPacienteStyle = {
    width: '100%', 
    borderRadius: 8, 
    height: 36, 
    boxShadow: "0 0 10px 0px silver", 
    border: "none",
    padding: 12, 
    marginRight: 10 
}

export const BotaoIrStyle = {
    backgroundColor: '#7340BD',
    color: '#FFFFFF',
    fontFamily: "Nunito, sans-serif",
    border: "none",
    padding: "7px 30px",
    borderRadius: "10px",
}

export const BotaoNovoPacienteStyle = {
    alignItems: "center", 
    justifyContent: "center", 
    display: "flex", 
    marginTop: 20, 
    paddingBottom: 50
}