import Recepcao from '../../layouts/Recepcao';
import React, { useEffect, useState } from 'react';
import Container from '../../components/Container';
import { useAuth } from '../../context/auth';
import { DivListaDocumentos, DivPrincipal, ItemLista, NomeDocumento, ParagrafoInformativoNumeroDocumentos, ParagrafoSaudacao, } from '../DocumentoAssinatura/styles';
import api from '../../services/api';
import PlanoTerapeuticoModal from '../../components/Modais/PlanoTerapeuticoModal';

const PlanosTerapeuticosPendentes = () => {
    const { user } = useAuth();
    const [openModalPlanoTerapeutico, setOpenModalPlanoTerapeutico] = useState(false);
    const [planoTerapeuticoModal, setPlanoTerapeuticoModal] = useState();
    const [planosTerapeuticos, setPlanosTerapeuticos] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [numeroDocumentosPendentes, setNumeroDocumentosPendentes] = useState('00');

    const fetchData = () => {
        api.get(`/api/plano-terapeutico?with=paciente,terapeutum,terapium,responsavel`)
            .then(({ data }) => {
                setNumeroDocumentosPendentes(data.length);
                if (data.length > 0 && data.length < 10) {
                    setNumeroDocumentosPendentes('0' + data.length);
                }
                setPlanosTerapeuticos(data);
            });
    }

    useEffect(() => {
        if (openModalPlanoTerapeutico) return;
        fetchData();
    }, [openModalPlanoTerapeutico]);

    useEffect(() => {
        if (!planosTerapeuticos) return;
        setIsLoaded(true);
    }, [planosTerapeuticos]);

    return (
        <Recepcao>
            <Container>
                <DivPrincipal>
                    <ParagrafoSaudacao>
                        <b>Olá,</b> {user.name}
                    </ParagrafoSaudacao>
                    <ParagrafoInformativoNumeroDocumentos>
                        Você tem <strong><b>{numeroDocumentosPendentes}</b></strong> documentos pendentes de <b>plano terapêutico</b> para aprovação
                    </ParagrafoInformativoNumeroDocumentos>
                    {isLoaded &&
                        <DivListaDocumentos>
                            <span style={{ fontWeight: 'bold', marginLeft: 15 }}>Documentos:</span>
                            <ul style={{ width: '100%' }}>
                                {planosTerapeuticos.map((plano) => {
                                    return (
                                        <ItemLista key={plano.id}>
                                            <NomeDocumento>Terapia {plano.terapium.terapia} - Terapeuta {plano.terapeutum.nome} -
                                                Paciente {plano.paciente.nome}</NomeDocumento>
                                            <button onClick={() => { setPlanoTerapeuticoModal(plano); setOpenModalPlanoTerapeutico(true) }}className='btn-padrao'>Abrir </button>
                                        </ItemLista>
                                    );
                                })}
                            </ul>
                        </DivListaDocumentos>
                    }
                </DivPrincipal>
            </Container>
            {openModalPlanoTerapeutico && <PlanoTerapeuticoModal planoTerapeutico={planoTerapeuticoModal} onClose={setOpenModalPlanoTerapeutico} />}
        </Recepcao>
    );
};

export default PlanosTerapeuticosPendentes;
