import React, { useEffect, useState } from "react";
import Lista from "./Lista";

const Testes2 = () => {
    const [lista, setLista] = useState([]);
    useEffect(() => {
        setLista([1, 2, 3, 4, 5]);
    }, []);
    return (
        <div className="">
            <Lista array={lista} setLista={setLista} />
        </div>
    )
}
export default Testes2;